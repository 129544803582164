import {FormattedModal} from "./FormattedModal";
import {ModalBody} from "./ModalBody";
import {ModalFooter} from "./ModalFooter";
import {ModalHeader} from "./ModalHeader";

export const Modal = {
	FormattedWrapper: FormattedModal,
	Header: ModalHeader,
	Body: ModalBody,
	Footer: ModalFooter,
};
export * from "./ModalPageNavigator";
export * from "./ModalPageNavigatorMenu";
export * from "./Slider";
