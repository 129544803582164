import i18n from "i18next";
import React from "react";

import {AssetManager} from "../../../../Scripts/AssetManager";
import {AUTOMATION_TEST_ID} from "../../../../Scripts/Constants";
import {WeeklyGoal} from "./TreeActivityMetricsWidgets";

export const TreeActivityMetrics = ({goals, domain, totalCompletions}) => {
	const goalsInCurrentDomain = goals.find(
		(goal) => goal.taxonomy_term_id === domain
	);

	const totalCompletionsInCurrentDomain = totalCompletions?.filter(
		(completeActivity) => completeActivity.domain_id === domain
	);

	const completedIds = new Set(
		totalCompletionsInCurrentDomain.map((completion) => completion.activity_id)
	);

	let completionCounts = 0;
	for (const completedActivityId of completedIds) {
		const findCompletion = totalCompletionsInCurrentDomain.find(
			(completion) => completion.activity_id === completedActivityId
		);
		completionCounts += findCompletion.completed_on.length;
	}

	return goalsInCurrentDomain && totalCompletions ? (
		<div
			className="tree-activity-metrics"
			id={AUTOMATION_TEST_ID.CAREGIVER.HOME.TREE_ACTIVITY_METRICS}
		>
			<TreeActivityMetric
				imgSrc={AssetManager.ASSETS.ICON.LEAF_ICON}
				title={i18n.t("common.total")}
				data={completionCounts}
				imgAlt="leaf-icon"
			/>
			<div className="tree-activity-metric">
				<WeeklyGoal goalsInCurrentDomain={goalsInCurrentDomain} />
				<div className="tree-activity-metric-title">
					{i18n.t("time.weekly")}
				</div>
			</div>
		</div>
	) : null;
};

const TreeActivityMetric = ({data, imgSrc, title, imgAlt}) => {
	return (
		<div className="tree-activity-metric">
			<div className="tree-activity-metric-circle">
				<div className="tree-activity-metric-icon">
					<img src={imgSrc} alt={imgAlt} />
				</div>
				<div
					className={`tree-activity-metric-data ${
						data > 9 ? "-small-font-size" : ""
					}`}
				>
					{data}
				</div>
			</div>
			<div className="tree-activity-metric-title">{title}</div>
		</div>
	);
};
