import "./ActivityListCurrentCard.scss";

import htmlToText from "html-to-text";
import React from "react";

import {
	ActivityMonitorActions,
	ModalActions,
	PortalStateActions,
} from "../../../../Actions";
import {
	getCardHeaderBackgroundColor,
	getMessageAndTitleForActivityNotification,
} from "../../../../Scripts/Activities";
import {
	ACTIVITY_MONITOR_ACTIONS,
	AUTOMATION_TEST_ID,
	MODAL_TYPES,
	PORTALS,
} from "../../../../Scripts/Constants";
import {
	ActivityBodyComponents,
	ActivityFooterComponentsCaregiver,
	ActivityFooterComponentsEducator,
	ActivityHeaderComponents,
} from "../Modal/Modals/Activity/ActivityModalWidget";

export const ActivityListCurrentCard = ({
	currentCard,
	taxonomies,
	student,
	user,
	isActivitiesList,
	filters,
	portal,
	groups = {groups: {}},
	students = [],
	milestoneGroup = {},
	isReadingCard = false,
	hasClassroomFromOtherDomain = false,
	currentDomain = 0,
}) => {
	const {activity, saved, completions} = currentCard;
	const baseClass = "activity-list-current-card";

	const customizedFunctions = () => {
		PortalStateActions.setActivitiesListCurrentCard({
			activitiesListCurrentCard: {
				...currentCard,
				saved: !saved,
			},
			portal,
		});
	};

	const customizedFunctionsAfterComplete = () => {
		if (!currentCard.completions) {
			ModalActions.showModal(
				{
					open: true,
					size: "md",
					role: portal,
					data: {
						activity,
					},
					keepInWindow: true,
				},
				MODAL_TYPES.ACTIVITY_FEEDBACK_MODAL
			);
		}
		PortalStateActions.setActivitiesListCurrentCard({
			activitiesListCurrentCard: {
				...currentCard,
				completions: currentCard.completions ? currentCard.completions + 1 : 1,
			},
			portal,
		});
	};

	const buttonBackgroundStyle = currentCard.cardBackgroundStyle
		? currentCard.cardBackgroundStyle
		: getCardHeaderBackgroundColor({
				taxonomies,
				activity,
				filters,
		  });

	const getFooterComponent = () => {
		const footerCommonProps = {
			activity,
			user,
			filters,
			isActivitiesList,
			buttonBackgroundStyle,
		};
		switch (portal) {
			case PORTALS.CAREGIVER:
				return (
					<ActivityFooterComponentsCaregiver
						{...footerCommonProps}
						studentId={student.id}
						customizedFunctionsAfterComplete={customizedFunctionsAfterComplete}
					/>
				);
			case PORTALS.EDUCATOR:
				return (
					<ActivityFooterComponentsEducator
						{...footerCommonProps}
						customizedFunctionsAfterComplete={() => {
							if (!completions) showActivityFeedbackModal();
						}}
						groups={groups?.groups?.[0]?.groups}
						students={students}
						handlePushNotification={handlePushNotification}
						taxonomies={taxonomies}
						milestoneGroup={milestoneGroup}
						isReadingCard={isReadingCard}
						hasClassroomFromOtherDomain={hasClassroomFromOtherDomain}
					/>
				);
			default:
				throw new Error(`Unsupported portal type: ${portal}`);
		}
	};

	const handlePushNotification = ({action, saved, error}) => {
		const {activity} = currentCard;
		const activityTitle = htmlToText.fromString(activity?.title?.rendered);
		const titleAndMessage = getMessageAndTitleForActivityNotification(
			action,
			error,
			activityTitle,
			saved
		);
		ActivityMonitorActions.addToQueue({
			type: error
				? ACTIVITY_MONITOR_ACTIONS.ERROR
				: ACTIVITY_MONITOR_ACTIONS.SUCCESS,
			title: titleAndMessage?.title,
			message: titleAndMessage?.message,
		});
	};

	const showActivityFeedbackModal = () => {
		ModalActions.showModal(
			{
				open: true,
				size: "md",
				role: user.role,
				data: {
					activity,
				},
				keepInWindow: true,
				blockCloseOnOverlayClick: true,
			},
			MODAL_TYPES.ACTIVITY_FEEDBACK_MODAL
		);
	};

	return (
		<div
			className={baseClass}
			id={AUTOMATION_TEST_ID.PUBLIC.COMPONENTS.ACTIVITY_CURRENT_CARD}
		>
			<div className={`${baseClass}--header`} style={buttonBackgroundStyle}>
				<ActivityHeaderComponents
					activity={activity}
					saved={saved}
					studentId={student.id}
					customizedFunctions={customizedFunctions}
					completions={completions}
					role={portal}
					userId={user.id}
					isReadingCard={isReadingCard}
				/>
			</div>
			<div className={`${baseClass}--body`}>
				<ActivityBodyComponents
					activity={activity}
					taxonomies={taxonomies}
					isReadingCard={isReadingCard}
					hasClassroomFromOtherDomain={hasClassroomFromOtherDomain}
					currentDomain={currentDomain}
				/>
			</div>
			<div className={`${baseClass}--footer`}>{getFooterComponent()}</div>
		</div>
	);
};
