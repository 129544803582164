import i18n from "i18next";
import React from "react";

import {AUTOMATION_TEST_ID} from "../../../../Scripts/Constants";
import {GradeTaxonomyItem} from "../../../../Scripts/PublicInterfaces";
import {Dropdown, DropdownItemModel} from "../index";

interface StudentGradeProps {
	gradeTaxonomy: GradeTaxonomyItem[];
	renderGrade: string;
	handleChosenGrade: (item: DropdownItemModel) => void;
}

export const StudentGrade = ({
	gradeTaxonomy,
	renderGrade,
	handleChosenGrade,
}: StudentGradeProps) => {
	const dropdownItems = gradeTaxonomy.map((grade) => {
		return {
			value: grade.id,
			label: i18n.t(`grade.${grade.slug}`),
			id: `${AUTOMATION_TEST_ID.PUBLIC.DROPDOWN.GRADE.GRADE_SELECT}-${grade.slug}`,
		};
	});

	const selectedItem =
		dropdownItems !== undefined && dropdownItems.length > 0
			? dropdownItems.find((item) => item.label === renderGrade)
			: null;

	const selectedItemId = selectedItem ? selectedItem.id : "";

	return (
		<div className="row w-100 mb-2">
			<small>{i18n.t("activity_taxonomies.grade")}</small>
			<Dropdown
				id={AUTOMATION_TEST_ID.PUBLIC.DROPDOWN.GRADE.GRADE_SELECT}
				className="btn btn-dropdown dropdown-toggle w-100"
				items={dropdownItems}
				selectedItem={{label: renderGrade, id: selectedItemId}}
				onSelect={handleChosenGrade}
			/>
		</div>
	);
};
