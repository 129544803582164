import React, {Component} from "react";

import {CheckboxItem} from "./CheckboxDropdown";
interface CheckboxDropdownItemProps {
	checked: boolean;
	isMasterChecked: boolean;
	isMasterItem: boolean;
	item: CheckboxItem;
	onChange: (item: CheckboxItem) => void;
}

export class CheckboxDropdownItem extends Component<CheckboxDropdownItemProps> {
	private inputRef: React.RefObject<HTMLInputElement>;

	constructor(props: CheckboxDropdownItemProps) {
		super(props);
		this.inputRef = React.createRef();
	}

	componentDidMount = () => {
		const {checked, isMasterChecked, isMasterItem} = this.props;
		if (checked !== true && this.inputRef.current !== null) {
			this.inputRef.current.checked = checked;
		}
		if (
			isMasterItem &&
			isMasterChecked === true &&
			this.inputRef.current !== null
		) {
			this.inputRef.current.checked = isMasterChecked;
		}
	};

	componentDidUpdate = (prevProps: CheckboxDropdownItemProps) => {
		const {checked, isMasterChecked, isMasterItem} = this.props;
		if (prevProps.checked !== checked && this.inputRef.current !== null) {
			this.inputRef.current.checked = checked;
		}
		if (
			isMasterItem &&
			prevProps.isMasterChecked !== isMasterChecked &&
			this.inputRef.current !== null
		) {
			this.inputRef.current.checked = isMasterChecked;
		}
	};

	handleChange = () => {
		const {onChange, item} = this.props;
		onChange(item);
	};

	render() {
		const {item, isMasterItem, isMasterChecked, checked} = this.props;
		return (
			<div
				className={`component-dropdown_checkbox dropdown-item p-3 flex align-center btn-block${
					isMasterItem ? " dd-master" : ""
				}`}
			>
				<input
					ref={this.inputRef}
					type="checkbox"
					id={`filter-${item.id}`}
					defaultChecked={checked || (isMasterItem && isMasterChecked)}
					onChange={this.handleChange}
					disabled={isMasterItem}
				/>
				<label
					className="w-100 ws-nowrap"
					title={item.label}
					htmlFor={`filter-${item.id}`}
				>
					{item.label}
				</label>
			</div>
		);
	}
}
