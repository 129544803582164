import React from "react";

import {STYLED_ELEMENT} from "../../../Scripts/Constants";
import {getSupportUrl} from "../../../Scripts/Fetch";
import {portalClassSelector} from "../../../Scripts/Utilities";
import {Icon} from "../../Public";

export const PrimaryNavBarSupportButton = ({portal}) => {
	const portalSpecificClassName = portalClassSelector(
		portal,
		STYLED_ELEMENT.BUTTON
	);
	const className = `btn border-0 ${portalSpecificClassName}`;
	const href = getSupportUrl(portal);
	return href ? (
		<a
			href={href}
			target="_blank"
			className="hide-username"
			rel="noopener noreferrer"
		>
			<button type="button" className={className}>
				<span className="">
					<Icon type="faInfoCircle" size="lg" className="text-white" />
				</span>
			</button>
		</a>
	) : null;
};
