import React from "react";

export const ActivityInfoTag = ({item}) => {
	const baseClass = "info-block";
	return item.content ? (
		<div className={baseClass}>
			<div className={`${baseClass}--title`}>{item.title}</div>
			<div className={`${baseClass}--content`}>
				{item.content.map((contentItem) => (
					<div key={contentItem}>{contentItem}</div>
				))}
			</div>
		</div>
	) : null;
};
