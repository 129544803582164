import "../../../../Stylesheets/css/scss/Components/Portal/Public/Inputs/Radio.css";

import React from "react";

import {Icon} from "../../../Public";

interface RadioProps {
	checked: boolean;
	onClick: (data: any) => void;
	disabled?: boolean;
	data: any;
}

export const Radio = ({checked, onClick, data, disabled}: RadioProps) => (
	<div className="radio" onClick={disabled ? () => {} : () => onClick(data)}>
		{checked ? <Icon type="faCheckCircle" /> : <div className="empty-radio" />}
	</div>
);
