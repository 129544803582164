import "../../../../Stylesheets/css/scss/Components/Portal/Public/Inputs/Checkbox.css";

import React from "react";

import {Icon} from "../../../Public";

type $CheckboxData = any;

interface CheckboxProps {
	checked: boolean;
	onClick: (data: $CheckboxData) => void;
	disabled?: boolean;
	data: $CheckboxData;
	name?: string;
}

export const Checkbox = ({
	checked,
	onClick,
	data,
	disabled,
	name,
}: CheckboxProps) => (
	<div
		className="checkbox"
		name={name}
		onClick={disabled ? () => {} : () => onClick(data)}
	>
		{checked ? (
			<Icon type="faCheckSquare" />
		) : (
			<div className="empty-checkbox" />
		)}
	</div>
);
