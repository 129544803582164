import "./radio.scss";

import React, {Fragment} from "react";

import {STYLE_CONSTANT} from "../../../Scripts/StyleConstants";

export interface RadioProps {
	/**
	 * A required custom id for each radio input
	 */
	id: string;
	/**
	 * Is the radio input selected?
	 */
	checked: boolean;
	/**
	 * Should the button be disabled?
	 */
	disabled?: boolean;
	/**
	 * What happens on a change?
	 */
	onChange?: () => void;
	/**
	 * Optional click handler
	 */
	onClick?: () => void;
	name?: string;
}

/**
 * Primary UI component for user interaction
 */
export const Radio: React.FC<RadioProps> = ({
	id,
	checked = false,
	disabled = false,
	onChange = () => null,
	...props
}) => {
	return (
		<Fragment>
			<input
				id={id}
				type="radio"
				checked={checked}
				className={STYLE_CONSTANT.COMPONENT.INPUT.RADIO}
				disabled={disabled}
				onChange={onChange}
				{...props}
			/>
			<label htmlFor={id} />
		</Fragment>
	);
};
