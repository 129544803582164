import i18n from "i18next";
import React, {useEffect} from "react";
import ReactTooltip from "react-tooltip";

import {Icon} from "../../../Public";

export const ActivityCompletion = ({
	completion,
	id,
}: {
	completion: number;
	id?: number | string;
}) => {
	useEffect(() => {
		ReactTooltip.rebuild();
	});
	return completion ? (
		<span
			className="activity-completions-count"
			data-tip={i18n.t("admin.number_of_completions")}
			data-effect="solid"
			data-for={`activity-completion-tooltip-${id}`}
			data-test="activity-completion-count-component"
			data-place="bottom"
		>
			<ReactTooltip id={`activity-completion-tooltip-${id}`} />
			<small data-test="activity-completion-count">{completion}</small>
		</span>
	) : (
		<span className="activity-no-completion">
			<Icon size="2x" type="faRegularCircle" className="text-white" />
		</span>
	);
};
