import React from "react";
import {Helmet} from "react-helmet";

interface TitleProps {
	title: string;
	meta?: string;
}

export const Title = ({title, meta}: TitleProps) => (
	<Helmet>
		<title>{title}</title>
		{meta && <meta name="description" content={meta} />}
	</Helmet>
);
