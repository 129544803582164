import * as ActionTypes from "../Redux/ActionTypes";
import {CreateReducer} from "../Redux/Utilities";
import {DOMAIN_SUBSCRIPTION_KEY, ROLES} from "../Scripts/Constants";

const initialState = {
	initializedProducts: false,
	initializedCheckout: false,
	initializedTeachersAndStudents: false,
	error: {
		freeTrial: null,
		paidSubscription: null,
		checkout: null,
		products: null,
		resetPassword: null,
		redeemCoupon: null,
	},
	authed: false,
	authStatus: null,
	authTokenChecked: false,
	registration: {},
	verifyStudentCode: {},
	completeStudentCode: {},
	resetPassword: false,
	entitlements: {
		domain_subscriptions: {},
	},
	checkout: null,
	userId: null,
	products: [],
	roles: [],
	teachersAndStudents: [],
	peopleInClassroom: [],
	featureToggles: null,
	lastFeedbackSubmission: "",
	redeemedCoupon: false,
};

export const UserReducer = CreateReducer(initialState, {
	[ActionTypes.USER_SET_ROLES]: (state, {payload}) => {
		if (!payload) return state;
		const roleStatus = {...payload};
		const {
			isAdmin,
			isTeacher,
			isPrincipal,
			isCaregiver,
			isDirector,
			isEditor,
			isReadingTeacher,
		} = roleStatus;
		const roles = [
			...state.roles,
			...(isAdmin && !state.roles.includes(ROLES.ADMIN) ? [ROLES.ADMIN] : []),
			...(isTeacher && !state.roles.includes(ROLES.TEACHER)
				? [ROLES.TEACHER]
				: []),
			...(isCaregiver && !state.roles.includes(ROLES.CAREGIVER)
				? [ROLES.CAREGIVER]
				: []),
			...(isDirector && !state.roles.includes(ROLES.DIRECTOR)
				? [ROLES.DIRECTOR]
				: []),
			...(isPrincipal && !state.roles.includes(ROLES.PRINCIPAL)
				? [ROLES.PRINCIPAL]
				: []),
			...(isEditor && !state.roles.includes(ROLES.EDITOR)
				? [ROLES.EDITOR]
				: []),
			...(isReadingTeacher && !state.roles.includes(ROLES.READING_TEACHER)
				? [ROLES.READING_TEACHER]
				: []),
		];
		if (!isReadingTeacher && roles.includes(ROLES.READING_TEACHER)) {
			const index = roles.findIndex((role) => role === ROLES.READING_TEACHER);
			roles.splice(index, 1);
		}
		if (!isTeacher && roles.includes(ROLES.TEACHER)) {
			const index = roles.findIndex((role) => role === ROLES.TEACHER);
			roles.splice(index, 1);
		}
		return {
			...state,
			roles,
		};
	},
	[ActionTypes.USER_GET_AUTHED]: (state, {payload}) => {
		if (!payload) return state;
		return {
			...state,
			authed: payload.status === 200,
			authStatus: payload.status,
			authTokenChecked: true,
		};
	},
	[ActionTypes.USER_LOGOUT]: (state, {payload}) => {
		return {
			...state,
			authed: false,
			authStatus: null,
		};
	},
	[ActionTypes.USER_RESET_REGISTRATION]: (state, {payload}) => {
		return {
			...state,
			registration: {},
		};
	},
	[ActionTypes.USER_REGISTER_CAREGIVER_ACCOUNT]: (state, {payload}) => {
		if (!payload) return state;
		const {response, id} = payload;
		return {
			...state,
			registration: {
				completed: response.status === 200,
				id,
				error: response.status !== 200 ? response : null,
			},
		};
	},
	[ActionTypes.USER_VERIFY_STUDENT_CODE]: (state, {payload}) => {
		if (!payload) return state;
		const {response, data} = payload;
		return {
			...state,
			verifyStudentCode: {
				completed: response.status === 200,
				data:
					data && response
						? {
								code: data.code,
								student:
									response && response.data && response.data.student_suggestions
										? response.data.student_suggestions[0]
										: null,
								teacher:
									response && response.data && response.data.teacher_suggestions
										? response.data.teacher_suggestions[0]
										: null,
						  }
						: null,
				error: response.status !== 200 ? response : null,
			},
		};
	},
	[ActionTypes.USER_RESET_STUDENT_CODE_VERIFICATION]: (state, {payload}) => {
		return {
			...state,
			verifyStudentCode: {},
		};
	},
	[ActionTypes.USER_COMPLETE_STUDENT_CODE_REDEMPTION]: (state, {payload}) => {
		if (!payload) return state;
		const {response, data} = payload;
		return {
			...state,
			completeStudentCode: {
				completed: response.status === 200,
				data: data ? data : null,
				error: response.status !== 200 ? response : null,
			},
		};
	},
	[ActionTypes.USER_RESET_STUDENT_CODE_REDEMPTION]: (state, {payload}) => {
		return {
			...state,
			completeStudentCode: {},
		};
	},
	[ActionTypes.USER_RESET_REDEEM_COUPON_ERROR_MESSAGE]: (state, {payload}) => {
		return {
			...state,
			error: {
				...state.error,
				redeemCoupon: null,
			},
		};
	},
	[ActionTypes.USER_SET_ENTITLEMENTS]: (state, {payload}) => {
		if (!payload) return state;
		const {entitlements, id, person, client_objects} = payload;
		return {
			...state,
			entitlements,
			userId: id,
			languages:
				client_objects?.length && client_objects[0].languages
					? client_objects[0].languages
					: {},
			featureToggles:
				client_objects?.length && client_objects[0].feature_flags
					? client_objects[0].feature_flags
					: {},
			subscriptions: person?.data?.subscriptions?.length
				? person.data.subscriptions
				: null,
			isPaidSubscriber: entitlements?.domain_subscriptions?.[
				DOMAIN_SUBSCRIPTION_KEY.PAID_SUBSCRIPTION
			]
				? true
				: false,
			getMeInfo: {...payload},
		};
	},
	[ActionTypes.USER_ADD_STUDENT_TO_PAID_SUBSCRIPTION]: (state, {payload}) => {
		if (!payload) return state;
		if (
			payload.response &&
			payload.student &&
			state.entitlements &&
			state.entitlements.domain_subscriptions
		) {
			const {domain_subscriptions} = state.entitlements;
			const newDomainSubscriptions = {};
			Object.keys(domain_subscriptions).forEach((subscriptionKey) => {
				const subscriptionInCurrentDomain =
					domain_subscriptions[subscriptionKey][payload.student.domain];
				if (subscriptionInCurrentDomain) {
					if (subscriptionKey === DOMAIN_SUBSCRIPTION_KEY.PAID_SUBSCRIPTION) {
						newDomainSubscriptions[subscriptionKey] = {
							...domain_subscriptions[subscriptionKey],
							[payload.student.domain]: [
								...subscriptionInCurrentDomain,
								payload.student.student_id,
							],
						};
					} else {
						newDomainSubscriptions[subscriptionKey] = {
							...domain_subscriptions[subscriptionKey],
							[payload.student.domain]: subscriptionInCurrentDomain.filter(
								(studentId) => studentId !== payload.student.student_id
							),
						};
					}
				}
			});
			return {
				...state,
				entitlements: {
					...state.entitlements,
					domain_subscriptions: newDomainSubscriptions,
				},
				error: {
					...state.error,
					paidSubscription: null,
				},
			};
		}
		return {
			...state,
			error: {
				...state.error,
				paidSubscription: {
					student: payload.student,
					error: payload.error,
				},
			},
		};
	},
	[ActionTypes.USER_CREATE_PERSON_FOR_FREE_TRIAL]: (state, {payload}) => {
		if (!payload) return state;
		if (
			payload.success &&
			payload.student &&
			state.entitlements &&
			state.entitlements.domain_subscriptions
		) {
			const {domain_subscriptions} = state.entitlements;
			const freeTrialDomains =
				domain_subscriptions[DOMAIN_SUBSCRIPTION_KEY.FREE_TRIAL_USER];
			const newFreeTrialDomains = freeTrialDomains ? {...freeTrialDomains} : {};
			let newFreeTrialDomain = newFreeTrialDomains[payload.student.domain];
			newFreeTrialDomain =
				newFreeTrialDomain && newFreeTrialDomain.length
					? [...newFreeTrialDomain, payload.student.id]
					: [payload.student.id];
			return {
				...state,
				entitlements: {
					...state.entitlements,
					domain_subscriptions: {
						...domain_subscriptions,
						[DOMAIN_SUBSCRIPTION_KEY.FREE_TRIAL_USER]: {
							[payload.student.domain]: newFreeTrialDomain,
						},
					},
				},
				error: {
					...state.error,
					freeTrial: null,
				},
			};
		}
		return {
			...state,
			error: {
				...state.error,
				freeTrial: {
					student: payload.student,
					error: payload.error,
				},
			},
		};
	},
	[ActionTypes.USER_CREATE_SUBSCRIPTION_BY_COUPON]: (state, {payload}) => {
		if (!payload) return state;
		if (payload.success) {
			return {
				...state,
				redeemedCoupon: true,
				error: {
					...state.error,
					redeemCoupon: null,
				},
			};
		}
		return {
			...state,
			error: {
				...state.error,
				redeemCoupon: {
					error: payload.error,
				},
			},
		};
	},
	[ActionTypes.USER_CHECKOUT_SUBSCRIPTION]: (state, {payload}) => {
		if (!payload) return state;
		if (payload.success) {
			return {
				...state,
				checkout: payload.response,
				initializedCheckout: true,
				error: {
					...state.error,
					checkout: null,
				},
			};
		}
		return {
			...state,
			checkout: null,
			intializedCheckout: true,
			error: {
				...state.error,
				checkout: payload.error,
			},
		};
	},
	[ActionTypes.USER_GET_SUBSCRIPTION_PRODUCTS]: (state, {payload}) => {
		if (!payload) return state;
		if (payload.success) {
			const products = [...state.products, payload.response];
			return {
				...state,
				products,
				initializedProducts:
					products.length === payload.productsCount ? true : false,
				error: {
					...state.error,
					products: null,
				},
			};
		}

		return {
			...state,
			products: [],
			error: {
				...state.error,
				products: payload.error,
			},
		};
	},
	[ActionTypes.USER_RESET_PASSWORD]: (state, {payload}) => {
		if (!payload) return state;
		if (payload.success) {
			return {
				...state,
				resetPassword: {success: true, action: payload.action},
				error: {
					...state.error,
					resetPassword: false,
				},
			};
		}

		return {
			...state,
			error: {
				...state.error,
				resetPassword: {message: payload.error, errorCode: payload.errorCode},
			},
		};
	},
	[ActionTypes.USER_GET_ACCESSIBLE_PEOPLE]: (state, {payload}) => {
		if (!payload) return state;

		return {
			...state,
			teachersAndStudents: payload,
			initializedTeachersAndStudents: true,
		};
	},
	[ActionTypes.USER_GET_PEOPLE_IN_CLASSROOM]: (state, {payload}) => {
		if (!payload) return state;
		const temp = state.peopleInClassroom.filter(
			(classroom) => classroom.classId !== payload.classId
		);
		const peopleInClassroom = [...temp].concat(payload);
		return {
			...state,
			peopleInClassroom,
		};
	},
	[ActionTypes.USER_SUBMIT_FEEDBACK]: (state, {payload}) => {
		if (!payload) return state;
		return {
			...state,
			lastFeedbackSubmission: payload,
		};
	},
	[ActionTypes.USER_RESET_FEEDBACK]: (state) => {
		return {
			...state,
			lastFeedbackSubmission: "",
		};
	},
});
