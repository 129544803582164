import htmlToText from "html-to-text";
import i18n from "i18next";
import React, {Fragment, useState} from "react";
import ReactTooltip from "react-tooltip";

import {
	ActivityActions,
	ActivityMonitorActions,
	ModalActions,
	NotificationActions,
	SchoolActions,
} from "../../../../../../../Actions";
import * as ActionTypes from "../../../../../../../Redux/ActionTypes";
import {
	ACTIVITY_FILTERS,
	ACTIVITY_MONITOR_ACTIONS,
	MODAL_TYPES,
	PORTALS,
} from "../../../../../../../Scripts/Constants";
import {ActivityModel} from "../../../../../../../Scripts/PublicInterfaces";
import {Button, Icon} from "../../../../../../Public";
import {Loader} from "../../../../../PublicComponents";

interface ActivityFooterComponentsCaregiverProps {
	studentId: number;
	user: {};
	activity: ActivityModel;
	customizedFunctionsAfterComplete: () => void;
	isActivitiesList?: boolean;
	filters: {hidden: boolean};
	buttonBackgroundStyle: {};
	closeModal?: () => void;
}

export const ActivityFooterComponentsCaregiver = ({
	studentId,
	user,
	activity,
	customizedFunctionsAfterComplete,
	isActivitiesList = false,
	filters = {hidden: false},
	buttonBackgroundStyle = {},
	closeModal = () => {},
}: ActivityFooterComponentsCaregiverProps) => {
	const [completingActivity, setCompletingActivity] = useState(false);
	const [currentHiddenState, setCurrentHiddenState] = useState(filters?.hidden);
	const handleCaregiverClickComplete = () => {
		setCompletingActivity(true);
		const students = [studentId];
		ActivityActions.completeActivity(activity.id, {
			students,
			notification: {
				success: () => {
					NotificationActions.getNotifications();
					if (studentId) {
						ActivityActions.getActivitiesCompletionCounts(studentId);
						SchoolActions.getStudentById(studentId, user);
					}
					const activityTitle = htmlToText.fromString(
						activity?.title?.rendered
					);
					ActivityMonitorActions.addToQueue({
						type: ACTIVITY_MONITOR_ACTIONS.SUCCESS,
						title: i18n.t("admin.activity_completed"),
						message: i18n.t("admin.activity_completed_message", {
							activity: activityTitle,
						}),
					});
					setCompletingActivity(false);
					if (customizedFunctionsAfterComplete) {
						customizedFunctionsAfterComplete();
					}
				},
				error: (error) => {
					setCompletingActivity(false);
					ActivityMonitorActions.addToQueue({
						type: ACTIVITY_MONITOR_ACTIONS.ERROR,
						title: i18n.t("admin.activity_completed"),
						message: error.data.message,
					});
				},
			},
		});
	};

	const handleClickHideActivity = () => {
		if (currentHiddenState) {
			ActivityActions.unhideActivity(activity.id, {
				handling: {
					success: () => {
						setCurrentHiddenState(false);
						ActivityActions.getActivities({
							...filters,
							[ACTIVITY_FILTERS.PAGE]: null,
						});
					},
				},
			});
		} else {
			closeModal();
			ModalActions.showModal(
				{
					open: true,
					size: "md",
					role: PORTALS.CAREGIVER,
					keepInWindow: true,
					data: {activityId: activity.id, filters},
				},
				MODAL_TYPES.CONFIRM_HIDE_ACTIVITY_MODAL
			);
		}
	};

	return (
		<Fragment>
			{!isActivitiesList ? (
				<div
					className="hide-activity"
					onClick={handleClickHideActivity}
					data-tip={i18n.t(
						`feedback.${
							currentHiddenState ? "unhide_activity" : "hide_activity"
						}`
					)}
				>
					<Loader.Wrap actionTypes={[ActionTypes.ACTIVITY_PUT_UNHIDE_ACTIVITY]}>
						<Loader.HideContent>
							<Icon type={currentHiddenState ? "faEye" : "faEyeSlash"} />
						</Loader.HideContent>
						<Loader.Spinner size="1x" spinnerClassName="spinner-icon" />
					</Loader.Wrap>
				</div>
			) : null}
			<Button
				style={buttonBackgroundStyle}
				onClick={handleCaregiverClickComplete}
				isLoading={completingActivity}
			>
				{i18n.t("phrase.click_to_complete")}
			</Button>
			<ReactTooltip place="top" type="dark" effect="solid" />
		</Fragment>
	);
};
