import "react-datepicker/dist/react-datepicker.css";

import i18n from "i18next";
import React, {Component, Fragment} from "react";

import {
	NotificationActions,
	SchoolActions,
	UserActions,
} from "../../../../Actions";
import {
	getIdentity,
	renderChosenGender,
	renderChosenIdentity,
} from "../../../../Scripts/AddAndEditUser";
import {
	ACTIONS,
	ACTIVITY_MONITOR_ACTIONS,
	ADD_EDIT_USER,
	ADMIN_REQUEST_TYPE,
	AUTOMATION_TEST_ID,
	PORTALS,
	ROLES,
} from "../../../../Scripts/Constants";
import {getLocaleDateString} from "../../../../Scripts/Date";
import {
	adminAddEditRemoveUserToast,
	closeRequestToast,
	requestAddUserToast,
} from "../../../../Scripts/Notifications";
import {
	ClassroomPeopleStudentModel,
	GradeTaxonomyItem,
} from "../../../../Scripts/PublicInterfaces";
import {Button, Form} from "../../../Public";
import {
	ErrorMessage,
	StudentGender,
	StudentGrade,
	StudentIdentity,
} from "../../PublicComponents/AddAndEditUser";
import {StudentDateOfBirth} from "./StudentDateOfBirth";
import {UserName} from "./UserName";

interface AddAndEditFormProps {
	data: AddEditFormDataModel;
	match?: {params: {schoolId?: string}};
	portal: string;
	requestType?: string;
	closeModal: () => void;
}

interface AddAndEditFormState {
	birthday: Date | null;
	gender: string | null;
	identity: boolean | null | string;
	firstName: string;
	lastName: string;
	errorMessage: string;
	grade: number;
	requestMessage: string;
	sendRequest: boolean;
	requestType: string;
	[key: string]: any;
}

export interface AddEditFormDataModel {
	new_grade_id: number;
	new_is_aboriginal: boolean | null;
	new_gender: string | null;
	student_name: string;
	new_first_name: string;
	new_last_name: string;
	new_dob: Date | null;
	request_type: string;
	class: number;
	currentStudent?: ClassroomPeopleStudentModel;
	student: number | null;
	class_name: string;
	request: number;
	school: number;
	studentId: number;
	classId: number;
	gradeTaxonomy: GradeTaxonomyItem[];
	school_name: string;
	completed: boolean;
	message: string;
	new_email: string;
	teacher_name: string;
}

export class AddAndEditForm extends Component<
	AddAndEditFormProps,
	AddAndEditFormState
> {
	constructor(props: AddAndEditFormProps) {
		super(props);
		this.state = {
			errorMessage: "",
			grade: props.data.new_grade_id,
			firstName: "",
			lastName: "",
			requestMessage: "",
			identity:
				props.data.new_is_aboriginal !== null
					? props.data.new_is_aboriginal
					: ADD_EDIT_USER.CHOOSE,
			gender:
				props.data.new_gender !== undefined
					? props.data.new_gender
					: ADD_EDIT_USER.CHOOSE,
			sendRequest: false,
			birthday: null,
			requestType: "",
		};
	}

	componentDidMount = () => {
		const {
			student_name,
			new_first_name,
			new_last_name,
			new_dob,
			new_gender,
			new_is_aboriginal,
			request_type,
		} = this.props.data;
		const firstName = new_first_name
			? new_first_name
			: student_name.split(" ")[0];
		const lastName = new_last_name ? new_last_name : student_name.split(" ")[1];
		const now = new Date();
		let birthday: Date | null = null;
		if (new_dob) {
			const selectedDate = new Date(new_dob);
			birthday = selectedDate < now ? selectedDate : null;
		}
		birthday = birthday ? new Date(getLocaleDateString(birthday)) : null;
		const gender = new_gender;
		const identity = new_is_aboriginal;
		const requestType = request_type;
		this.setState({
			firstName,
			lastName,
			birthday,
			gender,
			identity,
			requestType,
		});
	};

	yesAndCloseRequest = () => {
		this.setState({sendRequest: true});
		const {birthday, gender, identity, firstName, lastName, grade} = this.state;
		const {data} = this.props;
		const body = {
			class_grades: [
				{
					class: data.class,
					grade,
				},
			],
			name: `${firstName} ${lastName}`,
			type: "student",
			dob: birthday ? new Date(birthday).getTime() / 1000 : null,
			gender,
			is_aboriginal: identity,
		};
		const toastData = {
			userName: `${firstName} ${lastName}`,
		};
		UserActions.createNewUser(body, {
			handling: {
				success: () => {
					requestAddUserToast(
						ACTIVITY_MONITOR_ACTIONS.SUCCESS,
						i18n.t("admin.add_student"),
						{...toastData, classroomName: data.class_name}
					);
					this.handleAdminRequestResolved();
				},
				error: (error) => {
					this.setState({sendRequest: false});
					requestAddUserToast(
						ACTIVITY_MONITOR_ACTIONS.ERROR,
						i18n.t("error.failed_add_user"),
						{
							...toastData,
							errorMessage: error.data.message,
						}
					);
				},
			},
		});
	};

	isGradeUpdated = () => {
		const {new_grade_id, currentStudent} = this.props.data;
		if (currentStudent) {
			const {class_grades} = currentStudent;
			const currentClassGradeOject = class_grades.find(
				(classGradeObject) =>
					classGradeObject.class.id === this.props.data.class
			);
			if (currentClassGradeOject)
				return currentClassGradeOject.grade.id !== new_grade_id;
		}
	};

	saveAndCloseRequest = () => {
		this.setState({sendRequest: true});
		const {student} = this.props.data;
		const {birthday, gender, identity, firstName, lastName} = this.state;
		const data = {
			name: `${firstName} ${lastName}`,
			type: "student",
			dob: birthday ? new Date(birthday).getTime() / 1000 : null,
			gender,
			is_aboriginal: identity,
		};
		UserActions.updateUser(student, data, {
			handling: {
				success: () => {
					if (this.isGradeUpdated()) {
						this.updateGrade();
					} else {
						this.handleEditSuccessfully();
						this.handleAdminRequestResolved();
					}
				},
				error: (error) => {
					this.handleEditFailed(error);
				},
			},
		});
	};

	handleEditSuccessfully = () => {
		const {firstName, lastName} = this.state;
		adminAddEditRemoveUserToast(
			ACTIVITY_MONITOR_ACTIONS.SUCCESS,
			ACTIONS.EDIT,
			`${firstName} ${lastName}`
		);
	};

	handleEditFailed = (response) => {
		const {firstName, lastName} = this.state;
		this.handleError(null);
		adminAddEditRemoveUserToast(
			ACTIVITY_MONITOR_ACTIONS.ERROR,
			ACTIONS.EDIT,
			`${firstName} ${lastName}`,
			response.data.message
		);
	};

	updateGrade = () => {
		const {student} = this.props.data;
		const {grade} = this.state;
		const requestBody = {
			student,
			grade,
		};
		SchoolActions.updateStudentsInClassroom(
			this.props.data.class,
			requestBody,
			{
				handling: {
					success: () => {
						this.handleEditSuccessfully();
						this.handleAdminRequestResolved();
					},
					error: (response) => {
						this.handleEditFailed(response);
					},
				},
			}
		);
	};

	handleAdminRequestResolved = async () => {
		const {data} = this.props;
		UserActions.closeAdminRequest(data.request, {
			handling: {
				success: () => {
					closeRequestToast(ACTIVITY_MONITOR_ACTIONS.SUCCESS);
					this.refreshList();
				},
				error: (error) => {
					closeRequestToast(ACTIVITY_MONITOR_ACTIONS.ERROR, error.data.message);
				},
			},
		});
	};

	refreshList = () => {
		const {data, match, portal, closeModal} = this.props;
		this.setState({sendRequest: false});
		NotificationActions.getNotifications();
		if (portal === PORTALS.ADMIN && match?.params) {
			const {params} = match;
			if (params.schoolId) {
				if (parseInt(params.schoolId, 10) === data.school)
					UserActions.getPeopleInClassroom(data.class);
			} else
				UserActions.getAccessiblePeople([
					ROLES.TEACHER,
					ROLES.STUDENT,
					ROLES.PRINCIPAL,
				]);
		}
		closeModal();
	};

	handleError = (errorMessage: string | null) => {
		if (errorMessage) {
			this.setState({errorMessage});
		}
		this.setState({sendRequest: false});
	};

	handleChosenGender = (item) => {
		this.setState({
			gender:
				item.value === ADD_EDIT_USER.VALUE.NOT_SPECIFIED ? null : item.value,
		});
	};

	handleChosenIdentity = (item) => {
		this.setState({
			identity: getIdentity(item.value),
		});
	};

	handleChosenGrade = (item) => {
		this.setState({
			grade: Number(item.value),
		});
	};

	handleChange = (event) => {
		const {value, name} = event.target;
		this.setState({
			[name]: value,
		});
	};

	handleDateChange = (birthday) => {
		this.setState({birthday});
	};

	handleClickSendAdminRequest = () => {
		this.setState({sendRequest: true});
		const {requestMessage} = this.state;
		const {requestType} = this.props;
		if (requestType !== "none" && requestMessage !== "") {
			this.sendAdminRequest();
		} else {
			this.handleError(i18n.t("error.input_in_text_area"));
		}
	};

	sendAdminRequest = () => {
		const {studentId, classId} = this.props.data;
		const {
			firstName,
			lastName,
			gender,
			identity,
			requestMessage,
			birthday,
			grade,
		} = this.state;
		const {requestType} = this.props;
		const chosenGender =
			gender === ADD_EDIT_USER.VALUE.NOT_SPECIFIED ? null : gender;
		const chosenIdentity = identity ? identity : null;
		if (firstName && lastName) {
			const editData = {
				new_first_name: firstName,
				new_last_name: lastName,
				new_dob: birthday,
				new_gender: chosenGender,
				new_is_aboriginal: chosenIdentity,
				message: requestMessage,
				class_id: classId,
				request_type: requestType,
				student_id: studentId,
				new_grade_id: grade,
			};
			const removeData = {
				message: requestMessage,
				class_id: classId,
				request_type: requestType,
				student_id: studentId,
			};
			UserActions.createAdminRequest(
				requestType === ADMIN_REQUEST_TYPE.EDIT ? editData : removeData,
				{
					handling: {
						success: () => {
							this.closeModal();
						},
						error: (error) => {
							this.handleError(error.data.message);
						},
					},
				}
			);
		} else {
			this.handleError(
				`${i18n.t("error.first_name_or_last_name_missing")} ${i18n.t(
					"error.please_make_sure_student_name_is_filled"
				)}`
			);
		}
	};

	closeModal = () => {
		this.setState({sendRequest: false});
		NotificationActions.getNotifications();
		this.props.closeModal();
	};

	render() {
		const {portal, data} = this.props;
		const {gradeTaxonomy} = data;
		const requestType = this.props.requestType
			? this.props.requestType
			: data.request_type;
		const textareaSize = requestType === ADMIN_REQUEST_TYPE.EDIT ? 3 : 10;
		const {
			gender,
			identity,
			errorMessage,
			grade,
			requestMessage,
			sendRequest,
			firstName,
			lastName,
		} = this.state;
		const renderGender = renderChosenGender(gender);
		const renderIdentity = renderChosenIdentity(identity);
		const currentGrade = gradeTaxonomy.find(
			(gradeItem) => gradeItem.id === grade
		);
		const renderGrade = currentGrade
			? i18n.t(`grade.${currentGrade.slug}`)
			: i18n.t("admin.choose_grade");
		return (
			<Fragment>
				{requestType && (
					<div className="modal-body-form">
						<Form>
							{requestType !== ADMIN_REQUEST_TYPE.REMOVE &&
							requestType !== ADMIN_REQUEST_TYPE.NONE ? (
								<Fragment>
									<UserName
										firstName={firstName}
										lastName={lastName}
										handleChange={this.handleChange}
									/>
									<StudentGender
										renderGender={renderGender}
										handleChosenGender={this.handleChosenGender}
									/>
									<StudentDateOfBirth
										birthday={this.state.birthday}
										handleDateChange={this.handleDateChange}
										placeholder={i18n.t("admin.not_specified")}
									/>
									<StudentGrade
										renderGrade={renderGrade}
										handleChosenGrade={this.handleChosenGrade}
										gradeTaxonomy={gradeTaxonomy}
									/>
									<StudentIdentity
										renderIdentity={renderIdentity}
										handleChosenIdentity={this.handleChosenIdentity}
									/>
								</Fragment>
							) : (
								<Fragment />
							)}
							{portal === PORTALS.EDUCATOR ? (
								<Form.Group
									label={i18n.t("admin.message")}
									formType="textarea"
									type="textarea"
									className="form-control"
									id={
										AUTOMATION_TEST_ID.PUBLIC.ADD_EDIT_FORM
											.ADMIN_REQUEST_MESSAGE
									}
									placeholder={i18n.t("phrase.describe_issue_here")}
									rows={textareaSize}
									name="requestMessage"
									value={requestMessage}
									onChange={this.handleChange}
									autoFocus
								/>
							) : (
								<Fragment />
							)}
						</Form>
					</div>
				)}

				<div className="modal-body-form-footer pt-0">
					<div className="cl-border mb-2" />
					{errorMessage && errorMessage.length > 0 && (
						<ErrorMessage message={errorMessage} portal={portal} />
					)}
					{portal === PORTALS.ADMIN &&
						this.state.requestType === ADMIN_REQUEST_TYPE.ADD && (
							<Button
								isLoading={sendRequest}
								onClick={this.yesAndCloseRequest}
								children={i18n.t("admin.yes_close_request")}
								user="admin"
								className="mt-3 px-3 py-2"
								id={AUTOMATION_TEST_ID.PUBLIC.ADD_EDIT_FORM.SUBMIT_BUTTON}
							/>
						)}
					{portal === PORTALS.ADMIN &&
						this.state.requestType === ADMIN_REQUEST_TYPE.EDIT && (
							<Button
								isLoading={sendRequest}
								onClick={this.saveAndCloseRequest}
								children={i18n.t("admin.save_close_request")}
								user="admin"
								className="mt-3 px-3 py-2"
								id={AUTOMATION_TEST_ID.PUBLIC.ADD_EDIT_FORM.SAVE_EDIT_BUTTON}
							/>
						)}
					{portal === PORTALS.EDUCATOR && (
						<div className="container-fluid p-2 rounded-bottom">
							<div className="row">
								<div className="col-12 text-right">
									<Button
										isLoading={sendRequest}
										onClick={this.handleClickSendAdminRequest}
										children={i18n.t("admin.send_request")}
										user="educator"
										id={
											AUTOMATION_TEST_ID.PUBLIC.ADD_EDIT_FORM
												.SEND_REQUEST_BUTTON
										}
										className="px-3 py-2"
									/>
								</div>
							</div>
						</div>
					)}
				</div>
			</Fragment>
		);
	}
}
