import React from "react";

interface TableTdProps {
	children: JSX.Element[] | JSX.Element | string;
	className?: string;
	name?: string;
	scope?: string;
	colSpan?: number;
}

export const TableTd = ({children, ...rest}: TableTdProps) => (
	<td {...rest}>{children}</td>
);
