import i18n from "i18next";
import React from "react";

import {parseStringAndReplaceVariables} from "../../../../../Scripts/AssessmentResults";
import {capitalizeFirstLetterOfEveryWord} from "../../../../../Scripts/Utilities";
import {QuestionSection, QuestionTable} from "../Public";

export const WhereDoYouTalk = ({questions, languages, studentName}) => {
	const primaryLanguage = languages.find(
		(language) => language.is_primary
	)?.english_name;
	const communityLanguage = languages.find(
		(language) => language.is_ancestral
	)?.english_name;

	const header = {
		title: i18n.t("assessments.oral_language.where_do_you_talk.title"),
		description: i18n.t(
			"assessments.oral_language.where_do_you_talk.description",
			{
				student: studentName,
				primary: primaryLanguage,
				community: communityLanguage,
			}
		),
	};

	const getAllQuestionsForGroup = (questionIds) => {
		return questions
			.filter((question) => questionIds.includes(question.question_id))
			.map(mapQuestionForTable);
	};

	const mapQuestionForTable = (question) => ({
		label: question?.question?.minor_text
			? parseStringAndReplaceVariables(question.question.minor_text, languages)
			: "",
		img: {},
		selectionAnswerKey: question?.question?.answer_key
			? question.question.answer_key
			: [],
		selectionValues: question?.values?.length ? question.values : [],
	});

	const questionMetaData = questions?.find(
		(question) => question.question_id === 201
	);

	const options = questionMetaData?.question?.answer_key?.map((option) => {
		const text = Array.isArray(option) ? option[1] : option.text;
		return capitalizeFirstLetterOfEveryWord(text);
	});
	const data = {
		headers: options,
		question: i18n.t("assessments.oral_language.where_do_you_talk.question"),
		content: [
			{
				groupLabel: i18n.t("activity_taxonomy_place.home"),
				questions: getAllQuestionsForGroup([201, 202, 203]),
			},
			{
				groupLabel: i18n.t("activity_taxonomy_place.school"),
				questions: getAllQuestionsForGroup([211, 212, 213]),
			},
			{
				groupLabel: i18n.t("activity_taxonomy_place.community"),
				questions: getAllQuestionsForGroup([221, 222, 223]),
			},
		],
	};

	return questions?.length ? (
		<div className="assessment-ol-wheredoyoutalk pb-4">
			<QuestionSection {...header}>
				<QuestionTable data={data} />
			</QuestionSection>
		</div>
	) : null;
};
