import React, {Component} from "react";
import {connect} from "react-redux";

import {debounce} from "../../../../Scripts/Utilities";

interface LazyLoaderProps extends LazyLoaderPropsFromReducer {
	actionTypes?: string[];
	onLoadMore: () => void;
	children: JSX.Element;
}

interface LazyLoaderPropsFromReducer {
	queue: string[];
}

interface LazyLoaderState {}

class LazyLoader extends Component<LazyLoaderProps, LazyLoaderState> {
	private ref;
	private lockedChildHeight;
	private scrollHandler;

	constructor(props) {
		super(props);
		this.ref = React.createRef();
		this.lockedChildHeight = 0;
		this.scrollHandler = debounce(this.onScroll, 250);
	}

	componentDidMount = () => {
		if (this.ref?.current?.parentNode)
			this.ref.current.parentNode.addEventListener(
				"scroll",
				this.scrollHandler
			);
	};

	componentWillUnmount = () => {
		if (this.ref?.current?.parentNode)
			this.ref.current.parentNode.removeEventListener(
				"scroll",
				this.scrollHandler
			);
	};

	onScroll = (node) => {
		const {actionTypes, onLoadMore, queue} = this.props;
		if (node?.target) {
			const parentHeight = node.target.offsetHeight;
			const parentScrollTop = node.target.scrollTop;
			const childHeight = node.target.firstChild?.offsetHeight;
			if (parentHeight && parentScrollTop && childHeight) {
				const scrollPosition = parentHeight + parentScrollTop;
				if (
					scrollPosition === childHeight &&
					childHeight !== this.lockedChildHeight
				) {
					this.lockedChildHeight = childHeight;
					if (
						!actionTypes ||
						!actionTypes.some((actionType) => queue.includes(actionType))
					) {
						onLoadMore();
					}
				}
			}
		}
	};

	render() {
		return <div ref={this.ref}>{this.props.children}</div>;
	}
}

interface LazyLoaderReducerState {
	loader: {
		queue: [];
	};
}

export default connect((state: LazyLoaderReducerState) => ({
	queue: state.loader.queue,
}))(LazyLoader);
