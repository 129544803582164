import CaregiverHMMathSurvey from "../Data/Surveys/Caregiver/HMMath.json";
import CaregiverMathSurvey from "../Data/Surveys/Caregiver/Math.json";
import CaregiverOralLanguageSurvey from "../Data/Surveys/Caregiver/OralLanguage.json";
import ChildAssessment from "../Data/Surveys/ChildAssessment/ChildAssessment.json";
import * as ActionTypes from "../Redux/ActionTypes";
import {CreateReducer} from "../Redux/Utilities";
import {DOMAIN_MATH, DOMAIN_ORAL_LANGUAGE} from "../Scripts/Domain";
import {
	evaluatePagesAndCreateAllQuestions,
	evaluatePagesAndCreateAllQuestionsForChildAssessment,
	evaluatePagesAndUpdateAllQuestions,
	evaluatePagesAndUpdateAllQuestionsForChildAssessment,
	evaluateSubmissionsAndUpdateAllQuestionsForChildAssessment,
} from "../Scripts/SurveyUtilities";

const initialState = {
	assessments_metadata: null,
	lastUploadSuccess: false,
	survey_schema: {
		caregiver: {
			[DOMAIN_ORAL_LANGUAGE]: CaregiverOralLanguageSurvey,
			[DOMAIN_MATH]: CaregiverMathSurvey,
		},
		childAssessment: {
			[DOMAIN_ORAL_LANGUAGE]: {
				questions: [],
				pages: [...ChildAssessment.pages],
			},
		},
		holistic_math_caregiver: {
			[DOMAIN_MATH]: CaregiverHMMathSurvey,
		},
	},
	survey_submission: {
		caregiver: {
			[DOMAIN_ORAL_LANGUAGE]: {},
		},
		childAssessment: {[DOMAIN_ORAL_LANGUAGE]: {}},
		holistic_math_caregiver: {[DOMAIN_MATH]: {}},
	},
	survey_properties: {
		caregiver: {
			[DOMAIN_ORAL_LANGUAGE]: {},
		},
		childAssessment: {[DOMAIN_ORAL_LANGUAGE]: {}},
		holistic_math_caregiver: {[DOMAIN_MATH]: {}},
	},
	survey_responses: {
		childAssessment: {[DOMAIN_ORAL_LANGUAGE]: {}},
	},
};

const SCHEMA_CODES = {
	CAREGIVER: {
		[DOMAIN_ORAL_LANGUAGE]: "caregiver-ol",
		[DOMAIN_MATH]: "caregiver-math",
	},
	CHILD_ASSESSMENT: {
		[DOMAIN_ORAL_LANGUAGE]: "child-assessment-ol",
	},
	HM_CAREGIVER: {
		[DOMAIN_MATH]: "hm-caregiver-math",
	},
};

const createAnswerSchemaForUpload = (type, payload) => {
	switch (type) {
		case SCHEMA_CODES.CAREGIVER[DOMAIN_ORAL_LANGUAGE]:
			return evaluatePagesAndCreateAllQuestions(
				payload,
				CaregiverOralLanguageSurvey
			);
		case SCHEMA_CODES.CAREGIVER[DOMAIN_MATH]:
			return evaluatePagesAndCreateAllQuestions(payload, CaregiverMathSurvey);
		case SCHEMA_CODES.CHILD_ASSESSMENT[DOMAIN_ORAL_LANGUAGE]:
			return evaluatePagesAndCreateAllQuestionsForChildAssessment(payload);
		case SCHEMA_CODES.HM_CAREGIVER[DOMAIN_MATH]:
			return evaluatePagesAndCreateAllQuestionsForChildAssessment(payload);
		default:
			return null;
	}
};

const updateAnswerSchemaForUpload = (type, schema, payload) => {
	switch (type) {
		case SCHEMA_CODES.CAREGIVER[DOMAIN_ORAL_LANGUAGE]:
			return evaluatePagesAndUpdateAllQuestions(schema, payload);
		case SCHEMA_CODES.CAREGIVER[DOMAIN_MATH]:
			return evaluatePagesAndUpdateAllQuestions(schema, payload);
		case SCHEMA_CODES.CHILD_ASSESSMENT[DOMAIN_ORAL_LANGUAGE]:
			return evaluatePagesAndUpdateAllQuestionsForChildAssessment(
				schema,
				payload
			);
		case SCHEMA_CODES.HM_CAREGIVER[DOMAIN_MATH]:
			return evaluatePagesAndUpdateAllQuestions(schema, payload);
		default: {
			console.log(`Unsupported schema code: ${type}`);
			return null;
		}
	}
};

const updateAnswerSchemaForUploadFromSubmission = (
	type,
	schema,
	responses,
	payload
) => {
	switch (type) {
		case SCHEMA_CODES.CHILD_ASSESSMENT[DOMAIN_ORAL_LANGUAGE]:
			return evaluateSubmissionsAndUpdateAllQuestionsForChildAssessment(
				schema,
				responses,
				payload
			);
		default:
			return null;
	}
};

export const SurveyReducer = CreateReducer(initialState, {
	[ActionTypes.SURVEY_UPDATE_SURVEY_PROPERTIES]: (state, {payload}) => {
		if (!payload) return state;
		const {survey_type, survey_domain, data} = payload;
		return {
			...state,
			lastUploadSuccess: false,
			survey_properties: {
				[survey_type]: {
					[survey_domain]: {
						...state.survey_properties[survey_type][survey_domain],
						[data.studentId]: {
							...data,
						},
					},
				},
			},
		};
	},
	[ActionTypes.SURVEY_CAREGIVER_CREATE_ANSWER_SCHEMA]: (state, {payload}) => {
		if (!payload || !payload.domain || !payload.studentId) return state;
		const {domain, languages, studentId} = payload;
		return {
			...state,
			lastUploadSuccess: false,
			survey_submission: {
				...state.survey_submission,
				caregiver: {
					[domain]: {
						...state.survey_submission.caregiver[domain],
						[studentId]: createAnswerSchemaForUpload(
							SCHEMA_CODES.CAREGIVER[domain],
							{languages}
						),
					},
				},
			},
			survey_properties: {
				...state.survey_properties,
				caregiver: {
					[domain]: {
						...state.survey_properties.caregiver[domain],
						[studentId]: {
							page: 0,
							active: true,
						},
					},
				},
			},
		};
	},
	[ActionTypes.SURVEY_HM_CAREGIVER_CREATE_ANSWER_SCHEMA]: (
		state,
		{payload}
	) => {
		if (!payload || !payload.domain || !payload.studentId) return state;
		const {domain, studentId} = payload;
		return {
			...state,
			lastUploadSuccess: false,
			survey_submission: {
				...state.survey_submission,
				holistic_math_caregiver: {
					[domain]: {
						...state.survey_submission.holistic_math_caregiver[domain],
						[studentId]: createAnswerSchemaForUpload(
							SCHEMA_CODES.HM_CAREGIVER[domain],
							{
								questions: [
									...state.survey_schema.holistic_math_caregiver[domain]
										.questions,
								],
								pages: [
									...state.survey_schema.holistic_math_caregiver[domain].pages,
								],
							}
						),
					},
				},
			},
			survey_properties: {
				...state.survey_properties,
				holistic_math_caregiver: {
					[domain]: {
						...state.survey_properties.holistic_math_caregiver[domain],
						[studentId]: {
							page: 0,
							active: true,
						},
					},
				},
			},
		};
	},
	[ActionTypes.SURVEY_GET_CHILD_ASSESSMENT_QUESTIONS]: (state, {payload}) => {
		if (!payload) return state;
		return {
			...state,
			lastUploadSuccess: false,
			survey_schema: {
				...state.survey_schema,
				childAssessment: {
					...state.survey_schema.childAssessment,
					[DOMAIN_ORAL_LANGUAGE]: {
						...state.survey_schema.childAssessment[DOMAIN_ORAL_LANGUAGE],
						questions: [...payload],
					},
				},
			},
		};
	},
	[ActionTypes.SURVEY_CHILD_ASSESSMENT_CREATE_ANSWER_SCHEMA]: (
		state,
		{payload}
	) => {
		if (!payload || !payload.domain || !payload.studentId) return state;
		const {domain, studentId} = payload;
		return {
			...state,
			lastUploadSuccess: false,
			survey_submission: {
				...state.survey_submission,
				childAssessment: {
					[domain]: {
						...state.survey_submission.childAssessment[domain],
						[studentId]: createAnswerSchemaForUpload(
							SCHEMA_CODES.CHILD_ASSESSMENT[DOMAIN_ORAL_LANGUAGE],
							{
								questions: [
									...state.survey_schema.childAssessment[domain].questions,
								],
								pages: [...state.survey_schema.childAssessment[domain].pages],
							}
						),
					},
				},
			},
			survey_properties: {
				...state.survey_properties,
				childAssessment: {
					...state.survey_properties.childAssessment,
					[domain]: {
						...state.survey_properties.childAssessment[domain],
						[studentId]: {
							page: 0,
							active: true,
						},
					},
				},
			},
		};
	},
	[ActionTypes.SURVEY_CHILD_ASSESSMENT_UPDATE_ANSWER_SCHEMA]: (
		state,
		{payload}
	) => {
		if (!payload || !payload.domain || !payload.studentId) return state;
		const {domain, studentId} = payload;
		return {
			...state,
			lastUploadSuccess: false,
			survey_submission: {
				...state.survey_submission,
				childAssessment: {
					[domain]: {
						...state.survey_submission.childAssessment[domain],
						[studentId]: updateAnswerSchemaForUpload(
							SCHEMA_CODES.CHILD_ASSESSMENT[domain],
							{...state.survey_submission.childAssessment[domain][studentId]},
							payload
						),
					},
				},
			},
		};
	},
	[ActionTypes.SURVEY_CHILD_ASSESSMENT_UPDATE_ANSWER_SCHEMA_FROM_SUBMISSION]: (
		state,
		{payload}
	) => {
		if (!payload || !payload.domain || !payload.studentId) return state;
		const {domain, studentId} = payload;
		return {
			...state,
			lastUploadSuccess: false,
			survey_submission: {
				...state.survey_submission,
				childAssessment: {
					[domain]: {
						...state.survey_submission.childAssessment[domain],
						[studentId]: updateAnswerSchemaForUploadFromSubmission(
							SCHEMA_CODES.CHILD_ASSESSMENT[domain],
							{...state.survey_submission.childAssessment[domain][studentId]},
							[...state.survey_responses.childAssessment[domain][studentId]],
							payload
						),
					},
				},
			},
		};
	},
	[ActionTypes.SURVEY_CAREGIVER_UPDATE_ANSWER_SCHEMA]: (state, {payload}) => {
		if (!payload || !payload.domain || !payload.studentId) return state;
		const {domain, studentId} = payload;
		return {
			...state,
			lastUploadSuccess: false,
			survey_submission: {
				...state.survey_submission,
				caregiver: {
					[domain]: {
						...state.survey_submission.caregiver[domain],
						[studentId]: updateAnswerSchemaForUpload(
							SCHEMA_CODES.CAREGIVER[domain],
							{...state.survey_submission.caregiver[domain][studentId]},
							payload
						),
					},
				},
			},
		};
	},
	[ActionTypes.SURVEY_HM_CAREGIVER_UPDATE_ANSWER_SCHEMA]: (
		state,
		{payload}
	) => {
		if (!payload || !payload.domain || !payload.studentId) return state;
		const {domain, studentId} = payload;
		return {
			...state,
			lastUploadSuccess: false,
			survey_submission: {
				...state.survey_submission,
				holistic_math_caregiver: {
					[domain]: {
						...state.survey_submission.holistic_math_caregiver[domain],
						[studentId]: updateAnswerSchemaForUpload(
							SCHEMA_CODES.HM_CAREGIVER[domain],
							{
								...state.survey_submission.holistic_math_caregiver[domain][
									studentId
								],
							},
							payload
						),
					},
				},
			},
		};
	},
	[ActionTypes.SURVEY_SUBMIT_FOR_UPLOAD]: (state, {payload}) => {
		if (!payload || !payload.domain || !payload.studentId) return state;
		const {domain, studentId} = payload;
		return {
			...state,
			lastUploadSuccess: true,
			error: {
				...state.error,
				submission: null,
			},
			survey_submission: {
				...state.survey_submission,
				caregiver: {
					[domain]: {
						...state.survey_submission.caregiver[domain],
						[studentId]: null,
					},
				},
			},
			survey_properties: {
				...state.survey_properties,
				caregiver: {
					[domain]: {
						...state.survey_properties.caregiver[domain],
						[studentId]: null,
					},
				},
			},
		};
	},
	[ActionTypes.SURVEY_SUBMIT_FOR_UPLOAD_CHILD_ASSESSMENT]: (
		state,
		{payload}
	) => {
		if (!payload || !payload.domain || !payload.studentId) return state;
		const {domain, studentId} = payload;
		return {
			...state,
			lastUploadSuccess: true,
			error: {
				...state.error,
				submission: null,
			},
			survey_submission: {
				...state.survey_submission,
				childAssessment: {
					[domain]: {
						...state.survey_submission.childAssessment[domain],
						[studentId]: {
							...state.survey_submission.childAssessment[domain][studentId],
						},
					},
				},
			},
			survey_properties: {
				...state.survey_properties,
				childAssessment: {
					[domain]: {
						...state.survey_properties.childAssessment[domain],
						[studentId]: {
							page:
								state.survey_schema.childAssessment[domain].pages.length - 1,
							active: true,
						},
					},
				},
			},
		};
	},
	[ActionTypes.SURVEY_DONE_CHILD_ASSESSMENT]: (state, {payload}) => {
		if (!payload || !payload.domain || !payload.studentId) return state;
		const {domain, studentId} = payload;
		return {
			...state,
			lastUploadSuccess: true,
			error: {
				...state.error,
				submission: null,
			},
			survey_submission: {
				...state.survey_submission,
				childAssessment: {
					[domain]: {
						...state.survey_submission.childAssessment[domain],
						[studentId]: null,
					},
				},
			},
			survey_properties: {
				...state.survey_properties,
				childAssessment: {
					[domain]: {
						...state.survey_properties.childAssessment[domain],
						[studentId]: null,
					},
				},
			},
		};
	},
	[ActionTypes.SURVEY_GET_ASSESSMENT_METADATA]: (state, {payload}) => {
		if (!payload) return state;
		return {
			...state,
			assessments_metadata: payload,
		};
	},
	[ActionTypes.SURVEY_GET_RESPONSES_FOR_CHILD_ASSESSMENT]: (
		state,
		{payload}
	) => {
		if (!payload) return state;
		const {domain, studentId, assessmentType, response} = payload;
		return {
			...state,
			survey_responses: {
				...state.survey_responses,
				[assessmentType]: {
					...state.survey_responses[assessmentType],
					[domain]: {
						...state.survey_responses[assessmentType][domain],
						[studentId]: [...response],
					},
				},
			},
		};
	},
});
