import i18n from "i18next";
import React, {Component} from "react";
import {connect} from "react-redux";
import {Link, Redirect} from "react-router-dom";

import * as ActionTypes from "../../../../Redux/ActionTypes";
import {
	AUTOMATION_TEST_ID,
	ROLES,
	STYLED_ELEMENT,
} from "../../../../Scripts/Constants";
import {getSupportUrl} from "../../../../Scripts/Fetch";
import {UserMeModel} from "../../../../Scripts/PublicInterfaces";
import {handleLogout} from "../../../../Scripts/TokenAndUser";
import {portalClassSelector} from "../../../../Scripts/Utilities";
import {Icon} from "../../../Public";
import {Loader} from "../index";
import {UserRoles} from "./UserRoles";

interface UserDropdownReducer {
	user: {roles: []; getMeInfo: UserMeModel};
	appContent: {currentRole: string};
}

interface UserDropdownPropsFromReducer {
	roles: [];
	currentRole: string;
	user: UserMeModel;
}

const mapStateToProps = (state: UserDropdownReducer) => {
	return {
		user: state.user.getMeInfo,
		roles: state.user.roles,
		currentRole: state.appContent.currentRole,
	};
};

interface UserDropdownProps extends UserDropdownPropsFromReducer {
	portal: string;
}

interface UserDropdownState {
	goToProfile: boolean;
	showUserDropdown: boolean;
}

class UserDropdown extends Component<UserDropdownProps, UserDropdownState> {
	private userDropdown;
	private userDropdownButton;
	constructor(props) {
		super(props);
		this.state = {
			showUserDropdown: false,
			goToProfile: false,
		};
	}

	componentDidMount = () => {
		document.addEventListener("mousedown", this.handleOutsideTabClick, false);
	};

	componentWillUnMount = () => {
		document.removeEventListener(
			"mousedown",
			this.handleOutsideTabClick,
			false
		);
	};

	handleClickUserDropdown = () => {
		this.setState({showUserDropdown: !this.state.showUserDropdown});
	};

	handleOutsideTabClick = (e) => {
		const {showUserDropdown} = this.state;
		if (
			this.userDropdown &&
			!this.userDropdown.contains(e.target) &&
			!this.userDropdownButton.contains(e.target) &&
			showUserDropdown
		) {
			this.setState({showUserDropdown: false});
		}
	};

	handleProfile = () => {
		this.setState({goToProfile: true});
	};

	render() {
		const {showUserDropdown, goToProfile} = this.state;
		const userDropDownClassname = showUserDropdown ? `show` : ``;
		const {user, portal, currentRole, roles} = this.props;
		const textClassname = portalClassSelector(portal, STYLED_ELEMENT.TEXT);
		const buttonClassname = portalClassSelector(portal, STYLED_ELEMENT.BUTTON);
		const editorAccess =
			typeof user?.entitlements?.activity_editor === "boolean";
		if (goToProfile) {
			return <Redirect push to={`/portal/account`} />;
		}
		return (
			<div className="dropdown ml-auto">
				<button
					className={`btn ${buttonClassname} dropdown-toggle`}
					type="button"
					id={AUTOMATION_TEST_ID.ACCOUNT.USER_DROPDOWN_BUTTON}
					onClick={this.handleClickUserDropdown}
					ref={(node) => (this.userDropdownButton = node)}
				>
					<Icon
						type="faUserCircle"
						size="lg"
						className="mr-1 text-white"
						iconWrapperClassName="d-inline"
					/>{" "}
					<span className="hide-username"> {user.name} </span>
				</button>
				<div
					className={`dropdown-menu dropdown-menu-right rounded ${userDropDownClassname}`}
					ref={(node) => (this.userDropdown = node)}
				>
					{currentRole && (
						<UserRoles
							portal={portal}
							currentRole={currentRole}
							roles={roles}
						/>
					)}
					<button
						className="dropdown-item"
						onClick={this.handleProfile}
						id={AUTOMATION_TEST_ID.ACCOUNT.MY_ACCOUNT_LINK}
					>
						<Icon
							type="faUserCog"
							className={`mr-1 ${textClassname}`}
							iconWrapperClassName="d-inline"
						/>{" "}
						{i18n.t("admin.my_account")}
					</button>
					{currentRole === ROLES.TEACHER && (
						<Link
							to={{
								pathname: `/portal/resources`,
							}}
							className="no-link-decoration"
						>
							<button className="dropdown-item">
								<Icon
									type="faDownload"
									className={`mr-2 ${textClassname}`}
									iconWrapperClassName="d-inline"
								/>{" "}
								{i18n.t("admin.resources")}
							</button>
						</Link>
					)}
					{editorAccess && (
						<Link
							to={{
								pathname: `/portal/activities`,
							}}
							className="no-link-decoration"
						>
							<button className="dropdown-item">
								<Icon
									type="faLeaf"
									className={`mr-2 ${textClassname}`}
									iconWrapperClassName="d-inline"
								/>{" "}
								{i18n.t("common.my_activities")}
							</button>
						</Link>
					)}
					<a
						href={getSupportUrl(portal)}
						target="_blank"
						className="no-link-decoration"
						rel="noopener noreferrer"
					>
						<button className="dropdown-item">
							<Icon
								type="faInfoCircle"
								className={`mr-2 ${textClassname}`}
								iconWrapperClassName="d-inline"
							/>{" "}
							{i18n.t("common.support")}
						</button>
					</a>
					<div className="dropdown-divider" />
					<Loader.Wrap actionTypes={[ActionTypes.USER_LOGOUT]}>
						<Loader.HideContent>
							<button
								className="dropdown-item"
								id={AUTOMATION_TEST_ID.ACCOUNT.SIGN_OUT}
								onClick={handleLogout}
							>
								<Icon
									type="faSignOutAlt"
									className={`mr-2 ${textClassname}`}
									iconWrapperClassName="d-inline"
								/>
								{i18n.t("admin.sign_out")}
							</button>
						</Loader.HideContent>
						<Loader.Spinner size="1x" />
					</Loader.Wrap>
				</div>
			</div>
		);
	}
}
export default connect(mapStateToProps)(UserDropdown);
