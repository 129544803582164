import i18n from "i18next";
import React, {Fragment} from "react";

import {sanitizeContent} from "../../../../../../../Scripts/Activities";
import {
	AUTOMATION_TEST_ID,
	OUTCOME_LEVEL,
	SANITIZE_TYPE,
} from "../../../../../../../Scripts/Constants";
import {
	DOMAIN_MATH,
	DOMAIN_ORAL_LANGUAGE,
} from "../../../../../../../Scripts/Domain";
import {ActivityInfoTag} from "./";

export const ActivityBodyComponents = ({
	activity,
	taxonomies,
	isReadingCard = false,
	hasClassroomFromOtherDomain = false,
	currentDomain = 0,
}) => {
	const currentLearningOutcome = activity[OUTCOME_LEVEL.ONE].filter(
		(outcome) => {
			const currentLearningOutcome =
				isReadingCard && hasClassroomFromOtherDomain
					? taxonomies.tagging.reading.learningOutcomes.find(
							(outcomeTaxonomy) => outcomeTaxonomy.id === outcome
					  )
					: taxonomies.learningOutcomes.find(
							(outcomeTaxonomy) => outcomeTaxonomy.id === outcome
					  );
			return currentLearningOutcome ? outcome : null;
		}
	);
	const infoTags = [
		...(!isReadingCard
			? [
					{
						title: i18n.t("admin.where"),
						content: activity.places.map((place) => {
							return i18n.t(
								`activity_taxonomy_place.${
									taxonomies.places.find(
										(placeTaxonomy) => placeTaxonomy.id === place
									).slug
								}`
							);
						}),
					},
			  ]
			: []),
		{
			title: isReadingCard
				? i18n.t("reading.learning_topic")
				: i18n.t("activity_taxonomies.learning_outcome"),
			content: currentLearningOutcome.map((outcome) => {
				return i18n.t(
					`domain.${
						(currentDomain ? currentDomain : activity.domains[0]) ===
						DOMAIN_MATH
							? "math_outcome"
							: (currentDomain ? currentDomain : activity.domains[0]) ===
							  DOMAIN_ORAL_LANGUAGE
							? "oral_language_outcome"
							: "reading_outcome"
					}.${
						isReadingCard && hasClassroomFromOtherDomain
							? taxonomies.tagging.reading.learningOutcomes.find(
									(outcomeTaxonomy) => outcomeTaxonomy.id === outcome
							  ).slug
							: taxonomies.learningOutcomes.find(
									(outcomeTaxonomy) => outcomeTaxonomy.id === outcome
							  ).slug
					}`
				);
			}),
		},
		{
			title: i18n.t("activity_taxonomies.duration"),
			content: activity.durations.map((durationItem) => {
				return i18n.t(
					`activity_taxonomy_duration.${
						taxonomies.duration.find(
							(durationTaxonomy) => durationTaxonomy.id === durationItem
						).slug
					}`
				);
			}),
		},
	];
	return (
		<Fragment>
			<div
				className={`activity-info${isReadingCard ? " on-reading-card" : ""}`}
			>
				{infoTags.map((item) => (
					<ActivityInfoTag item={item} key={item.title} />
				))}
				{activity.materials ? (
					<div className="material-block">
						<div className="material-block--title">
							{i18n.t("common.materials")}
						</div>
						<div
							className="material-block--content"
							dangerouslySetInnerHTML={sanitizeContent(
								activity.materials,
								SANITIZE_TYPE.ACTIVITY_CONTENT
							)}
						/>
					</div>
				) : null}
			</div>
			<div className="activity-content">
				<div className="title">{i18n.t("admin.description")}</div>
				<div
					dangerouslySetInnerHTML={sanitizeContent(
						activity.content.rendered,
						SANITIZE_TYPE.ACTIVITY_CONTENT
					)}
					id={AUTOMATION_TEST_ID.PUBLIC.MODAL.ACTIVITY_MODAL.MODAL_CONTENT}
				></div>
			</div>
		</Fragment>
	);
};
