import i18n from "i18next";
import React from "react";

import {AUTOMATION_TEST_ID} from "../../../../Scripts/Constants";
import {Form} from "../../../Public";

export const UserName = ({firstName, lastName, handleChange}) => {
	return (
		<div className="row w-100 pl-0">
			<div className="col-md-6 pl-0">
				<Form.Group
					label={i18n.t("form.first_name")}
					type="text"
					className="form-control mb-2"
					id={AUTOMATION_TEST_ID.PUBLIC.FORM.FIRST_NAME}
					placeholder={i18n.t("form.enter_first_name")}
					name="firstName"
					value={firstName}
					onChange={handleChange}
					autoFocus
				/>
			</div>
			<div className="col-md-6 pr-0">
				<Form.Group
					label={i18n.t("form.last_name")}
					type="text"
					className="form-control mb-2"
					id={AUTOMATION_TEST_ID.PUBLIC.FORM.LAST_NAME}
					placeholder={i18n.t("form.enter_last_name")}
					name="lastName"
					value={lastName}
					onChange={handleChange}
				/>
			</div>
		</div>
	);
};
