import "../../../../../Stylesheets/css/scss/Components/Portal/Public/Surveys/Pages/SurveyGrid.css";

import i18n from "i18next";
import React, {Component} from "react";

import {SURVEY_OPTION_TYPE} from "../../../../../Scripts/Constants";
import {organizeQuestionsForRender} from "../../../../../Scripts/SurveyUtilities";
import {Checkbox, Radio} from "../../index";
import {QuestionModel, SurveyPage} from "./SurveyCells";

interface SurveyGridProps {
	page: SurveyPage;
	questions: QuestionModel[];
	properties: {
		page: number;
	};
	onSelect: ({
		questionId,
		customResponseId,
		languageVariant,
		responseId,
		removeAllResponses,
		max_response_count,
		options,
	}: {
		questionId: number;
		customResponseId?: string | null;
		languageVariant?: string | null;
		responseId?: string;
		removeAllResponses?: {response: boolean; customResponse: boolean};
		max_response_count?: number;
		options?: [];
	}) => void;
	formatTitle: (text: string, page: SurveyPage) => string;
}

interface SurveyGridState {
	customResponse: string;
}

export class SurveyGrid extends Component<SurveyGridProps, SurveyGridState> {
	constructor(props) {
		super(props);
		this.state = {
			customResponse: this.getQuestionsAndReturnCustomInputIfItExists(),
		};
	}

	componentDidUpdate = (prevProps) => {
		const {properties} = this.props;
		if (prevProps?.properties.page !== properties?.page) {
			this.setState({
				customResponse: this.getQuestionsAndReturnCustomInputIfItExists(),
			});
		}
	};

	onInputBlur = () => {
		const {onSelect} = this.props;
		const customQuestion = this.getCustomQuestion();
		if (
			(!this.state.customResponse &&
				(customQuestion?.response?.length ||
					customQuestion?.customResponse?.length)) ||
			(this.state.customResponse &&
				customQuestion?.customResponse?.length &&
				customQuestion.customResponse[0] !== this.state.customResponse)
		) {
			if (onSelect) {
				onSelect({
					questionId: customQuestion.id,
					removeAllResponses: {
						response: true,
						customResponse: true,
					},
				});
			}
		}
	};

	onUpdateCustomResponse = (e) => {
		this.setState({
			customResponse: e.target.value,
		});
	};

	onSelect = (datum) => {
		const {id, option, options, type, language, max_response_count} = datum;
		const {onSelect} = this.props;
		if (onSelect) {
			onSelect({
				questionId: id,
				responseId: option.value ? option.value : option.text.toLowerCase(),
				customResponseId:
					type === SURVEY_OPTION_TYPE.CUSTOM_TEXT
						? this.state.customResponse
						: null,
				max_response_count,
				languageVariant: language ? language.code : null,
				options,
			});
		}
	};

	getCustomQuestion = () => {
		const {page, questions} = this.props;
		return questions.find(
			(question) =>
				page?.questions?.includes(question.id) &&
				question.type === SURVEY_OPTION_TYPE.CUSTOM_TEXT &&
				(!page?.language || page?.language?.code === question?.language?.code)
		);
	};

	getQuestionsAndReturnCustomInputIfItExists = () => {
		const customQuestion = this.getCustomQuestion();
		return customQuestion?.customResponse?.length
			? customQuestion.customResponse[0]
			: "";
	};

	userSelectionComponent = (radio) => {
		return radio ? Radio : Checkbox;
	};

	renderEditableCustomTextRow = () => (
		<input
			className="survey-grid-editable-input"
			placeholder={i18n.t("survey.other_option")}
			value={this.state.customResponse}
			onChange={this.onUpdateCustomResponse}
			onBlur={this.onInputBlur}
		/>
	);

	renderSubQuestionRows = (question, index) => {
		const {formatTitle, page} = this.props;
		return question.subQuestions.map((subQuestion, subIndex) => (
			<div
				key={`sub-question:${subQuestion.id}-${index}-${subIndex}`}
				className="survey-grid-sub-question"
			>
				<div
					className="survey-grid-sub-question-text"
					title={
						subQuestion.type !== SURVEY_OPTION_TYPE.CUSTOM_TEXT
							? formatTitle(subQuestion.minor_text, page)
							: ""
					}
				>
					{subQuestion.type === SURVEY_OPTION_TYPE.CUSTOM_TEXT
						? this.renderEditableCustomTextRow()
						: formatTitle(subQuestion.minor_text, page)}
				</div>
			</div>
		));
	};

	renderSubQuestionSelections = (question, index) => {
		const SelectionComponent = this.userSelectionComponent(
			!question.max_response_count || question.max_response_count === 1
		);
		return question.options.map((option) => (
			<div
				className="survey-grid-option-group"
				key={`option-header-${option.text}-${index}`}
			>
				<div className="survey-grid-question-option">{option.text}</div>
				{question.subQuestions.map((subQuestion, subIndex) => (
					<div
						key={`option-selection:${subQuestion.id}-${option.text}-${index}-${subIndex}`}
						className="survey-grid-option"
						name="survey-grid-option"
					>
						<SelectionComponent
							onClick={this.onSelect}
							disabled={
								subQuestion.type === SURVEY_OPTION_TYPE.CUSTOM_TEXT &&
								!this.state.customResponse
							}
							checked={subQuestion?.response?.includes(option.value)}
							data={{
								...question,
								...subQuestion,
								options: question.options,
								option,
							}}
						/>
					</div>
				))}
			</div>
		));
	};

	render() {
		const {formatTitle, questions, page} = this.props;
		const formattedQuestions = organizeQuestionsForRender(questions, page);
		return (
			<div className="survey-grid">
				{formattedQuestions.map((question, index) => (
					<div
						className="survey-grid-question"
						key={`survey-question:${index}`}
					>
						<div className="survey-grid-question-title">
							{formatTitle(question.question, page)}
						</div>
						<div className="survey-grid-content">
							<div className="survey-grid-questions">
								{this.renderSubQuestionRows(question, index)}
							</div>
							<div className="survey-grid-question-options">
								{this.renderSubQuestionSelections(question, index)}
							</div>
						</div>
					</div>
				))}
			</div>
		);
	}
}
