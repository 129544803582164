import i18n from "i18next";
import React from "react";

import {Icon, IconType} from "../Icons";

interface FormAlertProps {
	type: string;
	message?: string | JSX.Element;
	icon?: IconType;
}

export const FormAlert = ({type, message, icon}: FormAlertProps) => {
	const getAlertText = () => {
		switch (type) {
			case "fail":
				return i18n.t("error.credentials_dont_match");
			case "empty":
				return i18n.t("error.please_fill_in_username_password");
			case "error":
				return i18n.t("error.network_error");
			case "reset_complete":
				return i18n.t("form.successful_password_reset");
			case "set_complete":
				return i18n.t("form.successful_password_set");
			case "account_created":
				return i18n.t("admin.account_created_success");
			default:
				return i18n.t("error.na");
		}
	};

	const getAlertIcon = () => {
		switch (type) {
			case "reset_complete":
			case "account_created":
				return "faCheckCircle";
			default:
				return "faExclamationCircle";
		}
	};

	return type ? (
		<div className="alert edu-bg-secondary text-white mb-0 mt-4" role="alert">
			<Icon
				type={icon ? icon : getAlertIcon()}
				className="mr-2"
				iconWrapperClassName="d-inline"
			/>
			{message ? message : getAlertText()}
		</div>
	) : null;
};
