import * as ActionTypes from "../Redux/ActionTypes";
import {getData, postData} from "../Scripts/Axios/axiosRequests";
import {get_url} from "../Scripts/Fetch";
import {ErrorReporterActions, LoaderActions} from "./index";

export const getNotifications = () => {
	return (dispatch) => {
		const actionType = ActionTypes.NOTIFICATION_GET;
		const url = `${get_url()}wp-json/sprig/v2/notifications`;
		LoaderActions.addToQueue(actionType);
		getData(url)
			.then((response) => {
				dispatch({
					type: actionType,
					payload: response,
				});
			})
			.catch((error) => {
				dispatch({type: actionType});
				ErrorReporterActions.addToQueue({
					actionType,
					error,
				});
			});
	};
};

export const postNotification = (notificationId) => {
	return () => {
		const actionType = ActionTypes.NOTIFICATION_POST;
		const url = `${get_url()}wp-json/sprig/v2/notifications/${notificationId}`;
		LoaderActions.addToQueue(actionType);
		postData(url, {read: true}).catch((error) => {
			console.error(`Error: ${error.message}`);
		});
	};
};

export const hideShowNotificationTab = (showTab) => {
	return {
		type: ActionTypes.NOTIFICATION_HIDE_SHOW,
		payload: showTab,
	};
};
