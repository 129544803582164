import React, {Component} from "react";

interface FormattedModalProps {
	format?: {
		header: boolean;
		body: boolean;
		footer: boolean;
	};
	children: JSX.Element[];
}

export class FormattedModal extends Component<FormattedModalProps> {
	bodyClassBasedOnFormat = () => {
		const {
			format = {
				header: true,
				body: true,
				footer: true,
			},
		} = this.props;
		const {header, footer} = format;
		if (header && footer) return "p-0 h-f";
		if (header && !footer) return "rounded-bottom h-nf";
		if (!header && footer) return "rounded-top nh-f";
		if (!header && !footer) return "rounded-bottom rounded-top";
	};

	render() {
		const {children} = this.props;
		return children
			? React.Children.map(children, (child) => {
					return React.cloneElement(child, {
						className:
							child.type.displayName === "modalBody"
								? this.bodyClassBasedOnFormat()
								: "",
					});
			  })
			: null;
	}
}
