import {DOMAIN_MATH, DOMAIN_ORAL_LANGUAGE} from "./Domain";

export const AUTOMATION_TEST_ID = require("./Automation").ID;

export const GRADE_SLUG = {
	G1: "g1",
	G2: "g2",
	G3: "g3",
	K2: "k2",
	K3: "k3",
	K4: "k4",
	K5: "k5",
};

export const ROLES = {
	ADMIN: "admin",
	TEACHER: "teacher",
	PRINCIPAL: "principal",
	CAREGIVER: "caregiver",
	DIRECTOR: "director",
	STUDENT: "student",
	EDITOR: "editor",
	ADULT: "adult",
	SUPERADMIN: "superadmin",
	READING_TEACHER: "reading-teacher",
};

export const DIFFICULTY_SLUG = {
	MEDIUM: "medium",
	HARD: "hard",
	EASY: "easy",
};

export const LEVEL_ONE_OUTCOME_SLUG = {
	LISTENING_SPEAKING: "listening-and-speaking",
	READING_VIEWING: "reading-and-viewing",
	NUMBER_SENSE: "number-sense",
	PATTERNS_AND_RELATIONS: "patterns-and-relations",
	MEASUREMENT: "measurement",
	GEOMETRY: "geometry",
	A_SOCIAL_EMOTIONAL_LEARNING: "a-socialemotionallearning",
	B_NUMBERS: "b-numbers",
	C_ALGEBRA: "c-algebra",
	D_DATA: "d-data",
	E_SPATIAL_SENSE: "e-spatialsense",
	F_FINANCIAL_LITERACY: "f-financialliteracy",
	SR_PHONICS: "lo-sr-phonics",
	SR_PHONOLOGICAL_AWARENESS: "lo-sr-phonological-awareness",
	SR_COMPREHENSION: "lo-sr-comprehension",
	SR_VOCABULARY: "lo-sr-high-frequency-words",
	SR_ORAL_LANGUAGE: "lo-sr-oral-language-vocabulary",
	SR_FLUENCY: "lo-sr-fluency",
};

export const OUTCOME_LABEL = {
	[LEVEL_ONE_OUTCOME_SLUG.LISTENING_SPEAKING]:
		"oral_language_outcome.listening-and-speaking",
	[LEVEL_ONE_OUTCOME_SLUG.READING_VIEWING]:
		"oral_language_outcome.reading-and-viewing",
	[LEVEL_ONE_OUTCOME_SLUG.NUMBER_SENSE]: "math_outcome.number-sense",
	[LEVEL_ONE_OUTCOME_SLUG.PATTERNS_AND_RELATIONS]:
		"math_outcome.patterns-and-relations",
	[LEVEL_ONE_OUTCOME_SLUG.MEASUREMENT]: "math_outcome.measurement",
	[LEVEL_ONE_OUTCOME_SLUG.GEOMETRY]: "math_outcome.geometry",
};

export const DOMAIN_LEARNING_OUTCOME = {
	[DOMAIN_ORAL_LANGUAGE]: "oral_language_outcome",
	[DOMAIN_MATH]: "math_outcome",
};

export const FILTER_TAGS = {
	COMPETENCIES: "competencies",
	DIFFICULTIES: "difficulties",
	GENERAL_OUTCOMES: "general_outcomes",
	LANGUAGES: "languages",
	LEARNING_OUTCOMES: "learning_outcomes",
	SPECIFIC_OUTCOMES: "specific_outcomes",
};

export const REPORT_SUBMISSION_COUNT_VAR = {
	CAREGIVER_SUB_COUNT: "caregiver_submission_count",
	ELDER_SUB_COUNT: "elder_submission_count",
	STUDENT_SUB_COUNT: "student_submission_count",
	TEACHER_SUB_COUNT: "teacher_submission_count",
	TOTAL_CAREGIVER_SUB_COUNT: "required_caregiver_submission_count",
	TOTAL_ELDER_SUB_COUNT: "required_elder_submission_count",
	TOTAL_STUDENT_SUB_COUNT: "required_student_submission_count",
	TOTAL_TEACHER_SUB_COUNT: "required_teacher_submission_count",
};

export const MODAL_TYPES = {
	ADD_STUDENT: "AddStudent",
	REMOVE_STUDENT: "RemoveStudent",
	EDIT_STUDENT: "EditStudent",
	MATH_ASSESSMENT_RESULT: "MathAssessmentResults",
	ORAL_LANGUAGE_ASSESSMENT_RESULTS: "OralLanguageAssessmentResults",
	COMPLETE_AND_RECOMMEND_ACTIVITY_NEW: "CompleteAndRecommendActivity",
	BADGE_COLLECTION: "badgeCollection",
	BADGES_EARNED: "BadgesEarned",
	NEW_ACTIVITY: "NewActivity",
	TRANSLATE_ACTIVITY: "TranslateActivity",
	DUPLICATE_ACTIVITY: "DuplicateActivity",
	PARENT_SUBSCRIPTION: "ParentSubscription",
	ADMIN_REQUEST: "AdminRequest",
	ACTIVITY_MODAL_NEW: "ActivityModal",
	STUDENT_MODAL: "StudentModal",
	CANCEL_SUBSCRIPTION: "CancelSubscription",
	ADD_CHILD_SUBSCRIPTION: "AddChildToSubscription",
	SUBSCRIPTION_PAYMENT_ISSUE: "SubscriptionPaymentIssue",
	REQUEST_ADD_STUDENT: "RequestAddStudent",
	CLASS_CODE: "ClassCodeModal",
	DELETE_CLASS_CODE: "StudentCodeDeleteModal",
	EDIT_GROUP_NAME: "EditGroupNameModal",
	MOVE_GROUP_STUDENT: "MoveStudentModal",
	DELETE_USER: "DeleteUser",
	ADD_USER: "AddUser",
	ADD_USER_TO_CLASSROOM: "AddUserToClassroomModal",
	EDIT_USER: "EditUser",
	EDIT_TEACHER: "EditTeacher",
	REMOVE_USER: "RemoveStudentModal",
	STUDENT_PAST_TERMS: "StudentPastTerms",
	ADD_EDIT_SCHOOL: "AddEditSchool",
	ACTIVITY_COMMENT: "ActivityCommentModal",
	CAREGIVER_ONBOARDING: "CaregiverOnboarding",
	CAREGIVER_SURVEY: "CaregiverSurveys",
	ASSESSMENT_OVERVIEW: "AssessmentOverview",
	CONFIRMATION_MODAL: "ConfirmationModal",
	DATA_IMPORT_ADD_UPDATE_SCHOOL_CLASSROOM: "AddUpdateSchoolClassroom",
	GENERATE_TEMPLATE: "generate-template",
	ADD_TEACHER: "AddTeacher",
	FEEDBACK_MODAL: "FeedbackModal",
	RECOMMENDED_ACTIVITIES: "MilestoneRecommendedActivitiesModal",
	EDIT_PSM_STUDENT: "EditPSMStudent",
	CLASSROOMS_LIST_MODAL: "ClassroomsListModal",
	ADD_SPRIG_HOME_CHILD: "AddSprigHomeChildModal",
	REDEEM_CODE_AND_CONFIRMATION_MODAL: "RedeemCodeAndConfirmModal",
	REDEEM_COUPON: "RedeemCoupon",
	POSTAL_CODE_COLLECTING: "PostalCodeCollecting",
	CHILD_ASSESSMENT_MODAL: "ChildAssessmentModal",
	SPRIG_HOME_ONBOARDING_MODAL: "SprigHomeOnboardingModal",
	CONFIRM_HIDE_ACTIVITY_MODAL: "ConfirmHideActivityModal",
	ACTIVITY_FEEDBACK_MODAL: "ActivityFeedbackModal",
	INSTRUCTION_MODAL: "InstructionModal",
	READING_ADD_STUDENT_MODAL: "ReadingAddStudentModal",
	READING_STUDENT_MODAL: "ReadingStudentModal",
	INVITE_TEACHER_MODAL: "InviteTeacherModal",
	ADD_EDIT_READING_CLASSROOM_MODAL: "AddEditReadingClassroomModal",
	CONFIRM_REMOVE_USER_MODAL: "ConfirmRemoveUserModal",
	SKILL_MODAL: "SkillModal",
	TRAINING_VIDEO_MODAL: "TrainingVideoModal",
	READING_EDIT_SCHOOL_MODAL: "ReadingEditSchoolModal",
	PLAIN_CONTENT_MODAL: "PlainContentModal",
	NOTE_MODAL: "NoteModal",
	EDIT_NOTE_MODAL: "EditNoteModal",
	HOLISTIC_MATH_STUDENT_MODAL: "HolisticMathStudentModal",
	ACTIVITY_ASSESSMENT_MODAL: "ActivityAssessmentModal",
	HM_ONBOARDING_MODAL: "HMOnboardingModal",
	HM_CAREGIVER_SURVEY_MODAL: "HMCaregiverSurveyModal",
	READING_WELCOME_MODAL: "ReadingWelcomeModal",
};

export const ADMIN_REQUEST_TYPE = {
	REMOVE: "remove_student",
	ADD: "add_student",
	EDIT: "edit_student",
	ADD_TEACHER: "add_teacher",
	NONE: "none",
};

export const REPORT_TYPE = {
	DIVISION: "division",
	SCHOOL: "school",
	CLASS: "class",
	STUDENT: "student",
	PERSON: "person",
};

export const REPORT_ID = {
	ACTIVITY_TAG_STATS: "activity_tag_stats",
	SUBMISSION_COUNT: "submission_count",
	ANCESTRAL_SPEAKERS: "ancestral_speakers",
	HOME_LANGUAGES: "home_languages",
	RUBRIC: "rubric",
	MILESTONE: "milestone_level",
	SCREENTIME: "screentime",
};

export const SUBMISSION_COUNT = {
	CLASSROOM: "CLASSROOM",
	SCHOOL: "SCHOOL",
	DIVISION: "DIVISION",
};

export const REPORT_CHART_TYPE = {
	ACTIVITY_COMPLETION: "activity_completion",
	ASSESSMENT_COMPLETION: "assessment_completion",
	RUBRIC_LEVEL: "rubric_level",
	ANCESTRAL_LANGUAGE_SPEAKER: "ancestral_language_speakers",
	LANGUAGE_SPOKEN_AT_HOME: "languages_spoken_at_home",
	ACTIVITY_TAG_COMPLETION: "activity_tag_completion",
};

export const CHART_TYPE = {
	BAR: "bar",
	PIE: "pie",
};

export const ROUTE_CONSTANT = {
	ADMIN: {
		ADD_USER: "add-user",
		DELETE_USER: "delete-user",
		EDIT_USER: "edit-user",
		REMOVE_USER: "remove-user",
		USERS: {
			ALL: "all",
			STUDENTS: "students",
			TEACHERS: "teachers",
		},
	},
	EDUCATOR: {
		STUDENT: {
			ALL: "all",
			CUSTOM: "custom",
			MILESTONE: "milestone",
			RESULTS: "results",
		},
	},
};

export const ROUTES = {
	ACTIVITY: "/portal/activity",
	ADMIN: {
		ADD_USER: `/users/${ROUTE_CONSTANT.ADMIN.ADD_USER}`,
		CLASSROOM_ADD_USER: `/school/:schoolId/classroom/:classroomId/${ROUTE_CONSTANT.ADMIN.ADD_USER}`,
		CLASSROOM_BY_ID: "/school/:schoolId/classroom/:classroomId",
		CLASSROOM_EDIT_USER: `/school/:schoolId/classroom/:classroomId/user/:userId/${ROUTE_CONSTANT.ADMIN.EDIT_USER}`,
		CLASSROOM_REMOVE_USER: `/school/:schoolId/classroom/:classroomId/user/:userId/${ROUTE_CONSTANT.ADMIN.REMOVE_USER}`,
		DELETE_USER: `/user/:userId/${ROUTE_CONSTANT.ADMIN.DELETE_USER}`,
		EDIT_USER: `/user/:userId/${ROUTE_CONSTANT.ADMIN.EDIT_USER}`,
		SCHOOLS: "/schools",
		SCHOOL_BY_ID: "/school/:schoolId",
		USER: "/user",
		USER_ACTION_ID: {
			GENERATE_TEMPLATE: "generate-template",
			UPLOAD_FILE: "upload-file",
		},
		USER_BY_ID: "/user/:actionId",
		USERS: "/users",
		USERS_BY_ID: "/users/:roleId",
	},
	LEARNING_ACTIVITIES: "/portal/learningactivities",
	PORTAL: "/portal",
	READING_REGISTER: "/reading-register",
	RESOURCES: "/portal/resources",
	REGISTER: "/register",
	RESET_PASSWORD: "/reset-password",
	RESET_REQUEST: "/reset-request",
	SIGN_IN: "/signin",
	COUPON: "/coupon",
	CREATE_PROFILE: "/create-profile",
	READING_FREE_TRIAL: "/reading-free-trial",
	HOLISTIC_MATH_PROTOTYPE: "/holistic-math-prototype",
};

export const TIME_PERIOD = {
	WEEK: "week",
	MONTH: "month",
	TERM: "term",
	YEAR: "year",
};

export const COUNT_TYPE = {
	CAREGIVER_ACCOUNTS_LINKED: "has_caregiver_accounts",
	STUDENT_ASSESSMENTS_COMPLETED: "has_student_assessment",
	CAREGIVER_SURVEYS_COMPLETED: "has_caregiver_assessment",
};

export const MATH_ASSESSMENT_RESULT = {
	ACTIVITIES: "activities",
	QUESTIONS: "questions",
};

export const COMPLETE_RECOMMEND_ACTIVITY = {
	COMPLETE_ACTIVITY: "complete_activity",
	RECOMMEND_ACTIVITY: "recommend_activity",
};

export const BADGE_ID = {
	SPROUT_UP: 1,
	TREEMENDOUS: 2,
	BIRDS_OF_A_FEATHER: 3,
	HOME_TWEET_HOME: 4,
	HOP_TO_IT: 5,
	RISE_AND_SHINE: 6,
	BUSY_AS_A_BEE: 7,
	EVEN_STEVEN: 8,
	SQUIRRELED_AWAY: 9,
	UNBELEAFABLE: 10,
	CHITTER_CHATTER: 11,
};

export const PORTALS = {
	EDUCATOR: "educator",
	ADMIN: "admin",
	CAREGIVER: "caregiver",
	EDITOR: "editor",
	DIRECTOR: "director",
	PRINCIPAL: "principal",
	READING_EDUCATOR: "reading-educator",
};

export const CLIENT_TYPE = {
	INDIGENOUS: "indigenous",
	NON_INDIGENOUS: "non-indigenous",
	MK: "mk",
};

export const ASSESSMENT_ID = {
	STUDENT_MATH: 9,
	STUDENT_ORAL_LANGUAGE: 1,
};

export const LANGUAGE_PRODUCT_SLUG = {
	MONTHLY: "parent-subscription-yearly-oral-language-monthly",
	YEARLY: "parent-subscription-yearly-oral-language-yearly",
};

export const SUBSCRIPTION_PRICING_OPTIONS = {
	MONTHLY: {
		billingId: "month",
		paymentPeriod: "time.monthly",
		pricePeriod: "subscription.per_month",
		billing: "subscription.billed_amount_month",
		slug: LANGUAGE_PRODUCT_SLUG.MONTHLY,
	},
	YEARLY: {
		billingId: "year",
		paymentPeriod: "time.yearly",
		pricePeriod: "subscription.per_month",
		billing: "subscription.billed_amount_yearly",
		slug: LANGUAGE_PRODUCT_SLUG.YEARLY,
		promotion: "subscription.best_value",
	},
};

export const SUBSCRIPTION_TYPE = {
	FREE_TRIAL: "trial",
	PAID_SUBSCRIPTION: "caregiver",
	CLIENT: "client",
};

export const DOMAIN_SUBSCRIPTION_KEY = {
	SCHOOL_BOARD_CLIENT: "SCHOOL_BOARD_CLIENT",
	PAID_SUBSCRIPTION: "PAID_SUBSCRIPTION",
	FREE_TRIAL_USER: "FREE_TRIAL_USER",
};

export const SUBSCRIPTION_COMPONENT_KEY = {
	ADD_CHILD_SUBSCRIPTION: "ADD_CHILD_SUBSCRIPTION",
	SUBSCRIPTION_CHECKOUT: "SUBSCRIPTION_CHECKOUT",
	FREE_TRIAL: "FREE_TRIAL",
	CHOOSE_SUBSCRIPTION: "choose_subscription",
	REDEEM_CODE: "redeem_code",
	REDEEM_CONFIRMATION: "redeem_confirmation",
};

export const REACH_LIMIT = {
	SCHOOL: "reached_school_limit",
	CLASS: "reached_class_limit",
};

export const ACTIVITY_PLACE_SLUG = {
	HOME: "home",
	SCHOOL: "school",
	COMMUNITY: "community",
};

export const SPRIG_SUPPORT_EMAIL = "support@spriglearning.com";
export const SPRIG_LETS_TALK_EMAIL = "letstalk@spriglearning.com";

export const GENDER = {
	MALE: {
		VALUE: "male",
		LABEL_ID: "admin.gender_male",
	},
	FEMALE: {
		VALUE: "female",
		LABEL_ID: "admin.gender_female",
	},
	NOT_SPECIFIED: {
		VALUE: "notspecified",
		LABEL_ID: "admin.not_specified",
	},
};

export const CURRICULA_SLUG = {
	SPRIG_READING: "sprig-reading",
};

export const COUNTRY = {
	CANADA: {
		VALUE: "CA",
		CODE_LABEL_ID: "country.canada_code",
		LABEL_ID: "country.canada",
		PROVINCES: {
			ALBERTA: {
				VALUE: "Alberta",
				CODE_LABEL_ID: "country.canada_province.alberta.shorthand",
				LABEL_ID: "country.canada_province.alberta.full",
				CURRICULUM: 34,
			},
			BRITISH_COLUMBIA: {
				VALUE: "British Columbia",
				CODE_LABEL_ID: "country.canada_province.british_columbia.shorthand",
				LABEL_ID: "country.canada_province.british_columbia.full",
				CURRICULUM: 34,
			},
			MANITOBA: {
				VALUE: "Manitoba",
				CODE_LABEL_ID: "country.canada_province.manitoba.shorthand",
				LABEL_ID: "country.canada_province.manitoba.full",
				CURRICULUM: 34,
			},
			NEW_BRUNSWICK: {
				VALUE: "New Brunswick",
				CODE_LABEL_ID: "country.canada_province.new_brunswick.shorthand",
				LABEL_ID: "country.canada_province.new_brunswick.full",
				CURRICULUM: 34,
			},
			NEWFOUNDLAND_AND_LABRADOR: {
				VALUE: "Newfoundland and Labrador",
				CODE_LABEL_ID: "country.canada_province.newfoundland.shorthand",
				LABEL_ID: "country.canada_province.newfoundland.full",
				CURRICULUM: 34,
			},
			NOVA_SCOTIA: {
				VALUE: "Nova Scotia",
				CODE_LABEL_ID: "country.canada_province.nova_scotia.shorthand",
				LABEL_ID: "country.canada_province.nova_scotia.full",
				CURRICULUM: 34,
			},
			NORTHWEST_TERRITORIES: {
				VALUE: "Northwest Territories",
				CODE_LABEL_ID:
					"country.canada_province.northwest_territories.shorthand",
				LABEL_ID: "country.canada_province.northwest_territories.full",
				CURRICULUM: 34,
			},
			NUNAVUT: {
				VALUE: "Nunavut",
				CODE_LABEL_ID: "country.canada_province.nunavut.shorthand",
				LABEL_ID: "country.canada_province.nunavut.full",
				CURRICULUM: 34,
			},
			ONTARIO: {
				VALUE: "Ontario",
				CODE_LABEL_ID: "country.canada_province.ontario.shorthand",
				LABEL_ID: "country.canada_province.ontario.full",
				CURRICULUM: 34,
			},
			PRINCE_EDWARD_ISLAND: {
				VALUE: "Prince Edward Island",
				CODE_LABEL_ID: "country.canada_province.prince_edward_island.shorthand",
				LABEL_ID: "country.canada_province.prince_edward_island.full",
				CURRICULUM: 34,
			},
			QUEBEC: {
				VALUE: "Quebec",
				CODE_LABEL_ID: "country.canada_province.quebec.shorthand",
				LABEL_ID: "country.canada_province.quebec.full",
				CURRICULUM: 34,
			},
			SASKATCHEWAN: {
				VALUE: "Saskatchewan",
				CODE_LABEL_ID: "country.canada_province.saskatchewan.shorthand",
				LABEL_ID: "country.canada_province.saskatchewan.full",
				CURRICULUM: 35,
			},
			YUKON: {
				VALUE: "Yukon Territory",
				CODE_LABEL_ID: "country.canada_province.yukon.shorthand",
				LABEL_ID: "country.canada_province.yukon.full",
				CURRICULUM: 34,
			},
		},
	},
	US: {
		VALUE: "US",
		PROVINCES: {
			AL: {VALUE: "Alabama"},
			AK: {VALUE: "Alaska"},
			AZ: {VALUE: "Arizona"},
			AR: {VALUE: "Arkansas"},
			CA: {VALUE: "California"},
			CO: {VALUE: "Colorado"},
			CT: {VALUE: "Connecticut"},
			DE: {VALUE: "Delaware"},
			FL: {VALUE: "Florida"},
			GA: {VALUE: "Georgia"},
			HI: {VALUE: "Hawaii"},
			ID: {VALUE: "Idaho"},
			IL: {VALUE: "Illinois"},
			IN: {VALUE: "Indiana"},
			IA: {VALUE: "Iowa"},
			KS: {VALUE: "Kansas"},
			KY: {VALUE: "Kentucky"},
			LA: {VALUE: "Louisiana"},
			MA: {VALUE: "Maine"},
			MD: {VALUE: "Maryland"},
			ME: {VALUE: "Massachusetts"},
			MI: {VALUE: "Michigan"},
			MN: {VALUE: "Minnesota"},
			MS: {VALUE: "Mississippi"},
			MO: {VALUE: "Missouri"},
			MT: {VALUE: "Montana"},
			NE: {VALUE: "Nebraska"},
			NV: {VALUE: "Nevada"},
			NH: {VALUE: "New Hampshire"},
			NJ: {VALUE: "New Jersey"},
			NM: {VALUE: "New Mexico"},
			NY: {VALUE: "New York"},
			NC: {VALUE: "North Carolina"},
			ND: {VALUE: "North Dakota"},
			OH: {VALUE: "Ohio"},
			OK: {VALUE: "Oklahoma"},
			OR: {VALUE: "Oregon"},
			PA: {VALUE: "Pennsylvania"},
			RI: {VALUE: "Rhode Island"},
			SC: {VALUE: "South Carolina"},
			SD: {VALUE: "South Dakota"},
			TN: {VALUE: "Tennessee"},
			TX: {VALUE: "Texas"},
			UT: {VALUE: "Utah"},
			VA: {VALUE: "Virginia"},
			VT: {VALUE: "Vermont"},
			WA: {VALUE: "Washington"},
			WI: {VALUE: "Wisconsin"},
			WV: {VALUE: "West Virginia"},
			WY: {VALUE: "Wyoming"},
		},
	},
};

export const SANITIZE_TYPE = {
	TITLE: "TITLE",
	ACTIVITY_CONTENT: "ACTIVITY_CONTENT",
	NOTIFICATION_CONTENT: "NOTIFICATION_CONTENT",
	RESOURCES: "RESOURCES",
};

export const EDITOR_ACTION = {
	UPDATE: "UPDATE",
	DUPLICATE: "DUPLICATE",
	TRANSLATE: "TRANSLATE",
};

export const ADD_EDIT_USER = {
	CHOOSE: "choose",
	USER_ROLE: "User Role",
	VALUE: {
		YES: "yes",
		NO: "no",
		MALE: "male",
		FEMALE: "female",
		NOT_SPECIFIED: "notspecified",
	},
};

export const KEY_BOARD_EVENT_KEY = {
	ENTER: "Enter",
};

export const SORT_KEY = {
	ASC: "asc",
	DSC: "dsc",
	STRING: "string",
	NUMBER: "number",
	BOOL: "boolean",
	DATE: "date",
};

export const ADMIN_ACTIONS = {
	ADD_SCHOOL: "add_school",
	EDIT_SCHOOL: "edit_school",
	ADD_CLASSROOM: "add_classroom",
	EDIT_CLASSROOM: "edit_classroom",
	EDIT_DIVISION: "edit_division",
};

export const KEY_EVENT = {
	ENTER: "Enter",
	TAB: "Tab",
};

export const STYLED_ELEMENT = {
	BUTTON: "btn",
	BACKGROUND: "bg",
	TEXT: "text",
	OUTLINE_BUTTON: "btn-outline",
	OPTIONS: {
		ALT: "alt",
		PRIMARY: "primary",
		SECONDARY: "secondary",
	},
};

export const FILE_EXTENSIONS = {
	MP4: "mp4",
	PNG: "png",
};

export const MATH_FILE_NAME = {
	BERRY_STUDENT_SORT_AUDIO: "berry_student_sort_audio",
	BERRY_APP_SORT_AUDIO: "berry_app_sort_audio",
	BERRY_CONNECTIONS_AUDIO: "berry_connections_audio",
	CROSSING_1_IMAGE: "crossing_1_image",
	CROSSING_2_IMAGE: "crossing_2_image",
	SHAPE_1_IMAGE: "shape_1_image",
	BERRY_STUDENT_SORT_IMAGE: "berry_student_sort_image",
	BERRY_APP_SORT_IMAGE: "berry_app_sort_image",
	INGREDIENTS_1_IMAGE: "ingredients_1_image",
	INGREDIENTS_2_IMAGE: "ingredients_2_image",
	SHARING_THREE_PLATES: "sharing_three_plates",
	SHARING_FOUR_PLATES: "sharing_four_plates",
};

export const MATH_ASSESSMENT_TASK = {
	COMMUNICATION: "communication-math",
	MAKING_CONNECTIONS: "connections",
	MENTAL_MATH_AND_ESTIMAT: "mental-math-and-estimation",
	PROBLEM_SOLVING: "problem-solving",
	REASONING: "reasoning",
	VISUALIZATION: "visualization",
};

export const MATH_PROCESS_SLUG = {
	COMMUNICATION: "communication-math",
	MAKING_CONNECTIONS: "making-connections",
	MENTAL_MATH_AND_ESTIMAT: "mental-math-and-estimation",
	PROBLEM_SOLVING: "problem-solving",
	REASONING: "reasoning",
	VISUALIZATION: "visualization",
	CONNECTIONS: "connections",
	COMMUNICATING: "communicating",
	CONNECTING: "connecting",
	PROBLEM_SOLVING_ON: "problemsolving",
	REASONING_PROVING: "reasoning-proving",
	REFLECTING: "reflecting",
	REPRESENTING: "representing",
	SELECTING_TOOLS_STRATEGIES: "selecting-tools-strategies",
};

export const OUTCOME_LEVEL = {
	ONE: "level-1-outcomes",
	TWO: "level-2-outcomes",
	THREE: "level-3-outcomes",
};

export const OUTCOME_LEVEL_S = {
	ONE: "level-1-outcome",
	TWO: "level-2-outcome",
	THREE: "level-3-outcome",
};

export const ACTIVITY_TAG = {
	[OUTCOME_LEVEL.ONE]: "level-1-outcomes",
	[OUTCOME_LEVEL.TWO]: "level-2-outcomes",
	[OUTCOME_LEVEL.THREE]: "level-3-outcomes",
	COMPETENCY: "competency",
	DIFFICULTY: "difficulty",
	LANGUAGE: "language",
	PLACE: "place",
};

export const POST_STATUS = {
	PENDING: "pending",
	PRIVATE: "private",
};

export const ACTIVITY_USER = {
	ACTION: {SAVE: "save", COMPLETE: "complete", RECOMMEND: "recommend"},
	STATUS: {
		SAVED: "saved",
		COMPLETED: "completed",
		RECOMMENDED: "recommended",
	},
};

export const ACTIVITY_TAG_STATS_REPORT = {
	LEARNING_OUTCOMES: {
		STUDENT: "LEARNING_OUTCOMES_STUDENT",
		CLASS: "LEARNING_OUTCOMES_CLASS",
		SCHOOL: "LEARNING_OUTCOMES_SCHOOL",
		CLASSROOMS: "LEARNING_OUTCOMES_CLASSROOMS",
		DIVISION: "LEARNING_OUTCOMES_DIVISION",
		SCHOOLS: "LEARNING_OUTCOMES_SCHOOLS",
	},
	GENERAL_OUTCOMES: {
		STUDENT: "GENERAL_OUTCOMES_STUDENT",
		CLASS: "GENERAL_OUTCOMES_CLASS",
		SCHOOL: "GENERAL_OUTCOMES_SCHOOL",
		CLASSROOMS: "GENERAL_OUTCOMES_CLASSROOMS",
		DIVISION: "GENERAL_OUTCOMES_DIVISION",
		SCHOOLS: "GENERAL_OUTCOMES_SCHOOLS",
	},
	SPECIFIC_OUTCOMES: {
		STUDENT: "SPECIFIC_OUTCOMES_STUDENT",
		CLASS: "SPECIFIC_OUTCOMES_CLASS",
		SCHOOL: "SPECIFIC_OUTCOMES_SCHOOL",
		CLASSROOMS: "SPECIFIC_OUTCOMES_CLASSROOMS",
		DIVISION: "SPECIFIC_OUTCOMES_DIVISION",
		SCHOOLS: "SPECIFIC_OUTCOMES_SCHOOLS",
	},
	COMPETENCY: {
		STUDENT: "COMPETENCY_STUDENT",
		CLASS: "COMPETENCY_CLASS",
		SCHOOL: "COMPETENCY_SCHOOL",
		CLASSROOMS: "COMPETENCY_CLASSROOMS",
		DIVISION: "COMPETENCY_DIVISION",
		SCHOOLS: "COMPETENCY_SCHOOLS",
	},
};

export const ASSESSMENT_TYPE = {
	CAREGIVER: "caregiver",
	EDUCATOR: "teacher",
	STUDENT: "student",
	ELDER: "elder",
};

export const SURVEY_PAGE_TYPE = {
	START: "adult_start",
	END: "adult_end",
	GRID: "grid",
	CELLS: "cells",
	QUESTION: "question",
};

export const SURVEY_OPTION_TYPE = {
	CUSTOM_TEXT: "custom_text",
	CUSTOM_RESPONSE: "custom_response",
};

export const ACTIVITY_FILTERS = {
	AUTHOR: "author",
	COMPETENCIES: "competencies",
	COMPLETED_BY: "completed_by",
	COMPLETED_BY_CLASS_AND_CURRENT_USER: "completed_by_class_and_current_user",
	CURRICULA: "curricula",
	DIFFICULTIES: "difficulties",
	DURATIONS: "durations",
	DOMAINS: "domains",
	GENERAL_OUTCOMES: "level-2-outcomes",
	HIDDEN: "hidden",
	LANGUAGES: "languages",
	LEARNING_OUTCOMES: "level-1-outcomes",
	NOT_COMPLETED_BY: "not_completed_by",
	PAGE: "page",
	PER_PAGE: "per_page",
	PLACES: "places",
	PRIORITIZE_FEATURED: "prioritize_featured",
	SAVED_FOR: "saved_for",
	SEARCH: "search",
	SPECIFIC_OUTCOMES: "level-3-outcomes",
	STATUS: "status",
};

export const DATA_IMPORT = {
	LIMIT: {
		SCHOOL: "school",
		CLASSROOM: {
			MATH: "math",
			ORAL_LANGUAGE: "oral-language",
			READING: "reading",
		},
		NO_LIMIT: "no-limit",
	},
	MODAL: {
		ADD: {
			SCHOOL: "add-school",
			CLASSROOM: "add-classroom",
		},
		UPDATE: {
			SCHOOL: "update-school",
			CLASSROOM: "update-classroom",
		},
	},
};

export const ERROR_CODE = {
	INVALID_KEY: "invalid_key",
};

export const ORAL_LANGUAGE_RUBRIC = {
	CLARITY: "clarity_of_the_child",
	ORAL_GRAMMAR_AND_STRUCTURE: "oral_grammar_and_structure",
	STORY_FEATURES: "story_features",
};

export const MATH_RUBRIC = {
	MENTAL_MATH_AND_ESTIMAT: "mental-math-and-estimation",
	PROBLEM_SOLVING: "problem-solving",
	REASONING: "reasoning",
	VISUALIZATION: "visualization",
	COMMUNICATION: "communication-math",
	MAKING_CONNECTIONS: "connections",
};

export const ORAL_LANGUAGE_RUBRIC_BREAKDOWN = {
	[ORAL_LANGUAGE_RUBRIC.CLARITY]: [
		"ask-to-clarify",
		"demonstrate-comprehension",
	],
	[ORAL_LANGUAGE_RUBRIC.ORAL_GRAMMAR_AND_STRUCTURE]: [
		"demonstrate-different-language",
		"begin-vocabulary-choices",
		"growing-vocabulary-choices",
		"listening-skills",
		"sequential-experience",
		"simple-directions",
		"use-different-language",
		"use-expression-and-tone",
		"use-expression",
		"ask-and-respond",
		"give-directions",
		"group-conversation",
		"informal-presentation",
		"listening-habits",
		"personal-experience",
		"share-ideas",
		"use-sentences",
		"identify-major-point",
		"character-personality",
		"predictions-about-text",
		"personal-connections",
		"similarities-and-differences",
		"visualize-to-support-comprehension",
		"culturally-relevant-texts",
		"simple-written-directions",
		"talk-about-particular-texts",
		"talk-about-learning",
		"select-just-right-texts-with-assistance",
		"reread-a-familiar-text",
		"fluent-reading-of-familiar-passage",
		"awareness-of-fluency-sounds",
		"talk-about-interested-texts",
		"talk-about-information-in-texts",
		"select-just-right-texts",
		"talk-about-texts-with-reference",
		"talk-about-author",
		"identify-text",
		"use-punctuation-to-guide",
		"identify-print-and-digital-texts",
		"print-and-digital-texts",
		"find-information",
		"ask-questions-about-topics",
		"questions-about-topics",
		"gather-information",
		"talk-about-topic",
		"choose-a-face-to-represent-feelings",
		"draw-pictures-about-personal-reactions",
		"talk-about-personal-reactions",
		"back-up-an-opinion",
		"begin-to-ask-questions",
		"talk-about-texts-by-same-author",
		"talk-about-text-about-same-topic",
		"understanding-and-respect-for-diversity",
		"discuss-what-they-are-wondering-about",
		"begin-to-recognize-different-points-of-view",
		"discuss-personal-reaction",
		"text-about-the-same-topic",
		"ask-questions-of-text",
		"text-written-by-the-same-author-or-llustrator",
		"use-text-features-that-support-comprehension",
		"respect-for-diversity",
		"back-up-opinions",
		"recognize-different-points-of-view",
		"understand-print-carries-a-message",
		"play-with-words-and-sounds",
		"begin-to-label-some-drawings",
		"begin-to-understand-comments-to-clarify-meaning",
		"understand-writing-conveys-meaning",
		"express-ideas-in-complete-thought",
		"label-drawings-to-explain",
		"understand-comments-to-clarify-meaning",
		"focused-conversations",
		"growing-vocabulary",
		"express-opinions",
		"read-independently",
	],
	[ORAL_LANGUAGE_RUBRIC.STORY_FEATURES]: [
		"begin-social-conventions",
		"growing-social-conventions",
	],
};

export const HOLISTIC_MATH_EXPECTATION_LEVEL = {
	milestones: [
		{
			milestone_id: [0, 1],
			expectation: [1, 1.25],
		},
		{
			milestone_id: [2, 3],
			expectation: [1.5, 1.75],
		},
		{
			milestone_id: [4, 5],
			expectation: [2, 2.25],
		},
		{
			milestone_id: [6, 7],
			expectation: [2.5, 2.75],
		},
		{
			milestone_id: [8, 9],
			expectation: [3, 3.25],
		},
		{
			milestone_id: [10, 11],
			expectation: [3.5, 3.75],
		},
	],
};

export const STUDENT_EXPECTATION_LEVEL = {
	[GRADE_SLUG.K2]: {
		age: [2, 3],
		milestones: [
			{
				milestone_id: [0, 1],
				expectation: [1.75, 2],
			},
			{
				milestone_id: [2, 3],
				expectation: [2, 2.25],
			},
			{
				milestone_id: [4, 5],
				expectation: [2.25, 2.5],
			},
			{
				milestone_id: [6, 7],
				expectation: [2.5, 2.75],
			},
			{
				milestone_id: [8, 9],
				expectation: [2.75, 3],
			},
			{
				milestone_id: [10, 11],
				expectation: [3, 3],
			},
		],
	},
	[GRADE_SLUG.K3]: {
		age: [3, 4],
		milestones: [
			{
				milestone_id: [0, 1],
				expectation: [1.75, 2],
			},
			{
				milestone_id: [2, 3],
				expectation: [2, 2.25],
			},
			{
				milestone_id: [4, 5],
				expectation: [2.25, 2.5],
			},
			{
				milestone_id: [6, 7],
				expectation: [2.5, 2.75],
			},
			{
				milestone_id: [8, 9],
				expectation: [2.75, 3],
			},
			{
				milestone_id: [10, 11],
				expectation: [3, 3],
			},
		],
	},
	[GRADE_SLUG.K4]: {
		age: [4, 5],
		milestones: [
			{
				milestone_id: [0, 1],
				expectation: [2, 2.25],
			},
			{
				milestone_id: [2, 3],
				expectation: [2.25, 2.5],
			},
			{
				milestone_id: [4, 5],
				expectation: [2.5, 2.75],
			},
			{
				milestone_id: [6, 7],
				expectation: [2.75, 3],
			},
			{
				milestone_id: [8, 9],
				expectation: [3, 3],
			},
			{
				milestone_id: [10, 11],
				expectation: [3, 3],
			},
		],
	},
	[GRADE_SLUG.K5]: {
		age: [5, 6],
		milestones: [
			{
				milestone_id: [0, 1],
				expectation: [2.25, 2.5],
			},
			{
				milestone_id: [2, 3],
				expectation: [2.5, 2.75],
			},
			{
				milestone_id: [4, 5],
				expectation: [2.75, 3],
			},
			{
				milestone_id: [6, 7],
				expectation: [3, 3],
			},
			{
				milestone_id: [8, 9],
				expectation: [3, 3],
			},
			{
				milestone_id: [10, 11],
				expectation: [3, 3],
			},
		],
	},
	[GRADE_SLUG.G1]: {
		age: [6, 7],
		milestones: [
			{
				milestone_id: [0, 1],
				expectation: [2.25, 2.5],
			},
			{
				milestone_id: [2, 3],
				expectation: [2.5, 2.75],
			},
			{
				milestone_id: [4, 5],
				expectation: [2.75, 3],
			},
			{
				milestone_id: [6, 7],
				expectation: [3, 3],
			},
			{
				milestone_id: [8, 9],
				expectation: [3, 3],
			},
			{
				milestone_id: [10, 11],
				expectation: [3, 3],
			},
		],
	},
	[GRADE_SLUG.G2]: {
		age: [7, 8],
		milestones: [
			{
				milestone_id: [0, 1],
				expectation: [2.25, 2.5],
			},
			{
				milestone_id: [2, 3],
				expectation: [2.5, 2.75],
			},
			{
				milestone_id: [4, 5],
				expectation: [2.75, 3],
			},
			{
				milestone_id: [6, 7],
				expectation: [3, 3],
			},
			{
				milestone_id: [8, 9],
				expectation: [3, 3],
			},
			{
				milestone_id: [10, 11],
				expectation: [3, 3],
			},
		],
	},
	[GRADE_SLUG.G3]: {
		age: [7, 8],
		milestones: [
			{
				milestone_id: [0, 1],
				expectation: [2.25, 2.5],
			},
			{
				milestone_id: [2, 3],
				expectation: [2.5, 2.75],
			},
			{
				milestone_id: [4, 5],
				expectation: [2.75, 3],
			},
			{
				milestone_id: [6, 7],
				expectation: [3, 3],
			},
			{
				milestone_id: [8, 9],
				expectation: [3, 3],
			},
			{
				milestone_id: [10, 11],
				expectation: [3, 3],
			},
		],
	},
};

export const ACTIVITY_MONITOR_ACTIONS = {
	SUCCESS: "success",
	ERROR: "error",
};

export const ACTIONS = {
	ADD: "add",
	EDIT: "edit",
	REMOVE: "remove",
};

export const URL_ACTIONS = {
	SPRIG_HOME: "sprig_home",
	SPRIG_LANGUAGE: "sprig_language",
	SPRIG_HOME_COUPON: "sprig_home_coupon",
};

export const PROPERTY_SORT_ID = {
	NAME: "name",
	ROLE: "role",
	SCHOOL: "school",
	USERNAME: "username",
	GENDER: "gender",
	IDENTITY: "identity",
	GRADE: "grade",
	DOB: "dob",
};

export const ACCEPTED_REDIRECT_ROUTES = {
	[ROLES.ADMIN]: [
		`${ROUTES.PORTAL}${ROUTES.ADMIN.ADD_USER}`,
		`${ROUTES.PORTAL}${ROUTES.ADMIN.USERS}`,
		`${ROUTES.PORTAL}${ROUTES.ADMIN.USERS}/all`,
		`${ROUTES.PORTAL}${ROUTES.ADMIN.USERS}/students`,
		`${ROUTES.PORTAL}${ROUTES.ADMIN.USERS}/teachers`,
		`${ROUTES.PORTAL}${ROUTES.ADMIN.SCHOOLS}`,
		`${ROUTES.PORTAL}${ROUTES.ADMIN.USER}/${ROUTES.ADMIN.USER_ACTION_ID.GENERATE_TEMPLATE}`,
		`${ROUTES.PORTAL}${ROUTES.ADMIN.USER}/${ROUTES.ADMIN.USER_ACTION_ID.UPLOAD_FILE}`,
	],
	[ROLES.TEACHER]: [
		ROUTES.RESOURCES,
		ROUTES.ACTIVITY,
		ROUTES.LEARNING_ACTIVITIES,
	],
};

export const ACCEPTED_REDIRECT_ROUTES_REG_EXP = {
	[ROLES.ADMIN]: {
		ADMIN_SCHOOL: /^\/portal\/school\/\d+$/g,
		ADMIN_SCHOOL_CLASSROOM: /^\/portal\/school\/\d+\/classroom\/\d+$/g,
		ADMIN_SCHOOL_CLASSROOM_ADD_USER:
			/^\/portal\/school\/\d+\/classroom\/\d+\/add-user$/g,
		ADMIN_SCHOOL_CLASSROOM_EDIT_USER:
			/^\/portal\/school\/\d+\/classroom\/\d+\/user\/\d+\/edit-user$/g,
		ADMIN_SCHOOL_CLASSROOM_REMOVE_USER:
			/^\/portal\/school\/\d+\/classroom\/\d+\/user\/\d+\/remove-user$/g,
		ADMIN_USER_EDIT_USER: /^\/portal\/user\/\d+\/edit-user$/g,
		ADMIN_USER_DELETE_USER: /^\/portal\/user\/\d+\/delete-user$/g,
	},
	[ROLES.TEACHER]: {
		EDUCATOR_STUDENT_ASSESSMENT:
			/^\/portal\/classroom\/\d+\/students\/\d+\/results$/g,
	},
};

export const PROVINCE_SLUG = {
	ON: "ontario",
	AB: "alberta",
	MB: "manitoba",
	NB: "new-brunswick",
	NS: "nova-scotia",
	QC: "quebec",
};

export const FORM_OPTION_SLUG = {
	ACTIVITY_FEEDBACK_CAREGIVER: "activity-feedback-caregiver",
	ACTIVITY_FEEDBACK_EDUCATOR: "activity-feedback-educator",
	HIDE_ACTIVITY_CAREGIVER: "hide-activity-caregiver",
	HIDE_ACTIVITY_EDUCATOR: "hide-activity-educator",
};

export const EDUCATOR_MATERIAL_CATEGORY = {
	CLASSROOM_MATERIAL: "classroom-material",
	PARENT_ENGAGEMENT: "parent-engagement",
	SUPPORTING_DOCUMENTS: "supporting-documents",
};

export const INSTRUCTION_CONTENT_TYPE = {
	DESCRIPTION: "description",
	ASSESSMENT_STRATEGY: "assessment-strategy",
	INSTRUCTIONAL_STRATEGY: "instructional-strategy",
};

export const READING_TOAST_TYPE = {
	ADD_CLASSROOM: "reading-toast-add-classroom",
	INVITE_TEACHER: "reading-toast-invite-teacher",
	REMOVE_TEACHER: "reading-toast-remove-teacher",
	ADD_STUDENT: "reading-toast-add-student",
	REMOVE_STUDENT: "reading-toast-remove-student",
	ASSESSING_SKILL: {
		MASTERED: "reading-toast-assessing-mastered",
		NEEDS_PRACTICE: "reading-toast-assessing-needs-practice",
		NEEDS_INSTRUCTION: "reading-toast-assessing-needs-instruction",
		NOT_YET_TO_BE_ASSESSED: "reading-toast-assessing-not-yet-to-be-assessed",
	},
};

export const HIGHLIGHTER_ID = {
	BLUE: "blue",
	GREEN: "green",
	RED: "red",
	YELLOW: "yellow",
};

export const HOLISTIC_MATH_PROTOTYPE_DASHBOARD_DROPDOWN_SELECTION = {
	OUTCOME: "outcome",
};

export const PRE_K_MATH_ASSESSMENT_SLUG = {
	MD: {
		MD_1_1: "md_1_1",
		MD_1_2: "md_1_2",
		MD_1_3: "md_1_3",
		MD_1_COMPLETE: "md_1_complete",
		MD_2_1: "md_2_1",
		MD_2_2: "md_2_2",
		MD_2_3: "md_2_3",
		MD_2_COMPLETE: "md_2_complete",
	},
	GS: {
		GS_1_1: "gs_1_1",
		GS_1_2: "gs_1_2",
		GS_1_3: "gs_1_3",
		GS_1_COMPLETE: "gs_1_complete",
		GS_2_1: "gs_2_1",
		GS_2_2: "gs_2_2",
		GS_2_3: "gs_2_3",
		GS_2_COMPLETE: "gs_2_complete",
	},
	NU: {
		NU_1_1: "nu_1_1",
		NU_1_2: "nu_1_2",
		NU_1_3: "nu_1_3",
		NU_1_COMPLETE: "nu_1_complete",
		NU_2_1: "nu_2_1",
		NU_2_2: "nu_2_2",
		NU_2_3: "nu_2_3",
		NU_2_COMPLETE: "nu_2_complete",
	},
	OA: {
		OA_1_1: "oa_1_1",
		OA_1_2: "oa_1_2",
		OA_1_3: "oa_1_3",
		OA_1_COMPLETE: "oa_1_complete",
		OA_2_1: "oa_2_1",
		OA_2_2: "oa_2_2",
		OA_2_3: "oa_2_3",
		OA_2_COMPLETE: "oa_2_complete",
	},
};

export const OBSERVATIONAL_ASSESSMENT_QUESTION_TYPE = {
	SCO: "sco_circle",
	COMPETENCY: "competency_circle",
};

export const QUESTION_TYPE_STAR_COUNT = "star_count";
