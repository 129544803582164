import * as d3 from "d3";
import tip from "d3-tip";
import React from "react";
import {renderToStaticMarkup} from "react-dom/server";

import {DOMAIN_ORAL_LANGUAGE} from "../../../../../Scripts/Domain";
import {Tooltip} from "./index";

export class TreeTop {
	static runClean(chart, node?: any) {
		const svg = node ? d3.select(node) : chart;
		const defs = svg.select("defs");
		if (defs.childNodes) {
			defs.childNodes.forEach((def) => {
				if (def.id === "treeOutline") {
					defs.removeChild("#treeOutline");
				}
			});
		}
		const oldSvgBody = svg.select(".svg-body");
		if (oldSvgBody && oldSvgBody.node()) oldSvgBody.remove();
		const oldScale = svg.select("#scale");
		if (oldScale && oldScale.node()) oldScale.remove();
		const oldScaleItems = svg.select("#scale-items");
		if (oldScaleItems && oldScaleItems.node()) oldScaleItems.remove();
	}

	static draw(node, chart) {
		const preData = chart && chart.data ? chart.data : null;
		if (!preData) return null;
		const data = preData.filter((datum) => datum.value !== 0);
		const svg = d3.select(node);
		this.runClean(svg);
		const tooltip = tip()
			.attr("class", "d3-tip")
			.offset([-5, 0])
			.html((d) => {
				return renderToStaticMarkup(<Tooltip data={d.data} />);
			});
		svg.call(tooltip);
		const treeOutline = svg.select("#tree-mask");
		const treeOutlinePath =
			treeOutline && treeOutline.node() ? treeOutline.attr("d") : "";
		const treeOutlineDimensions =
			treeOutline && treeOutline.node()
				? treeOutline.node().getBBox()
				: {width: 0};
		const getBoundingBoxCenter = (selection, node?: any) => {
			const element = selection ? selection.node() : null;
			const bbox = element ? element.getBBox() : node.getBBox();
			return [bbox.x + bbox.width / 2, bbox.y + bbox.height / 2];
		};

		const maskId = `treeOutline-${chart.id}`;
		const oldDefs = svg.select("defs");
		const defs = oldDefs && oldDefs.node() ? oldDefs : svg.append("defs");
		const mask = defs.append("clipPath").attr("id", maskId);
		mask.append("path").attr("fill", "#fff").attr("d", treeOutlinePath);
		const svgBody = svg
			.append("g")
			.attr("class", "svg-body mask-animation")
			.attr("clip-path", `url(#${maskId})`);
		const pie = d3.pie().value((d) => d.value);
		const radius = treeOutlineDimensions.width / 2 + 10;
		const arc = d3.arc().innerRadius(0).outerRadius(radius);
		const arcs = svgBody
			.append("g")
			.attr("class", "pie")
			.attr(
				"transform",
				(d) =>
					`translate(${getBoundingBoxCenter(treeOutline)[0]}, ${
						getBoundingBoxCenter(treeOutline)[1]
					})`
			)
			.selectAll("slices")
			.data(pie(data))
			.enter();
		arcs
			.append("path")
			.attr("d", arc)
			.attr("class", (d, i) => `slice slice${i} slice${d.data.id}`)
			.attr("stroke", "white")
			.style("stroke-width", "5px")
			.on("mouseover", (d, i) => {
				tooltip.show(d, svg.select(`.arc-center-${i}`).node());
			})
			.on("mousemove", function () {
				return tooltip
					.style("top", `${d3.event.pageY - 10}px`)
					.style("left", `${d3.event.pageX + 10}px`);
			})
			.on("mouseout", tooltip.hide)
			.on("click", (d) => {
				if (chart.onSelectOutcome) chart.onSelectOutcome(d.data.id);
			});

		arcs
			.append("circle")
			.attr("class", (d, i) => `arc-center arc-center-${i}`)
			.attr("cx", 0)
			.attr("cy", 0)
			.attr("height", 1)
			.attr("width", 1)
			.attr("transform", (d) => `translate(${arc.centroid(d)})`);
		const leavesOL = [
			"M476.25,475.42a3.69,3.69,0,0,0-5.58.11c-7.3,8.24-26.62,32.43-26.14,56.82.43,21.78,16.33,34.42,30.73,34.13s29.78-13.54,29.35-35.31C504.13,506.77,483.86,483.37,476.25,475.42Z",
			"M271.35,516.26c19.34-14.88,26.12-45.08,28-55.93a3.69,3.69,0,0,0-3.41-4.42c-11-1-41.9-2.15-61.23,12.73-17.26,13.29-18,33.59-9.2,45S254.09,529.55,271.35,516.26Z",
			"M569.56,820.67c-7.83,7.73-28.74,30.56-29.9,54.94-1,21.75,14,35.43,28.36,36.11s30.63-11.5,31.67-33.26c1.16-24.37-17.49-49.08-24.55-57.52A3.69,3.69,0,0,0,569.56,820.67Z",
			"M390.62,847.52c-23.65,6-41.84,31.06-47.85,40.28a3.68,3.68,0,0,0,1.38,5.41c9.68,5.24,37.62,18.57,61.27,12.56,21.11-5.37,29.8-23.72,26.26-37.68S411.73,842.16,390.62,847.52Z",
			"M202.08,725.64a3.68,3.68,0,0,0-5.21,2c-4.05,10.23-14,39.55-5.2,62.32,7.82,20.33,27.08,26.79,40.52,21.61s23.38-22.88,15.56-43.21C239,745.6,212,730.51,202.08,725.64Z",
			"M326.08,616.33c13.44-5.17,23.39-22.88,15.56-43.21-8.77-22.77-35.8-37.86-45.66-42.74a3.71,3.71,0,0,0-5.22,2c-4,10.23-14,39.56-5.2,62.33C293.39,615,312.65,621.5,326.08,616.33Z",
			"M178.8,498.26l-1.05.1a28.86,28.86,0,0,0-10.87-20.1c-11.52-8.64-31.81-7.67-44.87,9.76-14.65,19.52-13.09,50.43-12,61.38a3.68,3.68,0,0,0,4.47,3.35c9.23-1.71,32.5-7.17,48.23-20.86,4.31,4.31,10.87,7.15,19.06,6.54,16.31-1.21,31.23-15.63,36.27-21a2.46,2.46,0,0,0-.27-3.73C212,509.1,195.11,497.05,178.8,498.26Z",
			"M405.3,826.25c22.33,9.85,52.09,1.34,62.5-2.22a3.69,3.69,0,0,0,2.26-5.11c-3.79-8.67-14.51-30.37-31.64-42.55,16-16.44,20.69-44.07,21.91-54.35a3.69,3.69,0,0,0-3.63-4.25c-11-.4-41.95,0-60.49,15.9-16.47,14.09-16.21,34.28-7,45.25a25.12,25.12,0,0,0-3.59,5.83C379.84,797.92,385.37,817.46,405.3,826.25Z",
			"M239.27,600.32c6.74-12.72,2.63-32.61-16.62-42.81-21.56-11.42-51.86-5.07-62.5-2.27a3.7,3.7,0,0,0-2.62,4.94c3.67,10.37,15.42,39,37,50.43C213.76,620.81,232.53,613.05,239.27,600.32Z",
			"M270,711.64a25,25,0,0,0,5.18.81c12.63.6,26.7-8.74,30.64-25.76,4,5.74,9.23,11.26,15.67,14.5,13,6.58,25.51,1.12,29.86-7.5s1.32-21.89-11.72-28.46c-11.05-5.58-25.31-4.26-34.68-2.41-4.78-18.34-17.19-34.63-22.66-41.16a3.69,3.69,0,0,0-5.58-.26c-6.8,6.71-23.48,24.84-28.49,45.48-21-8.21-47.66-2.59-57.5,0a3.68,3.68,0,0,0-2.61,4.93c3.66,10.38,15.42,39,37,50.44,19.24,10.2,38,2.43,44.76-10.29Z",
			"M149,604.51c4.07-8.62.94-21.6-11.9-28.07-14.6-7.37-34.82-2.7-41.92-.68a2.47,2.47,0,0,0-1.68,3.34c2.34,6.23,9.3,22.33,21.36,30.83-7,7.83-10.58,20.08-6.25,34,7.22,23.31,33.17,40.18,42.69,45.71a3.68,3.68,0,0,0,5.33-1.66c4.73-9.93,16.6-38.52,9.38-61.83C162.7,615.66,156.28,608.49,149,604.51Z",
			"M347.63,527.6c10.82-2,40.94-9.16,55.58-28.68,13.07-17.42,8.32-37.17-3.2-45.81-11.35-8.52-31.24-7.68-44.32,9.05a29,29,0,0,0-1.27-4.24c-5.88-15.26-24-25.38-30.61-28.65a2.47,2.47,0,0,0-3.49,1.35c-2.72,6.86-9.37,26.51-3.49,41.78,5.25,13.62,18.16,18,27.16,14.48.32-.12.64-.26,1-.4-3.4,15.28-2.5,30.69-1.79,37.77A3.69,3.69,0,0,0,347.63,527.6Z",
			"M120.35,317.6c8.72,2.63,30.82,8.18,50.63,4q-1.54,1.91-2.9,3.94c-12,18.2-6,37.62,6,45.52s32.22,5.68,44.18-12.52c13.4-20.4,9.92-51.16,8.14-62a3.69,3.69,0,0,0-4.67-3.07,135.9,135.9,0,0,0-21.36,7.31c4.19-8.56,4.19-18,.65-25.33-6.28-13-24.75-21.4-44.35-11.91-22,10.63-34.76,38.82-38.79,49.06A3.68,3.68,0,0,0,120.35,317.6Z",
			"M251.88,425.29c10,4.51,21.47,7.85,32.76,6.92,21.71-1.79,33.33-18.45,32.15-32.8s-15.38-28.88-37.09-27.09c-16.55,1.36-32.07,11.85-42.64,21-8.5-4.09-19.62-4.71-31.14.87-22,10.63-34.75,38.82-38.79,49.06a3.69,3.69,0,0,0,2.44,5c10.53,3.18,40.58,10.63,62.54,0C243.24,442.85,249.6,434.16,251.88,425.29Z",
			"M334.6,789c19.61-9.49,24.44-29.21,18.17-42.17s-24.75-21.4-44.35-11.91c-22,10.63-34.76,38.82-38.79,49.06a3.69,3.69,0,0,0,2.43,5,135.74,135.74,0,0,0,23.84,4.87c-7.58,10.47-14.95,24.12-15.62,38.3-1,21.76,14,35.44,28.36,36.12s30.63-11.51,31.67-33.26c.74-15.64-6.67-31.42-13.95-42.95A48,48,0,0,0,334.6,789Z",
			"M496.54,643.13c4.69-21.27-7.8-37.29-21.86-40.39s-32.13,6.16-36.82,27.43C432.6,654,446.8,681.51,452.33,691a3.69,3.69,0,0,0,5.46,1.2C466.81,685.92,491.28,667,496.54,643.13Z",
			"M583.55,754.66c-7-8.82-20.8-13.26-34.6-8.21,4.25-24-11-50.87-16.91-60.14a3.69,3.69,0,0,0-5.5-1c-8.76,6.65-32.45,26.58-36.75,50.6-3.85,21.44,9.27,37,23.45,39.49a25.81,25.81,0,0,0,7.46.2,127.67,127.67,0,0,0-10,33.71,3.68,3.68,0,0,0,3.49,4.36c11,.77,41.92,1.37,61-13.88C592.2,786.21,592.54,765.9,583.55,754.66Z",
			"M467.64,848.14c-19.06,15.25-25.28,45.57-26.94,56.45a3.68,3.68,0,0,0,3.49,4.36c11,.77,41.93,1.37,61-13.88,17-13.6,17.35-33.91,8.36-45.15S484.65,834.54,467.64,848.14Z",
			"M374.75,693.5c9-6.3,33.49-25.27,38.75-49.09,2.86-12.95-.66-23.95-7-31.27a42.19,42.19,0,0,0,5.14-3.47c19.2-15,25.7-45.32,27.47-56.18a3.69,3.69,0,0,0-3.45-4.4c-11-.87-41.91-1.76-61.11,13.3-17.14,13.44-17.67,33.75-8.78,45.08a24.81,24.81,0,0,0,2.18,2.42c-6.05,4.67-11,11.87-13.11,21.56-5.26,23.83,8.94,51.34,14.48,60.85A3.68,3.68,0,0,0,374.75,693.5Z",
			"M724,344.66c-13.39-5.29-32.71,1-40.72,21.24-9,22.69.68,52.1,4.63,62.37a3.68,3.68,0,0,0,5.19,2.06c9.91-4.79,37.08-19.63,46.06-42.32C747.2,367.76,737.41,350,724,344.66Z",
			"M842.41,369.62A25.9,25.9,0,0,0,836.79,380c-4.51,15.72,3.85,34.71,7.15,41.31a2.47,2.47,0,0,0,3.6,1c6.1-3.71,22.37-14.77,27.5-29.73,4.7,2.15,8.78,3.69,11.55,4.66a3.7,3.7,0,0,0,4.93-2.63c2.76-10.65,9-41-2.48-62.5-10.26-19.21-30.17-23.26-42.87-16.48S825.77,341.19,836,360.4A51.2,51.2,0,0,0,842.41,369.62Z",
			"M266.3,260a3.7,3.7,0,0,0,4.93-2.63c2.76-10.65,9-41-2.48-62.5-10.26-19.21-30.16-23.26-42.86-16.48s-20.41,25.58-10.15,44.79C227.24,244.68,255.92,256.34,266.3,260Z",
			"M494.71,157.25a49.75,49.75,0,0,0-5.49,6.88c-12,18.19-6,37.61,6,45.53s32.22,5.69,44.18-12.51c13.41-20.39,10-51.15,8.17-62a3.7,3.7,0,0,0-4.67-3.07,135.94,135.94,0,0,0-23,8c1.14-12.87.47-28.37-6.22-40.89C503.48,80,483.57,76,470.87,82.73s-20.4,25.58-10.14,44.79C468.11,141.33,482.56,151.08,494.71,157.25Z",
			"M411.13,142.58c17.22-17.29,20-48.12,20.44-59.11a3.7,3.7,0,0,0-4-4c-11,.47-41.81,3.35-59,20.63-15.38,15.42-13.44,35.64-3.24,45.81S395.75,158,411.13,142.58Z",
			"M574.37,137.32c19.2,15.06,50.15,14.16,61.12,13.29a3.67,3.67,0,0,0,3.44-4.39c-1.77-10.86-8.27-41.13-27.47-56.19-17.14-13.44-37-9.12-45.87,2.21S557.23,123.88,574.37,137.32Z",
			"M654.92,366.4a3.69,3.69,0,0,0,3.45-4.4c-1.77-10.86-8.27-41.12-27.47-56.18-17.14-13.44-37-9.12-45.87,2.21s-8.36,31.63,8.78,45.07C613,368.16,644,367.27,654.92,366.4Z",
			"M704.18,489.53c8.37,11.72,28,16.92,45.72,4.26,13.52-9.66,21.47-26.61,25.88-39.85,9.39-.85,19.26-6,26.29-16.71,13.41-20.39,9.95-51.15,8.17-62a3.69,3.69,0,0,0-4.67-3.07c-10.67,2.67-40.29,11.67-53.7,32.06-6.8,10.33-7.81,21.05-5.22,29.84-10.87,1.22-22.46,4.26-31.68,10.84C697.25,457.55,695.81,477.82,704.18,489.53Z",
			"M357.57,207.85c7.69,5.51,28,18.64,49,20.32-5.31,22.32,4.83,48.45,9,57.91a3.7,3.7,0,0,0,5.3,1.78c9.64-5.29,36-21.53,43.79-44.65,6.92-20.55-3.65-37.74-17.2-42.43a25.8,25.8,0,0,0,.1-6.85c-1.76-14.28-16.53-28.23-38.15-25.57-24.22,3-45.41,25.55-52.53,33.94A3.7,3.7,0,0,0,357.57,207.85Z",
			"M617.49,165.3c-21.75,1.11-33.89,17.39-33.16,31.77l0,.36a24.83,24.83,0,0,0-4.86,2c-12.7,6.78-20.4,25.58-10.14,44.79,11.5,21.52,40.17,33.18,50.56,36.81a3.69,3.69,0,0,0,4.93-2.63c2.4-9.26,7.43-33.37,1.16-53.66,22.24-3.72,42.24-22.2,49.37-29.47a3.68,3.68,0,0,0-.29-5.57C666.63,182.62,641.86,164.06,617.49,165.3Z",
			"M792.49,345c14.32,1.51,31.24-9.72,33.54-31.38,2.56-24.26-14.62-50-21.18-58.85a3.69,3.69,0,0,0-5.56-.58c-8.26,7.26-30.46,28.84-33,53.11C764,329,778.17,343.48,792.49,345Z",
			"M869.74,476.77c.94-21.76-14.14-35.38-28.52-36s-30.58,11.64-31.52,33.4c-1.05,24.38,17.7,49,24.8,57.42a3.69,3.69,0,0,0,5.58.24,136.94,136.94,0,0,0,14.59-17.22c.79,9.5,5.64,17.62,12.43,22.08,12,7.91,32.22,5.69,44.18-12.5,13.42-20.39,10-51.15,8.17-62a3.69,3.69,0,0,0-4.67-3.07c-8.83,2.21-30.65,8.76-45.51,22.53C869.5,480,869.67,478.4,869.74,476.77Z",
			"M717.47,217.5c16.2,14.56,36.29,11.58,45.91.88s10.46-31-5.73-45.57c-18.15-16.31-49.08-17.5-60.08-17.37a3.7,3.7,0,0,0-3.74,4.16C694.87,170.55,699.32,201.18,717.47,217.5Z",
			"M605.19,486.07c7.55.8,15.83-1.95,22.31-7.91,5.47,5.19,14.48,7.76,24.47,4,15.28-5.83,25.46-23.91,28.75-30.51a2.47,2.47,0,0,0-1.34-3.5c-6.69-2.68-25.58-9.15-40.7-4-2.16-20.78-15.52-40.7-21.13-48.25a3.7,3.7,0,0,0-5.56-.59c-8.26,7.27-30.46,28.85-33,53.11C576.67,470,590.87,484.56,605.19,486.07Z",
			"M438.34,308.6a3.68,3.68,0,0,0-5.3,1.76c-4.52,10-15.81,38.86-8.12,62,6.87,20.67,25.81,28,39.47,23.49s24.43-21.77,17.56-42.44C474.26,330.27,448,313.93,438.34,308.6Z",
			"M293.05,321.19c10.57,4,24.65.73,33.92-10.68,8.64,22.79,35.52,38,45.34,43a3.68,3.68,0,0,0,5.22-2c4-9.95,13.73-38,6.24-60.54a25.83,25.83,0,0,0,8-8.63c8.26-14.12,4.85-34.59,3.28-41.8a2.48,2.48,0,0,0-3.24-1.89c-7.05,2.17-26.56,9.24-34.81,23.36a28.4,28.4,0,0,0-1.88,3.85,26.92,26.92,0,0,0-12.31,1.47,25.83,25.83,0,0,0-6.52,3.66c-1.41-14.59-6.18-27.86-8.7-34.07a3.69,3.69,0,0,0-5.23-2c-9.82,5-36.7,20.32-45.26,43.17C269.45,298.53,279.57,316.15,293.05,321.19Z",
			"M304.36,203.51c13.48,5.05,32.68-1.6,40.31-22,8.55-22.85-1.65-52.08-5.79-62.27a3.7,3.7,0,0,0-5.24-2c-9.81,5-36.7,20.32-45.25,43.17C280.76,180.85,290.87,198.46,304.36,203.51Z",
			"M509,264.94a3.69,3.69,0,0,0-5.3,1.76c-4.52,10-15.81,38.86-8.12,62,4.18,12.58,12.84,20.22,22.05,23.25a43.31,43.31,0,0,0-2.63,5.61c-8.77,22.77,1.15,52.09,5.2,62.32a3.68,3.68,0,0,0,5.21,2c9.87-4.87,36.9-20,45.67-42.74,7.82-20.32-2.12-38-15.56-43.2a23.48,23.48,0,0,0-3.12-1c2.8-7.12,3.34-15.82.21-25.24C544.91,286.61,518.61,270.27,509,264.94Z",
			"M453.1,424.78c-7.37,12.6-2.69,25.39,5.64,30.26s21.77,2.67,29.13-9.93c8.26-14.13,4.85-34.6,3.28-41.8a2.48,2.48,0,0,0-3.24-1.89C480.86,403.58,461.35,410.65,453.1,424.78Z",
			"M673.73,221c-5.22,5.21-19.15,20.59-19.84,36.93-.52,12.19,6.46,20.55,14.39,23.21,2.75,13.07,15.9,25.39,35.7,24.38,24.37-1.24,47.13-22.22,54.84-30.08a3.68,3.68,0,0,0-.29-5.57c-8.46-7-33.23-25.6-57.6-24.36a41.09,41.09,0,0,0-8.65,1.34c-3.57-11.52-11.3-21.55-14.81-25.69A2.47,2.47,0,0,0,673.73,221Z",
			"M559.58,466c10.48-10.16,9.41-23.73,2.7-30.66s-20.25-8.42-30.74,1.74c-11.74,11.38-14,32-14.38,39.38a2.47,2.47,0,0,0,2.61,2.69C527.14,478.94,547.83,477.37,559.58,466Z",
			"M531.86,579.84a3.68,3.68,0,0,0,5.53.8c8.53-6.95,31.52-27.68,35-51.84,3.11-21.55-10.54-36.6-24.79-38.65S516,498.69,512.9,520.24C509.43,544.4,525.64,570.77,531.86,579.84Z",
			"M769.54,535.42a3.69,3.69,0,0,0-.85-5.52c-9.14-6.13-35.68-22.06-59.8-18.33-21.52,3.33-31.93,20.77-29.73,35s17.39,27.71,38.91,24.39C742.19,567.23,762.68,544,769.54,535.42Z",
			"M872.36,621.82c-13.87,3.87-25.46,20.56-19.6,41.53,6.56,23.51,32,41.1,41.38,46.9a3.69,3.69,0,0,0,5.38-1.51c5-9.8,17.68-38,11.12-61.54C904.78,626.22,886.23,618,872.36,621.82Z",
			"M760.3,807.25c-13.87,3.87-25.46,20.55-19.61,41.53,6.56,23.5,32,41.1,41.39,46.89a3.69,3.69,0,0,0,5.38-1.5c5-9.8,17.67-38,11.11-61.55C792.72,811.64,774.16,803.37,760.3,807.25Z",
			"M717,633.92c7-12.56,3.4-32.54-15.6-43.19C680.15,578.8,649.71,584.44,639,587a3.69,3.69,0,0,0-2.73,4.87c2.94,9,11.55,31.62,27.44,45.37-17.49,14.85-24.79,41.9-27,52a3.68,3.68,0,0,0,3.21,4.57c10.91,1.45,41.76,4,61.73-10.06,17.74-12.46,19.4-32.58,11.26-44.38A25.69,25.69,0,0,0,717,633.92Z",
			"M871,711.43c-18.19-12-37.61-6-45.53,6-.07.1-.13.2-.19.3a24.92,24.92,0,0,0-5.08-1.29c-14.25-2-31.58,8.53-34.69,30.09-3.47,24.16,12.74,50.53,19,59.6a3.69,3.69,0,0,0,5.53.8c7.42-6,25.75-22.5,32.7-42.56,20.13,10.16,47.19,7.11,57.23,5.47a3.69,3.69,0,0,0,3.08-4.66C900.3,754.48,891.32,724.85,871,711.43Z",
			"M789.17,690.89c22.88-8.49,38.3-35.34,43.29-45.14a3.7,3.7,0,0,0-1.94-5.24,135.84,135.84,0,0,0-23.26-7.12c8.54-9.7,17.17-22.59,19.19-36.64,3.1-21.56-10.54-36.61-24.79-38.66s-31.59,8.54-34.69,30.1c-2.23,15.5,3.64,31.9,9.79,44.07a50.53,50.53,0,0,0-8.5,2.29c-20.42,7.58-27.11,26.76-22.1,40.26S768.76,698.47,789.17,690.89Z",
			"M641.73,720.87a3.69,3.69,0,0,0-5.31-1.72c-9.59,5.41-35.75,22-43.25,45.18-6.7,20.73,4.2,37.86,17.9,42.29s32.58-3.08,39.28-23.8C657.85,759.6,646.33,730.87,641.73,720.87Z",
			"M561.94,718a3.69,3.69,0,0,0,5.38,1.51c9.36-5.79,34.84-23.37,41.41-46.88,5.86-21-5.72-37.66-19.58-41.53a25.77,25.77,0,0,0-7.42-.92c7.5-12.59,11.51-26.11,13.15-32.6a3.69,3.69,0,0,0-3-4.68c-10.86-1.81-41.61-5.35-62,8C511.57,612.79,509.3,633,517.18,645c6.18,9.45,19.43,15.18,33.65,11.47C544.31,680,557,708.17,561.94,718Z",
			"M641.46,563c20.43-13.36,29.5-43,32.2-53.62a3.69,3.69,0,0,0-3.06-4.67c-10.86-1.81-41.61-5.36-62,8-18.23,11.92-20.5,32.11-12.62,44.16S623.24,574.92,641.46,563Z",
			"M733.13,789.46a54.1,54.1,0,0,0,2.45-15.39,27.63,27.63,0,0,0,5.71-1.51c15.28-5.83,25.46-23.91,28.75-30.51a2.47,2.47,0,0,0-1.33-3.5c-6.76-2.7-26-9.27-41.15-3.85-1.14-2.92-2.21-5.37-3-7.19a3.68,3.68,0,0,0-5.31-1.72c-9.58,5.41-35.74,22-43.25,45.18-4.08,12.62-1.62,23.9,4,31.8a41.44,41.44,0,0,0-5.44,3C654,818.88,644.61,848.39,641.81,859a3.68,3.68,0,0,0,3,4.7c10.83,1.91,41.55,5.75,62.1-7.41,18.34-11.75,20.8-31.91,13-44a25.78,25.78,0,0,0-2-2.62C724.49,805.59,730.08,798.9,733.13,789.46Z",
			"M922.29,548a2.46,2.46,0,0,0-3.23-1.89c-7,2.16-26.56,9.23-34.82,23.35-7.37,12.61-2.69,25.39,5.64,30.26s21.77,2.68,29.14-9.93C927.27,575.65,923.87,555.18,922.29,548Z",
		];
		const leavesMath = [
			"M457.47,472.26c-5-1.68-.77-6.25-6.41-9.76-5-3.11-11.77,10.2-15.61,19.09a18.71,18.71,0,0,0,0,14.89c3.84,8.89,10.61,22.19,15.61,19.09,5.64-3.51,1.37-8.09,6.41-9.76,7.77-2.6,11.89,22.56,27.14,15.85,6.72-2.95-1.53-9.45,4-12.5,4.53-2.51,14.59,11.64,23.18,3.05,5.19-5.18-1.83-9.76,1.83-12.5C518.07,496.34,527,498.33,527,489s-8.93-7.31-13.42-10.67c-3.66-2.75,3.36-7.32-1.83-12.51-8.59-8.58-18.65,5.57-23.18,3-5.49-3,2.76-9.54-4-12.5C469.36,449.69,465.24,474.85,457.47,472.26Z",
			"M252.18,316.19c-16.65-.56-11.12,24.33-19.3,24.81-5.3.31-3-5.52-9.57-6.68-5.8-1-7.14,13.84-7.41,23.52a18.75,18.75,0,0,0,5.54,13.83c6.87,6.82,18.1,16.66,21.59,11.92,3.93-5.36-1.73-8,2.32-11.44,6.25-5.3,19.42,16.53,31.09,4.63,5.14-5.24-4.93-8.21-1-13.08,3.27-4,17.87,5.38,22.65-5.78,2.89-6.74-5.32-8.38-2.94-12.29,2.91-4.79,11.94-6.26,8.49-14.89s-11-3.47-16.43-4.93c-4.42-1.19.4-8-6.34-10.93-11.17-4.78-15.25,12.1-20.39,11.45C254.28,325.54,259.53,316.44,252.18,316.19Z",
			"M622.67,577.74c-3.46-8.64-11-3.47-16.43-4.93-4.42-1.19.4-8-6.34-10.93-11.17-4.78-15.25,12.1-20.39,11.45-6.23-.79-1-9.89-8.33-10.14-16.65-.56-11.12,24.33-19.3,24.81-5.3.31-3-5.52-9.57-6.68-5.8-1-7.14,13.84-7.41,23.52a18.75,18.75,0,0,0,5.54,13.83c6.87,6.82,18.1,16.66,21.59,11.92,3.93-5.36-1.73-8,2.32-11.44,6.25-5.3,19.42,16.53,31.09,4.63,5.14-5.24-4.93-8.21-1-13.08,3.27-4,17.87,5.38,22.65-5.78,2.89-6.74-5.32-8.38-2.94-12.29C617.09,587.84,626.12,586.37,622.67,577.74Z",
			"M922.22,318.53c-8.12-4.54-10.74,4.23-15.87,6.51-4.18,1.86-4.75-6.5-11.81-4.5-11.68,3.31-4.24,19-8.64,21.72-5.34,3.3-7-7.07-12.85-2.64-13.3,10,6.66,25.91.6,31.43-3.92,3.57-5.83-2.38-11.64.83-5.15,2.85,3.16,15.25,9,22.94a18.76,18.76,0,0,0,13,7.27c9.63,1,24.55,1.56,24.27-4.32-.31-6.64-6.38-5.14-5.39-10.36,1.53-8.05,25.5.63,27.08-16,.7-7.32-9-3.28-9-9.56,0-5.18,17.28-7.06,14-18.75-2-7.06-9.41-3.16-10-7.7C924.24,329.9,930.33,323.07,922.22,318.53Z",
			"M837.53,693.36c-4-2.15,2.18-7.75-3.74-12.07-9.82-7.16-17.57,8.38-22.43,6.6-5.9-2.16,1.25-9.86-5.85-11.74-16.11-4.27-16.28,21.23-24.36,19.87-5.23-.88-1.72-6.06-7.84-8.65-5.42-2.3-10,11.9-12.47,21.27a18.77,18.77,0,0,0,2.3,14.72c5.17,8.18,13.92,20.28,18.38,16.44,5-4.34.11-8.2,4.82-10.64,7.28-3.76,15.24,20.46,29.27,11.47,6.18-4-3-9.11,2-13,4.08-3.18,16.22,9.24,23.37-.57,4.32-5.93-3.32-9.36-.13-12.64,3.91-4,13-3.43,11.61-12.62S842.48,696,837.53,693.36Z",
			"M727.44,501.83c-1.44-9.19-10-5.84-14.91-8.47-4-2.15,2.18-7.75-3.74-12.07-9.82-7.16-17.57,8.38-22.43,6.6-5.9-2.16,1.25-9.86-5.85-11.74-16.11-4.27-16.28,21.23-24.36,19.87-5.23-.88-1.72-6.06-7.84-8.65-3.65-1.55-6.95,4.39-9.45,11.3a4.5,4.5,0,0,1-5.1-1.3c-7.07-7.05,18.09-25.26,2.47-38.21-6.89-5.71-9.47,6.82-15.78,2.5-5.21-3.55,4.72-22.23-9.32-26.91-8.47-2.82-9.63,7.32-14.62,4.83-6.11-3.05-8.81-13.87-18.94-8.8s-3.09,13.72-4.31,20.44c-1,5.48-9.8.34-12.62,8.81-4.67,14,16.23,17.29,16,23.59-.33,7.64-11.9,2.2-11.46,11.13,1,20.27,30.66,11,32.08,20.92.91,6.39-6.4,4.24-7.14,12.3-.66,7.14,17.53,7.26,29.3,6.6a22.87,22.87,0,0,0,16.22-8.13c.82-1,1.67-2,2.54-3.09h0c5.17,8.18,13.92,20.28,18.38,16.44,5-4.34.11-8.2,4.82-10.64,7.28-3.76,15.24,20.46,29.27,11.47,6.18-4-3-9.11,2-13,4.08-3.18,16.22,9.24,23.37-.57,4.32-5.93-3.32-9.36-.13-12.64C719.74,510.43,728.88,511,727.44,501.83Z",
			"M764.4,465.14c3.6-2,11.61,9.26,18.44,2.43,4.12-4.13-1.46-7.76,1.46-9.95,3.56-2.67,10.67-1.09,10.67-8.49s-7.11-5.82-10.67-8.49c-2.92-2.19,2.66-5.83-1.46-9.95-6.83-6.83-14.84,4.42-18.44,2.42-4.37-2.42,2.19-7.59-3.15-9.94-12.14-5.34-15.41,14.68-21.6,12.61-4-1.33-.61-5-5.1-7.76-4-2.47-9.36,8.11-12.42,15.19a15,15,0,0,0,0,11.84c3.06,7.08,8.45,17.66,12.42,15.19,4.49-2.79,1.1-6.43,5.1-7.77,6.19-2.06,9.46,18,21.6,12.62C766.59,472.74,760,467.57,764.4,465.14Z",
			"M222.55,292.24c4.49-2.79,1.1-6.43,5.1-7.77,6.19-2.06,9.46,18,21.6,12.62,5.34-2.35-1.22-7.52,3.15-9.95,3.6-2,11.61,9.26,18.44,2.43,4.12-4.13-1.46-7.76,1.46-9.95,3.56-2.67,10.67-1.09,10.67-8.49s-7.11-5.82-10.67-8.49c-2.92-2.19,2.66-5.83-1.46-9.95-6.83-6.83-14.84,4.42-18.44,2.42-4.37-2.42,2.19-7.59-3.15-9.94-12.14-5.34-15.41,14.68-21.6,12.61-4-1.33-.61-5-5.1-7.76-4-2.47-9.36,8.11-12.42,15.19a15,15,0,0,0,0,11.84C213.19,284.13,218.58,294.71,222.55,292.24Z",
			"M207.87,495.94c-2,4.25,9.21,8.29,16.61,10.45a14.89,14.89,0,0,0,11.75-1.46c6.64-3.9,16.49-10.56,13.54-14.2-3.32-4.11-6.51-.29-8.33-4.1-2.81-5.88,16.65-11.6,9.86-23-3-5-7.32,2.13-10.27-1.91-2.42-3.32,7.76-12.66.14-18.6-4.6-3.58-7.53,2.41-10-.21-3.1-3.22-2.4-10.46-9.75-9.55s-4.89,7.77-7.11,11.64c-1.81,3.16-6.11-1.93-9.69,2.67-5.94,7.62,6.22,14.18,4.68,18-1.87,4.64-7.81-1.24-9.48,4.36-3.8,12.7,16.46,13.48,15.18,19.87C214.12,494.06,210.09,491.14,207.87,495.94Z",
			"M455.26,307.57c-4.07,2.32,2.66,12.11,7.42,18.17a15,15,0,0,0,10.42,5.65c7.67.68,19.54,1,19.27-3.68-.31-5.28-5.13-4-4.4-8.18,1.14-6.42,20.3.24,21.39-13,.48-5.82-7.19-2.52-7.24-7.52,0-4.11,13.67-5.78,10.93-15.05-1.66-5.59-7.52-2.42-8-6-.65-4.41,4.13-9.91-2.38-13.43s-8.5,3.47-12.55,5.33c-3.31,1.52-3.85-5.12-9.44-3.46-9.27,2.74-3.19,15.15-6.66,17.36-4.22,2.68-5.63-5.55-10.25-2-10.48,8.12,5.55,20.54.79,25C461.43,309.68,459.85,305,455.26,307.57Z",
			"M494,366.6c-6.2,4.59-15.28,12.24-12,15.55,3.74,3.74,6.51-.4,8.72,3.2,3.41,5.55-15.34,13.29-7.38,23.9,3.5,4.67,7-2.89,10.4.81,2.77,3.05-6.38,13.41,1.83,18.51,4.95,3.08,7.23-3.18,10-.84,3.42,2.86,3.49,10.15,10.7,8.46s4-8.24,5.85-12.32c1.46-3.33,6.27,1.28,9.35-3.68,5.11-8.2-7.68-13.45-6.55-17.41,1.37-4.8,7.9.42,9-5.33,2.45-13-17.79-11.67-17.19-18.16.39-4.2,4.71-1.72,6.41-6.73,1.5-4.43-10-7.28-17.61-8.65A14.94,14.94,0,0,0,494,366.6Z",
			"M386,158.92c-.83,4.14-4.86,1.22-7.08,6-2,4.25,9.21,8.29,16.61,10.45a14.89,14.89,0,0,0,11.75-1.46c6.64-3.9,16.49-10.56,13.54-14.2-3.32-4.11-6.51-.29-8.33-4.1-2.81-5.88,16.65-11.6,9.86-23-3-5-7.32,2.13-10.27-1.91-2.42-3.32,7.76-12.66.14-18.6-4.6-3.58-7.53,2.41-10.05-.21-3.1-3.22-2.4-10.46-9.75-9.55s-4.89,7.77-7.11,11.64c-1.81,3.16-6.11-1.93-9.69,2.67-5.94,7.62,6.22,14.18,4.68,18-1.87,4.64-7.81-1.24-9.48,4.36C367,151.75,387.23,152.53,386,158.92Z",
			"M267.47,220.1c-.57,4.42-6.66,8.41-1.35,13.57s9.12-1,13.53-1.73c3.59-.56,2.31,6,8.14,5.9,9.66-.14,7.18-13.73,11.12-14.91,3.68-1.11,4,3.53,6.43,4.75.8,2.17,2.07,3.52,4,3.55,8.09.14,6.74-7.36,13-5.76,9.67,2.48-2.73,31,17.31,34.16,8.83,1.41,4.68-10.69,12.31-10.18,6.29.41,7.25,21.54,21.71,18.43,8.73-1.89,4.57-11.2,10.13-11.59,6.81-.49,14.64,7.45,20.78-2.07s-4.31-13.38-6.68-19.79c-1.94-5.22,8.27-5.27,6.38-14-3.12-14.46-22.76-6.63-25.74-12.19-3.6-6.74,9.13-8,4.2-15.41-11.17-16.94-32,6.09-38.25-1.69-4-5,3.35-6.91-.11-14.22-2.66-5.62-14.8.49-24.38,6.49a14.9,14.9,0,0,0-7.35-6.08c-7.2-2.74-18.54-6.26-19.55-1.68-1.13,5.16,3.85,5.26,2,9.06-2.84,5.88-19.47-5.73-24.11,6.69-2,5.47,6.24,4.37,4.94,9.19-1.08,4-14.74,1.87-14.6,11.53C261.44,218,267.94,216.49,267.47,220.1Z",
			"M614.4,788.63c-3.5-1,.37-6.39-5-8.74-8.85-3.87-12.22,9.53-16.3,9-5-.67-.72-7.87-6.55-8.12-13.25-.56-9,19.28-15.53,19.61-4.22.21-2.37-4.41-7.57-5.38-4.61-.86-5.78,11-6.05,18.66a14.91,14.91,0,0,0,4.31,11c5.42,5.48,14.29,13.38,17.09,9.63,3.17-4.23-1.32-6.39,1.93-9.09,5-4.17,15.34,13.29,24.7,3.9,4.13-4.14-3.86-6.56-.68-10.41,2.63-3.18,14.18,4.4,18.06-4.45,2.34-5.34-4.18-6.7-2.26-9.8,2.35-3.79,9.55-4.9,6.86-11.79S618.7,789.83,614.4,788.63Z",
			"M422.74,250.67c5.42,5.48,14.29,13.38,17.09,9.63,3.17-4.23-1.32-6.39,1.93-9.09,5-4.17,15.34,13.29,24.7,3.9,4.13-4.14-3.86-6.56-.68-10.41,2.63-3.18,14.18,4.4,18.06-4.45,2.34-5.34-4.18-6.7-2.26-9.8,2.35-3.79,9.55-4.9,6.86-11.79s-8.74-2.83-13-4c-3.5-1,.37-6.39-5-8.73-8.85-3.88-12.22,9.52-16.3,9-4.95-.67-.72-7.87-6.55-8.12-13.25-.56-9,19.28-15.53,19.61-4.22.21-2.37-4.41-7.57-5.38-4.61-.86-5.78,11-6.05,18.66A14.91,14.91,0,0,0,422.74,250.67Z",
			"M642.68,110.6c-.77,4.94-7.89.57-8.25,6.4-.81,13.23,19.1,9.39,19.31,15.9.13,4.22-4.46,2.29-5.53,7.47-.94,4.59,10.85,6,18.55,6.41a14.94,14.94,0,0,0,11.11-4.1c5.58-5.31,13.65-14,10-16.9-4.17-3.25-6.41,1.19-9.05-2.1-4.07-5.09,13.58-15.09,4.38-24.63-4.06-4.2-6.64,3.74-10.43.48-3.12-2.68,4.68-14.09-4.09-18.14-5.3-2.45-6.79,4-9.84,2.07-3.75-2.42-4.72-9.64-11.66-7.08s-3,8.68-4.28,13c-1,3.49-6.39-.49-8.83,4.81C630,102.91,643.31,106.53,642.68,110.6Z",
			"M358.83,849.78c-4.17-3.25-6.41,1.19-9.05-2.1-4.07-5.09,13.58-15.09,4.38-24.63-4.06-4.2-6.64,3.74-10.43.48-3.12-2.68,4.68-14.09-4.09-18.14-5.3-2.45-6.79,4.05-9.84,2.07-3.75-2.42-4.72-9.64-11.66-7.08s-3,8.68-4.28,13c-1,3.49-6.39-.49-8.83,4.81-4,8.77,9.28,12.39,8.65,16.46-.77,4.94-7.89.57-8.25,6.4-.81,13.23,19.1,9.39,19.31,15.9.13,4.22-4.46,2.29-5.53,7.47-.94,4.59,10.85,6,18.55,6.41a14.94,14.94,0,0,0,11.11-4.1C354.45,861.37,362.52,852.65,358.83,849.78Z",
			"M796.21,546.37c-.94,4.59,10.85,6,18.55,6.41a14.94,14.94,0,0,0,11.11-4.1c5.58-5.31,13.65-14,10-16.9-4.17-3.25-6.41,1.19-9.05-2.1-4.07-5.09,13.58-15.09,4.38-24.63-4.06-4.2-6.64,3.74-10.43.48-3.12-2.68,4.68-14.09-4.09-18.14-5.3-2.45-6.79,4.05-9.84,2.07-3.75-2.42-4.72-9.64-11.66-7.08s-3,8.68-4.28,12.95c-1,3.49-6.39-.49-8.83,4.81-4,8.77,9.28,12.39,8.65,16.46-.77,4.94-7.89.57-8.25,6.4-.81,13.23,19.1,9.39,19.31,15.9C801.87,543.12,797.28,541.19,796.21,546.37Z",
			"M557.63,160.68c-4,2.47,3.1,12,8.08,17.88a14.87,14.87,0,0,0,10.61,5.27c7.69.41,19.57.29,19.12-4.37-.5-5.26-5.27-3.84-4.68-8,.9-6.45,20.29-.48,20.9-13.72.27-5.84-7.27-2.26-7.5-7.25-.19-4.12,13.45-6.28,10.37-15.44-1.86-5.53-7.6-2.15-8.26-5.73-.81-4.39,3.77-10.05-2.86-13.34s-8.37,3.78-12.35,5.79c-3.25,1.63-4-5-9.56-3.13-9.16,3.09-2.64,15.27-6,17.6-4.12,2.83-5.83-5.34-10.31-1.6-10.18,8.49,6.29,20.33,1.69,24.95C563.88,162.57,562.13,157.9,557.63,160.68Z",
			"M288.58,771.36c-3.66-2.75,3.36-7.32-1.83-12.51-8.59-8.58-18.65,5.57-23.18,3-5.49-3,2.76-9.54-4-12.5-15.25-6.71-19.37,18.45-27.14,15.86-5-1.68-.77-6.25-6.41-9.76-5-3.11-11.77,10.2-15.61,19.09a18.71,18.71,0,0,0,0,14.89c3.84,8.89,10.61,22.19,15.61,19.09,5.64-3.51,1.37-8.09,6.41-9.76,7.77-2.6,11.89,22.56,27.14,15.85,6.72-2.95-1.53-9.45,4-12.5,4.53-2.51,14.59,11.64,23.18,3.05,5.19-5.18-1.83-9.76,1.83-12.5C293.07,789.34,302,791.33,302,782S293.07,774.72,288.58,771.36Z",
			"M752.18,263.23c-13.39,9.92,6.41,26,.3,31.44-4,3.54-5.8-2.44-11.65.72-5.18,2.8,3,15.28,8.83,23a18.28,18.28,0,0,0,2.56,2.78,22.94,22.94,0,0,0-2.57,9.58c-.5,11.79-.13,30,7,29.21,8.05-.85,5.79-8.13,12.2-7.3,9.9,1.28,1.07,31.06,21.35,31.79,8.94.32,3.34-11.18,11-11.61,6.29-.35,9.82,20.5,23.79,15.64,8.44-2.93,3.17-11.67,8.64-12.74,6.71-1.31,15.44,5.62,20.38-4.58s-5.92-12.76-9-18.82c-2.56-5,7.56-6.25,4.63-14.68-4.86-14-23.41-3.8-27-9-4.4-6.25,8.09-9,2.29-15.81-13.16-15.45-31,9.95-38.17,3-3-2.89-1.11-5.06-.54-8,7.43-1.74,21.42.3,22.74-12.24.76-7.3-9-3.36-8.89-9.64.06-5.18,17.34-6.9,14.15-18.61-1.93-7.08-9.39-3.26-10-7.8-.7-5.56,5.46-12.33-2.61-16.94s-10.78,4.13-15.93,6.35c-4.2,1.82-4.69-6.54-11.76-4.61-11.72,3.19-4.43,18.95-8.85,21.64C759.63,269.25,758.09,258.86,752.18,263.23Z",
			"M266.74,458.06c2.45,8.59,11.47,3.84,12.23,9.36.92,6.77-6.49,15.09,3.41,20.6s13.07-5.17,19.31-7.95c5.09-2.27,5.8,7.9,14.39,5.46,7.76-2.21,8.58-8.9,8.59-15.06,6.17-2,9.46,17.95,21.58,12.62,5.34-2.35-1.22-7.52,3.15-9.95,3.6-2,11.61,9.26,18.44,2.43,4.12-4.13-1.46-7.76,1.46-9.95,3.56-2.67,10.67-1.09,10.67-8.49s-7.11-5.82-10.67-8.49c-2.92-2.19,2.66-5.83-1.46-9.95-6.83-6.83-14.84,4.42-18.44,2.42-4.37-2.42,2.19-7.59-3.15-9.94a8.83,8.83,0,0,0-5.72-.7c-.82-2.69-.83-4.94.89-6.51,4.77-4.37,7.11,2.89,14.18-1,6.27-3.48-3.88-18.57-11.06-27.93a22.87,22.87,0,0,0-15.85-8.82c-11.74-1.17-29.91-1.85-29.57,5.31.4,8.09,7.79,6.25,6.6,12.6-1.85,9.81-31.07-.71-33,19.49-.84,8.91,11,4,11,11.63C283.71,441.51,262.69,443.84,266.74,458.06Z",
			"M122.11,392.91c3.41,5.31-13,18.64-1.91,28.42,6.71,5.9,11.72-3,15.34,1.25,4.44,5.19,2.71,16.21,14,15.49s8.19-11.43,11.93-17.14c3.06-4.66,9.16,3.51,15.06-3.2,9.78-11.1-8.21-22.24-5.5-27.93,3.28-6.91,11.82,2.61,14.89-5.79,7-19.06-23.93-22.12-21.38-31.77,1.65-6.25,7.54-1.41,11.37-8.54,3.39-6.32-13.31-13.52-24.41-17.5a22.89,22.89,0,0,0-18.11,1.15c-10.51,5.36-26.16,14.62-22,20.46,4.7,6.59,9.93,1,12.36,7,3.75,9.25-26.51,16.21-17.17,34.22C110.72,397,118,386.48,122.11,392.91Z",
			"M723.11,188.91c3.41,5.31-13,18.64-1.91,28.42,6.71,5.9,11.72-3,15.34,1.25,4.44,5.19,2.71,16.21,14,15.49s8.19-11.43,11.93-17.14c3.06-4.66,9.16,3.51,15.06-3.2,9.78-11.1-8.21-22.24-5.5-27.93,3.28-6.91,11.82,2.61,14.89-5.79,7-19.06-23.93-22.12-21.38-31.77,1.65-6.25,7.54-1.41,11.37-8.54,3.39-6.32-13.31-13.52-24.41-17.5a22.89,22.89,0,0,0-18.11,1.15c-10.51,5.36-26.16,14.62-22,20.46,4.7,6.59,9.93,1,12.36,7,3.75,9.25-26.51,16.21-17.17,34.22C711.72,193,719,182.48,723.11,188.91Z",
			"M685.33,327a24.9,24.9,0,0,0-4.21-3.11c-11.25-6.63-29.2-15.73-32.17-8.37-3.36,8.3,4.93,9.89.78,15.72-6.4,9-30.88-15.12-42.15,4.31-5,8.56,9.18,9.08,5.63,16.76-2.93,6.34-25.14-1.07-27.66,15.11-1.52,9.76,9.75,9.17,8,15.06-2.21,7.23-13.52,12.16-6.12,22.29s15.53.86,23.09.95c6.16.08,2.17,10.64,11.93,12.16,16.18,2.52,15.88-20.89,22.81-21.75,8.4-1,4.58,12.59,14.25,10.46,10-2.2,12.22-9.8,12.66-17.72a4.39,4.39,0,0,1,5,2.08c3.41,5.31-13,18.64-1.91,28.42,6.71,5.9,11.72-3,15.34,1.25,4.44,5.19,2.71,16.21,14,15.49s8.19-11.43,11.93-17.14c3.06-4.66,9.16,3.51,15.06-3.2,9.78-11.1-8.21-22.24-5.5-27.93,3.28-6.91,11.82,2.61,14.89-5.79,7-19.06-23.93-22.12-21.38-31.77,1.65-6.25,7.54-1.41,11.37-8.54,3.39-6.32-13.31-13.52-24.41-17.5a22.89,22.89,0,0,0-18.11,1.15C687.42,325.85,686.39,326.39,685.33,327Z",
			"M580.19,729.93c5.34-13.79-15.39-18-14.81-24.32.69-7.61,12-1.62,12-10.57,0-20.29-30.09-12.49-31-22.43-.61-6.43,6.59-3.93,7.72-11.94,1-7.1-17.16-8.09-28.95-8A22.83,22.83,0,0,0,508.49,660c-8,8.66-19.5,22.76-13.57,26.8,6.68,4.56,9.67-2.45,14,2.33,6.72,7.38-19.28,24.37-4.29,38.05,6.6,6,9.78-6.36,15.88-1.74,5,3.8-5.78,22,8,27.32,8.33,3.22,10-6.85,14.83-4.12,6,3.34,8.14,14.27,18.5,9.69s3.74-13.55,5.28-20.21C568.42,732.7,577,738.26,580.19,729.93Z",
			"M211.74,153.8c-14.77-.86-12.47,20.17-18.62,21.55-7.46,1.67-5.22-10.92-13.73-8.17-19.31,6.25-2.68,32.47-11.85,36.41-5.94,2.55-5.76-5.07-13.73-3.69-7.07,1.22-2.45,18.81,1.25,30a22.84,22.84,0,0,0,12.06,13.55c10.7,5,27.64,11.6,29.67,4.72,2.29-7.76-5.3-8.46-2.08-14.07,5-8.65,29.1,10.9,37.54-7.55,3.72-8.14-9.05-7.37-6.52-14.59,2.08-5.95,22.7-1.23,23.55-16,.52-8.91-9.57-7.39-8.46-12.86,1.36-6.69,11.1-12.11,3.56-20.58s-14,.59-20.85,1.16C218,164.16,220.65,154.32,211.74,153.8Z",
			"M341.75,318.72c.45,12.14,17.45,8.62,19.06,13.54,1.94,6-8.52,5.12-5.6,11.86,6.6,15.29,26.74-.34,30.68,6.85,2.54,4.65-3.7,5.1-1.95,11.5,1.26,4.61,10.71,2.1,19-1.09a4.89,4.89,0,0,1,1.88,2.46c3.75,9.25-26.51,16.21-17.17,34.22,4.11,7.95,11.37-2.58,15.5,3.85,3.41,5.31-13,18.64-1.91,28.42,6.71,5.9,11.72-3,15.34,1.25,4.44,5.19,2.71,16.21,14,15.49s8.19-11.43,11.93-17.14c3.06-4.66,9.16,3.51,15.06-3.2,9.78-11.1-8.21-22.24-5.5-27.93,3.28-6.91,11.82,2.61,14.89-5.79,7-19.06-23.93-22.12-21.38-31.77,1.65-6.25,7.54-1.41,11.37-8.54,3.39-6.32-13.31-13.52-24.41-17.5a22.83,22.83,0,0,0-10-1.22c.92-5.37.67-9.79-2.44-10.39-6.52-1.27-6.5,5-11.33,2.79-7.46-3.39,6.64-24.63-9.1-30.09-6.94-2.41-5.32,8-11.41,6.47-5-1.24-2.78-18.46-14.91-18-7.33.27-5.3,8.4-9.85,7.92-5.58-.59-10.77-8.12-17.1-1.31s1.57,11.44,2.57,17C349.73,312.82,341.47,311.4,341.75,318.72Z",
			"M466.9,834.22c2.84-5.63,22.67,1.75,25.45-12.78,1.68-8.77-8.52-8.58-6.71-13.85,2.22-6.46,12.59-10.56,6.23-19.94s-14-1.26-20.82-1.58c-5.57-.26-1.63-9.67-10.4-11.35-14.53-2.79-15,18.36-21.28,18.92-3.88.35-4.78-2.64-5.89-5.45,5.55-6.81,11.69-14.89,4.39-23.93-5.62-7-10.63,4.81-16-.67-4.4-4.52,9-20.87-3.81-28.22-7.75-4.44-10.89,5.26-15.28,1.83-5.39-4.2-5.9-15.33-16.83-12.37s-5.75,12.84-8.27,19.18c-2.06,5.18-9.67-1.61-14.11,6.14-7.36,12.84,12.49,20.16,11,26.28-1.83,7.42-12.1-.2-13.43,8.65-3,20.06,27.87,16.88,27.3,26.85-.37,6.45-7.11,2.89-9.43,10.64-1.33,4.43,5.61,7.55,13.84,9.65.42,3.67,1,7.35,1.63,10.54a22.89,22.89,0,0,0,10.19,15c9.95,6.33,25.87,15.11,28.79,8.56,3.29-7.4-4.14-9.09-.22-14.22,6.07-7.93,27.42,14.62,38.21-2.57C476.21,842,463.45,841.05,466.9,834.22Z",
			"M386.59,601.26c.33-8.09-7.2-6.92-5.45-13.13,2.7-9.61,30.88,3.44,34.55-16.52,1.61-8.8-10.58-4.93-9.9-12.55.56-6.27,21.71-6.74,18.93-21.27C423,529,413.63,533,413.37,527.39c-.33-6.82,7.79-14.46-1.59-20.82s-13.47,4-19.93,6.22c-5.27,1.81-5.08-8.39-13.86-6.71-14.53,2.79-7.15,22.61-12.77,25.45-6.83,3.45-7.74-9.31-15.32-4.55-17.19,10.78,5.35,32.14-2.58,38.2-5.13,3.93-6.82-3.5-14.21-.22-6.56,2.92,2.22,18.84,8.56,28.8a22.83,22.83,0,0,0,15,10.18C368.27,606.15,386.31,608.42,386.59,601.26Z",
			"M804.59,848.26c.33-8.09-7.2-6.92-5.45-13.13,2.7-9.61,30.88,3.44,34.55-16.52,1.61-8.8-10.58-4.93-9.9-12.55.56-6.27,21.71-6.74,18.93-21.27C841,776,831.63,780,831.37,774.39c-.33-6.82,7.79-14.46-1.59-20.82s-13.47,4-19.93,6.22c-5.27,1.81-5.08-8.39-13.86-6.71-14.53,2.79-7.15,22.61-12.77,25.45-6.83,3.45-7.74-9.31-15.32-4.55-17.19,10.78,5.35,32.14-2.58,38.2-5.13,3.93-6.82-3.5-14.21-.22-6.56,2.92,2.22,18.84,8.56,28.8a22.83,22.83,0,0,0,15,10.18C786.27,853.15,804.31,855.42,804.59,848.26Z",
			"M843.18,456.84c-7.16.28-4.89,18.33-2.69,29.92a22.89,22.89,0,0,0,10.19,15c10,6.33,25.87,15.11,28.79,8.56,3.29-7.4-4.14-9.09-.22-14.22,6.07-7.93,27.42,14.62,38.21-2.57,4.75-7.58-8-8.49-4.56-15.32,2.84-5.63,22.67,1.75,25.45-12.78,1.68-8.77-8.52-8.58-6.71-13.85,2.22-6.46,12.59-10.56,6.23-19.94s-14-1.26-20.82-1.58c-5.57-.26-1.63-9.67-10.4-11.35-14.53-2.79-15,18.36-21.28,18.92-7.62.68-3.75-11.51-12.55-9.89-20,3.66-6.9,31.84-16.51,34.55C850.09,464.05,851.27,456.52,843.18,456.84Z",
			"M744.88,869c-6.9-4.23-9.55,2.91-14.12-1.66-7.07-7,18.09-25.26,2.47-38.21-6.89-5.71-9.47,6.82-15.78,2.5-5.21-3.55,4.72-22.23-9.32-26.91-8.47-2.82-9.63,7.32-14.62,4.83-6.11-3.05-8.81-13.87-18.94-8.8s-3.09,13.72-4.31,20.44c-1,5.48-9.8.34-12.62,8.81-4.67,14,16.23,17.29,16,23.59-.33,7.64-11.9,2.2-11.46,11.13,1,20.27,30.66,11,32.08,20.92.91,6.39-6.4,4.24-7.14,12.3-.66,7.14,17.53,7.26,29.3,6.6a22.87,22.87,0,0,0,16.22-8.13C740.19,887.41,751,872.78,744.88,869Z",
			"M658.11,753.85c14.47,14.23,30-12.64,37.77-6.33,5,4.09-1.84,7.45,3.07,13.88,4.35,5.7,17.82-6.52,26-15a22.86,22.86,0,0,0,6.43-17c-.53-11.79-2.49-29.87-9.53-28.49-7.94,1.56-5.06,8.61-11.51,8.35-10-.41-3.79-30.84-24.06-29.79-8.93.46-2.35,11.42-9.92,12.52-5,.72-9.38-12.13-17.8-14.3-2.6-6.15-6.44-11.78-3.31-14.64,3.91-3.59,5.83,2.37,11.64-.85,5.15-2.86-3.18-15.25-9.08-22.93a18.71,18.71,0,0,0-13-7.24c-9.64-1-24.56-1.52-24.28,4.36.33,6.63,6.4,5.13,5.42,10.34-1.52,8.05-25.51-.58-27.06,16-.69,7.32,9,3.27,9,9.55,0,5.17-17.27,7.09-13.94,18.77,2,7,9.42,3.14,10,7.68.76,5.55-5.32,12.39,2.8,16.91s10.74-4.25,15.86-6.53c3.75-1.68,4.61,4.91,9.87,4.79a11.21,11.21,0,0,1,2.07,2.36c3,4.71-7,6.89-3.32,15,6.07,13.49,23.65,1.73,27.71,6.55C663.94,743.74,651.74,747.57,658.11,753.85Z",
			"M606.69,197.55c-9,4-2.36,13.17-8.29,14.88-7.26,2.09-17.56-4.69-22,7s7.76,13.49,11.8,19.87c3.3,5.21-7.73,7.62-3.67,16.64,6.71,14.93,26.17,1.91,30.66,7.24,5.46,6.47-8,10.71-1,17.65,16,15.75,33.23-14,41.79-7,5.54,4.52-2,8.24,3.4,15.36,4.82,6.31,19.72-7.22,28.81-16.58a25.29,25.29,0,0,0,7.12-18.77c-.59-13-2.76-33-10.54-31.52-8.79,1.72-5.6,9.53-12.74,9.24-11-.46-4.2-34.13-26.62-33-9.89.51-2.6,12.64-11,13.86C627.54,213.48,621.62,190.83,606.69,197.55Z",
			"M111,719.56c-3.81,9.12,7.27,11.24,4.11,16.53-3.87,6.49-16,8.58-11.27,20.18s14.88,4.54,22.19,6.44c6,1.56-.44,10.85,8.68,14.66,15.1,6.32,20.42-16.48,27.35-15.66,8.4,1,1.44,13.32,11.34,13.57,22.44.57,14.71-32.92,25.73-33.66,7.14-.48,4.15,7.41,13,8.9,7.83,1.31,9.47-18.74,9.71-31.79a25.28,25.28,0,0,0-7.61-18.58C204.86,691,189.61,677.9,185,684.34c-5.24,7.26,2.42,10.78-3,15.44-8.37,7.21-26.38-22.06-42-5.89-6.88,7.13,6.73,11,1.45,17.62C137.1,717,117.31,704.46,111,719.56Z",
			"M496.87,630.68c5.58-5.31,13.65-14,10-16.9-4.17-3.25-6.41,1.19-9.05-2.1-4.07-5.09,13.58-15.09,4.38-24.63-4.06-4.2-6.64,3.74-10.43.48-3.12-2.68,4.68-14.09-4.09-18.14-5.3-2.45-6.79,4.05-9.84,2.07-3.75-2.42-4.72-9.64-11.66-7.08s-3,8.68-4.28,13c-1,3.49-6.39-.49-8.83,4.81-4,8.77,9.28,12.39,8.65,16.46-.39,2.52-2.44,2.62-4.39,2.88-3.35-1.6-6.18-1.83-7.87.29-5.59,7,1.9,10.89-3.74,15.29-8.7,6.79-25.28-23.31-41.63-7.91-7.2,6.79,6.2,11.32.61,17.67-4.62,5.23-23.78-8.21-30.82,6.58-4.25,8.92,6.72,11.57,3.31,16.7-4.18,6.3-16.43,7.8-12.23,19.61s14.64,5.26,21.86,7.51c5.88,1.84-1,10.81,8,15.06,14.78,7,21.18-15.48,28.06-14.32,8.35,1.4.8,13.37,10.68,14.09,22.39,1.65,16.28-32.17,27.33-32.38,7.15-.14,3.78,7.6,12.53,9.51,7.75,1.7,10.36-18.26,11.23-31.28a26.13,26.13,0,0,0,0-3.19l1.18.07A14.94,14.94,0,0,0,496.87,630.68Z",
			"M939.86,574c-8.89-9.56-23.49-23.41-28.44-17.21-5.59,7,1.9,10.89-3.74,15.29-8.7,6.79-25.28-23.31-41.63-7.91-7.2,6.79,6.2,11.32.61,17.67-4.62,5.23-23.78-8.21-30.82,6.58-4.25,8.92,6.72,11.57,3.31,16.7-4.18,6.3-16.43,7.8-12.23,19.61s14.64,5.26,21.86,7.51c5.88,1.84-1,10.81,8,15.06,14.78,7,21.18-15.48,28.06-14.32,8.35,1.4.8,13.37,10.68,14.09,22.39,1.65,16.28-32.17,27.33-32.38,7.15-.14,3.78,7.6,12.53,9.51,7.76,1.7,10.36-18.26,11.23-31.28A25.28,25.28,0,0,0,939.86,574Z",
			"M571.12,851.85c-11.25-6.63-29.2-15.73-32.17-8.37-3.36,8.3,4.93,9.89.78,15.72-6.4,9-30.88-15.12-42.15,4.31-5,8.56,9.18,9.08,5.63,16.76-2.93,6.34-25.14-1.07-27.66,15.11-1.52,9.76,9.75,9.17,7.95,15.06-2.21,7.23-13.52,12.16-6.12,22.29s15.53.86,23.09.95c6.16.08,2.17,10.64,11.93,12.16,16.18,2.52,15.88-20.89,22.81-21.75,8.4-1,4.58,12.59,14.25,10.46,21.93-4.81,6.42-35.48,16.94-38.84,6.81-2.17,5.8,6.2,14.73,5.54,7.91-.6,4.71-20.46,1.83-33.19A25.32,25.32,0,0,0,571.12,851.85Z",
			"M214.73,647.4c4.93-11.52-7.2-13.79-11-20.34-3.08-5.34,8-7.29,4.36-16.46-6.09-15.2-26.07-3-30.34-8.51-5.19-6.69,8.48-10.37,1.72-17.6-15.35-16.4-33.79,12.6-42.05,5.26-5.35-4.74,2.37-8.15-2.76-15.48-4.55-6.51-20,6.39-29.47,15.37a25.31,25.31,0,0,0-7.9,18.46c.05,13,1.39,33.13,9.24,31.93,8.85-1.36,6-9.29,13.11-8.7,11,.91,2.77,34.27,25.23,34,9.9-.1,3.12-12.52,11.54-13.39,6.94-.72,11.91,22.15,27.11,16.06,9.17-3.67,2.9-13.06,8.89-14.52C199.78,651.73,209.8,658.93,214.73,647.4Z",
			"M769.37,649.93c7.3.72,6.39-7.6,10.83-6.51,5.45,1.34,9.57,9.51,16.76,3.61s0-11.54-.25-17.15c-.2-4.57,7.79-2,8.51-9.34,1.2-12.08-16.13-10.9-17.05-16-1.12-6.18,9.13-3.92,7.15-11-4.48-16-26.54-3.28-29.47-10.94-1.89-5,4.35-4.55,3.49-11.14-.35-2.63-3.5-3.42-7.7-3.26A112,112,0,0,0,751.54,553a22.87,22.87,0,0,0-15.85-8.82c-11.74-1.17-29.91-1.85-29.57,5.31.4,8.09,7.79,6.25,6.6,12.6-1.85,9.81-31.07-.71-33,19.49-.84,8.91,11,4,11,11.63,0,6.31-21,8.64-17,22.86,2.45,8.59,11.47,3.84,12.23,9.36.92,6.77-6.49,15.09,3.41,20.6s13.07-5.17,19.31-7.95c5.09-2.27,5.8,7.9,14.39,5.46,14.23-4.06,5.13-23.15,10.48-26.48,2.46-1.52,4.27-.67,6,.69-1.58,6.41-2.3,13.33,5.32,17.19,6.55,3.33,6.35-7.17,12.18-4.86C761.85,632,757.28,648.73,769.37,649.93Z",
			"M471.16,137c-4.44,8.6-10.43,22.28-4.86,24.17,6.29,2.14,7.12-4.06,11.61-1.23,6.93,4.37-9.91,23.51,4.94,31,6.55,3.33,6.35-7.17,12.18-4.86,4.82,1.91.25,18.66,12.34,19.86,7.3.72,6.39-7.6,10.83-6.51,5.45,1.34,9.57,9.51,16.76,3.61s0-11.54-.25-17.15c-.2-4.57,7.79-2,8.51-9.34,1.2-12.08-16.13-10.9-17.05-16-1.12-6.18,9.13-3.92,7.15-11-1.59-5.73-5.44-7.78-9.85-8.4a4,4,0,0,0,2.18-3.65c-.37-8.08-7.77-6.27-6.56-12.61,1.88-9.81,31.07.79,33-19.41.86-8.9-11-4-10.92-11.65,0-6.31,21.05-8.58,17-22.82-2.42-8.6-11.46-3.86-12.19-9.39-.91-6.77,6.52-15.07-3.36-20.61s-13.09,5.14-19.33,7.9c-5.1,2.26-5.78-7.92-14.38-5.49-14.24,4-5.19,23.13-10.56,26.44-6.5,4-8.5-8.61-15.64-3.23-16.21,12.21,8.07,31.57.69,38.29-4.78,4.35-7.1-2.91-14.18,1-5.47,3,1.49,14.87,8.1,24.07A18.68,18.68,0,0,0,471.16,137Z",
			"M188.22,526.54c1.2-12.08-16.13-10.9-17.05-16-1.12-6.18,9.13-3.92,7.15-11-4.48-16.05-26.54-3.28-29.47-10.94-1.89-4.95,4.35-4.55,3.49-11.14-.77-5.84-15.35-2.64-24.66,0A18.74,18.74,0,0,0,116.16,487c-4.44,8.6-10.43,22.28-4.86,24.17,6.29,2.14,7.12-4.06,11.61-1.23,6.93,4.37-9.91,23.51,4.94,31,6.55,3.33,6.35-7.17,12.18-4.86,4.82,1.91.25,18.66,12.34,19.86,7.3.72,6.39-7.6,10.83-6.51,5.45,1.34,9.57,9.51,16.76,3.61s0-11.54-.25-17.15C179.51,531.31,187.5,533.84,188.22,526.54Z",
			"M348.22,715.54c1.2-12.08-16.13-10.9-17.05-16-1.12-6.18,9.13-3.92,7.15-11-4.48-16-26.54-3.28-29.47-10.94-1.89-5,4.35-4.55,3.49-11.14-.64-4.89-11-3.43-19.82-1.26a81.74,81.74,0,0,0-3.37-9.63,15,15,0,0,0-8.49-8.26c-7.2-2.74-18.54-6.26-19.55-1.68-1.13,5.16,3.85,5.26,2,9.06-2.84,5.88-19.47-5.73-24.11,6.69-2,5.47,6.24,4.37,4.94,9.19-1.08,4-14.74,1.87-14.6,11.53.08,5.83,6.58,4.37,6.11,8-.57,4.42-6.66,8.41-1.35,13.57s9.12-1,13.53-1.73c3.59-.56,2.31,6,8.14,5.9,9.66-.14,7.18-13.73,11.12-14.91a2.77,2.77,0,0,1,2.44.23c-.66,3.5-.3,6.2,1.95,7,6.29,2.14,7.12-4.06,11.61-1.23,6.93,4.37-9.91,23.51,4.94,31,6.55,3.33,6.35-7.17,12.18-4.86,4.82,1.91.25,18.66,12.34,19.86,7.3.72,6.39-7.6,10.83-6.51,5.45,1.34,9.57,9.51,16.76,3.61s0-11.54-.25-17.15C339.51,720.31,347.5,722.84,348.22,715.54Z",
			"M509.63,339.57c2.5,9,10.57,4.63,15.79,6.67,4.27,1.65-1.25,7.95,5.13,11.54,10.59,6,16.47-10.38,21.5-9.18,6.11,1.46-.08,9.94,7.19,11,16.49,2.35,13.68-23,21.87-22.59,5.3.26,2.42,5.82,8.8,7.68,5.65,1.64,8.59-13,9.89-22.59a18.74,18.74,0,0,0-4-14.34c-6.1-7.53-16.2-18.52-20.18-14.18-4.49,4.9.86,8.16-3.54,11.13-6.79,4.59-17.53-18.53-30.41-8-5.68,4.66,4,8.69-.45,13.11-3.68,3.63-17.18-7.28-23.14,3.3-3.6,6.39,4.39,8.91,1.6,12.54C516.26,330.12,507.12,330.61,509.63,339.57Z",
			"M269.7,121.35c-2.1,9.06,7.06,9.14,10.67,13.43,2.94,3.5-4.92,6.37-1,12.59,6.42,10.31,19.42-1.2,23.26,2.27,4.66,4.21-4.84,8.67,1,13.07,13.34,10,23-13.6,30-9.32,4.52,2.77-.67,6.26,4,11,4.17,4.16,13.77-7.27,19.52-15.06a18.74,18.74,0,0,0,3.36-14.51c-1.73-9.53-5.33-24-10.9-22.12-6.29,2.14-3.16,7.56-8.44,8.06-8.16.77-6.49-24.67-22.86-21.58-7.22,1.37-.65,9.56-6.69,11.29-5,1.43-11.58-14.63-21.89-8.2-6.22,3.88-.42,9.92-4.6,11.76C280.06,116.25,271.8,112.29,269.7,121.35Z",
			"M360.86,914.43c.69,9.28,9.45,6.63,14.17,9.65,3.86,2.47-2.8,7.55,2.75,12.34,9.2,7.93,18.19-6.93,22.89-4.76,5.7,2.64-2,9.73,4.88,12.18,15.7,5.56,17.95-19.83,25.9-17.82,5.14,1.3,1.22,6.18,7.1,9.26,5.22,2.73,11-11,14.17-20.19a18.79,18.79,0,0,0-1.1-14.85c-4.49-8.58-12.23-21.35-17-17.88-5.36,3.91-.77,8.16-5.66,10.2-7.56,3.16-13.53-21.62-28.24-13.81-6.49,3.45,2.22,9.32-3,12.77-4.33,2.84-15.41-10.53-23.34-1.33-4.79,5.55,2.54,9.59-.9,12.6C369.23,906.48,360.18,905.15,360.86,914.43Z",
			"M245.56,583.91c.94,6.21-9.24,3.65-7.47,10.78,4,16.17,26.44,4,29.14,11.79,1.75,5-4.48,4.42-3.81,11,.6,5.86,15.27,3.09,24.65.69a18.78,18.78,0,0,0,11.79-9.1c4.68-8.48,11.07-22,5.56-24-6.23-2.32-7.24,3.86-11.64.9-6.8-4.57,10.59-23.21-4-31.17-6.45-3.52-6.55,7-12.32,4.5-4.76-2,.29-18.66-11.76-20.21-7.27-.93-6.6,7.42-11,6.2-5.41-1.49-9.29-9.78-16.65-4.09s-.33,11.53-.25,17.14c.07,4.57-7.84,1.82-8.77,9.09C227.43,579.48,244.78,578.79,245.56,583.91Z",
		];
		svgBody
			.append("g")
			.attr("class", "leaves")
			.attr(
				"transform",
				(d) => `translate(0, ${getBoundingBoxCenter(treeOutline)[1] - 500})`
			)
			.selectAll("leaf")
			.data(
				chart && chart.domain === DOMAIN_ORAL_LANGUAGE ? leavesOL : leavesMath
			)
			.enter()
			.append("path")
			.attr("d", (d) => d)
			.attr("class", "leaf")
			.attr("opacity", 0.1)
			.attr("fill", "#fff")
			.attr("pointer-events", "none");
		if (chart.callback) chart.callback(svg.node(), data);
	}
}
