import i18n from "i18next";
import React from "react";
import {Trans} from "react-i18next";

import {ActivityMonitorActions} from "../Actions";
import {
	ACTIONS,
	ACTIVITY_MONITOR_ACTIONS,
	ADMIN_ACTIONS,
	ADMIN_REQUEST_TYPE,
} from "./Constants";

export const NOTIFICATION_TYPE = {
	activityRecommendation: "activity_recommendation",
	activityUnrecommendation: "activity_unrecommendation",
	studentBadgeEarned: "badge_earned",
	adminRequestOpened: "admin_request_opened",
	adminRequestClosed: "admin_request_closed",
};

export function formatStringToUpperCase(string) {
	if (string) {
		string = string.replace("_", " ");
		return string.replace(/\b\w/g, (l) => l.toUpperCase());
	}
	return null;
}

export const getDayDifference = (notificationIssuedOn) => {
	const milliSecond = 1000;
	const milliSecondInADay = milliSecond * 3600 * 24;
	const issuedOn = Math.floor(notificationIssuedOn * milliSecond);
	const now = new Date().getTime();
	const daysDiff = Math.floor((now - issuedOn) / milliSecondInADay);
	let dayDifference = 0;
	if (daysDiff === 0) {
		const issuedDay = new Date(issuedOn).getDate();
		const currentDay = new Date(now).getDate();
		dayDifference = currentDay - issuedDay;
	} else {
		dayDifference = daysDiff;
	}
	return dayDifference;
};

export const getUnreadNotificationCount = (notifications) => {
	if (notifications) {
		return notifications.filter((notification) => notification.read === false)
			.length;
	}
};

export const getNotificationIcon = (notificationType, requestType) => {
	switch (notificationType) {
		case NOTIFICATION_TYPE.activityRecommendation:
		case NOTIFICATION_TYPE.activityUnrecommendation:
			return "faGraduationCap";
		case NOTIFICATION_TYPE.studentBadgeEarned:
			return "faCertificate";
		case NOTIFICATION_TYPE.adminRequestOpened:
			return "faFlag";
		case NOTIFICATION_TYPE.adminRequestClosed:
			switch (requestType) {
				case ADMIN_REQUEST_TYPE.REMOVE:
					return "faBan";
				case ADMIN_REQUEST_TYPE.ADD:
				case ADMIN_REQUEST_TYPE.ADD_TEACHER:
					return "faUserPlus";
				case ADMIN_REQUEST_TYPE.EDIT:
					return "faEdit";
				default:
					throw new Error(`Unsupported request type: ${requestType}`);
			}
		case "activity_feedback_response_created":
			return "faCoins";
		case "activity_completion":
			return "faLeaf";
		default:
			throw new Error(`Unsupported notification type: ${notificationType}`);
	}
};

export const adminAddEditSchoolClassroomToast = (
	type,
	action,
	name,
	errorMessage?: string
) => {
	const message =
		type === ACTIVITY_MONITOR_ACTIONS.SUCCESS ? (
			<Trans
				i18nKey={`phrase.admin_${
					action === ADMIN_ACTIONS.ADD_CLASSROOM ||
					action === ADMIN_ACTIONS.ADD_SCHOOL
						? `added`
						: `edited`
				}`}
			>
				{[
					<span key="schoolName" className="nun-bold">
						{{name}}
					</span>,
				]}
			</Trans>
		) : type === ACTIVITY_MONITOR_ACTIONS.ERROR ? (
			i18n.t("error.failed_add_user_message", {
				user: name,
				error: errorMessage,
			})
		) : null;
	ActivityMonitorActions.addToQueue({
		type,
		title: i18n.t(`admin.${action}`),
		message,
	});
};

export const adminAddEditRemoveUserToast = (
	type,
	action,
	name,
	errorMessage?: string
) => {
	const phraseName =
		action === ACTIONS.ADD
			? `added`
			: action === ACTIONS.EDIT
			? `edited`
			: action === ACTIONS.REMOVE
			? `removed`
			: null;
	const message =
		type === ACTIVITY_MONITOR_ACTIONS.SUCCESS ? (
			<Trans i18nKey={`phrase.admin_${phraseName}`}>
				{[
					<span key="userName" className="nun-bold">
						{{name}}
					</span>,
				]}
			</Trans>
		) : type === ACTIVITY_MONITOR_ACTIONS.ERROR ? (
			i18n.t(`error.failed_${action}_user_message`, {
				user: name,
				error: errorMessage,
			})
		) : null;
	ActivityMonitorActions.addToQueue({
		type,
		title: i18n.t(`admin.${action}_user`),
		message,
	});
};

export const requestAddUserToast = (type, title, data) => {
	const message =
		type === ACTIVITY_MONITOR_ACTIONS.SUCCESS ? (
			<Trans i18nKey="admin.add_user_resolved">
				{[
					<span key="name" className="nun-bold">
						{{user: data.userName}}
					</span>,
					<span key="classroom" className="nun-bold">
						{{classroom: data.classroomName}}
					</span>,
				]}
			</Trans>
		) : type === ACTIVITY_MONITOR_ACTIONS.ERROR ? (
			i18n.t("error.failed_add_user_message", {
				user: data.userName,
				error: data.errorMessage,
			})
		) : null;
	ActivityMonitorActions.addToQueue({
		type,
		title,
		message,
	});
};

export const requestRemoveUserToast = (type, data, errorMessage?: string) => {
	const message =
		type === ACTIVITY_MONITOR_ACTIONS.SUCCESS ? (
			<Trans i18nKey="phrase.student_has_been_removed">
				{[
					<span key="userName" className="nun-bold">
						{{
							studentName: data.studentName,
							classroom: data.classroom,
						}}
					</span>,
				]}
			</Trans>
		) : type === ACTIVITY_MONITOR_ACTIONS.ERROR ? (
			i18n.t("error.failed_remove_user_message", {
				user: data.studentName,
				error: errorMessage,
			})
		) : null;
	ActivityMonitorActions.addToQueue({
		type,
		title: i18n.t("admin.remove_user"),
		message,
	});
};

export const closeRequestToast = (type, errorMessage?: string) => {
	const title =
		type === ACTIVITY_MONITOR_ACTIONS.SUCCESS
			? i18n.t("admin.close_request")
			: type === ACTIVITY_MONITOR_ACTIONS.ERROR
			? i18n.t("error.failed_close_request")
			: null;
	const message =
		type === ACTIVITY_MONITOR_ACTIONS.SUCCESS
			? i18n.t("admin.request_closed")
			: type === ACTIVITY_MONITOR_ACTIONS.ERROR
			? i18n.t("error.failed_close_request_message", {
					error: errorMessage,
			  })
			: null;
	ActivityMonitorActions.addToQueue({
		type,
		title,
		message,
	});
};
