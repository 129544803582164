import React, {Component} from "react";

import {AUTOMATION_TEST_ID} from "../../../../Scripts/Constants";
import styles from "../../../../Stylesheets/scss/exportable.scss";
import {Icon, IconType} from "../../../Public";
import {DropdownItem} from "./index";

interface DropdownProps {
	items?: DropdownItemModel[];
	onSelect?: (item: DropdownItemModel) => void;

	id?: string | number;
	hideLabel?: boolean;
	menuId?: string;
	selectedItem?: DropdownItemModel;
	selectedItemIcon?: IconType;
	className?: string;
	componentClassName?: string;
	disabled?: boolean;
	isActionDropdown?: boolean;
	inModal?: boolean;
	parentModal?: HTMLDivElement;
	dropdownLabel?: string;
	dropdownMenuClassName?: string;
	labelIcon?: IconType;
	modalHeaderRef?: HTMLDivElement;
	actionDropdownClassName?: string;
}

interface DropdownState {
	isDropdown: boolean;
	dropUp: boolean;
	selectedItem?: DropdownItemModel;
	dropdownHeight?: number;
	dropdownTop?: number;
}

export interface DropdownItemModel {
	id?: number | string;
	label: string;
	value?: string | number;
	img?: string;
	alt?: string;
}

export class Dropdown extends Component<DropdownProps, DropdownState> {
	private dropdown: HTMLElement;
	private dropdownButton: HTMLElement;

	constructor(props: DropdownProps) {
		super(props);
		if (props.isActionDropdown) {
			this.state = {
				isDropdown: false,
				dropUp: false,
			};
		} else {
			const {selectedItem = null, items} = props;
			this.state = {
				isDropdown: false,
				dropUp: false,
				selectedItem: selectedItem ? selectedItem : items?.[0],
			};
		}
		this.dropdown = document.createElement("div");
		this.dropdownButton = document.createElement("button");
	}

	componentDidUpdate = (prevProps) => {
		const {selectedItem = null} = this.props;
		if (
			selectedItem &&
			prevProps.selectedItem &&
			selectedItem.id !== prevProps.selectedItem.id
		) {
			this.setState({
				selectedItem,
			});
		}
	};

	componentDidMount = () => {
		const {selectedItem = null, isActionDropdown, items} = this.props;
		if (!isActionDropdown) {
			this.setState({
				selectedItem: selectedItem ? selectedItem : items?.[0],
			});
		}
		document.addEventListener("mousedown", this.handleOutsideClick, false);
	};

	componentWillUnMount = () => {
		document.removeEventListener("mousedown", this.handleOutsideClick, false);
	};

	handleOutsideClick = (e) => {
		const {isDropdown} = this.state;
		if (
			this.dropdown &&
			!this.dropdown.contains(e.target) &&
			this.dropdownButton &&
			!this.dropdownButton.contains(e.target) &&
			isDropdown
		) {
			this.setState({isDropdown: false});
		}
	};

	onSelectItem = (item: DropdownItemModel) => {
		this.setState({selectedItem: item, isDropdown: false}, () => {
			if (this.props.onSelect) this.props.onSelect(item);
		});
	};

	toggleDropdown = () => {
		this.setState({isDropdown: !this.state.isDropdown, dropUp: false}, () => {
			if (this.state.isDropdown) {
				this.determineDropUp();
			}
		});
	};

	determineDropUp = () => {
		if (!this.dropdown) return null;
		const {inModal, parentModal} = this.props;
		let windowHeight: number;
		if (inModal && parentModal) {
			windowHeight = parentModal.getBoundingClientRect().bottom;
		} else {
			windowHeight = window.innerHeight;
		}
		const menuHeight = this.dropdown.clientHeight;
		const dropdownPosition = this.dropdown.getBoundingClientRect();
		if (dropdownPosition.top + menuHeight > windowHeight) {
			this.setState({
				dropUp: true,
				dropdownHeight: dropdownPosition.height,
				dropdownTop: dropdownPosition.top,
			});
		} else {
			this.setState({
				dropUp: false,
			});
		}
	};

	render() {
		const {
			selectedItem = null,
			isDropdown,
			dropUp,
			dropdownHeight,
			dropdownTop,
		} = this.state;
		const {
			items,
			selectedItemIcon = null,
			disabled,
			dropdownLabel,
			labelIcon,
			isActionDropdown,
			actionDropdownClassName,
			children,
			id,
			menuId,
			componentClassName,
			dropdownMenuClassName,
			hideLabel,
			inModal,
			modalHeaderRef,
			className = "btn btn-dropdown dropdown-toggle btn-dropdown-w-fixed",
		} = this.props;
		const primaryNavigationHeight = parseInt(
			styles.primarynavigationheight,
			10
		);
		const secondNavigationHeight = parseInt(
			styles.secondarynavigationheight,
			10
		);
		let maxHeight: number;
		if (inModal && dropdownTop && modalHeaderRef) {
			maxHeight =
				dropdownTop - modalHeaderRef.getBoundingClientRect().bottom - 20;
		} else {
			maxHeight =
				window.innerHeight - (primaryNavigationHeight + secondNavigationHeight);
		}
		const style = dropUp
			? {
					maxHeight: `${maxHeight}px`,
			  }
			: {};
		return (items?.length && selectedItem) || isActionDropdown ? (
			<div
				className={
					isActionDropdown
						? actionDropdownClassName
						: `dropdown${dropUp ? " dropup" : ""}${
								componentClassName
									? ` ${componentClassName}`
									: " d-inline w-100"
						  }`
				}
			>
				<button
					className={className}
					type="button"
					onClick={this.toggleDropdown}
					ref={(node) => (this.dropdownButton = node as HTMLButtonElement)}
					disabled={disabled}
					id={id?.toString()}
				>
					{isActionDropdown ? (
						<Icon type="faEllipsisH" className="mr-1 ml-1" />
					) : (
						<span>
							{selectedItem && selectedItem.img && (
								<img
									src={selectedItem.img}
									width="25px"
									height="25px"
									alt={selectedItem.alt}
								/>
							)}
							{selectedItemIcon && (
								<Icon
									type={selectedItemIcon}
									className="mr-2"
									iconWrapperClassName="d-inline"
								/>
							)}
							<span
								style={{padding: "0 5px"}}
								id={AUTOMATION_TEST_ID.PUBLIC.DROPDOWN.CURRENT_ITEM}
								className={`${hideLabel ? `hide-username` : ``}`}
							>
								{selectedItem?.label}
							</span>
						</span>
					)}
				</button>
				{isDropdown ? (
					isActionDropdown ? (
						<div
							className="d-inline"
							style={{
								height: dropdownHeight ? `${dropdownHeight}px` : "",
								overflow: dropdownHeight ? "auto" : "",
								maxHeight: `300px`,
								...style,
							}}
							ref={(node) => (this.dropdown = node as HTMLDivElement)}
							id={menuId}
						>
							{children}
						</div>
					) : (
						<div
							className={`dropdown-menu ${
								dropdownMenuClassName
									? dropdownMenuClassName
									: `line-height-def dd-overflow-auto`
							} show`}
							style={{
								height: dropdownHeight ? `${dropdownHeight}px` : "",
								overflow: dropdownHeight ? "auto" : "",
								maxHeight: `300px`,
								...style,
							}}
							ref={(node) => (this.dropdown = node as HTMLDivElement)}
							id={menuId}
						>
							{dropdownLabel && labelIcon && (
								<div className="pr-3 pl-3 pt-2 pb-2 bg-white border-bottom">
									<p className="mb-0">
										<Icon
											type={labelIcon}
											className="mr-2 edu-text-primary"
											iconWrapperClassName="d-inline"
										/>
										{dropdownLabel}
									</p>
								</div>
							)}
							{items?.map((item) => (
								<DropdownItem
									key={`dropdown_item_${item.id}`}
									item={item}
									onSelectItem={this.onSelectItem}
								/>
							))}
						</div>
					)
				) : null}
			</div>
		) : null;
	}
}
