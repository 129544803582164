import React from "react";

interface TableHeadProps {
	children: JSX.Element[] | JSX.Element;
	className?: string;
}

export const TableHead = ({children, ...rest}: TableHeadProps) => (
	<thead className="bg-white rounded-top text-muted" {...rest}>
		{children}
	</thead>
);
