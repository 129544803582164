import "../../../../../Stylesheets/css/scss/Components/Portal/Public/Modal/Parts/ModalPageNavigatorMenu.css";

import React, {Component} from "react";

import {Icon} from "../../../../Public";
import {OutsideClickHandler} from "../../index";

interface ModalPageNavigatorMenuProps {
	onSelect: (page: number) => void;
	pages: Page[];
}

export interface Page {
	page: number;
	title: string;
}

export class ModalPageNavigatorMenu extends Component<ModalPageNavigatorMenuProps> {
	state = {
		modalOpen: false,
	};

	onSelect = (page: number) => {
		const {onSelect} = this.props;
		this.setState({modalOpen: false}, () => {
			onSelect(page);
		});
	};

	onToggle = () => {
		this.setState({modalOpen: !this.state.modalOpen});
	};

	onOutsideClick = () => {
		this.setState({modalOpen: false});
	};

	render() {
		const {pages} = this.props;
		return (
			<div className="modal-page-navigatior-menu">
				<OutsideClickHandler onOutsideClick={this.onOutsideClick}>
					<div
						className="modal-page-navigator-menu-icon"
						onClick={this.onToggle}
					>
						<Icon type="faListUl" />
					</div>
					{this.state.modalOpen && (
						<div className="modal-page-navigator-menu-dropdown dropdown-menu">
							{pages.map((page) => (
								<div
									key={`modal-page-navigator-menu-dropdown-item${page.page}`}
									className="modal-page-navigator-menu-dropdown-item dropdown-item"
									onClick={() => this.onSelect(page.page)}
									title={page.title}
								>
									{page.title}
								</div>
							))}
						</div>
					)}
				</OutsideClickHandler>
			</div>
		);
	}
}
