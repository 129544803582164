import "../../../../../Stylesheets/css/scss/Components/Portal/Public/Rewards/Badge.css";

import i18n from "i18next";
import React from "react";

import {BadgeIcon} from "./BadgeIcon";

interface BadgeProps {
	badge: {
		name: string;
		description?: string;
		id: number;
		tier: number;
		earned: boolean;
		is_secret?: boolean;
	};
}

export const Badge = ({badge}: BadgeProps) => {
	const {name, description, id, tier, earned, is_secret} = badge;
	return (
		<div
			className={`badge-component${earned ? " earned " : " "}col-md-3 col-sm-6`}
		>
			<div className="badge-icon">
				<BadgeIcon
					iconName={id}
					tier={tier}
					earned={earned}
					secret={is_secret}
				/>
			</div>
			<h5 className="pt-2 mb-1">
				{!earned && is_secret ? i18n.t("rewards.hidden_badge_title") : name}
			</h5>
			<small className="text-center">
				{!earned && is_secret
					? i18n.t("rewards.hidden_badge_description")
					: description}
			</small>
		</div>
	);
};
