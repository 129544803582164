import React, {Component} from "react";
import {connect} from "react-redux";

import {LoaderSpinner} from "./LoaderSpinner";

interface LoaderProps {
	actionTypes?: string[];
	conditional?: boolean;
	loader: LoaderReducerProps;
	children: JSX.Element[];
}

interface LoaderReducerProps {
	queue: string[];
}

class Loader extends Component<LoaderProps> {
	shouldSpinnerRender = () => {
		const {actionTypes = [], conditional = true, loader} = this.props;
		return (
			!conditional ||
			actionTypes.some((actionType) => loader.queue.includes(actionType))
		);
	};

	render() {
		const {children} = this.props;
		const show = this.shouldSpinnerRender();
		if (children) {
			return React.Children.map(children, (child) => {
				return React.cloneElement(child, {
					show,
				});
			});
		} else {
			return <LoaderSpinner show={show} />;
		}
	}
}
export default connect((state: {loader: LoaderReducerProps}) => {
	return {
		loader: state.loader,
	};
})(Loader);
