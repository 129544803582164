import "../../../../Stylesheets/css/scss/Components/Portal/Public/Charts/d3/line.css";

import React, {Component, Fragment} from "react";
import IsEqual from "react-fast-compare";
import ReactTooltip from "react-tooltip";

import {debounce} from "../../../../Scripts/Utilities";
import {Line} from "./d3";

interface ChartProps {
	type: string;
	data: {}[];
	options: any;
	isHolisticMath?: boolean;
}

export class Chart extends Component<ChartProps> {
	private node;
	private resizeHandler;

	constructor(props) {
		super(props);
		this.node = null;
		this.resizeHandler = debounce(this.onResize, 500);
	}

	componentDidMount = () => {
		this.drawChart(this.node);
		window.addEventListener("resize", this.resizeHandler);
	};

	componentDidUpdate = (prevProps) => {
		const {data} = this.props;
		if (!IsEqual(data, prevProps.data)) {
			this.tearDownForReDraw(this.node);
			this.drawChart(this.node);
		}
	};

	componentWillUnmount = () => {
		window.removeEventListener("resize", this.resizeHandler);
	};

	onResize = () => {
		this.tearDownForReDraw(this.node);
		this.drawChart(this.node);
	};

	tearDownForReDraw = (node) => {
		if (node?.firstChild) node.removeChild(node.firstChild);
	};

	drawChart = (node) => {
		const {type, data, options, isHolisticMath} = this.props;
		switch (type) {
			case "line":
				Line({node, data, options, isHolisticMath});
				return;
			default:
				return null;
		}
	};

	render() {
		return (
			<Fragment>
				<div
					className="visualization"
					style={{height: "300px", width: "100%"}}
					ref={(node) => (this.node = node)}
				/>
				<ReactTooltip
					id="d3-chart-tooltip"
					type="dark"
					effect="solid"
					multiline
				/>
			</Fragment>
		);
	}
}
