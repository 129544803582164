import i18n from "i18next";

import * as ActionTypes from "../Redux/ActionTypes";
import {
	deleteData,
	getData,
	postData,
	postDataJSON,
	putData,
} from "../Scripts/Axios/axiosRequests";
import {
	ACTIVITY_FILTERS,
	ACTIVITY_MONITOR_ACTIONS,
	FILTER_TAGS,
} from "../Scripts/Constants";
import {get_url} from "../Scripts/Fetch";
import {
	getCurrentCurriculumCompetencies,
	getCurrentCurriculumOutcomes,
} from "../Scripts/LearningOpportunities";
import {
	getCurricula,
	getDomains,
	getLevelOneOutcomes,
	getLevelThreeOutcomes,
	getLevelTwoOutcomes,
} from "../Scripts/Utilities";
import {
	ActivityActions,
	ActivityMonitorActions,
	ErrorReporterActions,
	LoaderActions,
} from "./index";

const learningOutcomePerPage = 50;
const generalSpecificOutcomePerPage = 70;

const domainsTaxonomy = getDomains();
const levelOneOutcomesTaxonomy = getLevelOneOutcomes();
const levelTwoOutcomesTaxonomy = getLevelTwoOutcomes();
const levelThreeOutcomesTaxonomy = getLevelThreeOutcomes();
const curriculaTaxonomy = getCurricula();

const mapFilterTagToQueryTag = (tag) => {
	switch (tag) {
		case FILTER_TAGS.COMPETENCIES:
			return "competencies=";
		case FILTER_TAGS.DIFFICULTIES:
			return "difficulties=";
		case FILTER_TAGS.GENERAL_OUTCOMES:
			return "level-2-outcomes=";
		case FILTER_TAGS.LANGUAGES:
			return "languages=";
		case FILTER_TAGS.LEARNING_OUTCOMES:
			return "level-1-outcomes=";
		case FILTER_TAGS.SPECIFIC_OUTCOMES:
			return "level-3-outcomes=";
		default:
			console.error(
				`Error: No specified query param for the targeted tag, ${tag}`
			);
	}
};

export const setFilters = (filters) => ({
	type: ActionTypes.ACTIVITY_SET_FILTERS,
	payload: {filters},
});

const notCompletedActivitiesCount = (queries) => {
	const parametersObject = JSON.parse(
		`{"${queries.slice(1).replace(/&/g, '","').replace(/=/g, '":"')}"}`
	);
	const keys = Object.keys(parametersObject);
	const hasEssentialProperties =
		parametersObject.hasOwnProperty("domains") &&
		parametersObject.hasOwnProperty("curricula") &&
		parametersObject.hasOwnProperty("not_completed_by");
	if (parametersObject.hasOwnProperty("prioritize_featured")) {
		return keys.length === 4 && hasEssentialProperties;
	} else {
		return keys.length === 3 && hasEssentialProperties;
	}
};

export const getActivities = (filters = {}) => {
	const actionType = ActionTypes.ACTIVITY_GET_ALL;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const {
			filterTags,
			search,
			page = 1,
			per_page,
			completed_by_class_and_current_user,
			completed_by,
			not_completed_by,
			saved_for,
			domains,
			status,
			outcome,
			difficulties,
			generalOutcome,
			specificOutcome,
			author,
			languages,
			learningOutcome,
			competency,
			places,
			competencies,
			prioritize_featured,
			durations,
			hidden,
			curricula,
		} = filters;
		let url = `${get_url()}wp-json/wp/v2/activity`;
		let queries = "";
		if (filterTags) {
			Object.keys(filterTags).forEach((key) => {
				const queryArray = filterTags[key];
				if (queryArray && queryArray.length)
					queries += `${queries ? "&" : "?"}${mapFilterTagToQueryTag(
						key
					)}${queryArray.join(",")}`;
			});
		}
		if (search) queries += `${queries ? "&" : "?"}search=${search}`;
		if (page > 1) queries += `${queries ? "&" : "?"}page=${page}`;
		if (per_page) queries += `${queries ? "&" : "?"}per_page=${per_page}`;
		if (completed_by_class_and_current_user)
			queries += `${
				queries ? "&" : "?"
			}completed_by_class_and_current_user=${completed_by_class_and_current_user}`;
		if (saved_for) queries += `${queries ? "&" : "?"}saved_for=${saved_for}`;
		if (domains) queries += `${queries ? "&" : "?"}domains=${domains}`;
		if (status) queries += `${queries ? "&" : "?"}status=${status}`;
		if (completed_by)
			queries += `${queries ? "&" : "?"}completed_by=${completed_by}`;
		if (not_completed_by)
			queries += `${queries ? "&" : "?"}not_completed_by=${not_completed_by}`;
		if (outcome) queries += `${queries ? "&" : "?"}level-1-outcomes=${outcome}`;
		if (difficulties && difficulties.length)
			queries += `${queries ? "&" : "?"}difficulties=${difficulties}`;
		if (learningOutcome)
			queries += `${queries ? "&" : "?"}level-1-outcomes=${learningOutcome}`;
		if (filters[ACTIVITY_FILTERS.LEARNING_OUTCOMES])
			queries += `${queries ? "&" : "?"}level-1-outcomes=${
				filters[ACTIVITY_FILTERS.LEARNING_OUTCOMES]
			}`;
		if (generalOutcome)
			queries += `${queries ? "&" : "?"}level-2-outcomes=${generalOutcome}`;
		if (filters[ACTIVITY_FILTERS.GENERAL_OUTCOMES])
			queries += `${queries ? "&" : "?"}level-2-outcomes=${
				filters[ACTIVITY_FILTERS.GENERAL_OUTCOMES]
			}`;
		if (specificOutcome)
			queries += `${queries ? "&" : "?"}level-3-outcomes=${specificOutcome}`;
		if (filters[ACTIVITY_FILTERS.SPECIFIC_OUTCOMES])
			queries += `${queries ? "&" : "?"}level-3-outcomes=${
				filters[ACTIVITY_FILTERS.SPECIFIC_OUTCOMES]
			}`;
		if (author) queries += `${queries ? "&" : "?"}author=${author}`;
		if (languages) queries += `${queries ? "&" : "?"}languages=${languages}`;
		if (competency)
			queries += `${queries ? "&" : "?"}competencies=${competency}`;
		if (competencies)
			queries += `${queries ? "&" : "?"}competencies=${competencies}`;
		if (places?.length) queries += `${queries ? "&" : "?"}places=${places}`;
		if (prioritize_featured)
			queries += `${
				queries ? "&" : "?"
			}prioritize_featured=${prioritize_featured}`;
		if (durations?.length)
			queries += `${queries ? "&" : "?"}durations=${durations}`;
		if (hidden) queries += `${queries ? "&" : "?"}hidden=${hidden}`;
		if (curricula) queries += `${queries ? "&" : "?"}curricula=${curricula}`;
		url += queries;
		return getData(url, true)
			.then((response) => {
				if (response.status === 200) {
					dispatch({
						type: actionType,
						payload: {
							activities: response.data,
							totalPages: response.headers["x-wp-totalpages"],
							...(notCompletedActivitiesCount(queries) && {
								totalNotCompletedActivitiesCount: {
									[filters.domains]: response.headers["x-wp-total"],
								},
							}),
							lazyLoad: page > 1,
						},
					});
				} else {
					dispatch({
						type: actionType,
						payload: {
							activities: [],
						},
					});
				}
			})
			.catch(() => {
				dispatch({
					type: actionType,
					payload: {
						activities: [],
						totalPages: 1,
					},
				});

				ActivityMonitorActions.addToQueue({
					type: ACTIVITY_MONITOR_ACTIONS.ERROR,
					title: i18n.t("admin.loading_activities"),
					message: i18n.t("error.loading_activities_error"),
				});
			});
	};
};

export const clearActivities = () => ({
	type: ActionTypes.ACTIVITY_CLEAR_ACTIVITIES,
	payload: [],
});

export const createActivity = (body, {handling}) => {
	const actionType = ActionTypes.ACTIVITY_CREATE_ACTIVITY;
	return () => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/wp/v2/activity`;
		postDataJSON(url, body)
			.then((response) => {
				handling.success(response);
			})
			.catch((error) => {
				handling.error(error);
			});
	};
};

export const editActivity = (activityId, body, {handling}) => {
	const actionType = ActionTypes.ACTIVITY_EDIT_ACTIVITY;
	return () => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/wp/v2/activity/${activityId}`;
		postDataJSON(url, body)
			.then((response) => {
				handling.success(response);
			})
			.catch((error) => {
				handling.error(error);
			});
	};
};

export const saveAndDeleteSaveActivity = (person, query, body, {handling}) => {
	const actionType = ActionTypes.ACTIVITY_SAVE_ACTIVITY;
	return () => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/people/${person}/savedActivities${query}`;
		if (query) {
			deleteData(url)
				.then((response) => {
					if (response.status < 300) handling.success(response);
					else handling.error(response);
				})
				.catch((error) => {
					handling.error(error);
				});
		} else {
			postData(url, body)
				.then((response) => {
					if (response.status < 300) handling.success(response);
					else handling.error(response);
				})
				.catch((error) => {
					handling.error(error);
				});
		}
	};
};

export const getActivityById = (activityId, returnResponse, {handling}) => {
	const actionType = ActionTypes.ACTIVITY_GET_ACTIVITY_BY_ID;
	return () => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/wp/v2/activity/${activityId}`;
		getData(url, returnResponse)
			.then((response) => {
				handling.success(response);
			})
			.catch((error) => {
				handling.error(error);
			});
	};
};

export const getActivityBySlug = (activitySlug, returnResponse, {handling}) => {
	const actionType = ActionTypes.ACTIVITY_GET_ACTIVITY_BY_SLUG;
	return () => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/wp/v2/activity?slug=${activitySlug}`;
		getData(url, returnResponse)
			.then((response) => {
				handling.success(response[0]);
			})
			.catch((error) => {
				handling.error(error);
			});
	};
};

export const checkIfActivitySaved = (userId, activityId, {handling}) => {
	const actionType = ActionTypes.ACTIVITY_CHECK_IF_ACTIVITY_SAVED;
	return () => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/people/${userId}/savedActivities/${activityId}`;
		getData(url)
			.then((response) => {
				handling.success(response);
			})
			.catch((error) => {
				handling.error(error);
			});
	};
};

export const recommendActivity = (activityId, {students, notification}) => {
	const actionType = ActionTypes.ACTIVITY_POST_RECOMMEND_ACTIVITY;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/wp/v2/activity/${activityId}/recommend?students=${students}`;
		postData(url)
			.then(() => {
				if (notification?.success) notification.success();
				dispatch({
					type: actionType,
					payload: activityId,
				});
			})
			.catch((error) => {
				if (notification?.error) notification.error(error.data.message);
			});
	};
};

export const updateActivityTranslations = (translations, {handling}) => {
	const actionType = ActionTypes.ACTIVITY_UPDATE_TRANSLATIONS;
	return () => {
		LoaderActions.addToQueue(actionType);
		Promise.all(
			translations.map((translation) =>
				postData(
					`${get_url()}wp-json/wp/v2/activity/${translation.id}`,
					translation.body
				)
			)
		)
			.then((responses) => {
				if (responses.every((response) => response.status < 300)) {
					handling.success();
				} else {
					handling.error();
				}
			})
			.catch(() => {
				handling.error();
			});
	};
};

export const recommendAndCompleteActivityWithStudents = (
	activities,
	action,
	students,
	{handling}
) => {
	const actionType = ActionTypes.ACTIVITY_RECOMMEND_AND_COMPLETE_WITH_STUDENTS;
	return () => {
		LoaderActions.addToQueue(actionType);
		Promise.all(
			activities.map((activity) =>
				postData(
					`${get_url()}wp-json/wp/v2/activity/${activity}/${action}?students=${students}`
				)
			)
		)
			.then((responses) => {
				if (responses.every((response) => response.status === 200)) {
					handling.success();
				} else {
					handling.error();
				}
			})
			.catch(() => {
				handling.error();
			});
	};
};

export const getActivitiesCompletionCounts = (id) => {
	const actionType = ActionTypes.ACTIVITY_GET_COMPLETION_COUNTS;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/students/${id}/completions`;
		getData(url)
			.then((response) => {
				dispatch({
					type: actionType,
					payload: {activities: response, id},
				});
			})
			.catch((error) => {
				console.error(`Error: ${error.message}`);
			});
	};
};

export const getSavedActivities = (id, {handling} = {}) => {
	const actionType = ActionTypes.ACTIVITY_GET_SAVED_ACTIVITIES;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/people/${id}/savedActivities`;
		getData(url)
			.then((response) => {
				if (handling?.success) handling.success();
				dispatch({
					type: actionType,
					payload: response,
				});
			})
			.catch((error) => {
				console.error(`Error: ${error.message}`);
			});
	};
};

export const getActivityLanguages = () => {
	const actionType = ActionTypes.ACTIVITY_GET_LANGUAGES;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const perPage = 60;
		const url = `${get_url()}wp-json/wp/v2/languages?per_page=${perPage}`;
		getData(url, true)
			.then((response) => {
				const totalPages = response.headers["x-wp-totalpages"];
				const urls = Array(parseInt(totalPages, 10))
					.fill()
					.map(
						(url, page) =>
							`${get_url()}wp-json/wp/v2/languages?per_page=${perPage}&page=${
								page + 1
							}`
					);
				Promise.all(urls.map((url) => getData(url))).then((languages) => {
					dispatch({
						type: actionType,
						payload: [...languages.flat()],
					});
				});
			})
			.catch((error) => {
				dispatch({type: actionType});
				ErrorReporterActions.addToQueue({
					actionType,
					error,
				});
			});
	};
};

export const getActivityPlaces = () => {
	const actionType = ActionTypes.ACTIVITY_GET_PLACES;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/wp/v2/places`;
		getData(url)
			.then((response) => {
				dispatch({
					type: actionType,
					payload: response,
				});
			})
			.catch((error) => {
				dispatch({type: actionType});
				ErrorReporterActions.addToQueue({
					actionType,
					error,
				});
			});
	};
};

export const getActivityDifficulties = () => {
	const actionType = ActionTypes.ACTIVITY_GET_DIFFICULTIES;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/wp/v2/difficulties`;
		getData(url)
			.then((response) => {
				dispatch({
					type: actionType,
					payload: response,
				});
			})
			.catch((error) => {
				dispatch({type: actionType});
				ErrorReporterActions.addToQueue({
					actionType,
					error,
				});
			});
	};
};

export const getActivityGeneralOutcomes = (
	local = false,
	subscriptions,
	term,
	isSprigEditor = false,
	isSprigHomeUser = false,
	nsCurriculumId = 0,
	isFreeTrialReadingTeacher = false
) => {
	const actionType = ActionTypes.ACTIVITY_GET_GENERAL_OUTCOMES;
	return (dispatch) => {
		if (local && levelTwoOutcomesTaxonomy.length) {
			const response = [...levelTwoOutcomesTaxonomy];
			const outcomeInCurrentCurriculum = isSprigEditor
				? response
				: getCurrentCurriculumOutcomes(
						response,
						subscriptions,
						term,
						isSprigHomeUser,
						nsCurriculumId,
						isFreeTrialReadingTeacher
				  );
			dispatch({
				type: actionType,
				payload: outcomeInCurrentCurriculum,
			});
		} else {
			LoaderActions.addToQueue(actionType);
			const url = `${get_url()}wp-json/wp/v2/level-2-outcomes?per_page=${generalSpecificOutcomePerPage}`;
			getData(url)
				.then((response) => {
					const outcomeInCurrentCurriculum = isSprigEditor
						? response
						: getCurrentCurriculumOutcomes(
								response,
								subscriptions,
								term,
								isSprigHomeUser,
								nsCurriculumId,
								isFreeTrialReadingTeacher
						  );
					dispatch({
						type: actionType,
						payload: outcomeInCurrentCurriculum,
					});
				})
				.catch((error) => {
					console.error(`Error: ${error.message}`);
				});
		}
	};
};

export const getActivityLearningOutcomes = (
	local = false,
	subscriptions,
	term,
	isSprigEditor = false,
	isSprigHomeUser = false,
	nsCurriculumId = 0,
	isFreeTrialReadingTeacher = false
) => {
	const actionType = ActionTypes.ACTIVITY_GET_LEARNING_OUTCOMES;
	return (dispatch) => {
		if (local && levelOneOutcomesTaxonomy.length) {
			const response = [...levelOneOutcomesTaxonomy];
			const outcomeInCurrentCurriculum = isSprigEditor
				? response
				: getCurrentCurriculumOutcomes(
						response,
						subscriptions,
						term,
						isSprigHomeUser,
						nsCurriculumId,
						isFreeTrialReadingTeacher
				  );
			dispatch({
				type: actionType,
				payload: outcomeInCurrentCurriculum,
			});
		} else {
			LoaderActions.addToQueue(actionType);
			const url = `${get_url()}wp-json/wp/v2/level-1-outcomes?per_page=${learningOutcomePerPage}`;
			getData(url)
				.then((response) => {
					const outcomeInCurrentCurriculum = isSprigEditor
						? response
						: getCurrentCurriculumOutcomes(
								response,
								subscriptions,
								term,
								isSprigHomeUser,
								nsCurriculumId,
								isFreeTrialReadingTeacher
						  );
					dispatch({
						type: actionType,
						payload: outcomeInCurrentCurriculum,
					});
				})
				.catch((error) => {
					console.error(`Error: ${error.message}`);
				});
		}
	};
};

export const getActivitySpecificOutcomes = (
	local = false,
	subscriptions,
	term,
	isSprigEditor = false,
	isSprigHomeUser = false,
	nsCurriculumId = 0,
	isFreeTrialReadingTeacher = false
) => {
	const actionType = ActionTypes.ACTIVITY_GET_SPECIFIC_OUTCOMES;
	return (dispatch) => {
		if (local && levelThreeOutcomesTaxonomy.length) {
			const response = [...levelThreeOutcomesTaxonomy];
			const outcomeInCurrentCurriculum = isSprigEditor
				? [...response]
				: getCurrentCurriculumOutcomes(
						[...response],
						subscriptions,
						term,
						isSprigHomeUser,
						nsCurriculumId,
						isFreeTrialReadingTeacher
				  );
			dispatch({
				type: actionType,
				payload: outcomeInCurrentCurriculum,
			});
		} else {
			LoaderActions.addToQueue(actionType);
			const url = `${get_url()}wp-json/wp/v2/level-3-outcomes?per_page=${generalSpecificOutcomePerPage}`;
			getData(url, true)
				.then((response) => {
					const totalPages = response.headers["x-wp-totalpages"];
					const urls = Array(parseInt(totalPages, 10))
						.fill()
						.map(
							(url, page) =>
								`${get_url()}wp-json/wp/v2/level-3-outcomes?per_page=${generalSpecificOutcomePerPage}&page=${
									page + 1
								}`
						);
					Promise.all(urls.map((url) => getData(url))).then((outcomes) => {
						const outcomeInCurrentCurriculum = isSprigEditor
							? [...outcomes.flat()]
							: getCurrentCurriculumOutcomes(
									[...outcomes.flat()],
									subscriptions,
									term,
									isSprigHomeUser,
									nsCurriculumId,
									isFreeTrialReadingTeacher
							  );
						dispatch({
							type: actionType,
							payload: outcomeInCurrentCurriculum,
						});
					});
				})
				.catch((error) => {
					console.error(`Error: ${error.message}`);
				});
		}
	};
};

export const getActivityCompetencies = (
	subscriptions,
	term,
	isSprigEditor = false,
	isSprigHomeUser = false,
	nsCurriculumId = 0
) => {
	const actionType = ActionTypes.ACTIVITY_GET_COMPETENCIES;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/wp/v2/competencies?per_page=30`;
		getData(url)
			.then((response) => {
				const competenciesInCurrentCurriculum = isSprigEditor
					? response
					: getCurrentCurriculumCompetencies(
							response,
							subscriptions,
							term,
							isSprigHomeUser,
							nsCurriculumId
					  );
				dispatch({
					type: actionType,
					payload: competenciesInCurrentCurriculum,
				});
			})
			.catch((error) => {
				console.error(`Error: ${error.message}`);
			});
	};
};

export const getAllBadges = () => {
	return (dispatch) => {
		const actionType = ActionTypes.ACTIVITY_GET_ALL_BADGES;
		const url = `${get_url()}wp-json/sprig/v2/badges`;
		getData(url)
			.then((response) => {
				dispatch({
					type: actionType,
					payload: response,
				});
			})
			.catch((error) => {
				dispatch({type: actionType});
				ErrorReporterActions.addToQueue({
					actionType,
					error,
				});
			});
	};
};

export const getBadgesPerPerson = (id) => {
	const actionType = ActionTypes.ACTIVITY_GET_BADGES_PERSON;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/people/${id}/badges`;
		getData(url)
			.then((response) => {
				dispatch({
					type: actionType,
					payload: response,
				});
			})
			.catch((error) => {
				console.error(`Error: ${error.message}`);
			});
	};
};

export const updateEarnedBadges = (badges) => ({
	type: ActionTypes.ACTIVITY_UPDATE_EARNED_BADGES,
	payload: badges,
});

export const getActivityCompletionsByClassroom = (options = {}) => {
	const {classId, studentIds, reporter} = options;
	return (dispatch) => {
		let url = `${get_url()}wp-json/sprig/v2/completions?class=${classId}`;
		if (studentIds) url += `&students=${studentIds}`;
		if (reporter) url += `&reporter=${reporter}`;
		getData(url)
			.then((response) => {
				dispatch({
					type: ActionTypes.ACTIVITY_GET_ACTIVITY_COMPLETIONS_BY_CLASSROOM,
					payload: response,
				});
			})
			.catch((error) => {
				console.error(`Error: ${error.message}`);
			});
	};
};

export const getActivityDomain = (local = false, {handling} = {}) => {
	const actionType = ActionTypes.ACTIVITY_GET_DOMAIN;
	return (dispatch) => {
		if (local && domainsTaxonomy.length) {
			const response = [...domainsTaxonomy];
			if (response.length > 0) {
				if (handling?.success) {
					handling.success(response);
				}
			}
			dispatch({
				type: actionType,
				payload: response,
			});
		} else {
			const url = `${get_url()}wp-json/wp/v2/domains`;
			getData(url)
				.then((response) => {
					if (response.length > 0) {
						if (handling?.success) {
							handling.success(response);
						}
					}
					dispatch({
						type: actionType,
						payload: response,
					});
				})
				.catch((error) => {
					console.error(`Error: ${error.message}`);
				});
		}
	};
};

export const getActivityDuration = () => {
	return (dispatch) => {
		const url = `${get_url()}wp-json/wp/v2/durations`;
		getData(url)
			.then((response) => {
				dispatch({
					type: ActionTypes.ACTIVITY_GET_DURATION,
					payload: response,
				});
			})
			.catch((error) => {
				console.error(`Error: ${error.message}`);
			});
	};
};

export const getActivityGrades = () => {
	return (dispatch) => {
		const actionType = ActionTypes.ACTIVITY_GET_GRADE;
		const url = `${get_url()}wp-json/wp/v2/grades`;
		getData(url)
			.then((response) => {
				dispatch({
					type: actionType,
					payload: response,
				});
			})
			.catch((error) => {
				dispatch({type: actionType});
				ErrorReporterActions.addToQueue({
					actionType,
					error,
				});
			});
	};
};

export const getActivityCurriculum = (local = false, {handling} = {}) => {
	return (dispatch) => {
		if (local && curriculaTaxonomy) {
			const response = [...curriculaTaxonomy];
			if (handling?.success) handling.success(response);
			dispatch({
				type: ActionTypes.ACTIVITY_GET_CURRICULA,
				payload: response,
			});
		} else {
			const url = `${get_url()}wp-json/wp/v2/curricula`;
			getData(url)
				.then((response) => {
					if (handling?.success) handling.success(response);
					dispatch({
						type: ActionTypes.ACTIVITY_GET_CURRICULA,
						payload: response,
					});
				})
				.catch((error) => {
					console.error(`Error: ${error.message}`);
				});
		}
	};
};

export const completeActivity = (activityId, {students, notification}) => {
	const actionType = ActionTypes.ACTIVITY_POST_COMPLETE_ACTIVITY;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/wp/v2/activity/${activityId}/complete`;
		postData(url, {students})
			.then((response) => {
				if (notification?.success) notification.success();
				if (response.data.length) {
					ActivityActions.updateEarnedBadges(response.data);
				}
				dispatch({
					type: actionType,
					payload: activityId,
				});
			})
			.catch((error) => {
				if (notification?.error) notification.error(error.data.message);
			});
	};
};

export const hideActivity = (
	{activityId, hideOptionId, otherReason},
	{handling}
) => {
	const actionType = ActionTypes.ACTIVITY_PUT_HIDE_ACTIVITY;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/activities/${activityId}/hide`;
		putData(url, {
			option_id: hideOptionId,
			remark: otherReason,
		})
			.then(() => {
				handling.success();
				dispatch({
					type: actionType,
				});
			})
			.catch((error) => {
				dispatch({type: actionType});
				ErrorReporterActions.addToQueue({
					actionType,
					error,
				});
				handling.error();
			});
	};
};

export const unhideActivity = (activityId, {handling}) => {
	const actionType = ActionTypes.ACTIVITY_PUT_UNHIDE_ACTIVITY;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/activities/${activityId}/unhide`;
		putData(url)
			.then(() => {
				handling.success();
				dispatch({
					type: actionType,
				});
			})
			.catch((error) => {
				dispatch({type: actionType});
				ErrorReporterActions.addToQueue({
					actionType,
					error,
				});
			});
	};
};

export const sendThumbsUpDownFeedback = (
	{activityId, liked, option_id, remark},
	{handling}
) => {
	const actionType = ActionTypes.ACTIVITY_PUT_SEND_THUMBS_UP_DOWN_FEEDBACK;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/activities/${activityId}/feedbackFormEntries`;
		putData(url, {liked, option_id, remark})
			.then((response) => {
				handling.success(response);
				dispatch({
					type: actionType,
				});
			})
			.catch((error) => {
				dispatch({type: actionType});
				ErrorReporterActions.addToQueue({
					actionType,
					error,
				});
				handling.error(error);
			});
	};
};

export const getActivityComment = (activityId) => {
	const actionType = ActionTypes.ACTIVITY_GET_ACTIVITY_COMMENT;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/activities/${activityId}/feedback`;
		getData(url)
			.then((response) => {
				dispatch({
					type: actionType,
					payload: {response, activityId},
				});
			})
			.catch((error) => {
				dispatch({type: actionType});
				ErrorReporterActions.addToQueue({
					actionType,
					error,
				});
			});
	};
};

export const postActivityComment = (activityId, body, {handling}) => {
	const actionType = ActionTypes.ACTIVITY_POST_ACTIVITY_COMMENT;
	return () => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/activities/${activityId}/feedback`;
		postData(url, body)
			.then((response) => {
				if (response.status < 300) {
					handling.success();
				} else {
					handling.error(response);
				}
			})
			.catch((error) => {
				handling.error(error);
			});
	};
};

export const getLearningOutcomesByDomain = (local, domain) => {
	const actionType = ActionTypes.ACTIVITY_GET_LEARNING_OUTCOMES_BY_DOMAIN;
	return (dispatch) => {
		if (local && levelOneOutcomesTaxonomy.length) {
			const response = [...levelOneOutcomesTaxonomy];
			const outcomeInCurrentDomain = response.filter(
				(outcome) => outcome.domain === domain.id
			);
			dispatch({
				type: actionType,
				payload: {response: outcomeInCurrentDomain, domain},
			});
		} else {
			LoaderActions.addToQueue(actionType);
			const url = `${get_url()}wp-json/wp/v2/level-1-outcomes?per_page=${learningOutcomePerPage}`;
			getData(url)
				.then((response) => {
					const outcomeInCurrentDomain = response.filter(
						(outcome) => outcome.domain === domain.id
					);
					dispatch({
						type: actionType,
						payload: {response: outcomeInCurrentDomain, domain},
					});
				})
				.catch((error) => {
					console.error(`Error: ${error.message}`);
				});
		}
	};
};

export const getGeneralOutcomesByDomain = (local = false, domain) => {
	const actionType = ActionTypes.ACTIVITY_GET_GENERAL_OUTCOMES_BY_DOMAIN;
	return (dispatch) => {
		if (local && levelTwoOutcomesTaxonomy.length) {
			const response = [...levelTwoOutcomesTaxonomy];
			const outcomeInCurrentDomain = response.filter(
				(outcome) => outcome.domain === domain.id
			);
			dispatch({
				type: actionType,
				payload: {response: outcomeInCurrentDomain, domain},
			});
		} else {
			LoaderActions.addToQueue(actionType);
			const url = `${get_url()}wp-json/wp/v2/level-2-outcomes?per_page=${generalSpecificOutcomePerPage}`;
			getData(url)
				.then((response) => {
					const outcomeInCurrentDomain = response.filter(
						(outcome) => outcome.domain === domain.id
					);
					dispatch({
						type: actionType,
						payload: {response: outcomeInCurrentDomain, domain},
					});
				})
				.catch((error) => {
					console.error(`Error: ${error.message}`);
				});
		}
	};
};

export const getActivitySpecificOutcomesByDomain = (local = false, domain) => {
	const actionType = ActionTypes.ACTIVITY_GET_SPECIFIC_OUTCOMES_BY_DOMAIN;
	return (dispatch) => {
		if (local && levelThreeOutcomesTaxonomy.length) {
			const response = [...levelThreeOutcomesTaxonomy];
			const outcomeInCurrentDomain = response.filter(
				(outcome) => outcome.domain === domain.id
			);
			dispatch({
				type: actionType,
				payload: {response: outcomeInCurrentDomain, domain},
			});
		} else {
			LoaderActions.addToQueue(actionType);
			const url = `${get_url()}wp-json/wp/v2/level-3-outcomes?per_page=${generalSpecificOutcomePerPage}`;
			getData(url, true)
				.then((response) => {
					const totalPages = response.headers["x-wp-totalpages"];
					const urls = Array(parseInt(totalPages, 10))
						.fill()
						.map(
							(url, page) =>
								`${get_url()}wp-json/wp/v2/level-3-outcomes?per_page=${generalSpecificOutcomePerPage}&page=${
									page + 1
								}`
						);
					Promise.all(urls.map((url) => getData(url))).then((outcomes) => {
						const outcomeInCurrentDomain = outcomes
							.flat()
							.filter((outcome) => outcome.domain === domain.id);
						dispatch({
							type: actionType,
							payload: {response: outcomeInCurrentDomain, domain},
						});
					});
				})
				.catch((error) => {
					console.error(`Error: ${error.message}`);
				});
		}
	};
};

export const getActivityAssessmentQuestions = ({activityId, assessmentId}) => {
	const actionType =
		ActionTypes.ACTIVITY_GET_ACTIVITY_ASSESSMENT_QUESTIONS_BY_ASSESSMENT_ID;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/questions?assessment=${assessmentId}`;
		getData(url)
			.then((response) => {
				dispatch({
					type: actionType,
					payload: {activityId, response},
				});
			})
			.catch((error) => {
				dispatch({type: actionType});
				ErrorReporterActions.addToQueue({
					actionType,
					error,
				});
			});
	};
};
