import "../../../../../../Stylesheets/css/scss/Components/Portal/Public/Assessment/Public/QuestionTable.css";

import React from "react";

import {QuestionRow} from "./QuestionRow";

interface TableData {
	headers: string[];
	question?: string;
	content: Section[];
}

interface Section {
	groupLabel: string;
	questions: Question[];
}

export interface Question {
	img: Image;
	label: string;
	selectionAnswerKey: QuestionAnswerKey[];
	selectionValues: string[];
}

interface Image {
	src: string;
	alt: string;
}

export interface QuestionAnswerKey {
	text: string;
	value: string;
}

export const QuestionTable = ({data}: {data: TableData}) => (
	<div className="assessment-question-table">
		<div className="assessment-question-table-headers">
			{data?.headers && <div className="group-label-spacer" />}
			{data?.headers && <div className="img-spacer">{data?.question}</div>}
			<div className="assessment-question-table-header-options">
				{data?.headers.map((header) => (
					<div key={header} className="assessment-question-table-header">
						{header}
					</div>
				))}
			</div>
		</div>
		<div className="assessment-question-table-rows">
			{data.content.map((section, index) => (
				<div
					key={`assessment-question-table-${index}`}
					className="assessment-question-table-grouping"
				>
					<div className="assessment-question-table-grouping-label">
						<div className="assessment-question-rotated-label">
							{section.groupLabel}
						</div>
					</div>
					<div className="assessment-question-table-grouping-question">
						{section.questions.map((question) => (
							<QuestionRow key={question.label} {...question} />
						))}
					</div>
				</div>
			))}
		</div>
	</div>
);
