import React from "react";
import {Trans} from "react-i18next";

import {ADMIN_REQUEST_TYPE} from "../../../../../Scripts/Constants";
import {
	AdminRequestNotificationData,
	NotificationModel,
} from "../../../../../Scripts/PublicInterfaces";

export const IssueResolved = ({
	notification,
}: {
	notification: NotificationModel<AdminRequestNotificationData>;
}) => {
	const {data} = notification;
	if (data) {
		const requestType = data.request_type;
		const addedOrRemoved =
			requestType === ADMIN_REQUEST_TYPE.ADD ? `added` : `removed`;
		const requestPhrase = `phrase.student_has_been_${addedOrRemoved}`;
		const studentName =
			requestType === ADMIN_REQUEST_TYPE.ADD
				? `${data.new_first_name} ${data.new_last_name}`
				: data.student_name;
		return (
			<div className="col-10">
				<small className="nun-semibold">
					{requestType === ADMIN_REQUEST_TYPE.EDIT && (
						<Trans i18nKey="phrase.student_has_been_edit">
							{[
								<span className="nun-bold" key="studentName">
									{{studentName}}
								</span>,
							]}
						</Trans>
					)}
					{(requestType === ADMIN_REQUEST_TYPE.REMOVE ||
						requestType === ADMIN_REQUEST_TYPE.ADD) && (
						<Trans i18nKey={requestPhrase}>
							{[
								<span key="studentName" className="nun-bold">
									{{studentName}}
								</span>,
								<span key="classroom" className="nun-bold">
									{{classroom: data.class_name}}
								</span>,
							]}
						</Trans>
					)}
					{requestType === ADMIN_REQUEST_TYPE.ADD_TEACHER && (
						<Trans i18nKey="admin.add_user_resolved">
							{[
								<span key="teacherName" className="nun-bold">
									{{
										user: `${data.new_first_name} ${data.new_last_name}`,
									}}
								</span>,
								<span key="classroom" className="nun-bold">
									{{classroom: data.class_name}}
								</span>,
							]}
						</Trans>
					)}
				</small>
			</div>
		);
	}
	return null;
};
