import React, {Fragment} from "react";
import {Route} from "react-router-dom";

import {Title} from "./index";

interface AppRouteWithPropsProps {
	title: string;
	meta?: string;
	component: React.ElementType;
	urlAction: {};
	path: string;
}

export const AppRouteWithProps = ({
	title,
	meta,
	component: Component,
	urlAction,
	...props
}: AppRouteWithPropsProps) => (
	<Fragment>
		{title || meta ? <Title title={title} meta={meta} /> : null}
		<Route
			{...props}
			render={(routeProps) => (
				<Component {...routeProps} urlAction={urlAction} />
			)}
		/>
	</Fragment>
);
