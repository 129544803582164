import "../../../../Stylesheets/css/scss/Components/Portal/Public/Inputs/ExpandableSearch.css";

import i18n from "i18next";
import React, {Component} from "react";
import ReactTooltip from "react-tooltip";

import {
	AUTOMATION_TEST_ID,
	KEY_EVENT,
	PORTALS,
	STYLED_ELEMENT,
} from "../../../../Scripts/Constants";
import {portalClassSelector} from "../../../../Scripts/Utilities";
import {Icon} from "../../../Public";

interface ExpandableSearchProps {
	onSearch: (query: string) => void;
	placeholder: string;
	query?: string;
	inputId: string;
	portal?: string;
	searchButtonId?: string;
}

interface ExpandableSearchState {
	available: boolean;
	query: string;
}

export class ExpandableSearch extends Component<
	ExpandableSearchProps,
	ExpandableSearchState
> {
	private inputSearch: HTMLElement;

	constructor(props: ExpandableSearchProps) {
		super(props);
		this.state = {
			available: props.query ? true : false,
			query: props.query ? props.query : "",
		};
		this.inputSearch = document.createElement("input");
	}

	componentDidMount = () => {
		const {query} = this.props;
		if (query) {
			this.setState({
				query,
				available: true,
			});
		}
	};

	componentDidUpdate = (prevProps) => {
		const {query} = this.props;
		if (query && query !== prevProps.query) {
			this.setState({
				query,
				available: true,
			});
		} else if (query === "" && query !== prevProps.query) {
			this.setState({
				query: "",
				available: false,
			});
		}
	};

	handleKeyDown = (event) => {
		const {available, query} = this.state;
		if (event.key === KEY_EVENT.ENTER) {
			if (available && query) this.props.onSearch(query);
		}
	};

	handleChange = (event) => {
		this.setState({
			query: event.target.value,
		});
	};

	handleClick = () => {
		const {available, query} = this.state;
		if (!available) {
			this.setState({available: true});
			this.inputSearch.focus();
		}
		if (available && query) this.props.onSearch(query);
	};

	handleClose = () => {
		this.setState({query: "", available: false}, () => {
			this.props.onSearch(this.state.query);
		});
	};

	render() {
		const {available, query} = this.state;
		const {
			placeholder,
			searchButtonId,
			inputId,
			portal = PORTALS.EDUCATOR,
		} = this.props;
		const closeButtonClassname = portalClassSelector(
			portal,
			STYLED_ELEMENT.BUTTON,
			STYLED_ELEMENT.OPTIONS.ALT
		);
		return (
			<div
				className={`component-expandable-search${
					available ? " open" : ""
				} flex align-center`}
				id={AUTOMATION_TEST_ID.PUBLIC.COMPONENTS.EXPANDABLE_SEARCH}
			>
				<div
					className="search-button btn edu-btn-light-bg"
					onClick={this.handleClick}
					id={searchButtonId}
					data-tip={placeholder}
				>
					<Icon type="faSearch" className={`${available ? "mr-2 " : ""}`} />
				</div>
				<input
					value={query}
					placeholder={placeholder ? placeholder : i18n.t("admin.search")}
					onChange={this.handleChange}
					onKeyDown={this.handleKeyDown}
					ref={(inputSearch) =>
						(this.inputSearch = inputSearch as HTMLInputElement)
					}
					id={inputId}
				/>
				{available ? (
					<div
						className="close-button mr-1"
						onClick={this.handleClose}
						id={
							AUTOMATION_TEST_ID.PUBLIC.COMPONENTS
								.EXPANDABLE_SEARCH_CLOSE_BUTTON
						}
					>
						<Icon type="faTimesCircle" className={closeButtonClassname} />
					</div>
				) : null}
				<ReactTooltip type="dark" effect="solid" place="bottom" />
			</div>
		);
	}
}
