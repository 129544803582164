import i18n from "i18next";
import React from "react";

import {ActivityActions} from "../Actions";
import {ReportChart} from "../Components/Portal/PublicComponents";
import {
	ACTIVITY_TAG,
	ACTIVITY_TAG_STATS_REPORT,
	ACTIVITY_USER,
	CHART_TYPE,
	LEVEL_ONE_OUTCOME_SLUG,
	OUTCOME_LEVEL,
	PROVINCE_SLUG,
	REPORT_CHART_TYPE,
	TIME_PERIOD,
} from "./Constants";
import {DOMAIN_MATH} from "./Domain";
import {ReportActivityTagStatsCounts} from "./Interfaces";
import {sanitizeHTML} from "./Security";

export const sanitizeContent = (content, type) => {
	return sanitizeHTML(content, type);
};

export const getActivitiesCount = ({
	type,
	count,
	countUniqueActivity = false,
}: {
	type: string;
	count: ReportActivityTagStatsCounts;
	countUniqueActivity: boolean;
}) => {
	if (count === undefined) return 0;
	switch (type) {
		case TIME_PERIOD.WEEK:
			return countUniqueActivity
				? count.unique_this_week
				: count.total_this_week;
		case TIME_PERIOD.MONTH:
			return countUniqueActivity
				? count.unique_this_month
				: count.total_this_month;
		case TIME_PERIOD.TERM:
			return countUniqueActivity
				? count.unique_this_term
				: count.total_this_term;
		default:
			throw new Error(`Unsupported period type ${type}`);
	}
};

export const getSingleTagStatsCount = ({
	reportCounts,
	tagId,
	type,
	countUniqueActivity = false,
}: {
	reportCounts: ReportActivityTagStatsCounts[];
	tagId: number;
	type: string;
	countUniqueActivity?: boolean;
}) => {
	const outcomeCount = reportCounts.find((count) => {
		return count.tag_id === tagId;
	});
	if (outcomeCount)
		return getActivitiesCount({type, count: outcomeCount, countUniqueActivity});
	return 0;
};

const getMathOutcomesCompletion = (
	reportCounts,
	content,
	taxonomies,
	curriculumSlug
) => {
	switch (curriculumSlug) {
		case PROVINCE_SLUG.ON: {
			const mathOutcomesCompletion = [
				LEVEL_ONE_OUTCOME_SLUG.A_SOCIAL_EMOTIONAL_LEARNING,
				LEVEL_ONE_OUTCOME_SLUG.B_NUMBERS,
				LEVEL_ONE_OUTCOME_SLUG.C_ALGEBRA,
				LEVEL_ONE_OUTCOME_SLUG.D_DATA,
				LEVEL_ONE_OUTCOME_SLUG.E_SPATIAL_SENSE,
				LEVEL_ONE_OUTCOME_SLUG.F_FINANCIAL_LITERACY,
			].map((slug) => {
				return getSingleTagStatsCount({
					reportCounts,
					tagId: taxonomies.find((outcome) => outcome.slug === slug).id,
					type: content,
				});
			});
			const mathTotal = mathOutcomesCompletion.reduce((sum, currentValue) => {
				return sum + currentValue;
			});
			return [mathTotal, ...mathOutcomesCompletion];
		}
		default: {
			const mathOutcomesCompletion = [
				LEVEL_ONE_OUTCOME_SLUG.NUMBER_SENSE,
				LEVEL_ONE_OUTCOME_SLUG.PATTERNS_AND_RELATIONS,
				LEVEL_ONE_OUTCOME_SLUG.MEASUREMENT,
				LEVEL_ONE_OUTCOME_SLUG.GEOMETRY,
			].map((slug) => {
				return getSingleTagStatsCount({
					reportCounts,
					tagId: taxonomies.find((outcome) => outcome.slug === slug).id,
					type: content,
				});
			});
			const mathTotal = mathOutcomesCompletion.reduce((sum, currentValue) => {
				return sum + currentValue;
			});
			return [mathTotal, ...mathOutcomesCompletion];
		}
	}
};

const getOralLanguageOutcomesCompletion = (
	reportCounts,
	content,
	taxonomies
) => {
	const languageOutcomesCompletion = [
		LEVEL_ONE_OUTCOME_SLUG.LISTENING_SPEAKING,
		LEVEL_ONE_OUTCOME_SLUG.READING_VIEWING,
	].map((slug) => {
		return getSingleTagStatsCount({
			reportCounts,
			tagId: taxonomies.find((outcome) => outcome.slug === slug).id,
			type: content,
		});
	});
	const oralLanguageTotal = languageOutcomesCompletion.reduce(
		(sum, currentValue) => {
			return sum + currentValue;
		}
	);
	return [oralLanguageTotal, ...languageOutcomesCompletion];
};

export const getDomainData = (domainId, curriculumSlug) => {
	if (domainId === DOMAIN_MATH) {
		switch (curriculumSlug) {
			case PROVINCE_SLUG.ON:
				return {
					labels: [
						i18n.t("domain.math"),
						i18n.t("domain.math_outcome.a-socialemotionallearning-short"),
						i18n.t("domain.math_outcome.b-numbers"),
						i18n.t("domain.math_outcome.c-algebra"),
						i18n.t("domain.math_outcome.d-data"),
						i18n.t("domain.math_outcome.e-spatialsense"),
						i18n.t("domain.math_outcome.f-financialliteracy"),
					],
					backgroundColor: [
						"#FF8F1A",
						"#6BC4C3",
						"#8FD3D2",
						"#6BC4C3",
						"#8FD3D2",
						"#6BC4C3",
						"#8FD3D2",
					],
				};
			default:
				return {
					labels: ["Math", "NS", "PR", "M", "G"],
					backgroundColor: [
						"#FF8F1A",
						"#6BC4C3",
						"#8FD3D2",
						"#6BC4C3",
						"#8FD3D2",
					],
				};
		}
	} else {
		return {
			labels: ["OL", "LS", "RV"],
			backgroundColor: ["#FF8F1A", "#6BC4C3", "#8FD3D2"],
		};
	}
};

export const getChartDataColor = () => [
	"#6bc4c3",
	"#5dc8b3",
	"#61ca9b",
	"#76ca7d",
	"#93c75b",
	"#b4c138",
	"#d9b613",
	"#ffa600",
	"#d78c0d",
	"#b07413",
	"#8b5c14",
	"#674514",
	"#462f11",
	"#261b0c",
	"#685e54",
	"#b1aba5",
	"#989089",
	"#672936",
	"#773531",
	"#82452b",
	"#885724",
	"#866b22",
	"#7e802a",
	"#6f943d",
	"#54a85b",
	"#00a775",
	"#00a491",
	"#009fac",
	"#0098c2",
	"#008ecf",
	"#0082d2",
	"#5074ca",
	"#0087d7",
	"#0097d4",
	"#00a4c2",
	"#00aea7",
	"#00b585",
	"#5bb965",
	"#97ba4c",
	"#6fb969",
	"#4cb584",
	"#33ae9a",
	"#37a6a8",
	"#4f9cac",
	"#6692a6",
	"#778899",
	"#5ca2b4",
	"#43bcb3",
	"#6dd194",
	"#bbdd69",
];

export const getReportChart = (data, reportName) => {
	switch (reportName) {
		case ACTIVITY_TAG_STATS_REPORT.LEARNING_OUTCOMES.DIVISION:
		case ACTIVITY_TAG_STATS_REPORT.LEARNING_OUTCOMES.CLASS:
		case ACTIVITY_TAG_STATS_REPORT.LEARNING_OUTCOMES.SCHOOL:
		case ACTIVITY_TAG_STATS_REPORT.LEARNING_OUTCOMES.SCHOOLS:
		case ACTIVITY_TAG_STATS_REPORT.LEARNING_OUTCOMES.STUDENT:
		case ACTIVITY_TAG_STATS_REPORT.LEARNING_OUTCOMES.CLASSROOMS: {
			return (
				<ReportChart
					chartType={CHART_TYPE.BAR}
					data={data}
					type={REPORT_CHART_TYPE.ACTIVITY_COMPLETION}
				/>
			);
		}
		case ACTIVITY_TAG_STATS_REPORT.COMPETENCY.DIVISION:
		case ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.DIVISION:
		case ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.DIVISION:
		case ACTIVITY_TAG_STATS_REPORT.COMPETENCY.CLASS:
		case ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.CLASS:
		case ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.CLASS:
		case ACTIVITY_TAG_STATS_REPORT.COMPETENCY.SCHOOL:
		case ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.SCHOOL:
		case ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.SCHOOL:
		case ACTIVITY_TAG_STATS_REPORT.COMPETENCY.SCHOOLS:
		case ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.SCHOOLS:
		case ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.SCHOOLS:
		case ACTIVITY_TAG_STATS_REPORT.COMPETENCY.STUDENT:
		case ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.STUDENT:
		case ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.STUDENT:
		case ACTIVITY_TAG_STATS_REPORT.COMPETENCY.CLASSROOMS:
		case ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.CLASSROOMS:
		case ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.CLASSROOMS: {
			return (
				<ReportChart
					chartType={CHART_TYPE.PIE}
					data={data}
					type={REPORT_CHART_TYPE.ACTIVITY_TAG_COMPLETION}
				/>
			);
		}
		default:
			return (
				<ReportChart
					chartType={CHART_TYPE.BAR}
					data={data}
					type={REPORT_CHART_TYPE.ACTIVITY_COMPLETION}
				/>
			);
	}
};

export const getCurrentDomainItems = (items, domain) => {
	return items.filter((item) => item.domain === domain);
};

export const getCurrentReports = (
	reportCounts,
	content,
	reportName,
	currentDomain,
	currentParentOutcome,
	taxonomies,
	curriculumSlug
) => {
	switch (reportName) {
		case ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.STUDENT:
		case ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.CLASS:
		case ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.SCHOOL:
		case ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.CLASSROOMS:
		case ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.SCHOOLS:
		case ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.DIVISION:
		case ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.DIVISION:
		case ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.SCHOOLS:
		case ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.CLASSROOMS:
		case ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.SCHOOL:
		case ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.STUDENT:
		case ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.CLASS: {
			return getOutcomesData(
				reportCounts,
				content,
				currentParentOutcome,
				taxonomies
			);
		}
		case ACTIVITY_TAG_STATS_REPORT.LEARNING_OUTCOMES.DIVISION:
		case ACTIVITY_TAG_STATS_REPORT.LEARNING_OUTCOMES.SCHOOLS:
		case ACTIVITY_TAG_STATS_REPORT.LEARNING_OUTCOMES.CLASSROOMS:
		case ACTIVITY_TAG_STATS_REPORT.LEARNING_OUTCOMES.SCHOOL:
		case ACTIVITY_TAG_STATS_REPORT.LEARNING_OUTCOMES.STUDENT:
		case ACTIVITY_TAG_STATS_REPORT.LEARNING_OUTCOMES.CLASS: {
			return getActivityRollUpData(
				reportCounts,
				content,
				currentDomain,
				taxonomies,
				curriculumSlug
			);
		}
		case ACTIVITY_TAG_STATS_REPORT.COMPETENCY.STUDENT:
		case ACTIVITY_TAG_STATS_REPORT.COMPETENCY.CLASS:
		case ACTIVITY_TAG_STATS_REPORT.COMPETENCY.SCHOOL:
		case ACTIVITY_TAG_STATS_REPORT.COMPETENCY.CLASSROOMS:
		case ACTIVITY_TAG_STATS_REPORT.COMPETENCY.SCHOOLS:
		case ACTIVITY_TAG_STATS_REPORT.COMPETENCY.DIVISION:
			return getActivityTagRollUpData(
				reportCounts,
				content,
				taxonomies,
				currentDomain
			);
		default:
			throw new Error(`Unsupported report name: ${reportName}`);
	}
};

const getOutcomesData = (
	classReportCounts,
	content,
	currentParentOutcome,
	taxonomies
) => {
	const currentOutcomes = getItemsWithSameParent(
		taxonomies,
		currentParentOutcome
	);
	const currentOutcomeIds = currentOutcomes.map((outcome) => outcome.id);
	const outcomeCompletion = currentOutcomeIds.map((id) =>
		getSingleTagStatsCount({
			reportCounts: classReportCounts,
			tagId: id,
			type: content,
		})
	);
	const allValuesZero = outcomeCompletion.every((count) => count === 0);
	const labels = [
		...currentOutcomes.map((outcome) => outcome.name),
		...(allValuesZero ? [i18n.t("phrase.complete_activity_to_see_data")] : []),
	];
	const data = getChartData(labels, outcomeCompletion, allValuesZero);
	return data;
};

const getActivityTagRollUpData = (
	classReportCounts,
	content,
	taxonomies,
	currentDomain
) => {
	const currentDomainOutcomes = getCurrentDomainItems(
		taxonomies,
		currentDomain
	);
	const taxonomyIds = currentDomainOutcomes.map((taxonomy) => taxonomy.id);
	const outcomeCompletion = taxonomyIds.map((taxonomyId) =>
		getSingleTagStatsCount({
			reportCounts: classReportCounts,
			tagId: taxonomyId,
			type: content,
		})
	);
	const allValuesZero = outcomeCompletion.every((count) => count === 0);
	const labels = [
		...currentDomainOutcomes.map((taxonomy) => taxonomy.name),
		...(allValuesZero ? [i18n.t("phrase.complete_activity_to_see_data")] : []),
	];
	const data = getChartData(labels, outcomeCompletion, allValuesZero);
	return data;
};

const getChartData = (labels, outcomeCompletion, allValuesZero) => ({
	labels,
	datasets: [
		{
			data: [...outcomeCompletion, allValuesZero ? 1 : []],
			backgroundColor: [
				...getChartDataColor().slice(0, outcomeCompletion.length),
				allValuesZero ? "#dee2e6" : "white",
			],
			hoverBackgroundColor: [
				...getChartDataColor().slice(0, outcomeCompletion.length),
				allValuesZero ? "#dee2e6" : "white",
			],
		},
	],
});

const getActivityRollUpDataByDomain = (
	domainId,
	reportCounts,
	content,
	taxonomies,
	curriculumSlug
) => {
	if (domainId === DOMAIN_MATH) {
		return getMathOutcomesCompletion(
			reportCounts,
			content,
			taxonomies,
			curriculumSlug
		);
	} else {
		return getOralLanguageOutcomesCompletion(reportCounts, content, taxonomies);
	}
};

const getActivityRollUpData = (
	reportCounts,
	content,
	currentDomain,
	taxonomies,
	curriculumSlug
) => {
	const chartData = getActivityRollUpDataByDomain(
		currentDomain,
		reportCounts,
		content,
		taxonomies,
		curriculumSlug
	);
	const chartSettings = getDomainData(currentDomain, curriculumSlug);
	const data = {
		labels: chartSettings.labels,
		datasets: [
			{
				label: i18n.t("admin.activities_completed"),
				backgroundColor: chartSettings.backgroundColor,
				borderColor: "white",
				borderWidth: 1,
				hoverBackgroundColor: "#F88D25",
				hoverBorderColor: "#F88D25",
				data: chartData,
			},
		],
	};
	return data;
};

export const getItemsWithSameParent = (items, parent) => {
	return items.filter((item) => item.parent === parent);
};

export const findCurrentItem = (outcomeId, taxonomies) => {
	const item = taxonomies.find((outcome) => outcomeId === outcome.id);
	return item;
};

export const getTooltips = (tooltipItem) => {
	switch (tooltipItem[0].label) {
		case "OL":
			return i18n.t("domain.oral_language");
		case "RV":
			return i18n.t("domain.oral_language_outcome.reading-and-viewing");
		case "LS":
			return i18n.t("domain.oral_language_outcome.listening-and-speaking");
		case "Math":
			return i18n.t("domain.math");
		case "NS":
			return i18n.t("domain.math_outcome.number-sense");
		case "PR":
			return i18n.t("domain.math_outcome.patterns-and-relations");
		case "M":
			return i18n.t("domain.math_outcome.measurement");
		case "G":
			return i18n.t("domain.math_outcome.geometry");
		default:
			break;
	}
};

export const getOriginalActivityId = (currentActivity: {
	translations: {[languageCode: string]: string};
}) => {
	const {translations} = currentActivity;
	const translationIds = Object.values(translations).map((translation) =>
		parseInt(translation, 10)
	);
	return Math.min(...translationIds);
};

export const getDropdownLabel = (reportName) => {
	switch (reportName) {
		case ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.STUDENT:
		case ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.CLASS:
		case ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.SCHOOL:
		case ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.CLASSROOMS:
		case ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.SCHOOLS:
		case ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.DIVISION:
			return `general_curriculum_outcome`;
		case ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.STUDENT:
		case ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.CLASS:
		case ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.SCHOOL:
		case ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.CLASSROOMS:
		case ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.SCHOOLS:
		case ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.DIVISION:
			return `learning_outcome`;
		default:
			throw new Error(`Unsupported report type: ${reportName}`);
	}
};

export const getChartTitle = (reportName) => {
	switch (reportName) {
		case ACTIVITY_TAG_STATS_REPORT.LEARNING_OUTCOMES.STUDENT:
		case ACTIVITY_TAG_STATS_REPORT.COMPETENCY.STUDENT:
		case ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.STUDENT:
		case ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.STUDENT:
			return `activities_completed_student`;
		case ACTIVITY_TAG_STATS_REPORT.LEARNING_OUTCOMES.CLASS:
		case ACTIVITY_TAG_STATS_REPORT.COMPETENCY.CLASS:
		case ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.CLASS:
		case ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.CLASS:
		case ACTIVITY_TAG_STATS_REPORT.LEARNING_OUTCOMES.CLASSROOMS:
		case ACTIVITY_TAG_STATS_REPORT.COMPETENCY.CLASSROOMS:
		case ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.CLASSROOMS:
		case ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.CLASSROOMS:
			return `activities_completed_classroom`;
		case ACTIVITY_TAG_STATS_REPORT.LEARNING_OUTCOMES.SCHOOL:
		case ACTIVITY_TAG_STATS_REPORT.COMPETENCY.SCHOOL:
		case ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.SCHOOL:
		case ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.SCHOOL:
		case ACTIVITY_TAG_STATS_REPORT.LEARNING_OUTCOMES.SCHOOLS:
		case ACTIVITY_TAG_STATS_REPORT.COMPETENCY.SCHOOLS:
		case ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.SCHOOLS:
		case ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.SCHOOLS:
			return `activities_completed_school`;
		case ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.DIVISION:
		case ACTIVITY_TAG_STATS_REPORT.LEARNING_OUTCOMES.DIVISION:
		case ACTIVITY_TAG_STATS_REPORT.COMPETENCY.DIVISION:
		case ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.DIVISION:
			return `activities_completed_client`;
		default:
			throw new Error(`Unsupported report: ${reportName}`);
	}
};

export const setFiltersGetActivities = (filter, clearActivities?: boolean) => {
	if (clearActivities) ActivityActions.clearActivities();
	ActivityActions.setFilters(filter);
	ActivityActions.getActivities(filter);
};

export const getMessageAndTitleForActivityNotification = (
	action,
	error,
	activityTitle,
	saved
) => {
	switch (action) {
		case ACTIVITY_USER.ACTION.RECOMMEND: {
			const title = error
				? i18n.t("error.failed_recommend_activity")
				: i18n.t("admin.activity_recommended");
			const message = error
				? i18n.t("error.failed_recommend_activity_message", {
						activity: activityTitle,
						error,
				  })
				: i18n.t("admin.activity_recommended_message", {
						activity: activityTitle,
				  });
			return {title, message};
		}
		case ACTIVITY_USER.ACTION.SAVE: {
			if (error) {
				const title = i18n.t(
					saved ? "error.failed_unsave_activity" : "error.failed_save_activity"
				);
				const message = i18n.t(
					saved
						? "admin.failed_unsave_activity_message"
						: "error.failed_save_activity_message",
					{
						activity: activityTitle,
						error,
					}
				);
				return {title, message};
			} else {
				const title = saved
					? i18n.t("admin.activity_unsaved")
					: i18n.t("admin.activity_saved");

				const message = i18n.t(
					saved
						? "admin.activity_unsaved_message"
						: "admin.activity_saved_message",
					{
						activity: activityTitle,
					}
				);
				return {title, message};
			}
		}
		case ACTIVITY_USER.ACTION.COMPLETE: {
			const title = error
				? i18n.t("error.failed_complete_activity")
				: i18n.t("admin.activity_completed");
			const message = error
				? i18n.t("error.failed_complete_activity_message", {
						activity: activityTitle,
						error,
				  })
				: i18n.t("admin.activity_completed_message", {
						activity: activityTitle,
				  });
			return {title, message};
		}
		default:
			break;
	}
};

export const getCardHeaderBackgroundColor = ({
	taxonomies,
	activity,
	filters,
}) => {
	const filterOutCurrentOutcomes = activity[
		ACTIVITY_TAG[OUTCOME_LEVEL.ONE]
	].filter((outcomeId) => {
		return taxonomies.learningOutcomes.find(
			(outcome) => outcome.id === outcomeId
		);
	});
	if (
		filters[ACTIVITY_TAG[OUTCOME_LEVEL.ONE]] &&
		filterOutCurrentOutcomes.includes(filters[ACTIVITY_TAG[OUTCOME_LEVEL.ONE]])
	) {
		return getColourByOutcomeId(
			filters[ACTIVITY_TAG[OUTCOME_LEVEL.ONE]],
			taxonomies
		);
	} else if (filters[ACTIVITY_TAG[OUTCOME_LEVEL.TWO]]) {
		const currentGeneralOutcome = taxonomies.generalOutcomes.find(
			(outcome) => outcome.id === filters[ACTIVITY_TAG[OUTCOME_LEVEL.TWO]]
		);
		if (
			currentGeneralOutcome &&
			filterOutCurrentOutcomes.includes(currentGeneralOutcome.parent)
		) {
			return getColourByOutcomeId(currentGeneralOutcome.parent, taxonomies);
		} else {
			return getColourByOutcomeId(filterOutCurrentOutcomes?.[0], taxonomies);
		}
	} else if (filters[ACTIVITY_TAG[OUTCOME_LEVEL.THREE]]) {
		const currentSpecificOutcome = taxonomies.specificOutcomes.find(
			(outcome) => outcome.id === filters[ACTIVITY_TAG[OUTCOME_LEVEL.THREE]]
		);
		if (
			currentSpecificOutcome &&
			filterOutCurrentOutcomes.includes(currentSpecificOutcome.parent_parent)
		) {
			return getColourByOutcomeId(
				currentSpecificOutcome.parent_parent,
				taxonomies
			);
		} else {
			return getColourByOutcomeId(filterOutCurrentOutcomes?.[0], taxonomies);
		}
	} else {
		return getColourByOutcomeId(filterOutCurrentOutcomes?.[0], taxonomies);
	}
};

export const getColourByOutcomeId = (currentOutcomeId, taxonomies) => {
	let cssProperties = {};
	const selectedOutcome = taxonomies.learningOutcomes.find(
		(outcome) => outcome.id === currentOutcomeId
	);
	if (selectedOutcome) {
		cssProperties["--backgroundColor"] = selectedOutcome.primary_color;
		return cssProperties;
	}
};
