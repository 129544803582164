import i18n from "i18next";
import React, {Component, ComponentType, LazyExoticComponent} from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";

import {AppContentActions, UserActions} from "../../../Actions";
import {LaunchScreen} from "../index";
import {AppRoute} from "./index";

interface PrivateRouteProps {
	user: {authed: boolean; authTokenChecked: boolean};
	component: ComponentType | LazyExoticComponent<any>;
	urlAction: {} | null;
	newUser?: boolean;
	path: string;
}

interface PrivateRouteState {}

class PrivateRoute extends Component<PrivateRouteProps, PrivateRouteState> {
	componentDidUpdate = (prevProps) => {
		const {user} = this.props;
		if (prevProps?.user?.authed && !user?.authed) {
			localStorage.removeItem("sprig-login-token");
			AppContentActions.setCurrentRoleToDefault();
			UserActions.onLogoutCompleted();
		}
	};

	render() {
		const {
			component: Component,
			user,
			urlAction,
			newUser,
			...rest
		} = this.props;

		return user.authTokenChecked ? (
			<AppRoute
				{...rest}
				title={i18n.t("page_titles.portal.default")}
				render={(props) => {
					if (user?.authed) {
						return (
							<Component {...props} urlAction={urlAction} newUser={newUser} />
						);
					}
					return (
						<Redirect
							to={{
								pathname: "/signin",
								state: {from: props.location},
							}}
						/>
					);
				}}
			/>
		) : (
			<LaunchScreen />
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.user,
});

export default connect(mapStateToProps)(PrivateRoute);
