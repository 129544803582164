import {default as LoaderWrap} from "./Loader";
import {LoaderHideContent, LoaderShowContent} from "./LoaderContent";
import {LoaderSpinner} from "./LoaderSpinner";

export const Loader = {
	Wrap: LoaderWrap,
	HideContent: LoaderHideContent,
	ShowContent: LoaderShowContent,
	Spinner: LoaderSpinner,
};
