import {bindActionCreators} from "redux";

import {ConfigureStore} from "../Redux/ConfigureStore";
import * as ActivityActionsCreators from "./ActivityActions";
import * as ActivityFormActionsCreators from "./ActivityFormAction";
import * as ActivityMonitorActionsCreators from "./ActivityMonitorActions";
import * as AppContentCreators from "./AppContentActions";
import * as ErrorReporterActionsCreators from "./ErrorReporterActions";
import * as LoaderActionsCreators from "./LoaderActions";
import * as ModalActionsCreators from "./ModalActions";
import * as NotificationActionsCreators from "./NotificationActions";
import * as PortalStateActionsCreators from "./PortalStateActions";
import * as ReportActionsCreators from "./ReportActions";
import * as SchoolActionsCreators from "./SchoolActions";
import * as SurveyActionsCreators from "./SurveyActions";
import * as UserActionsCreators from "./UserActions";

export const Store = ConfigureStore();

const ActivityActions = bindActionCreators(
	ActivityActionsCreators,
	Store.store.dispatch
);
const ActivityFormActions = bindActionCreators(
	ActivityFormActionsCreators,
	Store.store.dispatch
);
const ActivityMonitorActions = bindActionCreators(
	ActivityMonitorActionsCreators,
	Store.store.dispatch
);
const AppContentActions = bindActionCreators(
	AppContentCreators,
	Store.store.dispatch
);
const ErrorReporterActions = bindActionCreators(
	ErrorReporterActionsCreators,
	Store.store.dispatch
);
const LoaderActions = bindActionCreators(
	LoaderActionsCreators,
	Store.store.dispatch
);
const ModalActions = bindActionCreators(
	ModalActionsCreators,
	Store.store.dispatch
);
const NotificationActions = bindActionCreators(
	NotificationActionsCreators,
	Store.store.dispatch
);
const PortalStateActions = bindActionCreators(
	PortalStateActionsCreators,
	Store.store.dispatch
);
const ReportActions = bindActionCreators(
	ReportActionsCreators,
	Store.store.dispatch
);
const SchoolActions = bindActionCreators(
	SchoolActionsCreators,
	Store.store.dispatch
);
const SurveyActions = bindActionCreators(
	SurveyActionsCreators,
	Store.store.dispatch
);
const UserActions = bindActionCreators(
	UserActionsCreators,
	Store.store.dispatch
);

export {
	ActivityActions,
	ActivityFormActions,
	ActivityMonitorActions,
	AppContentActions,
	ErrorReporterActions,
	LoaderActions,
	ModalActions,
	NotificationActions,
	PortalStateActions,
	ReportActions,
	SchoolActions,
	SurveyActions,
	UserActions,
};
