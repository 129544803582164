import "./button.scss";

import React from "react";

import {AUTOMATION_TEST_ID} from "../../../Scripts/Constants";
import {
	SIZE_OPTIONS,
	STYLE_CONSTANT,
	TYPE_OPTIONS,
	USER_TYPE_OPTIONS,
} from "../../../Scripts/StyleConstants";
import {Icon} from "../index";

export interface ButtonProps {
	/**
	 * Is this the principal call to action on the page?
	 */
	type?: TYPE_OPTIONS;
	/**
	 * An optional override to get a different user type button then the default colours assigned per portal. Default is caregiver.
	 */
	user?: USER_TYPE_OPTIONS;
	/**
	 * How large should the button be?
	 */
	size?: SIZE_OPTIONS;
	/**
	 * Should the button be disabled and display a loading indicator?
	 */
	isLoading?: boolean;
	/**
	 * Should the button be disabled?
	 */
	disabled?: boolean;
	/**
	 * Optional click handler
	 */
	onClick?: (event) => void;
	/**
	 * Optional id string parameter
	 */
	id?: string;
	className?: string;
	style?: React.CSSProperties;
	name?: string;
	onMouseEnter?: (event) => void;
	value?: string;
	contentClassName?: string;
}

/**
 * Primary UI component for user interaction
 */
export const Button: React.FC<ButtonProps> = ({
	type = STYLE_CONSTANT.TYPE.PRIMARY,
	size = STYLE_CONSTANT.SIZE.MEDIUM,
	user,
	isLoading = false,
	disabled = false,
	children,
	className = "",
	contentClassName = "",
	...props
}) => {
	const mode = `${STYLE_CONSTANT.COMPONENT.BUTTON}--${type}`;
	const userOverride = user
		? `${STYLE_CONSTANT.COMPONENT.BUTTON}--${user}`
		: "";
	return (
		<button
			type="button"
			className={[
				STYLE_CONSTANT.COMPONENT.BUTTON,
				`${STYLE_CONSTANT.COMPONENT.BUTTON}--${size}`,
				mode,
				userOverride,
				className,
			].join(" ")}
			disabled={isLoading || disabled}
			{...props}
		>
			<div
				className={[
					`${STYLE_CONSTANT.COMPONENT.BUTTON}--content`,
					contentClassName,
				].join(" ")}
			>
				{children}
				{isLoading && (
					<Icon
						type="faSpinner"
						size="sm"
						pulse
						id={AUTOMATION_TEST_ID.PUBLIC.BUTTONS.IN_BUTTON_SPINNER}
						className="spinner-icon"
					/>
				)}
			</div>
		</button>
	);
};
