import "../../../../../../Stylesheets/css/scss/Components/Portal/Public/Assessment/Public/QuestionSection.css";

import React from "react";

export const QuestionSection = ({
	title,
	description,
	children,
}: {
	title: string;
	description: string;
	children: React.ReactNode;
}) => (
	<div className="assessment-question-section">
		<h4 className="assessment-question-title">{title}</h4>
		<p className="assessment-question-description">{description}</p>
		{children}
	</div>
);
