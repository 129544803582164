export const getLocaleCurrency = (number: number): string => {
	return number.toLocaleString("en-CA", {
		style: "currency",
		currency: "CAD",
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
};

export const getRoundedNumber = (number: number, significantDigits: number) => {
	const precision = significantDigits ? significantDigits : 0;
	return number.toFixed(precision);
};
