import * as ActionTypes from "../Redux/ActionTypes";

export const newActivity = () => ({
	type: ActionTypes.ACTIVITY_FORM_NEW_ACTIVITY,
});

export const readActivity = (activity, tagging) => ({
	type: ActionTypes.ACTIVITY_FORM_READ_ACTIVITY,
	payload: {activity, tagging},
});

export const editTitle = (title) => ({
	type: ActionTypes.ACTIVITY_FORM_EDIT_TITLE,
	payload: title,
});

export const editDescription = (description) => ({
	type: ActionTypes.ACTIVITY_FORM_EDIT_DESCRIPTION,
	payload: description,
});

export const editMaterials = (materials) => ({
	type: ActionTypes.ACTIVITY_FORM_EDIT_MATERIALS,
	payload: materials,
});

export const editPlace = (places) => ({
	type: ActionTypes.ACTIVITY_FORM_EDIT_PLACE,
	payload: places,
});

export const editLanguage = (language) => ({
	type: ActionTypes.ACTIVITY_FORM_EDIT_LANGUAGE,
	payload: language,
});

export const editGrade = (grades) => ({
	type: ActionTypes.ACTIVITY_FORM_EDIT_GRADE,
	payload: grades,
});

export const editDifficulty = (difficulty) => ({
	type: ActionTypes.ACTIVITY_FORM_EDIT_DIFFICULTY,
	payload: difficulty,
});

export const editDuration = (duration) => ({
	type: ActionTypes.ACTIVITY_FORM_EDIT_DURATION,
	payload: duration,
});

export const editDomain = (domain) => ({
	type: ActionTypes.ACTIVITY_FORM_EDIT_DOMAIN,
	payload: domain,
});

export const editCurriculum = (newCurriculumId, id) => ({
	type: ActionTypes.ACTIVITY_FORM_EDIT_CURRICULUM,
	payload: {newCurriculumId, id},
});

export const editLearningOutcome = (
	newLearningOutcomeId,
	learningOutcomeId,
	curriculumId
) => ({
	type: ActionTypes.ACTIVITY_FORM_EDIT_LEARNING_OUTCOME,
	payload: {newLearningOutcomeId, learningOutcomeId, curriculumId},
});

export const editGeneralOutcome = (
	newGeneralOutcomeId,
	generalOutcomeId,
	learningOutcomeId,
	curriculumId
) => ({
	type: ActionTypes.ACTIVITY_FORM_EDIT_GENERAL_OUTCOME,
	payload: {
		newGeneralOutcomeId,
		generalOutcomeId,
		learningOutcomeId,
		curriculumId,
	},
});

export const editCompetency = (competencies, curriculumId) => ({
	type: ActionTypes.ACTIVITY_FORM_EDIT_COMPENTENCY,
	payload: {competencies, curriculumId},
});

export const addCurriculum = () => ({
	type: ActionTypes.ACTIVITY_FORM_ADD_CURRICULUM,
});

export const deleteCurriculum = (curriculumId) => ({
	type: ActionTypes.ACTIVITY_FORM_DELETE_CURRICULUM,
	payload: {curriculumId},
});

export const addLearningOutcome = (curriculumId) => ({
	type: ActionTypes.ACTIVITY_FORM_ADD_LEARNING_OUTCOME,
	payload: {curriculumId},
});

export const deleteLearningOutcome = (learningOutcomeId, curriculumId) => ({
	type: ActionTypes.ACTIVITY_FORM_DELETE_LEARNING_OUTCOME,
	payload: {learningOutcomeId, curriculumId},
});

export const editSpecificOutcome = (
	newSpecificOutcomeIds,
	generalOutcomeId,
	learningOutcomeId,
	curriculumId
) => ({
	type: ActionTypes.ACTIVITY_FORM_EDIT_SPECIFIC_OUTCOME,
	payload: {
		newSpecificOutcomeIds,
		generalOutcomeId,
		learningOutcomeId,
		curriculumId,
	},
});

export const addGeneralOutcome = (learningOutcomeId, curriculumId) => ({
	type: ActionTypes.ACTIVITY_FORM_ADD_GENERAL_OUTCOME,
	payload: {learningOutcomeId, curriculumId},
});

export const deleteGeneralOutcome = (
	generalOutcomeId,
	learningOutcomeId,
	curriculumId
) => ({
	type: ActionTypes.ACTIVITY_FORM_DELETE_GENERAL_OUTCOME,
	payload: {generalOutcomeId, learningOutcomeId, curriculumId},
});

export const validateFields = () => ({
	type: ActionTypes.ACTIVITY_FORM_VALIDATE_FIELDS,
	payload: true,
});

export const editSharePermission = (communityOnly) => ({
	type: ActionTypes.ACTIVITY_FORM_EDIT_SHARE_PERMISSION,
	payload: communityOnly,
});
