import TagManager from "react-gtm-module";

import {PORTALS, ROLES, SORT_KEY} from "./Constants";
import {InjectedWindow} from "./PublicInterfaces";

declare let window: InjectedWindow;

export const getDomains = () => {
	return window.sprig
		? window.sprig.domains
		: require("../Assets/Taxonomy/domains_development.json");
};

export const getLevelOneOutcomes = () => {
	return window.sprig
		? window.sprig.learningOutcomes
		: require("../Assets/Taxonomy/level_1_outcomes_development.json");
};

export const getLevelTwoOutcomes = () => {
	return window.sprig
		? window.sprig.generalCurricularOutcomes
		: require("../Assets/Taxonomy/level_2_outcomes_development.json");
};

export const getLevelThreeOutcomes = () => {
	return window.sprig
		? (window as any).sprig.specificCurricularOutcomes
		: require("../Assets/Taxonomy/level_3_outcomes_development.json");
};

export const getCurricula = () => {
	return window.sprig
		? window.sprig.curricula
		: require("../Assets/Taxonomy/curricular_development.json");
};

export const portalClassSelector = (roleOrPortal, item, option?: string) => {
	const optionSelection = option ? option : "primary";
	switch (roleOrPortal) {
		case PORTALS.ADMIN:
		case ROLES.ADMIN:
			return `adm-${item}-${optionSelection}`;
		case PORTALS.EDUCATOR:
		case ROLES.TEACHER:
		case ROLES.EDITOR:
		case PORTALS.EDITOR:
			return `edu-${item}-${optionSelection}`;
		case PORTALS.PRINCIPAL:
		case ROLES.PRINCIPAL:
			return `edu-${item}-${optionSelection}`;
		case PORTALS.DIRECTOR:
		case ROLES.DIRECTOR:
			return `edu-${item}-${optionSelection}`;
		case PORTALS.CAREGIVER:
		case ROLES.CAREGIVER:
			return `${item}-${optionSelection}`;
		default:
			return "";
	}
};

export function debounce(func, wait, immediate?: boolean) {
	let timeout;
	return function (this) {
		const context = this;
		const args = arguments;
		const later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};

		const callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}

export const sortArrayByKey = (
	type,
	array,
	key,
	sort,
	fromArray?: boolean,
	nested?: boolean
) => {
	const duplicateArray = [...array];
	switch (type) {
		case SORT_KEY.STRING:
		case SORT_KEY.NUMBER:
		case SORT_KEY.BOOL:
			if (fromArray) {
				return duplicateArray.sort((a, b) => {
					if (sort === SORT_KEY.ASC) return b[key][0] < a[key][0] ? 1 : -1;
					else return a[key][0] < b[key][0] ? 1 : -1;
				});
			} else if (nested) {
				const keys = key.split(".");
				return duplicateArray.sort((a, b) => {
					if (sort === SORT_KEY.ASC)
						return b[keys[0]][keys[1]] < a[keys[0]][keys[1]] ? 1 : -1;
					else return a[keys[0]][keys[1]] < b[keys[0]][keys[1]] ? 1 : -1;
				});
			}
			return duplicateArray.sort((a, b) => {
				if (sort === SORT_KEY.ASC) return b[key] < a[key] ? 1 : -1;
				else return a[key] < b[key] ? 1 : -1;
			});
		case SORT_KEY.DATE:
			return duplicateArray.sort((a, b) => {
				if (sort === SORT_KEY.ASC)
					return new Date(b[key]) > new Date(a[key]) ? 1 : -1;
				else return new Date(a[key]) > new Date(b[key]) ? 1 : -1;
			});
		default:
			return duplicateArray;
	}
};

export const getActivityCurriculumInfo = (curriculums) => {
	const curriculumIds = curriculums
		.filter((curriculum) => curriculum.curriculumId)
		.map((curriculum) => curriculum.curriculumId);
	const learningOutcomeIds = curriculums
		.map((curriculum) =>
			curriculum.learningOutcomes
				.filter((learningOutcome) => learningOutcome.learningOutcomeId)
				.map((learningOutcome) => learningOutcome.learningOutcomeId)
				.reduce((a, b) => a.concat(b), [])
		)
		.reduce((a, b) => a.concat(b), []);
	const generalOutcomeIds = curriculums
		.map((curriculum) =>
			curriculum.learningOutcomes
				.map((learningOutcome) =>
					learningOutcome.generalOutcomes
						.filter((generalOutcome) => generalOutcome.generalOutcomeId)
						.map((generalOutcome) => generalOutcome.generalOutcomeId)
						.reduce((a, b) => a.concat(b), [])
				)
				.reduce((a, b) => a.concat(b), [])
		)
		.reduce((a, b) => a.concat(b), []);
	const specificOutcomeIds = curriculums
		.map((curriculum) =>
			curriculum.learningOutcomes
				.map((learningOutcome) =>
					learningOutcome.generalOutcomes
						.map((generalOutcome) =>
							generalOutcome.specificOutcomes
								.map((specificOutcome) => specificOutcome)
								.reduce((a, b) => a.concat(b), [])
						)
						.reduce((a, b) => a.concat(b), [])
				)
				.reduce((a, b) => a.concat(b), [])
		)
		.reduce((a, b) => a.concat(b), []);

	const competencies = curriculums
		.map((curriculum) => curriculum.competencies)
		.reduce((a, b) => a.concat(b), []);

	return {
		curriculumIds,
		learningOutcomeIds,
		generalOutcomeIds,
		specificOutcomeIds,
		competencies,
	};
};

export const getClientLanguages = (user) => {
	if (user && user.client_objects && user.client_objects.length) {
		const clientObject = user.client_objects[0];
		if (clientObject && clientObject.languages) {
			return clientObject.languages.map((language) => ({
				...language,
				label: language.english_name,
			}));
		}
	}
	return [];
};

export const capitalizeFirstLetterOfEveryWord = (string) => {
	const splitStrings = string
		.split(" ")
		.map(
			(splitString) =>
				`${splitString.charAt(0).toUpperCase()}${splitString.slice(1)}`
		);
	return splitStrings.join(" ");
};

export const compareIfArrayChanged = (newArray, oldArray) => {
	if (newArray.length && oldArray.length) {
		return (
			newArray.length !== oldArray.length ||
			newArray.some((element) => oldArray.indexOf(element) < 0)
		);
	} else if (!newArray.length && !oldArray.length) {
		return false;
	} else {
		return true;
	}
};

export const matchAcceptedRoute = (location, regExpress) => {
	const reg = new RegExp(regExpress);
	const fromPathName = location.state?.from?.pathname;
	return reg.test(fromPathName);
};

export const updateHistoryAfterCloseResultsModal = (routing) => {
	if (routing?.match?.params?.studentId) {
		if (routing.history) {
			routing.history.push(
				`/portal/classroom/${routing.match.params.classroomId}/students/all`
			);
		}
	}
};

export const isObjectEmpty = (currentObject) => {
	for (let i in currentObject) {
		return false;
	}
	return true;
};

export const mergeArrays = (newArray, existingArray) => {
	const existingArrayMap = new Map(
		existingArray.map((existingObject) => [existingObject.id, existingObject])
	);
	newArray.forEach((newObject) => {
		const existingObject = existingArrayMap.get(newObject.id);
		if (existingObject) {
			const isDifferent = Object.keys(newObject).some(
				(key) => newObject[key] !== existingObject[key]
			);
			if (isDifferent) {
				existingArrayMap.set(newObject.id, newObject);
			}
		} else {
			existingArrayMap.set(newObject.id, newObject);
		}
	});
	return Array.from(existingArrayMap.values());
};

export const trackConversion = (eventName, eventData) => {
	TagManager.dataLayer({
		dataLayer: {
			event: eventName,
			...eventData,
		},
	});
};
