import * as ActionTypes from "../Redux/ActionTypes";

export const addToQueue = (toast) => ({
	type: ActionTypes.ACTIVITY_MONITOR_ADD_TO_QUEUE,
	payload: toast,
});

export const removeFromQueue = (id) => ({
	type: ActionTypes.ACTIVITY_MONITOR_REMOVE_FROM_QUEUE,
	payload: id,
});
