import "./navigationItemList.scss";

import React, {useEffect, useState} from "react";
import {LinkProps, NavLink} from "react-router-dom";

import {STYLE_CONSTANT} from "../../../Scripts/StyleConstants";
import {ConditionalWrapper} from "../../Portal/PublicComponents";
import {Icon, IconProps} from "../index";
import PortalTourStep from "../Tours/PortalTourStep";

interface CustomLinkProps extends LinkProps {
	activeClassName?: string;
	id?: string;
	onClick?: () => void;
}

export interface NavigationItemProps {
	icon?: IconProps;
	label: string;
	linkProps: CustomLinkProps;
	isPortalTourStep?: boolean;
	portalTourProps?: {step: number; tourId: string};
}

export interface NavigationItemListProps {
	navigationItems: Array<NavigationItemProps>;
}

/**
 * Primary UI component for user interaction
 */
export const NavigationItemList: React.FC<NavigationItemListProps> = ({
	navigationItems = [],
	...props
}) => {
	const [windowSize, setWindowSize] = useState(window.innerWidth);

	useEffect(() => {
		const handleResize = () => {
			setWindowSize(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);

		return () => window.removeEventListener("resize", handleResize);
	}, []);
	return (
		<ul
			className={[STYLE_CONSTANT.COMPONENT.NAVIGATION.ITEM_LIST].join(" ")}
			{...props}
		>
			{navigationItems.map((item, index) => (
				<li
					title={item.label}
					className={[STYLE_CONSTANT.COMPONENT.NAVIGATION.ITEM].join(" ")}
					key={item.label}
				>
					<ConditionalWrapper
						condition={item.isPortalTourStep && windowSize >= 992}
						wrapper={(children) => {
							if (item.portalTourProps)
								return (
									<PortalTourStep
										{...item.portalTourProps}
										isWalkThrough={true}
										nextPageLink={
											index < navigationItems.length - 1 &&
											navigationItems[index + 1].isPortalTourStep
												? navigationItems[index + 1].linkProps.to
												: ""
										}
										wrapperClassName="w-100"
									>
										{children}
									</PortalTourStep>
								);
						}}
					>
						<NavLink {...item.linkProps}>
							{item.icon ? <Icon {...item.icon} /> : null}
							<span>{item.label}</span>
						</NavLink>
					</ConditionalWrapper>
				</li>
			))}
		</ul>
	);
};
