import i18n from "i18next";
import React from "react";

import {IconType} from "../../../Icons";
import {PortalTourTooltip} from "../../PortalTourTooltip";
import {PageDescription} from "../../Widgets";

const pageData = [
	{
		icon: "sprigReadingCircle" as IconType,
		title: i18n.t("tour.reading.page.circle_chart.title"),
		description: i18n.t("tour.reading.page.circle_chart.description"),
		tooltipTop: "10px",
	},
	{
		icon: "sprigLeaf" as IconType,
		title: i18n.t("tour.reading.page.activities.title"),
		description: i18n.t("tour.reading.page.activities.description"),
		tooltipTop: "10px",
	},
	{
		icon: "faDownload" as IconType,
		title: i18n.t("tour.reading.page.resources.title"),
		description: i18n.t("tour.reading.page.resources.description"),
		tooltipTop: "10px",
	},
	{
		icon: "sprigStudent" as IconType,
		title: i18n.t("tour.reading.page.students.title"),
		description: i18n.t("tour.reading.page.students.description"),
		tooltipTop: "40px",
	},
	{
		icon: "faChalkboardUser" as IconType,
		title: i18n.t("tour.reading.page.teachers.title"),
		description: i18n.t("tour.reading.page.teachers.description"),
		tooltipTop: "70px",
	},
];

export const ReadingMainPageDescriptionSteps = pageData.map((page, index) => (
	<PortalTourTooltip
		key={page.title}
		style={{
			"--arrowTop": `${index * 50 + 7}px`,
			"--tooltipTop": `${page.tooltipTop}`,
		}}
	>
		<PageDescription {...page} />
	</PortalTourTooltip>
));
