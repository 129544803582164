import React from "react";

interface ModalFooterProps {
	className?: string;
	children?: JSX.Element[] | JSX.Element;
}

export const ModalFooter = ({className, children}: ModalFooterProps) => (
	<div
		className={`modal-footer rounded-bottom${className ? ` ${className}` : ""}`}
	>
		{children}
	</div>
);
