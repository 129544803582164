import i18n from "i18next";
import React from "react";

export const ChartHeader = ({title}: {title: string}) => {
	return (
		<div className="d-flex justify-content-between mb-3 report-head border-bottom rounded-top">
			<h6>{title}</h6>
			<small className="float-right text-right">
				<span className="text-muted">{i18n.t("admin.updated")}:</span>{" "}
				{new Date().toLocaleDateString()}
			</small>
		</div>
	);
};
