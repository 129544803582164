import React from "react";

import {AssetManager} from "../../../../../Scripts/AssetManager";

interface BadgeIconProps {
	iconName: number;
	tier: number;
	earned: boolean;
	secret?: boolean;
}

export const BadgeIcon = ({iconName, tier, earned, secret}: BadgeIconProps) => {
	const asset = AssetManager.getBadgeAssetById(iconName);

	const getIcon = () => {
		if (asset) {
			const badgeAsset = asset[`TIER_${tier}`];
			if (badgeAsset) {
				if (!earned && secret) {
					const hiddenAsset = AssetManager.getBadgeAssetById("HIDDEN");
					if (hiddenAsset) return {src: hiddenAsset.SRC, alt: hiddenAsset.ALT};
				}
				return {
					src: earned ? badgeAsset.ASSET.EARNED : badgeAsset.ASSET.LOCKED,
					alt: badgeAsset.ALT,
				};
			}
		}
		return null;
	};

	const icon = getIcon();
	return icon ? <img src={icon.src} alt={icon.alt} /> : null;
};
