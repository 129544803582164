import React from "react";

import {
	MathWhatWhereWhoQuestion,
	WhatAndWhereTab,
	WhoDoYouDoMathWith,
} from "./Widgets";

interface MathResultsQuestionsProps {
	page: number;
	questions: MathWhatWhereWhoQuestion[];
}

export const MathResultsQuestions = ({
	page,
	questions,
}: MathResultsQuestionsProps) => {
	switch (page) {
		case 1:
			return <WhatAndWhereTab page={page} questions={questions} />;
		case 2:
			return <WhatAndWhereTab page={page} questions={questions} />;
		case 3:
			return <WhoDoYouDoMathWith questions={questions} />;
		default:
			throw new Error(`Unsupported page number: ${page}`);
	}
};
