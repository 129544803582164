import i18n from "i18next";
import React from "react";

import {
	CHART_TYPE,
	PORTALS,
	REPORT_CHART_TYPE,
	SUBMISSION_COUNT,
} from "../../../../Scripts/Constants";
import {ReportSubmissionCount} from "../../../../Scripts/Interfaces";
import {ClassroomStudentModel} from "../../../../Scripts/PublicInterfaces";
import {ChartHeader, ReportAlertNoData, ReportChart} from "../index";

interface AssessmentsCompletedProps {
	report: ReportSubmissionCount;
	students?: ClassroomStudentModel[];
	type?: string;
	portal: string;
}

export const AssessmentsCompleted = ({
	report,
	students,
	type,
	portal,
}: AssessmentsCompletedProps) => {
	const parseData = () => {
		const classroomReport = type === SUBMISSION_COUNT.CLASSROOM;
		let studentsAssessed: number;
		let caregiversAssessed: number;
		if (classroomReport && students) {
			studentsAssessed = students.filter(
				(student) => student.has_student_assessment
			).length;

			caregiversAssessed = students.filter(
				(student) => student.has_caregiver_assessment
			).length;
		} else {
			studentsAssessed = report.student_submission_count;
			caregiversAssessed = report.caregiver_submission_count;
		}
		const requiredStudents = classroomReport
			? students?.length
			: report.required_student_submission_count;
		const requiredCaregivers = classroomReport
			? students?.length
			: report.required_caregiver_submission_count;
		const percentageArray = [
			getPercentage(studentsAssessed, requiredStudents),
			getPercentage(caregiversAssessed, requiredCaregivers),
			getPercentage(
				report.teacher_submission_count,
				report.required_teacher_submission_count
			),
			getPercentage(
				report.elder_submission_count,
				report.required_elder_submission_count
			),
		];
		const data = {
			labels: [
				i18n.t("role.student"),
				i18n.t("role.caregiver"),
				i18n.t("role.educator"),
				i18n.t("role.elder"),
			],
			datasets: [
				{
					label: i18n.t("chart.percent_assessments_completed"),
					backgroundColor: ["#4BB5B4", "#6BC4C3", "#8FD3D2", "#B3E2E1"],
					borderColor: "white",
					borderWidth: 1,
					hoverBackgroundColor: "#F88D25",
					hoverBorderColor: "#F88D25",
					data: [...percentageArray],
				},
			],
		};

		const counts = [
			studentsAssessed,
			caregiversAssessed,
			report.teacher_submission_count,
			report.elder_submission_count,
		];
		return {data, counts};
	};

	const getPercentage = (count, total) => {
		return Math.min(100, (count / total) * 100).toFixed(2);
	};

	const getChartData = () => {
		switch (portal) {
			case PORTALS.EDUCATOR:
				return parseData();
			case PORTALS.PRINCIPAL:
			case PORTALS.DIRECTOR:
			default:
				return parseData();
		}
	};

	const chartData = getChartData();

	const isReportAvailable = () => {
		return type === SUBMISSION_COUNT.CLASSROOM
			? students && students.length > 0
			: Object.keys(report).length !== 0;
	};
	return (
		<div className="mt-2">
			<ChartHeader title={i18n.t("admin.assessments_completed")} />
			<div className="text-right mb-2 mt-5">
				{isReportAvailable() ? (
					<ReportChart
						chartType={CHART_TYPE.BAR}
						data={chartData.data}
						type={REPORT_CHART_TYPE.ASSESSMENT_COMPLETION}
						tooltipValue={chartData.counts}
					/>
				) : (
					<ReportAlertNoData />
				)}
			</div>
		</div>
	);
};
