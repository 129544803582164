import * as ActionTypes from "../Redux/ActionTypes";

export const showModal = (modalProps, modalType) => ({
	type: ActionTypes.SHOW_MODAL,
	payload: {modalProps, modalType},
});

export const hideModal = (id = 0) => ({
	type: ActionTypes.HIDE_MODAL,
	payload: id,
});

export const updateModalProps = (props) => ({
	type: ActionTypes.MODAL_UPDATE_PROPS,
	payload: props,
});
