import "./sidebarNavigation.scss";

import React from "react";

import {STYLE_CONSTANT} from "../../../Scripts/StyleConstants";
import {NavigationItemList, NavigationItemProps} from "./navigationItemList";

export interface SidebarNavigationProps {
	navigationItems: Array<NavigationItemProps>;
}

/**
 * Primary UI component for user interaction
 */
export const SidebarNavigation: React.FC<SidebarNavigationProps> = ({
	navigationItems = [],
	...props
}) => {
	return (
		<nav
			className={[STYLE_CONSTANT.COMPONENT.NAVIGATION.SIDEBAR].join(" ")}
			{...props}
		>
			<section
				className={`${STYLE_CONSTANT.COMPONENT.NAVIGATION.SIDEBAR}--content`}
			>
				<NavigationItemList navigationItems={navigationItems} />
			</section>
		</nav>
	);
};
