import React from "react";

interface ModalBodyProps {
	className?: string;
	children: JSX.Element[] | JSX.Element;
}

export const ModalBody = ({className, children}: ModalBodyProps) => (
	<div className={`modal-body ${className ? className : "rounded-bottom"}`}>
		{children}
	</div>
);
ModalBody.displayName = "modalBody";
