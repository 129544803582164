import * as ActionTypes from "../Redux/ActionTypes";

export const setUserSearch = (search) => ({
	type: ActionTypes.PORTAL_STATE_ADMIN_SET_USER_SEARCH,
	payload: search,
});

export const importSetLimit = (limit, type) => ({
	type: ActionTypes.PORTAL_STATE_ADMIN_IMPORT_SET_LIMIT,
	payload: {limit, type},
});

export const importAddSchool = (school) => ({
	type: ActionTypes.PORTAL_STATE_ADMIN_IMPORT_ADD_SCHOOL,
	payload: {school},
});

export const importDeleteSchool = (schoolToBeDeleted) => ({
	type: ActionTypes.PORTAL_STATE_ADMIN_IMPORT_DELETE_SCHOOL,
	payload: {schoolToBeDeleted},
});

export const importUpdateSchool = (schoolToBeUpdated, updatedSchool) => ({
	type: ActionTypes.PORTAL_STATE_ADMIN_IMPORT_UPDATE_SCHOOL,
	payload: {schoolToBeUpdated, updatedSchool},
});

export const importAddClassroom = (classroom, schoolId) => ({
	type: ActionTypes.PORTAL_STATE_ADMIN_IMPORT_ADD_CLASSROOM,
	payload: {classroom, schoolId},
});

export const importDeleteClassroom = (classroomToBeDeleted, schoolId) => ({
	type: ActionTypes.PORTAL_STATE_ADMIN_IMPORT_DELETE_CLASSROOM,
	payload: {classroomToBeDeleted, schoolId},
});

export const importUpdateClassroom = (
	classroomToBeUpdated,
	updatedClassroom,
	schoolId
) => ({
	type: ActionTypes.PORTAL_STATE_ADMIN_IMPORT_UPDATE_CLASSROOM,
	payload: {classroomToBeUpdated, updatedClassroom, schoolId},
});

export const flyoutNavigationToggle = () => ({
	type: ActionTypes.PORTAL_STATE_FLYOUT_NAVIGATION_TOGGLE,
});

export const setActivitiesFilterBy = (filterBy) => ({
	type: ActionTypes.PORTAL_STATE_CAREGIVER_SET_ACTIVITIES_FILTER_BY,
	payload: filterBy,
});

export const updateAssessmentResult = (results) => ({
	type: ActionTypes.PORTAL_STATE_EDUCATOR_UPDATE_ASSESSMENT_RESULT,
	payload: results,
});

export const updateSignupType = (signupUserType) => ({
	type: ActionTypes.PORTAL_STATE_CAREGIVER_UPDATE_SIGNUP_TYPE,
	payload: signupUserType,
});

export const setActivitiesToList = (isActivitiesList, portal) => ({
	type: ActionTypes.PORTAL_STATE_SET_ACTIVITIES_LIST,
	payload: {isActivitiesList, portal},
});

export const setActivitiesListCurrentCard = (data) => {
	const {activitiesListCurrentCard, portal} = data;
	return {
		type: ActionTypes.PORTAL_STATE_SET_ACTIVITIES_LIST_CURRENT_CARD,
		payload: {activitiesListCurrentCard, portal},
	};
};

export const setCircleChart = (data) => {
	const {currentTopicId, currentSubTopicId} = data;
	return {
		type: ActionTypes.PORTAL_STATE_READING_CIRCLE_CHART,
		payload: {currentTopicId, currentSubTopicId},
	};
};

export const updateCircleChartFilterSkillsByGrade = (currentGrades) => {
	return {
		type: ActionTypes.PORTAL_STATE_READING_CIRCLE_CHART_FILTER_SKILLS_BY_GRADE,
		payload: currentGrades,
	};
};

export const updateInitialWelcomeModal = (status) => {
	return {
		type: ActionTypes.PORTAL_STATE_READING_INITIAL_WELCOME_MODAL,
		payload: status,
	};
};
