import "../../../../Stylesheets/css/scss/Components/Portal/Public/Visualizations/SprigVisualization.css";
import "../../../../Stylesheets/css/scss/Components/Portal/Public/Visualizations/Stickers.css";

import React, {Fragment, useEffect, useState} from "react";

import * as ActionTypes from "../../../../Redux/ActionTypes";
import {BADGE_ID} from "../../../../Scripts/Constants";
import {DOMAIN_MATH, DOMAIN_ORAL_LANGUAGE} from "../../../../Scripts/Domain";
import {
	ReducerActivity,
	StudentGoal,
} from "../../../../Scripts/PublicInterfaces";
import {Loader, SprigTree, StickerToggle} from "../index";
import {TreeActivityMetrics} from "./TreeActivityMetrics";

interface SprigVisualizationProps {
	activity: ReducerActivity;
	domain: number;
	goals: StudentGoal[];
	selectedTreeOutcome: number;
	onSelectTreeOutcome: (selectedTreeOutcome: number) => void;
	isHolisticMath: boolean;
}

export const SprigVisualization = ({
	activity,
	domain,
	goals,
	selectedTreeOutcome,
	onSelectTreeOutcome,
	isHolisticMath,
}: SprigVisualizationProps) => {
	const [hideStickers, setHideStickers] = useState(false);
	const [currentSelectedOutcome, setCurrentSelectedOutcome] =
		useState(selectedTreeOutcome);

	useEffect(() => {
		if (selectedTreeOutcome !== currentSelectedOutcome) {
			setCurrentSelectedOutcome(selectedTreeOutcome);
		}
	}, [currentSelectedOutcome, selectedTreeOutcome]);

	const getTreeLevel = () => {
		const levelOne = 1;
		if (
			activity?.rewards?.earnedBadges &&
			activity?.completedActivities?.length
		) {
			const {rewards, completedActivities} = activity;
			const domainCompletedActivities = completedActivities.filter(
				(completedActivity) => completedActivity.domain_id === domain
			);
			const uniqueCompletedActivities = [
				...new Set(
					domainCompletedActivities.map(
						(completedActivity) => completedActivity.activity_id
					)
				),
			];
			const activityLength = uniqueCompletedActivities.length;
			const largeTreeMinimum = 5;
			if (activityLength < largeTreeMinimum) {
				return activityLength === 0 ? levelOne : activityLength;
			} else {
				if (domain === DOMAIN_ORAL_LANGUAGE) {
					const treeBadge = rewards?.earnedBadges.find(
						(badge) => badge.id === BADGE_ID.TREEMENDOUS
					);
					if (treeBadge) return treeBadge.tier + 4;
				} else if (domain === DOMAIN_MATH) {
					const calculatedTreeLevel =
						largeTreeMinimum +
						Math.floor((activityLength - largeTreeMinimum) / largeTreeMinimum);
					return calculatedTreeLevel > 10 ? 10 : calculatedTreeLevel;
				}
			}
		}
		return levelOne;
	};

	const toggleStickers = () => {
		setHideStickers(!hideStickers);
	};

	const onSelectOutcome = (outcome) => {
		if (outcome === currentSelectedOutcome) {
			onSelectTreeOutcome(0);
		} else {
			onSelectTreeOutcome(outcome);
		}
	};

	const treeLevel = getTreeLevel();
	return (
		<div className={`sprig-visual domain-${domain} tree-level-${treeLevel}`}>
			{goals.length > 0 && (
				<Loader.Wrap
					actionTypes={[
						ActionTypes.ACTIVITY_GET_BADGES_PERSON,
						ActionTypes.ACTIVITY_GET_COMPLETION_COUNTS,
						ActionTypes.SCHOOL_UPDATE_CURRENT_STUDENT,
					]}
					conditional={activity?.completedActivities !== null}
				>
					<Loader.HideContent>
						<TreeActivityMetrics
							goals={goals}
							domain={domain}
							totalCompletions={activity?.completedActivities}
						/>
					</Loader.HideContent>
					<Fragment />
				</Loader.Wrap>
			)}
			{domain !== DOMAIN_MATH && <StickerToggle onToggle={toggleStickers} />}
			{treeLevel ? (
				<Loader.Wrap
					actionTypes={[ActionTypes.ACTIVITY_GET_BADGES_PERSON]}
					conditional={activity.initializedLearningOutcomes}
				>
					<Loader.HideContent>
						<SprigTree
							activity={activity}
							domain={domain}
							level={treeLevel}
							onSelectOutcome={onSelectOutcome}
							hideStickers={
								hideStickers || (domain === DOMAIN_MATH && !isHolisticMath)
							}
							selectedTreeOutcome={currentSelectedOutcome}
							isHolisticMath={isHolisticMath}
						/>
					</Loader.HideContent>
					<Loader.Spinner />
				</Loader.Wrap>
			) : null}
		</div>
	);
};
