import i18n from "i18next";
import React, {Fragment} from "react";
import {Trans} from "react-i18next";

export const Storytelling = ({
	studentName,
	getImage,
	getAnswer,
	getStrings,
	audioComponent,
}: {
	studentName: string;
	getImage: (index: number) => string;
	getAnswer: (questionId: number) => string;
	getStrings: (questionId: number) => {[key: number]: string};
	audioComponent: JSX.Element | null;
}) => (
	<Fragment>
		<div className="row">
			<div className="col-12 pt-3 pb-2">
				<h4 className="text-left mb-0">
					{i18n.t("assessments.oral_language.storytelling.title")}
				</h4>
			</div>
			<div className="col-12 col-md-9 mt-2">
				<p className="text-left assessment-paragraph nun-semibold">
					<Trans i18nKey="assessments.oral_language.storytelling.description.p1">
						{[
							<strong key={`${studentName}-p1`}>
								{{student: studentName}}
							</strong>,
						]}
					</Trans>
				</p>
				<p className="text-left assessment-paragraph nun-semibold">
					<Trans i18nKey="assessments.oral_language.storytelling.description.p2">
						{[
							<strong key={`${studentName}-p2`}>
								{{student: studentName}}
							</strong>,
						]}
					</Trans>
				</p>
				<p className="text-left assessment-paragraph nun-semibold">
					{i18n.t("assessments.oral_language.storytelling.description.p3")}
				</p>
			</div>
		</div>
		<div className="row border-bottom pb-3">
			<div className="story-images col-6 col-md-3 p-2 rounded text-left">
				<h4 className="assessment-img-count bg-primary text-white">1</h4>
				<object
					className="w-100 rounded"
					type="image/svg+xml"
					data={getImage(1)}
				>
					Storytelling Picture 1
				</object>
			</div>
			<div className="story-images col-6 col-md-3 p-2 rounded text-left">
				<h4 className="assessment-img-count bg-primary text-white">2</h4>
				<object
					className="w-100 rounded"
					type="image/svg+xml"
					data={getImage(2)}
				>
					Storytelling Picture 2
				</object>
			</div>
			<div className="story-images col-6 col-md-3 p-2 rounded text-left">
				<h4 className="assessment-img-count bg-primary text-white">3</h4>
				<object
					className="w-100 rounded"
					type="image/svg+xml"
					data={getImage(3)}
				>
					Storytelling Picture 3
				</object>
			</div>
			<div className="story-images col-6 col-md-3 p-2 rounded text-left">
				<h4 className="assessment-img-count bg-primary text-white">4</h4>
				<object
					className="w-100 rounded"
					type="image/svg+xml"
					data={getImage(4)}
				>
					Storytelling Picture 4
				</object>
			</div>
		</div>
		<div className="row">
			<table
				id="storytelling-table"
				className="table table-responsive-sm border-light-green"
			>
				<thead>
					<tr>
						<th className="storytelling-th border-top-0" scope="col" />
						<th
							className="storytelling-th border-top-0 text-center pb-2"
							scope="col"
						>
							<span id="assessment-student-name">{studentName}</span>{" "}
							{audioComponent}
							{!audioComponent && "N/A"}
						</th>
						<th
							className="storytelling-th border-top-0 text-center pb-2 nun-semibold"
							scope="col"
						>
							{i18n.t("admin.beginner_storyteller")}
							<audio controls>
								<source src="horse.mp3" type="audio/mp4" />
							</audio>
						</th>
						<th
							className="storytelling-th border-top-0 text-center pb-2 nun-semibold"
							scope="col"
						>
							{i18n.t("admin.advanced_storyteller")}
							<audio controls>
								<source src="horse.mp3" type="audio/mp4" />
							</audio>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<th scope="row">
							{i18n.t(
								"assessments.oral_language.storytelling.rubric.labels.701"
							)}
						</th>
						<td className="bg-primary-header">{getAnswer(701)}</td>
						<td className="nun-semibold">{getStrings(701)[1]}</td>
						<td className="nun-semibold">{getStrings(701)[4]}</td>
					</tr>
					<tr>
						<th scope="row">
							{i18n.t(
								"assessments.oral_language.storytelling.rubric.labels.702"
							)}
						</th>
						<td className="bg-primary-header">{getAnswer(702)}</td>
						<td className="nun-semibold">{getStrings(702)[1]}</td>
						<td className="nun-semibold">{getStrings(702)[4]}</td>
					</tr>
					<tr>
						<th scope="row">
							{i18n.t(
								"assessments.oral_language.storytelling.rubric.labels.703"
							)}
						</th>
						<td className="bg-primary-header">{getAnswer(703)}</td>
						<td className="nun-semibold">{getStrings(703)[1]}</td>
						<td className="nun-semibold">{getStrings(703)[4]}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</Fragment>
);
