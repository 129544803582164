import CaregiverAssessment from "../Assets/Assessments/CaregiverAssessment.svg";
import CaregiverAssessmentDisabled from "../Assets/Assessments/CaregiverAssessmentDisabled.svg";
import CaregiverAssessmentInverse from "../Assets/Assessments/CaregiverAssessmentInverse.svg";
import ElderAssessment from "../Assets/Assessments/ElderAssessment.svg";
import ElderAssessmentDisabled from "../Assets/Assessments/ElderAssessmentDisabled.svg";
import StudentAssessment from "../Assets/Assessments/StudentAssessment.svg";
import StudentAssessmentDisabled from "../Assets/Assessments/StudentAssessmentDisabled.svg";
import TeacherAssessment from "../Assets/Assessments/TeacherAssessment.svg";
import TeacherAssessmentDisabled from "../Assets/Assessments/TeacherAssessmentDisabled.svg";
import BirdsOfAFeatherTier1 from "../Assets/Badges/Birds of a Feather 1.svg";
import BirdsOfAFeatherTier1Grey from "../Assets/Badges/Birds of a Feather 1-grey.svg";
import BirdsOfAFeatherTier2 from "../Assets/Badges/Birds of a Feather 2.svg";
import BirdsOfAFeatherTier2Grey from "../Assets/Badges/Birds of a Feather 2-grey.svg";
import BirdsOfAFeatherTier3 from "../Assets/Badges/Birds of a Feather 3.svg";
import BirdsOfAFeatherTier3Grey from "../Assets/Badges/Birds of a Feather 3-grey.svg";
import BusyAsABee1 from "../Assets/Badges/Busy as a Bee 1.svg";
import BusyAsABee1Grey from "../Assets/Badges/Busy as a Bee 1-grey.svg";
import BusyAsABee2 from "../Assets/Badges/Busy as a Bee 2.svg";
import BusyAsABee2Grey from "../Assets/Badges/Busy as a Bee 2-grey.svg";
import ChitterChatter1Grey from "../Assets/Badges/Chitter-Chatter 1-grey.svg";
import ChitterChatter2 from "../Assets/Badges/Chitter-Chatter 2.svg";
import ChitterChatter2Grey from "../Assets/Badges/Chitter-Chatter 2-grey.svg";
import ChitterChatter3 from "../Assets/Badges/Chitter-Chatter 3.svg";
import ChitterChatter3Grey from "../Assets/Badges/Chitter-Chatter 3-grey.svg";
import EvenSteven1 from "../Assets/Badges/Even Steven 1.svg";
import EvenSteven1Grey from "../Assets/Badges/Even Steven 1-grey.svg";
import EvenSteven2 from "../Assets/Badges/Even Steven 2.svg";
import EvenSteven2Grey from "../Assets/Badges/Even Steven 2-grey.svg";
import HiddenBadge from "../Assets/Badges/hidden-grey.svg";
import HomeTweetHome1 from "../Assets/Badges/Home Tweet Home 1.svg";
import HomeTweetHome1Grey from "../Assets/Badges/Home Tweet Home 1-grey.svg";
import HomeTweetHome2 from "../Assets/Badges/Home Tweet Home 2.svg";
import HomeTweetHome2Grey from "../Assets/Badges/Home Tweet Home 2-grey.svg";
import HomeTweetHome3 from "../Assets/Badges/Home Tweet Home 3.svg";
import HomeTweetHome3Grey from "../Assets/Badges/Home Tweet Home 3-grey.svg";
import HopToIt1 from "../Assets/Badges/Hop to It 1.svg";
import HopToIt1Grey from "../Assets/Badges/Hop to It 1-grey.svg";
import HopToIt2 from "../Assets/Badges/Hop to It 2.svg";
import HopToIt2Grey from "../Assets/Badges/Hop to It 2-grey.svg";
import HopToIt3 from "../Assets/Badges/Hop to It 3.svg";
import HopToIt3Grey from "../Assets/Badges/Hop to It 3-grey.svg";
import RiseAndShine1 from "../Assets/Badges/Rise and Shine 1.svg";
import RiseAndShine1Grey from "../Assets/Badges/Rise and Shine 1-grey.svg";
import RiseAndShine2 from "../Assets/Badges/Rise and Shine 2.svg";
import RiseAndShine2Grey from "../Assets/Badges/Rise and Shine 2-grey.svg";
import SproutUp from "../Assets/Badges/Sprout Up.svg";
import SproutUpGrey from "../Assets/Badges/Sprout Up-grey.svg";
import SquirreledAway1 from "../Assets/Badges/Squirreled Away 1.svg";
import SquirreledAway1Grey from "../Assets/Badges/Squirreled Away 1-grey.svg";
import SquirreledAway2 from "../Assets/Badges/Squirreled Away 2.svg";
import SquirreledAway2Grey from "../Assets/Badges/Squirreled Away 2-grey.svg";
import ChitterChatter1 from "../Assets/Badges/Treemendous 1.svg";
import Treemendous1 from "../Assets/Badges/Treemendous 1.svg";
import Treemendous1Grey from "../Assets/Badges/Treemendous 1-grey.svg";
import Treemendous2 from "../Assets/Badges/Treemendous 2.svg";
import Treemendous2Grey from "../Assets/Badges/Treemendous 2-grey.svg";
import Treemendous3 from "../Assets/Badges/Treemendous 3.svg";
import Treemendous3Grey from "../Assets/Badges/Treemendous 3-grey.svg";
import Treemendous4 from "../Assets/Badges/Treemendous 4.svg";
import Treemendous4Grey from "../Assets/Badges/Treemendous 4-grey.svg";
import Treemendous5 from "../Assets/Badges/Treemendous 5.svg";
import Treemendous5Grey from "../Assets/Badges/Treemendous 5-grey.svg";
import Treemendous6 from "../Assets/Badges/Treemendous 6.svg";
import Treemendous6Grey from "../Assets/Badges/Treemendous 6-grey.svg";
import Unbeleafable1 from "../Assets/Badges/Unbe-leaf-able 1.svg";
import Unbeleafable1Grey from "../Assets/Badges/Unbe-leaf-able 1-grey.svg";
import Unbeleafable2 from "../Assets/Badges/Unbe-leaf-able 2.svg";
import Unbeleafable2Grey from "../Assets/Badges/Unbe-leaf-able 2-grey.svg";
import OnboardingBadge from "../Assets/Caregiver/Onboarding/badge.svg";
import OnboardingLeaves from "../Assets/Caregiver/Onboarding/leaves.svg";
import OnboardingResults from "../Assets/Caregiver/Onboarding/reports.svg";
import OnboardingActivities from "../Assets/Caregiver/Onboarding/SprigHome/activities.svg";
import OnboardingAssessment from "../Assets/Caregiver/Onboarding/SprigHome/assessment.svg";
import OnboardingHome from "../Assets/Caregiver/Onboarding/SprigHome/home.svg";
import OnboardingLearningTime from "../Assets/Caregiver/Onboarding/SprigHome/learning_time.svg";
import OnboardingAppIcon from "../Assets/Caregiver/Onboarding/SprigHome/sprig_home_icon.svg";
import OnboardingTreeGrowth from "../Assets/Caregiver/Onboarding/SprigHome/tree_growth.svg";
import OnboardingWelcome from "../Assets/Caregiver/Onboarding/SprigHome/welcome.svg";
import OnboardingTree from "../Assets/Caregiver/Onboarding/tree.svg";
import ActivitiesIcon from "../Assets/Caregiver/PrimaryNav/activities_icon_mobile.svg";
import ActivitiesIconActive from "../Assets/Caregiver/PrimaryNav/activities_icon_mobile_active.svg";
import HomeIcon from "../Assets/Caregiver/PrimaryNav/home_icon_mobile.svg";
import HomeIconActive from "../Assets/Caregiver/PrimaryNav/home_icon_mobile_active.svg";
import SprigLogoLeavesWhite from "../Assets/Caregiver/PrimaryNav/logo.svg";
import PaidSubscription from "../Assets/Caregiver/Subscription/small sprout.svg";
import RedeemStudentCode from "../Assets/Caregiver/Subscription/student code.svg";
import CaregiverNavActivityIcon from "../Assets/Caregiver/Trees/activities-icon.svg";
import CaregiverNavHomeIcon from "../Assets/Caregiver/Trees/home-icon.svg";
import LeafIcon from "../Assets/Caregiver/Trees/leaf2.svg";
import TreeIcon from "../Assets/Caregiver/Trees/tree.svg";
import PdfImage from "../Assets/Educator/pdf-image.svg";
import Bloom from "../Assets/HolisticMathPrototype/Bloom.svg";
import HMCharacter from "../Assets/HolisticMathPrototype/character.svg";
import Flower from "../Assets/HolisticMathPrototype/Flower.svg";
import Seed from "../Assets/HolisticMathPrototype/Seed.svg";
import Sprout from "../Assets/HolisticMathPrototype/Sprout.svg";
import Character from "../Assets/Login/404_character.svg";
import SprigLogoLeavesGreen from "../Assets/Login/sprig_logo.svg";
import SprigHomeAppLogo from "../Assets/Logos/sprighome_app_icon.svg";
import SprigHomeLogo from "../Assets/Logos/sprighome_logo.svg";
import SprigHomeLogoWhite from "../Assets/Logos/sprighome_white.svg";
import SprigLanguageLogo from "../Assets/Logos/spriglanguage_logo.svg";
import SprigLanguageLogoWhite from "../Assets/Logos/spriglanguage_white.svg";
import SprigLibraryAppLogo from "../Assets/Logos/spriglibrary_app_icon.svg";
import SprigLogoLeaves from "../Assets/Logos/spriglogoleaves.svg";
import SprigMathLogo from "../Assets/Logos/sprigmath_logo.svg";
import BerryEstimation from "../Assets/MathAssessmentResults/Activity/berryEstimation.svg";
import MakingConnections from "../Assets/MathAssessmentResults/Activity/makingConnections.svg";
import PieceEstimation from "../Assets/MathAssessmentResults/Activity/pieceEstimation.svg";
import WhatGames from "../Assets/MathAssessmentResults/What/what1games.svg";
import WhatSingDance from "../Assets/MathAssessmentResults/What/what2singdance.svg";
import WhatHelpCook from "../Assets/MathAssessmentResults/What/what3helpcook.svg";
import WhatBuild from "../Assets/MathAssessmentResults/What/what4build.svg";
import WhatDraw from "../Assets/MathAssessmentResults/What/what5draw.svg";
import WhatSort from "../Assets/MathAssessmentResults/What/what6sort.svg";
import WhatShop from "../Assets/MathAssessmentResults/What/what7shop.svg";
import WhatPuzzle from "../Assets/MathAssessmentResults/What/what8puzzle.svg";
import WhereHome from "../Assets/MathAssessmentResults/Where/where1home.svg";
import WhereSchool from "../Assets/MathAssessmentResults/Where/where2school.svg";
import WhereCommunity from "../Assets/MathAssessmentResults/Where/where3community.svg";
import WhoMom from "../Assets/MathAssessmentResults/Who/who1mother.svg";
import WhoDad from "../Assets/MathAssessmentResults/Who/who2father.svg";
import WhoGrandParents from "../Assets/MathAssessmentResults/Who/who3grandparents.svg";
import WhoSiblings from "../Assets/MathAssessmentResults/Who/who4siblings.svg";
import WhoAuntUncle from "../Assets/MathAssessmentResults/Who/who5auntuncle.svg";
import WhoFriends from "../Assets/MathAssessmentResults/Who/who6friends.svg";
import WhoCousins from "../Assets/MathAssessmentResults/Who/who7cousins.svg";
import WhoTeacher from "../Assets/MathAssessmentResults/Who/who8teacher.svg";
import {ReactComponent as Geometry} from "../Assets/Outcomes/Geometry.svg";
import {ReactComponent as ListeningAndSpeaking} from "../Assets/Outcomes/ListeningAndSpeaking.svg";
import {ReactComponent as Measurement} from "../Assets/Outcomes/Measurement.svg";
import {ReactComponent as NumberSense} from "../Assets/Outcomes/NumberSense.svg";
import {ReactComponent as PatternsAndRelations} from "../Assets/Outcomes/PatternsAndRelations.svg";
import {ReactComponent as ReadingAndViewing} from "../Assets/Outcomes/ReadingAndViewing.svg";
import SprigReadingLogo from "../Assets/Reading/SRLogo.svg";
import ReadingVideoThumbnail1 from "../Assets/Reading/training1Thumbnail.png";
import ReadingVideoThumbnail2 from "../Assets/Reading/training2Thumbnail.png";
import ReadingVideoThumbnail3 from "../Assets/Reading/training3Thumbnail.png";
import ReadingVideoThumbnail4 from "../Assets/Reading/training4Thumbnail.png";
import ReadingVideoThumbnail5 from "../Assets/Reading/training5Thumbnail.png";
import ReadingVideoThumbnail6 from "../Assets/Reading/training6Thumbnail.png";
import {ASSESSMENT_TYPE, BADGE_ID, LEVEL_ONE_OUTCOME_SLUG} from "./Constants";

export class AssetManager {
	static ASSETS = {
		ASSESSMENT: {
			TYPES: {
				[ASSESSMENT_TYPE.CAREGIVER]: {
					ENABLED: CaregiverAssessment,
					DISABLED: CaregiverAssessmentDisabled,
					INVERSE: CaregiverAssessmentInverse,
				},
				[ASSESSMENT_TYPE.EDUCATOR]: {
					ENABLED: TeacherAssessment,
					DISABLED: TeacherAssessmentDisabled,
				},
				[ASSESSMENT_TYPE.ELDER]: {
					ENABLED: ElderAssessment,
					DISABLED: ElderAssessmentDisabled,
				},
				[ASSESSMENT_TYPE.STUDENT]: {
					ENABLED: StudentAssessment,
					DISABLED: StudentAssessmentDisabled,
				},
			},
			MATH: {
				WHATANDWHERE: {
					12010: WhatGames,
					12011: WhatSingDance,
					12012: WhatHelpCook,
					12013: WhatBuild,
					12014: WhatDraw,
					12015: WhatSort,
					12016: WhatShop,
					12017: WhatPuzzle,
					12020: WhereHome,
					12021: WhereSchool,
					12022: WhereCommunity,
				},
				STATIC: {
					BERRY_ESTIMATION: BerryEstimation,
					MAKING_CONNECTIONS: MakingConnections,
					PIECE_ESTIMATION: PieceEstimation,
				},
			},
			ROLES: {
				MOM: WhoMom,
				DAD: WhoDad,
				GRANDPARENTS: WhoGrandParents,
				SIBLINGS: WhoSiblings,
				AUNT_UNCLE: WhoAuntUncle,
				FRIENDS: WhoFriends,
				COUSINS: WhoCousins,
				TEACHER: WhoTeacher,
			},
		},
		CHARACTER: {
			CHARACTER_404: Character,
		},
		ICON: {
			TREE_ICON: TreeIcon,
			LEAF_ICON: LeafIcon,
			NAV_HOME_ICON: CaregiverNavHomeIcon,
			NAV_ACTIVITY_ICON: CaregiverNavActivityIcon,
			ACTIVITY_ICON_MOBILE: ActivitiesIcon,
			ACTIVITY_ICON_MOBILE_ACTIVE: ActivitiesIconActive,
			HOME_ICON_MOBILE: HomeIcon,
			HOME_ICON_MOBILE_ACTIVE: HomeIconActive,
		},
		LOGO: {
			SPRIG_HOME: {
				SRC: SprigHomeLogo,
				ALT: "alt_text.subscription.sprig_home_logo",
			},
			SPRIG_HOME_WHITE: {
				SRC: SprigHomeLogoWhite,
				ALT: "alt_text.subscription.sprig_home_logo",
			},
			SPRIG_LANGUAGE: {
				SRC: SprigLanguageLogo,
				ALT: "alt_text.subscription.sprig_language_logo",
			},
			SPRIG_LANGUAGE_WHITE: {
				SRC: SprigLanguageLogoWhite,
				ALT: "alt_text.subscription.sprig_language_logo",
			},
			SPRIG_LEAVES: SprigLogoLeaves,
			SPRIG_LEAVES_GREEN: SprigLogoLeavesGreen,
			SPRIG_LEAVES_WHITE: SprigLogoLeavesWhite,
			SPRIG_MATH: {
				SRC: SprigMathLogo,
				ALT: "alt_text.subscription.sprig_math_logo",
			},
			SPRIG_HOME_APP: {
				SRC: SprigHomeAppLogo,
				ALT: "alt_text.subscription.sprig_home_app_logo",
			},
			SPRIG_LIBRARY_APP: {
				SRC: SprigLibraryAppLogo,
				ALT: "alt_text.subscription.sprig_library_app_logo",
			},
			SPRIG_READING_APP: {
				SRC: SprigReadingLogo,
				ALT: "alt_text.subscription.sprig_reading_logo",
			},
		},
		ONBOARDING: {
			BADGE: OnboardingBadge,
			RESULTS: OnboardingResults,
			ACTIVITIES: OnboardingLeaves,
			WELCOME: OnboardingTree,
			HM_CHARACTER: HMCharacter,
			SPRIG_HOME: {
				APP_ICON: OnboardingAppIcon,
				WELCOME: OnboardingWelcome,
				TREE_GROWTH: OnboardingTreeGrowth,
				LEARNING_TIME: OnboardingLearningTime,
				HOME: OnboardingHome,
				ASSESSMENT: OnboardingAssessment,
				ACTIVITIES: OnboardingActivities,
			},
		},
		OUTCOMES: {
			LEARNING: {
				READING_VIEWING: ReadingAndViewing,
				LISTENING_SPEAKING: ListeningAndSpeaking,
				PATTERNS_RELATIONS: PatternsAndRelations,
				NUMBER_SENSE: NumberSense,
				GEOMETRY: Geometry,
				MEASUREMENT: Measurement,
			},
		},
		PDF_IMAGE: PdfImage,
		REWARDS: {
			BADGES: {
				[BADGE_ID.BIRDS_OF_A_FEATHER]: {
					TIER_1: {
						ASSET: {
							EARNED: BirdsOfAFeatherTier1,
							LOCKED: BirdsOfAFeatherTier1Grey,
						},
						ALT: "Birds of a feather badge",
					},
					TIER_2: {
						ASSET: {
							EARNED: BirdsOfAFeatherTier2,
							LOCKED: BirdsOfAFeatherTier2Grey,
						},
						ALT: "Birds of a feather badge",
					},
					TIER_3: {
						ASSET: {
							EARNED: BirdsOfAFeatherTier3,
							LOCKED: BirdsOfAFeatherTier3Grey,
						},
						ALT: "Birds of a feather badge",
					},
				},
				[BADGE_ID.HOP_TO_IT]: {
					TIER_1: {
						ASSET: {
							EARNED: HopToIt1,
							LOCKED: HopToIt1Grey,
						},
						ALT: "Hop to it",
					},
					TIER_2: {
						ASSET: {
							EARNED: HopToIt2,
							LOCKED: HopToIt2Grey,
						},
						ALT: "Hop to it",
					},
					TIER_3: {
						ASSET: {
							EARNED: HopToIt3,
							LOCKED: HopToIt3Grey,
						},
						ALT: "Hop to it",
					},
				},
				[BADGE_ID.HOME_TWEET_HOME]: {
					TIER_1: {
						ASSET: {
							EARNED: HomeTweetHome1,
							LOCKED: HomeTweetHome1Grey,
						},
						ALT: "Home Tweet Home",
					},
					TIER_2: {
						ASSET: {
							EARNED: HomeTweetHome2,
							LOCKED: HomeTweetHome2Grey,
						},
						ALT: "Home Tweet Home",
					},
					TIER_3: {
						ASSET: {
							EARNED: HomeTweetHome3,
							LOCKED: HomeTweetHome3Grey,
						},
						ALT: "Home Tweet Home",
					},
				},
				[BADGE_ID.RISE_AND_SHINE]: {
					TIER_1: {
						ASSET: {
							EARNED: RiseAndShine1,
							LOCKED: RiseAndShine1Grey,
						},
						ALT: "Home Tweet Home",
					},
					TIER_2: {
						ASSET: {
							EARNED: RiseAndShine2,
							LOCKED: RiseAndShine2Grey,
						},
						ALT: "Home Tweet Home",
					},
				},
				[BADGE_ID.BUSY_AS_A_BEE]: {
					TIER_1: {
						ASSET: {
							EARNED: BusyAsABee1,
							LOCKED: BusyAsABee1Grey,
						},
						ALT: "Home Tweet Home",
					},
					TIER_2: {
						ASSET: {
							EARNED: BusyAsABee2,
							LOCKED: BusyAsABee2Grey,
						},
						ALT: "Home Tweet Home",
					},
				},
				[BADGE_ID.EVEN_STEVEN]: {
					TIER_1: {
						ASSET: {
							EARNED: EvenSteven1,
							LOCKED: EvenSteven1Grey,
						},
						ALT: "Home Tweet Home",
					},
					TIER_2: {
						ASSET: {
							EARNED: EvenSteven2,
							LOCKED: EvenSteven2Grey,
						},
						ALT: "Home Tweet Home",
					},
				},
				[BADGE_ID.SQUIRRELED_AWAY]: {
					TIER_1: {
						ASSET: {
							EARNED: SquirreledAway1,
							LOCKED: SquirreledAway1Grey,
						},
						ALT: "Home Tweet Home",
					},
					TIER_2: {
						ASSET: {
							EARNED: SquirreledAway2,
							LOCKED: SquirreledAway2Grey,
						},
						ALT: "Home Tweet Home",
					},
				},
				[BADGE_ID.UNBELEAFABLE]: {
					TIER_1: {
						ASSET: {
							EARNED: Unbeleafable1,
							LOCKED: Unbeleafable1Grey,
						},
						ALT: "Home Tweet Home",
					},
					TIER_2: {
						ASSET: {
							EARNED: Unbeleafable2,
							LOCKED: Unbeleafable2Grey,
						},
						ALT: "Home Tweet Home",
					},
				},
				[BADGE_ID.CHITTER_CHATTER]: {
					TIER_1: {
						ASSET: {
							EARNED: ChitterChatter1,
							LOCKED: ChitterChatter1Grey,
						},
						ALT: "Hop to it",
					},
					TIER_2: {
						ASSET: {
							EARNED: ChitterChatter2,
							LOCKED: ChitterChatter2Grey,
						},
						ALT: "Hop to it",
					},
					TIER_3: {
						ASSET: {
							EARNED: ChitterChatter3,
							LOCKED: ChitterChatter3Grey,
						},
						ALT: "Hop to it",
					},
				},
				[BADGE_ID.SPROUT_UP]: {
					TIER_1: {
						ASSET: {
							EARNED: SproutUp,
							LOCKED: SproutUpGrey,
						},
						ALT: "Sprout up",
					},
				},
				[BADGE_ID.TREEMENDOUS]: {
					TIER_1: {
						ASSET: {
							EARNED: Treemendous1,
							LOCKED: Treemendous1Grey,
						},
						ALT: "Treemendous",
					},
					TIER_2: {
						ASSET: {
							EARNED: Treemendous2,
							LOCKED: Treemendous2Grey,
						},
						ALT: "Treemendous",
					},
					TIER_3: {
						ASSET: {
							EARNED: Treemendous3,
							LOCKED: Treemendous3Grey,
						},
						ALT: "Treemendous",
					},
					TIER_4: {
						ASSET: {
							EARNED: Treemendous4,
							LOCKED: Treemendous4Grey,
						},
						ALT: "Treemendous",
					},
					TIER_5: {
						ASSET: {
							EARNED: Treemendous5,
							LOCKED: Treemendous5Grey,
						},
						ALT: "Treemendous",
					},
					TIER_6: {
						ASSET: {
							EARNED: Treemendous6,
							LOCKED: Treemendous6Grey,
						},
						ALT: "Treemendous",
					},
				},
				HIDDEN: {
					SRC: HiddenBadge,
					ALT: "Hidden",
				},
			},
		},
		SUBSCRIPTION: {
			REDEEM_STUDENT_CODE: {
				SRC: RedeemStudentCode,
				ALT: "alt_text.subscription.redeem_student_code",
			},
			PAID_SUBSCRIPTION: {
				SRC: PaidSubscription,
				ALT: "alt_text.subscription.paid_subscription",
			},
		},
		READING: {
			VIDEO: {
				THUMBNAIL_1: ReadingVideoThumbnail1,
				THUMBNAIL_2: ReadingVideoThumbnail2,
				THUMBNAIL_3: ReadingVideoThumbnail3,
				THUMBNAIL_4: ReadingVideoThumbnail4,
				THUMBNAIL_5: ReadingVideoThumbnail5,
				THUMBNAIL_6: ReadingVideoThumbnail6,
			},
		},
		HOLISTIC_MATH_PROTOTYPE: {
			SEED: Seed,
			SPROUT: Sprout,
			BLOOM: Bloom,
			FLOWER: Flower,
		},
	};

	static getAllAssets() {
		return this.ASSETS;
	}

	static getBadgeAssetById(id: string | number) {
		const badge = this.ASSETS.REWARDS.BADGES[id];
		if (badge) return badge;
		console.error(`No badge asset exists for id: ${id}`);
		return null;
	}

	static getOutcomeAssetBySlug(slug) {
		switch (slug) {
			case LEVEL_ONE_OUTCOME_SLUG.READING_VIEWING:
			case LEVEL_ONE_OUTCOME_SLUG.E_SPATIAL_SENSE:
				return this.ASSETS.OUTCOMES.LEARNING.READING_VIEWING;
			case LEVEL_ONE_OUTCOME_SLUG.GEOMETRY:
			case LEVEL_ONE_OUTCOME_SLUG.A_SOCIAL_EMOTIONAL_LEARNING:
				return this.ASSETS.OUTCOMES.LEARNING.GEOMETRY;
			case LEVEL_ONE_OUTCOME_SLUG.MEASUREMENT:
			case LEVEL_ONE_OUTCOME_SLUG.B_NUMBERS:
				return this.ASSETS.OUTCOMES.LEARNING.MEASUREMENT;
			case LEVEL_ONE_OUTCOME_SLUG.NUMBER_SENSE:
			case LEVEL_ONE_OUTCOME_SLUG.C_ALGEBRA:
				return this.ASSETS.OUTCOMES.LEARNING.NUMBER_SENSE;
			case LEVEL_ONE_OUTCOME_SLUG.PATTERNS_AND_RELATIONS:
			case LEVEL_ONE_OUTCOME_SLUG.D_DATA:
				return this.ASSETS.OUTCOMES.LEARNING.PATTERNS_RELATIONS;
			case LEVEL_ONE_OUTCOME_SLUG.LISTENING_SPEAKING:
			case LEVEL_ONE_OUTCOME_SLUG.F_FINANCIAL_LITERACY:
			default:
				return this.ASSETS.OUTCOMES.LEARNING.LISTENING_SPEAKING;
		}
	}
}
