import React, {Children, useEffect} from "react";
import {connect} from "react-redux";

import {ErrorReporterActions} from "../../../../Actions";

interface ErrorReporterProps {
	actionTypes: string[];
	doNotRemoveOnUnmount?: boolean;
	children: JSX.Element[];
}

interface ErrorReporterReducerProps {
	queue: {actionType: string; error: {data: {message: string}}}[];
}

const ErrorReporter = ({
	actionTypes = [],
	children,
	doNotRemoveOnUnmount = false,
	queue = [],
}: ErrorReporterProps & ErrorReporterReducerProps) => {
	const error = queue.find((queueItem) =>
		actionTypes.includes(queueItem?.actionType)
	);

	useEffect(() => {
		return () => {
			if (!doNotRemoveOnUnmount && error)
				ErrorReporterActions.removeErrorFromQueue(error.actionType);
		};
	}, [doNotRemoveOnUnmount, error, queue]);

	return error ? (
		<div className="error-reporter">
			{Children.map(children, (child) => {
				return React.cloneElement(child, {
					error: error?.error?.data?.message,
				});
			})}
		</div>
	) : null;
};

export default connect((state: {errorReporter: ErrorReporterReducerProps}) => ({
	queue: state.errorReporter.queue,
}))(ErrorReporter);
