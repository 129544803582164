import "../../../../Stylesheets/css/scss/Components/Portal/Public/Toasts/Toast.css";

import i18n from "i18next";
import React, {useEffect, useState} from "react";

import {ActivityMonitorActions} from "../../../../Actions";
import {Icon} from "../../../Public";

export const Toast = ({id, title, type = "action", message}) => {
	const [showMessage, setShowMessage] = useState(false);

	const onClickIcon = () => {
		setShowMessage(true);
	};

	const onRemoveToast = () => {
		ActivityMonitorActions.removeFromQueue(id);
	};

	const maximumMessageLength = 60;

	useEffect(() => {
		const interval = setInterval(() => {
			ActivityMonitorActions.removeFromQueue(id);
			clearInterval(interval);
		}, 10000);

		return () => clearInterval(interval);
	}, [id]);

	const getMessage = () => {
		if (typeof message === "string") {
			return message;
		} else if (typeof message === "object" && message.type.name === "Trans") {
			const keys = Object.keys(message.props.children[0].props.children);
			const i18nString = i18n.t(`${message.props.i18nKey}`, {
				[keys[0]]: message.props.children[0].props.children[keys[0]],
			});
			return i18nString.replace("<0>", "").replace("</0>", "");
		}
	};

	return (
		<div
			className={`toast-notification${showMessage ? `-show-full-message` : ``}`}
		>
			<div className="toast-notification-head">
				<div className={`toast-notification-status ${type}`} />
				<div className="toast-notification-title" title={title}>
					<small>{title}</small>
				</div>
				<div className="toast-notification-close" onClick={onRemoveToast}>
					<Icon type="faTimes" />
				</div>
			</div>
			<div
				className={`toast-notification-body${
					showMessage ? `-show-full-message` : ``
				}`}
			>
				<div className="toast-notification-message" title={getMessage()}>
					<small>{message}</small>
				</div>
				{!showMessage && message.length > maximumMessageLength && (
					<div
						className="toast-notification-expand-message"
						onClick={onClickIcon}
					>
						<Icon type="faChevronCircleRight" />
					</div>
				)}
			</div>
		</div>
	);
};
