import ReactPixel from "react-facebook-pixel";
import TagManager from "react-gtm-module";

if (!window.sprig) {
	if (process.env.REACT_APP_SPRIG_ENV === "production") {
		window.sprig = {
			gtmEnabled: true,
			gtmArguments: {
				gtmId: "GTM-5LXQMHX",
				auth: "XMg8HFmUQTwhraKuDzuaog",
				preview: "env-2",
			},
			domains: require("../Assets/Taxonomy/domains_production.json"),
			curricula: require("../Assets/Taxonomy/curricular_production.json"),
			learningOutcomes: require("../Assets/Taxonomy/level_1_outcomes_production.json"),
			generalCurricularOutcomes: require("../Assets/Taxonomy/level_2_outcomes_production.json"),
			specificCurricularOutcomes: require("../Assets/Taxonomy/level_3_outcomes_production.json"),
			REACT_APP_BACKEND_URL: "https://www.spriglearning.com/",
		};
	} else {
		window.sprig = {
			gtmEnabled: false,
			gtmArguments: undefined,
			domains: require("../Assets/Taxonomy/domains_development.json"),
			curricula: require("../Assets/Taxonomy/curricular_development.json"),
			learningOutcomes: require("../Assets/Taxonomy/level_1_outcomes_development.json"),
			generalCurricularOutcomes: require("../Assets/Taxonomy/level_2_outcomes_development.json"),
			specificCurricularOutcomes: require("../Assets/Taxonomy/level_3_outcomes_development.json"),
			REACT_APP_BACKEND_URL: "https://www.dev.spriglearning.intra.sprig/",
		};
	}
}

// Google Tag Manager Snippets
if (window.sprig.gtmEnabled) {
	TagManager.initialize(window.sprig.gtmArguments);
}

if (window.sprig.metaPixelEnabled) {
	ReactPixel.init(window.sprig.metaPixelArguments.metaPixelId);
}
