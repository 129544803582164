module.exports = {
	ID: {
		ACCOUNT: {
			ACCOUNT_INFORMATION: "account-information",
			CANCEL_SUBSCRIPTION: "cancel-subscription-button",
			CONFIRM_CANCEL_SUBSCRIPTION: "cancel-subscription-confirmation",
			CONFIRM_PASSWORD: "my-account-confirm-password",
			EDIT_EMAIL_BUTTON: "edit-email-button",
			EDIT_EMAIL_INPUT: "edit-email-input",
			EDIT_FIRST_NAME_BUTTON: "edit-first-name-button",
			EDIT_FIRST_NAME_INPUT: "edit-first-name-input",
			EDIT_LAST_NAME_BUTTON: "edit-last-name-button",
			EDIT_LAST_NAME_INPUT: "edit-last-name-input",
			MY_ACCOUNT_LINK: "my-account-link",
			PASSWORD: "my-account-password",
			SAVE_PASSWORD: "my-account-save-password",
			SAVE_PROFILE: "my-account-save-profile",
			SIGN_OUT: "sign-out-button",
			USER_DROPDOWN_BUTTON: "user-dropdown-button",
			USER_DROPDOWN_CURRENT_ROLE_BUTTON: "user-dropdown-current-role-button",
			USER_DROPDOWN_USER_ROLE_BUTTON: "user-dropdown-role-button",
			USER_DROPDOWN_ROLE_BUTTON: "user-dropdown-role-button",
			STANDALONE_RESOURCES: "educator-standalone-resources",
			STANDALONE_ACTIVITIES: "educator-standalone-activities",
		},
		ADMIN: {
			IMPORT: {
				ADD_CLASSROOM: "admin-import-add-classroom",
				ADD_CLASSROOM_INPUT: "classroomName",
				ADD_CLASSROOM_MODAL: "admin-import-add-classroom-modal",
				ADD_SCHOOL: "admin-import-add-school",
				ADD_SCHOOL_INPUT: "admin-import-add-school-input",
				ADD_SCHOOL_MODAL: "admin-import-add-school-modal",
				CONFIRM_GENERATE_TEMPLATE:
					"admin-import-confirm-generate-template-button",
				DOWNLOAD_TEMPLATE: "admin-import-download-template-button",
				GENERATE_TEMPLATE: "admin-import-generate-template-button",
				SCHOOL_CARD: "admin-import-school-card",
				SUBMIT_BUTTON: "admin-import-submit-button",
				SUBMIT_UPLOAD: "admin-import-submit-upload-button",
				SUBSCRIPTION_LIMITS: "admin-import-subscription-limits",
				UPDATE_CLASSROOM_MODAL: "admin-import-update-classroom-modal",
				UPDATE_SCHOOL_MODAL: "admin-import-update-school",
				UPLOAD_ERROR_TABLE: "admin-error-list-table",
				UPLOAD_FILE: "admin-import-upload-file-button",
				UPLOAD_FILE_AFTER_ERROR: "admin-import-upload-file-button-after-error",
				UPLOAD_SUCCESS: "admin-import-submit-upload-success",
			},
			NOTIFICATION: {
				ADD_STUDENT_MODAL: "admin-notification-add-student-modal",
				EDIT_STUDENT_MODAL: "admin-notification-edit-student-modal",
				NOTIFICATION_BUTTON: "admin-notification-button",
				NOTIFICATION_RECEIVE_DATE: "admin-notification-receive-date",
				NOTIFICATION_TAB: "admin-notification-tab",
				REMOVE_STUDENT_MODAL: "admin-notification-remove-student-modal",
				REMOVE_STUDENT_MODAL_SUBMIT:
					"admin-notification-remove-student-modal-submit",
				REMOVE_STUDENT_MODAL_TITLE:
					"admin-notification-remove-student-modal-title",
				RESOLVE_BUTTON: "admin-notification-resolve-button",
			},
			SCHOOL: {
				CLASSROOM_ADD_USER_MODAL: "admin-school-classroom-add-user-modal",
				CLASSROOM_ADD_USER_MODAL_CONFIRM_ADD_USER:
					"admin-school-classroom-add-user-confirm-add-user",
				CLASSROOM_ADD_USER_MODAL_CONFIRM_SEARCH:
					"admin-school-classroom-add-user-confirm-search",
				CLASSROOM_ADD_USER_MODAL_INPUT:
					"admin-school-classroom-add-user-modal-search",
				CLASSROOM_ADD_USER_MODAL_SUBMIT_ADD_USER:
					"admin-school-classroom-add-user-submit",
				CLASSROOM_CARD: "admin-school-classroom-card",
				CLASSROOM_CARD_ADD_USER_BUTTON:
					"admin-school-classroom-add-user-button",
				CLASSROOM_CARD_COLLAPSE: "admin-school-classroom-card-collapse-button",
				CLASSROOM_CARD_TABLE_ROW_STUDENT:
					"admin-school-classroom-table-row-student",
				CLASSROOM_CARD_TABLE_ROW_STUDENT_EDIT_USER:
					"admin-school-classroom-table-row-student-edit-button",
				CLASSROOM_CARD_TABLE_ROW_STUDENT_REMOVE_USER:
					"admin-school-classroom-table-row-student-remove-button",
				CLASSROOM_CARD_TABLE_ROW_TEACHER:
					"admin-school-classroom-table-row-teacher",
				CLASSROOM_CARD_TABLE_ROW_TEACHER_EDIT_USER:
					"admin-school-classroom-table-row-teacher-edit-button",
				CLASSROOM_CARD_TABLE_ROW_TEACHER_REMOVE_USER:
					"admin-school-classroom-table-row-teacher-remove-button",
				CLASSROOM_EDIT_NAME_BUTTON: "admin-school-classroom-edit-name-button",
				CLASSROOM_EDIT_NAME_INPUT: "admin-school-classroom-edit-name-input",
				CLASSROOM_NAME: "admin-school-classroom-name",
				CLASSROOM_REMOVE_USER_MODAL_BUTTON:
					"admin-school-classroom-remove-user-modal-button",
				CLASSROOM_TABLE_STUDENTS: "admin-school-classroom-table-students",
				CLASSROOM_TABLE_TEACHERS: "admin-school-classroom-table-teachers",
				STUDENT_GENDER: "admin-school-student-gender",
				STUDENT_GRADE: "admin-school-student-grade",
				STUDENT_IDENTITY: "admin-school-student-IDENTITY",
				STUDENT_NAME: "admin-school-student-name",
				TEACHER_NAME: "admin-school-teacher-name",
			},
			SCHOOLS: {
				SCHOOL_CARD: "admin-school-card",
				SCHOOL_CARD_EDIT_BUTTON: "admin-school-edit-button",
				SCHOOL_CARD_EDIT_MODAL: "admin-school-edit-modal",
				SCHOOL_CARD_EDIT_MODAL_SAVE: "admin-school-edit-modal-save",
				SCHOOL_CARD_TITLE: "admin-school-card-title",
				SCHOOL_CARD_VIEW_SCHOOL_BUTTON: "admin-school-view-school-button",
				SECONDARY_NAV_LINK: "admin-schools-nav-link",
			},
			USERS: {
				ADD_USER_BUTTON: "admin-add-user-button",
				ADD_USER_MODAL: "admin-add-user-modal",
				ADD_USER_SELECT_ROLE: "admin-add-user-select-role",
				DELETE_USER: "admin-users-delete-user",
				DELETE_USER_MODAL: "admin-users-delete-user-modal",
				DELETE_USER_VERIFICATION: "admin-users-delete-user-verification",
				EDIT_USER_BUTTON: "admin-edit-user-button",
				EDIT_USER_MODAL: "admin-edit-user-modal",
				EDIT_TEACHER_MODAL: "admin-edit-teacher-modal",
				EDIT_USER_SAVE_BUTTON: "admin-edit-user-save-changes-button",
				SAVE_USER: "admin-users-save-user",
				SEARCH_USERS_BUTTON: "admin-search-users-button",
				SEARCH_USERS_INPUT: "admin-search-users-input",
				SELECT_CLASS: "admin-select-class",
				SELECT_CLASS_MENU: "admin-select-class-menu",
				SELECT_ROLE_PRINCIPAL: "admin-select-principal-role",
				SELECT_ROLE_STUDENT: "admin-select-student-role",
				SELECT_ROLE_TEACHER: "admin-select-teacher-role",
				SELECT_SCHOOL: "admin-select-school",
				SELECT_SCHOOL_MENU: "admin-select-school-menu",
				SELECT_USER_ALL: "admin-select-user-all",
				SELECT_USER_BUTTON: "admin-select-user-button",
				SELECT_USER_STUDENT: "admin-select-user-student",
				SELECT_USER_TEACHER: "admin-select-user-teacher",
				TABLE_ROW_NAME: "admin-table-row-name",
				TABLE_ROW_ROLE: "admin-table-row-role",
				USER_TABLE_BODY: "admin-user-table-body",
				USERS_CONTENT: "admin-users-content",
			},
		},
		CAREGIVER: {
			HOME: {
				ACTIVITY_COMPLETION_TABLE: "caregiver-activity-completion-table",
				ASSESSMENT_OVERVIEW_CAREGIVER_BUTTON:
					"assessment-overview-caregiver-button",
				ASSESSMENT_OVERVIEW_MODAL: "assessment-overview-modal",
				ASSESSMENTS_AND_SURVEYS: "assessments-and-surveys",
				BADGE_BUTTON: "caregiver-student-badge-button",
				CAREGIVER_SECONDARY_ACTIONS_BUTTON_LARGE:
					"caregiver-secondary-actions-button-large",
				CAREGIVER_SECONDARY_ACTIONS_MENU: "caregiver-secondary-actions-menu",
				CAREGIVER_SURVEY_MODAL: "caregiver-survey-modal",
				FEEDBACK_BUTTON: "caregiver-navigation-feedback-button",
				NAV_LINK: {
					ACTIVITIES: "caregiver-nav-link-activities",
					HOME: "caregiver-nav-link-home",
				},
				RESULTS_BUTTON: "caregiver-student-results-button",
				RESULTS_OPTIONS: "caregiver-student-results-options-button",
				STICKERS_TOGGLE_BUTTON: "caregiver-stickers-toggle-button",
				STUDENT_PAST_TERMS_MODAL: "student-past-terms-modal",
				STUDENT_SELECTION_ADD_STUDENT:
					"caregiver-navigation-student-selection-add-child",
				STUDENT_SELECTION_BUTTON:
					"caregiver-navigation-student-selection-button",
				STUDENT_SELECTION_MENU: "caregiver-navigation-student-selection-menu",
				STUDENT_SELECTION_MENU_ITEM:
					"caregiver-navigation-student-selection-menu-item",
				TREE_ACTIVITY_METRICS: "caregiver-tree-activity-metrics",
			},
			ONBOARDING: {
				ONBOARDING_MODAL: "caregiver-onboarding",
				ONBOARDING_MODAL_NEXT_PAGE: "caregiver-onboarding-next-page",
				SPRIG_HOME_ONBOARDING_MODAL: "sprig-home-onboarding",
				SPRIG_HOME_ONBOARDING_MODAL_NEXT_PAGE: "sprig-home-onboarding-next",
				VIEW_BADGE: "caregiver-onboarding-view-badge",
			},
			REGISTRATION: {
				REGISTRATION_LINK: "caregiver-registration-link",
				SIGN_UP: {
					CONFIRM_PASSWORD_INPUT: "caregiver-register-confirm-password",
					EMAIL_INPUT: "caregiver-register-email",
					FIRST_NAME_INPUT: "caregiver-register-first-name",
					LAST_NAME_INPUT: "caregiver-register-last-name",
					PASSWORD_INPUT: "caregiver-register-password",
					POSTAL_CODE_INPUT: "caregiver-register-postal-code",
					SIGN_UP_SUBMIT: "caregiver-register-submit",
					SUBMIT_POSTAL_CODE: "caregiver-submit-postal-code",
					TERMS_AND_CONDITIONS_CHECKBOX: "caregiver-register-terms-checkbox",
				},
			},
			SUBSCRIPTION: {
				ADD_CHILD_PAID_SUBSCRIBER_MODAL:
					"caregiver-subscription-add-child-paid-subscription-modal",
				ADD_CHILD_PAID_SUBSCRIBER_MODAL_INPUT_FIRST_NAME:
					"caregiver-subscription-add-child-paid-subscription-modal-fn-input",
				ADD_CHILD_PAID_SUBSCRIBER_MODAL_INPUT_LAST_NAME:
					"caregiver-subscription-add-child-paid-subscription-modal-ln-input",
				ADD_CHILD_PAID_SUBSCRIBER_UPGRADE_SUBSCRIPTION_BUTTON:
					"caregiver-subscription-add-child-paid-subscription-upgrade-button",
				ADD_CHILD_REDEEM_CODE_BUTTON:
					"caregiver-subscription-add-child-redeem-code-button",
				ADD_CHILD_PAID_SUBSCRIBER_SUBMIT_BUTTON:
					"caregiver-subscription-add-child-paid-subscription-button",
				FREE_TRIAL: {
					ACTIVITIES_UPGRADE: "caregiver-free-trial-activities-upgrade-button",
					CUSTOMIZE_EXPERIENCE_MODAL:
						"caregiver-free-trial-customize-experience-modal",
					FREE_TRIAL_CHILD_FIRST_NAME: "caregiver-free-trial-child-first-name",
					FREE_TRIAL_CHILD_GRADE: "caregiver-free-trial-child-grade",
					FREE_TRIAL_CHILD_LAST_NAME: "caregiver-free-trial-child-last-name",
					FREE_TRIAL_MODAL: "caregiver-free-trial-modal",
					FREE_TRIAL_OPTION_BUTTON: "caregiver-free-trial-option-button",
					FREE_TRIAL_SUBMIT: "caregiver-free-trial-submit-button",
					SECONDARY_NAV_UPGRADE:
						"caregiver-free-trial-secondary-nav-upgrade-button",
				},
				PAYMENT_PLAN_OPTION: "caregiver-payment-plan-option",
				PAYMENT_PLAN_OPTION_MONTHLY: "caregiver-payment-plan-option-monthly",
				PAYMENT_PLAN_OPTION_YEARLY: "caregiver-payment-plan-option-yearly",
				REDEEM_CODE_MODAL: "caregiver-redeem-code-modal",
				REDEEM_CODE_MODAL_GO_BACK_BUTTON:
					"caregiver-redeem-code-modal-go-back-button",
				REDEEM_CODE_MODAL_DONE_BUTTON:
					"caregiver-redeem-code-modal-done-button",
				REDEEM_CODE_MODAL_NEXT_BUTTON:
					"caregiver-redeem-code-modal-next-button",
				SELECT_SUBSCRIPTION_BUTTON: "caregiver-subscription-button",
				SUBSCRIPTION_PLAN_MODAL: "caregiver-subscription-plan-modal",
			},
			SURVEY: {
				CHILD_ASSESSMENT_MODAL: {
					MODAL: "child-assessment-modal",
					START_BUTTON: "child-assessment-start-button",
					LANGUAGE_DROPDOWN: "child-assessment-language-dropdown",
					LANGUAGE_DROPDOWN_MENU: "child-assessment-language-dropdown-menu",
					LANGUAGE_DROPDOWN_ITEM: "child-assessment-language-dropdown-button",
					QUESTION_OPTION_BUTTON: "child-assessment-question-option-button",
					DONE_BUTTON: "child-assessment-done-button",
				},
				CONFIRMATION_MODAL: "caregiver-survey-confirmation-modal",
				PAGE_TITLE: "caregiver-survey-page-title",
				START_BUTTON: "caregiver-survey-start-button",
				SUBMIT_BUTTON: "caregiver-survey-submit-button",
			},
		},
		DOMAIN: {
			MATH: "15",
			ORAL_LANGUAGE: "4",
		},
		EDUCATOR: {
			CLASSROOM: {
				CLASSROOM_DROPDOWN: "educator-classroom-dropdown",
				CLASSROOM_DROPDOWN_MENU: "educator-classroom-dropdown-menu",
				CLASSROOM_DROPDOWN_MENU_CLASSROOM_LIST_BUTTON:
					"educator-classroom-dropdown-menu-classroom-list-button",
				CLASSROOM_DROPDOWN_MENU_ITEM: "educator-classroom-dropdown-menu-item",
				CLASSROOM_DROPDOWN_MENU_ITEM_CLASSROOM_NAME:
					"educator-classroom-dropdown-menu-item-classroom-name",
				CLASSROOM_DROPDOWN_MENU_ITEM_LINK_RADIO:
					"educator-classroom-dropdown-menu-item-link-radio",
				DASHBOARD: {
					DASHBOARD_CARD: "educator-dashboard-card",
					DASHBOARD_COMPLETE_ACTIVITIES:
						"educator-dashboard-completed-activities",
					DASHBOARD_TREE: "educator-dashboard-tree",
					LEARNING_OPPORTUNITIES: "educator-dashboard-learning-opportunities",
					LEARNING_OPPORTUNITIES_OUTCOME:
						"educator-dashboard-learning-opportunities-outcome",
					LEARNING_OPPORTUNITIES_OUTCOME_NAME:
						"educator-dashboard-learning-opportunities-outcome-name",
					STUDENT_LIST: "educator-dashboard-student-list",
					STUDENT_LIST_STUDENT_ROW:
						"educator-dashboard-student-list-student-row",
					STUDENT_LIST_STUDENT_ROW_NAME_BUTTON:
						"educator-dashboard-student-list-student-row-student-name",
				},
				LEARNING_ACTIVITY: {
					COMPLETE_RECOMMEND_ACTIVITY:
						"educator-learning-activity-complete-recommend-activity",
					COMPLETE_RECOMMEND_ACTIVITY_ALL_STUDENTS:
						"educator-learning-activity-complete-recommend-activity-all-students",
					COMPLETE_RECOMMEND_ACTIVITY_MILESTONE_GROUPS_ABOVE:
						"educator-learning-activity-complete-recommend-activity-milestone-groups-above",
					COMPLETE_RECOMMEND_ACTIVITY_MILESTONE_GROUPS_AT:
						"educator-learning-activity-complete-recommend-activity-milestone-groups-at",
					COMPLETE_RECOMMEND_ACTIVITY_MILESTONE_GROUPS_BELOW:
						"educator-learning-activity-complete-recommend-activity-milestone-groups-below",
					COMPLETE_RECOMMEND_ACTIVITY_CUSTOM_GROUPS:
						"educator-learning-activity-complete-recommend-activity-custom-groups",
					COMPLETE_RECOMMEND_ACTIVITY_STUDENT_ITEM:
						"educator-learning-activity-complete-recommend-activity-student-item",
					COMPLETE_RECOMMEND_ACTIVITY_BACK_BUTTON:
						"educator-learning-activity-complete-recommend-activity-back-button",
					CURRENT_LEARNING_OPPORTUNITIES_DOMAIN:
						"educator-learning-activity-current-learning-opportunities-domain",
					CURRENT_LEARNING_OPPORTUNITIES_LEARNING_OUTCOME:
						"educator-learning-activity-current-learning-opportunities-learning-outcome",
					CURRENT_LEARNING_OUTCOMES:
						"educator-learning-activity-current-learning-outcome",
					LEARNING_ACTIVITY_BUTTON:
						"educator-learning-activity-current-learning-opportunities-button",
					LEARNING_ACTIVITY_OUTCOME_TAB_GENERAL_OUTCOME:
						"educator-learning-activity-current-learning-opportunities-outcome-tab-general-outcome",
					LEARNING_ACTIVITY_OUTCOME_TAB_LEARNING_OUTCOME:
						"educator-learning-activity-current-learning-opportunities-outcome-tab-learning-outcome",
					LEARNING_ACTIVITY_OUTCOME_TAB_LEARNING_OUTCOMES:
						"educator-learning-activity-current-learning-opportunities-outcome-tab-learning-outcomes",
					LEARNING_ACTIVITY_OUTCOME_TAB_MATH_PROCESS:
						"educator-learning-activity-current-learning-opportunities-outcome-tab-math-process",
					LEARNING_ACTIVITY_OUTCOME_TAB_SPECIFIC_OUTCOME:
						"educator-learning-activity-current-learning-opportunities-outcome-tab-specific-outcome",
					CLASSROOM_NAV_LINK: "educator-learning-activities-nav-link",
				},
				PRIMARY_CLASSROOM_NAME: "educator-classroom-primary-nav-classroom-name",
				PRIMARY_DOMAIN: "educator-classroom-primary-nav-domain",
				REPORTS: {
					ACTIVITY_COMPLETION_REPORT_COMPETENCY:
						"educator-reports-completion-competency",
					ACTIVITY_COMPLETION_REPORT_GENERAL_OUTCOME:
						"educator-reports-completion-general-outcomes",
					ACTIVITY_COMPLETION_REPORT_LEARNING_OUTCOME:
						"educator-reports-completion-learning-outcomes",
					ACTIVITY_COMPLETION_REPORT_SPECIFIC_OUTCOME:
						"educator-reports-completion-specific-outcomes",
					ASSESSMENT_COMPLETION_REPORT:
						"educator-reports-assessment-completion",
					CLASSROOM_NAV_LINK: "educator-reports-nav-link",
					COMMUNITY_CULTURE: "educator-reports-community-culture",
					STUDENT_ASSESSMENTS: "educator-reports-student-assessments",
					STUDENT_ASSESSMENTS_MATH_ROW:
						"educator-reports-student-assessments-math-row",
					STUDENT_ASSESSMENTS_MATH_ROW_NAME:
						"educator-reports-student-assessments-math-row-name",
					STUDENT_ASSESSMENTS_MATH_ROW_VIEW_RESULTS_BUTTON:
						"educator-reports-student-assessments-math-row-view-results-button",
					STUDENT_MILESTONE_REPORT: "educator-reports-student-milestone-report",
				},
				RESOURCES: {
					CLASSROOM_NAV_LINK: "educator-resources-nav-link",
					RESOURCE_CARD: "educator-resources-card",
					RESOURCE_CARD_TITLE: "educator-resources-card-title",
					RESOURCE_CATEGORIES_BUTTON: "educator-resource-categories-button",
					RESOURCE_CATEGORY_ALL: "educator-resource-category-all",
					RESOURCE_CATEGORY_CLASSROOM_MATERIAL:
						"educator-resource-category-classroom-material",
					RESOURCE_CATEGORY_PARENT_ENGAGEMENT:
						"educator-resource-category-parent-engagement",
					RESOURCE_CATEGORY_SUPPORTING_DOCUMENTS:
						"educator-resource-category-supporting-documents",
					SEARCH_RESOURCES_INPUT: "educator-resource-search-input",
				},
				STUDENTS: {
					ADD_STUDENT_BUTTON: "educator-students-add-student-button",
					ADD_STUDENT_MODAL: "educator-students-add-student-modal",
					ADD_STUDENT_MODAL_SELECT_USER_ROLE:
						"educator-students-add-student-modal-select-user-role",
					ADD_STUDENT_MODAL_SELECT_USER_ROLE_MENU:
						"educator-students-add-student-modal-select-user-role-menu",
					ADD_STUDENT_SEND_REQUEST:
						"educator-students-add-student-send-request",
					CLASSROOM_NAV_LINK: "educator-students-nav-link",
					EDIT_GROUP_NAME_MODAL: "educator-student-group-edit-group-modal",
					EDIT_GROUP_NAME_MODAL_INPUT:
						"educator-student-group-edit-group-modal-input",
					EDIT_GROUP_NAME_MODAL_SAVE_CHANGE_BUTTON:
						"educator-student-group-edit-group-modal-save-change-button",
					EDIT_STUDENT_GROUP_BUTTON: "educator-student-group-edit-group-button",
					EDIT_STUDENT_MODAL: "educator-students-edit-student-modal",
					MOVE_STUDENTS_BUTTON: "educator-students-move-students-button",
					STUDENT_CARD: "educator-students-student-card",
					STUDENT_CARD_NAME: "educator-students-student-card-name",
					STUDENT_CARD_OPTIONS_BUTTON:
						"educator-students-student-card-options-button",
					STUDENT_CARD_OPTIONS_BUTTON_EDIT:
						"educator-students-student-card-progress-button-edit",
					STUDENT_CARD_OPTIONS_BUTTON_MENU:
						"educator-students-student-card-options-button-menu",
					STUDENT_CARD_OPTIONS_BUTTON_REMOVE:
						"educator-students-student-card-progress-button-remove",
					STUDENT_CODES_BUTTON: "educator-students-student-code-button",
					STUDENT_CODES_DELETE_CONFIRMATION_BUTTON:
						"educator-student-codes-delete-confirmation-button",
					STUDENT_CODES_DELETE_CONFIRMATION_MODAL:
						"educator-student-codes-delete-confirmation-modal",
					STUDENT_CODES_MODAL: "educator-student-codes-modal",
					STUDENT_GROUP: "educator-student-group-card",
					STUDENT_GROUP_MOVE_STUDENTS_BUTTON:
						"educator-student-group-move-students-button",
					STUDENT_GROUP_MOVE_STUDENTS_DROPDOWN_ITEM:
						"educator-student-group-move-students-dropdown-item",
					STUDENT_GROUP_MOVE_STUDENTS_MODAL:
						"educator-student-group-move-students-modal",
					STUDENT_GROUP_MOVE_STUDENTS_MODAL_CONFIRM_BUTTON:
						"educator-student-group-move-students-modal-confirm-button",
					STUDENT_GROUP_MOVE_STUDENTS_MODAL_MESSAGE:
						"educator-student-group-move-students-modal-message",
					STUDENT_GROUP_TITLE: "educator-student-group-card-title",
				},
				NAVIGATION: {
					PORTAL_TOUR: "educator-navigation-portal-tour",
				},
			},
			CLASSROOMS: {
				CLASSROOM_CARD: "educator-classroom-card",
				CLASSROOM_CARD_TITLE: "educator-classroom-card-title",
				CLASSROOM_CARD_DOMAIN: "educator-classroom-card-domain",
				CLASSROOM_CARD_VIEW_CLASSROOM_BUTTON:
					"educator-classroom-card-view-button",
				CLASSROOM_LIST: "educator-classroom-list",
				CLASSROOM_LIST_TERM_DROPDOWN: "educator-classroom-list-term-dropdown",
				CLASSROOM_LIST_TERM_DROPDOWN_MENU:
					"educator-classroom-list-term-dropdown-menu",
			},
			PUBLIC: {
				STUDENT_PROGRESS_MODAL: "educator-student-progress-modal",
				STUDENT_PROGRESS_MODAL_ADMIN_REQUEST_BUTTON:
					"educator-student-progress-modal-admin-request",
				STUDENT_PROGRESS_MODAL_ASSESSMENT_RESULTS:
					"educator-student-progress-modal-assessment-results",
				CREATE_ACTIVITY: {
					BUTTON: "educator-create-activity-button",
					SEARCH_INPUT: "educator-create-activity-search-input",
					SEARCH_BUTTON: "educator-create-activity-search-button",
					TABLE: "educator-create-activity-activity-table",
					ACTIVITY_ROW: "educator-create-activity-activity-row",
					ACTIVITY_ROW_ID: "educator-create-activity-activity-row-id",
					ACTIVITY_ROW_ORIGINAL_ID:
						"educator-create-activity-activity-row-original-id",
					ACTIVITY_ROW_NAME: "educator-create-activity-activity-row-name",
					ACTIVITY_ROW_STATUS: "educator-create-activity-activity-row-status",
					ACTIVITY_ROW_DOMAIN: "educator-create-activity-activity-row-domain",
					ACTIVITY_ROW_LANGUAGE:
						"educator-create-activity-activity-row-language",
					ACTIVITY_ROW_AUTHOR: "educator-create-activity-activity-row-author",
					ACTIVITY_ROW_LAST_MODIFIED:
						"educator-create-activity-activity-row-last-modified",
					ACTIVITY_ROW_DUPLICATE:
						"educator-create-activity-activity-row-duplicate-button",
					ACTIVITY_ROW_TRANSLATE:
						"educator-create-activity-activity-row-translate-button",
					TRANSLATE_ACTIVITY_MODAL:
						"educator-create-activity-translate-activity-modal",
					DUPLICATE_ACTIVITY_MODAL:
						"educator-create-activity-duplicate-activity-modal",
					DUPLICATE_ACTIVITY_MODAL_TITLE_INPUT:
						"educator-create-activity-duplicate-activity-modal-title-input",
					DUPLICATE_ACTIVITY_MODAL_DESCRIPTION_EDITOR:
						"educator-create-activity-duplicate-activity-modal-description-editor",
					DUPLICATE_ACTIVITY_MODAL_MATERIALS:
						"educator-create-activity-duplicate-activity-modal-materials",
					DUPLICATE_ACTIVITY_MODAL_PLACES:
						"educator-create-activity-duplicate-activity-modal-places",
					DUPLICATE_ACTIVITY_MODAL_LANGUAGES:
						"educator-create-activity-duplicate-activity-modal-languages",
					DUPLICATE_ACTIVITY_MODAL_GRADES:
						"educator-create-activity-duplicate-activity-modal-grades",
					DUPLICATE_ACTIVITY_MODAL_DOMAIN:
						"educator-create-activity-duplicate-activity-modal-domain",
					DUPLICATE_ACTIVITY_MODAL_CURRICULUM:
						"educator-create-activity-duplicate-activity-modal-curriculum",
					DUPLICATE_ACTIVITY_MODAL_CURRICULUM_MENU:
						"educator-create-activity-duplicate-activity-modal-curriculum-menu",
					DUPLICATE_ACTIVITY_MODAL_CURRICULUM_MENU_ITEM:
						"educator-create-activity-duplicate-activity-modal-curriculum-menu-item",
					DUPLICATE_ACTIVITY_MODAL_LEARNING_OUTCOME:
						"educator-create-activity-duplicate-activity-modal-learning-outcome",
					DUPLICATE_ACTIVITY_MODAL_GENERAL_OUTCOME:
						"educator-create-activity-duplicate-activity-modal-general-outcome",
					DUPLICATE_ACTIVITY_MODAL_SPECIFIC_OUTCOME_BUTTON:
						"educator-create-activity-duplicate-activity-modal-specific-outcome-button",
					DUPLICATE_ACTIVITY_MODAL_SPECIFIC_OUTCOME:
						"educator-create-activity-duplicate-activity-modal-specific-outcome",
					DUPLICATE_ACTIVITY_MODAL_COMPETENCY:
						"educator-create-activity-duplicate-activity-modal-competency",
				},
			},
		},
		EDITOR: {
			NAV_BACK: "editor-portal-nav-back-button",
			NEW_ACTIVITY_BUTTON: "editor-portal-new-activity-button",
			NEW_ACTIVITY_MODAL: "editor-portal-new-activity-modal",
		},
		PRINCIPAL: {
			NAVIGATION: {
				CLASSROOMS: "principal-navigation-classrooms",
				REPORTS: "principal-navigation-reports",
			},
			REPORTS: {
				ACTIVITY_COMPLETION_REPORT_COMPETENCY:
					"principal-reports-completion-competency",
				ACTIVITY_COMPLETION_REPORT_COMPETENCY_CLASSROOM:
					"principal-reports-completion-competency-classroom",
				ACTIVITY_COMPLETION_REPORT_COMPETENCY_SCHOOL:
					"principal-reports-completion-competency-school",
				ACTIVITY_COMPLETION_REPORT_GENERAL_OUTCOME:
					"principal-reports-completion-general-outcomes",
				ACTIVITY_COMPLETION_REPORT_GENERAL_OUTCOME_CLASSROOM:
					"principal-reports-completion-general-outcomes-classroom",
				ACTIVITY_COMPLETION_REPORT_GENERAL_OUTCOME_SCHOOL:
					"principal-reports-completion-general-outcomes-school",
				ACTIVITY_COMPLETION_REPORT_LEARNING_OUTCOME:
					"principal-reports-completion-learning-outcomes",
				ACTIVITY_COMPLETION_REPORT_LEARNING_OUTCOME_CLASSROOM:
					"principal-reports-completion-learning-outcomes-classroom",
				ACTIVITY_COMPLETION_REPORT_LEARNING_OUTCOME_SCHOOL:
					"principal-reports-completion-learning-outcomes-school",
				ACTIVITY_COMPLETION_REPORT_SPECIFIC_OUTCOME:
					"principal-reports-completion-specific-outcomes",
				ACTIVITY_COMPLETION_REPORT_SPECIFIC_OUTCOME_CLASSROOM:
					"principal-reports-completion-specific-outcomes-classroom",
				ACTIVITY_COMPLETION_REPORT_SPECIFIC_OUTCOME_SCHOOL:
					"principal-reports-completion-specific-outcomes-school",
				ASSESSMENT_COMPLETION_REPORT: "principal-reports-assessment-completion",
				COMMUNITY_CULTURE: "principal-reports-community-culture",
				DOMAIN_SWITCH_BUTTON: "principal-reports-domain-switch-button",
				DOMAIN_SWITCH_MENU: "principal-reports-domain-switch-menu",
			},
		},
		PUBLIC: {
			ADD_EDIT_FORM: {
				ADMIN_REQUEST_MESSAGE: "add-edit-form-admin-request-message",
				SAVE_EDIT_BUTTON: "add-edit-form-submit-edit-button",
				SEND_REQUEST_BUTTON: "add-edit-form-send-request-button",
				SUBMIT_BUTTON: "add-edit-form-submit-button",
			},
			BUTTONS: {
				IN_BUTTON_SPINNER: "public-button-with-spinner-icon",
				MODAL_CLOSE_BUTTON: "public-modal-close-button",
				NOTIFICATION_BUTTON: "notification-button",
			},
			COMPONENTS: {
				ACTIVITY_CARD: "public-component-activity-card",
				ACTIVITY_CARD_SAVE_ACTIVITY_BUTTON:
					"public-component-activity-card-save-activity-button",
				ACTIVITY_CARD_SAVE_ACTIVITY_BUTTON_SPINNER:
					"public-component-activity-card-save-activity-button-spinner",
				ACTIVITY_CARD_TITLE: "public-component-activity-card-title",
				ACTIVITY_CARD_UPGRADE_BUTTON:
					"public-component-activity-card-upgrade-button",
				ACTIVITY_CURRENT_CARD: "public-component-activity-current-card",
				ACTIVITY_SEARCH_BAR: "public-component-activity-search-bar",
				ACTIVITY_SEARCH_ACTIVITY_INPUT:
					"public-component-activity-search-input",
				ACTIVITY_SEARCH_BUTTON: "public-component-activity-search-button",
				LOADER_SPINNER: "public-component-loader-spinner",
				NOTIFICATION_CONTENT: "notification-content",
				NOTIFICATION_TAB_MENU: "notification-tab-menu",
				SPRIG_TREE: "public-component-sprig-tree",
				EXPANDABLE_SEARCH: "component-expandable-search",
				DOMAIN_DROPDOWN_BUTTON: "domain-dropdown-button",
				DOMAIN_DROPDOWN_ORAL_LANGUAGE: "domain-dropdown-oral-language",
				DOMAIN_DROPDOWN_MATH: "domain-dropdown-math",
				EXPANDABLE_SEARCH_CLOSE_BUTTON:
					"component-expandable-search-close-button",
				NO_FOUND: "component-no-found",
				ERROR_MESSAGE: "component-error-message",
			},
			DROPDOWN: {
				CURRENT_ITEM: "dropdown-current-item",
				DROPDOWN_ITEM: "public-dropdown-item",
				MULTISELECT_DROPDOWN_MENU: "multi-select-dropdown-menu",
				MULTISELECT_ITEM: "multi-select-dropdown-item",
				GENDER: {
					GENDER_SELECT: "gender-selection",
					GENDER_SELECT_FEMALE: "gender-selection-female",
					GENDER_SELECT_MALE: "gender-selection-male",
					GENDER_SELECT_NOT: "gender-selection-not-specified",
				},
				GRADE: {
					GRADE_SELECT: "grade-selection",
					GRADE_SELECT_G1: "grade-selection-g1",
				},
				IDENTITY: {
					IDENTITY_SELECT: "identity-selection",
					IDENTITY_SELECT_INDIGENOUS: "identity-selection-indigenous",
					IDENTITY_SELECT_NON_INDIGENOUS: "identity-selection-non-indigenous",
					IDENTITY_SELECT_NOT: "identity-selection-not-specified",
				},
				ACTIVITY_FILTER: {
					APPLY_BUTTON:
						"public-learning-activities-filter-activities-apply-button",
					BUTTON: "public-learning-activities-filter-activities-button",
					CLEAR_BUTTON:
						"public-learning-activities-filter-activities-clear-button",
					MENU: "public-learning-activities-filter-activities-menu",
					MENU_ITEM: "public-learning-activities-filter-activities-menu-item",
				},
			},
			FORM: {
				FIRST_NAME: "public-form-first-name",
				LAST_NAME: "public-form-last-name",
			},
			MODAL: {
				ACTIVITY_ASSESSMENT_MODAL: {
					MODAL: "activity-assessment-modal",
					ASSESSMENT_QUESTION: "activity-assessment-modal-assessment-question",
					BACK_BUTTON: "activity-assessment-modal-back-button",
					STUDENT_ROW: "activity-assessment-modal-student-row",
					STUDENT_ROW_NAME: "activity-assessment-modal-student-row-name",
					STUDENT_ROW_CURRENT_LEVEL:
						"activity-assessment-modal-student-row-current-level",
					CIRCLE_PICKER: "activity-assessment-modal-circle-picker",
				},
				ACTIVITY_MODAL: {
					COMPLETE: "activity-modal-complete",
					MODAL: "activity-modal",
					MODAL_CONTENT: "activity-modal-content",
					SAVE: "activity-modal-save",
					ASSESS_BUTTON: "activity-modal-assess-button",
					COPY_BUTTON: "activity-modal-copy-button",
					COPY_BUTTON_DUPLICATE: "activity-modal-copy-button-duplicate",
					COPY_BUTTON_TRANSLATE: "activity-modal-copy-button-translate",
				},
				BADGES_EARNED_MODAL: "badges-earned-modal",
				BADGES_MODAL: "badges-modal",
				CONFIRMATION: {
					MODAL: "confirmation-modal",
					PRIMARY_BUTTON: "confirmation-modal-primary-button",
					SECONDARY_BUTTON: "confirmation-modal-secondary-button",
				},
				MATH_ASSESSMENT_MODAL: "math-assessment-modal",
				MATH_RESULTS: {
					NEXT_PAGE_BUTTON: "math-results-next-page-button",
				},
				ORAL_LANGUAGE_ASSESSMENT_MODAL: "oral-language-assessment-modal",
				PLAIN_CONTENT_MODAL: "plain-content-modal",
			},
			REPORTS: {
				LABELS_DROPDOWN_BUTTON: "public-report-labels-button",
				LABELS_DROPDOWN_MENU: "public-report-labels-menu",
				PARENT_OUTCOME_DROPDOWN_BUTTON:
					"public-report-parent-outcome-dropdown-button",
				PARENT_OUTCOME_DROPDOWN_MENU:
					"public-report-parent-outcome-dropdown-menu",
				PERIOD_DROPDOWN_BUTTON: "public-report-period-dropdown-button",
				PERIOD_DROPDOWN_MENU: "public-report-period-dropdown-menu",
			},
		},
		READING: {
			FREE_TRIAL: {
				EMAIL: "reading-free-trial-email",
				TERMS_CHECKBOX: "reading-free-trial-terms-checkbox",
				PROFILE_FIRST_NAME: "reading-free-trial-first-name",
				PROFILE_LAST_NAME: "reading-free-trial-last-name",
				PROFILE_PASSWORD: "reading-free-trial-password",
				PROFILE_CONFIRM_PASSWORD: "reading-free-trial-confirm-password",
				ROLE: "reading-free-trial-role",
				SPECIFIED_ROLE: "reading-free-trial-specified-role",
				ORGANIZATION_NAME: "reading-free-trial-organization-name",
				COUNTRY: "reading-add-school-country",
				PROVINCE: "reading-add-school-province",
				CITY: "reading-add-school-city",
			},
			CLASSROOMS: {
				CLASSROOM_CARD: "reading-classrooms-classroom-card",
				CLASSROOM_CARD_EDIT: "reading-classrooms-classroom-card-edit",
				CLASSROOM_CARD_NAME: "reading-classrooms-classroom-card-name",
				ADD_CLASSROOM_BUTTON: "reading-classrooms-add-classroom-button",
			},
			CLASSROOM: {
				CIRCLE_CHART: {
					CIRCLE_CHART_CARD: "reading-classroom-circle-chart-topic-card",
					CIRCLE_CHART_CARD_INFO_CHARTS:
						"reading-classroom-circle-chart-topic-card-info-charts",
					CIRCLE_CHART_CARD_INFO_SKILLS:
						"reading-classroom-circle-chart-topic-card-info-skills",
					CIRCLE_CHART_CARD_INFO_PROGRESS:
						"reading-classroom-circle-chart-topic-card-info-progress",
					CIRCLE_CHART_CARD_TOPIC_NAME:
						"reading-classroom-circle-chart-topic-card-topic-name",
					CIRCLE_CHART_TABLE: "reading-classroom-circle-chart-table",
					CIRCLE_CHART_TABLE_ROW: "reading-classroom-circle-chart-table-row",
					CIRCLE_CHART_TABLE_ROW_NAME:
						"reading-classroom-circle-chart-table-row-name",
					CIRCLE_CHART_TABLE_SKILL:
						"reading-classroom-circle-chart-table-skill",
					CIRCLE_CHART_RESULT: "reading-classroom-circle-chart-result",
					CIRCLE_CHART_PICKER_MESSAGE_ICON:
						"reading-classroom-circle-chart-picker-message-icon",
					CLASSROOM_NAV_LINK: "reading-classroom-circle-chart-nav-link",
					TOPIC_MENU_BUTTON: "reading-classroom-circle-chart-topic-menu-button",
					TOPIC_MENU_DROPDOWN: "reading-classroom-circle-chart-topic-menu",
					TOPIC_MENU_DROPDOWN_DOMAIN_BUTTON:
						"reading-classroom-circle-chart-topic-menu-domain-button",
					TOPIC_MENU_DROPDOWN_ITEM_TOPIC:
						"reading-classroom-circle-chart-topic-menu-topic-button",
					TOPIC_MENU_DROPDOWN_ITEM_TOPIC_MOBILE:
						"reading-classroom-circle-chart-topic-menu-topic-button-mobile",
					TOPIC_MENU_DROPDOWN_ITEM_SUB_TOPIC:
						"reading-classroom-circle-chart-topic-menu-sub-topic-button",
					TOPIC_MENU_DROPDOWN_ITEM_SKILL:
						"reading-classroom-circle-chart-topic-menu-skill-button",
					TOPIC_MENU_DROPDOWN_ITEM_SUB_TOPIC_MOBILE:
						"reading-classroom-circle-chart-topic-menu-sub-topic-button-mobile",
					NAV_INFO_BUTTON: "reading-classroom-circle-chart-nav-info-button",
					NAV_ASSESSMENT_STRATEGIES_BUTTON:
						"reading-classroom-circle-chart-nav-assessment-strategies-button",
					NAV_INSTRUCTIONAL_STRATEGIES_BUTTON:
						"reading-classroom-circle-chart-nav-instructional-strategies-button",
					NAV_PRINT_REPORT_LINK:
						"reading-classroom-circle-chart-nav-print-report-link",
					NAV_INFO_CHARTS: "reading-classroom-circle-chart-nav-info-charts",
					NAV_INFO_SKILLS: "reading-classroom-circle-chart-nav-info-skills",
					NAV_INFO_PROGRESS: "reading-classroom-circle-chart-nav-info-progress",
					NAV_FILTER_SKILLS_BY_GRADE_BUTTON:
						"reading-classroom-circle-chart-nav-filter-skills-by-grade-button",
					NAV_FILTER_SKILLS_BY_GRADE_DROPDOWN_MENU:
						"reading-classroom-circle-chart-nav-filter-skills-by-grade-dropdown-menu",
					NAV_FILTER_SKILLS_BY_GRADE_DROPDOWN_MENU_ITEM:
						"reading-classroom-circle-chart-nav-filter-skills-by-grade-dropdown-menu-item",
					NAV_FILTER_SKILLS_BY_GRADE_DROPDOWN_MENU_ITEM_CHECKBOX:
						"reading-classroom-circle-chart-nav-filter-skills-by-grade-dropdown-menu-item-checkbox",
					MODAL_INSTRUCTION: "reading-classroom-circle-chart-modal-instruction",
					MODAL_INSTRUCTION_TITLE:
						"reading-classroom-circle-chart-modal-instruction-title",
					MODAL_SKILL: "reading-classroom-circle-chart-modal-skill",
					MODAL_SKILL_LINK_ACTIVITIES:
						"reading-classroom-circle-chart-modal-skill-link-activities",
					MODAL_SKILL_LINK_RESOURCES:
						"reading-classroom-circle-chart-modal-skill-link-resources",
					PRINT: {
						BACK_BUTTON: "reading-classroom-circle-chart-print-back-button",
					},
					REPORT: {
						COMPONENT: "reading-classroom-circle-chart-report-component",
						CURRENT_CLASSROOM:
							"reading-classroom-circle-chart-report-current-classroom",
						SKILL_HIGHLIGHT_BUTTON:
							"reading-classroom-circle-chart-report-skill-highlight-button",
						HIGHLIGHTER_DROPDOWN_BUTTON:
							"reading-classroom-circle-chart-report-highlight-dropdown-button",
						HIGHLIGHTER_DROPDOWN_MENU:
							"reading-classroom-circle-chart-report-highlight-dropdown-menu",
						HIGHLIGHTER_DROPDOWN_ITEM:
							"reading-classroom-circle-chart-report-highlight-dropdown-item",
						STUDENT_HIGHLIGHT_BUTTON:
							"reading-classroom-circle-chart-report-student-highlight-button",
						CIRCLE_CHART_TABLE_ROW_DATA:
							"reading-classroom-circle-chart-report-row-data",
					},
				},
				STUDENTS: {
					CLASSROOM_NAV_LINK: "reading-classroom-students-nav-link",
					TABLE: "reading-classrooms-students-table",
					TABLE_ROW: "reading-classrooms-students-table-row",
					TABLE_ROW_NAME: "reading-classrooms-students-table-row-name",
					TABLE_ROW_GRADE: "reading-classrooms-students-table-row-grade",
					TABLE_ROW_STAT_SKILL_MASTERED:
						"reading-classrooms-students-table-row-stat-skill-mastered",
					TABLE_ROW_STAT_SKILL_NEED_MORE:
						"reading-classrooms-students-table-row-stat-skill-need-more",
					TABLE_ROW_STAT_SKILL_NEED_EXPLICIT:
						"reading-classrooms-students-table-row-stat-skill-need-explicit",
					TABLE_ROW_STAT_SKILL_NOT_ASSESSED:
						"reading-classrooms-students-table-row-stat-skill-not-assessed",
					TABLE_ROW_EDIT_BUTTON:
						"reading-classrooms-students-table-row-edit-button",
					NAVIGATION_ADD_STUDENT:
						"reading-classrooms-students-navigation-add-students",
					ADD_STUDENT_MODAL: "reading-classrooms-students-add-students-modal",
					ADD_STUDENT_MODAL_FIRST_NAME: "add-reading-student-first-name",
					ADD_STUDENT_MODAL_LAST_NAME: "add-reading-student-last-name",
					ADD_STUDENT_MODAL_ADD_BUTTON: "add-reading-student-add-button",
					ADD_STUDENT_MODAL_GRADE_DROPDOWN:
						"add-reading-student-grade-dropdown",
					ADD_STUDENT_MODAL_REMOVE_BUTTON: "add-reading-student-remove-button",
					ADD_STUDENT_MODAL_ADD_EXISTING_STUDENT_BUTTON:
						"add-reading-student-add-existing-student-button",
					ADD_STUDENT_MODAL_EXISTING_STUDENT_ROW:
						"add-reading-student-existing-student-row",
					ADD_STUDENT_MODAL_EXISTING_STUDENT_ROW_GRADE_DROPDOWN:
						"add-reading-student-existing-student-grade-dropdown",
				},
				LEARNING_ACTIVITY: {
					ACTIVITY_CARD: "reading-classroom-learning-activities-activity-card",
					ACTIVITY_CARD_TITLE:
						"reading-classroom-learning-activities-activity-card-title",
					ALL_ACTIVITIES_BUTTON:
						"reading-classroom-learning-activities-all-activities-button",
					CLASSROOM_NAV_LINK: "reading-classroom-learning-activities-nav-link",
					SAVED_ACTIVITIES_BUTTON:
						"reading-classroom-learning-activities-saved-activities-button",
					LIST_VIEW_TOGGLE_BUTTON:
						"reading-classroom-learning-activities-list-view-toggle-button",
				},
				RESOURCES: {
					CLASSROOM_NAV_LINK: "reading-classroom-resources-nav-link",
					SEARCH_RESOURCES_INPUT: "reading-classroom-resource-search-input",
				},
				REPORTS: {
					CLASSROOM_NAV_LINK: "reading-classroom-reports-nav-link",
					DOMAIN_SECTION: "reading-classroom-reports-domain-section",
					STATS_CARD: {
						STAT_SKILL_MASTERED:
							"reading-classrooms-reports-stat-skill-mastered",
						STAT_SKILL_NEED_MORE:
							"reading-classrooms-reports-stat-skill-need-more",
						STAT_SKILL_NEED_EXPLICIT:
							"reading-classrooms-reports-stat-skill-need-explicit",
						STAT_SKILL_NOT_ASSESSED:
							"reading-classrooms-reports-stat-skill-not-assessed",
					},
					TOPIC_SECTION: "reading-classroom-reports-topic-section",
				},
				TEACHERS: {
					CLASSROOM_NAV_LINK: "reading-classroom-teachers-nav-link",
					INVITE_TEACHER_BUTTON:
						"reading-classroom-teachers-nav-invite-teacher-button",
					INVITE_TEACHER_MODAL:
						"reading-classroom-teachers-nav-invite-teacher-modal",
					INVITE_TEACHER_MODAL_FIRSTNAME: "invite-reading-teacher-first-name",
					INVITE_TEACHER_MODAL_LASTNAME: "invite-reading-teacher-last-name",
					INVITE_TEACHER_MODAL_EMAIL: "invite-reading-teacher-email",
					INVITE_TEACHER_MODAL_INVITE_BUTTON:
						"invite-reading-teacher-invite-button",
					TABLE: "reading-classroom-teachers-table",
					TABLE_ROW: "reading-classroom-teachers-table-row",
					TABLE_ROW_NAME: "reading-classroom-teachers-table-row-name",
					TABLE_ROW_EMAIL: "reading-classroom-teachers-table-row-email",
					TABLE_ROW_USERNAME: "reading-classroom-teachers-table-row-username",
				},
				TRAINING_VIDEOS: {
					CLASSROOM_NAV_LINK: "reading-classroom-training-videos-nav-link",
					DESCRIPTION: "reading-classroom-training-videos-description",
					DESCRIPTION_BUTTON:
						"reading-classroom-training-videos-description-button",
					VIDEO_CARD: "reading-classroom-training-videos-video-card",
					MODAL: "reading-classroom-training-videos-video-modal",
				},
				NAVIGATION: {
					PORTAL_TOUR: "reading-navigation-portal-tour",
				},
			},
			PUBLIC: {
				SIGN_UP_NEXT: "reading-sign-up-next",
				ADD_SCHOOL: {
					NAME: "reading-add-school-name",
				},
				ADD_EDIT_CLASSROOM_MODAL: {
					MODAL: "reading-add-edit-classroom-modal",
					CLASSROOM_NAME: "reading-add-edit-classroom-modal-name",
					INVITE_TEACHER_EMAIL:
						"add-edit-reading-classroom-invite-teacher-email",
					INVITE_TEACHER_FIRST_NAME:
						"add-edit-reading-classroom-invite-teacher-first-name",
					INVITE_TEACHER_LAST_NAME:
						"add-edit-reading-classroom-invite-teacher-last-name",
					LIMIT_MESSAGE: "add-edit-reading-classroom-limit-message",
					SAVE_BUTTON: "add-edit-reading-classroom-save-button",
				},
				CONFIRM_REMOVE_USER_MODAL: "reading-confirm-remove-user-modal",
				CONFIRM_REMOVE_USER_MODAL_TEXT:
					"reading-confirm-remove-user-modal-text",
				CONFIRM_REMOVE_USER_MODAL_YES: "reading-confirm-remove-user-modal-yes",
				STUDENT_MODAL: {
					MODAL: "reading-student-modal",
					STAT_SKILL_MASTERED: "reading-student-modal-stat-mastered",
					STAT_SKILL_NEED_MORE: "reading-student-modal-stat-need-more",
					STAT_SKILL_NEED_EXPLICIT: "reading-student-modal-stat-need-explicit",
					STAT_SKILL_NOT_ASSESSED: "reading-student-modal-stat-not-assessed",
					STAT_VALUE: "reading-student-modal-stat-value",
					RELATIVE_TO_OTHERS: "reading-student-modal-relative-to-others",
					AVERAGE: "reading-student-modal-average",
					TOPIC_AREA_CARD: "reading-student-modal-topic-area-card",
					TOPIC_AREA_CARD_TITLE: "reading-student-modal-topic-area-card-title",
					SKILL_BUTTON: "reading-student-modal-skill-button",
					CIRCLE_CHART_SECTION: "reading-student-modal-circle-chart-section",
					PROGRESS_VALUE: "reading-student-modal-progress-value",
				},
				MY_ACCOUNT_SCHOOL_CARD: {
					CARD: "reading-my-account-school-card",
					EDIT_BUTTON: "reading-my-account-school-card-edit-button",
					SCHOOL_NAME_INPUT: "reading-my-account-school-card-school-name-input",
					SCHOOL_DISTRICT_INPUT:
						"reading-my-account-school-card-school-district-input",
				},
				MY_ACCOUNT_EDIT_SCHOOL_DISTRICT_MODAL: {
					MODAL: "reading-my-account-edit-school-district-modal",
					SCHOOL_NAME_INPUT:
						"reading-my-account-edit-school-district-school-name-input",
					SCHOOL_DISTRICT_INPUT:
						"reading-my-account-edit-school-district-school-district-input",
					SAVE_BUTTON:
						"reading-my-account-edit-school-district-school-save-button",
				},
				NOTE_MODAL: {
					MODAL: "reading-note-modal",
					NOTE_AREA: "reading-note-modal-note-textarea",
					NOTE: "reading-note-modal-note",
					SAVE_BUTTON: "reading-note-modal-save-button",
					NOTE_ACTION_BUTTON: "reading-note-modal-note-action-button",
					NOTE_ACTION_EDIT: "reading-note-modal-note-action-button-edit",
					NOTE_ACTION_DELETE: "reading-note-modal-note-action-button-delete",
					NOTE_ACTION_DELETE_CONFIRM:
						"reading-note-modal-note-action-button-delete-confirm",
					NOTE_CONTENT: "reading-note-modal-note-content",
				},
				EDIT_NOTE_MODAL: {
					MODAL: "reading-edit-note-modal",
					CONTENT_TEXTAREA: "reading-edit-note-modal-content-textarea",
					SAVE_BUTTON: "reading-edit-note-modal-save-button",
				},
			},
			REPORTS: {
				DOMAIN_SECTION: "reading-reports-domain-section",

				TOPIC_SECTION: "reading-reports-topic-section",
			},
		},
		HOLISTIC_MATH: {
			DASHBOARD: {
				INFO_CARD: "hm-dashboard-info-card",
				LEVEL_CHART: "hm-dashboard-level-chart",
				COMPLETION_CHART: "hm-dashboard-completion-chart",
			},
			STUDENTS: {
				TABLE: "hm-students-table",
				TABLE_STUDENT_ROW: "hm-students-table-student-row",
				TABLE_STUDENT_ROW_NAME: "hm-students-table-student-row-name",
				TABLE_STUDENT_ROW_GRADE: "hm-students-table-student-row-grade",
				TABLE_STUDENT_ROW_ACTIVITY_COMPLETION:
					"hm-students-table-student-row-completion",
				TABLE_STUDENT_ROW_SCREEN_TIME: "hm-students-table-student-screentime",
				TABLE_STUDENT_ROW_PASSCODE: "hm-students-table-student-row-passcode",
				TABLE_STUDENT_ROW_CAREGIVER_LINKED:
					"hm-students-table-student-row-caregiver",
			},
			ACTIVITIES: {
				CURRENT_OUTCOME: "hm-activities-current-outcomes",
			},
		},
	},
};
