import i18n from "i18next";

import * as ActionTypes from "../Redux/ActionTypes";
import {getData, postData} from "../Scripts/Axios/axiosRequests";
import {ACTIVITY_MONITOR_ACTIONS} from "../Scripts/Constants";
import {get_url} from "../Scripts/Fetch";
import {
	ActivityMonitorActions,
	ErrorReporterActions,
	LoaderActions,
	SurveyActions,
} from "./index";

export const getAssessmentsMetadata = () => {
	const actionType = ActionTypes.SURVEY_GET_ASSESSMENT_METADATA;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/assessments`;
		getData(url)
			.then((response) => {
				dispatch({
					type: actionType,
					payload: response,
				});
			})
			.catch((error) => {
				console.error(`Error: ${error.message}`);
				dispatch({
					type: actionType,
					error,
				});
			});
	};
};

export const getResponsesForAssessment = (submissionId, {handling}) => {
	const actionType = ActionTypes.SURVEY_GET_RESPONSES_FOR_ASSESSMENT;
	return () => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/responses?submission=${submissionId}`;
		getData(url)
			.then((responses) => {
				handling.success(responses);
			})
			.catch(() => {
				handling.error();
			});
	};
};

export const getResponsesForChildAssessment = (submissionId, datum) => {
	const actionType = ActionTypes.SURVEY_GET_RESPONSES_FOR_CHILD_ASSESSMENT;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/responses?submission=${submissionId}&context=view`;
		getData(url)
			.then((response) => {
				dispatch({
					type: actionType,
					payload: {response, ...datum},
				});
			})
			.then(() => {
				SurveyActions.updateChildAssessmentFromSubmission({
					...datum,
				});
			})
			.catch((error) => {
				console.error(`Error: ${error.message}`);
				dispatch({
					type: actionType,
					error,
				});
			});
	};
};

export const updateSurveyProperties = (payload) => ({
	type: ActionTypes.SURVEY_UPDATE_SURVEY_PROPERTIES,
	payload,
});

export const createCaregiverSurvey = (payload) => ({
	type: ActionTypes.SURVEY_CAREGIVER_CREATE_ANSWER_SCHEMA,
	payload,
});

export const createHMCaregiverSurvey = (payload) => ({
	type: ActionTypes.SURVEY_HM_CAREGIVER_CREATE_ANSWER_SCHEMA,
	payload,
});

export const getChildAssessmentQuestions = () => {
	const actionType = ActionTypes.SURVEY_GET_CHILD_ASSESSMENT_QUESTIONS;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/questions`;
		getData(url)
			.then((response) => {
				dispatch({
					type: actionType,
					payload: response,
				});
			})
			.catch((error) => {
				console.error(`Error: ${error.message}`);
				dispatch({
					type: actionType,
					error,
				});
			});
	};
};

export const createChildAssessment = (payload) => ({
	type: ActionTypes.SURVEY_CHILD_ASSESSMENT_CREATE_ANSWER_SCHEMA,
	payload,
});

export const updateChildAssessment = (payload) => ({
	type: ActionTypes.SURVEY_CHILD_ASSESSMENT_UPDATE_ANSWER_SCHEMA,
	payload,
});

export const updateChildAssessmentFromSubmission = (payload) => ({
	type: ActionTypes.SURVEY_CHILD_ASSESSMENT_UPDATE_ANSWER_SCHEMA_FROM_SUBMISSION,
	payload,
});

export const updateCaregiverSurvey = (payload) => ({
	type: ActionTypes.SURVEY_CAREGIVER_UPDATE_ANSWER_SCHEMA,
	payload,
});

export const updateHMCaregiverSurvey = (payload) => ({
	type: ActionTypes.SURVEY_HM_CAREGIVER_UPDATE_ANSWER_SCHEMA,
	payload,
});

export const submitCaregiverSurvey = (request, datum) => {
	const actionType = ActionTypes.SURVEY_SUBMIT_FOR_UPLOAD;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/submissions`;
		postData(url, request)
			.then((response) => {
				ActivityMonitorActions.addToQueue({
					type: ACTIVITY_MONITOR_ACTIONS.SUCCESS,
					title: i18n.t("survey.complete_survey"),
					message: i18n.t("survey.survey_submission_success"),
				});

				dispatch({
					type: actionType,
					payload: {
						...response,
						...datum,
					},
				});
			})
			.catch((error) => {
				ActivityMonitorActions.addToQueue({
					type: ACTIVITY_MONITOR_ACTIONS.ERROR,
					title: i18n.t("survey.complete_survey"),
					message: i18n.t("error.survey_submission_error", {
						error: error.data.message,
					}),
				});

				ErrorReporterActions.addToQueue({
					actionType,
					error,
				});

				dispatch({
					type: actionType,
					payload: null,
				});
			});
	};
};

export const doneChildAssessment = (payload) => ({
	type: ActionTypes.SURVEY_DONE_CHILD_ASSESSMENT,
	payload,
});

export const submitChildAssessment = (request, datum) => {
	const actionType = ActionTypes.SURVEY_SUBMIT_FOR_UPLOAD_CHILD_ASSESSMENT;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/submissions`;
		postData(url, request)
			.then((response) => {
				ActivityMonitorActions.addToQueue({
					type: ACTIVITY_MONITOR_ACTIONS.SUCCESS,
					title: i18n.t("survey.complete_child_assessment"),
					message: i18n.t("survey.assessment_submission_success"),
				});

				dispatch({
					type: actionType,
					payload: {
						...response,
						...datum,
					},
				});
			})
			.catch((error) => {
				ActivityMonitorActions.addToQueue({
					type: ACTIVITY_MONITOR_ACTIONS.ERROR,
					title: i18n.t("survey.complete_child_assessment"),
					message: i18n.t("error.assessment_submission_error", {
						error: error.data.message,
					}),
				});

				ErrorReporterActions.addToQueue({
					actionType,
					error,
				});

				dispatch({
					type: actionType,
					payload: null,
				});
			});
	};
};
