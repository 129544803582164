import "./PortalTourStep.scss";

import React from "react";
import {connect} from "react-redux";

import {AppContentActions} from "../../../Actions";
import {HasId} from "../../../Scripts/PublicInterfaces";
import {
	ConditionalWrapper,
	OutsideClickHandler,
} from "../../Portal/PublicComponents";
import {getTourStepComponentBasedOnId} from ".";

interface PortalTourModel extends HasId {
	step: number;
	tourId: string;
	isActive: boolean;
}

interface PortalTourStepProps extends PortalTourStepPropsFromReducer {
	step: number;
	tourId: string;
	children: React.ReactNode;
	wrapperClassName?: string;
	isWalkThrough: boolean;
	nextPageLink: any;
	isMobileScreen?: boolean;
}

interface PortalTourStepPropsFromReducer extends HasId {
	tours: PortalTourModel[];
}

const PortalTourStep = ({
	id,
	tours,
	step,
	tourId,
	children,
	wrapperClassName,
	isWalkThrough,
	nextPageLink = "",
	isMobileScreen = false,
}: PortalTourStepProps) => {
	const componentId = `${tourId}-step-${step}`;
	const allTours = tours ? tours : [];
	const tour = allTours.find(
		(tour) => tour.tourId === tourId && tour.id === id
	);
	const tourStepComponent = getTourStepComponentBasedOnId(tourId, step);
	const shouldShowTour = tour?.isActive && tour?.step === step;
	const onNextStep = () => {
		if (shouldShowTour && !isWalkThrough) {
			AppContentActions.proceedToNextPortalTourStep(tour);
		}
	};

	const onClickOutside = () => {
		if (shouldShowTour) {
			AppContentActions.endCurrentPortalTour(tour);
		}
	};
	return (
		<ConditionalWrapper
			condition={!isMobileScreen}
			wrapper={(children) => {
				return (
					<OutsideClickHandler
						onOutsideClick={onClickOutside}
						wrapperClassName={wrapperClassName}
					>
						{children}
					</OutsideClickHandler>
				);
			}}
		>
			<div
				className={`portal-tour-step${shouldShowTour ? " active" : ""}`}
				id={componentId}
				key={componentId}
			>
				<div
					className="portal-tour-content"
					onClick={isWalkThrough ? onNextStep : undefined}
				>
					{children}
				</div>
				<ConditionalWrapper
					condition={isMobileScreen}
					wrapper={(children) => {
						return (
							<OutsideClickHandler
								onOutsideClick={onClickOutside}
								wrapperClassName={wrapperClassName}
							>
								{children}
							</OutsideClickHandler>
						);
					}}
				>
					<div className="portal-tour-step-component">
						{tourStepComponent
							? React.cloneElement(tourStepComponent, {tour, nextPageLink})
							: null}
					</div>
				</ConditionalWrapper>
			</div>
		</ConditionalWrapper>
	);
};

const mapStateToProps = (state) => {
	return {
		tours: state?.appContent?.tours,
		id: state?.user?.userId,
	};
};

export default connect(mapStateToProps)(PortalTourStep);
