import i18n from "i18next";
import React, {useEffect, useState} from "react";

import {
	ClassroomStudentModel,
	EducatorTaxonomiesModel,
} from "../../../../../Scripts/PublicInterfaces";
import {createMilestoneReportData} from "../../../../../Scripts/Reports/MilestoneReportHelper";
import {Chart} from "../../index";
import {MilestoneStudentModel} from "../StudentMilestone";

interface StudentMilestoneChartProps {
	onSelectMilestone: (data: any) => void;
	onSelectDeviation: (data: any) => void;
	selections: {
		milestone: string | number;
		deviation: string | null;
		students: MilestoneStudentModel[];
		selectedStudents: {}[];
	};
	data: {milestones: {}[]};
	students: ClassroomStudentModel[];
	taxonomies: EducatorTaxonomiesModel;
	domain: number;
	isHolisticMath?: boolean;
}

export default function useWindowDimensions() {
	function getWindowDimensions() {
		const {innerWidth: width} = window;
		return {
			width,
		};
	}

	const [windowDimensions, setWindowDimensions] = useState(
		getWindowDimensions()
	);

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return windowDimensions;
}

export const StudentMilestoneChart = ({
	onSelectDeviation,
	onSelectMilestone,
	selections,
	data,
	students,
	taxonomies,
	domain,
	isHolisticMath,
}: StudentMilestoneChartProps) => {
	const {width} = useWindowDimensions();
	const [mobileScreen, setMobileScreen] = useState(false);

	useEffect(() => {
		const mobileScreenWidth = 599;
		if (width <= mobileScreenWidth) {
			setMobileScreen(true);
		}
	}, [width]);

	const type = "line";
	if (!data?.milestones?.length) return null;
	const chartData = createMilestoneReportData(data, {
		taxonomies,
		selections,
		students,
		domain,
		isHolisticMath,
	});
	let dataForMobileScreen;
	if (mobileScreen) {
		dataForMobileScreen = chartData[0].map((item) => {
			return {
				...item,
				x: item.x.slice(0, item.x.length === 4 ? 4 : 3),
			};
		});
	}
	const options = {
		deviationDataAvailable: !selections?.selectedStudents?.length,
		callbacks: {
			bandSelect: (d) => onSelectMilestone({...d, id: d.milestone}),
			deviationSelect: onSelectDeviation,
		},
		axis: {
			x: {
				scale: "ordinal",
				label: i18n.t("chart.milestone"),
				includeBandsOnAxis: true,
			},
			y: {
				scale: "linear",
				domain: [0, 4],
				ticks: [0, 1, 2, 3, 4],
				label: i18n.t("chart.student_level"),
			},
		},
	};
	const studentsBelow = selections?.students?.filter(
		(student) => student.level < student.expectedLevel
	).length;
	const studentsAt = selections?.students?.filter(
		(student) => student.level === student.expectedLevel
	).length;
	const studentsAbove = selections?.students?.filter(
		(student) => student.level > student.expectedLevel
	).length;
	const totalStudents = studentsBelow + studentsAt + studentsAbove;
	const legendItems = [
		{
			label: i18n.t("chart.below_expectation"),
			class: "below",
			studentCount: studentsBelow,
			percentage: `${Math.round((studentsBelow / totalStudents) * 100)}%`,
		},
		{
			label: i18n.t("chart.at_expectation"),
			class: "expectation",
			studentCount: studentsAt,
			percentage: `${Math.round((studentsAt / totalStudents) * 100)}%`,
		},
		{
			label: i18n.t("chart.above_expectation"),
			class: "above",
			studentCount: studentsAbove,
			percentage: `${Math.round((studentsAbove / totalStudents) * 100)}%`,
		},
	];
	return (
		<div className="student-milestone-chart">
			<div className="student-milestone-chart-legend">
				{legendItems.map((legendItem) => (
					<div className="legend-item" key={legendItem.label}>
						<div className="legend-item-label">
							<div className={`legend-item-color ${legendItem.class}`} />
							<span>{legendItem.label}</span>
						</div>
						{isHolisticMath ? null : (
							<div className="legend-item-statistics">
								<div className={`expectation ${legendItem.class}`}>
									<span>{selections.students[0].expectedLevel}</span>
									<div className={`arrow ${legendItem.class}`} />
								</div>
								{legendItem.studentCount ? (
									<div className="totals">
										<span>{legendItem.studentCount} /</span>
										<span>{legendItem.percentage}</span>
									</div>
								) : null}
							</div>
						)}
					</div>
				))}
			</div>
			<div className="student-milestone-chart-area">
				<Chart
					type={type}
					data={mobileScreen ? [dataForMobileScreen] : chartData}
					options={options}
					isHolisticMath={isHolisticMath}
				/>
			</div>
		</div>
	);
};
