import "./dropdownWrapper.scss";

import React, {useState} from "react";

import {STYLE_CONSTANT} from "../../../Scripts/StyleConstants";
import {OutsideClickHandler} from "../../Portal/PublicComponents";

export interface DropdownWrapperProps {
	/**
	 * Specific where the dropdown menu will be positioned from
	 */
	position?: DropdownPosition;
	menuItems: (arg) => Array<React.ReactNode>;
	menuId?: string;
	disabled?: boolean;
	customizedClickEvent?: () => void;
	wrapperClassName?: string;
}

export type DropdownPosition = "nw" | "ne" | "sw" | "se" | "s";

/**
 * Primary UI component for user interaction
 */
export const DropdownWrapper: React.FC<DropdownWrapperProps> = ({
	position = "sw",
	menuItems,
	children,
	menuId,
	disabled = false,
	customizedClickEvent = () => {},
	wrapperClassName,
}) => {
	const [isDropdownOpen, updateDropdown] = useState(false);
	const componentClass = STYLE_CONSTANT.COMPONENT.DROPDOWN_WRAPPER;
	const clickEvent = () => {
		customizedClickEvent();
		updateDropdown(!isDropdownOpen);
	};

	const closeMenuEvent = () => updateDropdown(false);
	const items = menuItems(closeMenuEvent);
	return (
		<OutsideClickHandler
			onOutsideClick={closeMenuEvent}
			wrapperClassName={wrapperClassName}
		>
			<div
				className={[componentClass, `${componentClass}--${position}`].join(" ")}
			>
				<div
					className={`${componentClass}--parent`}
					onClick={() => {
						if (!disabled) clickEvent();
					}}
				>
					{children}
				</div>
				<div
					className={`${componentClass}--menu${
						isDropdownOpen ? " menu-open" : ""
					}`}
					id={menuId}
				>
					{items.map((menuItem, index) => (
						<div
							className={`${componentClass}--menu-item`}
							key={`${componentClass}--menu-item-${index}`}
						>
							{menuItem}
						</div>
					))}
				</div>
			</div>
		</OutsideClickHandler>
	);
};
