import i18n from "i18next";
import React from "react";

import {QuestionSection, QuestionVisual} from "../Public";

export const WhoDoYouTalk = ({questions, languages, studentName}) => {
	const communityLanguage = languages?.find(
		(language) => language.is_ancestral
	)?.english_name;

	const header = {
		title: i18n.t("assessments.oral_language.who_do_you_talk.title"),
		description: i18n.t(
			"assessments.oral_language.who_do_you_talk.description",
			{student: studentName, community: communityLanguage}
		),
	};

	const data = [
		{
			label: i18n.t("assessments.oral_language.who_do_you_talk.talk_with"),
			id: "talk_with",
			answers: questions?.find((question) => question.question_id === 401)
				?.values,
		},
		{
			label: i18n.t(
				"assessments.oral_language.who_do_you_talk.hear_speaking_language",
				{
					language: communityLanguage
						? communityLanguage
						: i18n.t("error.unknown_community_language"),
				}
			),
			id: "hear_speaking_community_language",
			answers: questions?.find((question) => question.question_id === 411)
				?.values,
		},
		{
			label: i18n.t(
				"assessments.oral_language.who_do_you_talk.speaking_language_with",
				{
					language: communityLanguage
						? communityLanguage
						: i18n.t("error.unknown_community_language"),
				}
			),
			id: "speaking_community_language",
			answers: questions?.find((question) => question.question_id === 421)
				?.values,
		},
	];

	return (
		<div className="assessment-ol-whodoyoutalk pb-4">
			<QuestionSection {...header}>
				<div className="assessment-question-visuals">
					{data.map((datum) => (
						<QuestionVisual key={datum.label} {...datum} />
					))}
				</div>
			</QuestionSection>
		</div>
	);
};
