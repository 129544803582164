import MathLogo from "../Assets/Caregiver/Domains/aliet.svg";
import OralLanguageLogo from "../Assets/Caregiver/Domains/antle.svg";
import MathColor from "../Assets/Caregiver/Domains/icon-aliet.svg";
import OralLanguageColor from "../Assets/Caregiver/Domains/icon-antle.svg";
import OralLanguageLogoWhite from "../Assets/Caregiver/Domains/language-white.svg";
import MathLogoWhite from "../Assets/Caregiver/Domains/math-white.svg";
import {getDomains} from "./Utilities";

const domainsTaxonomy = getDomains();

export const DOMAIN_SLUG = {
	READING: "reading",
	ORAL_LANGUAGE: "oral-language",
	MATH: "math",
};

export const getDomainId = (slug) => {
	const item = domainsTaxonomy.find(
		(domainTaxonomy) => domainTaxonomy.slug === slug
	);
	if (item) return item.id;
	return 0;
};

export const DOMAIN_MATH = getDomainId(DOMAIN_SLUG.MATH);
export const DOMAIN_ORAL_LANGUAGE = getDomainId(DOMAIN_SLUG.ORAL_LANGUAGE);
export const DOMAIN_READING = getDomainId(DOMAIN_SLUG.READING);

export const getLocaleId = (domainId: number): string => {
	switch (domainId) {
		case DOMAIN_MATH:
			return "domain.math";
		case DOMAIN_ORAL_LANGUAGE:
			return "domain.oral_language";
		case DOMAIN_READING:
			return "reading.domain";
		default:
			return "domain.unknown";
	}
};

export const getClassroomLogo = (
	domainId: number,
	color: boolean,
	white: boolean
): string => {
	switch (domainId) {
		case DOMAIN_ORAL_LANGUAGE:
			return color
				? OralLanguageColor
				: white
				? OralLanguageLogoWhite
				: OralLanguageLogo;
		case DOMAIN_MATH:
			return color ? MathColor : white ? MathLogoWhite : MathLogo;
		default:
			throw new Error(`Unsupported domain id ${domainId}`);
	}
};

export const verifyDomainAndChangeIfNecessary = (student, domain) => {
	if (student?.authorization?.subscription) {
		if (student?.authorization?.domains.includes(domain)) {
			return domain;
		}
		return student.authorization.domains[0];
	}
	return domain;
};
