interface LoaderHideContentProps {
	show?: boolean;
	children: JSX.Element;
}

export const LoaderHideContent = ({
	show,
	children,
}: LoaderHideContentProps): JSX.Element | null => {
	return show ? null : children;
};

export const LoaderShowContent = ({show, children}: LoaderHideContentProps) => {
	return show ? children : null;
};
