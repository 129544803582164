// Imported Functions, Constants, etc
import {lazy} from "react";

import {MODAL_TYPES} from "../../../../../Scripts/Constants";

const ReadingWelcomeModal = lazy(() =>
	import(
		"../../../SprigReading/ReadingClassroomsPage/ReadingWelcomeModal"
	).then(({ReadingWelcomeModal}) => ({
		default: ReadingWelcomeModal,
	}))
);

const HMCaregiverSurveyModal = lazy(() =>
	import("./Surveys").then(({HMCaregiverSurveyModal}) => ({
		default: HMCaregiverSurveyModal,
	}))
);

const HMOnboardingModal = lazy(() =>
	import("../../../Caregiver/Onboarding/HMOnboardingModal").then(
		({HMOnboardingModal}) => ({
			default: HMOnboardingModal,
		})
	)
);

const ActivityAssessmentModal = lazy(
	() =>
		import(
			"../../../Educator/HolisticMath/Classroom/Activities/ActivityAssessmentModal"
		)
);

const HolisticMathStudentModal = lazy(() =>
	import(
		"../../../Educator/HolisticMath/Classroom/Students/HolisticMathStudentModal"
	).then(({HolisticMathStudentModal}) => ({
		default: HolisticMathStudentModal,
	}))
);

const EditNoteModal = lazy(() =>
	import("../../../SprigReading/ReadingClassroom").then(({EditNoteModal}) => ({
		default: EditNoteModal,
	}))
);

const NoteModal = lazy(() =>
	import(
		"../../../SprigReading/ReadingClassroom/Widgets/CircleChartNote/NoteModal"
	).then(({NoteModal}) => ({
		default: NoteModal,
	}))
);

const PlainContentModal = lazy(() =>
	import("./Generic").then(({PlainContentModal}) => ({
		default: PlainContentModal,
	}))
);

const ReadingEditSchoolModal = lazy(() =>
	import("../../../MyAccount/Widgets").then(({ReadingEditSchoolModal}) => ({
		default: ReadingEditSchoolModal,
	}))
);

const TrainingVideoModal = lazy(() =>
	import("../../../SprigReading/ReadingClassroom").then(
		({TrainingVideoModal}) => ({
			default: TrainingVideoModal,
		})
	)
);

const SkillModal = lazy(() =>
	import("../../../SprigReading/ReadingClassroom").then(({SkillModal}) => ({
		default: SkillModal,
	}))
);

const ConfirmRemoveUserModal = lazy(() =>
	import("../../../SprigReading/ReadingClassroom").then(
		({ConfirmRemoveUserModal}) => ({
			default: ConfirmRemoveUserModal,
		})
	)
);

const AddEditReadingClassroomModal = lazy(() =>
	import("../../../SprigReading/Widgets").then(
		({AddEditReadingClassroomModal}) => ({
			default: AddEditReadingClassroomModal,
		})
	)
);

const InviteTeacherModal = lazy(() =>
	import("../../../SprigReading/ReadingClassroom").then(
		({InviteTeacherModal}) => ({
			default: InviteTeacherModal,
		})
	)
);

const ReadingStudentModal = lazy(() =>
	import("../../../SprigReading/ReadingClassroom").then(
		({ReadingStudentModal}) => ({
			default: ReadingStudentModal,
		})
	)
);

const ReadingAddStudentModal = lazy(() =>
	import("../../../SprigReading/ReadingClassroom").then(
		({ReadingAddStudentModal}) => ({
			default: ReadingAddStudentModal,
		})
	)
);

const InstructionModal = lazy(() =>
	import("../../../SprigReading/ReadingClassroom").then(
		({InstructionModal}) => ({
			default: InstructionModal,
		})
	)
);

const AddEditSchool = lazy(() =>
	import("../../../Admin/AdminContent/Schools/SchoolDivedown/Modals").then(
		({AddEditSchool}) => ({default: AddEditSchool})
	)
);

const AddUserToClassroomModal = lazy(() =>
	import("../../../Admin/AdminContent/Schools/SchoolDivedown/Modals").then(
		({AddUserToClassroomModal}) => ({default: AddUserToClassroomModal})
	)
);

const RemoveStudentModal = lazy(() =>
	import("../../../Admin/AdminContent/Schools/SchoolDivedown/Modals").then(
		({RemoveStudentModal}) => ({default: RemoveStudentModal})
	)
);

const AddUpdateSchoolClassroom = lazy(() =>
	import("../../../Admin/AdminContent/Users/DataImport/Modals").then(
		({AddUpdateSchoolClassroom}) => ({default: AddUpdateSchoolClassroom})
	)
);

const GenerateTemplate = lazy(() =>
	import("../../../Admin/AdminContent/Users/DataImport/Modals").then(
		({GenerateTemplate}) => ({default: GenerateTemplate})
	)
);

const AddUser = lazy(() =>
	import("../../../Admin/AdminContent/Users/Modals").then(({AddUser}) => ({
		default: AddUser,
	}))
);

const DeleteUser = lazy(() =>
	import("../../../Admin/AdminContent/Users/Modals").then(({DeleteUser}) => ({
		default: DeleteUser,
	}))
);

const EditTeacher = lazy(() =>
	import("../../../Admin/AdminContent/Users/Modals").then(({EditTeacher}) => ({
		default: EditTeacher,
	}))
);

const EditUser = lazy(() =>
	import("../../../Admin/AdminContent/Users/Modals").then(({EditUser}) => ({
		default: EditUser,
	}))
);

const AddStudent = lazy(() =>
	import(
		"../../../Admin/AdminPublicComponents/AdminRequestFromNotification/Modals"
	).then(({AddStudent}) => ({default: AddStudent}))
);

const AddTeacher = lazy(() =>
	import(
		"../../../Admin/AdminPublicComponents/AdminRequestFromNotification/Modals"
	).then(({AddTeacher}) => ({default: AddTeacher}))
);

const EditStudent = lazy(() =>
	import(
		"../../../Admin/AdminPublicComponents/AdminRequestFromNotification/Modals"
	).then(({EditStudent}) => ({default: EditStudent}))
);

const RemoveStudent = lazy(() =>
	import(
		"../../../Admin/AdminPublicComponents/AdminRequestFromNotification/Modals"
	).then(({RemoveStudent}) => ({default: RemoveStudent}))
);

const AssessmentOverviewModal = lazy(
	() => import("./Surveys/AssessmentOverviewModal")
);

const CaregiverSurveyModal = lazy(
	() => import("./Surveys/CaregiverSurveyModal")
);

const ChildAssessmentModal = lazy(
	() => import("./Surveys/ChildAssessmentModal")
);

const OnboardingModal = lazy(
	() => import("../../../Caregiver/Onboarding/OnboardingModal")
);

const PostalCodeCollecting = lazy(() =>
	import("../../../Caregiver/Onboarding/PostalCodeCollecting").then(
		({PostalCodeCollecting}) => ({
			default: PostalCodeCollecting,
		})
	)
);

const SprigHomeOnboardingModal = lazy(() =>
	import("../../../Caregiver/Onboarding/SprigHomeOnboardingModal").then(
		({SprigHomeOnboardingModal}) => ({default: SprigHomeOnboardingModal})
	)
);

const AddSprigHomeChildModal = lazy(() =>
	import("../../../Caregiver/Subscription").then(
		({AddSprigHomeChildModal}) => ({default: AddSprigHomeChildModal})
	)
);

const RedeemCodeAndConfirmModal = lazy(() =>
	import("../../../Caregiver/Subscription").then(
		({RedeemCodeAndConfirmModal}) => ({default: RedeemCodeAndConfirmModal})
	)
);

const RedeemCoupon = lazy(() =>
	import("../../../Caregiver/Subscription").then(({RedeemCoupon}) => ({
		default: RedeemCoupon,
	}))
);

const DuplicateActivity = lazy(
	() => import("../../../Editor/Modals/DuplicateActivity")
);

const TranslateActivity = lazy(
	() => import("../../../Editor/Modals/TranslateActivity")
);
const NewActivity = lazy(() => import("../../../Editor/Modals/NewActivity"));

const ActivityCommentModal = lazy(
	() =>
		import(
			"../../../Educator/Classroom/LearningActivities/ActivityCommentModal"
		)
);

const CompleteAndRecommendActivity = lazy(() =>
	import(
		"../../../Educator/Classroom/LearningActivities/CompleteAndRecommendActivity"
	).then(({CompleteAndRecommendActivity}) => ({
		default: CompleteAndRecommendActivity,
	}))
);

const ClassCodeModal = lazy(() =>
	import("../../../Educator/Classroom/Students/modals").then(
		({ClassCodeModal}) => ({
			default: ClassCodeModal,
		})
	)
);

const EditGroupNameModal = lazy(() =>
	import("../../../Educator/Classroom/Students/modals").then(
		({EditGroupNameModal}) => ({
			default: EditGroupNameModal,
		})
	)
);

const MoveStudentModal = lazy(() =>
	import("../../../Educator/Classroom/Students/modals").then(
		({MoveStudentModal}) => ({
			default: MoveStudentModal,
		})
	)
);

const RequestAddStudent = lazy(() =>
	import("../../../Educator/Classroom/Students/modals").then(
		({RequestAddStudent}) => ({
			default: RequestAddStudent,
		})
	)
);

const StudentCodesDeleteModal = lazy(() =>
	import("../../../Educator/Classroom/Students/modals").then(
		({StudentCodesDeleteModal}) => ({
			default: StudentCodesDeleteModal,
		})
	)
);

const ClassroomsListModal = lazy(() =>
	import("../../../Educator/ClassroomsPage/Modals").then(
		({ClassroomsListModal}) => ({
			default: ClassroomsListModal,
		})
	)
);

const AdminRequest = lazy(() =>
	import("../../../Educator/EducatorPublicComponents").then(
		({AdminRequest}) => ({
			default: AdminRequest,
		})
	)
);

const StudentModal = lazy(() =>
	import("../../../Educator/EducatorPublicComponents").then(
		({StudentModal}) => ({
			default: StudentModal,
		})
	)
);

const EditPSMStudent = lazy(() =>
	import("../../../MyAccount/Subscription/Modals/EditPSMStudent").then(
		({EditPSMStudent}) => ({
			default: EditPSMStudent,
		})
	)
);

const ActivityFeedbackModal = lazy(() =>
	import("./Activity").then(({ActivityFeedbackModal}) => ({
		default: ActivityFeedbackModal,
	}))
);

const ActivityModal = lazy(() =>
	import("./Activity").then(({ActivityModal}) => ({
		default: ActivityModal,
	}))
);

const ConfirmHideActivityModal = lazy(() =>
	import("./Activity").then(({ConfirmHideActivityModal}) => ({
		default: ConfirmHideActivityModal,
	}))
);

const MilestoneRecommendedActivitiesModal = lazy(() =>
	import("./Activity").then(({MilestoneRecommendedActivitiesModal}) => ({
		default: MilestoneRecommendedActivitiesModal,
	}))
);

const MathAssessmentResults = lazy(() =>
	import("./Assessment").then(({MathAssessmentResults}) => ({
		default: MathAssessmentResults,
	}))
);

const OralLanguageAssessmentResults = lazy(() =>
	import("./Assessment").then(({OralLanguageAssessmentResults}) => ({
		default: OralLanguageAssessmentResults,
	}))
);

const StudentPastTermsModal = lazy(() =>
	import("./Assessment").then(({StudentPastTermsModal}) => ({
		default: StudentPastTermsModal,
	}))
);

const SprigFeedbackModal = lazy(() =>
	import("./Feedback").then(({SprigFeedbackModal}) => ({
		default: SprigFeedbackModal,
	}))
);

const ConfirmationModal = lazy(() =>
	import("./Generic").then(({ConfirmationModal}) => ({
		default: ConfirmationModal,
	}))
);

const BadgesEarnedModal = lazy(() =>
	import("./Rewards").then(({BadgesEarnedModal}) => ({
		default: BadgesEarnedModal,
	}))
);

const BadgesModal = lazy(() =>
	import("./Rewards").then(({BadgesModal}) => ({
		default: BadgesModal,
	}))
);

const AddChildSubscriptionModal = lazy(() =>
	import("./Subscription").then(({AddChildSubscriptionModal}) => ({
		default: AddChildSubscriptionModal,
	}))
);

const CancelSubscriptionModal = lazy(() =>
	import("./Subscription").then(({CancelSubscriptionModal}) => ({
		default: CancelSubscriptionModal,
	}))
);

const ParentSubscriptionModal = lazy(() =>
	import("./Subscription").then(({ParentSubscriptionModal}) => ({
		default: ParentSubscriptionModal,
	}))
);

const PaymentIssueSubscriptionModal = lazy(() =>
	import("./Subscription").then(({PaymentIssueSubscriptionModal}) => ({
		default: PaymentIssueSubscriptionModal,
	}))
);

export const modalTypes = {
	[MODAL_TYPES.ORAL_LANGUAGE_ASSESSMENT_RESULTS]: OralLanguageAssessmentResults,
	[MODAL_TYPES.BADGE_COLLECTION]: BadgesModal,
	[MODAL_TYPES.BADGES_EARNED]: BadgesEarnedModal,
	[MODAL_TYPES.COMPLETE_AND_RECOMMEND_ACTIVITY_NEW]:
		CompleteAndRecommendActivity,
	[MODAL_TYPES.EDIT_STUDENT]: EditStudent,
	[MODAL_TYPES.ADD_STUDENT]: AddStudent,
	[MODAL_TYPES.REMOVE_STUDENT]: RemoveStudent,
	[MODAL_TYPES.NEW_ACTIVITY]: NewActivity,
	[MODAL_TYPES.TRANSLATE_ACTIVITY]: TranslateActivity,
	[MODAL_TYPES.DUPLICATE_ACTIVITY]: DuplicateActivity,
	[MODAL_TYPES.ADMIN_REQUEST]: AdminRequest,
	[MODAL_TYPES.MATH_ASSESSMENT_RESULT]: MathAssessmentResults,
	[MODAL_TYPES.PARENT_SUBSCRIPTION]: ParentSubscriptionModal,
	[MODAL_TYPES.ACTIVITY_MODAL_NEW]: ActivityModal,
	[MODAL_TYPES.STUDENT_MODAL]: StudentModal,
	[MODAL_TYPES.CANCEL_SUBSCRIPTION]: CancelSubscriptionModal,
	[MODAL_TYPES.ADD_CHILD_SUBSCRIPTION]: AddChildSubscriptionModal,
	[MODAL_TYPES.SUBSCRIPTION_PAYMENT_ISSUE]: PaymentIssueSubscriptionModal,
	[MODAL_TYPES.REQUEST_ADD_STUDENT]: RequestAddStudent,
	[MODAL_TYPES.CLASS_CODE]: ClassCodeModal,
	[MODAL_TYPES.DELETE_CLASS_CODE]: StudentCodesDeleteModal,
	[MODAL_TYPES.EDIT_GROUP_NAME]: EditGroupNameModal,
	[MODAL_TYPES.MOVE_GROUP_STUDENT]: MoveStudentModal,
	[MODAL_TYPES.DELETE_USER]: DeleteUser,
	[MODAL_TYPES.ADD_USER]: AddUser,
	[MODAL_TYPES.ADD_USER_TO_CLASSROOM]: AddUserToClassroomModal,
	[MODAL_TYPES.EDIT_USER]: EditUser,
	[MODAL_TYPES.EDIT_TEACHER]: EditTeacher,
	[MODAL_TYPES.REMOVE_USER]: RemoveStudentModal,
	[MODAL_TYPES.STUDENT_PAST_TERMS]: StudentPastTermsModal,
	[MODAL_TYPES.ADD_EDIT_SCHOOL]: AddEditSchool,
	[MODAL_TYPES.ACTIVITY_COMMENT]: ActivityCommentModal,
	[MODAL_TYPES.CAREGIVER_ONBOARDING]: OnboardingModal,
	[MODAL_TYPES.SPRIG_HOME_ONBOARDING_MODAL]: SprigHomeOnboardingModal,
	[MODAL_TYPES.CAREGIVER_SURVEY]: CaregiverSurveyModal,
	[MODAL_TYPES.ASSESSMENT_OVERVIEW]: AssessmentOverviewModal,
	[MODAL_TYPES.CONFIRMATION_MODAL]: ConfirmationModal,
	[MODAL_TYPES.DATA_IMPORT_ADD_UPDATE_SCHOOL_CLASSROOM]:
		AddUpdateSchoolClassroom,
	[MODAL_TYPES.GENERATE_TEMPLATE]: GenerateTemplate,
	[MODAL_TYPES.ADD_TEACHER]: AddTeacher,
	[MODAL_TYPES.FEEDBACK_MODAL]: SprigFeedbackModal,
	[MODAL_TYPES.RECOMMENDED_ACTIVITIES]: MilestoneRecommendedActivitiesModal,
	[MODAL_TYPES.EDIT_PSM_STUDENT]: EditPSMStudent,
	[MODAL_TYPES.CLASSROOMS_LIST_MODAL]: ClassroomsListModal,
	[MODAL_TYPES.ADD_SPRIG_HOME_CHILD]: AddSprigHomeChildModal,
	[MODAL_TYPES.REDEEM_COUPON]: RedeemCoupon,
	[MODAL_TYPES.REDEEM_CODE_AND_CONFIRMATION_MODAL]: RedeemCodeAndConfirmModal,
	[MODAL_TYPES.POSTAL_CODE_COLLECTING]: PostalCodeCollecting,
	[MODAL_TYPES.CHILD_ASSESSMENT_MODAL]: ChildAssessmentModal,
	[MODAL_TYPES.CONFIRM_HIDE_ACTIVITY_MODAL]: ConfirmHideActivityModal,
	[MODAL_TYPES.ACTIVITY_FEEDBACK_MODAL]: ActivityFeedbackModal,
	[MODAL_TYPES.INSTRUCTION_MODAL]: InstructionModal,
	[MODAL_TYPES.READING_ADD_STUDENT_MODAL]: ReadingAddStudentModal,
	[MODAL_TYPES.READING_STUDENT_MODAL]: ReadingStudentModal,
	[MODAL_TYPES.INVITE_TEACHER_MODAL]: InviteTeacherModal,
	[MODAL_TYPES.ADD_EDIT_READING_CLASSROOM_MODAL]: AddEditReadingClassroomModal,
	[MODAL_TYPES.CONFIRM_REMOVE_USER_MODAL]: ConfirmRemoveUserModal,
	[MODAL_TYPES.SKILL_MODAL]: SkillModal,
	[MODAL_TYPES.TRAINING_VIDEO_MODAL]: TrainingVideoModal,
	[MODAL_TYPES.READING_EDIT_SCHOOL_MODAL]: ReadingEditSchoolModal,
	[MODAL_TYPES.PLAIN_CONTENT_MODAL]: PlainContentModal,
	[MODAL_TYPES.NOTE_MODAL]: NoteModal,
	[MODAL_TYPES.EDIT_NOTE_MODAL]: EditNoteModal,
	[MODAL_TYPES.HOLISTIC_MATH_STUDENT_MODAL]: HolisticMathStudentModal,
	[MODAL_TYPES.ACTIVITY_ASSESSMENT_MODAL]: ActivityAssessmentModal,
	[MODAL_TYPES.HM_ONBOARDING_MODAL]: HMOnboardingModal,
	[MODAL_TYPES.HM_CAREGIVER_SURVEY_MODAL]: HMCaregiverSurveyModal,
	[MODAL_TYPES.READING_WELCOME_MODAL]: ReadingWelcomeModal,
};
