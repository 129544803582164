import * as ActionTypes from "../Redux/ActionTypes";
import {CreateReducer} from "../Redux/Utilities";

const initialState = {
	queue: [],
};

export const ActivityMonitorReducer = CreateReducer(initialState, {
	[ActionTypes.ACTIVITY_MONITOR_ADD_TO_QUEUE]: (state, {payload}) => {
		if (!payload) return state;
		const updatedQueue =
			state.queue.length >=
			(state.queue.every((item) => item.isReading) ? 2 : 4)
				? [...state.queue].slice(1)
				: state.queue;
		return {
			...state,
			queue: [...updatedQueue, {...payload, id: Date.now()}],
		};
	},
	[ActionTypes.ACTIVITY_MONITOR_REMOVE_FROM_QUEUE]: (state, {payload}) => {
		if (!payload) return state;
		return {
			...state,
			queue: state.queue.filter((queueItem) => queueItem.id !== payload),
		};
	},
});
