import React, {Component} from "react";

interface OutsideClickHandlerProps {
	onOutsideClick: (event?: any) => void;
	wrapperClassName?: string;
}

export class OutsideClickHandler extends Component<OutsideClickHandlerProps> {
	private node: HTMLElement;

	constructor(props: OutsideClickHandlerProps) {
		super(props);
		this.node = document.createElement("div");
	}

	componentDidMount = () => {
		document.addEventListener("mousedown", this.handleClick, false);
	};

	componentWillUnmount = () => {
		document.removeEventListener("mousedown", this.handleClick, false);
	};

	handleClick = (e: any) => {
		if (!this.node.contains(e.target)) {
			this.props.onOutsideClick(e);
		}
	};

	render() {
		return (
			<div
				ref={(node) => (this.node = node as HTMLDivElement)}
				className={this.props.wrapperClassName}
			>
				{this.props.children}
			</div>
		);
	}
}
