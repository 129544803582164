import * as ActionTypes from "../Redux/ActionTypes";
import {CreateReducer} from "../Redux/Utilities";
import {ACTIVITY_FILTERS, FILTER_TAGS} from "../Scripts/Constants";

const initialState = {
	initialized: {
		activities: false,
	},
	initializedActivities: false,
	initializedAllBadges: false,
	initializedGrades: false,
	initializedPlaces: false,
	initializedDifficulties: false,
	initializedLanguages: false,
	initializedLearningOutcomes: false,
	initializedCurriculum: false,
	totalPages: 1,
	tagging: {
		[FILTER_TAGS.GENERAL_OUTCOMES]: [],
		[FILTER_TAGS.LEARNING_OUTCOMES]: [],
		[FILTER_TAGS.SPECIFIC_OUTCOMES]: [],
		reading: {},
	},
	activities: null,
	completedActivities: null,
	recentlyCompletedActivities: [],
	studentIdForCompletedActivities: null,
	rewards: {},
	grade: [],
	competencies: [],
	places: null,
	filters: {
		[ACTIVITY_FILTERS.SEARCH]: "",
		[ACTIVITY_FILTERS.PAGE]: 1,
		[ACTIVITY_FILTERS.PER_PAGE]: "",
		[ACTIVITY_FILTERS.COMPLETED_BY_CLASS_AND_CURRENT_USER]: "",
		[ACTIVITY_FILTERS.SAVED_FOR]: "",
		[ACTIVITY_FILTERS.DOMAINS]: "",
		[ACTIVITY_FILTERS.STATUS]: "",
		[ACTIVITY_FILTERS.COMPLETED_BY]: "",
		[ACTIVITY_FILTERS.NOT_COMPLETED_BY]: "",
		[ACTIVITY_FILTERS.LEARNING_OUTCOMES]: "",
		[ACTIVITY_FILTERS.GENERAL_OUTCOMES]: "",
		[ACTIVITY_FILTERS.SPECIFIC_OUTCOMES]: "",
		[ACTIVITY_FILTERS.AUTHOR]: "",
		[ACTIVITY_FILTERS.LANGUAGES]: "",
		[ACTIVITY_FILTERS.COMPETENCIES]: "",
		[ACTIVITY_FILTERS.PLACES]: "",
		[ACTIVITY_FILTERS.DIFFICULTIES]: "",
		[ACTIVITY_FILTERS.DURATIONS]: "",
		filterTags: {
			[FILTER_TAGS.COMPETENCIES]: [],
			[FILTER_TAGS.DIFFICULTIES]: [],
			[FILTER_TAGS.GENERAL_OUTCOMES]: [],
			[FILTER_TAGS.LANGUAGES]: [],
			[FILTER_TAGS.LEARNING_OUTCOMES]: [],
			[FILTER_TAGS.SPECIFIC_OUTCOMES]: [],
		},
	},
	activityFeedbackComment: {},
};

export const ActivityReducer = CreateReducer(initialState, {
	[ActionTypes.ACTIVITY_SET_FILTERS]: (state, {payload}) => {
		if (!payload) return state;
		const {filters} = payload;
		return {
			...state,
			filters: {
				...state.filters,
				...filters,
			},
		};
	},
	[ActionTypes.ACTIVITY_GET_ALL]: (state, {payload}) => {
		if (!payload) return state;
		const {totalPages, activities, lazyLoad, totalNotCompletedActivitiesCount} =
			payload;
		return {
			...state,
			totalPages,
			initializedActivities: true,
			activities: lazyLoad ? [...state.activities, ...activities] : activities,
			totalNotCompletedActivitiesCount: {
				...state.totalNotCompletedActivitiesCount,
				...totalNotCompletedActivitiesCount,
			},
		};
	},
	[ActionTypes.ACTIVITY_CLEAR_ACTIVITIES]: (state, {payload}) => {
		return {
			...state,
			activities: payload,
		};
	},
	[ActionTypes.ACTIVITY_GET_COMPLETION_COUNTS]: (state, {payload}) => {
		if (!payload) return state;
		return {
			...state,
			completedActivities: payload.activities,
			studentIdForCompletedActivities: payload.id,
		};
	},
	[ActionTypes.ACTIVITY_GET_SAVED_ACTIVITIES]: (state, {payload}) => {
		if (!payload) return state;
		return {
			...state,
			savedActivities: payload,
		};
	},
	[ActionTypes.ACTIVITY_GET_LANGUAGES]: (state, {payload}) => {
		if (!payload) return state;
		return {
			...state,
			tagging: {
				...state.tagging,
				[FILTER_TAGS.LANGUAGES]: payload,
			},
			initializedLanguages: true,
		};
	},
	[ActionTypes.ACTIVITY_GET_PLACES]: (state, {payload}) => {
		if (!payload) return state;
		return {
			...state,
			tagging: {
				...state.tagging,
				places: payload,
			},
			places: payload,
			initializedPlaces: true,
		};
	},
	[ActionTypes.ACTIVITY_GET_DIFFICULTIES]: (state, {payload}) => {
		if (!payload) return state;
		return {
			...state,
			tagging: {
				...state.tagging,
				[FILTER_TAGS.DIFFICULTIES]: payload,
			},
			initializedDifficulties: true,
		};
	},
	[ActionTypes.ACTIVITY_GET_LEARNING_OUTCOMES]: (state, {payload}) => {
		if (!payload) return state;
		return {
			...state,
			initializedLearningOutcomes: true,
			tagging: {
				...state.tagging,
				[FILTER_TAGS.LEARNING_OUTCOMES]: payload,
			},
		};
	},
	[ActionTypes.ACTIVITY_GET_GENERAL_OUTCOMES]: (state, {payload}) => {
		if (!payload) return state;
		return {
			...state,
			tagging: {
				...state.tagging,
				[FILTER_TAGS.GENERAL_OUTCOMES]: payload,
			},
		};
	},
	[ActionTypes.ACTIVITY_GET_SPECIFIC_OUTCOMES]: (state, {payload}) => {
		if (!payload) return state;
		return {
			...state,
			tagging: {
				...state.tagging,
				[FILTER_TAGS.SPECIFIC_OUTCOMES]: payload,
			},
		};
	},
	[ActionTypes.ACTIVITY_GET_DOMAIN]: (state, {payload}) => {
		if (!payload) return state;
		return {
			...state,
			domain: payload,
		};
	},
	[ActionTypes.ACTIVITY_GET_DURATION]: (state, {payload}) => {
		if (!payload) return state;
		return {
			...state,
			duration: payload,
		};
	},
	[ActionTypes.ACTIVITY_GET_GRADE]: (state, {payload}) => {
		if (!payload) return state;
		return {
			...state,
			grade: payload,
			initializedGrades: true,
		};
	},
	[ActionTypes.ACTIVITY_GET_CURRICULA]: (state, {payload}) => {
		if (!payload) return state;
		return {
			...state,
			curriculum: payload,
			initializedCurriculum: true,
		};
	},
	[ActionTypes.ACTIVITY_GET_COMPETENCIES]: (state, {payload}) => {
		if (!payload) return state;
		return {
			...state,
			competencies: payload,
			tagging: {
				...state.tagging,
				[FILTER_TAGS.COMPETENCIES]: payload,
			},
		};
	},
	[ActionTypes.ACTIVITY_GET_ALL_BADGES]: (state, {payload}) => {
		if (!payload) return state;
		return {
			...state,
			initializedAllBadges: true,
			rewards: {
				...state.rewards,
				badges: payload,
			},
		};
	},
	[ActionTypes.ACTIVITY_GET_BADGES_PERSON]: (state, {payload}) => {
		if (!payload) return state;
		return {
			...state,
			rewards: {
				...state.rewards,
				earnedBadges: payload,
			},
		};
	},
	[ActionTypes.ACTIVITY_GET_ACTIVITY_COMPLETIONS_BY_CLASSROOM]: (
		state,
		{payload}
	) => {
		if (!payload) return state;
		return {
			...state,
			completions: payload,
		};
	},
	[ActionTypes.ACTIVITY_UPDATE_EARNED_BADGES]: (state, {payload}) => {
		if (!payload) return state;
		const previousEarnedBadges =
			state.rewards && state.rewards.earnedBadges
				? state.rewards.earnedBadges
				: null;
		if (previousEarnedBadges) {
			const updatedPreviousEarnedBadges = previousEarnedBadges.map(
				(earnedBadge) => {
					const matchedBadge = payload.find(
						(badge) => badge.id === earnedBadge.id
					);
					if (matchedBadge) return matchedBadge;
					return earnedBadge;
				}
			);
			const previousEarnedBadgeIds = previousEarnedBadges.map(
				(badge) => badge.id
			);
			const newEarnedBadges = payload.filter(
				(badge) => !previousEarnedBadgeIds.includes(badge.id)
			);
			return {
				...state,
				rewards: {
					...state.rewards,
					earnedBadges: [...updatedPreviousEarnedBadges, ...newEarnedBadges],
				},
			};
		}
		return {
			...state,
			rewards: {
				...state.rewards,
				earnedBadges: payload,
			},
		};
	},
	[ActionTypes.ACTIVITY_POST_COMPLETE_ACTIVITY]: (state, {payload}) => {
		if (!payload) return state;
		return {
			...state,
			recentlyCompletedActivities: [
				...state.recentlyCompletedActivities,
				payload,
			],
		};
	},
	[ActionTypes.ACTIVITY_GET_ACTIVITY_COMMENT]: (state, {payload}) => {
		if (!payload) return state;
		const {response, activityId} = payload;
		return {
			...state,
			activityFeedbackComment: {
				...state.activityFeedbackComment,
				[activityId]: response,
			},
		};
	},
	[ActionTypes.ACTIVITY_GET_LEARNING_OUTCOMES_BY_DOMAIN]: (
		state,
		{payload}
	) => {
		if (!payload) return state;
		const {domain, response} = payload;
		return {
			...state,
			tagging: {
				...state.tagging,
				[domain.slug]: {
					...state.tagging[domain.slug],
					learningOutcomes: [...response],
				},
			},
		};
	},
	[ActionTypes.ACTIVITY_GET_GENERAL_OUTCOMES_BY_DOMAIN]: (state, {payload}) => {
		if (!payload) return state;
		const {domain, response} = payload;
		return {
			...state,
			tagging: {
				...state.tagging,
				[domain.slug]: {
					...state.tagging[domain.slug],
					generalOutcomes: [...response],
				},
			},
		};
	},
	[ActionTypes.ACTIVITY_GET_SPECIFIC_OUTCOMES_BY_DOMAIN]: (
		state,
		{payload}
	) => {
		if (!payload) return state;
		const {domain, response} = payload;
		return {
			...state,
			tagging: {
				...state.tagging,
				[domain.slug]: {
					...state.tagging[domain.slug],
					specificOutcomes: [...response],
				},
			},
		};
	},
	[ActionTypes.ACTIVITY_GET_ACTIVITY_ASSESSMENT_QUESTIONS_BY_ASSESSMENT_ID]: (
		state,
		{payload}
	) => {
		if (!payload) return state;
		const {activityId, response} = payload;
		return {
			...state,
			activityAssessments: {
				...state.activityAssessments,
				[activityId]: [...response],
			},
		};
	},
});
