import * as ActionTypes from "../Redux/ActionTypes";
import {CreateReducer} from "../Redux/Utilities";
import {
	ACTIVITY_TAG,
	OUTCOME_LEVEL,
	REPORT_ID,
	ROLES,
} from "../Scripts/Constants";
import {DOMAIN_ORAL_LANGUAGE} from "../Scripts/Domain";
import {getDomains} from "../Scripts/Utilities";

const domainsTaxonomy = getDomains();
const initialReports = {
	activity_tag_stats: {
		[OUTCOME_LEVEL.ONE]: [],
		[`${OUTCOME_LEVEL.ONE}Initialized`]: false,
		[OUTCOME_LEVEL.TWO]: [],
		[`${OUTCOME_LEVEL.TWO}Initialized`]: false,
		[OUTCOME_LEVEL.THREE]: [],
		[`${OUTCOME_LEVEL.THREE}Initialized`]: false,
		[ACTIVITY_TAG.COMPETENCY]: [],
		[`${ACTIVITY_TAG.COMPETENCY}Initialized`]: false,
	},
	submission_count: [],
	[`${REPORT_ID.SUBMISSION_COUNT}Initialized`]: false,
	rubric: [],
	[`${REPORT_ID.RUBRIC}Initialized`]: false,
	milestone_level: null,
	[`${REPORT_ID.MILESTONE}Initialized`]: false,
	screentime: null,
	[`${REPORT_ID.SCREENTIME}Initialized`]: false,
};
const initialState = {
	domain: DOMAIN_ORAL_LANGUAGE,
	class: {...initialReports},
	school: {...initialReports},
	division: {...initialReports},
	person: {...initialReports},
	directReports: {
		class: {...initialReports},
		school: {...initialReports},
		division: {...initialReports},
		person: {...initialReports},
	},
};

export const ReportReducer = CreateReducer(initialState, {
	[ActionTypes.REPORT_GET]: (state, {payload}) => {
		if (!payload) return state;
		if (payload.report === REPORT_ID.RUBRIC) {
			const {directReports} = {...state[ROLES.DIRECTOR]};
			directReports[payload.type][payload.report] = payload.response;
			return {
				...state,
				[ROLES.DIRECTOR]: {
					...state[ROLES.DIRECTOR],
					directReports,
				},
			};
		}
		return {
			...state,
			[payload.type]: {
				...state[payload.type],
				[payload.report]: payload.response,
			},
		};
	},
	[ActionTypes.REPORT_GET_SINGLE]: (state, {payload}) => {
		if (!payload) return state;
		if (payload.direct) {
			const directReports = {...state.directReports};
			return {
				...state,
				directReports: {
					...directReports,
					[payload.type]: {
						...directReports[payload.type],
						[payload.report]: payload.tag
							? {
									...directReports[payload.type][payload.report],
									[payload.tag]: payload.response,
									[`${payload.tag}Initialized`]: true,
							  }
							: [...payload.response],
						[`${payload.report}Initialized`]: true,
					},
				},
			};
		} else
			return {
				...state,
				[payload.type]: {
					...state[payload.type],
					[payload.report]: payload.tag
						? {
								...state[payload.type][payload.report],
								[payload.tag]: payload.response,
								[`${payload.tag}Initialized`]: true,
						  }
						: [...payload.response],
					[`${payload.report}Initialized`]: true,
				},
			};
	},
	[ActionTypes.REPORT_GET_ALL]: (state, {payload}) => {
		if (!payload || !payload.reports) return state;
		const {reports, portal} = payload;
		const reportsPerPortal = {
			directReports: {},
		};
		reports.forEach((report) => {
			if (report.direct) {
				reportsPerPortal.directReports[report.type] =
					report.report === REPORT_ID.RUBRIC
						? {
								...reportsPerPortal.directReports[report.type],
								[report.report]: {[report.domains]: report.response},
						  }
						: {
								...reportsPerPortal.directReports[report.type],
								[report.report]: report.response,
						  };
			} else {
				reportsPerPortal[report.type] = {
					...reportsPerPortal[report.type],
					[report.report]: report.response,
				};
			}
		});
		return {
			...state,
			[portal]: reportsPerPortal,
		};
	},
	[ActionTypes.REPORT_SET_DOMAIN]: (state, {payload}) => {
		if (!payload) return state;
		const domainIds = domainsTaxonomy.map(
			(domainTaxonomy) => domainTaxonomy.id
		);
		if (domainIds.includes(payload)) {
			return {
				...state,
				domain: payload,
			};
		}
		console.error("Invalid domain code: ", payload);
		return state;
	},
	[ActionTypes.REPORT_GET_STUDENT_SCREEN_TIME_REPORT]: (state, {payload}) => {
		if (!payload) return state;
		return {
			...state,
			[payload.type]: {
				...state[payload.type],
				[payload.report]: {...payload.response},
				[`${payload.report}Initialized`]: true,
			},
		};
	},
});
