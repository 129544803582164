import "../../../Stylesheets/css/scss/Components/Public/LoaderSpinner.css";

import i18n from "i18next";
import React, {ReactElement} from "react";

import {AssetManager} from "../../../Scripts/AssetManager";

export const LoaderSpinner = (): ReactElement => (
	<div className="loader-spinner-component">
		<div className="loader-circle" />
		<div className="loader-img">
			<img
				src={AssetManager.ASSETS.LOGO.SPRIG_LEAVES_GREEN}
				alt={i18n.t("common.sprig_logo")}
			/>
		</div>
	</div>
);
