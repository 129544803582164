import i18n from "i18next";
import React from "react";
import {Trans} from "react-i18next";

import * as ActionTypes from "../Redux/ActionTypes";
import {
	deleteDataAcceptJSON,
	getData,
	postData,
	postDataJSON,
	postDataNoAuth,
	putData,
} from "../Scripts/Axios/axiosRequests";
import {ACTIVITY_MONITOR_ACTIONS} from "../Scripts/Constants";
import {get_url} from "../Scripts/Fetch";
import {
	ActivityMonitorActions,
	ErrorReporterActions,
	LoaderActions,
	SchoolActions,
} from "./index";

export const setUserRoles = (roles) => ({
	type: ActionTypes.USER_SET_ROLES,
	payload: roles,
});

export const getMe = () => {
	const actionType = ActionTypes.USER_SET_ENTITLEMENTS;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/me`;
		getData(url)
			.then((response) => {
				dispatch({
					type: actionType,
					payload: response,
				});
			})
			.catch((error) => {
				console.error(error.message);
			});
	};
};

export const setUserEntitlements = (entitlements) => {
	return {
		type: ActionTypes.USER_SET_ENTITLEMENTS,
		payload: entitlements,
	};
};

export const verifyUserAndSetToken = (credentials, {handling} = {}) => {
	const actionType = ActionTypes.USER_GET_AUTHED;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/jwt-auth/v1/token`;
		postDataNoAuth(url, credentials)
			.then((response, error) => {
				if (response) {
					if (response.data && response.data.token) {
						localStorage.setItem(
							"sprig-login-token",
							JSON.stringify(response.data.token)
						);
						if (handling?.success) {
							handling.success();
						}
					} else {
						if (handling?.error) {
							handling.error();
						}
					}
					dispatch({
						type: actionType,
						payload: response,
					});
				} else {
					dispatch({
						type: actionType,
						payload: {status: error ? error.status : 0},
					});
				}
			})
			.catch((error) => {
				dispatch({
					type: actionType,
					payload: {status: error ? error.status : 0},
				});
			});
	};
};

export const userHasVerifiedToken = (authed) => {
	const actionType = ActionTypes.USER_GET_AUTHED;
	return {
		type: actionType,
		payload: authed ? {status: 200} : {},
	};
};

export const handleUserLogout = () => {
	const actionType = ActionTypes.USER_LOGOUT;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/jwt-auth/v1/token/revoke`;
		postData(url)
			.then(() => {
				dispatch({
					type: actionType,
				});
			})
			.then(() => {
				window.location.replace("/");
			})
			.catch((error) => {
				ErrorReporterActions.addToQueue({
					actionType,
					error,
				});

				dispatch({
					type: actionType,
				});
			});
	};
};

export const requestResetPasswordEmail = (body, {handling}) => {
	const actionType = ActionTypes.USER_REQUEST_RESET_PASSWORD_EMAIL;
	return () => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/jwt-auth/v1/token/resetpassword`;
		postDataNoAuth(url, body)
			.then((response) => {
				if (response.status === 200) {
					handling.success(response);
				} else {
					handling.error(response);
				}
			})
			.catch((error) => {
				handling.error(error);
			});
	};
};

export const resetAccountRegistration = () => {
	return {
		type: ActionTypes.USER_RESET_REGISTRATION,
		payload: null,
	};
};

export const registerNewCaregiverAccount = (accountInformation) => {
	const actionType = ActionTypes.USER_REGISTER_CAREGIVER_ACCOUNT;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/accounts/register`;
		postDataNoAuth(url, accountInformation)
			.then((response) => {
				dispatch({
					type: actionType,
					payload: {
						response,
						id: accountInformation.email,
					},
				});
			})
			.catch((error) => {
				dispatch({
					type: actionType,
					payload: {
						response: error,
						id: accountInformation.email,
					},
				});
			});
	};
};

export const registerNewReadingFreeTrialAccount = (
	accountInformation,
	{handling}
) => {
	const actionType = ActionTypes.USER_REGISTER_READING_FREE_TRIAL_ACCOUNT;
	return () => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/clientFreeTrials`;
		postDataNoAuth(url, accountInformation)
			.then((response) => {
				if (response.status < 300) {
					handling.success(response);
				} else {
					handling.error(response);
				}
			})
			.catch((error) => {
				handling.error(error);
			});
	};
};

export const acceptTeacherInvitation = (body, {handling}) => {
	const actionType = ActionTypes.USER_ACCEPT_TEACHER_INVITATION;
	return () => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/teacherInvitations/accept`;
		postDataNoAuth(url, body)
			.then((response) => {
				if (response.status < 300) {
					handling.success(response);
				} else {
					handling.error(response);
				}
			})
			.catch((error) => {
				handling.error(error);
			});
	};
};

export const getAccessiblePeople = (type, {handling} = {}) => {
	const actionType = ActionTypes.USER_GET_ACCESSIBLE_PEOPLE;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/people?type=${type}`;
		getData(url)
			.then((response) => {
				if (handling?.success) {
					handling.success(response);
				}
				dispatch({
					type: actionType,
					payload: response,
				});
			})
			.catch((error) => {
				console.error(`Error: ${error.message}`);
			});
	};
};

export const searchAccessiblePeople = (type, search, {handling}) => {
	const actionType = ActionTypes.USER_SEARCH_ACCESSIBLE_PEOPLE;
	return () => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/people?type=${type}&search=${search}`;
		getData(url)
			.then((response) => {
				handling.success(response);
			})
			.catch((error) => {
				handling.error(error);
			});
	};
};

export const getPeopleInClassroom = (classId, cancel, handleFunction = {}) => {
	const actionType = ActionTypes.USER_GET_PEOPLE_IN_CLASSROOM;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/classes/${classId}/people?context=view`;
		getData(url, false, cancel)
			.then((response) => {
				if (handleFunction?.handling) {
					handleFunction.handling.success(response);
				} else
					dispatch({
						type: actionType,
						payload: {classId, response},
					});
			})
			.catch((error) => {
				console.error(`Error: ${error.message}`);
			});
	};
};

export const resetClassCodeVerification = () => {
	return {
		type: ActionTypes.USER_RESET_STUDENT_CODE_VERIFICATION,
		payload: null,
	};
};

export const verifyClassCode = (redemptionInformation) => {
	const actionType = ActionTypes.USER_VERIFY_STUDENT_CODE;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/codes/check`;
		postData(url, redemptionInformation)
			.then((response) => {
				dispatch({
					type: actionType,
					payload: {response, data: redemptionInformation},
				});
			})
			.catch((error) => {
				let modifiedError;
				switch (error.status) {
					case 404:
						modifiedError = {
							...error,
							data: {
								...error?.data,
								message: (
									<Trans i18nKey="error.invalid_teacher_student_name">
										{[
											<a
												href="https://www.spriglearning.com/support/?page_id=15"
												target="_blank"
												rel="noopener noreferrer"
											>
												{/**/}
											</a>,
										]}
									</Trans>
								),
							},
						};
						break;
					default:
						modifiedError = error;
						break;
				}
				dispatch({
					type: actionType,
					payload: {response: modifiedError},
				});
			});
	};
};

export const resetCompleteStudentCodeRedemption = () => {
	return {
		type: ActionTypes.USER_RESET_STUDENT_CODE_REDEMPTION,
		payload: null,
	};
};

export const clearRedeemedCouponErrorMessage = () => {
	return {
		type: ActionTypes.USER_RESET_REDEEM_COUPON_ERROR_MESSAGE,
		payload: null,
	};
};

export const completeStudentCodeRedemption = (redemptionInformation) => {
	const actionType = ActionTypes.USER_COMPLETE_STUDENT_CODE_REDEMPTION;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/codes/redeem`;
		postData(url, redemptionInformation)
			.then((response) => {
				dispatch({
					type: actionType,
					payload: {response, data: redemptionInformation},
				});
			})
			.catch((error) => {
				dispatch({
					type: actionType,
					payload: {response: error},
				});
			});
	};
};

export const addStudentToPaidSubscription = (domain, request) => {
	const actionType = ActionTypes.USER_ADD_STUDENT_TO_PAID_SUBSCRIPTION;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/subscriptions/${domain}/students`;
		putData(url, request)
			.then((response) => {
				dispatch({
					type: actionType,
					payload: {response, student: {...request, domain}},
				});
			})
			.catch((error) => {
				dispatch({
					type: actionType,
					payload: {error, student: {...request, domain}},
				});
			});
	};
};

export const updateFreeTrial = (body, {handling}) => {
	const actionType = ActionTypes.USER_UPDATE_FREE_TRIAL;
	return () => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/trials`;
		putData(url, body)
			.then((response) => {
				if (response.status < 300) {
					handling.success();
				}
			})
			.catch((error) => {
				handling.error(error);
			});
	};
};

export const addStudentToFreeTrial = (student) => {
	const actionType = ActionTypes.USER_CREATE_PERSON_FOR_FREE_TRIAL;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/trials`;
		postData(url, student)
			.then((response) => {
				dispatch({
					type: actionType,
					payload: {success: response.status === 200, response},
				});
			})
			.catch((error) => {
				dispatch({
					type: actionType,
					payload: {
						success: false,
						student,
						error,
					},
				});
			});
	};
};

export const createStudentAndStartFreeTrial = (student) => {
	const actionType = ActionTypes.USER_CREATE_PERSON_FOR_FREE_TRIAL;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/people`;
		postData(url, {
			type: "student",
			caregiver_ids: [student.caregiver_id],
			name: student.name,
		})
			.then((response) => {
				const studentWithId = {
					...student,
					id: response.data.id,
				};
				postData(`${get_url()}wp-json/sprig/v2/trials`, {
					student_id: response.data.id,
					domain_id: student.domain,
					grade_id: student.grade,
				})
					.then((response) => {
						dispatch({
							type: actionType,
							payload: {success: true, student: studentWithId},
						});
					})
					.catch((error) => {
						dispatch({
							type: actionType,
							payload: {
								success: false,
								student: studentWithId,
								error,
							},
						});
					});
			})
			.catch((error) => {
				dispatch({
					type: actionType,
					payload: {error, success: false},
				});
			});
	};
};

export const updateUser = (userId, body, {handling}) => {
	const actionType = ActionTypes.USER_UPDATE_USER;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/people/${userId}`;
		postData(url, body)
			.then((response) => {
				if (response.status < 300) {
					handling.success(response);
				} else {
					handling.error(response);
				}
				dispatch({
					type: actionType,
				});
			})
			.catch((error) => {
				handling.error(error);
				dispatch({
					type: actionType,
				});
			});
	};
};

export const deleteUser = (userId, {handling}) => {
	const actionType = ActionTypes.USER_DELETE_USER;
	return () => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/people/${userId}`;
		deleteDataAcceptJSON(url)
			.then((response) => {
				if (response.status < 300) {
					handling.success();
				}
			})
			.catch((error) => {
				handling.error(error);
			});
	};
};

export const submitCouponAndPostalCode = (couponAndPostalCode) => {
	const actionType = ActionTypes.USER_CREATE_SUBSCRIPTION_BY_COUPON;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/subscriptions`;
		postData(url, {
			subscription_type: "community",
			...couponAndPostalCode,
		})
			.then((response) => {
				if (response.status < 300) {
					dispatch({
						type: actionType,
						payload: {success: true},
					});
				} else {
					dispatch({
						type: actionType,
						payload: {success: false, error: response.data},
					});
				}
			})
			.catch((error) => {
				dispatch({
					type: actionType,
					payload: {success: false, error},
				});
			});
	};
};

export const checkEmail = (body, {handling}) => {
	const actionType = ActionTypes.USER_CHECK_EMAIL;
	return () => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/accounts/checkEmail`;
		postData(url, body)
			.then((response) => {
				handling.handleResponse(response);
			})
			.catch((error) => {
				handling.handleResponse(error);
			});
	};
};

export const createNewUser = (body, {handling}) => {
	const actionType = ActionTypes.USER_CREATE_NEW_USER;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/people`;
		postData(url, body)
			.then((response) => {
				if (response.status < 300) {
					handling.success(response);
				} else {
					handling.error(response);
				}
				dispatch({
					type: actionType,
				});
			})
			.catch((error) => {
				handling.error(error);
			});
	};
};

export const createAdminRequest = (body, {handling}) => {
	const actionType = ActionTypes.USER_CREATE_ADMIN_REQUEST;
	return () => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/adminRequests`;
		postData(url, body)
			.then((response) => {
				if (response.status < 300) {
					handling.success();
				}
			})
			.catch((error) => {
				handling.error(error);
			});
	};
};

export const closeAdminRequest = (request, {handling}) => {
	const actionType = ActionTypes.USER_CLOSE_ADMIN_REQUEST;
	return () => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/adminRequests/${request}/close`;
		postDataJSON(url, {
			response: "Close request",
		})
			.then((response) => {
				if (response.status < 300) {
					handling.success();
				} else {
					handling.error(response);
				}
			})
			.catch((error) => {
				handling.error(error);
			});
	};
};

export const createStudentAndAddToPaidSubscription = (domain, student) => {
	const actionType = ActionTypes.USER_ADD_STUDENT_TO_PAID_SUBSCRIPTION;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/people`;
		postData(url, {
			type: "student",
			caregiver_ids: [student.caregiver_id],
			name: student.name,
		})
			.then((response) => {
				SchoolActions.updateCurrentStudent(response.data.id);
				const url = `${get_url()}wp-json/sprig/v2/subscriptions/${domain}/students`;
				putData(url, {
					student_id: response.data.id,
					domain_id: domain,
					grade_id: student.grade_id,
					curriculum_id: student.curriculum_id,
				})
					.then((subscriptionResponse) => {
						dispatch({
							type: actionType,
							payload: {
								response: subscriptionResponse,
								student: {...student, domain, student_id: response.data.id},
							},
						});
					})
					.catch((error) => {
						dispatch({
							type: actionType,
							payload: {error, student: {...student, domain}},
						});
					});
			})
			.catch((error) => {
				dispatch({
					type: actionType,
					payload: {error, student: {...student, domain}},
				});
			});
	};
};

export const checkoutSubscription = (request) => {
	const actionType = ActionTypes.USER_CHECKOUT_SUBSCRIPTION;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/checkout`;
		postData(url, request)
			.then((response) => {
				dispatch({
					type: actionType,
					payload: {success: true, response: response.data.checkout_url},
				});
			})
			.catch((error) => {
				dispatch({
					type: actionType,
					payload: {success: false, error},
				});
			});
	};
};

export const getSubscriptionProducts = (slug) => {
	const actionType = ActionTypes.USER_GET_SUBSCRIPTION_PRODUCTS;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/wc/v3/products?${
			slug ? `&slug=${slug}` : ``
		}`;
		getData(url)
			.then((response) => {
				const subscriptionProduct = response.find(
					(product) => product.slug === "sprig-language"
				);
				const productIds = subscriptionProduct.variations;
				if (productIds?.length) {
					productIds.forEach((productId) => {
						const getProductUrl = `${get_url()}wp-json/wc/v3/products/${productId}`;
						getData(getProductUrl)
							.then((response) => {
								if (response?.hasOwnProperty("id")) {
									dispatch({
										type: actionType,
										payload: {
											success: true,
											response,
											productsCount: productIds.length,
										},
									});
								} else {
									dispatch({
										type: actionType,
										payload: {
											success: false,
											error: i18n.t("error.portal_failed_to_initialize"),
										},
									});

									ErrorReporterActions.addToQueue({
										actionType,
										error: i18n.t("error.portal_failed_to_initialize"),
									});
								}
							})
							.catch((error) => {
								dispatch({
									type: actionType,
									payload: {success: false, error},
								});

								ErrorReporterActions.addToQueue({
									actionType,
									error,
								});
							});
					});
				}
			})
			.catch((error) => {
				dispatch({
					type: actionType,
					payload: {success: false, error},
				});

				ErrorReporterActions.addToQueue({
					actionType,
					error,
				});
			});
	};
};

export const resetPassword = (request) => {
	const actionType = ActionTypes.USER_RESET_PASSWORD;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/resetPassword`;
		const {rp_key, username, pass, action} = request;
		const body = {rp_key, username, pass};
		postDataNoAuth(url, body)
			.then((response) => {
				if (response.status === 200) {
					dispatch({
						type: actionType,
						payload: {success: true, action},
					});
				} else {
					dispatch({
						type: actionType,
						payload: {
							success: false,
							error: response.data.message,
							errorCode: response.data.code,
						},
					});
				}
			})
			.catch((error) => {
				dispatch({
					type: actionType,
					payload: {
						success: false,
						error: error.data.message,
						errorCode: error.data.code,
					},
				});
			});
	};
};

export const onLogoutCompleted = () => ({
	type: ActionTypes.USER_LOGOUT_COMPLETED,
});

export const submitFeedback = ({
	activityEnjoymentRating = 0,
	easeOfUseRating = 0,
	message = "",
}) => {
	const actionType = ActionTypes.USER_SUBMIT_FEEDBACK;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/gf/v2/entries`;
		const requestBody = {
			form_id: 4,
		};
		if (message) requestBody["2"] = message;
		if (easeOfUseRating) requestBody["3"] = easeOfUseRating;
		if (activityEnjoymentRating) requestBody["4"] = activityEnjoymentRating;
		postData(url, requestBody)
			.then((response) => {
				if (response.status < 300) {
					dispatch({
						type: actionType,
						payload: Date.now(),
					});

					ActivityMonitorActions.addToQueue({
						type: ACTIVITY_MONITOR_ACTIONS.SUCCESS,
						title: i18n.t("feedback.feedback_submission_title"),
						message: i18n.t("feedback.feedback_submission_success"),
					});
				} else {
					ErrorReporterActions.addToQueue({
						actionType,
						error: response?.data,
					});

					dispatch({
						type: actionType,
					});
				}
			})
			.catch((error) => {
				ErrorReporterActions.addToQueue({
					actionType,
					error,
				});

				dispatch({
					type: actionType,
				});
			});
	};
};

export const resetFeedback = () => ({
	type: ActionTypes.USER_RESET_FEEDBACK,
});

export const getPortalRelatedFormOptions = (slug, {handling}) => {
	const actionType = ActionTypes.USER_GET_PORTAL_RELATED_FORM_OPTIONS;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/formOptions?form_slug=${slug}`;
		getData(url)
			.then((response) => {
				dispatch({
					type: actionType,
				});
				handling.success(response);
			})
			.catch((error) => {
				ErrorReporterActions.addToQueue({
					actionType,
					error,
				});

				dispatch({
					type: actionType,
				});
			});
	};
};
