export const STYLE_CONSTANT = {
	COMPONENT: {
		BUTTON: "btn",
		DROPDOWN: "dropdown",
		DROPDOWN_WRAPPER: "dropdown-wrapper",
		ICON: "icon",
		INPUT: {
			RADIO: "input-radio",
		},
		LAYOUT: {
			PAGE: "page-layout",
		},
		NAVIGATION: {
			PRIMARY: "navigation-primary",
			SECONDARY: "navigation-secondary",
			SIDEBAR: "navigation-sidebar",
			ITEM: "navigation-item",
			ITEM_LIST: "navigation-item-list",
		},
	},
	SIZE: {
		SMALL: "sm",
		MEDIUM: "md",
		LARGE: "lg",
	},
	TYPE: {
		PRIMARY: "primary",
		SECONDARY: "secondary",
		ALT: "alt",
	},
	USER_TYPE: {
		ADMIN: "admin",
		CAREGIVER: "caregiver",
		EDUCATOR: "educator",
	},
};

export type TYPE_OPTIONS = "primary" | "secondary" | "alt" | "text";
export type SIZE_OPTIONS = "xsm" | "sm" | "md" | "lg";
export type USER_TYPE_OPTIONS = "admin" | "caregiver" | "educator";
