import * as ActionTypes from "../Redux/ActionTypes";
import {CreateReducer} from "../Redux/Utilities";
import {ACTIVITY_USER, DATA_IMPORT} from "../Scripts/Constants";

const initialState = {
	admin: {
		userSearch: "",
		dataImport: {
			limits: {
				schoolsLimit: null,
				classroomsLimit: {
					math: 0,
					oralLanguage: 0,
					reading: 0,
					noClassesLimit: false,
				},
			},
			schools: [],
			schoolId: 0,
			classroomId: 0,
			generateTemplateButtonDisabled: true,
		},
	},
	caregiver: {
		activitiesFilterBy: ACTIVITY_USER.STATUS.RECOMMENDED,
		signupType: {},
		isActivitiesList: false,
		activitiesListCurrentCard: null,
	},
	educator: {
		assessmentResults: [],
		isActivitiesList: false,
		activitiesListCurrentCard: null,
	},
	reading: {
		circleChart: {
			currentTopicId: null,
			currentSubTopicId: null,
		},
		circleChartFilterSkillsByGrade: {},
		initialWelcomeModal: true,
	},
	public: {
		flyoutNavigation: false,
	},
};

const getLimits = (limit, type, state) => {
	const {limits} = state.admin.dataImport;
	switch (type) {
		case DATA_IMPORT.LIMIT.SCHOOL: {
			return {
				...limits,
				schoolsLimit: limit,
			};
		}
		case DATA_IMPORT.LIMIT.CLASSROOM.ORAL_LANGUAGE: {
			return {
				...limits,
				classroomsLimit: {
					...limits.classroomsLimit,
					oralLanguage: limit,
				},
			};
		}
		case DATA_IMPORT.LIMIT.CLASSROOM.MATH: {
			return {
				...limits,
				classroomsLimit: {
					...limits.classroomsLimit,
					math: limit,
				},
			};
		}
		case DATA_IMPORT.LIMIT.CLASSROOM.READING: {
			return {
				...limits,
				classroomsLimit: {
					...limits.classroomsLimit,
					reading: limit,
				},
			};
		}
		case DATA_IMPORT.LIMIT.NO_LIMIT: {
			return {
				...limits,
				classroomsLimit: {
					...limits.classroomsLimit,
					classroomsLimit: {
						...limits.classroomsLimit,
						noClassesLimit: limit,
					},
				},
			};
		}
		default:
			throw new Error(`Unsupported limit type: ${type}`);
	}
};

const shouldButtonDisabled = (schools) => {
	return schools.length
		? schools.some((school) => school.classes.length <= 0)
		: true;
};

export const PortalStateReducer = CreateReducer(initialState, {
	[ActionTypes.PORTAL_STATE_ADMIN_SET_USER_SEARCH]: (state, {payload}) => {
		return {
			...state,
			admin: {
				...state.admin,
				userSearch: payload,
			},
		};
	},
	[ActionTypes.PORTAL_STATE_ADMIN_IMPORT_SET_LIMIT]: (state, {payload}) => {
		const {limit, type} = payload;
		const limits = getLimits(limit, type, state);
		return {
			...state,
			admin: {
				...state.admin,
				dataImport: {
					...state.admin.dataImport,
					limits,
				},
			},
		};
	},
	[ActionTypes.PORTAL_STATE_ADMIN_IMPORT_ADD_SCHOOL]: (state, {payload}) => {
		const {school} = payload;
		const schools = [...state.admin.dataImport.schools, school];
		return {
			...state,
			admin: {
				...state.admin,
				dataImport: {
					...state.admin.dataImport,
					schools,
					schoolId: state.admin.dataImport.schoolId + 1,
					generateTemplateButtonDisabled: shouldButtonDisabled(schools),
				},
			},
		};
	},
	[ActionTypes.PORTAL_STATE_ADMIN_IMPORT_DELETE_SCHOOL]: (state, {payload}) => {
		const schools = [...state.admin.dataImport.schools];
		const {schoolToBeDeleted} = payload;
		const schoolToBeDeletedIndex = schools.findIndex(
			(school) => school.schoolId === schoolToBeDeleted
		);
		schools.splice(schoolToBeDeletedIndex, 1);
		return {
			...state,
			admin: {
				...state.admin,
				dataImport: {
					...state.admin.dataImport,
					schools,
					generateTemplateButtonDisabled: shouldButtonDisabled(schools),
				},
			},
		};
	},
	[ActionTypes.PORTAL_STATE_ADMIN_IMPORT_UPDATE_SCHOOL]: (state, {payload}) => {
		const schools = [...state.admin.dataImport.schools];
		const {schoolToBeUpdated, updatedSchool} = payload;
		const schoolToBeUpdatedIndex = schools.findIndex(
			(school) => school.schoolId === schoolToBeUpdated
		);
		schools.splice(schoolToBeUpdatedIndex, 1, updatedSchool);
		return {
			...state,
			admin: {
				...state.admin,
				dataImport: {
					...state.admin.dataImport,
					schools,
				},
			},
		};
	},
	[ActionTypes.PORTAL_STATE_ADMIN_IMPORT_ADD_CLASSROOM]: (state, {payload}) => {
		const {classroom, schoolId} = payload;
		const schools = [...state.admin.dataImport.schools];
		const schoolToBeUpdated = schools.find(
			(school) => school.schoolId === schoolId
		);
		const schoolToBeUpdatedIndex = schools.findIndex(
			(school) => school.schoolId === schoolId
		);
		if (schoolToBeUpdatedIndex >= 0) {
			schools[schoolToBeUpdatedIndex].classes = [
				...schoolToBeUpdated.classes,
				classroom,
			];
			return {
				...state,
				admin: {
					...state.admin,
					dataImport: {
						...state.admin.dataImport,
						schools,
						classroomId: state.admin.dataImport.classroomId + 1,
						generateTemplateButtonDisabled: shouldButtonDisabled(schools),
					},
				},
			};
		}
		return state;
	},
	[ActionTypes.PORTAL_STATE_ADMIN_IMPORT_DELETE_CLASSROOM]: (
		state,
		{payload}
	) => {
		const {classroomToBeDeleted, schoolId} = payload;
		const schools = [...state.admin.dataImport.schools];
		const schoolToBeUpdatedIndex = schools.findIndex(
			(school) => school.schoolId === schoolId
		);
		const classroomToBeDeletedIndex = schools[
			schoolToBeUpdatedIndex
		].classes.findIndex(
			(classroom) => classroom.classroomId === classroomToBeDeleted
		);
		if (schoolToBeUpdatedIndex >= 0) {
			schools[schoolToBeUpdatedIndex].classes.splice(
				classroomToBeDeletedIndex,
				1
			);
			return {
				...state,
				admin: {
					...state.admin,
					dataImport: {
						...state.admin.dataImport,
						schools,
						generateTemplateButtonDisabled: shouldButtonDisabled(schools),
					},
				},
			};
		}
		return state;
	},
	[ActionTypes.PORTAL_STATE_ADMIN_IMPORT_UPDATE_CLASSROOM]: (
		state,
		{payload}
	) => {
		const {classroomToBeUpdated, updatedClassroom, schoolId} = payload;
		const schools = [...state.admin.dataImport.schools];
		const schoolToBeUpdatedIndex = schools.findIndex(
			(school) => school.schoolId === schoolId
		);
		const classroomToBeUpdatedIndex = schools[
			schoolToBeUpdatedIndex
		].classes.findIndex(
			(classroom) => classroom.classroomId === classroomToBeUpdated
		);
		if (schoolToBeUpdatedIndex >= 0) {
			schools[schoolToBeUpdatedIndex].classes.splice(
				classroomToBeUpdatedIndex,
				1,
				updatedClassroom
			);
			return {
				...state,
				admin: {
					...state.admin,
					dataImport: {
						...state.admin.dataImport,
						schools,
					},
				},
			};
		}
		return state;
	},
	[ActionTypes.PORTAL_STATE_FLYOUT_NAVIGATION_TOGGLE]: (state) => {
		return {
			...state,
			public: {
				...state.public,
				flyoutNavigation: !state.public.flyoutNavigation,
			},
		};
	},
	[ActionTypes.PORTAL_STATE_CAREGIVER_SET_ACTIVITIES_FILTER_BY]: (
		state,
		{payload}
	) => {
		return {
			...state,
			caregiver: {
				...state.caregiver,
				activitiesFilterBy: payload,
			},
		};
	},
	[ActionTypes.PORTAL_STATE_EDUCATOR_UPDATE_ASSESSMENT_RESULT]: (
		state,
		{payload}
	) => {
		const educatorStates = {...state.educator};
		let assessmentResults = [...educatorStates.assessmentResults];
		const indexOfCurrentResult = assessmentResults.findIndex(
			(result) =>
				result.id === payload.id && result.domainId === payload.domainId
		);
		if (indexOfCurrentResult >= 0) {
			assessmentResults.splice(indexOfCurrentResult, 1);
		}
		assessmentResults = [
			...assessmentResults,
			{
				id: payload.id,
				domainId: payload.domainId,
				timestamp: payload.timestamp,
				assessment: payload.assessment,
			},
		];
		return {
			...state,
			educator: {
				...state.educator,
				assessmentResults,
			},
		};
	},
	[ActionTypes.PORTAL_STATE_CAREGIVER_UPDATE_SIGNUP_TYPE]: (
		state,
		{payload}
	) => {
		return {
			...state,
			caregiver: {
				...state.caregiver,
				signupType: payload,
			},
		};
	},
	[ActionTypes.PORTAL_STATE_SET_ACTIVITIES_LIST]: (state, {payload}) => {
		const {isActivitiesList, portal} = payload;
		return {
			...state,
			[portal]: {
				...state[portal],
				isActivitiesList,
			},
		};
	},
	[ActionTypes.PORTAL_STATE_SET_ACTIVITIES_LIST_CURRENT_CARD]: (
		state,
		{payload}
	) => {
		const {activitiesListCurrentCard, portal} = payload;
		return {
			...state,
			[portal]: {
				...state[portal],
				activitiesListCurrentCard,
			},
		};
	},
	[ActionTypes.PORTAL_STATE_READING_CIRCLE_CHART]: (state, {payload}) => {
		const {currentTopicId, currentSubTopicId} = payload;
		return {
			...state,
			reading: {
				...state.reading,
				circleChart: {
					currentTopicId,
					currentSubTopicId,
				},
			},
		};
	},
	[ActionTypes.PORTAL_STATE_READING_INITIAL_WELCOME_MODAL]: (
		state,
		{payload}
	) => {
		return {
			...state,
			reading: {
				...state.reading,
				initialWelcomeModal: payload,
			},
		};
	},
	[ActionTypes.PORTAL_STATE_READING_CIRCLE_CHART_FILTER_SKILLS_BY_GRADE]: (
		state,
		{payload}
	) => {
		return {
			...state,
			reading: {
				...state.reading,
				circleChartFilterSkillsByGrade: payload,
			},
		};
	},
});
