import React from "react";

interface TableBodyProps {
	children: JSX.Element[];
	id?: string;
}

export const TableBody = ({children, ...rest}: TableBodyProps) => (
	<tbody {...rest}>{children}</tbody>
);
