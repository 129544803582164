import "../../../../../Stylesheets/css/scss/Components/Portal/Public/Charts/Tooltip.css";

import React from "react";

export const Tooltip = ({data}) => {
	return (
		<div className="d3-tooltip">
			<div className="tooltip-arrow" />
			{data.key}
		</div>
	);
};
