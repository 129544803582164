import i18n from "i18next";

import {
	HOLISTIC_MATH_EXPECTATION_LEVEL,
	ORAL_LANGUAGE_RUBRIC_BREAKDOWN,
	STUDENT_EXPECTATION_LEVEL,
} from "../Constants";
import {getMonthIdByDate, getMonthName} from "../Date";
import {DOMAIN_ORAL_LANGUAGE} from "../Domain";

export const getExpectationStatus = (level, expectedLevel) => {
	if (level > expectedLevel) return "above";
	else if (level < expectedLevel) return "below";
	return "at-expected";
};

export const getStudentLevel = (breakdown, rubric) => {
	if (breakdown) {
		if (rubric) {
			const validBreakdown = ORAL_LANGUAGE_RUBRIC_BREAKDOWN[rubric].filter(
				(item) => !!breakdown[item]
			);
			return (
				validBreakdown?.reduce(
					(total, outcome) => total + breakdown[outcome],
					0
				) / validBreakdown?.length
			);
		}
		const breakdownKeys = Object.keys(breakdown);
		return (
			breakdownKeys.reduce((total, key) => total + breakdown[key], 0) /
			breakdownKeys?.length
		);
	}

	return i18n.t("error.na");
};

export const getStudentMathLevel = (breakdown, rubric) => {
	if (breakdown) {
		if (rubric) {
			return breakdown[rubric];
		}
		const breakdownKeys = Object.keys(breakdown);
		const validBreakdown = breakdownKeys.filter((item) => !!breakdown[item]);
		return (
			validBreakdown.reduce((total, key) => total + breakdown[key], 0) /
			validBreakdown?.length
		);
	}

	return i18n.t("error.na");
};

export const getClassroomGrade = (grades, students) => {
	if (grades?.length && students?.length) {
		return grades.find((grade) => grade.id === students[0]?.grade_id)?.slug;
	}

	return null;
};

export const getExpectedLevel = (
	gradeSlug,
	milestoneId,
	returnRange = false,
	isHolisticMath = false
) => {
	if (isHolisticMath) {
		const expectationObject = HOLISTIC_MATH_EXPECTATION_LEVEL.milestones.find(
			(studentMilestone) => studentMilestone.milestone_id.includes(milestoneId)
		);
		const expectation = expectationObject.expectation;
		const monthIndex = expectationObject.milestone_id.findIndex(
			(studentMilestone) => studentMilestone === milestoneId
		);
		return returnRange ? expectation : expectation[monthIndex];
	}
	const expectation = STUDENT_EXPECTATION_LEVEL[gradeSlug].milestones.find(
		(studentMilestone) => studentMilestone.milestone_id.includes(milestoneId)
	).expectation;
	return returnRange ? expectation : expectation[0];
};

export const constructDataForStudents = ({
	students,
	report,
	milestone,
	rubric,
	taxonomies,
	domain,
	isHolisticMath = false,
}) => {
	if (!students?.length || !report || (!milestone && milestone !== 0))
		return [];
	const {subitems} = report;
	if (subitems?.length) {
		const classGradeSlug = getClassroomGrade(taxonomies?.grade, students);
		return subitems
			.map((subitem) => {
				if (subitem.type === "person") {
					const student = students.find((student) => subitem.id === student.id);
					if (student) {
						const breakdown = subitem.milestones.find(
							(subitemMilestone) => subitemMilestone.milestone_id === milestone
						);
						return {
							id: student.id,
							name: student.name,
							level:
								domain === DOMAIN_ORAL_LANGUAGE
									? getStudentLevel(breakdown?.breakdown, rubric)
									: getStudentMathLevel(breakdown?.breakdown, rubric),
							expectedLevel: isHolisticMath
								? getExpectedLevel(
										classGradeSlug,
										milestone,
										false,
										isHolisticMath
								  )
								: classGradeSlug
								? getExpectedLevel(
										classGradeSlug,
										milestone,
										false,
										isHolisticMath
								  )
								: null,
							activityCompletion: student.total_completed_activity_count,
						};
					}
				}

				return null;
			})
			.filter((student) => student);
	}

	return [];
};

export const createMilestoneReportData = (data, options = {}) => {
	const {selections, taxonomies, students, domain, isHolisticMath} = options;
	const chartData = [];

	const classGradeSlug = getClassroomGrade(taxonomies?.grade, students);

	const expectedLevelData = data.milestones.map((milestone) => {
		const expectedLevel = getExpectedLevel(
			classGradeSlug,
			milestone.milestone_id,
			false,
			isHolisticMath
		);
		if (!expectedLevel) return {};
		const dataPoint = {
			x: getMonthName(milestone.start),
			y: expectedLevel,
			current: milestone.milestone_id === selections?.milestone,
			milestone: milestone.milestone_id,
		};

		if (!selections?.selectedStudents?.length) {
			const allStudents = constructDataForStudents({
				students,
				milestone: milestone.milestone_id,
				report: data,
				rubric: selections?.rubric,
				domain,
				isHolisticMath,
			});
			if (allStudents?.length) {
				const allStudentsBelowExpected = allStudents.filter(
					(student) => student.level < expectedLevel
				);
				const allStudentsAboveExpected = allStudents.filter(
					(student) => student.level > expectedLevel
				);

				dataPoint.deviation = {
					below: {
						value: Math.min(
							...allStudentsBelowExpected.map((student) => student.level)
						),
						text: `${allStudentsBelowExpected?.length} Students`,
					},
					above: {
						value: Math.max(
							...allStudentsAboveExpected.map((student) => student.level)
						),
						text: `${allStudentsAboveExpected?.length} Students`,
					},
				};
			}

			if (
				selections?.deviation &&
				selections?.milestone === milestone.milestone_id
			) {
				dataPoint.currentDeviation = selections.deviation;
			}
		}

		return dataPoint;
	});

	const studentLevelData = selections?.selectedStudents?.length
		? data.milestones.map((milestone) => {
				const expectedLevel = getExpectedLevel(
					classGradeSlug,
					milestone.milestone_id,
					false,
					isHolisticMath
				);
				const allStudents = constructDataForStudents({
					students,
					milestone: milestone.milestone_id,
					report: data,
					rubric: selections?.rubric,
					domain,
					isHolisticMath,
				});

				const selectedStudent = allStudents.find(
					(student) => student.id === selections?.selectedStudents[0].id
				);

				return selectedStudent
					? {
							x: getMonthName(milestone.start),
							y: selectedStudent.level,
							current: milestone.milestone_id === selections?.milestone,
							class: getExpectationStatus(selectedStudent.level, expectedLevel),
					  }
					: null;
		  })
		: null;

	if (expectedLevelData) chartData.push(expectedLevelData);
	if (studentLevelData) chartData.push(studentLevelData);

	return chartData;
};

export const getCurrentMilestone = (milestones) => {
	if (milestones?.length) {
		const dateNow = new Date();
		const currentMilestone = milestones.find(
			(milestone) => getMonthIdByDate(milestone.start) === dateNow.getMonth()
		);
		if (currentMilestone) {
			return currentMilestone.milestone_id;
		}
		return 0;
	}

	return 0;
};
