import {UserActions} from "../Actions";
import {ROLES} from "./Constants";
import {get_url, getAuth} from "./Fetch";
import {UserMeModel} from "./PublicInterfaces";

export function validateToken() {
	return new Promise((resolve, reject) => {
		const auth = getAuth();
		const url = `${get_url()}wp-json/jwt-auth/v1/token/validate`;
		const httpRequest = "POST";
		fetch(url, {
			method: httpRequest,
			mode: "cors",
			headers: {
				Authorization: auth,
			},
		})
			.then((res) => {
				if (res.ok) {
					return res.json();
				} else {
					let rejectMessage = `Request rejected with status ${res.status}`;
					reject(rejectMessage);
				}
			})
			.then((token) => {
				if (token && token.code === "jwt_auth_valid_token") {
					resolve(true);
				} else {
					resolve(false);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	});
}

export function getUser() {
	return new Promise<UserMeModel>((resolve, reject) => {
		const auth = getAuth();
		const httpRequest = "GET";
		const url = `${get_url()}wp-json/sprig/v2/me`;
		fetch(url, {
			method: httpRequest,
			mode: "cors",
			headers: {
				Authorization: auth,
			},
		})
			.then((res) => {
				if (res.ok) {
					return res.json();
				} else {
					let rejectMessage = `Request rejected with status ${res.status}`;
					reject(rejectMessage);
				}
			})
			.then((user) => {
				resolve(user);
			})
			.catch((err) => {
				console.log(err);
			});
	});
}

export function addUserValidation(items) {
	let is_valid = true;
	switch (items.type) {
		case "student":
			if (!items.name) {
				is_valid = false;
			} else if (items.gender === 0) {
				is_valid = false;
			} else if (items.is_aboriginal === 0) {
				is_valid = false;
			} else if (items.class_grades[0].class === 0) {
				is_valid = false;
			} else if (items.class_grades[0].grade === 0) {
				is_valid = false;
			}
			break;

		case "teacher":
			if (!items.name) {
				is_valid = false;
			} else if (!items.email) {
				is_valid = false;
			} else if (items.teacher_of.class_ids === 0) {
				is_valid = false;
			}

			break;
		case ROLES.PRINCIPAL:
			if (!items.name) {
				is_valid = false;
			} else if (!items.email) {
				is_valid = false;
			} else if (items.admin_of.school_ids.length === 0) {
				is_valid = false;
			}
			break;
		default:
			throw new Error(`Unsupported role type ${items.type}`);
	}

	return is_valid;
}

export const handleUserUpdated = () => {
	window.location.reload();
};

export const handleLogout = () => {
	UserActions.handleUserLogout();
};
