import "./ActivitiesListItem.scss";

import htmlToText from "html-to-text";
import i18n from "i18next";
import React, {Component, Fragment} from "react";

import {ModalActions, PortalStateActions} from "../../../../Actions";
import {getCardHeaderBackgroundColor} from "../../../../Scripts/Activities";
import {
	MODAL_TYPES,
	OUTCOME_LEVEL,
	PORTALS,
} from "../../../../Scripts/Constants";
import {DOMAIN_MATH, DOMAIN_ORAL_LANGUAGE} from "../../../../Scripts/Domain";
import {
	ActivityModel,
	CompletedActivitiesModel,
	OutcomeTaxonomyItem,
	taxonomyItem,
} from "../../../../Scripts/PublicInterfaces";
import {getIconType} from "../../../../Scripts/Reading";
import {
	ActivityHeaderComponents,
	ActivityInfoTag,
} from "../../PublicComponents/Modal/Modals/Activity/ActivityModalWidget";

interface ActivitiesListItemProps {
	activity: ActivityModel;
	completions: CompletedActivitiesModel[] | {[activityId: number]: number};
	saved: boolean;
	student?: number;
	taxonomies: {
		duration: taxonomyItem[];
		places: taxonomyItem[];
		learningOutcomes: OutcomeTaxonomyItem[];
		generalOutcomes: OutcomeTaxonomyItem[];
		specificOutcomes: OutcomeTaxonomyItem[];
	};
	isActivitiesList?: boolean;
	isHiddenActivity?: boolean;
	filters: {};
	groups?: {}[];
	students?: {}[];
	portal: string;
	user?: {id: number};
	milestoneGroup?: {};
	isReadingCard?: boolean;
	hasClassroomFromOtherDomain?: boolean;
	currentDomain?: number;
}
interface ActivitiesListItemState {}

export class ActivitiesListItem extends Component<
	ActivitiesListItemProps,
	ActivitiesListItemState
> {
	getCompletion = (activity_id) => {
		const {completions, portal} = this.props;
		switch (portal) {
			case PORTALS.CAREGIVER: {
				if (Array.isArray(completions)) {
					const currentCompletion = completions?.find(
						(completion) => completion.activity_id === activity_id
					);
					return currentCompletion ? currentCompletion.completed_on.length : 0;
				}
				return 0;
			}
			case PORTALS.EDUCATOR: {
				const currentCompletion = completions?.[activity_id];
				return currentCompletion ? currentCompletion : 0;
			}
			default:
				throw new Error(
					`unknown portal type when getting completion: ${portal}`
				);
		}
	};

	handleActivityCardClick = () => {
		const {
			activity,
			student,
			isActivitiesList,
			saved,
			isHiddenActivity,
			filters,
			taxonomies,
			portal,
			students,
			groups,
			milestoneGroup,
			isReadingCard,
			hasClassroomFromOtherDomain,
			currentDomain,
		} = this.props;
		const currentCompletion = this.getCompletion(activity.id);
		const cardBackgroundStyle = getCardHeaderBackgroundColor({
			taxonomies,
			activity,
			filters,
		});
		if (isActivitiesList) {
			PortalStateActions.setActivitiesListCurrentCard({
				activitiesListCurrentCard: {
					activity,
					completions: currentCompletion,
					saved,
					cardBackgroundStyle,
				},
				portal,
			});
		} else
			ModalActions.showModal(
				{
					open: true,
					student,
					size: "md",
					role: portal,
					data: {
						activity,
						completions: currentCompletion,
						saved,
						isHiddenActivity,
						filters,
						students,
						groups,
						milestoneGroup,
						isReadingCard,
						hasClassroomFromOtherDomain,
						currentDomain,
					},
					keepInWindow: true,
					fullSizedOnSmallScreen: true,
				},
				MODAL_TYPES.ACTIVITY_MODAL_NEW
			);
	};

	customizedFunctionsForSave = () => {
		const {
			activity,
			saved,
			isActivitiesList,
			completions,
			taxonomies,
			filters,
			portal,
		} = this.props;
		if (isActivitiesList) {
			let currentCompletion;
			if (portal === PORTALS.EDUCATOR) {
				currentCompletion = completions[activity.id];
			} else if (portal === PORTALS.CAREGIVER) {
				if (Array.isArray(completions)) {
					const currentCompletionItem = completions.find(
						(completion) => completion.activity_id === activity.id
					);
					if (currentCompletionItem)
						currentCompletion = currentCompletionItem?.completed_on.length;
				}
			}
			PortalStateActions.setActivitiesListCurrentCard({
				activitiesListCurrentCard: {
					activity,
					completions: currentCompletion ? currentCompletion : 0,
					saved: !saved,
					cardBackgroundStyle: getCardHeaderBackgroundColor({
						taxonomies,
						activity,
						filters,
					}),
				},
				portal,
			});
		}
	};

	getCurrentIconType = () => {
		const {activity, taxonomies} = this.props;
		const readingOutcomeId = activity["level-1-outcomes"].filter(
			(outcomeId) => {
				return !!taxonomies.learningOutcomes.find(
					(learningOutcome) => learningOutcome.id === outcomeId
				);
			}
		);
		const currentOutcome = taxonomies.learningOutcomes.find(
			(learningOutcome) => learningOutcome.id === readingOutcomeId[0]
		);

		if (currentOutcome) {
			const iconType = getIconType(currentOutcome.slug);
			return iconType;
		}
	};

	render() {
		const {
			saved,
			activity,
			student,
			taxonomies,
			filters,
			portal,
			user,
			isReadingCard,
			currentDomain,
		} = this.props;
		const cardBackgroundStyle = getCardHeaderBackgroundColor({
			taxonomies,
			activity,
			filters,
		});
		return (
			<div
				className="activity-card-wrapper"
				onClick={this.handleActivityCardClick}
			>
				<div
					className="activity-card animated fadeInUp delay-5s slow"
					name="activity-card"
				>
					<div className="activity-card-header" style={cardBackgroundStyle}>
						<ActivityHeaderComponents
							activity={activity}
							saved={saved}
							studentId={student}
							customizedFunctions={this.customizedFunctionsForSave}
							completions={this.getCompletion(activity.id)}
							role={portal}
							{...(portal === PORTALS.EDUCATOR ? {userId: user?.id} : {})}
							isReadingCard={isReadingCard}
							iconType={isReadingCard ? this.getCurrentIconType() : undefined}
						/>
					</div>
					<div className="activity-card-body">
						<div
							className={`activity-info${
								isReadingCard ? " on-reading-card" : ""
							}`}
						>
							<InfoTags
								activity={activity}
								taxonomies={taxonomies}
								currentDomain={currentDomain}
								isReadingCard={isReadingCard}
							/>
						</div>
						<div className="activity-card-content">
							<small className="nun-semibold">
								{htmlToText.fromString(activity.content.rendered)}
							</small>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const InfoTags = ({activity, taxonomies, currentDomain, isReadingCard}) => {
	const currentLearningOutcome = activity[OUTCOME_LEVEL.ONE].filter(
		(outcome) => {
			const currentLearningOutcome = taxonomies.learningOutcomes.find(
				(outcomeTaxonomy) => outcomeTaxonomy.id === outcome
			);
			return currentLearningOutcome ? outcome : null;
		}
	);
	const infoTags = [
		...(!isReadingCard
			? [
					{
						title: i18n.t("admin.where"),
						content: activity.places.map((place) => {
							return i18n.t(
								`activity_taxonomy_place.${
									taxonomies.places.find(
										(placeTaxonomy) => placeTaxonomy.id === place
									).slug
								}`
							);
						}),
					},
			  ]
			: []),
		{
			title: isReadingCard
				? i18n.t("reading.learning_topic")
				: i18n.t("activity_taxonomies.learning_outcome"),
			content: currentLearningOutcome.map((outcome) => {
				return i18n.t(
					`domain.${
						(currentDomain ? currentDomain : activity.domains[0]) ===
						DOMAIN_MATH
							? "math_outcome"
							: (currentDomain ? currentDomain : activity.domains[0]) ===
							  DOMAIN_ORAL_LANGUAGE
							? "oral_language_outcome"
							: "reading_outcome"
					}.${
						taxonomies?.learningOutcomes.find(
							(outcomeTaxonomy) => outcomeTaxonomy.id === outcome
						).slug
					}`
				);
			}),
		},
		{
			title: i18n.t("activity_taxonomies.duration"),
			content: activity.durations.map((durationItem) => {
				return i18n.t(
					`activity_taxonomy_duration.${
						taxonomies.duration.find(
							(durationTaxonomy) => durationTaxonomy.id === durationItem
						).slug
					}`
				);
			}),
		},
	];
	return (
		<Fragment>
			{infoTags.map((item) => (
				<ActivityInfoTag item={item} key={item.title} />
			))}
		</Fragment>
	);
};
