import i18n from "i18next";
import React from "react";

import {Icon} from "../Icons";

interface FormRatingProps {
	id: string | number;
	rating: number;
	numberOfRatings?: number;
	onSelect: (target: {id: number | string; value: number}) => void;
	label: string;
}

export const FormRating = ({
	id,
	rating = 0,
	numberOfRatings = 5,
	onSelect,
	label = "",
	...rest
}: FormRatingProps) => {
	const getText = (rating) => {
		const highestRating = numberOfRatings - 1;
		switch (rating) {
			case 0:
				return i18n.t("feedback.nope");
			case highestRating:
				return i18n.t("feedback.absolutely");
			default:
				return null;
		}
	};

	return (
		<div className="form-rating" id={id.toString()} {...rest}>
			<div className="rating-label">{label}</div>
			<div className="rating-items">
				{[...Array(numberOfRatings).keys()].reverse().map((index) => (
					<div className="rating-item" key={`rating-${id}-${index}`}>
						<div
							onClick={() => onSelect({id, value: index + 1})}
							className={`rating-icon${
								rating && index + 1 <= rating ? " selected" : ""
							}`}
						>
							<Icon type="faStar" />
						</div>
						<div className="rating-label">{getText(index)}</div>
					</div>
				))}
			</div>
		</div>
	);
};
