import {applyMiddleware, createStore} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import {persistStore} from "redux-persist";
import thunkMiddleware from "redux-thunk";

import RootReducer from "../Reducers";

export const ConfigureStore = (preloadedState) => {
	const middlewares = [thunkMiddleware];
	const composedEnhancers = composeWithDevTools(
		applyMiddleware(...middlewares)
	);

	const store = createStore(RootReducer, preloadedState, composedEnhancers);

	if (process.env.NODE_ENV !== "production" && module.hot) {
		module.hot.accept("../Reducers", () => store.replaceReducer(RootReducer));
	}
	const persistor = persistStore(store);
	return {store, persistor};
};
