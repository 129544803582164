import i18n from "i18next";
import React from "react";
import {Trans} from "react-i18next";

import {ADMIN_REQUEST_TYPE} from "../../../../../Scripts/Constants";

export const IssueOpened = ({notification}: {notification: Notification}) => {
	const {data} = notification;
	if (data) {
		const studentName =
			data.request_type === ADMIN_REQUEST_TYPE.ADD
				? `${data.new_first_name} ${data.new_last_name}`
				: data.student_name;
		const teacherName = `${data.new_first_name} ${data.new_last_name}`;
		const requestType = i18n.t(`admin.${data.request_type}`);
		return (
			<div className="col-10">
				<small className="nun-semibold">
					<Trans i18nKey="phrase.request_sent">
						{[
							<span className="nun-bold" key={requestType}>
								{{requestType}}
							</span>,
							<span className="nun-bold" key="studentName">
								{{
									name:
										data.request_type === ADMIN_REQUEST_TYPE.ADD
											? studentName
											: teacherName,
								}}
							</span>,
						]}
					</Trans>
				</small>
			</div>
		);
	}
	return null;
};
