import "../../../../Stylesheets/css/scss/Components/Portal/Public/Navigation/FlyoutNavigation.css";

import React, {useEffect} from "react";
import {Link, NavLink} from "react-router-dom";

import {PortalStateActions} from "../../../../Actions";
import {Icon, IconType} from "../../../Public";

interface FlyoutNavigationProps {
	open: boolean;
	title?: {
		icon: IconType;
		label: string;
	};
	items: {label: string; id: string; to: string; icon: IconType}[];
	goBack: boolean;
	humburgerMenuRef: any;
	onSelect: () => void;
}

export const FlyoutNavigation = ({
	title,
	items,
	open,
	onSelect,
	goBack,
	humburgerMenuRef,
}: FlyoutNavigationProps) => {
	let flyOutNavigation;
	const OutsideClickHandler = (event) => {
		if (
			open &&
			flyOutNavigation &&
			!flyOutNavigation.contains(event.target) &&
			!humburgerMenuRef.contains(event.target)
		) {
			PortalStateActions.flyoutNavigationToggle();
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", OutsideClickHandler);
		return function cleanup() {
			document.removeEventListener("mousedown", OutsideClickHandler);
		};
	});

	return (
		<div
			className={`col-sm-12 col-md-4 position-fixed flyout-navigation-component${
				open ? " open" : ""
			}`}
			ref={(node) => (flyOutNavigation = node as HTMLDivElement)}
		>
			{title ? (
				<div
					className={`flyout-navigation-header ${
						goBack ? " go-back-link" : ""
					}`}
				>
					{goBack && (
						<Link className="btn flyout-link" to="/portal">
							<Icon type="faArrowLeft" iconWrapperClassName="d-inline" />
						</Link>
					)}
					{title.icon ? <Icon type={title.icon as IconType} /> : null}
					<h4 className="text-secondary">{title.label ? title.label : null}</h4>
				</div>
			) : null}
			<ul className="flyout-navigation-items">
				{items &&
					items.map((item) => (
						<li
							key={`flyout-navigation-item-${item.id}`}
							className="flyout-navigation-item"
						>
							<NavLink
								activeClassName="active"
								id={item.id}
								to={item.to}
								onClick={onSelect}
							>
								{item.icon ? <Icon type={item.icon} /> : null}
								<span>{item.label ? item.label : null}</span>
							</NavLink>
						</li>
					))}
			</ul>
		</div>
	);
};
