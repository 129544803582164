import i18n from "i18next";
import React, {useState} from "react";
import ReactTooltip from "react-tooltip";

import {Icon} from "../../../Public";

interface EditableInputProps {
	label: string;
	footer?: string;
	disabled?: boolean;
	id: string;
	buttonId?: string;
	inputId?: string;
	type?: string;
	className?: string;
	onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
	name?: string;
	value?: string;
	placeholder?: string;
	defaultValue?: string;
}

export const EditableInput = ({
	label,
	footer,
	disabled,
	buttonId,
	inputId,
	...rest
}: EditableInputProps) => {
	const [editable, setEditable] = useState(false);
	const toggleEdit = () => {
		setEditable(!editable);
	};
	return (
		<div className="form-group mb-4">
			<small className="mb-0">
				<label htmlFor={rest.id}>{label}</label>
			</small>
			<div className="input-group">
				<input {...rest} disabled={!editable || disabled} id={inputId} />
				{!disabled && (
					<div className="input-group-append">
						<button
							className="btn btn-outline-secondary edit-field-button"
							type="button"
							onClick={toggleEdit}
							data-tip={i18n.t("admin.edit")}
							id={buttonId}
						>
							<Icon type="faEdit" className="no-event text-muted" size="1x" />
						</button>
					</div>
				)}
			</div>
			<small className="form-text text-muted">{footer}</small>
			<ReactTooltip effect="solid" />
		</div>
	);
};
