import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";

import en from "./languages/en";

/*
    1) detector will go grab the preferred browser lang and attempt to use if a file exists, fallback is "en"
    2) react-i18next is utilized to wrap our components when necessary with our context
    3) fr is not yet supported (will default to english), just used as a template/testing functionality
    4) Plural docs: https://www.i18next.com/translation-function/plurals

    As of v10 the supported ways to utilize i18n is as follows:
    1) import i18n from 'i18next'; (inside any component) and then call i18n.t("key value here")
    2) import { withTranslation } from 'react-i18next'; and then wrap your component.
        Ex. export default withTranslation()(MyComponent); (on last line)
        MyComponent will then have access to i18n through props, so, this.props.t("key value here")
*/

const resources = {
	en: {
		translations: en,
	},
};

i18n
	.use(detector)
	.use(initReactI18next)
	.init({
		resources,
		fallbackLng: "en",
		ns: ["translations"],
		defaultNS: "translations",
		debug: true,
		keySeparator: ".",
		interpolation: {
			escapeValue: false,
		},
		react: {
			wait: true,
		},
	});

export default i18n;
