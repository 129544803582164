import i18n from "i18next";

import {ADD_EDIT_USER, ADMIN_REQUEST_TYPE} from "./Constants";

export const renderChosenIdentity = (identity: string | boolean | null) => {
	switch (identity) {
		case ADD_EDIT_USER.CHOOSE:
			return i18n.t("admin.choose_indigenous_identity");
		case true:
			return i18n.t("common.yes");
		case false:
			return i18n.t("common.no");
		case null:
			return i18n.t("admin.not_specified");
		case undefined:
			return i18n.t("admin.not_specified");
		default:
			throw new Error(`Unsupported identity type ${identity}`);
	}
};

export const renderChosenRequestType = (requestType: string) => {
	switch (requestType) {
		case ADMIN_REQUEST_TYPE.EDIT:
			return i18n.t("admin.edit_student");
		case ADMIN_REQUEST_TYPE.REMOVE:
			return i18n.t("admin.remove_student");
		default:
			return i18n.t("admin.choose_request_type");
	}
};

export const renderChosenGender = (gender: string | null) => {
	switch (gender) {
		case ADD_EDIT_USER.CHOOSE:
			return i18n.t("admin.choose_gender");
		case ADD_EDIT_USER.VALUE.MALE:
			return i18n.t("admin.gender_male");
		case ADD_EDIT_USER.VALUE.FEMALE:
			return i18n.t("admin.gender_female");
		case null:
			return i18n.t("admin.not_specified");
		case undefined:
			return i18n.t("admin.not_specified");
		default:
			throw new Error(`Unsupported gender type ${gender}`);
	}
};

export const getIdentity = (identity: string) => {
	switch (identity) {
		case ADD_EDIT_USER.VALUE.YES:
			return true;
		case ADD_EDIT_USER.VALUE.NO:
			return false;
		case ADD_EDIT_USER.VALUE.NOT_SPECIFIED:
			return null;
		default:
			throw new Error(`Unsupported identity type ${identity}`);
	}
};

export const getGender = (gender: string) => {
	switch (gender) {
		case ADD_EDIT_USER.VALUE.MALE:
			return ADD_EDIT_USER.VALUE.MALE;
		case ADD_EDIT_USER.VALUE.FEMALE:
			return ADD_EDIT_USER.VALUE.FEMALE;
		case ADD_EDIT_USER.VALUE.NOT_SPECIFIED:
			return null;
		default:
			throw new Error(`Unsupported identity type ${gender}`);
	}
};
