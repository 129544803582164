import i18n from "i18next";
import React from "react";
import {Trans} from "react-i18next";
import {Link} from "react-router-dom";

import {PORTALS} from "../../../../../../../Scripts/Constants";
import {DOMAIN_MATH} from "../../../../../../../Scripts/Domain";
import {SingleMathTask, SingleMathTaskTab} from ".";

interface MathResultsActivitiesTabProps {
	page: number;
	resultTabs: MathResultsActivitiesSingleTab[];
	classroomId?: number;
	studentId: number;
	competencies: Competency[];
	role: string;
	closeModal: () => void;
}

interface MathResultsActivitiesSingleTab {
	page: number;
	tabs: SectionTab[];
}

export const MathResultsActivitiesTab = ({
	page,
	resultTabs,
	competencies,
	role,
	classroomId,
	studentId,
	closeModal,
}: MathResultsActivitiesTabProps) => {
	const tab = resultTabs.find((tab) => tab.page === page);
	return (
		<div className="mb-2">
			{tab &&
				tab.tabs.map((section) => (
					<MathProcessSection
						classroomId={classroomId}
						competencies={competencies}
						role={role}
						section={section}
						key={section.slug}
						studentId={studentId}
						closeModal={closeModal}
					/>
				))}
		</div>
	);
};

interface MathProcessSectionModel {
	section: SectionTab;
	competencies: Competency[];
	role: string;
	classroomId?: number;
	studentId: number;
	closeModal: () => void;
}

interface SectionTab {
	tabs: SingleMathTaskTab[];
	title: string;
	slug: string;
	background: string;
}

interface Competency {
	slug: string;
	domain: number;
	id: number;
}

const MathProcessSection = ({
	section,
	competencies,
	role,
	classroomId,
	studentId,
	closeModal,
}: MathProcessSectionModel) => {
	const getLinkTo = () => {
		const process = competencies.find(
			(competency) =>
				competency.slug === section.slug && competency.domain === DOMAIN_MATH
		);
		if (process) {
			switch (role) {
				case PORTALS.EDUCATOR:
					return {
						pathname: `/portal/classroom/${classroomId}/learningactivities/recommended`,
						state: {process: process.id, fromMathResultModal: true},
					};
				case PORTALS.CAREGIVER:
					return {
						pathname: `/portal/student/${studentId}/domain/${DOMAIN_MATH}/activities`,
						state: {process: process.id, fromMathResultModal: true},
					};
				default:
					throw new Error(`Unsupported role type: ${role}`);
			}
		}
	};

	const linkTo = getLinkTo();
	return (
		<div className="math-results-tab">
			<div
				className="math-results-rubric-title mb-4 text-center p-1"
				style={{backgroundColor: section.background}}
			>
				{section.title}
			</div>
			{section.tabs.map((tab) => (
				<SingleMathTask tab={tab} key={tab.task} />
			))}
			<div className="row view-activities justify-content-center">
				<div className="col-12 col-md-9 mt-2 text-center">
					<small>
						<Trans i18nKey="phrase.view_activities">
							{[<strong key="process">{{process: section.title}}</strong>]}
						</Trans>
					</small>
				</div>
				<div className="col-12 col-md-9 mt-2 text-center">
					{linkTo && (
						<Link
							className="btn text-white"
							style={{backgroundColor: section.background}}
							to={linkTo}
							onClick={closeModal}
						>
							{i18n.t("admin.view_activities")}
						</Link>
					)}
				</div>
			</div>
		</div>
	);
};
