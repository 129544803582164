import * as ActionTypes from "../Redux/ActionTypes";
import {CreateReducer} from "../Redux/Utilities";

const initialState = {
	title: "",
	description: null,
	places: [],
	languages: [],
	grades: [],
	difficulties: null,
	durations: null,
	domains: [],
	curriculums: [],
	curriculumId: 0,
	validated: false,
	communityOnly: false,
	materials: "",
};

const getSubSpecificOutcomes = (generalOutcome, specificOutcomes, tagging) => {
	const {specific_outcomes} = tagging;
	const fullSpecificOutcomes = specific_outcomes.filter((outcome) => {
		if (
			specificOutcomes.includes(outcome.id) &&
			generalOutcome === outcome.parent
		) {
			return outcome;
		}
		return null;
	});
	if (fullSpecificOutcomes) {
		const specificOutcomeIds = fullSpecificOutcomes.map(
			(specificOutcome) => specificOutcome.id
		);
		return specificOutcomeIds;
	}
	return [];
};

const getSubGeneralOutcomes = (
	learningOutcome,
	generalOutcomes,
	specificOutcomes,
	tagging
) => {
	const {general_outcomes} = tagging;
	const fullGeneralOutcomes = general_outcomes.filter((outcome) => {
		if (
			generalOutcomes.includes(outcome.id) &&
			learningOutcome === outcome.parent
		) {
			return outcome;
		}
		return null;
	});

	const currentGeneralOutcomes = fullGeneralOutcomes.map(
		(generalOutcome, index) => {
			return {
				id: index,
				generalOutcomeId: generalOutcome.id,
				specificOutcomes: getSubSpecificOutcomes(
					generalOutcome.id,
					specificOutcomes,
					tagging
				),
			};
		}
	);
	return currentGeneralOutcomes;
};

const getSubLearningOutcomes = (
	curriculum,
	learningOutcomes,
	generalOutcomes,
	specificOutcomes,
	tagging
) => {
	if (curriculum) {
		const {learning_outcomes} = tagging;
		const fullLearningOutcomes = learning_outcomes.filter((outcome) => {
			if (
				learningOutcomes.includes(outcome.id) &&
				outcome.curriculum === curriculum
			) {
				return outcome;
			}
			return null;
		});
		const currentLearningOutcomes = fullLearningOutcomes.map(
			(learningOutcome, index) => {
				return {
					id: index,
					learningOutcomeId: learningOutcome.id,
					generalOutcomes: getSubGeneralOutcomes(
						learningOutcome.id,
						generalOutcomes,
						specificOutcomes,
						tagging
					),
				};
			}
		);
		return currentLearningOutcomes;
	}
};

const emptyCurriculum = [
	{
		id: 0,
		learningOutcomes: [
			{
				id: 0,
				generalOutcomes: [
					{id: 0, specificOutcomes: [], generalOutcomeId: null},
				],
				learningOutcomeId: null,
			},
		],
		curriculumId: null,
		competencies: [],
	},
];

export const ActivityFormReducer = CreateReducer(initialState, {
	[ActionTypes.ACTIVITY_FORM_READ_ACTIVITY]: (state, {payload}) => {
		if (!payload) return state;
		const {activity, tagging} = payload;
		const {
			title,
			content,
			places,
			languages,
			grades,
			difficulties,
			durations,
			domains,
			curricula,
			competencies,
			materials,
			client_id,
		} = activity;
		const currentLearningOutcomes = activity["level-1-outcomes"];
		const currentGeneralOutcomes = activity["level-2-outcomes"];
		const currentSpecificOutcomes = activity["level-3-outcomes"];
		const curriculums = curricula.map((curriculum, index) => {
			return {
				id: index,
				learningOutcomes: getSubLearningOutcomes(
					curriculum,
					currentLearningOutcomes,
					currentGeneralOutcomes,
					currentSpecificOutcomes,
					tagging
				),
				curriculumId: curriculum,
				competencies,
			};
		});
		const communityOnly = client_id !== null;
		const formActivityTitle = title.rendered;
		const formActivityDescription = content.rendered;
		return {
			formActivityTitle,
			formActivityDescription,
			places,
			languages,
			grades,
			difficulties,
			durations,
			domains,
			curriculums,
			materials,
			communityOnly,
		};
	},
	[ActionTypes.ACTIVITY_FORM_NEW_ACTIVITY]: (state, {payload}) => {
		const curriculums = [...emptyCurriculum];
		return {
			...state,
			formActivityTitle: "",
			formActivityDescription: "",
			places: [],
			languages: [],
			grades: [],
			difficulties: [],
			durations: [],
			domains: [],
			curriculums,
			materials: "",
		};
	},
	[ActionTypes.ACTIVITY_FORM_EDIT_DOMAIN]: (state, {payload}) => {
		const currentSelectedCurriculums = [...state.curriculums];
		const updatedCurriculums = currentSelectedCurriculums.map(() => {
			const updatedCurriculum = {...emptyCurriculum[0]};
			return updatedCurriculum;
		});
		const curriculums = [...updatedCurriculums];
		return {
			...state,
			domains: [...payload],
			curriculums,
		};
	},
	[ActionTypes.ACTIVITY_FORM_EDIT_CURRICULUM]: (state, {payload}) => {
		const {newCurriculumId, id} = payload;
		const curriculums = [...state.curriculums];
		const updatedCurriculum = {
			id,
			learningOutcomes: [
				{
					id: 0,
					generalOutcomes: [
						{id: 0, specificOutcomes: [], generalOutcomeId: null},
					],
					learningOutcomeId: null,
				},
			],
			curriculumId: newCurriculumId,
			competencies: [],
		};
		const curriculumToBeUpdatedIndex = curriculums.findIndex(
			(curriculum) => curriculum.id === id
		);
		if (curriculumToBeUpdatedIndex >= 0) {
			curriculums.splice(curriculumToBeUpdatedIndex, 1, updatedCurriculum);
			const newCurriculums = [...curriculums];
			return {
				...state,
				curriculums: newCurriculums,
			};
		}
		return state;
	},
	[ActionTypes.ACTIVITY_FORM_EDIT_LEARNING_OUTCOME]: (state, {payload}) => {
		const {newLearningOutcomeId, learningOutcomeId, curriculumId} = payload;
		const updatedLearningOutcome = {
			id: learningOutcomeId,
			generalOutcomes: [{id: 0, specificOutcomes: [], generalOutcomeId: null}],
			learningOutcomeId: newLearningOutcomeId,
		};
		const curriculums = [...state.curriculums];
		const curriculumToBeUpdated = curriculums.find(
			(curriculum) => curriculum.id === curriculumId
		);
		const learningOutcomeTobeUpdatedIndex =
			curriculumToBeUpdated.learningOutcomes.findIndex(
				(learningOutcome) => learningOutcome.id === learningOutcomeId
			);
		if (learningOutcomeTobeUpdatedIndex >= 0) {
			const learningOutcomes = [...curriculumToBeUpdated.learningOutcomes];
			learningOutcomes.splice(
				learningOutcomeTobeUpdatedIndex,
				1,
				updatedLearningOutcome
			);
			const updatedCurriculum = {
				...curriculumToBeUpdated,
				learningOutcomes,
			};
			const curriculumToBeUpdatedIndex = curriculums.findIndex(
				(curriculum) => curriculum.id === curriculumId
			);
			curriculums.splice(curriculumToBeUpdatedIndex, 1, updatedCurriculum);
			const newCurriculums = [...curriculums];

			return {
				...state,
				curriculums: newCurriculums,
			};
		}
		return state;
	},
	[ActionTypes.ACTIVITY_FORM_EDIT_GENERAL_OUTCOME]: (state, {payload}) => {
		const {
			newGeneralOutcomeId,
			generalOutcomeId,
			learningOutcomeId,
			curriculumId,
		} = payload;

		const updatedGeneralOutcome = {
			id: generalOutcomeId,
			specificOutcomes: [],
			generalOutcomeId: newGeneralOutcomeId,
		};

		const curriculums = [...state.curriculums];
		const curriculumToBeUpdated = curriculums.find(
			(curriculum) => curriculum.id === curriculumId
		);
		const learningOutcomeTobeUpdated =
			curriculumToBeUpdated.learningOutcomes.find(
				(learningOutcome) => learningOutcome.id === learningOutcomeId
			);
		const generalOutcomeTobeUpdatedIndex =
			learningOutcomeTobeUpdated.generalOutcomes.findIndex(
				(generalOutcome) => generalOutcome.id === generalOutcomeId
			);
		if (generalOutcomeTobeUpdatedIndex >= 0) {
			learningOutcomeTobeUpdated.generalOutcomes.splice(
				generalOutcomeTobeUpdatedIndex,
				1,
				updatedGeneralOutcome
			);
			const generalOutcomes = [...learningOutcomeTobeUpdated.generalOutcomes];

			const updatedLearningOutcome = {
				...learningOutcomeTobeUpdated,
				generalOutcomes,
			};
			const learningOutcomeTobeUpdatedIndex =
				curriculumToBeUpdated.learningOutcomes.findIndex(
					(learningOutcome) => learningOutcome.id === learningOutcomeId
				);
			if (learningOutcomeTobeUpdatedIndex >= 0) {
				curriculumToBeUpdated.learningOutcomes.splice(
					learningOutcomeTobeUpdatedIndex,
					1,
					updatedLearningOutcome
				);
				const learningOutcomes = [...curriculumToBeUpdated.learningOutcomes];

				const updatedCurriculum = {
					...curriculumToBeUpdated,
					learningOutcomes,
				};
				const curriculumToBeUpdatedIndex = curriculums.findIndex(
					(curriculum) => curriculum.id === curriculumId
				);
				if (curriculumToBeUpdatedIndex >= 0) {
					curriculums.splice(curriculumToBeUpdatedIndex, 1, updatedCurriculum);
					const newCurriculums = [...curriculums];

					return {
						...state,
						curriculums: newCurriculums,
					};
				}
				return state;
			}
			return state;
		}
		return state;
	},
	[ActionTypes.ACTIVITY_FORM_EDIT_COMPENTENCY]: (state, {payload}) => {
		const {competencies, curriculumId} = payload;
		const curriculums = [...state.curriculums];
		const curriculumToBeUpdated = curriculums.find(
			(curriculum) => curriculum.id === curriculumId
		);
		curriculumToBeUpdated.competencies = competencies;
		const curriculumToBeUpdatedIndex = curriculums.findIndex(
			(curriculum) => curriculum.id === curriculumId
		);
		if (curriculumToBeUpdatedIndex >= 0) {
			curriculums.splice(curriculumToBeUpdatedIndex, 1, curriculumToBeUpdated);
			const newCurriculums = [...curriculums];

			return {
				...state,
				curriculums: newCurriculums,
			};
		}
		return state;
	},
	[ActionTypes.ACTIVITY_FORM_ADD_CURRICULUM]: (state) => {
		const curriculums = [...state.curriculums];
		const id = curriculums.length
			? curriculums[curriculums.length - 1].id + 1
			: 0;
		const newCurriculum = {
			id,
			learningOutcomes: [
				{
					id: 0,
					generalOutcomes: [
						{id: 0, specificOutcomes: [], generalOutcomeId: null},
					],
					learningOutcomeId: null,
				},
			],
			curriculumId: null,
			competencies: [],
		};
		const newCurriculums = [...curriculums, newCurriculum];
		return {
			...state,
			curriculums: newCurriculums,
		};
	},
	[ActionTypes.ACTIVITY_FORM_DELETE_CURRICULUM]: (state, {payload}) => {
		const {curriculumId} = payload;
		const curriculums = [...state.curriculums];
		const curriculumIndex = curriculums.findIndex(
			(curriculum) => curriculum.id === curriculumId
		);
		if (curriculumIndex >= 0) {
			curriculums.splice(curriculumIndex, 1);
			const newCurriculums = [...curriculums];
			return {
				...state,
				curriculums: newCurriculums,
			};
		}
		return state;
	},
	[ActionTypes.ACTIVITY_FORM_ADD_LEARNING_OUTCOME]: (state, {payload}) => {
		const {curriculumId} = payload;
		const curriculums = [...state.curriculums];
		const curriculumToBeUpdatedIndex = curriculums.findIndex(
			(curriculum) => curriculum.id === curriculumId
		);
		if (curriculumToBeUpdatedIndex >= 0) {
			const learningOutcomes =
				curriculums[curriculumToBeUpdatedIndex].learningOutcomes;
			const id =
				learningOutcomes.length > 0
					? learningOutcomes[learningOutcomes.length - 1].id + 1
					: 0;
			const newlearningOutcome = {
				id,
				generalOutcomes: [
					{id: 0, specificOutcomes: [], generalOutcomeId: null},
				],
				learningOutcomeId: null,
			};

			curriculums[curriculumToBeUpdatedIndex].learningOutcomes = [
				...learningOutcomes,
				newlearningOutcome,
			];

			return {
				...state,
				curriculums,
			};
		}
		return state;
	},
	[ActionTypes.ACTIVITY_FORM_DELETE_LEARNING_OUTCOME]: (state, {payload}) => {
		const {learningOutcomeId, curriculumId} = payload;
		const curriculums = [...state.curriculums];
		const curriculumToBeUpdatedIndex = curriculums.findIndex(
			(curriculum) => curriculum.id === curriculumId
		);
		if (curriculumToBeUpdatedIndex >= 0) {
			const learningOutcomeToBeDeleted = curriculums[
				curriculumToBeUpdatedIndex
			].learningOutcomes.findIndex(
				(learningOutcome) => learningOutcome.id === learningOutcomeId
			);
			if (learningOutcomeToBeDeleted >= 0) {
				curriculums[curriculumToBeUpdatedIndex].learningOutcomes.splice(
					learningOutcomeToBeDeleted,
					1
				);
				return {
					...state,
					curriculums,
				};
			}
			return state;
		}
		return state;
	},
	[ActionTypes.ACTIVITY_FORM_EDIT_SPECIFIC_OUTCOME]: (state, {payload}) => {
		const {
			newSpecificOutcomeIds,
			generalOutcomeId,
			learningOutcomeId,
			curriculumId,
		} = payload;

		const curriculums = [...state.curriculums];
		const curriculumToBeUpdated = curriculums.find(
			(curriculum) => curriculum.id === curriculumId
		);
		const learningOutcomeTobeUpdated =
			curriculumToBeUpdated.learningOutcomes.find(
				(learningOutcome) => learningOutcome.id === learningOutcomeId
			);

		const generalOutcomeTobeUpdatedIndex =
			learningOutcomeTobeUpdated.generalOutcomes.findIndex(
				(generalOutcome) => generalOutcome.id === generalOutcomeId
			);

		if (generalOutcomeTobeUpdatedIndex >= 0) {
			const generalOutcomes = [...learningOutcomeTobeUpdated.generalOutcomes];
			const generalOutcomeTobeUpdated =
				generalOutcomes[generalOutcomeTobeUpdatedIndex];

			const updatedGeneralOutcome = {
				...generalOutcomeTobeUpdated,
				specificOutcomes: [...newSpecificOutcomeIds],
			};
			generalOutcomes.splice(
				generalOutcomeTobeUpdatedIndex,
				1,
				updatedGeneralOutcome
			);
			const updatedLearningOutcome = {
				...learningOutcomeTobeUpdated,
				generalOutcomes,
			};
			const learningOutcomeTobeUpdatedIndex =
				curriculumToBeUpdated.learningOutcomes.findIndex(
					(learningOutcome) => learningOutcome.id === learningOutcomeId
				);
			if (learningOutcomeTobeUpdatedIndex >= 0) {
				curriculumToBeUpdated.learningOutcomes.splice(
					learningOutcomeTobeUpdatedIndex,
					1,
					updatedLearningOutcome
				);
				const curriculumToBeUpdatedIndex = curriculums.findIndex(
					(curriculum) => curriculum.id === curriculumId
				);
				if (curriculumToBeUpdatedIndex >= 0) {
					curriculums.splice(
						curriculumToBeUpdatedIndex,
						1,
						curriculumToBeUpdated
					);
					return {
						...state,
						curriculums,
					};
				}
				return state;
			}
			return state;
		}
		return state;
	},
	[ActionTypes.ACTIVITY_FORM_ADD_GENERAL_OUTCOME]: (state, {payload}) => {
		const {learningOutcomeId, curriculumId} = payload;

		const curriculums = [...state.curriculums];
		const curriculumToBeUpdated = curriculums.find(
			(curriculum) => curriculum.id === curriculumId
		);
		const learningOutcomeTobeUpdated =
			curriculumToBeUpdated.learningOutcomes.find(
				(learningOutcome) => learningOutcome.id === learningOutcomeId
			);
		const generalOutcomes = [...learningOutcomeTobeUpdated.generalOutcomes];
		const id =
			generalOutcomes.length > 0
				? generalOutcomes[generalOutcomes.length - 1].id + 1
				: 0;

		const newGeneralOutcome = {
			id,
			generalOutcomeId: null,
			specificOutcomes: [],
		};

		generalOutcomes.splice(generalOutcomes.length, 0, newGeneralOutcome);

		const updatedLearningOutcome = {
			...learningOutcomeTobeUpdated,
			generalOutcomes,
		};
		const learningOutcomeTobeUpdatedIndex =
			curriculumToBeUpdated.learningOutcomes.findIndex(
				(learningOutcome) => learningOutcome.id === learningOutcomeId
			);
		if (learningOutcomeTobeUpdatedIndex >= 0) {
			curriculumToBeUpdated.learningOutcomes.splice(
				learningOutcomeTobeUpdatedIndex,
				1,
				updatedLearningOutcome
			);
			const learningOutcomes = [...curriculumToBeUpdated.learningOutcomes];

			const updatedCurriculum = {
				...curriculumToBeUpdated,
				learningOutcomes,
			};
			const curriculumToBeUpdatedIndex = curriculums.findIndex(
				(curriculum) => curriculum.id === curriculumId
			);
			if (curriculumToBeUpdatedIndex >= 0) {
				curriculums.splice(curriculumToBeUpdatedIndex, 1, updatedCurriculum);
				const newCurriculums = [...curriculums];

				return {
					...state,
					curriculums: newCurriculums,
				};
			}
			return state;
		}
		return state;
	},
	[ActionTypes.ACTIVITY_FORM_DELETE_GENERAL_OUTCOME]: (state, {payload}) => {
		const {generalOutcomeId, learningOutcomeId, curriculumId} = payload;
		const curriculums = [...state.curriculums];
		const curriculumToBeUpdatedIndex = curriculums.findIndex(
			(curriculum) => curriculum.id === curriculumId
		);
		if (curriculumToBeUpdatedIndex >= 0) {
			const learningOutcomeTobeUpdatedIndex = curriculums[
				curriculumToBeUpdatedIndex
			].learningOutcomes.findIndex(
				(learningOutcome) => learningOutcome.id === learningOutcomeId
			);
			const generalOutcomeToBeDeletedIndex = curriculums[
				curriculumToBeUpdatedIndex
			].learningOutcomes[
				learningOutcomeTobeUpdatedIndex
			].generalOutcomes.findIndex(
				(generalOutcome) => generalOutcome.id === generalOutcomeId
			);

			if (generalOutcomeToBeDeletedIndex >= 0) {
				curriculums[curriculumToBeUpdatedIndex].learningOutcomes[
					learningOutcomeTobeUpdatedIndex
				].generalOutcomes.splice(generalOutcomeToBeDeletedIndex, 1);
				return {
					...state,
					curriculums,
				};
			}
			return state;
		}
		return state;
	},
	[ActionTypes.ACTIVITY_FORM_EDIT_TITLE]: (state, {payload}) => {
		return {
			...state,
			formActivityTitle: payload,
		};
	},
	[ActionTypes.ACTIVITY_FORM_EDIT_PLACE]: (state, {payload}) => {
		return {
			...state,
			places: [...payload],
		};
	},
	[ActionTypes.ACTIVITY_FORM_EDIT_LANGUAGE]: (state, {payload}) => {
		return {
			...state,
			languages: [...payload],
		};
	},
	[ActionTypes.ACTIVITY_FORM_EDIT_GRADE]: (state, {payload}) => {
		return {
			...state,
			grades: [...payload],
		};
	},
	[ActionTypes.ACTIVITY_FORM_EDIT_DIFFICULTY]: (state, {payload}) => {
		return {
			...state,
			difficulties: [payload],
		};
	},
	[ActionTypes.ACTIVITY_FORM_EDIT_DURATION]: (state, {payload}) => {
		return {
			...state,
			durations: [payload],
		};
	},
	[ActionTypes.ACTIVITY_FORM_EDIT_DESCRIPTION]: (state, {payload}) => {
		return {
			...state,
			description: payload,
		};
	},
	[ActionTypes.ACTIVITY_FORM_EDIT_MATERIALS]: (state, {payload}) => {
		return {
			...state,
			materials: payload,
		};
	},
	[ActionTypes.ACTIVITY_FORM_VALIDATE_FIELDS]: (state, {payload}) => {
		return {
			...state,
			validated: payload,
		};
	},
	[ActionTypes.ACTIVITY_FORM_EDIT_SHARE_PERMISSION]: (state, {payload}) => {
		return {
			...state,
			communityOnly: payload,
		};
	},
});
