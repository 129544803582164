import "./OutcomeHierarchy.scss";

import i18n from "i18next";
import React, {Fragment} from "react";

import {
	findCurrentItem,
	setFiltersGetActivities,
} from "../../../../Scripts/Activities";
import {
	ACTIVITY_FILTERS,
	AUTOMATION_TEST_ID,
	OUTCOME_LEVEL_S,
	PORTALS,
} from "../../../../Scripts/Constants";
import {getLocaleId} from "../../../../Scripts/Domain";
import {resetCurrentOutcomes} from "../../../../Scripts/LearningOpportunities";

export const OutcomeHierarchy = ({
	filters,
	currentDomain,
	taxonomies,
	currentOutcomes,
	portal,
}) => {
	const handleRandomOutcomeClick = (event) => {
		const outcomeSelected = parseInt(event.currentTarget.id, 10);
		const {specificOutcomes, generalOutcomes, learningOutcomes} = taxonomies;
		const outcomeItem = [
			...specificOutcomes,
			...learningOutcomes,
			...generalOutcomes,
		].find((outcome) => outcome.id === outcomeSelected);
		switch (outcomeItem.taxonomy) {
			case "general-outcome": {
				const currentFilters = {
					...filters,
					[ACTIVITY_FILTERS.LEARNING_OUTCOMES]: outcomeSelected,
					[ACTIVITY_FILTERS.GENERAL_OUTCOMES]: null,
					[ACTIVITY_FILTERS.SPECIFIC_OUTCOMES]: null,
					[ACTIVITY_FILTERS.COMPETENCIES]: null,
				};
				setFiltersGetActivities({...currentFilters}, true);
				break;
			}
			case OUTCOME_LEVEL_S.TWO: {
				const currentFilters = {
					...filters,
					[ACTIVITY_FILTERS.LEARNING_OUTCOMES]: outcomeItem.parent,
					[ACTIVITY_FILTERS.GENERAL_OUTCOMES]: outcomeSelected,
					[ACTIVITY_FILTERS.SPECIFIC_OUTCOMES]: null,
					[ACTIVITY_FILTERS.COMPETENCIES]: null,
				};
				setFiltersGetActivities({...currentFilters}, true);
				break;
			}
			case "specific-outcome": {
				const currentFilters = {
					...filters,
					[ACTIVITY_FILTERS.LEARNING_OUTCOMES]: outcomeItem.parent_parent,
					[ACTIVITY_FILTERS.GENERAL_OUTCOMES]: outcomeItem.parent,
					[ACTIVITY_FILTERS.SPECIFIC_OUTCOMES]: outcomeSelected,
					[ACTIVITY_FILTERS.COMPETENCIES]: null,
				};
				setFiltersGetActivities({...currentFilters}, true);

				break;
			}
			default:
				throw new Error(`Unsupported taxonomy type: ${outcomeItem.taxonomy}`);
		}
	};

	const handleClearSelectedOutcomes = () => {
		const currentFilters = {
			...filters,
			...resetCurrentOutcomes,
			[ACTIVITY_FILTERS.COMPETENCIES]: null,
			[ACTIVITY_FILTERS.PAGE]: 1,
		};
		setFiltersGetActivities({...currentFilters}, true);
	};

	const {
		currentLearningOutcome,
		currentGeneralOutcome,
		currentSpecificOutcome,
		competency,
	} = currentOutcomes;
	const learningOutcome = currentLearningOutcome
		? findCurrentItem(currentLearningOutcome, taxonomies.learningOutcomes)
		: null;
	const generalOutcome = currentGeneralOutcome
		? findCurrentItem(currentGeneralOutcome, taxonomies.generalOutcomes)
		: null;
	const specificOutcome = currentSpecificOutcome
		? findCurrentItem(currentSpecificOutcome, taxonomies.specificOutcomes)
		: null;
	const selectCompetency = competency
		? findCurrentItem(competency, taxonomies.competencies)
		: null;

	const showComponent = () => {
		switch (portal) {
			case PORTALS.EDUCATOR:
				return (
					!filters.completed_by_class_and_current_user && !filters.saved_for
				);
			case PORTALS.CAREGIVER:
				return !filters.completed_by && !filters.saved_for;
			default:
				throw new Error(`Unsupported portal: ${portal}`);
		}
	};

	return (
		<Fragment>
			{showComponent() && (
				<Fragment>
					{currentLearningOutcome && (
						<div
							className="row pt-2 pb-2 pl-2 col-md-12"
							id={
								AUTOMATION_TEST_ID.EDUCATOR.CLASSROOM.LEARNING_ACTIVITY
									.CURRENT_LEARNING_OUTCOMES
							}
						>
							<div className="current-selected-outcome align-items-center d-flex">
								<span
									onClick={handleClearSelectedOutcomes}
									id={
										AUTOMATION_TEST_ID.EDUCATOR.CLASSROOM.LEARNING_ACTIVITY
											.CURRENT_LEARNING_OPPORTUNITIES_DOMAIN
									}
								>
									{i18n.t(getLocaleId(currentDomain))}
								</span>
								<span
									onClick={handleRandomOutcomeClick}
									id={learningOutcome?.id}
									name={
										AUTOMATION_TEST_ID.EDUCATOR.CLASSROOM.LEARNING_ACTIVITY
											.CURRENT_LEARNING_OPPORTUNITIES_LEARNING_OUTCOME
									}
								>
									{learningOutcome?.name.replace(/&amp;/g, "&")}
								</span>
								{currentGeneralOutcome && (
									<span
										onClick={handleRandomOutcomeClick}
										id={generalOutcome.id}
										name={
											AUTOMATION_TEST_ID.EDUCATOR.CLASSROOM.LEARNING_ACTIVITY
												.CURRENT_LEARNING_OPPORTUNITIES_LEARNING_OUTCOME
										}
									>
										{generalOutcome.name.replace(/&amp;/g, "&")}
									</span>
								)}
								{currentSpecificOutcome && (
									<span
										onClick={handleRandomOutcomeClick}
										id={specificOutcome.id}
										name={
											AUTOMATION_TEST_ID.EDUCATOR.CLASSROOM.LEARNING_ACTIVITY
												.CURRENT_LEARNING_OPPORTUNITIES_LEARNING_OUTCOME
										}
									>
										{specificOutcome.name.replace(/&amp;/g, "&")}
									</span>
								)}
							</div>
						</div>
					)}
					{competency && (
						<div className="row pt-2 pb-2 pl-2 col-md-12">
							<div className="current-selected-outcome align-items-center d-flex">
								<span
									onClick={handleClearSelectedOutcomes}
									name={
										AUTOMATION_TEST_ID.EDUCATOR.CLASSROOM.LEARNING_ACTIVITY
											.CURRENT_LEARNING_OPPORTUNITIES_LEARNING_OUTCOME
									}
								>
									{i18n.t(getLocaleId(currentDomain))}
								</span>
								<span
									onClick={handleRandomOutcomeClick}
									id={competency?.id}
									name={
										AUTOMATION_TEST_ID.EDUCATOR.CLASSROOM.LEARNING_ACTIVITY
											.CURRENT_LEARNING_OPPORTUNITIES_LEARNING_OUTCOME
									}
								>
									{selectCompetency?.name.replace(/&amp;/g, "&")}
								</span>
							</div>
						</div>
					)}
				</Fragment>
			)}
		</Fragment>
	);
};
