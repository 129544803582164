import i18n from "i18next";
import React from "react";

import {AssetManager} from "../../../Scripts/AssetManager";
import {get_url} from "../../../Scripts/Fetch";

interface FormHeaderProps {
	title?: string;
	noLogo?: boolean;
	children?: JSX.Element[] | JSX.Element;
	logo?: string;
	logoAlt?: string;
	customizedLogo?: boolean;
}

export const FormHeader = ({
	title,
	noLogo,
	children,
	logo,
	logoAlt,
	customizedLogo,
}: FormHeaderProps) => (
	<div className="form-header w-100">
		<div className="form-header-logo col-12 rounded-top border border-bottom-0 bg-white login-holder">
			{!noLogo && !customizedLogo && (
				<a href={get_url()}>
					<img
						className=""
						width="45px"
						height="auto"
						src={AssetManager.ASSETS.LOGO.SPRIG_LEAVES}
						alt={i18n.t("common.sprig_logo")}
					/>
				</a>
			)}
			{!noLogo && customizedLogo && <img src={logo} alt={logoAlt} />}
			<h4 className="text-center">{title}</h4>
			{children}
		</div>
	</div>
);
