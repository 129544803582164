import "./primaryNavigationBar.scss";

import React, {useState} from "react";

import {
	STYLE_CONSTANT,
	USER_TYPE_OPTIONS,
} from "../../../Scripts/StyleConstants";
import {
	Button,
	DropdownWrapper,
	DropdownWrapperProps,
	Icon,
	IconProps,
	NavigationItemProps,
} from "../index";

/* 
    Typescript Definitions
*/
export interface PrimaryNavigationMenuProps {
	isOpen: boolean;
	action: () => void;
}

export interface PrimaryNavigationOptionProps {
	icon: IconProps;
	event?: () => void;
	dropdown?: DropdownWrapperProps;
	id?: string;
	optionClassName?: string;
	itemWidget?: React.ReactNode;
	isHolisticMath?: boolean;
}

export interface PrimaryDropdownMenuProps {
	label: string;
	secondaryLabel?: string;
	secondaryLabelId?: string;
	menuItems: (arg) => Array<React.ReactNode>;
	user?: USER_TYPE_OPTIONS;
	id?: string;
	menuId?: string;
	primaryLabelId?: string;
}

export interface PrimaryNavigationBarProps {
	/**
	 * Primary Dropdown Menu Properties
	 */
	primaryDropdownMenu?: PrimaryDropdownMenuProps;
	/**
	 * Optional properties for mobile menu control on tablet/mobile setting
	 */
	menu?: PrimaryNavigationMenuProps;
	/**
	 * Navigation Link Items
	 */
	navigationItems?: Array<NavigationItemProps>;
	/**
	 * Clickable Icons with Dropdowns or Actionable
	 */
	options?: Array<PrimaryNavigationOptionProps>;
	customizedComponent?: React.ReactNode;
}

/* 
    Broken Out Components for Navigation Bar
*/
const PrimaryDropdownMenuComponent = (props: PrimaryDropdownMenuProps) => {
	const [isMenuOpen, updateMenu] = useState(false);
	const menuItemsWithMenuUpdate = (closeMenuEvent) => {
		const updatedEvent = () => {
			closeMenuEvent();
			updateMenu(false);
		};
		return props.menuItems(updatedEvent);
	};
	return (
		<div
			className={`${STYLE_CONSTANT.COMPONENT.NAVIGATION.PRIMARY}--primary-dropdown`}
			id={props.id}
		>
			<DropdownWrapper
				menuItems={menuItemsWithMenuUpdate}
				position="se"
				menuId={props.menuId}
			>
				<Button onClick={() => updateMenu(!isMenuOpen)} user={props.user}>
					<div
						className={`primary-dropdown-content${
							isMenuOpen ? " menu-open" : ""
						}`}
					>
						<div className="label-content">
							<div className="primary-label" id={props.primaryLabelId}>
								{props.label}
							</div>
							<div className="secondary-label" id={props.secondaryLabelId}>
								{props.secondaryLabel}
							</div>
						</div>
						<Icon type="faCaretDown" />
					</div>
				</Button>
			</DropdownWrapper>
		</div>
	);
};

const OptionsComponent = ({options}) => {
	return (
		<div className={`${STYLE_CONSTANT.COMPONENT.NAVIGATION.PRIMARY}--options`}>
			{options.map((option: PrimaryNavigationOptionProps, index) => {
				const icon = (
					<div className="navigation-option-icon">
						<Icon {...option.icon} />
					</div>
				);
				const optionElement = (
					<div
						className={[
							"navigation-option",
							option.optionClassName && !option.dropdown
								? option.optionClassName
								: "",
						].join(" ")}
						onClick={option.event}
						key={`${index}-${option.id}`}
						id={option.id}
					>
						{icon}
						{option.itemWidget ? option.itemWidget : null}
					</div>
				);
				if (option.dropdown) {
					return (
						<div
							id={option.id}
							key={`${index}-${option.id}`}
							className={option.optionClassName ? option.optionClassName : ""}
						>
							<DropdownWrapper {...option.dropdown}>
								{optionElement}
							</DropdownWrapper>
						</div>
					);
				}
				return optionElement;
			})}
		</div>
	);
};

/*
    Primary UI component for user interaction
*/
export const PrimaryNavigationBar: React.FC<PrimaryNavigationBarProps> = ({
	navigationItems = [],
	menu,
	options,
	primaryDropdownMenu,
	children,
	customizedComponent,
	...props
}) => {
	return (
		<nav
			className={[STYLE_CONSTANT.COMPONENT.NAVIGATION.PRIMARY].join(" ")}
			{...props}
		>
			<section
				className={`${STYLE_CONSTANT.COMPONENT.NAVIGATION.PRIMARY}--content`}
			>
				<div className="content-wrapper content-left">
					<div
						className={`${
							STYLE_CONSTANT.COMPONENT.NAVIGATION.PRIMARY
						}--mobile-menu${menu?.isOpen ? " open" : ""}`}
						onClick={menu?.action}
					>
						<Icon type={menu?.isOpen ? "faTimes" : "faBars"} size="lg" />
					</div>
					<div
						className={`${STYLE_CONSTANT.COMPONENT.NAVIGATION.PRIMARY}--logo`}
					>
						<Icon type="sprigLogo" size="3x" />
					</div>
					{primaryDropdownMenu ? (
						<PrimaryDropdownMenuComponent {...primaryDropdownMenu} />
					) : null}
				</div>
				<div className="content-wrapper content-center">
					{customizedComponent ? customizedComponent : null}
				</div>
				<div className="content-wrapper content-right">
					{options ? <OptionsComponent options={options} /> : null}
				</div>
			</section>
		</nav>
	);
};
