import "../../../Stylesheets/css/scss/Components/Public/Table.css";

import React from "react";

import {TableBody} from "./TableBody";
import {TableHead} from "./TableHead";
import {TableRow} from "./TableRow";
import {TableTd} from "./TableTd";
import {TableTh} from "./TableTh";

interface TableProps {
	children: JSX.Element[];
	className?: string;
	id?: string;
	name?: string;
}

const Table = ({children, className, ...rest}: TableProps) => (
	<table
		className={`table-component table table-responsive-sm ${
			className ? className : `text-center rounded bg-white mb-0`
		}`}
		{...rest}
	>
		{children}
	</table>
);

Table.Head = TableHead;
Table.Body = TableBody;
Table.Row = TableRow;
Table.Th = TableTh;
Table.Td = TableTd;

export default Table;
