import i18n from "i18next";
import React, {Component} from "react";

import {ReportActions} from "../../../../Actions";
import {
	CHART_TYPE,
	REPORT_CHART_TYPE,
	REPORT_ID,
} from "../../../../Scripts/Constants";
import {ChartHeader, ReportAlertNoData, ReportChart} from "..";

interface AncestralSpeakersProps {
	id?: number;
	type?: string;
	report?: {}[];
}

interface AncestralSpeakersState {
	data: {};
	gotData: boolean;
	gotDataFromProps: boolean;
	noData: boolean;
}

export class AncestralSpeakers extends Component<
	AncestralSpeakersProps,
	AncestralSpeakersState
> {
	constructor(props) {
		super(props);
		this.state = {
			gotData: false,
			data: [],
			noData: false,
			gotDataFromProps: false,
		};
	}

	componentDidMount = () => {
		if (!this.props.report) {
			this.getData();
		} else {
			this.setState({
				gotDataFromProps: true,
			});
		}
	};

	getData = () => {
		const {id, type} = this.props;
		ReportActions.getReport(
			{report: REPORT_ID.ANCESTRAL_SPEAKERS, id, type, direct: true},
			{
				handling: {
					handleResponse: (data) => {
						if (!Array.isArray(data) || !data.length) {
							this.setState({
								noData: true,
							});
						} else {
							this.setState({
								gotData: true,
								data,
							});
						}
					},
				},
			}
		);
	};

	getChartData = () => {
		const {data, gotData, gotDataFromProps} = this.state;
		const {report} = this.props;
		if (gotData || gotDataFromProps) {
			let info;
			if (gotDataFromProps) {
				if (report) {
					info = report[0];
				} else info = null;
			} else {
				info = data[0];
			}
			if (info) {
				const caregiver =
					(info.caregiver_speakers / info.caregiver_total) * 100;
				const teacher = (info.teacher_speakers / info.teacher_total) * 100;
				const elder = (info.elder_speakers / info.elder_total) * 100;
				const percentageArray = [
					caregiver.toFixed(2),
					teacher.toFixed(2),
					elder.toFixed(2),
				];
				const chartData = {
					labels: [
						i18n.t("role.caregiver"),
						i18n.t("role.educator"),
						i18n.t("role.elder"),
					],
					datasets: [
						{
							label: i18n.t("chart.percent_ancestral_language_speakers"),
							backgroundColor: ["#4BB5B4", "#6BC4C3", "#8FD3D2"],
							borderColor: "white",
							borderWidth: 1,
							hoverBackgroundColor: "#F88D25",
							hoverBorderColor: "#F88D25",
							data: percentageArray,
						},
					],
				};
				const counts = [
					info.caregiver_speakers,
					info.teacher_speakers,
					info.elder_speakers,
				];
				return {chartData, counts};
			}
		}
		return {};
	};

	render() {
		const {gotData, noData, gotDataFromProps} = this.state;
		const chartData = gotData || gotDataFromProps ? this.getChartData() : {};
		return (
			<div className="mt-2">
				<ChartHeader title={i18n.t("admin.ancestral_language_speakers")} />
				<div className="text-right mb-2 mt-5">
					{(gotData || gotDataFromProps) && chartData.chartData && (
						<ReportChart
							chartType={CHART_TYPE.BAR}
							data={chartData.chartData}
							type={REPORT_CHART_TYPE.ANCESTRAL_LANGUAGE_SPEAKER}
							tooltipValue={chartData.counts}
						/>
					)}
					{(noData ||
						(gotDataFromProps && this.props.report?.length === 0)) && (
						<ReportAlertNoData />
					)}
				</div>
			</div>
		);
	}
}
