import "../../../../../Stylesheets/css/scss/Components/Portal/Public/Rewards/StickerToggle.css";

import i18n from "i18next";
import React, {Component} from "react";

import {AUTOMATION_TEST_ID} from "../../../../../Scripts/Constants";
import {Icon} from "../../../../Public";

interface StickerToggleProps {
	onToggle: (hideStatus: boolean) => void;
}

interface StickerToggleState {
	hide: boolean;
}

export class StickerToggle extends Component<
	StickerToggleProps,
	StickerToggleState
> {
	constructor(props) {
		super(props);
		this.state = {
			hide: props.startByHiding,
		};
	}

	handleToggle = () => {
		const {onToggle} = this.props;
		const {hide} = this.state;
		onToggle(!hide);
		this.setState({hide: !hide});
	};

	render() {
		return (
			<div
				title={i18n.t("admin.toggle_stickers")}
				className={`sticker-toggle${this.state.hide ? " hide" : ""}`}
				onClick={this.handleToggle}
				id={AUTOMATION_TEST_ID.CAREGIVER.HOME.STICKERS_TOGGLE_BUTTON}
			>
				<Icon type="faCertificate" />
			</div>
		);
	}
}
