import i18n from "i18next";
import React from "react";

import {IconType} from "../../../Icons";
import {PortalTourTooltip} from "../../PortalTourTooltip";
import {PageDescription} from "../../Widgets/PageDescription";

const pageData = [
	{
		icon: "sprigDashboard" as IconType,
		title: i18n.t("tour.educator.page.dashboard.title"),
		description: i18n.t("tour.educator.page.dashboard.description"),
		tooltipTop: "10px",
	},
	{
		icon: "sprigStudent" as IconType,
		title: i18n.t("tour.educator.page.students.title"),
		description: i18n.t("tour.educator.page.students.description"),
		tooltipTop: "10px",
	},
	{
		icon: "sprigLeaf" as IconType,
		title: i18n.t("tour.educator.page.activities.title"),
		description: i18n.t("tour.educator.page.activities.description"),
		tooltipTop: "10px",
	},
	{
		icon: "faChartArea" as IconType,
		title: i18n.t("tour.educator.page.reports.title"),
		description: i18n.t("tour.educator.page.reports.description"),
		tooltipTop: "10px",
	},
	{
		icon: "faDownload" as IconType,
		title: i18n.t("tour.educator.page.resources.title"),
		description: i18n.t("tour.educator.page.resources.description"),
		tooltipTop: "50px",
	},
];

export const EducatorMainPageDescriptionSteps = pageData.map((page, index) => (
	<PortalTourTooltip
		key={page.title}
		style={{
			"--arrowTop": `${index * 50 + 7}px`,
			"--tooltipTop": `${page.tooltipTop}`,
		}}
	>
		<PageDescription {...page} />
	</PortalTourTooltip>
));
