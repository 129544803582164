import i18n from "i18next";

import {CURRICULA_SLUG} from "./Constants";
import {Term} from "./PublicInterfaces";
import {getCurricula} from "./Utilities";

const curriculaTaxonomy = getCurricula();

export const getLocaleDateString = (date: Date) => {
	const options: Intl.DateTimeFormatOptions = {
		timeZone: "UTC",
		year: "numeric",
		month: "long",
		day: "numeric",
	};
	return new Date(date).toLocaleDateString("default", options);
};

export const getLocaleEpochDateString = (timestamp: number) => {
	const options: Intl.DateTimeFormatOptions = {
		timeZone: "UTC",
		year: "numeric",
		month: "long",
		day: "numeric",
	};
	const newDate = new Date(0);
	newDate.setUTCSeconds(timestamp);
	return newDate.toLocaleDateString("default", options);
};

export const getCurrentSchoolYear = () => {
	const september = 8;

	const currentDate = new Date();
	const currentMonth = currentDate.getMonth();
	const currentYear = currentDate.getFullYear();

	return currentMonth >= september ? currentYear : currentYear - 1;
};

export const getCurrentSubscription = (
	subscriptions: any[],
	currentTerm?: Term
) => {
	const readingCurriculum = curriculaTaxonomy.find(
		(curriculumItem) => curriculumItem.slug === CURRICULA_SLUG.SPRIG_READING
	);
	if (
		subscriptions.every(
			(subscription) => subscription.curriculum?.slug === readingCurriculum.slug
		)
	) {
		const currentSubscription = subscriptions.reduce(
			(max, current) => (max.start > current.start ? max : current),
			0
		);
		return currentSubscription;
	} else {
		if (currentTerm) {
			const halfTermLength = (currentTerm.end - currentTerm.start) / 2;
			return subscriptions
				.filter(
					(subscription) =>
						subscription.curriculum.slug !== CURRICULA_SLUG.SPRIG_READING
				)
				.find((subscription) => {
					const intersectionStart = Math.max(
						parseInt(subscription.start),
						currentTerm.start
					);
					const intersectionEnd = Math.min(
						parseInt(subscription.end),
						currentTerm.end
					);
					const intersectionLength = intersectionEnd - intersectionStart;
					return intersectionLength > halfTermLength;
				});
		}
	}
};

export const getTimeDurationFromSeconds = (seconds: number) => {
	const time = new Date(seconds * 1000).toISOString().substr(11, 8);
	const parts = time.split(":");
	return parts.reduce((string, part, partIndex) => {
		const value = Number(part);
		switch (partIndex) {
			case 0:
				const hourTemplate = value ? `${value}h` : null;
				return hourTemplate ? string + hourTemplate : string;
			case 1:
				const minuteTemplate = value ? `${value}m` : null;
				return minuteTemplate
					? string
						? `${string} ${minuteTemplate}`
						: string + minuteTemplate
					: string;
			case 2:
				const secondTemplate = value ? `${value}s` : null;
				return secondTemplate
					? string
						? `${string} ${secondTemplate}`
						: string + secondTemplate
					: string;
			default:
				return string;
		}
	}, "");
};

export const gmtToLocale = (gmtString: string | number) => {
	const date = new Date(gmtString);
	const newDate = new Date(
		date.getTime() - date.getTimezoneOffset() * 60 * 1000
	);
	return `${newDate.getFullYear()}-${`0${newDate.getMonth() + 1}`.slice(
		-2
	)}-${`0${newDate.getDate()}`.slice(-2)}`;
};

export const checkSubscriptionsAndReturnActiveDomains = (
	subscriptions: any[]
) => {
	if (subscriptions?.length) {
		const date = new Date();
		const secondsSinceEpoch = Math.round(date.getTime() / 1000);

		const activeSubscription = subscriptions.find((subscription) => {
			if (
				secondsSinceEpoch >= parseInt(subscription.start) &&
				secondsSinceEpoch <= parseInt(subscription.end)
			)
				return true;
			return false;
		});
		if (activeSubscription) return activeSubscription.domains;
	}

	return [];
};

export const getMonthIdByDate = (timestamp: number) => {
	const newDate = new Date(0);
	newDate.setUTCSeconds(timestamp);
	return newDate.getMonth();
};

export const getMonthName = (timestamp: number, short?: boolean) => {
	const form = short ? "short" : "full";
	const months = [
		i18n.t(`time.month_label.jan.${form}`),
		i18n.t(`time.month_label.feb.${form}`),
		i18n.t(`time.month_label.mar.${form}`),
		i18n.t(`time.month_label.apr.${form}`),
		i18n.t(`time.month_label.may.${form}`),
		i18n.t(`time.month_label.jun.${form}`),
		i18n.t(`time.month_label.jul.${form}`),
		i18n.t(`time.month_label.aug.${form}`),
		i18n.t(`time.month_label.sep.${form}`),
		i18n.t(`time.month_label.oct.${form}`),
		i18n.t(`time.month_label.nov.${form}`),
		i18n.t(`time.month_label.dec.${form}`),
	];

	return months[getMonthIdByDate(timestamp)];
};

export const secondsToHMS = (seconds) => {
	const hours = Math.floor(seconds / 3600);
	const minutes = Math.floor((seconds % 3600) / 60);
	const remainingSeconds = seconds % 60;

	const formattedHours = hours < 10 ? `0${hours}` : hours;
	const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
	const formattedSeconds =
		remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;

	return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};
