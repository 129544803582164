import * as ActionTypes from "../Redux/ActionTypes";
import {
	getMathResultFiles,
	getMostRecentSubmission,
	getQuestions,
	getStorytellingAudio,
} from "../Scripts/AssessmentResults";
import {
	deleteData,
	deleteDataAcceptJSON,
	getData,
	postData,
	putData,
} from "../Scripts/Axios/axiosRequests";
import {ACTIVITY_MONITOR_ACTIONS} from "../Scripts/Constants";
import {DOMAIN_MATH} from "../Scripts/Domain";
import {get_url} from "../Scripts/Fetch";
import {
	ActivityMonitorActions,
	ErrorReporterActions,
	LoaderActions,
} from "./index";

export const getSubmissionQueryString = (request = {}) => {
	const {context, assessed, classId, only_most_recent, term, type, app, class_id, start, end} = request;
	let query = "";
	if (context) query += `?context=${context}`;
	if (assessed) query += `${query ? "&" : "?"}assessed=${assessed}`;
	if (classId) query += `${query ? "&" : "?"}class=${classId}`;
	if (only_most_recent)
		query += `${query ? "&" : "?"}only_most_recent=${only_most_recent}`;
	if (term) query += `${query ? "&" : "?"}term=${term}`;
	if (type) query += `${query ? "&" : "?"}type=${type}`;
	if (app) query += `${query ? "&" : "?"}app=${app}`;
	if (class_id) query += `${query ? "&" : "?"}class_id=${class_id}`;
	if (start) query += `${query ? "&" : "?"}start=${start}`;
	if (end) query += `${query ? "&" : "?"}end=${end}`;
	return query;
};

export const getClasses = (request = {}, handlingFunction = {}) => {
	const {schoolId, term, person} = request;
	const actionType = ActionTypes.SCHOOL_GET_CLASSES;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		let url = `${get_url()}wp-json/sprig/v2/classes`;
		let query = "";
		if (schoolId) query += `?school=${schoolId}`;
		if (term) query += query ? `&term=${term}` : `?term=${term}`;
		if (person) query += query ? `&person=${person}` : `?person=${person}`;
		url += query;
		getData(url).then((response) => {
			if (handlingFunction.handling) {
				handlingFunction.handling.success(response);
			} else
				dispatch({
					type: actionType,
					payload: response,
				});
		});
	};
};

export const getReadingDemoClasses = () => {
	const actionType = ActionTypes.SCHOOL_GET_READING_DEMO_CLASSES;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		let url = `${get_url()}wp-json/sprig/v2/demoClasses`;
		getData(url).then((response) => {
			dispatch({
				type: actionType,
				payload: response,
			});
		});
	};
};

export const createClassroom = (body, {handling}) => {
	const actionType = ActionTypes.SCHOOL_CREATE_CLASSROOM;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/classes`;
		postData(url, body)
			.then((response) => {
				if (response.status < 300) {
					handling.success(response.data);
				} else {
					handling.error(response);
				}
				dispatch({
					type: actionType,
				});
			})
			.catch((error) => {
				handling.error(error);
			});
	};
};

export const getClassroom = (classroomId, {handling} = {}) => {
	const actionType = ActionTypes.SCHOOL_GET_CLASSROOM;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/classes/${classroomId}`;
		getData(url)
			.then((response) => {
				if (response.status < 300) {
					if (handling.success) handling.success();
				}
				dispatch({
					type: actionType,
					payload: response,
				});
			})
			.catch((error) => {
				if (handling.error) handling.error(error);
			});
	};
};

export const updateClassroom = (classroomId, body, {handling}) => {
	const actionType = ActionTypes.SCHOOL_UPDATE_CLASSROOM;
	return () => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/classes/${classroomId}`;
		postData(url, body)
			.then((response) => {
				if (response.status < 300) {
					handling.success();
				}
			})
			.catch((error) => {
				handling.error(error);
			});
	};
};

export const clearOutClasses = () => ({
	type: ActionTypes.SCHOOL_CLEAR_CLASSES,
	payload: [],
});

export const getSchools = ({handling} = {}) => {
	const actionType = ActionTypes.SCHOOL_GET_SCHOOLS;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/schools`;
		getData(url).then((response) => {
			if (handling?.success) handling.success(response);
			dispatch({
				type: actionType,
				payload: response,
			});
		});
	};
};

export const setCurrentSchoolId = (schoolId) => ({
	type: ActionTypes.SCHOOL_SET_CURRENT_SCHOOL_ID,
	payload: schoolId,
});

export const createSchool = (body, {handling}) => {
	const actionType = ActionTypes.SCHOOL_CREATE_SCHOOL;
	return () => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/schools`;
		postData(url, body)
			.then((response) => {
				if (response.status < 300) {
					handling.success();
				}
			})
			.catch((error) => {
				handling.error(error);
			});
	};
};

export const inviteTeacherToClass = (classroomId, body, {handling}) => {
	const actionType = ActionTypes.SCHOOL_INVITE_TEACHER_TO_CLASS;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/classes/${classroomId}/inviteTeacher`;
		postData(url, body)
			.then((response) => {
				if (response.status < 300) {
					handling.success();
				} else {
					handling.error(response);
				}
				dispatch({
					type: actionType,
				});
			})
			.catch((error) => {
				handling.error(error);
			});
	};
};

export const updateSchool = (schoolId, body, {handling}) => {
	const actionType = ActionTypes.SCHOOL_UPDATE_SCHOOL;
	return () => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/schools/${schoolId}`;
		postData(url, body)
			.then((response) => {
				if (response.status < 300) {
					handling.success();
				}
			})
			.catch((error) => {
				handling.error(error);
			});
	};
};

export const getClientDivisionId = (clientId, {handling}) => {
	const actionType = ActionTypes.SCHOOL_GET_DIVISION_ID;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/divisions?client=${clientId}&only_subdivisions=true`;
		getData(url).then((response) => {
			handling.success(response);
			dispatch({
				type: actionType,
			});
		});
	};
};

export const getSchoolById = ({id, schools}) => {
	const actionType = ActionTypes.SCHOOL_GET_SCHOOL_BY_ID;
	if (schools && schools.length) {
		const selectedSchool = schools.find((school) => school.id === id);
		if (selectedSchool) {
			return {
				type: actionType,
				payload: {selectedSchool},
			};
		}
	}
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/schools/${id}`;
		getData(url, true)
			.then((response) => {
				if (response.status === 200) {
					dispatch({
						type: actionType,
						payload: {selectedSchool: response.data, addToList: false},
					});
				} else {
					dispatch({type: actionType});
					ErrorReporterActions.addToQueue({
						actionType,
						error: response,
					});
				}
			})
			.catch((error) => {
				dispatch({type: actionType});
				ErrorReporterActions.addToQueue({
					actionType,
					error,
				});
			});
	};
};

export const getTermCurrent = (term) => {
	const actionType = ActionTypes.SCHOOL_GET_TERM_CURRENT;
	if (term) {
		return {
			type: actionType,
			payload: term,
		};
	}
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/terms/current`;
		getData(url).then((response) => {
			dispatch({
				type: actionType,
				payload: response,
			});
		});
	};
};

export const getTerms = () => {
	const actionType = ActionTypes.SCHOOL_GET_TERMS;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/terms`;
		getData(url)
			.then((response) => {
				dispatch({
					type: actionType,
					payload: response,
				});
			})
			.catch((error) => {
				dispatch({type: actionType});
				ErrorReporterActions.addToQueue({
					actionType,
					error,
				});
			});
	};
};

export const getDivisions = () => {
	const actionType = ActionTypes.SCHOOL_GET_DIVISIONS;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/divisions`;
		getData(url).then((response) => {
			dispatch({
				type: actionType,
				payload: response,
			});
		});
	};
};

export const createDivisions = (body, {handling} = {}) => {
	const actionType = ActionTypes.SCHOOL_CREATE_DIVISIONS;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/divisions`;
		postData(url, body).then((response) => {
			if (response.status < 300) {
				handling.success(response.data);
			}
		});
	};
};

export const updateDivision = (id, body, {handling}) => {
	const actionType = ActionTypes.SCHOOL_UPDATE_DIVISION;
	return () => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/divisions/${id}`;
		putData(url, body)
			.then((response) => {
				if (response.status < 300) {
					handling.success();
				}
			})
			.catch((error) => {
				handling.error(error);
			});
	};
};

export const getGroups = (classId) => {
	const actionType = ActionTypes.SCHOOL_GET_CLASS_GROUPS;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/groupsets?class=${classId}&context=view`;
		getData(url).then((response) => {
			dispatch({
				type: actionType,
				payload: response,
			});
		});
	};
};

export const updateGroup = (groupId, body, {handling}) => {
	const actionType = ActionTypes.SCHOOL_UPDATE_CLASS_GROUP;
	return () => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/groupsets/${groupId}`;
		postData(url, body)
			.then((response) => {
				if (response.status < 300) {
					handling.success();
				} else {
					handling.error(response);
				}
			})
			.catch((error) => {
				handling.error(error);
			});
	};
};

export const selectStudent = (student) => ({
	type: ActionTypes.SCHOOL_GROUP_SELECT_STUDENT,
	payload: student,
});

export const clearOutSelectedStudent = () => ({
	type: ActionTypes.SCHOOL_GROUP_CLEAR_SELECTED_STUDENT,
});

export const getResources = (filters = {}) => {
	const actionType = ActionTypes.SCHOOL_GET_RESOURCES;
	const {
		page = 1,
		search,
		category,
		domain,
		learningOutcomes,
		readingDomain,
		curricula,
	} = filters;
	return (dispatch) => {
		if (page === 1) LoaderActions.addToQueue(actionType);
		else LoaderActions.addToQueue(ActionTypes.SCHOOL_GET_RESOURCES_LOAD_MORE);
		let url = `${get_url()}wp-json/wp/v2/resource?page=${page}&context=view`;
		if (search) url += `&search=${search}`;
		if (category) url += `&resource-category=${[category]}`;
		if (domain) url += `&domains=${domain},0`;
		if (readingDomain) url += `&domains=${readingDomain}`;
		if (learningOutcomes) url += `&level-1-outcomes=${learningOutcomes}`;
		if (curricula) url += `&curricula=${curricula}`;
		getData(url, true)
			.then((response) => {
				if (page !== 1)
					dispatch({type: ActionTypes.SCHOOL_GET_RESOURCES_LOAD_MORE});
				dispatch({
					type: actionType,
					payload: {
						resources: response.data,
						totalPages: response.headers["x-wp-totalpages"],
						lazyLoad: page > 1,
					},
				});
			})
			.catch((error) => {
				console.error(`Error retrieving resources: ${error}`);
			});
	};
};

export const getResourceCategories = () => {
	const actionType = ActionTypes.SCHOOL_GET_RESOURCE_CATEGORIES;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/wp/v2/resource-category`;
		getData(url)
			.then((response) => {
				dispatch({
					type: actionType,
					payload: response,
				});
			})
			.catch((error) => {
				console.error(`Error retrieving resources categories: ${error}`);
			});
	};
};

export const getStudents = (user, addedNewChild) => {
	const actionType = ActionTypes.SCHOOL_GET_STUDENTS;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/students?context=view`;
		getData(url)
			.then((response) => {
				dispatch({
					type: actionType,
					payload: {students: response, user, addedNewChild},
				});
			})
			.catch((error) => {
				dispatch({type: actionType});
				ErrorReporterActions.addToQueue({
					actionType,
					error,
				});
			});
	};
};

export const getStudentById = (id, user, handleFunction = {}) => {
	const actionType = ActionTypes.SCHOOL_GET_STUDENT_BY_ID;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/people/${id}`;
		getData(url)
			.then((response) => {
				if (handleFunction.handling) {
					handleFunction.handling.success(response);
					dispatch({
						type: actionType,
					});
				} else
					dispatch({
						type: actionType,
						payload: {student: response, user},
					});
			})
			.catch((error) => {
				dispatch({type: actionType});
				ErrorReporterActions.addToQueue({
					actionType,
					error,
				});
			});
	};
};

export const setCurrentClassroom = (classroom) => ({
	type: ActionTypes.SCHOOL_SET_CURRENT_CLASSROOM,
	payload: {classroom},
});

export const getStudentsInClassroom = (classroomId, {handling} = {}) => {
	const actionType = ActionTypes.SCHOOL_GET_STUDENTS_IN_CLASSROOM;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/classes/${classroomId}/students`;
		getData(url, true)
			.then((response) => {
				if (response.status === 200) {
					if (handling?.success) {
						handling.success(response.data);
						dispatch({
							type: actionType,
						});
					} else
						dispatch({
							type: actionType,
							payload: {students: response.data},
						});
				} else {
					if (handling?.error) {
						handling.error();
						dispatch({
							type: actionType,
						});
					} else
						dispatch({
							type: actionType,
							payload: {students: []},
						});

					ActivityMonitorActions.addToQueue({
						type: ACTIVITY_MONITOR_ACTIONS.ERROR,
						title: "Failed to load students",
						message: response.data.message,
					});
				}
			})
			.catch((error) => {
				console.error(`Error retrieving students: ${error}`);
			});
	};
};

export const updateStudentsInClassroom = (classroomId, body, {handling}) => {
	const actionType = ActionTypes.SCHOOL_UPDATE_STUDENTS_IN_CLASSROOM;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/classes/${classroomId}/students`;
		putData(url, body)
			.then((response) => {
				if (response.status < 300) {
					handling.success();
				}
				dispatch({
					type: actionType,
				});
			})
			.catch((error) => {
				console.log(error);
				handling.error(error);
				dispatch({
					type: actionType,
				});
			});
	};
};

export const addUsersToClassroom = (
	users,
	classroomId,
	userType,
	{handling}
) => {
	const actionType = ActionTypes.SCHOOL_ADD_USERS_TO_CLASSROOM;
	return () => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/classes/${classroomId}/${userType}`;
		Promise.all(users.map((user) => putData(url, user)))
			.then((responses) => {
				const allRequestSucceed = responses.every(
					(response) => response.status === 200
				);
				if (allRequestSucceed) {
					handling.success();
				} else {
					handling.error(responses);
				}
			})
			.catch((error) => {
				console.log(error);
				handling.error();
			});
	};
};

export const addUserToClassrooms = (
	classroomsUserIn,
	userType,
	user,
	{handling}
) => {
	const actionType = ActionTypes.SCHOOL_ADD_USER_TO_CLASSROOMS;
	return () => {
		LoaderActions.addToQueue(actionType);
		Promise.all([
			...classroomsUserIn.map((classroom) =>
				putData(
					`${get_url()}wp-json/sprig/v2/classes/${classroom}/${userType}`,
					user
				)
			),
		])
			.then((responses) => {
				if (responses.every((response) => response.status === 200)) {
					handling.success();
				} else {
					handling.error(responses);
				}
			})
			.catch((error) => {
				handling.error(error);
			});
	};
};

export const addStudentsTeachersToClassroom = (
	studentsList,
	teachersList,
	classroomId,
	{handling}
) => {
	const actionType = ActionTypes.SCHOOL_ADD_STUDENTS_TEACHERS_TO_CLASSROOM;
	return () => {
		LoaderActions.addToQueue(actionType);
		Promise.all([
			...studentsList.map((student) =>
				putData(
					`${get_url()}wp-json/sprig/v2/classes/${classroomId}/students`,
					student
				)
			),
			...teachersList.map((teacher) =>
				putData(
					`${get_url()}wp-json/sprig/v2/classes/${classroomId}/teachers`,
					teacher
				)
			),
		])
			.then((responses) => {
				if (responses.every((response) => response.status === 200)) {
					handling.success();
				} else {
					handling.error(responses);
				}
			})
			.catch(() => {
				handling.error();
			});
	};
};

export const addUserToClassroom = (classroomId, body, userType, {handling}) => {
	const actionType = ActionTypes.SCHOOL_ADD_USER_TO_CLASSROOM;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/classes/${classroomId}/${userType}`;
		putData(url, body)
			.then((response) => {
				if (response.status < 300) {
					handling.success();
				} else {
					handling.error(response);
				}
				dispatch({
					type: actionType,
				});
			})
			.catch((error) => {
				handling.error(error);
			});
	};
};

export const updateStudentClassroomInformation = (
	classroomsStudentRemovedFrom,
	classroomsToBeAddedTo,
	body,
	{handling}
) => {
	const actionType = ActionTypes.SCHOOL_UPDATE_STUDENT_CLASSROOM_INFORMATION;
	return () => {
		LoaderActions.addToQueue(actionType);
		Promise.all([
			...classroomsStudentRemovedFrom.map((classroom) =>
				deleteDataAcceptJSON(
					`${get_url()}wp-json/sprig/v2/classes/${classroom}/students/${
						body.student
					}`
				)
			),
			...classroomsToBeAddedTo.map((classroom) =>
				putData(
					`${get_url()}wp-json/sprig/v2/classes/${classroom}/students`,
					body
				)
			),
		])
			.then((responses) => {
				if (responses.every((response) => response.status === 200)) {
					handling.success();
				} else {
					handling.error(responses);
				}
			})
			.catch(() => {
				handling.error();
			});
	};
};

export const getStudentsCodes = (classId, {handling}) => {
	const actionType = ActionTypes.SCHOOL_GET_STUDENT_CODES;
	return () => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/classes/${classId}/codes`;
		getData(url, true)
			.then((response) => {
				if (response.status === 200) {
					handling.success(response.data);
				} else {
					handling.error();
				}
			})
			.catch((error) => {
				handling.error(error);
			});
	};
};

export const generateStudentsCodes = (classId, {handling}) => {
	const actionType = ActionTypes.SCHOOL_GENERATE_STUDENT_CODES;
	return () => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/classes/${classId}/codes`;
		postData(url)
			.then((response) => {
				if (response.status === 201) {
					handling.success(response);
				} else {
					handling.error(response);
				}
			})
			.catch((error) => {
				handling.error(error);
			});
	};
};

export const deleteStudentsCodes = (classId, {handling}) => {
	const actionType = ActionTypes.SCHOOL_DELETE_STUDENT_CODES;
	return () => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/classes/${classId}/codes`;
		deleteData(url)
			.then((response) => {
				if (response.status === 200) {
					handling.success();
				}
			})
			.catch((error) => {
				handling.error(error);
			});
	};
};

export const removeUserFromClassroom = (
	classroomId,
	userType,
	userId,
	{handling}
) => {
	const actionType = ActionTypes.SCHOOL_REMOVE_USER_FROM_CLASSROOM;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/classes/${classroomId}/${userType}/${userId}`;
		deleteDataAcceptJSON(url)
			.then((response) => {
				if (response.status < 300) {
					handling.success();
				} else {
					handling.error(response);
				}
				dispatch({
					type: actionType,
				});
			})
			.catch((error) => {
				handling.error(error);
			});
	};
};

export const updateCurrentStudent = (studentId) => {
	return {
		type: ActionTypes.SCHOOL_UPDATE_CURRENT_STUDENT,
		payload: studentId,
	};
};

export const getStudentSubmissions = (studentId, termId, domain) => {
	const actionType = ActionTypes.SCHOOL_GET_STUDENT_SUBMISSIONS;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const queryString = getSubmissionQueryString({
			assessed: studentId,
			term: termId,
			only_most_recent: true,
			context: "view",
		});
		const submissionsUrl = `${get_url()}wp-json/sprig/v2/submissions${queryString}`;
		getData(submissionsUrl)
			.then((submissions) => {
				dispatch({
					type: actionType,
					payload: {
						submissions,
						domain,
					},
				});
			})
			.catch((error) => {
				console.error(error);
			});
	};
};

export const getSubmissions = (request, {handling}) => {
	const actionType = ActionTypes.SCHOOL_GET_SUBMISSIONS;
	return () => {
		LoaderActions.addToQueue(actionType);
		const queryString = getSubmissionQueryString(request);
		const submissionsUrl = `${get_url()}wp-json/sprig/v2/submissions${queryString}`;
		getData(submissionsUrl)
			.then((submissions) => {
				handling.success(submissions);
			})
			.catch((error) => {
				console.error(error);
			});
	};
};

export const getSubmissionsAndKeepResponses = (request) => {
	const actionType = ActionTypes.SCHOOL_GET_SUBMISSIONS_AND_KEEP_RESPONSES;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const queryString = getSubmissionQueryString(request);
		const submissionsUrl = `${get_url()}wp-json/sprig/v2/submissions${queryString}`;
		getData(submissionsUrl)
			.then((submissions) => {
				dispatch({
					type: actionType,
					payload: {
						submissions,
					},
				});
			})
			.catch((error) => {
				console.error(error);
			});
	};
};

export const postSubmissions = (request, {handling}) => {
	const actionType = ActionTypes.SCHOOL_POST_SUBMISSION;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/submissions`;
		postData(url, request)
			.then((response) => {
				if (handling?.success) {
					handling.success(response);
				}
			})
			.catch((error) => {
				dispatch({
					type: actionType,
					payload: null,
				});
			});
	};
};

export const getStudentOralLanguageResults = (
	studentId,
	termId,
	assessmentId
) => {
	const actionType = ActionTypes.SCHOOL_GET_STUDENT_ORAL_LANGUAGE_RESULTS;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const submissionsUrl = `${get_url()}wp-json/sprig/v2/submissions?assessed=${studentId}&term=${termId}&only_most_recent=true&context=view`;
		getData(submissionsUrl)
			.then((submissions) => {
				const mostRecentSubmission = getMostRecentSubmission(
					submissions,
					assessmentId
				);
				if (mostRecentSubmission) {
					const questions = getQuestions(mostRecentSubmission.id);
					const audio = getStorytellingAudio(mostRecentSubmission.id);
					Promise.all([questions, audio])
						.then(([questions, audio]) => {
							dispatch({
								type: actionType,
								payload: {
									questions,
									audio,
									grade_id: mostRecentSubmission.grade_id,
									submissions,
								},
							});
						})
						.catch((error) => {
							console.error(
								`Error retrieving student oral language questions and audio: ${error}`
							);
						});
				} else {
					dispatch({
						type: actionType,
						payload: {no_most_recent: true, submissions},
					});
				}
			})
			.catch((error) => {
				console.error(`Error retrieving student submissions: ${error}`);
			});
	};
};

export const getStudentMathResults = (studentId, termId, assessmentId) => {
	const actionType = ActionTypes.SCHOOL_GET_STUDENT_MATH_RESULTS;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const submissionsUrl = `${get_url()}wp-json/sprig/v2/submissions?assessed=${studentId}&term=${termId}&only_most_recent=true&context=view`;
		getData(submissionsUrl)
			.then((submissions) => {
				const mostRecentSubmission = getMostRecentSubmission(
					submissions,
					assessmentId
				);
				if (mostRecentSubmission) {
					const levels = getData(
						`${get_url()}wp-json/sprig/v2/students/${studentId}/stats?domain=${DOMAIN_MATH}`
					);
					const questions = getQuestions(mostRecentSubmission.id);
					const files = getMathResultFiles(mostRecentSubmission);
					Promise.all([levels, questions, files])
						.then(([levels, questions, files]) => {
							dispatch({
								type: actionType,
								payload: {
									levels,
									questions,
									files,
									submissions,
								},
							});
						})
						.catch((error) => {
							console.error(
								`Error retrieving student math levels, questions, and files: ${error}`
							);
						});
				} else {
					dispatch({
						type: actionType,
						payload: {no_most_recent: true, submissions},
					});
				}
			})
			.catch((error) => {
				console.error(`Error retrieving student submissions: ${error}`);
			});
	};
};

export const setDomain = (domain) => {
	return {
		type: ActionTypes.SCHOOL_SET_DOMAIN,
		payload: domain,
	};
};

export const getCircleCharts = (request, {handling}) => {
	const actionType = ActionTypes.SCHOOL_GET_CIRCLE_CHARTS;
	const {class_id, outcome_id} = request;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/circleCharts?classroom_id=${class_id}&outcome_id=${outcome_id}${
			request?.end ? `&end=${request.end}` : ""
		}`;
		getData(url)
			.then((response) => {
				handling.success(response[0]);
				dispatch({
					type: actionType,
					payload: {
						classroomId: class_id,
						outcomeId: outcome_id,
						response: {...response[0]},
					},
				});
			})
			.catch((error) => {
				console.error(error);
			});
	};
};

export const updateCircleCharts = (body, {handling}) => {
	const actionType = ActionTypes.SCHOOL_UPDATE_CIRCLE_CHARTS;
	return () => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/circleCharts`;
		postData(url, body)
			.then((response) => {
				if (response.status < 300) {
					handling.success(response);
				} else {
					handling.error(response);
				}
			})
			.catch((error) => {
				handling.error(error);
			});
	};
};

export const getCircleChartNotes = (request, {handling}) => {
	const actionType = ActionTypes.SCHOOL_GET_CIRCLE_CHART_NOTES;
	const {class_id, outcome_id, student_id} = request;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/circleChartNotes?class_id=${class_id}&student_id=${student_id}&outcome_id=${outcome_id}`;
		getData(url)
			.then((response) => {
				handling.success(response);
				dispatch({
					type: actionType,
				});
			})
			.catch((error) => {
				console.error(error);
			});
	};
};

export const updateCircleChartNote = (request, {handling}) => {
	const actionType = ActionTypes.SCHOOL_UPDATE_CIRCLE_CHART_NOTE;
	const {note_id, contents} = request;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/circleChartNotes/${note_id}`;
		postData(url, {contents})
			.then((response) => {
				handling.success(response);
				dispatch({
					type: actionType,
				});
			})
			.catch((error) => {
				console.error(error);
			});
	};
};

export const createNewCircleChartNote = (request, {handling}) => {
	const actionType = ActionTypes.SCHOOL_CREATE_CIRCLE_CHART_NOTE;
	const {outcome_id, student_id, class_id, contents} = request;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/circleChartNotes`;
		postData(url, {outcome_id, student_id, class_id, contents})
			.then((response) => {
				handling.success(response);
				dispatch({
					type: actionType,
				});
			})
			.catch((error) => {
				console.error(error);
			});
	};
};

export const deleteCircleChartNote = (note_id, {handling}) => {
	const actionType = ActionTypes.SCHOOL_DELETE_CIRCLE_CHART_NOTE;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/circleChartNotes/${note_id}`;
		deleteData(url)
			.then((response) => {
				handling.success(response);
				dispatch({
					type: actionType,
				});
			})
			.catch((error) => {
				console.error(error);
			});
	};
};

export const getAllPreKMathStudentData = () => {
	const actionType = ActionTypes.SCHOOL_GET_ALL_PRE_K_MATH_STUDENT_DATA;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		const url = `${get_url()}wp-json/sprig/v2/apps/prek_math/students`;
		getData(url)
			.then((response) => {
				dispatch({
					type: actionType,
					payload: {response},
				});
			})
			.catch((error) => {
				dispatch({
					type: actionType,
				});
			});
	};
};
