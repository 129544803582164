import "../../../../../../Stylesheets/css/scss/Components/Portal/Public/Assessment/Public/QuestionRow.css";

import React from "react";

import {Icon} from "../../../../../Public";
import {Question, QuestionAnswerKey} from "./QuestionTable";

export const QuestionRow = ({
	img,
	label,
	selectionAnswerKey,
	selectionValues,
}: Question) => (
	<div className="assessment-question-row">
		<div>
			<div className="assessment-question-img-label">
				<img className="assessment-question-img" src={img.src} alt={img.alt} />
				<div className="assessment-question-label">{label}</div>
			</div>
		</div>
		{selectionAnswerKey.map((answer: QuestionAnswerKey) => (
			<div
				className="assessment-question-row-option"
				key={`assessment-question-option-${answer.text}`}
			>
				{selectionValues.includes(answer.value) && (
					<Icon type="faCheckCircle" size="2x" className="text-primary" />
				)}
			</div>
		))}
	</div>
);
