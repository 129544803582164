import "../../../Stylesheets/css/scss/Components/Public/Form.css";

import React from "react";

import {FormAlert} from "./FormAlert";
import {FormBody} from "./FormBody";
import {FormCheckbox} from "./FormCheckbox";
import {FormDropdown} from "./FormDropdown";
import {FormGroup} from "./FormGroup";
import {FormHeader} from "./FormHeader";
import {FormRating} from "./FormRating";

interface FormProps {
	children: JSX.Element[];
	id?: string;
}

export const Form = ({children, id}: FormProps) => (
	<div className="form-component container-fluid w-100" id={id}>
		<div className="form-content row w-100">{children}</div>
	</div>
);

Form.Header = FormHeader;
Form.Body = FormBody;
Form.Alert = FormAlert;
Form.Group = FormGroup;
Form.Checkbox = FormCheckbox;
Form.Dropdown = FormDropdown;
Form.Rating = FormRating;
