import * as ActionTypes from "../Redux/ActionTypes";

export const addToQueue = ({actionType, error}) => ({
	type: ActionTypes.ERROR_REPORTER_ADD_TO_QUEUE,
	payload: {actionType, error},
});

export const removeErrorFromQueue = (actionType) => ({
	type: ActionTypes.ERROR_REPORTER_REMOVE_ERROR_FROM_QUEUE,
	payload: actionType,
});
