import * as d3 from "d3";
import ReactTooltip from "react-tooltip";

import {
	BloomBase64,
	FlowerBase64,
	SeedBase64,
	SproudBase64,
} from "../../../../../Assets/HolisticMathPrototype/IconBase64";
import {
	getChartConfiguration,
	sortThroughArrayOfElementsAndGetGreatestDimensions,
} from "./Helpers";

export const Line = ({node, options = {}, data, isHolisticMath}) => {
	let chartConfiguration = getChartConfiguration(options);
	const axisLabelSpacing = 10;

	const drawChart = () => {
		const width = node?.offsetWidth ? node.offsetWidth : 400;
		const height = node?.offsetHeight ? node.offsetHeight : 200;
		const innerWidth =
			width - chartConfiguration.margin.left - chartConfiguration.margin.right;
		const innerHeight =
			height - chartConfiguration.margin.top - chartConfiguration.margin.bottom;

		const xValues = data[0].map((datum) => datum.x);

		const currentPoint = data[0].find((datum) => datum.current);

		const xScale = d3.scalePoint().domain(xValues).range([0, innerWidth]);

		const yScale = d3
			.scaleLinear()
			.domain(options?.axis?.y?.domain)
			.range([innerHeight, 0]);

		const xAxis = d3.axisBottom(xScale).tickSizeOuter(0);
		const yAxis = d3
			.axisLeft(yScale)
			.tickValues([1, 2, 3, 4])
			.tickFormat(d3.format("d"))
			.tickSizeOuter(0);

		const line = d3
			.line()
			.x((d) => xScale(d.x))
			.y((d) => yScale(d.y))
			.curve(d3.curveMonotoneX);

		const svg = d3
			.select(node)
			.append("svg")
			.attr("class", "d3-line-chart")
			.attr("width", width)
			.attr("height", height)
			.append("g")
			.attr(
				"transform",
				`translate(${chartConfiguration.margin.left},${chartConfiguration.margin.top})`
			);

		/*
			========================================
				Optional Tools: Range bands on axis
			========================================
		*/
		svg
			.append("g")
			.attr("class", "x-axis-band-dividers")
			.selectAll("x-axis-band-divider")
			.data(xValues)
			.enter()
			.append("path")
			.attr("class", "x-axis-band-divider")
			.attr("d", (d) => `M${xScale(d)},${innerHeight}L${xScale(d)},0`);

		svg
			.append("g")
			.attr("class", "x-axis-bands")
			.selectAll("x-axis-band")
			.data(data[0])
			.enter()
			.append("rect")
			.attr(
				"class",
				(d) => `x-axis-band${d.x === currentPoint?.x ? " current" : ""}`
			)
			.attr("width", innerWidth / (xValues.length - 1))
			.attr("height", innerHeight)
			.attr("transform", (d) => `translate(${xScale(d.x)},0)`)
			.on("mousedown", (d) => options?.callbacks?.bandSelect(d));

		/*
			========================================
				Optional Tools: Deviation From Line
			========================================
		*/
		if (options?.deviationDataAvailable) {
			const deviationContainer = svg.append("g").attr("class", "deviation");
			data.forEach((lineData) => {
				lineData.forEach((datum, index) => {
					const deviations = [];
					const deviationBelow = datum?.deviation?.below
						? {...datum.deviation.below, type: "below"}
						: null;
					const deviationAbove = datum?.deviation?.above
						? {...datum.deviation.above, type: "above"}
						: null;
					if (deviationBelow) deviations.push(deviationBelow);
					if (deviationAbove) deviations.push(deviationAbove);
					if (deviations?.length) {
						deviations.forEach((deviation) => {
							if (index !== lineData.length - 1) {
								const nextDatum = lineData[index + 1];
								deviationContainer
									.append("polygon")
									.attr(
										"class",
										`deviation ${deviation.type}${
											datum.currentDeviation &&
											datum.currentDeviation === deviation.type
												? " selected"
												: ""
										}`
									)
									.attr("data-for", "d3-chart-tooltip")
									.attr("data-tip", deviation.text)
									.attr(
										"data-place",
										deviation.type === "above" ? "top" : "bottom"
									)
									.attr(
										"points",
										`${xScale(datum.x)},${yScale(datum.y)} ${xScale(
											datum.x
										)},${yScale(deviation.value)} ${xScale(
											nextDatum.x
										)},${yScale(
											nextDatum.deviation[deviation.type].value
										)} ${xScale(nextDatum.x)},${yScale(nextDatum.y)}`
									)
									.on("mousedown", () =>
										options?.callbacks?.deviationSelect({
											...datum,
											...deviation,
										})
									);
							}
						});
					}
				});
			});
		}
		/*
			========================================
				Required: Axis
			========================================
		*/
		const xAxisElement = svg
			.append("g")
			.attr("class", "axis x-axis")
			.attr("transform", `translate(0, ${innerHeight})`)
			.call(xAxis);

		const yAxisElement = svg
			.append("g")
			.attr("class", "axis y-axis")
			.call(yAxis);

		const lines = svg.append("g").attr("class", "lines");

		data.forEach((datum, index) => {
			const restructuredData = [];
			datum.forEach((d, index) => {
				if (index !== datum.length - 1) {
					const nextDataObject = datum[index + 1];
					restructuredData.push([d, nextDataObject]);
				}
			});

			lines
				.selectAll("linedata")
				.data(restructuredData)
				.enter()
				.append("path")
				.attr(
					"class",
					(d) =>
						`line line-${index}${d[1] && d[1].class ? ` ${d[1].class}` : ""}`
				)
				.attr("d", line);
		});
		/*
			========================================
				Optional Tools: Current Point
			========================================
		*/

		if (currentPoint) {
			svg
				.append("g")
				.append("circle")
				.attr("class", "current-point")
				.attr("cx", xScale(currentPoint.x))
				.attr("cy", yScale(currentPoint.y))
				.attr("r", 10);
		}

		/*
			========================================
				Optional Tools: Axis Titles
			========================================
		*/
		if (chartConfiguration?.axis?.x?.label) {
			xAxisElement
				.append("text")
				.attr("class", "axis-label x-axis-label")
				.attr("text-anchor", "middle")
				.attr(
					"transform",
					`translate(${innerWidth / 2},${
						chartConfiguration.dimensions
							? chartConfiguration.margin.bottom -
							  chartConfiguration.dimensions?.axis?.x?.label?.height
							: chartConfiguration.margin.bottom
					})`
				)
				.text(chartConfiguration.axis.x.label);
		}

		if (chartConfiguration?.axis?.y?.label) {
			yAxisElement
				.append("text")
				.attr("class", "axis-label y-axis-label")
				.attr("text-anchor", "middle")
				.attr(
					"transform",
					`rotate(-90) translate(-${innerHeight / 2},${
						chartConfiguration.dimensions
							? chartConfiguration.dimensions?.axis?.x?.label?.height -
							  chartConfiguration.margin.left
							: chartConfiguration.margin.left
					})`
				)
				.text(chartConfiguration.axis.y.label);
		}
	};

	const saveTextDimensions = () => {
		const svg = d3.select(node);
		const xAxis = svg.select(".x-axis");
		const yAxis = svg.select(".y-axis");
		const xAxisTickElements = xAxis.selectAll(".tick").nodes();
		const yAxisTickElements = yAxis.selectAll(".tick").nodes();
		const greatestXAxisDimensions =
			sortThroughArrayOfElementsAndGetGreatestDimensions(xAxisTickElements);
		const greatestYAxisDimensions =
			sortThroughArrayOfElementsAndGetGreatestDimensions(yAxisTickElements);
		const lastXAxisElementDimensions =
			sortThroughArrayOfElementsAndGetGreatestDimensions([
				xAxisTickElements[xAxisTickElements.length - 1],
			]);
		const xAxisLabelDimension =
			sortThroughArrayOfElementsAndGetGreatestDimensions([
				svg.select(".x-axis-label").node(),
			]);
		const yAxisLabelDimension =
			sortThroughArrayOfElementsAndGetGreatestDimensions([
				svg.select(".y-axis-label").node(),
			]);
		chartConfiguration = {
			...chartConfiguration,
			dimensions: {
				axis: {
					x: {
						label: xAxisLabelDimension,
					},
					y: {
						label: yAxisLabelDimension,
					},
				},
			},
			margin: chartConfiguration.margin
				? {
						...chartConfiguration.margin,
						left:
							greatestYAxisDimensions?.width +
							yAxisLabelDimension?.height +
							chartConfiguration.margin.left +
							axisLabelSpacing,
						bottom:
							greatestXAxisDimensions?.height +
							xAxisLabelDimension?.height +
							chartConfiguration.margin.bottom +
							axisLabelSpacing,
						right:
							chartConfiguration.margin.right +
							lastXAxisElementDimensions.width / 2,
				  }
				: {},
		};
	};

	const removeChart = () => {
		const parent = d3.select(node);
		const svg = parent.select("svg");
		svg.remove();
	};

	const drawIcon = () => {
		const svg = d3.select(node);
		const yAxis = svg.select(".y-axis");
		const yAxisTickElements = yAxis.selectAll(".tick");

		yAxisTickElements
			.append("image")
			.attr("xlink:href", (d, i) => {
				if (i === 0) return SeedBase64;
				else if (i === 1) return SproudBase64;
				else if (i === 2) return BloomBase64;
				else return FlowerBase64;
			})
			.attr("x", -25)
			.attr("y", -8)
			.attr("width", 20)
			.attr("height", 20);
	};

	if (data?.length) {
		drawChart();
		if (isHolisticMath) drawIcon();
		saveTextDimensions();
		removeChart();
		drawChart();
		if (isHolisticMath) drawIcon();
		ReactTooltip.rebuild();
	}
};
