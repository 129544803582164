import "../../../../Stylesheets/css/scss/Components/Portal/Public/Toasts/ActivityMonitor.css";

import React from "react";
import {connect} from "react-redux";

import {ReadingToast, Toast} from "./index";

const ActivityMonitor = ({queue}) => {
	return (
		<div
			className={`portal-activity-monitor${
				queue.every((toast) => toast.isReading) ? " reading-toast-monitor" : ""
			}`}
		>
			<div className="portal-activity-monitor-toasts">
				{queue.map((toast) =>
					toast.isReading ? (
						<ReadingToast key={toast.id} {...toast} />
					) : (
						<Toast key={toast.id} {...toast} />
					)
				)}
			</div>
		</div>
	);
};

interface ActivityMonitorReducerState {
	activityMonitor: {
		queue: [];
	};
}

export default connect((state: ActivityMonitorReducerState) => ({
	queue: state.activityMonitor.queue,
}))(ActivityMonitor);
