/* 
	REDUX ACTIONTYPES BY STORE NAME
*/

// Activity ActionTypes
export const ACTIVITY_GET_ALL = "ACTIVITY_GET_ALL";
export const ACTIVITY_GET_LANGUAGES = "ACTIVITY_GET_LANGUAGES";
export const ACTIVITY_GET_PLACES = "ACTIVITY_GET_PLACES";
export const ACTIVITY_GET_DIFFICULTIES = "ACTIVITY_GET_DIFFICULTIES";
export const ACTIVITY_GET_LEARNING_OUTCOMES = "ACTIVITY_GET_LEARNING_OUTCOMES";
export const ACTIVITY_GET_GENERAL_OUTCOMES = "ACTIVITY_GET_GENERAL_OUTCOMES";
export const ACTIVITY_GET_SPECIFIC_OUTCOMES = "ACTIVITY_GET_SPECIFIC_OUTCOMES";
export const ACTIVITY_GET_COMPLETION_COUNTS = "ACTIVITY_GET_COMPLETION_COUNTS";
export const ACTIVITY_RECOMMEND_AND_COMPLETE_WITH_STUDENTS =
	"ACTIVITY_RECOMMEND_AND_COMPLETE_WITH_STUDENTS";
export const ACTIVITY_UPDATE_TRANSLATIONS = "ACTIVITY_UPDATE_TRANSLATIONS";
export const ACTIVITY_POST_RECOMMEND_ACTIVITY =
	"ACTIVITY_POST_RECOMMEND_ACTIVITY";
export const ACTIVITY_GET_ALL_BADGES = "ACTIVITY_GET_ALL_BADGES";
export const ACTIVITY_GET_BADGES_PERSON = "ACTIVITY_GET_BADGES_PERSON";
export const ACTIVITY_GET_ACTIVITY_COMPLETIONS_BY_CLASSROOM =
	"ACTIVITY_GET_ACTIVITY_COMPLETIONS_BY_CLASSROOM";
export const ACTIVITY_UPDATE_EARNED_BADGES = "ACTIVITY_UPDATE_EARNED_BADGES";
export const ACTIVITY_GET_DOMAIN = "ACTIVITY_GET_DOMAIN";
export const ACTIVITY_GET_DURATION = "ACTIVITY_GET_DURATION";
export const ACTIVITY_GET_GRADE = "ACTIVITY_GET_GRADE";
export const ACTIVITY_GET_CURRICULA = "ACTIVITY_GET_CURRICULA";
export const ACTIVITY_GET_COMPETENCIES = "ACTIVITY_GET_COMPETENCIES";
export const ACTIVITY_CLEAR_ACTIVITIES = "ACTIVITY_CLEAR_ACTIVITIES";
export const ACTIVITY_CHECK_IF_ACTIVITY_SAVED =
	"ACTIVITY_CHECK_IF_ACTIVITY_SAVED";
export const ACTIVITY_GET_ACTIVITY_BY_ID = "ACTIVITY_GET_ACTIVITY_BY_ID";
export const ACTIVITY_GET_ACTIVITY_BY_SLUG = "ACTIVITY_GET_ACTIVITY_BY_SLUG";
export const ACTIVITY_CREATE_ACTIVITY = "ACTIVITY_CREATE_ACTIVITY";
export const ACTIVITY_EDIT_ACTIVITY = "ACTIVITY_EDIT_ACTIVITY";
export const ACTIVITY_SAVE_ACTIVITY = "ACTIVITY_SAVE_ACTIVITY";
export const ACTIVITY_SET_FILTERS = "ACTIVITY_SET_FILTERS";
export const ACTIVITY_GET_SAVED_ACTIVITIES = "ACTIVITY_GET_SAVED_ACTIVITIES";
export const ACTIVITY_POST_COMPLETE_ACTIVITY =
	"ACTIVITY_POST_COMPLETE_ACTIVITY";
export const ACTIVITY_PUT_HIDE_ACTIVITY = "ACTIVITY_PUT_HIDE_ACTIVITY";
export const ACTIVITY_PUT_UNHIDE_ACTIVITY = "ACTIVITY_PUT_UNHIDE_ACTIVITY";
export const ACTIVITY_PUT_SEND_THUMBS_UP_DOWN_FEEDBACK =
	"ACTIVITY_PUT_SEND_THUMBS_UP_DOWN_FEEDBACK";
export const ACTIVITY_GET_ACTIVITY_COMMENT = "ACTIVITY_GET_ACTIVITY_COMMENT";
export const ACTIVITY_POST_ACTIVITY_COMMENT = "ACTIVITY_POST_ACTIVITY_COMMENT";
export const ACTIVITY_GET_LEARNING_OUTCOMES_BY_DOMAIN =
	"ACTIVITY_GET_LEARNING_OUTCOMES_BY_DOMAIN";
export const ACTIVITY_GET_GENERAL_OUTCOMES_BY_DOMAIN =
	"ACTIVITY_GET_GENERAL_OUTCOMES_BY_DOMAIN";
export const ACTIVITY_GET_SPECIFIC_OUTCOMES_BY_DOMAIN =
	"ACTIVITY_GET_SPECIFIC_OUTCOMES_BY_DOMAIN";
export const ACTIVITY_GET_ACTIVITY_ASSESSMENT_QUESTIONS_BY_ASSESSMENT_ID =
	"ACTIVITY_GET_ACTIVITY_ASSESSMENT_QUESTIONS_BY_ASSESSMENT_ID";

// ActivityForm ActionTypes
export const ACTIVITY_FORM_EDIT_TITLE = "ACTIVITY_FORM_EDIT_TITLE";
export const ACTIVITY_FORM_EDIT_DESCRIPTION = "ACTIVITY_FORM_EDIT_DESCRIPTION";
export const ACTIVITY_FORM_EDIT_MATERIALS = "ACTIVITY_FORM_EDIT_MATERIALS";
export const ACTIVITY_FORM_EDIT_PLACE = "ACTIVITY_FORM_EDIT_PLACE";
export const ACTIVITY_FORM_EDIT_LANGUAGE = "ACTIVITY_FORM_EDIT_LANGUAGE";
export const ACTIVITY_FORM_EDIT_GRADE = "ACTIVITY_FORM_EDIT_GRADE";
export const ACTIVITY_FORM_EDIT_DIFFICULTY = "ACTIVITY_FORM_EDIT_DIFFICULTY";
export const ACTIVITY_FORM_EDIT_DURATION = "ACTIVITY_FORM_EDIT_DURATION";
export const ACTIVITY_FORM_EDIT_DOMAIN = "ACTIVITY_FORM_EDIT_DOMAIN";
export const ACTIVITY_FORM_EDIT_CURRICULUM = "ACTIVITY_FORM_EDIT_CURRICULUM";
export const ACTIVITY_FORM_EDIT_LEARNING_OUTCOME =
	"ACTIVITY_FORM_EDIT_LEARNING_OUTCOME";
export const ACTIVITY_FORM_EDIT_GENERAL_OUTCOME =
	"ACTIVITY_FORM_EDIT_GENERAL_OUTCOME";
export const ACTIVITY_FORM_EDIT_SPECIFIC_OUTCOME =
	"ACTIVITY_FORM_EDIT_SPECIFIC_OUTCOME";
export const ACTIVITY_FORM_EDIT_COMPENTENCY = "ACTIVITY_FORM_EDIT_COMPENTENCY";
export const ACTIVITY_FORM_ADD_LEARNING_OUTCOME =
	"ACTIVITY_FORM_ADD_LEARNING_OUTCOME";
export const ACTIVITY_FORM_ADD_CURRICULUM = "ACTIVITY_FORM_ADD_CURRICULUM";
export const ACTIVITY_FORM_ADD_GENERAL_OUTCOME =
	"ACTIVITY_FORM_ADD_GENERAL_OUTCOME";
export const ACTIVITY_FORM_DELETE_LEARNING_OUTCOME =
	"ACTIVITY_FORM_DELETE_LEARNING_OUTCOME";
export const ACTIVITY_FORM_DELETE_CURRICULUM =
	"ACTIVITY_FORM_DELETE_CURRICULUM";
export const ACTIVITY_FORM_DELETE_GENERAL_OUTCOME =
	"ACTIVITY_FORM_DELETE_GENERAL_OUTCOME";
export const ACTIVITY_FORM_READ_ACTIVITY = "ACTIVITY_FORM_READ_ACTIVITY";
export const ACTIVITY_FORM_NEW_ACTIVITY = "ACTIVITY_FORM_NEW_ACTIVITY";
export const ACTIVITY_FORM_VALIDATE_FIELDS = "ACTIVITY_FORM_VALIDATE_FIELDS";
export const ACTIVITY_FORM_EDIT_SHARE_PERMISSION =
	"ACTIVITY_FORM_EDIT_SHARE_PERMISSION";

// Activity Monitor ActionTypes
export const ACTIVITY_MONITOR_ADD_TO_QUEUE = "ACTIVITY_MONITOR_ADD_TO_QUEUE";
export const ACTIVITY_MONITOR_REMOVE_FROM_QUEUE =
	"ACTIVITY_MONITOR_REMOVE_FROM_QUEUE";

// AppContent ActionTypes
export const APPCONTENT_SET_CURRENT_ROLE = "USER_SET_CURRENT_ROLE";
export const APPCONTENT_DEFAULT_CURRENT_ROLE =
	"APPCONTENT_DEFAULT_CURRENT_ROLE";
export const APPCONTENT_INITIATE_PORTAL_TOUR =
	"APPCONTENT_INITIATE_PORTAL_TOUR";
export const APPCONTENT_PROCEED_TO_NEXT_PORTAL_TOUR_STEP =
	"APPCONTENT_PROCEED_TO_NEXT_PORTAL_TOUR_STEP";
export const APPCONTENT_END_CURRENT_TOUR = "APPCONTENT_END_CURRENT_TOUR";

// ErrorReporter ActionTypes
export const ERROR_REPORTER_ADD_TO_QUEUE = "ERROR_REPORTER_ADD_TO_QUEUE";
export const ERROR_REPORTER_REMOVE_ERROR_FROM_QUEUE =
	"ERROR_REPORTER_REMOVE_ERROR_FROM_QUEUE";

// Loader ActionTypes
export const LOADER_QUEUE_ADD = "LOADER_QUEUE_ADD";

// Modal ActionTypes
export const HIDE_MODAL = "HIDE_MODAL";
export const SHOW_MODAL = "SHOW_MODAL";
export const MODAL_UPDATE_PROPS = "MODAL_UPDATE_PROPS";

// Notification ActionTypes
export const NOTIFICATION_GET = "NOTIFICATION_GET";
export const NOTIFICATION_POST = "NOTIFICATION_POST";
export const NOTIFICATION_HIDE_SHOW = "NOTIFICATION_HIDE_SHOW";

// PortalState ActionTypes
export const PORTAL_STATE_ADMIN_SET_USER_SEARCH =
	"PORTAL_STATE_ADMIN_SET_USER_SEARCH";
export const PORTAL_STATE_ADMIN_IMPORT_ADD_SCHOOL =
	"PORTAL_STATE_ADMIN_IMPORT_ADD_SCHOOL";
export const PORTAL_STATE_ADMIN_IMPORT_DELETE_SCHOOL =
	"PORTAL_STATE_ADMIN_IMPORT_DELETE_SCHOOL";
export const PORTAL_STATE_ADMIN_IMPORT_UPDATE_SCHOOL =
	"PORTAL_STATE_ADMIN_IMPORT_UPDATE_SCHOOL";
export const PORTAL_STATE_ADMIN_IMPORT_ADD_CLASSROOM =
	"PORTAL_STATE_ADMIN_IMPORT_ADD_CLASSROOM";
export const PORTAL_STATE_ADMIN_IMPORT_DELETE_CLASSROOM =
	"PORTAL_STATE_ADMIN_IMPORT_DELETE_CLASSROOM";
export const PORTAL_STATE_ADMIN_IMPORT_UPDATE_CLASSROOM =
	"PORTAL_STATE_ADMIN_IMPORT_UPDATE_CLASSROOM";
export const PORTAL_STATE_ADMIN_IMPORT_SET_LIMIT =
	"PORTAL_STATE_ADMIN_IMPORT_SET_LIMIT";
export const PORTAL_STATE_FLYOUT_NAVIGATION_TOGGLE =
	"PORTAL_STATE_FLYOUT_NAVIGATION_TOGGLE";
export const PORTAL_STATE_CAREGIVER_SET_ACTIVITIES_FILTER_BY =
	"PORTAL_STATE_CAREGIVER_SET_ACTIVITIES_FILTER_BY";
export const PORTAL_STATE_EDUCATOR_UPDATE_ASSESSMENT_RESULT =
	"PORTAL_STATE_EDUCATOR_UPDATE_ASSESSMENT_RESULT";
export const PORTAL_STATE_CAREGIVER_UPDATE_SIGNUP_TYPE =
	"PORTAL_STATE_CAREGIVER_UPDATE_SIGNUP_TYPE";
export const PORTAL_STATE_SET_ACTIVITIES_LIST =
	"PORTAL_STATE_SET_ACTIVITIES_LIST";
export const PORTAL_STATE_SET_ACTIVITIES_LIST_CURRENT_CARD =
	"PORTAL_STATE_SET_ACTIVITIES_LIST_CURRENT_CARD";
export const PORTAL_STATE_READING_CIRCLE_CHART =
	"PORTAL_STATE_READING_CIRCLE_CHART";
export const PORTAL_STATE_READING_CIRCLE_CHART_FILTER_SKILLS_BY_GRADE =
	"PORTAL_STATE_READING_CIRCLE_CHART_FILTER_SKILLS_BY_GRADE";
export const PORTAL_STATE_READING_INITIAL_WELCOME_MODAL =
	"PORTAL_STATE_READING_INITIAL_WELCOME_MODAL";

// Report ActionTypes
export const REPORT_GET = "REPORT_GET";
export const REPORT_GET_ALL = "REPORT_GET_ALL";
export const REPORT_SET_DOMAIN = "REPORT_SET_DOMAIN";
export const REPORT_GET_SINGLE = "REPORT_GET_SINGLE";
export const REPORT_GET_STUDENT_SCREEN_TIME_REPORT =
	"REPORT_GET_STUDENT_SCREEN_TIME_REPORT";

// School ActionTypes
export const SCHOOL_GET_CLASSES = "SCHOOL_GET_CLASSES";
export const SCHOOL_GET_READING_DEMO_CLASSES =
	"SCHOOL_GET_READING_DEMO_CLASSES";
export const SCHOOL_GET_SCHOOLS = "SCHOOL_GET_SCHOOLS";
export const SCHOOL_SET_CURRENT_SCHOOL_ID = "SCHOOL_SET_CURRENT_SCHOOL_ID";
export const SCHOOL_CREATE_SCHOOL = "SCHOOL_CREATE_SCHOOL";
export const SCHOOL_INVITE_TEACHER_TO_CLASS = "SCHOOL_INVITE_TEACHER_TO_CLASS";
export const SCHOOL_UPDATE_SCHOOL = "SCHOOL_UPDATE_SCHOOL";
export const SCHOOL_GET_DIVISION_ID = "SCHOOL_GET_DIVISION_ID";
export const SCHOOL_CREATE_CLASSROOM = "SCHOOL_CREATE_CLASSROOM";
export const SCHOOL_GET_CLASSROOM = "SCHOOL_GET_CLASSROOM";
export const SCHOOL_UPDATE_CLASSROOM = "SCHOOL_UPDATE_CLASSROOM";
export const SCHOOL_GET_SCHOOL_BY_ID = "SCHOOL_GET_SCHOOL_BY_ID";
export const SCHOOL_GET_TERMS = "SCHOOL_GET_TERMS";
export const SCHOOL_GET_TERM_CURRENT = "SCHOOL_GET_TERM_CURRENT";
export const SCHOOL_UPDATE_DIVISION = "SCHOOL_UPDATE_DIVISION";
export const SCHOOL_GET_DIVISIONS = "SCHOOL_GET_DIVISIONS";
export const SCHOOL_CREATE_DIVISIONS = "SCHOOL_CREATE_DIVISIONS";
export const SCHOOL_GET_CLASS_GROUPS = "SCHOOL_GET_CLASS_GROUPS";
export const SCHOOL_UPDATE_CLASS_GROUP = "SCHOOL_UPDATE_CLASS_GROUP";
export const SCHOOL_GROUP_SELECT_STUDENT = "SCHOOL_GROUP_SELECT_STUDENT";
export const SCHOOL_GROUP_CLEAR_SELECTED_STUDENT =
	"SCHOOL_GROUP_CLEAR_SELECTED_STUDENT";
export const SCHOOL_CLEAR_CLASSES = "SCHOOL_CLEAR_CLASSES";
export const SCHOOL_GET_RESOURCES = "SCHOOL_GET_RESOURCES";
export const SCHOOL_GET_RESOURCES_LOAD_MORE = "SCHOOL_GET_RESOURCES_LOAD_MORE";
export const SCHOOL_GET_RESOURCE_CATEGORIES = "SCHOOL_GET_RESOURCE_CATEGORIES";
export const SCHOOL_GET_STUDENT_BY_ID = "SCHOOL_GET_STUDENT_BY_ID";
export const SCHOOL_GET_STUDENTS = "SCHOOL_GET_STUDENTS";
export const SCHOOL_SET_CURRENT_CLASSROOM = "SCHOOL_SET_CURRENT_CLASSROOM";
export const SCHOOL_GET_STUDENTS_IN_CLASSROOM =
	"SCHOOL_GET_STUDENTS_IN_CLASSROOM";
export const SCHOOL_UPDATE_STUDENTS_IN_CLASSROOM =
	"SCHOOL_UPDATE_STUDENTS_IN_CLASSROOM";
export const SCHOOL_ADD_USERS_TO_CLASSROOM = "SCHOOL_ADD_USERS_TO_CLASSROOM";
export const SCHOOL_ADD_USER_TO_CLASSROOMS = "SCHOOL_ADD_USER_TO_CLASSROOMS";
export const SCHOOL_ADD_STUDENTS_TEACHERS_TO_CLASSROOM =
	"SCHOOL_ADD_STUDENTS_TEACHERS_TO_CLASSROOM";
export const SCHOOL_ADD_USER_TO_CLASSROOM = "SCHOOL_ADD_USER_TO_CLASSROOM";
export const SCHOOL_UPDATE_STUDENT_CLASSROOM_INFORMATION =
	"SCHOOL_UPDATE_STUDENT_CLASSROOM_INFORMATION";
export const SCHOOL_GET_STUDENT_CODES = "SCHOOL_GET_STUDENT_CODES";
export const SCHOOL_GENERATE_STUDENT_CODES = "SCHOOL_GENERATE_STUDENT_CODES";
export const SCHOOL_DELETE_STUDENT_CODES = "SCHOOL_DELETE_STUDENT_CODES";
export const SCHOOL_REMOVE_USER_FROM_CLASSROOM =
	"SCHOOL_REMOVE_USER_FROM_CLASSROOM";
export const SCHOOL_GET_STUDENT_ORAL_LANGUAGE_RESULTS =
	"SCHOOL_GET_STUDENT_ORAL_LANGUAGE_RESULTS";
export const SCHOOL_GET_STUDENT_MATH_RESULTS =
	"SCHOOL_GET_STUDENT_MATH_RESULTS";
export const SCHOOL_UPDATE_CURRENT_STUDENT = "SCHOOL_UPDATE_CURRENT_STUDENT";
export const SCHOOL_GET_STUDENT_SUBMISSIONS = "SCHOOL_GET_STUDENT_SUBMISSIONS";
export const SCHOOL_GET_SUBMISSIONS = "SCHOOL_GET_SUBMISSIONS";
export const SCHOOL_POST_SUBMISSION = "SCHOOL_POST_SUBMISSION";
export const SCHOOL_SET_DOMAIN = "SCHOOL_SET_DOMAIN";
export const SCHOOL_GET_CIRCLE_CHARTS = "SCHOOL_GET_CIRCLE_CHARTS";
export const SCHOOL_UPDATE_CIRCLE_CHARTS = "SCHOOL_UPDATE_CIRCLE_CHARTS";
export const SCHOOL_GET_CIRCLE_CHART_NOTES = "SCHOOL_GET_CIRCLE_CHART_NOTES";
export const SCHOOL_UPDATE_CIRCLE_CHART_NOTE =
	"SCHOOL_UPDATE_CIRCLE_CHART_NOTE";
export const SCHOOL_CREATE_CIRCLE_CHART_NOTE =
	"SCHOOL_CREATE_CIRCLE_CHART_NOTE";
export const SCHOOL_DELETE_CIRCLE_CHART_NOTE =
	"SCHOOL_DELETE_CIRCLE_CHART_NOTE";
export const SCHOOL_GET_ALL_PRE_K_MATH_STUDENT_DATA =
	"SCHOOL_GET_ALL_PRE_K_MATH_STUDENT_DATA";
export const SCHOOL_GET_SUBMISSIONS_AND_KEEP_RESPONSES =
	"SCHOOL_GET_SUBMISSIONS_AND_KEEP_RESPONSES";

// Survey ActionTypes
export const SURVEY_CAREGIVER_CREATE_ANSWER_SCHEMA =
	"SURVEY_CAREGIVER_CREATE_ANSWER_SCHEMA";
export const SURVEY_HM_CAREGIVER_CREATE_ANSWER_SCHEMA =
	"SURVEY_HM_CAREGIVER_CREATE_ANSWER_SCHEMA";
export const SURVEY_CHILD_ASSESSMENT_CREATE_ANSWER_SCHEMA =
	"SURVEY_CHILD_ASSESSMENT_CREATE_ANSWER_SCHEMA";
export const SURVEY_CAREGIVER_UPDATE_ANSWER_SCHEMA =
	"SURVEY_CAREGIVER_UPDATE_ANSWER_SCHEMA";
export const SURVEY_HM_CAREGIVER_UPDATE_ANSWER_SCHEMA =
	"SURVEY_HM_CAREGIVER_UPDATE_ANSWER_SCHEMA";
export const SURVEY_CHILD_ASSESSMENT_UPDATE_ANSWER_SCHEMA =
	"SURVEY_CHILD_ASSESSMENT_UPDATE_ANSWER_SCHEMA";
export const SURVEY_CHILD_ASSESSMENT_UPDATE_ANSWER_SCHEMA_FROM_SUBMISSION =
	"SURVEY_CHILD_ASSESSMENT_UPDATE_ANSWER_SCHEMA_FROM_SUBMISSION";
export const SURVEY_DONE_CHILD_ASSESSMENT = "SURVEY_DONE_CHILD_ASSESSMENT";
export const SURVEY_GET_CHILD_ASSESSMENT_QUESTIONS =
	"SURVEY_GET_CHILD_ASSESSMENT_QUESTIONS";
export const SURVEY_UPDATE_SURVEY_PROPERTIES =
	"SURVEY_UPDATE_SURVEY_PROPERTIES";
export const SURVEY_SUBMIT_FOR_UPLOAD = "SURVEY_SUBMIT_FOR_UPLOAD";
export const SURVEY_SUBMIT_FOR_UPLOAD_CHILD_ASSESSMENT =
	"SURVEY_SUBMIT_FOR_UPLOAD_CHILD_ASSESSMENT";
export const SURVEY_GET_ASSESSMENT_METADATA = "SURVEY_GET_ASSESSMENT_METADATA";
export const SURVEY_GET_RESPONSES_FOR_ASSESSMENT =
	"SURVEY_GET_RESPONSES_FOR_ASSESSMENT";
export const SURVEY_GET_RESPONSES_FOR_CHILD_ASSESSMENT =
	"SURVEY_GET_RESPONSES_FOR_CHILD_ASSESSMENT";

// User ActionTypes
export const USER_SET_ROLES = "USER_SET_ROLES";
export const USER_GET_AUTHED = "USER_GET_AUTHED";
export const USER_RESET_PASSWORD = "USER_RESET_PASSWORD";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_REGISTER_CAREGIVER_ACCOUNT =
	"USER_REGISTER_CAREGIVER_ACCOUNT";
export const USER_REGISTER_READING_FREE_TRIAL_ACCOUNT =
	"USER_REGISTER_READING_FREE_TRIAL_ACCOUNT;";
export const USER_ACCEPT_TEACHER_INVITATION = "USER_ACCEPT_TEACHER_INVITATION";
export const USER_SET_ERROR = "USER_SET_ERROR";
export const USER_RESET_REGISTRATION = "USER_RESET_REGISTRATION";
export const USER_VERIFY_STUDENT_CODE = "USER_VERIFY_STUDENT_CODE";
export const USER_REDEEM_STUDENT_CODE = "USER_REDEEM_STUDENT_CODE";
export const USER_RESET_STUDENT_CODE_VERIFICATION =
	"USER_RESET_STUDENT_CODE_VERIFICATION";
export const USER_RESET_STUDENT_CODE_REDEMPTION =
	"USER_RESET_STUDENT_CODE_REDEMPTION";
export const USER_RESET_REDEEM_COUPON_ERROR_MESSAGE =
	"USER_RESET_REDEEM_COUPON_ERROR_MESSAGE";
export const USER_COMPLETE_STUDENT_CODE_REDEMPTION =
	"USER_COMPLETE_STUDENT_CODE_REDEMPTION";
export const USER_ADD_STUDENT_TO_PAID_SUBSCRIPTION =
	"USER_ADD_STUDENT_TO_PAID_SUBSCRIPTION";
export const USER_SET_ENTITLEMENTS = "USER_SET_ENTITLEMENTS";
export const USER_UPDATE_FREE_TRIAL = "USER_UPDATE_FREE_TRIAL";
export const USER_CREATE_PERSON_FOR_FREE_TRIAL =
	"USER_CREATE_PERSON_FOR_FREE_TRIAL";
export const USER_UPDATE_USER = "USER_UPDATE_USER";
export const USER_DELETE_USER = "USER_DELETE_USER";
export const USER_CREATE_SUBSCRIPTION_BY_COUPON =
	"USER_CREATE_SUBSCRIPTION_BY_COUPON";
export const USER_CREATE_NEW_USER = "USER_CREATE_NEW_USER";
export const USER_CHECK_EMAIL = "USER_CHECK_EMAIL";
export const USER_CREATE_ADMIN_REQUEST = "USER_CREATE_ADMIN_REQUEST";
export const USER_CLOSE_ADMIN_REQUEST = "USER_CLOSE_ADMIN_REQUEST";
export const USER_ADD_STUDENT_FREE_TRIAL = "USER_ADD_STUDENT_FREE_TRIAL";
export const USER_CREATE_PERSON_FOR_PAID_SUBSCRIPTION =
	"USER_CREATE_PERSON_FOR_PAID_SUBSCRIPTION";
export const USER_CHECKOUT_SUBSCRIPTION = "USER_CHECKOUT_SUBSCRIPTION";
export const USER_GET_SUBSCRIPTION_PRODUCTS = "USER_GET_SUBSCRIPTION_PRODUCTS";
export const USER_REQUEST_RESET_PASSWORD_EMAIL =
	"USER_REQUEST_RESET_PASSWORD_EMAIL";
export const USER_GET_ACCESSIBLE_PEOPLE = "USER_GET_ACCESSIBLE_PEOPLE";
export const USER_SEARCH_ACCESSIBLE_PEOPLE = "USER_SEARCH_ACCESSIBLE_PEOPLE";
export const USER_GET_PEOPLE_IN_CLASSROOM = "USER_GET_PEOPLE_IN_CLASSROOM";
export const USER_LOGOUT_COMPLETED = "USER_LOGOUT_COMPLETED";
export const USER_SUBMIT_FEEDBACK = "USER_SUBMIT_FEEDBACK";
export const USER_RESET_FEEDBACK = "USER_RESET_FEEDBACK";
export const USER_GET_PORTAL_RELATED_FORM_OPTIONS =
	"USER_GET_PORTAL_RELATED_FORM_OPTIONS";
