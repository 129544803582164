import React from "react";
import {PolarArea} from "react-chartjs-2";

interface ChartData {
	datasets: Dataset[];
	labels: string[];
}
interface Dataset {
	data: number[];
	backgroundColor: string[];
}
export const ResultsPolarChart = ({data}: {data: ChartData}) => {
	const options = {
		plugins: {
			legend: {
				position: "bottom" as const,
				labels: {
					font: {family: "'nunitobold', sans-serif"},
				},
			},
		},
		scales: {
			r: {
				ticks: {
					stepSize: 1,
				},
				max: 4,
			},
		},
	};
	return <PolarArea data={data} options={options} width={100} height={100} />;
};
