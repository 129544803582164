import "../../../../Stylesheets/css/scss/Components/Portal/Public/Navigation/HamburgerMenu.css";

import React, {useEffect} from "react";

interface HamburgerMenuProps {
	open: boolean;
	onClick: () => void;
	passUpRef: (ref: React.RefObject<HTMLDivElement>) => void;
}

export const HamburgerMenu = ({
	open,
	onClick,
	passUpRef,
}: HamburgerMenuProps) => {
	let hamburgerMenu;
	useEffect(() => passUpRef(hamburgerMenu), [hamburgerMenu, passUpRef]);
	return (
		<div
			className={`menu-hamburger${open ? " open" : ""}`}
			onClick={onClick}
			ref={(node) => (hamburgerMenu = node)}
		>
			<div className="menu-hamburger-line" />
			<div className="menu-hamburger-line" />
			<div className="menu-hamburger-line" />
		</div>
	);
};
