import React, {Fragment} from "react";

import {MathActivityImage, RubricTable} from ".";

export interface SingleMathTaskTab
	extends TabPropForImage,
		TabProForRubricTable {
	task: string;
	description: string;
}

export interface TabPropForImage {
	img: string[];
	imgCaption: string[];
	audio: string;
}

export interface TabProForRubricTable {
	rubrics: Rubric[];
	rubricLevel: number;
}

interface Rubric {
	level: number;
	rubric: string;
}

export const SingleMathTask = ({tab}: {tab: SingleMathTaskTab}) => (
	<Fragment>
		<div className="row">
			<div className="col-md-9 pb-3">
				<h5 className="text-uppercase">{tab.task}</h5>
				<small>{tab.description}</small>
			</div>
		</div>
		<div className="row math-process">
			<div className="col-md-6 math-activity-image-container">
				<MathActivityImage tab={tab} />
			</div>
			<div className="col-md-6 p-3">
				<RubricTable tab={tab} />
			</div>
		</div>
	</Fragment>
);
