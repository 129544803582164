import i18n from "i18next";
import React from "react";

import {ADD_EDIT_USER, AUTOMATION_TEST_ID} from "../../../../Scripts/Constants";
import {Dropdown, DropdownItemModel} from "../index";

interface StudentIdentityProps {
	renderIdentity: string;
	handleChosenIdentity: (item: DropdownItemModel) => void;
}

export function StudentIdentity(props: StudentIdentityProps) {
	const dropdownItems = [
		{
			value: ADD_EDIT_USER.VALUE.YES,
			label: i18n.t("common.yes"),
			id:
				AUTOMATION_TEST_ID.PUBLIC.DROPDOWN.IDENTITY.IDENTITY_SELECT_INDIGENOUS,
		},
		{
			value: ADD_EDIT_USER.VALUE.NO,
			label: i18n.t("common.no"),
			id:
				AUTOMATION_TEST_ID.PUBLIC.DROPDOWN.IDENTITY
					.IDENTITY_SELECT_NON_INDIGENOUS,
		},
		{
			value: ADD_EDIT_USER.VALUE.NOT_SPECIFIED,
			label: i18n.t("admin.not_specified"),
			id: AUTOMATION_TEST_ID.PUBLIC.DROPDOWN.IDENTITY.IDENTITY_SELECT_NOT,
		},
	];

	const selectedItem =
		dropdownItems !== undefined && dropdownItems.length > 0
			? dropdownItems.find((item) => item.label === props.renderIdentity)
			: null;

	const selectedItemId = selectedItem ? selectedItem.id : "";

	return (
		<div className="row w-100 mb-2">
			<small>{i18n.t("admin.indigenous_identity")}</small>
			<Dropdown
				id={AUTOMATION_TEST_ID.PUBLIC.DROPDOWN.IDENTITY.IDENTITY_SELECT}
				className="btn btn-dropdown dropdown-toggle w-100"
				items={dropdownItems}
				selectedItem={{label: props.renderIdentity, id: selectedItemId}}
				onSelect={props.handleChosenIdentity}
			/>
		</div>
	);
}
