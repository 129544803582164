import SanitizeHTML from "sanitize-html";

import {SANITIZE_TYPE} from "./Constants";

export const sanitizeHTML = (dirty: string, type: string) => {
	const options = getOptions(type);
	return {
		__html: SanitizeHTML(dirty, options),
	};
};

const getOptions = (type: string) => {
	switch (type) {
		case SANITIZE_TYPE.ACTIVITY_CONTENT:
			return {
				allowedAttributes: {
					span: ["style"],
					iframe: ["src", "frameborder"],
					a: ["href", "target", "rel"],
					img: ["src"],
				},
				allowedTags: false,
			};
		case SANITIZE_TYPE.NOTIFICATION_CONTENT:
			return {};
		case SANITIZE_TYPE.RESOURCES:
			return {};
		case SANITIZE_TYPE.TITLE:
			return {};
		default:
			throw new Error(`Unsupported content type: ${type}`);
	}
};
