import React, {Component} from "react";

import {ModalActions} from "../../../../Actions";
import {MODAL_TYPES} from "../../../../Scripts/Constants";
import {
	NotificationDataModel,
	NotificationModel,
} from "../../../../Scripts/PublicInterfaces";
import {LazyLoader} from "../index";
import {NotificationListItem} from "./Widgets/NotificationListItem";

interface NotificationListProps {
	notifications: NotificationModel<NotificationDataModel>[];
	currentRole: string;
	filters: any;
	student: any;
}

export class NotificationList extends Component<NotificationListProps> {
	notificationIncrement = 20;
	state = {
		notificationLimit: this.notificationIncrement,
	};

	componentDidUpdate = (prevProps) => {
		const {notifications} = this.props;
		if (
			prevProps.notifications &&
			notifications &&
			prevProps.notifications.length !== notifications.length
		) {
			const lengthDifference =
				notifications.length - prevProps.notifications.length;
			const onlyNewNotifications = [...notifications].splice(
				0,
				lengthDifference
			);
			const allNewNotifications = onlyNewNotifications.filter(
				(notification) => !notification.read
			);
			const allNewBadgesNotifications = allNewNotifications.filter(
				(notification) => notification.type === "badge_earned"
			);
			if (allNewBadgesNotifications.length)
				this.handleNewBadgeEarned(allNewBadgesNotifications);
		}
	};

	onLoadMoreNotifications = () => {
		const {notifications} = this.props;
		const {notificationLimit} = this.state;
		const filteredNotifications = notifications.filter(
			(notification) => notification.data !== null
		);
		if (notificationLimit < filteredNotifications.length) {
			this.setState({
				notificationLimit: notificationLimit + this.notificationIncrement,
			});
		}
	};

	handleNewBadgeEarned = (badges) => {
		ModalActions.showModal(
			{
				open: true,
				badges,
				role: this.props.currentRole,
				size: "md",
				blockCloseOnOverlayClick: true,
			},
			MODAL_TYPES.BADGES_EARNED
		);
	};

	render() {
		const {notifications, student, filters} = this.props;
		const filteredNotifications = notifications.filter(
			(notification) => notification.data !== null
		);
		const notificationsForRender = filteredNotifications.slice(
			0,
			this.state.notificationLimit
		);
		return (
			<LazyLoader onLoadMore={this.onLoadMoreNotifications}>
				<div className="notification-list">
					{notificationsForRender.map((notification) => (
						<NotificationListItem
							student={student}
							notification={notification}
							key={notification.id}
							filters={filters}
						/>
					))}
				</div>
			</LazyLoader>
		);
	}
}
