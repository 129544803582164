import i18n from "i18next";
import React, {useState} from "react";
import {Link} from "react-router-dom";

import {
	ActivityActions,
	ActivityFormActions,
	ModalActions,
} from "../../../../../../../Actions";
import * as ActionTypes from "../../../../../../../Redux/ActionTypes";
import {getOriginalActivityId} from "../../../../../../../Scripts/Activities";
import {
	ACTIVITY_USER,
	AUTOMATION_TEST_ID,
	COMPLETE_RECOMMEND_ACTIVITY,
	EDITOR_ACTION,
	MODAL_TYPES,
	PORTALS,
} from "../../../../../../../Scripts/Constants";
import {Button, DropdownWrapper, Icon} from "../../../../../../Public";
import {Loader} from "../../../../../PublicComponents";

export const ActivityFooterComponentsEducator = ({
	activity,
	groups,
	students,
	handlePushNotification,
	taxonomies,
	user,
	filters = {hidden: false},
	buttonBackgroundStyle = {},
	isActivitiesList = false,
	customizedFunctionsAfterComplete = () => {},
	milestoneGroup = {},
	closeModal = () => {},
	isReadingCard = false,
	isHolisticMathCard = false,
	levelGroup = {},
	activityModalData = {},
	hasClassroomFromOtherDomain,
}) => {
	const [currentHiddenState, setCurrentHiddenState] = useState(filters?.hidden);

	const handleClickComment = () => {
		closeModal();
		ModalActions.showModal(
			{
				open: true,
				data: {groups, students, activity, user, activityModalData},
				role: PORTALS.EDUCATOR,
			},
			MODAL_TYPES.ACTIVITY_COMMENT
		);
	};

	const handleClickCompleteOrRecommend = (type) => {
		const data = {
			activityId: activity.id,
			type,
			groups,
			students,
			handlePushNotification,
			pushNotificationActions: ACTIVITY_USER.ACTION,
			customizedFunctionsAfterComplete,
			milestoneGroup,
			isHolisticMathCard,
			levelGroup,
		};
		closeModal();
		ModalActions.showModal(
			{
				open: true,
				data,
				role: PORTALS.EDUCATOR,
				keepInWindow: true,
			},
			MODAL_TYPES.COMPLETE_AND_RECOMMEND_ACTIVITY_NEW
		);
	};

	const handleClickComplete = () => {
		handleClickCompleteOrRecommend(
			COMPLETE_RECOMMEND_ACTIVITY.COMPLETE_ACTIVITY
		);
	};

	const handleClickRecommend = () => {
		handleClickCompleteOrRecommend(
			COMPLETE_RECOMMEND_ACTIVITY.RECOMMEND_ACTIVITY
		);
	};

	const checkIfOriginal = () => {
		const {
			id,
			translations,
		}: {id: number; translations: {[languageCode: string]: string}} = activity;
		const translationIds = Object.values(translations).map((translation) =>
			parseInt(translation, 10)
		);
		return translationIds.length <= 0 || getOriginalActivityId(activity) > id;
	};

	const handleClickHideShow = () => {
		if (currentHiddenState) {
			ActivityActions.unhideActivity(activity.id, {
				handling: {
					success: () => {
						setCurrentHiddenState(false);
						ActivityActions.getActivities(filters);
					},
				},
			});
		} else {
			closeModal();
			ModalActions.showModal(
				{
					open: true,
					size: "md",
					role: PORTALS.EDUCATOR,
					keepInWindow: true,
					data: {activityId: activity.id, filters},
				},
				MODAL_TYPES.CONFIRM_HIDE_ACTIVITY_MODAL
			);
		}
	};

	const handleClickAssess = () => {
		closeModal();
		ModalActions.showModal(
			{
				open: true,
				data: {students, activity, activityModalData, buttonBackgroundStyle},
				role: PORTALS.EDUCATOR,
				keepInWindow: true,
			},
			MODAL_TYPES.ACTIVITY_ASSESSMENT_MODAL
		);
	};

	const handleOpenModal = (type) => {
		closeModal();
		const isOriginal = checkIfOriginal();
		const currentActivity = {...activity};
		if (type === EDITOR_ACTION.DUPLICATE) {
			const modalType = MODAL_TYPES.DUPLICATE_ACTIVITY;
			if (isOriginal) {
				ActivityFormActions.readActivity(
					currentActivity,
					isReadingCard && hasClassroomFromOtherDomain
						? {
								learning_outcomes: [
									...taxonomies.tagging.reading.learningOutcomes,
									...taxonomies.tagging.learning_outcomes,
								],
								general_outcomes: [
									...taxonomies.tagging.reading.generalOutcomes,
									...taxonomies.tagging.general_outcomes,
								],
								specific_outcomes: [
									...taxonomies.tagging.reading.specificOutcomes,
									...taxonomies.tagging.specific_outcomes,
								],
						  }
						: taxonomies.tagging
				);
				const data = {
					title: `${i18n.t("admin.duplicate")} - ${activity?.title?.rendered}`,
					type,
					currentActivity,
				};
				showModal(data, modalType);
			} else {
				fetchActivityAndShowModal(currentActivity, type, modalType);
			}
		} else {
			const modalType = MODAL_TYPES.TRANSLATE_ACTIVITY;
			if (isOriginal) {
				ActivityFormActions.readActivity(currentActivity, taxonomies.tagging);
				const data = {
					title: `${i18n.t("admin.translate")} - ${activity?.title?.rendered}`,
					type,
					currentActivity,
				};
				showModal(data, modalType);
			} else {
				fetchActivityAndShowModal(currentActivity, type, modalType);
			}
		}
	};

	const fetchActivityAndShowModal = (currentActivity, type, modalType) => {
		const originalActivityId = getOriginalActivityId(currentActivity);
		ActivityActions.getActivityById(originalActivityId, true, {
			handling: {
				success: (response) => {
					const fetchedActivity = {...response.data};
					ActivityFormActions.readActivity(fetchedActivity, taxonomies.tagging);
					if (fetchedActivity?.hasOwnProperty("id")) {
						const titleAction =
							type === EDITOR_ACTION.TRANSLATE
								? `${i18n.t("admin.translate")}`
								: `${i18n.t("admin.duplicate")}`;
						const data = {
							title: `${titleAction} - ${fetchedActivity?.title?.rendered}`,
							type,
							currentActivity: {...fetchedActivity},
						};
						showModal(data, modalType);
					}
				},
				error: (error) => {},
			},
		});
	};

	const showModal = (data, modalType) => {
		ModalActions.showModal(
			{
				open: true,
				role: PORTALS.EDITOR,
				data,
				keepInWindow: true,
			},
			modalType
		);
	};

	return (
		<div className="footer-educator">
			<div
				className="hide-show-icon"
				onClick={handleClickHideShow}
				title={i18n.t(
					`feedback.${currentHiddenState ? "unhide_activity" : "hide_activity"}`
				)}
			>
				<Loader.Wrap actionTypes={[ActionTypes.ACTIVITY_PUT_UNHIDE_ACTIVITY]}>
					<Loader.HideContent>
						<Icon type={currentHiddenState ? "faEye" : "faEyeSlash"} />
					</Loader.HideContent>
					<Loader.Spinner size="1x" spinnerClassName="spinner-icon" />
				</Loader.Wrap>
			</div>
			{!isReadingCard ? (
				<div className="footer-buttons-educator">
					<Button
						onClick={handleClickComplete}
						style={buttonBackgroundStyle}
						id={COMPLETE_RECOMMEND_ACTIVITY.COMPLETE_ACTIVITY}
					>
						{i18n.t("admin.complete")}
					</Button>
					{activity?.assessment_id ? (
						<Button
							onClick={handleClickAssess}
							style={buttonBackgroundStyle}
							className="assess-button"
							id={AUTOMATION_TEST_ID.PUBLIC.MODAL.ACTIVITY_MODAL.ASSESS_BUTTON}
						>
							{i18n.t("admin.assess")}
						</Button>
					) : null}
					<Button
						{...(!isHolisticMathCard
							? {
									user: "educator" as const,
							  }
							: {})}
						onClick={handleClickRecommend}
						style={isHolisticMathCard ? buttonBackgroundStyle : {}}
						id={COMPLETE_RECOMMEND_ACTIVITY.RECOMMEND_ACTIVITY}
					>
						{i18n.t("admin.recommend")}
					</Button>
				</div>
			) : null}

			<div className="footer-icons-right">
				{user?.featureToggles?.["educator_portal_editor_feature"] ? (
					<div
						className="copy-icon"
						id={AUTOMATION_TEST_ID.PUBLIC.MODAL.ACTIVITY_MODAL.COPY_BUTTON}
					>
						<DropdownWrapper
							menuItems={() => [
								<Link
									to={{
										pathname: `/portal/activities`,
									}}
									className="no-link-decoration d-block px-3 py-2"
									onClick={() => handleOpenModal(EDITOR_ACTION.DUPLICATE)}
									id={
										AUTOMATION_TEST_ID.PUBLIC.MODAL.ACTIVITY_MODAL
											.COPY_BUTTON_DUPLICATE
									}
								>
									{i18n.t("admin.duplicate")}
								</Link>,
								<Link
									to={{
										pathname: `/portal/activities`,
									}}
									className="no-link-decoration d-block px-3 py-2"
									onClick={() => handleOpenModal(EDITOR_ACTION.TRANSLATE)}
									id={
										AUTOMATION_TEST_ID.PUBLIC.MODAL.ACTIVITY_MODAL
											.COPY_BUTTON_TRANSLATE
									}
								>
									{i18n.t("admin.translate")}
								</Link>,
							]}
							position={isActivitiesList ? "nw" : "s"}
						>
							<Icon type="faCopy" />
						</DropdownWrapper>
					</div>
				) : null}
				<div
					className="comment-icon"
					onClick={handleClickComment}
					title={i18n.t("admin.comment")}
				>
					<Icon type="faCommentAlt" />
				</div>
			</div>
		</div>
	);
};
