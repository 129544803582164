import "../../../../../Stylesheets/css/scss/Components/Portal/Public/Surveys/Pages/SurveyCells.css";

import i18n from "i18next";
import React, {Component, Fragment} from "react";

import {SURVEY_OPTION_TYPE} from "../../../../../Scripts/Constants";
import {LanguageTaxonomyItem} from "../../../../../Scripts/PublicInterfaces";
import {Checkbox, Radio} from "../../index";

interface SurveyCellsProps {
	page: SurveyPage;
	questions: QuestionModel[];
	properties: {page: number};
	formatTitle: (text: string, page: SurveyPage) => string;
	onSelect: ({
		questionId,
		customResponseId,
		languageVariant,
		responseId,
	}: {
		questionId: number;
		customResponseId?: string;
		languageVariant: string | null;
		responseId?: string;
	}) => void;
}

export interface SurveyPage {
	questions: number[];
	language: LanguageTaxonomyItem;
}

export interface QuestionModel {
	id: number;
	options?: [] | null;
	major_text?: string;
	minor_text?: string;
	clone_from?: number;
	max_custom_response_count?: number;
	max_response_count?: number;
	min_response_count?: number;
	response?: string[];
	clone_for_each_language?: boolean;
	language?: {code: string};
	type?: string;
	customResponse?: string;
}

interface SurveyCellsState {}

export class SurveyCells extends Component<SurveyCellsProps, SurveyCellsState> {
	private customInput;

	state = {
		customText: "",
		customInputFocus: false,
	};

	componentDidUpdate = (prevProps) => {
		const {properties} = this.props;
		if (prevProps.properties && prevProps.properties.page !== properties.page) {
			this.setState({
				customText: "",
			});
		}
	};

	onUpdateCustomText = (e) => {
		this.setState({
			customText: e.target.value,
		});
	};

	onSubmitCustomText = (question, option) => {
		const {onSelect} = this.props;
		const {customText} = this.state;
		const customNotSubmitted = option.type === SURVEY_OPTION_TYPE.CUSTOM_TEXT;
		if (!customNotSubmitted || (customNotSubmitted && customText)) {
			this.setState({customText: ""}, () => {
				if (onSelect) {
					onSelect({
						questionId: question.id,
						customResponseId: customNotSubmitted ? customText : option.value,
						languageVariant: question.language ? question.language.code : null,
					});
				}
			});
		} else {
			this.setState(
				{
					customInputFocus: true,
				},
				() => {
					this.customInput.focus();
				}
			);
		}
	};

	onInputBlur = (question, option) => {
		const {onSelect} = this.props;
		const {customText} = this.state;
		const customNotSubmitted = option.type === SURVEY_OPTION_TYPE.CUSTOM_TEXT;
		if (!customNotSubmitted || (customNotSubmitted && customText)) {
			this.setState({customText: "", customInputFocus: false}, () => {
				if (onSelect) {
					onSelect({
						questionId: question.id,
						customResponseId: customNotSubmitted ? customText : option.value,
						languageVariant: question.language ? question.language.code : null,
					});
				}
			});
		} else {
			this.setState({customInputFocus: false});
		}
	};

	onSelect = (datum) => {
		const {onSelect} = this.props;
		const {id, option, language} = datum;
		if (onSelect) {
			onSelect({
				questionId: id,
				responseId: option.value ? option.value : option.text.toLowerCase(),
				languageVariant: language ? language.code : null,
			});
		}
	};

	getFullQuestions = () => {
		const {questions, page} = this.props;
		return page.questions.map((questionId: number) => {
			const foundQuestion = questions.find(
				(question) => question.id === questionId
			);
			if (foundQuestion && !foundQuestion.options) {
				const parentQuestion = questions.find(
					(question) => question.id === foundQuestion.clone_from
				);
				if (parentQuestion)
					return {
						...parentQuestion,
						...foundQuestion,
						response: foundQuestion.response,
						customResponse: foundQuestion.customResponse,
						options: parentQuestion.options,
					};
			}

			return foundQuestion;
		});
	};

	getOptionCustomResponses = (options) => {
		const {questions} = this.props;
		return options
			.map((option) => {
				if (option.value && option.value === "other" && option.preconditions) {
					const preconditionalQuestionId = option.preconditions[1][0]; // get questionId from first conditional
					const preconditionalQuestion = questions.find(
						(question) => preconditionalQuestionId === question.id
					);
					if (preconditionalQuestion?.customResponse?.length) {
						return {
							...option,
							text: preconditionalQuestion.customResponse[0],
						};
					}

					return null;
				}

				return option;
			})
			.filter((option) => option);
	};

	reviseOptionsForCustomText = (question) => {
		let options = this.getOptionCustomResponses([...question.options]);
		if (question.customResponse && question.customResponse.length)
			options = [
				...options,
				...question.customResponse.map((customResponse) => ({
					type: SURVEY_OPTION_TYPE.CUSTOM_RESPONSE,
					text: customResponse,
					value: customResponse,
				})),
			];
		if (
			question?.max_custom_response_count &&
			(!question.customResponse ||
				question.customResponse?.length < question.max_custom_response_count)
		) {
			options = [
				...options,
				{type: SURVEY_OPTION_TYPE.CUSTOM_TEXT, text: "other"},
			];
		}

		return options;
	};

	userSelectionComponent = (radio) => {
		return radio ? Radio : Checkbox;
	};

	renderCustomTextInput = (question, option) => (
		<input
			ref={(node) => (this.customInput = node)}
			className="survey-cells-editable-input"
			placeholder={i18n.t("survey.other_option")}
			value={this.state.customText}
			onChange={this.onUpdateCustomText}
			onBlur={() => this.onInputBlur(question, option)}
		/>
	);

	renderNonCustomOptions = (option, question) => {
		const SelectionComponent = this.userSelectionComponent(
			!question.max_response_count || question.max_response_count === 1
		);
		return (
			<SelectionComponent
				onClick={
					option.type === SURVEY_OPTION_TYPE.CUSTOM_TEXT ||
					option.type === SURVEY_OPTION_TYPE.CUSTOM_RESPONSE
						? () => this.onSubmitCustomText(question, option)
						: this.onSelect
				}
				checked={
					(option.type === SURVEY_OPTION_TYPE.CUSTOM_RESPONSE &&
						question.customResponse?.includes(option.value)) ||
					(option.type === SURVEY_OPTION_TYPE.CUSTOM_TEXT &&
						question.customResponse?.includes(this.state.customText)) ||
					(option.type === SURVEY_OPTION_TYPE.CUSTOM_TEXT &&
						this.state.customInputFocus) ||
					(question.response &&
						question.response.includes(
							option.value ? option.value : option.text.toLowerCase()
						))
				}
				data={{...question, option}}
			/>
		);
	};

	renderOption = (option, question: QuestionModel) => {
		return (
			<div
				className="survey-cells-option"
				name="survey-cells-option"
				key={`cells-option-${question.id}-${option.text}`}
			>
				{this.renderNonCustomOptions(option, question)}
				<div
					className="survey-cell-option-label"
					name="survey-cell-option-label"
					title={
						option.type === SURVEY_OPTION_TYPE.CUSTOM_TEXT ? "" : option.text
					}
				>
					<small>
						{option.type === SURVEY_OPTION_TYPE.CUSTOM_TEXT
							? this.renderCustomTextInput(question, option)
							: option.text}
					</small>
				</div>
			</div>
		);
	};

	render() {
		const {page, formatTitle} = this.props;
		const fullQuestions = this.getFullQuestions();
		return (
			<div className="survey-cells">
				{fullQuestions.length > 0
					? fullQuestions.map((question) => {
							if (question && question.major_text)
								return (
									<div
										className="survey-cells-question"
										key={`survey-cells-question-${question.id}`}
									>
										<div className="survey-cells-question-title">
											{formatTitle(question.major_text, page)}
										</div>
										<div className="survey-cells-question-options">
											{this.reviseOptionsForCustomText(question).map((option) =>
												this.renderOption(option, question)
											)}
										</div>
									</div>
								);
							return <Fragment />;
					  })
					: null}
			</div>
		);
	}
}
