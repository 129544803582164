import "../../../../Stylesheets/css/scss/Components/Portal/Public/ErrorReporting/ErrorScreen.css";

import i18n from "i18next";
import React from "react";

import {handleLogout} from "../../../../Scripts/TokenAndUser";

export const ErrorScreen = ({error}: {error: string[]}) => (
	<div className="portal-error-screen">
		<div className="portal-error-content">
			<div className="error-text">
				{i18n.t("error.portal_failed_to_initialize")}
			</div>
			<div className="error-text">{error}</div>
			<button className="btn btn-secondary" onClick={handleLogout}>
				{i18n.t("admin.sign_out")}
			</button>
		</div>
	</div>
);
