import i18n from "i18next";
import React from "react";
import {Trans} from "react-i18next";
import {Link} from "react-router-dom";

import {AssetManager} from "../../../Scripts/AssetManager";

export const NoMatch = ({location}) => (
	<div
		className="container-fluid min-height-full bg-light"
		data-test="no-match-component"
	>
		<div className="content-wrapper row min-height-full">
			<div className="col-md-6 flex align-center p-3">
				<div className="">
					<img
						className="mb-3"
						width="45px"
						height="auto"
						src={AssetManager.ASSETS.LOGO.SPRIG_LEAVES}
						alt=""
						data-test="no-match-sprig-leaves"
					/>
					<h1 data-test="no-match-title">{i18n.t("error.oops")}</h1>
					<p data-test="no-match-message">
						<Trans i18nKey="phrase.no_match">
							{[<code key="no_match">{{location: location.pathname}}</code>]}
						</Trans>
					</p>
					<Link className="btn edu-btn-primary" to="/portal">
						{i18n.t("phrase.go_to_portal")}
					</Link>
				</div>
			</div>
			<div className="col-md-6 flex align-end">
				<div>
					<img
						className="w-100"
						src={AssetManager.ASSETS.CHARACTER.CHARACTER_404}
						alt=""
						data-test="no-match-character"
					/>
				</div>
			</div>
		</div>
	</div>
);
