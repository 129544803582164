import i18n from "i18next";
import React from "react";

import {ADD_EDIT_USER, AUTOMATION_TEST_ID} from "../../../../Scripts/Constants";
import {Dropdown, DropdownItemModel} from "../index";

interface StudentGenderProps {
	renderGender: string;
	handleChosenGender: (item: DropdownItemModel) => void;
}

export const StudentGender = (props: StudentGenderProps) => {
	const dropdownItems = [
		{
			value: ADD_EDIT_USER.VALUE.MALE,
			label: i18n.t("admin.gender_male"),
			id: AUTOMATION_TEST_ID.PUBLIC.DROPDOWN.GENDER.GENDER_SELECT_MALE,
		},
		{
			value: ADD_EDIT_USER.VALUE.FEMALE,
			label: i18n.t("admin.gender_female"),
			id: AUTOMATION_TEST_ID.PUBLIC.DROPDOWN.GENDER.GENDER_SELECT_FEMALE,
		},
		{
			value: ADD_EDIT_USER.VALUE.NOT_SPECIFIED,
			label: i18n.t("admin.not_specified"),
			id: AUTOMATION_TEST_ID.PUBLIC.DROPDOWN.GENDER.GENDER_SELECT_NOT,
		},
	];
	const selectedItem = dropdownItems.find(
		(item) => item.label === props.renderGender
	);
	const selectedItemId = selectedItem ? selectedItem.id : "";
	return (
		<div className="row w-100 mb-2">
			<small>{i18n.t("admin.gender")}</small>
			<Dropdown
				id={AUTOMATION_TEST_ID.PUBLIC.DROPDOWN.GENDER.GENDER_SELECT}
				className="btn btn-dropdown dropdown-toggle w-100"
				items={dropdownItems}
				selectedItem={{
					label: props.renderGender,
					id: selectedItemId,
				}}
				onSelect={props.handleChosenGender}
			/>
		</div>
	);
};
