import React, {Fragment} from "react";

interface SlideControllerProps {
	units: {page: number}[];
	isClicked: (event: React.MouseEvent<HTMLSpanElement>) => void;
	page: number;
}

export const SlideController = ({
	units,
	isClicked,
	page,
}: SlideControllerProps) => {
	return (
		<Fragment>
			{units.map((unit) => (
				<SlideControllerUnit
					unit={unit}
					isClicked={isClicked}
					page={page}
					key={unit.page}
				/>
			))}
		</Fragment>
	);
};

interface SlideControllerUnitProps {
	unit: {
		page: number;
	};
	page: number;
	isClicked: (event: React.MouseEvent<HTMLSpanElement>) => void;
}

const SlideControllerUnit = (props: SlideControllerUnitProps) => {
	const controllerUnitClassName =
		props.page === props.unit.page ? "is-clicked" : undefined;
	return (
		<span
			className="controller-unit"
			id={props.unit.page.toString()}
			onClick={props.isClicked}
		>
			<span className={controllerUnitClassName} />
		</span>
	);
};
