import "../../../../../Stylesheets/css/scss/Components/Portal/Public/Modal/Parts/ModalPageNavigator.css";

import React from "react";

import {
	AUTOMATION_TEST_ID,
	MODAL_TYPES,
} from "../../../../../Scripts/Constants";
import {Icon} from "../../../../Public";

interface ModalPageNavigatorProps {
	totalPages: number;
	page: number;
	title?: string;
	children?: JSX.Element[] | JSX.Element;
	modalType?: string;
	onSubmitSurvey?: () => void;
	onBack: () => void;
	onNext: () => void;
}

export const ModalPageNavigator = ({
	totalPages,
	page,
	title,
	onBack,
	onNext,
	children,
	modalType,
	onSubmitSurvey,
}: ModalPageNavigatorProps) => (
	<div className="modal-page-navigatior">
		<div
			className={`modal-page-navigator-button${
				page === 0 ? " hide-navigator" : ""
			}`}
			onClick={onBack}
		>
			<Icon type="faChevronCircleLeft" iconWrapperClassName="d-inline" />
		</div>
		{title && (
			<div
				className="modal-page-navigator-title"
				id={AUTOMATION_TEST_ID.CAREGIVER.SURVEY.PAGE_TITLE}
			>
				{title}
			</div>
		)}
		{children && (
			<div className="modal-page-navigation-content">{children}</div>
		)}
		<div
			className={`modal-page-navigator-button${
				page === totalPages - 1 ? " hide-navigator" : ""
			}`}
			onClick={
				modalType === MODAL_TYPES.CHILD_ASSESSMENT_MODAL &&
				page === totalPages - 2
					? onSubmitSurvey
					: onNext
			}
			id={AUTOMATION_TEST_ID.CAREGIVER.ONBOARDING.ONBOARDING_MODAL_NEXT_PAGE}
		>
			<Icon type="faChevronCircleRight" iconWrapperClassName="d-inline" />
		</div>
	</div>
);
