import React from "react";

import {AssetManager} from "../../../Scripts/AssetManager";
import {get_url} from "../../../Scripts/Fetch";

export const PrimaryNavBarSprigLogo = () => (
	<span className="navbar-brand mb-0 h1 d-inline">
		<a href={get_url()}>
			<img
				src={AssetManager.ASSETS.LOGO.SPRIG_LEAVES_WHITE}
				width="auto"
				height="30px"
				className="d-inline-block align-centre"
				alt=""
			/>
		</a>
	</span>
);
