import {lazy} from "react";

const BeeSticker = lazy(() =>
	import("./Bee").then(({BeeSticker}) => ({default: BeeSticker}))
);

const ButterflySticker = lazy(() =>
	import("./Butterfly").then(({ButterflySticker}) => ({
		default: ButterflySticker,
	}))
);

const ChickadeeSticker = lazy(() =>
	import("./Chickadee").then(({ChickadeeSticker}) => ({
		default: ChickadeeSticker,
	}))
);

const GooseSticker = lazy(() =>
	import("./Goose").then(({GooseSticker}) => ({default: GooseSticker}))
);

const LeafpileSticker = lazy(() =>
	import("./Leafpile").then(({LeafpileSticker}) => ({default: LeafpileSticker}))
);

const NestSticker = lazy(() =>
	import("./Nest").then(({NestSticker}) => ({default: NestSticker}))
);

const RabbitSticker = lazy(() =>
	import("./Rabbit").then(({RabbitSticker}) => ({default: RabbitSticker}))
);

const SquirrelSticker = lazy(() =>
	import("./Squirrel").then(({SquirrelSticker}) => ({default: SquirrelSticker}))
);

const SunSticker = lazy(() =>
	import("./Sun").then(({SunSticker}) => ({default: SunSticker}))
);

export const Sticker = {
	Rabbit: RabbitSticker,
	Goose: GooseSticker,
	Leafpile: LeafpileSticker,
	Chickadee: ChickadeeSticker,
	Butterfly: ButterflySticker,
	Bee: BeeSticker,
	Nest: NestSticker,
	Squirrel: SquirrelSticker,
	Sun: SunSticker,
};
