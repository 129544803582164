import "./PageDescription.scss";

import i18n from "i18next";
import React from "react";
import {Link} from "react-router-dom";

import {AppContentActions} from "../../../../Actions";
import {Button} from "../../Button";
import {Icon, IconType} from "../../Icons";

export interface PageDescriptionProps {
	icon: IconType;
	title: string;
	description: string;
	tour?: {};
	nextPageLink?: string;
	learnMoreLink?: string;
}

export const PageDescription = ({
	icon,
	title,
	description,
	tour = {},
	nextPageLink = "",
	learnMoreLink = "",
}: PageDescriptionProps) => {
	const onNextStep = () => {
		AppContentActions.proceedToNextPortalTourStep(tour);
	};
	return (
		<div className="page-description">
			<div>
				<div className="page-description-header">
					<Icon type={icon} size="lg" />
					<div className="title">{title}</div>
				</div>
				<div className="page-description-content">{description}</div>
			</div>
			<div className="page-description-buttons">
				<div className="learn-more">
					{learnMoreLink ? (
						<a
							href={learnMoreLink}
							target="_blank"
							className="no-link-decoration"
							rel="noopener noreferrer"
						>
							{i18n.t("tour.learn_more")}
						</a>
					) : null}
				</div>
				{nextPageLink ? (
					<div className="continue">
						<Link
							to={nextPageLink}
							className="no-link-decoration"
							onClick={onNextStep}
							title={i18n.t("tour.continue")}
						>
							{i18n.t("tour.continue")}
						</Link>
					</div>
				) : (
					<div className="got-it">
						<Button
							type="primary"
							user="educator"
							onClick={() => AppContentActions.endCurrentPortalTour(tour)}
						>
							{i18n.t("form.got_it")}!
						</Button>
					</div>
				)}
			</div>
		</div>
	);
};
