import i18n from "i18next";
import React from "react";

import * as ActionTypes from "../../../../Redux/ActionTypes";
import {AUTOMATION_TEST_ID} from "../../../../Scripts/Constants";
import {handleLogout} from "../../../../Scripts/TokenAndUser";
import {Icon} from "../../../Public";
import {Loader} from "../Loader";

export const MenuItemSignOut = () => (
	<div className="dropdown-menu-item">
		<Loader.Wrap actionTypes={[ActionTypes.USER_LOGOUT]}>
			<Loader.HideContent>
				<div id={AUTOMATION_TEST_ID.ACCOUNT.SIGN_OUT} onClick={handleLogout}>
					<Icon type="faSignOutAlt" />
					<div>{i18n.t("admin.sign_out")}</div>
				</div>
			</Loader.HideContent>
			<Loader.Spinner size="1x" />
		</Loader.Wrap>
	</div>
);
