import i18n from "i18next";
import React from "react";
import DatePicker from "react-datepicker";

import {Icon} from "../../../Public";

export const StudentDateOfBirth = ({
	birthday,
	handleDateChange,
	placeholder,
}) => {
	return (
		<div className="row w-100 mb-2">
			<small>{i18n.t("admin.dob")}</small>
			<div className="input-group">
				<div className="input-group-prepend">
					<span className="input-group-text" id="basic-addon1">
						<Icon type="faCalendarDays" size="1x" />
					</span>
				</div>
				<DatePicker
					className="form-control"
					dateFormat="yyyy/MM/dd"
					selected={birthday}
					onChange={handleDateChange}
					placeholderText={placeholder}
					showYearDropdown
					dateFormatCalendar="MMMM"
					scrollableYearDropdown
					yearDropdownItemNumber={10}
					maxDate={new Date()}
					isClearable={true}
				/>
			</div>
		</div>
	);
};
