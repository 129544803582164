import i18n from "i18next";
import React from "react";

import {getRoundedNumber} from "../../../../../../Scripts/Localization";
import {Icon} from "../../../../../Public";

export const StudentExpectation = ({
	expectation,
	onSelectStudent,
	onRemoveStudent,
	onOpenRecommendedActivities,
	selectedStudentIds,
	isHolisticMath,
}) => (
	<div
		key={`expectation-${expectation.label}`}
		className="student-milestone-expectation"
	>
		<div className="expectation-header">
			<div className={`expectation-color expectation-${expectation.class}`} />
			<div className="expectation-title">{expectation.label}</div>
		</div>
		<div className="expectation-category-header">
			<div className="category-name">{i18n.t("common.name")}</div>
			<div className="category-level">{i18n.t("common.level")}</div>
		</div>
		<div className="expectation-rows">
			{expectation.data.map((student) => (
				<StudentExpectationRow
					key={student.id}
					student={student}
					onRemoveStudent={onRemoveStudent}
					onSelectStudent={onSelectStudent}
					onOpenRecommendedActivities={onOpenRecommendedActivities}
					selectedStudentIds={selectedStudentIds}
					isHolisticMath={isHolisticMath}
				/>
			))}
		</div>
	</div>
);

const StudentExpectationRow = ({
	selectedStudentIds,
	student,
	onRemoveStudent,
	onSelectStudent,
	onOpenRecommendedActivities,
	isHolisticMath,
}) => {
	const getStudentLevelIcon = () => {
		const studentLevel = Math.round(student.level);
		switch (studentLevel) {
			case 1:
				return "seed";
			case 2:
				return "sprout";
			case 3:
				return "bloom";
			case 4:
				return "flower";
			default:
				throw new Error(`Unsupported level: ${studentLevel}`);
		}
	};
	return (
		<div
			className={`expectation-row${
				selectedStudentIds.includes(student.id) ? " selected" : ""
			}`}
		>
			<div
				className="expectation-information"
				onClick={() =>
					selectedStudentIds.includes(student.id)
						? onRemoveStudent(student)
						: onSelectStudent(student)
				}
			>
				<div className="expectation-row-name">{student.name}</div>
				<div className="expectation-row-level">
					{isHolisticMath ? (
						<Icon type={getStudentLevelIcon()} />
					) : (
						getRoundedNumber(student.level, 2)
					)}
				</div>
			</div>
			{isHolisticMath ? null : (
				<div className="expectation-row-link">
					<div
						className="student-link"
						onClick={() => onOpenRecommendedActivities(student)}
					>
						<Icon type="faLeaf" size="1x" />
					</div>
				</div>
			)}
		</div>
	);
};
