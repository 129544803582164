import React from "react";
import {Link} from "react-router-dom";

import {AUTOMATION_TEST_ID} from "../../../../Scripts/Constants";

interface DropdownItemProps {
	item: DropdownSingleItem;
	onSelectItem: (item: DropdownSingleItem) => void;
}

export interface DropdownSingleItem {
	id?: number | string;
	label: string;
	alt?: string;
	img?: string;
	link?: string;
}

export const DropdownItem = ({item, ...rest}: DropdownItemProps) =>
	item.link ? (
		<Link key={item.id} to={item.link} className="no-link-decoration">
			{DropdownButton({item, ...rest})}
		</Link>
	) : (
		DropdownButton({item, ...rest})
	);

const DropdownButton = ({
	item,
	onSelectItem,
}: {
	item: DropdownSingleItem;
	onSelectItem: (item: DropdownSingleItem) => void;
}) => (
	<button
		className="dropdown-item p-3 btn-block"
		id={item.id?.toString()}
		type="button"
		onClick={() => onSelectItem(item)}
		name={AUTOMATION_TEST_ID.PUBLIC.DROPDOWN.DROPDOWN_ITEM}
	>
		<span>
			{item.img && (
				<img src={item.img} width="25px" height="25px" alt={item.alt} />
			)}
			<span style={{padding: "0 5px"}}>{item.label}</span>
		</span>
	</button>
);
