import * as ActionTypes from "../Redux/ActionTypes";
import {CreateReducer} from "../Redux/Utilities";

const initialState = {
	initializedNotifications: false,
	notifications: [],
	showTab: false,
};

export const NotificationReducer = CreateReducer(initialState, {
	[ActionTypes.NOTIFICATION_GET]: (state, {payload}) => {
		return {
			...state,
			notifications: payload,
			initializedNotifications: true,
		};
	},
	[ActionTypes.NOTIFICATION_HIDE_SHOW]: (state, {payload}) => {
		return {
			...state,
			showTab: payload,
		};
	},
});
