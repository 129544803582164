import {combineReducers} from "redux";
import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";

import * as ActionTypes from "../Redux/ActionTypes";
import {ActivityFormReducer} from "./ActivityFormReducer";
import {ActivityMonitorReducer} from "./ActivityMonitorReducer";
import {ActivityReducer} from "./ActivityReducer";
import {AppContentReducer} from "./AppContentReducer";
import {ErrorReporterReducer} from "./ErrorReporterReducer";
import {LoaderReducer} from "./LoaderReducer";
import {ModalReducer} from "./ModalReducer";
import {NotificationReducer} from "./NotificationReducer";
import {PortalStateReducer} from "./PortalStateReducer";
import {ReportReducer} from "./ReportReducer";
import {SchoolReducer} from "./SchoolReducer";
import {SurveyReducer} from "./SurveyReducer";
import {UserReducer} from "./UserReducer";

const rootPersistConfig = {
	key: "root",
	storage,
	whitelist: ["appContent", "survey"],
};

const AppReducer = combineReducers({
	activity: ActivityReducer,
	activityForm: ActivityFormReducer,
	activityMonitor: ActivityMonitorReducer,
	appContent: AppContentReducer,
	errorReporter: ErrorReporterReducer,
	loader: LoaderReducer,
	modal: ModalReducer,
	notification: NotificationReducer,
	portalStates: PortalStateReducer,
	reports: ReportReducer,
	school: SchoolReducer,
	survey: SurveyReducer,
	user: UserReducer,
});

const RootReducer = (state, action) => {
	let updatedState = state;
	if (
		(action.type === ActionTypes.USER_GET_AUTHED && !action.payload.status) ||
		action.type === ActionTypes.USER_LOGOUT_COMPLETED
	)
		updatedState = undefined;
	return AppReducer(updatedState, action);
};

export default persistReducer(rootPersistConfig, RootReducer);
