import * as ActionTypes from "../Redux/ActionTypes";
import {CreateReducer} from "../Redux/Utilities";
import {DOMAIN_SUBSCRIPTION_KEY} from "../Scripts/Constants";
import {DOMAIN_MATH, DOMAIN_ORAL_LANGUAGE} from "../Scripts/Domain";
import {getDomains, mergeArrays} from "../Scripts/Utilities";

const domainsTaxonomy = getDomains();
const initialState = {
	initializedClasses: false,
	initializedSchools: false,
	initializedStudents: false,
	initializedTerms: false,
	initializedResourceCategories: false,
	classes: [],
	schools: [],
	selectedSchool: null,
	terms: [],
	currentTerm: null,
	divisions: [],
	groups: {
		groups: [],
		studentsForMoving: [],
	},
	resources: [],
	totalResourcePages: 1,
	resourceCategories: [],
	students: [],
	currentStudent: null,
	studentMathResults: null,
	studentOralLanguageResults: null,
	currentClassroom: null,
	studentsIncurrentClassroom: [],
	domain: DOMAIN_ORAL_LANGUAGE,
	circleCharts: {},
	preKMathStudentData: [],
	activityAssessmentsSubmissions: [],
	readingDemoClasses: [],
};

const getAllStudentAuthorizationLevels = (students, user, state) => {
	const {currentTerm, terms} = state;
	if (
		(students.length &&
			user &&
			user.entitlements &&
			user.entitlements.domain_subscriptions &&
			currentTerm,
		terms)
	) {
		const {domain_subscriptions} = user.entitlements;
		return students.map((student, index) => {
			let authorization = {subscription: null, domains: []};
			let previousSchoolBoardSubscriptionTerms = null;
			Object.keys(domain_subscriptions).forEach((subscriptionKey) => {
				const subscriptionEntitlement =
					subscriptionKey === DOMAIN_SUBSCRIPTION_KEY.SCHOOL_BOARD_CLIENT
						? domain_subscriptions[subscriptionKey][currentTerm.id]
						: domain_subscriptions[subscriptionKey];
				if (subscriptionKey === DOMAIN_SUBSCRIPTION_KEY.SCHOOL_BOARD_CLIENT) {
					previousSchoolBoardSubscriptionTerms = Object.keys(
						domain_subscriptions[subscriptionKey]
					).filter((termId) => {
						return Number(termId) !== currentTerm.id;
					});

					if (
						previousSchoolBoardSubscriptionTerms &&
						previousSchoolBoardSubscriptionTerms.length
					) {
						authorization.previousTermSubscriptions =
							previousSchoolBoardSubscriptionTerms.map((term) => {
								return {
									termId: Number(term),
									domains: domain_subscriptions[subscriptionKey][term],
								};
							});
					}
				}
				if (subscriptionEntitlement) {
					Object.keys(subscriptionEntitlement).forEach((domainKey) => {
						const domainEntitlement = subscriptionEntitlement[domainKey];
						if (domainEntitlement.includes(student.id)) {
							authorization = {
								...authorization,
								subscription: subscriptionKey,
								domains: [...authorization.domains, Number(domainKey)],
							};
						}
					});
				}
			});
			return {
				...student,
				authorization,
			};
		});
	}
	return students;
};

const setCurrentStudent = (students, addedNewChild) => {
	if (students && students.length) {
		if (addedNewChild) {
			return students[students.length - 1];
		}
		return students[0];
	}
	return null;
};

export const SchoolReducer = CreateReducer(initialState, {
	[ActionTypes.SCHOOL_GET_CLASSES]: (state, {payload}) => {
		if (!payload) return state;
		const newClassrooms = mergeArrays(payload, [...state.classes]);
		return {
			...state,
			classes: [...newClassrooms],
			school_id: state.school_id
				? state.school_id
				: payload.length
				? payload[0].school_id
				: null,
			initializedClasses: true,
		};
	},
	[ActionTypes.SCHOOL_GET_CLASSROOM]: (state, {payload}) => {
		if (!payload) return state;
		return {
			...state,
			classes: [
				...state.classes.map((classroom) =>
					classroom.id === payload.id ? payload : classroom
				),
			],
			currentClassroom: {...payload},
		};
	},
	[ActionTypes.SCHOOL_GET_READING_DEMO_CLASSES]: (state, {payload}) => {
		if (!payload) return state;
		return {
			...state,
			readingDemoClasses: payload,
		};
	},
	[ActionTypes.SCHOOL_CLEAR_CLASSES]: (state, {payload}) => {
		if (!payload) return state;
		return {
			...state,
			classes: payload,
			school_id: null,
			initializedClasses: false,
		};
	},
	[ActionTypes.SCHOOL_GET_SCHOOLS]: (state, {payload}) => {
		return {
			...state,
			schools: payload,
			school_id: payload.length ? payload?.[0].id : null,
			selectedSchool: payload && payload.length ? payload[0] : {},
			initializedSchools: true,
		};
	},
	[ActionTypes.SCHOOL_SET_CURRENT_SCHOOL_ID]: (state, {payload}) => {
		return {
			...state,
			school_id: payload,
		};
	},
	[ActionTypes.SCHOOL_GET_SCHOOL_BY_ID]: (state, {payload}) => {
		if (!payload) return state;
		const schools = [...state.schools];
		if (payload.addToList) schools.push(payload.selectedSchool);
		return {
			...state,
			selectedSchool: payload.selectedSchool,
			schools,
		};
	},
	[ActionTypes.SCHOOL_GET_TERMS]: (state, {payload}) => {
		if (!payload) return state;
		return {
			...state,
			terms: payload,
			initializedTerms: true,
			currentTerm:
				payload && payload.length
					? payload.find((term) => term.is_current)
					: null,
		};
	},
	[ActionTypes.SCHOOL_GET_TERM_CURRENT]: (state, {payload}) => {
		if (!payload) return state;
		return {
			...state,
			currentTerm: payload,
		};
	},
	[ActionTypes.SCHOOL_GET_DIVISIONS]: (state, {payload}) => {
		if (!payload) return state;
		return {
			...state,
			divisions: payload,
		};
	},
	[ActionTypes.SCHOOL_GET_CLASS_GROUPS]: (state, {payload}) => {
		if (!payload) return state;
		const {groups} = state;
		groups.groups = payload;
		return {
			...state,
			groups,
		};
	},
	[ActionTypes.SCHOOL_GROUP_SELECT_STUDENT]: (state, {payload}) => {
		if (!payload) return state;
		const {groups} = state;
		const studentsForMoving = [...groups.studentsForMoving];
		const studentIdIndex = studentsForMoving.findIndex(
			(student) => student.id === payload.id
		);
		if (studentIdIndex >= 0) {
			studentsForMoving.splice(studentIdIndex, 1);
		} else {
			studentsForMoving.splice(studentsForMoving.length, 0, payload);
		}
		return {
			...state,
			groups: {
				...groups,
				studentsForMoving,
			},
		};
	},
	[ActionTypes.SCHOOL_GROUP_CLEAR_SELECTED_STUDENT]: (state) => {
		const {groups} = state;
		groups.studentsForMoving = [];
		return {
			...state,
			groups,
		};
	},
	[ActionTypes.SCHOOL_GET_RESOURCES]: (state, {payload}) => {
		if (!payload) return state;
		const {resources, totalPages, lazyLoad} = payload;
		return {
			...state,
			totalResourcePages: Number(totalPages),
			resources: lazyLoad ? [...state.resources, ...resources] : resources,
		};
	},
	[ActionTypes.SCHOOL_GET_RESOURCE_CATEGORIES]: (state, {payload}) => {
		if (!payload) return state;
		return {
			...state,
			resourceCategories: payload,
			initializedResourceCategories: true,
		};
	},
	[ActionTypes.SCHOOL_GET_STUDENTS]: (state, {payload}) => {
		if (!payload) return state;
		const {user, students, addedNewChild} = payload;
		const authorizedStudents = user
			? getAllStudentAuthorizationLevels(students, user, state)
			: students;
		const currentStudent = setCurrentStudent(authorizedStudents, addedNewChild);
		const allMathStudents =
			currentStudent?.classes.length > 0 &&
			currentStudent.classes.every(
				(classroom) => classroom?.domain_id === DOMAIN_MATH
			);
		return {
			...state,
			students: authorizedStudents,
			domain: allMathStudents ? DOMAIN_MATH : DOMAIN_ORAL_LANGUAGE,
			currentStudent,
			initializedStudents: true,
		};
	},
	[ActionTypes.SCHOOL_GET_STUDENT_BY_ID]: (state, {payload}) => {
		if (!payload) return state;
		const {student, user} = payload;
		const {students} = state;
		const studentToBeUpdatedIndex = students.findIndex(
			(oldStudent) => oldStudent.id === student.id
		);
		students.splice(studentToBeUpdatedIndex, 1, student);

		const authorizedStudents = user
			? getAllStudentAuthorizationLevels(students, user, state)
			: students;

		return {
			...state,
			students: authorizedStudents,
			currentStudent: authorizedStudents[studentToBeUpdatedIndex],
		};
	},
	[ActionTypes.SCHOOL_SET_CURRENT_CLASSROOM]: (state, {payload}) => {
		if (!payload) return state;
		const {classroom} = payload;
		return {
			...state,
			currentClassroom: {...classroom},
		};
	},
	[ActionTypes.SCHOOL_GET_STUDENTS_IN_CLASSROOM]: (state, {payload}) => {
		if (!payload) return state;
		const {students} = payload;
		return {
			...state,
			studentsIncurrentClassroom: [...students],
		};
	},
	[ActionTypes.SCHOOL_UPDATE_CURRENT_STUDENT]: (state, {payload}) => {
		if (!payload) return state;
		return {
			...state,
			currentStudent: state.students.find((student) => {
				return student.id === payload;
			}),
		};
	},
	[ActionTypes.SCHOOL_GET_STUDENT_ORAL_LANGUAGE_RESULTS]: (
		state,
		{payload}
	) => {
		if (!payload) return state;
		if (payload.no_most_recent) {
			return {
				...state,
				studentOralLanguageResults: {
					submissions: payload.submissions,
					hasAssessment: false,
				},
			};
		}
		return {
			...state,
			studentOralLanguageResults: {
				...payload,
				studentName: state.currentStudent ? state.currentStudent.name : "",
				hasAssessment: true,
			},
		};
	},
	[ActionTypes.SCHOOL_GET_STUDENT_MATH_RESULTS]: (state, {payload}) => {
		if (!payload) return state;
		if (payload.no_most_recent) {
			return {
				...state,
				studentMathResults: {
					submissions: payload.submissions,
					hasAssessment: false,
				},
			};
		}
		return {
			...state,
			studentMathResults: {
				...payload,
				studentName: state.currentStudent ? state.currentStudent.name : "",
				hasAssessment: true,
			},
		};
	},
	[ActionTypes.SCHOOL_GET_STUDENT_SUBMISSIONS]: (state, {payload}) => {
		if (!payload) return state;
		const newState = {
			...state,
		};
		if (payload.domain === DOMAIN_ORAL_LANGUAGE)
			newState.studentOralLanguageResults = {
				...state.studentOralLanguageResults,
				submissions: payload.submissions,
			};
		if (payload.domain === DOMAIN_MATH)
			newState.studentMathResults = {
				...state.studentMathResults,
				submissions: payload.submissions,
			};
		return newState;
	},
	[ActionTypes.SCHOOL_SET_DOMAIN]: (state, {payload}) => {
		if (!payload) return state;
		const domainIds = domainsTaxonomy.map(
			(domainTaxonomy) => domainTaxonomy.id
		);
		if (domainIds.includes(payload)) {
			return {
				...state,
				domain: payload,
			};
		}
		console.error("Invalid domain code: ", payload);
		return state;
	},
	[ActionTypes.SCHOOL_GET_CIRCLE_CHARTS]: (state, {payload}) => {
		if (!payload) return state;
		const {classroomId, outcomeId, response} = payload;
		const {students} = response;
		const hasClassroomData = state.circleCharts.hasOwnProperty(classroomId);
		return {
			...state,
			circleCharts: {
				...state.circleCharts,
				[classroomId]: {
					...(hasClassroomData ? state.circleCharts[classroomId] : {}),
					[outcomeId]: {
						...(hasClassroomData
							? state.circleCharts[classroomId][outcomeId]
							: {}),
						...students,
					},
				},
			},
		};
	},
	[ActionTypes.SCHOOL_GET_ALL_PRE_K_MATH_STUDENT_DATA]: (state, {payload}) => {
		if (!payload) return state;
		const {response} = payload;
		return {
			...state,
			preKMathStudentData: [...response],
		};
	},
	[ActionTypes.SCHOOL_GET_SUBMISSIONS_AND_KEEP_RESPONSES]: (
		state,
		{payload}
	) => {
		if (!payload) return state;
		const {submissions} = payload;
		return {
			...state,
			activityAssessmentsSubmissions: [...submissions],
		};
	},
});
