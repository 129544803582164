import {PORTALS, ROLES} from "./Constants";
import {InjectedWindow} from "./PublicInterfaces";

declare let window: InjectedWindow;

export function get_url() {
	return window.sprig.REACT_APP_BACKEND_URL;
}

export const getSupportUrl = (portal: string) => {
	if (
		portal === ROLES.PRINCIPAL ||
		portal === ROLES.DIRECTOR ||
		portal === ROLES.EDITOR ||
		portal === ROLES.ADULT
	)
		return "";
	const supportCategory = getSupportCategory(portal);
	return `${get_url()}support/?ht_kb_category=${supportCategory}`;
};

const getSupportCategory = (portal: string) => {
	switch (portal) {
		case ROLES.ADMIN:
		case PORTALS.ADMIN:
			return "for-data-admins";
		case ROLES.CAREGIVER:
		case PORTALS.CAREGIVER:
			return "for-caregivers";
		case ROLES.TEACHER:
		case PORTALS.EDUCATOR:
			return "for-teachers";
		default:
			throw new Error(`Unsupported portal type ${portal}`);
	}
};

export function getAuth() {
	const token = localStorage.getItem("sprig-login-token");
	return token ? `Bearer ${JSON.parse(token)}` : "";
}
