import "../../../../../../Stylesheets/css/scss/Components/Portal/Public/Assessment/Public/QuestionVisual.css";

import i18n from "i18next";
import React from "react";

import {AssetManager} from "../../../../../../Scripts/AssetManager";
import {Icon} from "../../../../../Public";

export const QuestionVisual = ({label, answers}) => {
	const assets = AssetManager.ASSETS;
	const visuals = [
		{
			label: i18n.t("role.mom"),
			img: {
				src: assets.ASSESSMENT.ROLES.MOM,
				alt: i18n.t("role.mom"),
			},
			checked: answers.includes("mom"),
		},
		{
			label: i18n.t("role.dad"),
			img: {
				src: assets.ASSESSMENT.ROLES.DAD,
				alt: i18n.t("role.dad"),
			},
			checked: answers.includes("dad"),
		},
		{
			label: i18n.t("role.grandparents"),
			img: {
				src: assets.ASSESSMENT.ROLES.GRANDPARENTS,
				alt: i18n.t("role.grandparents"),
			},
			checked: answers.includes("grandparents"),
		},
		{
			label: i18n.t("role.siblings"),
			img: {
				src: assets.ASSESSMENT.ROLES.SIBLINGS,
				alt: i18n.t("role.siblings"),
			},
			checked: answers.includes("siblings"),
		},
		{
			label: i18n.t("role.aunt"),
			img: {
				src: assets.ASSESSMENT.ROLES.AUNT_UNCLE,
				alt: i18n.t("role.aunt"),
			},
			checked: answers.includes("aunt"),
		},
		{
			label: i18n.t("role.friends"),
			img: {
				src: assets.ASSESSMENT.ROLES.FRIENDS,
				alt: i18n.t("role.friends"),
			},
			checked: answers.includes("friends"),
		},
		{
			label: i18n.t("role.cousins"),
			img: {
				src: assets.ASSESSMENT.ROLES.COUSINS,
				alt: i18n.t("role.cousins"),
			},
			checked: answers.includes("cousins"),
		},
		{
			label: i18n.t("role.teacher"),
			img: {
				src: assets.ASSESSMENT.ROLES.TEACHER,
				alt: i18n.t("role.teacher"),
			},
			checked: answers.includes("teacher"),
		},
	];

	return (
		<div className="question-visual">
			<div className="question-visual-group">
				<div className="question-visual-group-text" title={label}>
					{label}
				</div>
			</div>
			<div className="question-visual-assets">
				{visuals.map((visual) => (
					<div className="question-visual-asset" key={visual.label}>
						<div className="question-visual-img">
							<object type="image/svg+xml" data={visual.img.src}>
								{visual.img.alt}
							</object>
							{visual.checked && (
								<div className="question-visual-check">
									<Icon
										type="faCheckCircle"
										size="2x"
										className="text-primary"
									/>
								</div>
							)}
						</div>
						<div className="question-visual-asset-label">{visual.label}</div>
					</div>
				))}
			</div>
		</div>
	);
};
