import axios, {CancelToken} from "axios";

import {getAuth} from "../Fetch";

export const getData = async (
	url,
	returnFullResponse?: boolean,
	cancelToken?: CancelToken
) => {
	try {
		const response = await axios.get(url, {
			headers: {
				Authorization: getAuth(),
				"Content-Type": "text/plain",
				Accept: "application/json; charset=utf-8",
			},
			cancelToken,
		});
		if (response.status === 200) {
			return returnFullResponse ? response : response.data;
		}
	} catch (error: any) {
		console.error(error);
		return error.response;
	}
};

export const postDataNoAuth = async (url, data) => {
	try {
		const response = await axios({
			method: "post",
			url,
			data,
		});
		if (response.status < 300) return response;
	} catch (error: any) {
		console.error(error);
		return error.response;
	}
};

export const postData = async (url, data?: any) => {
	try {
		const response = await axios({
			method: "post",
			headers: {
				Authorization: getAuth(),
			},
			url,
			data,
		});
		if (response.status < 300) return response;
	} catch (error: any) {
		console.error(error);
		return error.response;
	}
};

export const postDataReturnResponse = async (url, data) => {
	try {
		const response = await axios({
			method: "post",
			headers: {
				Authorization: getAuth(),
			},
			url,
			data,
		});
		if (response.status < 300) return response;
	} catch (error: any) {
		console.error(error);
		return error.response;
	}
};

export const putData = async (url, data) => {
	try {
		const response = await axios({
			method: "put",
			headers: {
				Authorization: getAuth(),
			},
			url,
			data,
		});
		if (response.status < 300) return response;
	} catch (error: any) {
		console.error(error);
		return error.response;
	}
};

export const postDataJSON = async (url, data) => {
	try {
		const response = await axios({
			method: "post",
			headers: {
				Authorization: getAuth(),
				"Content-Type": "application/json; charset=utf-8",
			},
			url,
			data,
		});
		if (response.status < 300) return response;
	} catch (error: any) {
		console.error(error);
		return error.response;
	}
};

export const postDataAcceptJSON = async (url, data) => {
	try {
		const response = await axios({
			method: "post",
			headers: {
				Authorization: getAuth(),
				"Content-Type": "application/json; charset=utf-8",
				Accept: "application/json; charset=utf-8",
			},
			url,
			data,
		});
		if (response.status < 300) return response;
	} catch (error: any) {
		console.error(error);
		return error.response;
	}
};

export const deleteData = async (url) => {
	try {
		const response = await axios({
			method: "delete",
			headers: {
				Authorization: getAuth(),
			},
			url,
		});
		return response;
	} catch (error: any) {
		console.error(error);
		return error.response;
	}
};

export const deleteDataAcceptJSON = async (url) => {
	try {
		const response = await axios({
			method: "delete",
			headers: {
				Authorization: getAuth(),
				Accept: "application/json; charset=utf-8",
			},
			url,
		});
		return response;
	} catch (error: any) {
		console.error(error);
		return error.response;
	}
};

export const getDataNonJSON = async (url) => {
	try {
		const response = await axios.get(url, {
			headers: {
				Authorization: getAuth(),
				"Content-Type": "*/*",
				Accept: "*/*",
			},
			responseType: "arraybuffer",
		});

		if (response.status === 200) {
			return response.data;
		}
	} catch (error) {
		console.error(error);
	}
};

export const getDataOnlyAuth = async (url) => {
	try {
		const response = await axios({
			method: "get",
			headers: {
				Authorization: getAuth(),
			},
			url,
		});
		return response;
	} catch (error: any) {
		console.error(error);
		return error.response;
	}
};

export const postDataOnlyAuth = async (url) => {
	try {
		const response = await axios({
			method: "post",
			headers: {
				Authorization: getAuth(),
			},
			url,
		});
		return response;
	} catch (error: any) {
		console.error(error);
		return error.response;
	}
};

export const patchData = async (url, data) => {
	try {
		const response = await axios({
			method: "patch",
			headers: {
				Authorization: getAuth(),
				"Content-Type": "application/json; charset=utf-8",
				Accept: "application/json; charset=utf-8",
			},
			url,
			data,
		});
		return response;
	} catch (error: any) {
		console.error(error);
		return error.response;
	}
};
