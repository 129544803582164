import React from "react";
import {Trans} from "react-i18next";

import {AUTOMATION_TEST_ID} from "../../../../../Scripts/Constants";
import {NOTIFICATION_TYPE} from "../../../../../Scripts/Notifications";
import {
	BadgeEarnedNotificationData,
	NotificationModel,
} from "../../../../../Scripts/PublicInterfaces";

export const NewBadgeEarned = ({
	notification,
}: {
	notification: NotificationModel<BadgeEarnedNotificationData>;
}) => {
	const badgeName = notification.data.badge_name;
	return (
		<div className="col-10">
			<small
				className="nun-semibold"
				name={AUTOMATION_TEST_ID.PUBLIC.COMPONENTS.NOTIFICATION_CONTENT}
			>
				{notification.type === NOTIFICATION_TYPE.studentBadgeEarned && (
					<Trans i18nKey="phrase.new_badge_earned">
						{[
							<span key="studentName" className="nun-bold">
								{{studentName: notification.data.student_name}}
							</span>,
							<span key="badgeName" className="nun-bold">
								{{badgeName}}
							</span>,
						]}
					</Trans>
				)}
			</small>
		</div>
	);
};
