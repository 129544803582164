import i18n from "i18next";
import React from "react";

import {Icon} from "../../../../../../Public";
import {TabProForRubricTable} from ".";

export const RubricTable = ({tab}: {tab: TabProForRubricTable}) => (
	<div className="math-results-rubric">
		<table className="math-results-rubric-table">
			<tbody>
				<tr>
					<td />
					<td className="text-center">
						<small>{i18n.t("common.level")}</small>
					</td>
					<td>
						<small>{i18n.t("common.rubric")}</small>
					</td>
				</tr>
				{tab.rubrics.map((rubric) => {
					return (
						<tr key={rubric.level}>
							<td className="text-right">
								{rubric.level === tab.rubricLevel && (
									<Icon
										type="faCheckCircle"
										className="text-primary"
										size="2x"
									/>
								)}
							</td>
							<td className="text-center">
								<p>{rubric.level}</p>
							</td>
							<td className="">
								<small>{rubric.rubric}</small>
							</td>
						</tr>
					);
				})}
			</tbody>
		</table>
	</div>
);
