import React from "react";

import {
	AUTOMATION_TEST_ID,
	PORTALS,
	ROLES,
} from "../../../../Scripts/Constants";
import {Icon} from "../../../Public";

export const ErrorMessage = ({
	portal,
	message,
}: {
	portal: string;
	message: string | JSX.Element;
}) => {
	const portalStyle = getStyle(portal);
	return (
		<div className={portalStyle}>
			<div id={AUTOMATION_TEST_ID.PUBLIC.COMPONENTS.ERROR_MESSAGE}>
				<Icon
					type="faExclamationCircle"
					className="mr-2 text-white"
					iconWrapperClassName="d-inline"
				/>
				{message}
			</div>
		</div>
	);
};

const getStyle = (portal: string) => {
	switch (portal) {
		case PORTALS.ADMIN:
		case ROLES.ADMIN:
			return "alert adm-bg-primary text-white mb-0 mt-4";
		case PORTALS.EDUCATOR:
		case ROLES.TEACHER:
		case PORTALS.PRINCIPAL:
		case PORTALS.DIRECTOR:
		case ROLES.DIRECTOR:
		case ROLES.PRINCIPAL:
			return "alert edu-bg-primary text-white mb-1 mt-1";
		case PORTALS.CAREGIVER:
		case ROLES.CAREGIVER:
			return "alert bg-primary text-white mb-1 mt-1";
		case PORTALS.EDITOR:
		case ROLES.EDITOR:
			return "alert edu-bg-primary text-white mb-1 mt-1 w-100";
		default:
			throw new Error(`Unsupported portal type ${portal}`);
	}
};
