import * as ActionTypes from "../Redux/ActionTypes";

export const setCurrentRole = (role) => ({
	type: ActionTypes.APPCONTENT_SET_CURRENT_ROLE,
	payload: role,
});

export const setCurrentRoleToDefault = () => ({
	type: ActionTypes.APPCONTENT_DEFAULT_CURRENT_ROLE,
});

export const initiatePortalTour = ({personId, tourId}) => ({
	type: ActionTypes.APPCONTENT_INITIATE_PORTAL_TOUR,
	payload: {personId, tourId},
});

export const proceedToNextPortalTourStep = (tour) => ({
	type: ActionTypes.APPCONTENT_PROCEED_TO_NEXT_PORTAL_TOUR_STEP,
	payload: tour,
});

export const endCurrentPortalTour = (tour) => ({
	type: ActionTypes.APPCONTENT_END_CURRENT_TOUR,
	payload: tour,
});
