import "../../../../Stylesheets/css/scss/Components/Portal/Public/Reports/StudentMilestone.css";

import i18n from "i18next";
import React, {Component, Fragment} from "react";
import isEqual from "react-fast-compare";

import {ModalActions} from "../../../../Actions";
import * as ActionTypes from "../../../../Redux/ActionTypes";
import {
	AUTOMATION_TEST_ID,
	MODAL_TYPES,
	ORAL_LANGUAGE_RUBRIC,
	ORAL_LANGUAGE_RUBRIC_BREAKDOWN,
	PORTALS,
} from "../../../../Scripts/Constants";
import {DOMAIN_MATH, DOMAIN_ORAL_LANGUAGE} from "../../../../Scripts/Domain";
import {ReportMilestoneLevel} from "../../../../Scripts/Interfaces";
import {
	ClassroomModel,
	ClassroomStudentModel,
	EducatorTaxonomiesModel,
	HasId,
	HasName,
} from "../../../../Scripts/PublicInterfaces";
import {
	constructDataForStudents,
	getCurrentMilestone,
} from "../../../../Scripts/Reports/MilestoneReportHelper";
import {ClassroomStudentsModel} from "../../Educator/Classroom/Students";
import {Loader} from "../index";
import {ReportAlertNoData} from "./ReportAlertNoData";
import {
	StudentMilestoneChart,
	StudentMilestoneFilters,
	StudentMilestoneStudents,
} from "./StudentMilestoneComponents";

interface StudentMilestoneProps {
	report: ReportMilestoneLevel;
	students: ClassroomStudentModel[];
	taxonomies: EducatorTaxonomiesModel;
	classroom: ClassroomModel;
	isHolisticMath?: boolean;
	activity?: {};
}

export interface MilestoneStudentModel extends HasId, HasName {
	activityCompletion: number;
	expectedLevel: number;
	level: number;
}

interface StudentMilestoneState {
	currentMilestone: number | string;
	selectedStudents: [] | ClassroomStudentsModel[];
	rubric: string | null;
	students: MilestoneStudentModel[];
	deviation: string | null;
	milestone: number | string;
	initialized: boolean;
}

export class StudentMilestone extends Component<
	StudentMilestoneProps,
	StudentMilestoneState
> {
	constructor(props) {
		super(props);
		this.state = {
			milestone: 0,
			rubric: null,
			deviation: null,
			students: [],
			selectedStudents: [],
			initialized: false,
			currentMilestone: "",
		};
	}

	componentDidMount = () => {
		const {report, students, taxonomies, classroom, isHolisticMath} =
			this.props;
		if (report) {
			const {milestone, rubric} = this.state;
			const currentMilestone = getCurrentMilestone(report.milestones);
			const domain = classroom.domain_id;
			this.setState({
				students: constructDataForStudents({
					students,
					report,
					milestone,
					rubric,
					taxonomies,
					domain,
					isHolisticMath,
				}),
				milestone: currentMilestone,
				currentMilestone,
				initialized: true,
			});
		}
	};

	componentDidUpdate = (prevProps) => {
		const {report, students, taxonomies, classroom, isHolisticMath} =
			this.props;
		const {milestone, rubric} = this.state;
		if (!isEqual(prevProps.report, report)) {
			const currentMilestone = getCurrentMilestone(report?.milestones);
			const domain = classroom.domain_id;
			this.setState({
				students: constructDataForStudents({
					students,
					report,
					milestone,
					rubric,
					taxonomies,
					domain,
					isHolisticMath,
				}),
				milestone: currentMilestone,
				currentMilestone,
				initialized: true,
			});
		}
	};

	onSelectMilestone = (data) => {
		const {report, students, taxonomies, classroom, isHolisticMath} =
			this.props;
		const {rubric} = this.state;
		const domain = classroom.domain_id;
		this.setState({
			deviation: null,
			milestone: data.id,
			students: constructDataForStudents({
				students,
				report,
				milestone: data.id,
				rubric,
				taxonomies,
				domain,
				isHolisticMath,
			}),
		});
	};

	onSelectDeviation = (data) => {
		const {report, students, taxonomies, classroom, isHolisticMath} =
			this.props;
		const {rubric} = this.state;
		const domain = classroom.domain_id;
		this.setState({
			deviation: data.type,
			milestone: data.milestone,
			students: constructDataForStudents({
				students,
				report,
				milestone: data.milestone,
				rubric,
				taxonomies,
				domain,
				isHolisticMath,
			}),
		});
	};

	onSelectRubric = (data) => {
		const {report, students, taxonomies, classroom, isHolisticMath} =
			this.props;
		const {milestone} = this.state;
		const domain = classroom.domain_id;
		const constructData = constructDataForStudents({
			students,
			report,
			milestone,
			rubric: data.id,
			taxonomies,
			domain,
			isHolisticMath,
		});
		this.setState({
			rubric: data.id,
			students: constructData,
		});
	};

	onSelectStudent = (student) => {
		this.setState({
			selectedStudents: student ? [student] : [],
		});
	};

	onRemoveStudent = () => {
		this.setState({
			selectedStudents: [],
		});
	};

	onSelectReset = () => {
		this.setState({
			milestone: this.state.currentMilestone,
			rubric: null,
			deviation: null,
			selectedStudents: [],
		});
	};

	onOpenRecommendedActivities = (student) => {
		this.handleOpenRecommendActivities({student});
	};

	handleOpenRecommendActivities = ({
		recommendToGroup,
		student,
	}: {
		recommendToGroup?: boolean;
		student?: {};
	}) => {
		const {rubric, students} = this.state;
		const {taxonomies, classroom} = this.props;
		const outcomeSlugs = rubric
			? ORAL_LANGUAGE_RUBRIC_BREAKDOWN[rubric]
			: ORAL_LANGUAGE_RUBRIC_BREAKDOWN[ORAL_LANGUAGE_RUBRIC.CLARITY];
		let currentCompetency;
		if (rubric) {
			currentCompetency = taxonomies.competencies.find(
				(competency) =>
					(classroom.domain_id === DOMAIN_ORAL_LANGUAGE
						? competency.slug
						: competency.id) === rubric
			);
		}
		const outcomes =
			classroom.domain_id === DOMAIN_ORAL_LANGUAGE
				? outcomeSlugs.map((outcomeSlug) => {
						const targetOutcome = taxonomies?.specificOutcomes?.find(
							(outcome) => outcome.slug === outcomeSlug
						);
						return targetOutcome ? targetOutcome.id : null;
				  })
				: !!rubric
				? currentCompetency.id
				: 0;
		const data = {
			student,
			students,
			outcomes:
				classroom.domain_id === DOMAIN_ORAL_LANGUAGE
					? outcomes.filter((outcome) => outcome).join(",")
					: [],
			competency: classroom.domain_id === DOMAIN_MATH ? outcomes : null,
			domain: classroom.domain_id,
			recommendToGroup: recommendToGroup ? true : false,
		};
		ModalActions.showModal(
			{
				open: true,
				data,
				role: PORTALS.EDUCATOR,
				size: "md",
				keepInWindow: true,
			},
			MODAL_TYPES.RECOMMENDED_ACTIVITIES
		);
	};

	onOpenRecommendActivitiesOnFilters = () => {
		this.handleOpenRecommendActivities({recommendToGroup: true});
	};

	render() {
		const {report, taxonomies, classroom, isHolisticMath, activity} =
			this.props;
		const {students, initialized} = this.state;
		return (
			<div
				className="student-milestone-report"
				id={
					AUTOMATION_TEST_ID.EDUCATOR.CLASSROOM.REPORTS.STUDENT_MILESTONE_REPORT
				}
			>
				<div className="student-milestone-report-title">
					<h5>{i18n.t("chart.student_milestone_report")}</h5>
				</div>
				{students.length > 0 ? (
					<Loader.Wrap
						conditional={
							(students?.length && !!report?.milestones) || initialized
						}
						actionTypes={[
							ActionTypes.REPORT_GET_SINGLE,
							ActionTypes.REPORT_GET,
						]}
					>
						<Loader.HideContent>
							<Fragment>
								<StudentMilestoneFilters
									classroomDomain={classroom.domain_id}
									students={students}
									milestones={report?.milestones}
									studentMilestones={report?.subitems}
									selections={{...this.state}}
									onSelectStudent={this.onSelectStudent}
									onRemoveStudent={this.onRemoveStudent}
									onSelectMilestone={this.onSelectMilestone}
									onSelectRubric={this.onSelectRubric}
									onSelectReset={this.onSelectReset}
									onOpenRecommendActivitiesOnFilters={
										this.onOpenRecommendActivitiesOnFilters
									}
									competencies={taxonomies.competencies}
									isHolisticMath={isHolisticMath}
									activity={activity}
									classroom={classroom}
								/>
								<StudentMilestoneChart
									onSelectDeviation={this.onSelectDeviation}
									onSelectMilestone={this.onSelectMilestone}
									selections={{...this.state}}
									data={report}
									students={this.props.students}
									taxonomies={taxonomies}
									domain={classroom.domain_id}
									isHolisticMath={isHolisticMath}
								/>
								<StudentMilestoneStudents
									students={students}
									selections={{...this.state}}
									milestones={report?.milestones}
									onSelectStudent={this.onSelectStudent}
									onRemoveStudent={this.onRemoveStudent}
									onOpenRecommendedActivities={this.onOpenRecommendedActivities}
									domain={classroom.domain_id}
									isHolisticMath={isHolisticMath}
								/>
							</Fragment>
						</Loader.HideContent>
						<Loader.Spinner />
					</Loader.Wrap>
				) : (
					<ReportAlertNoData />
				)}
			</div>
		);
	}
}
