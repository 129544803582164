import "../../../Stylesheets/css/scss/Components/Private/Launch/LaunchScreen.css";

import React from "react";

import {LoaderSpinner} from "../../Public";

export const LaunchScreen = () => (
	<div className="launch-screen-component">
		<LoaderSpinner />
	</div>
);
