import Collapse from "@kunukn/react-collapse";
import i18n from "i18next";
import React, {Component, Fragment} from "react";
import {Redirect} from "react-router-dom";

import {AppContentActions} from "../../../../Actions";
import {AUTOMATION_TEST_ID, ROLES} from "../../../../Scripts/Constants";
import {STYLED_ELEMENT} from "../../../../Scripts/Constants";
import {portalClassSelector} from "../../../../Scripts/Utilities";
import {Icon} from "../../../Public";

interface UserRolesProps {
	currentRole: string;
	portal: string;
	roles: string[];
}

interface UserRolesState {
	toggleDown: boolean;
	switchPortal: boolean;
}

export class UserRoles extends Component<UserRolesProps, UserRolesState> {
	constructor(props) {
		super(props);
		this.state = {
			toggleDown: false,
			switchPortal: false,
		};
	}

	handleRolesDropdown = () => {
		const toggleDown = !this.state.toggleDown;
		this.setState({
			toggleDown,
		});
	};

	renderRoles = (role) => {
		if (
			[
				ROLES.EDITOR,
				ROLES.PRINCIPAL,
				ROLES.STUDENT,
				ROLES.TEACHER,
				ROLES.ADMIN,
				ROLES.CAREGIVER,
				ROLES.DIRECTOR,
			].includes(role)
		) {
			return i18n.t(`role.${role}`);
		} else {
			return i18n.t(`role.${ROLES.TEACHER}`);
		}
	};

	handleRoleClick = (event) => {
		const targetRole = event.target.value;
		const {currentRole} = this.props;
		if (targetRole !== currentRole) {
			this.setState(
				{
					switchPortal: true,
				},
				() => AppContentActions.setCurrentRole(targetRole)
			);
		}
	};

	render() {
		const {portal, roles, currentRole} = this.props;
		const {toggleDown, switchPortal} = this.state;
		const textColor = portalClassSelector(portal, STYLED_ELEMENT.TEXT);
		if (switchPortal) {
			return <Redirect push to={"/"} />;
		}
		return (
			<Fragment>
				{roles?.length > 1 ? (
					<div className="dropdown-item pt-0 pb-0 user-roles">
						<div className="roles">
							<div className="current-role">
								<button
									className="btn text-capitalize"
									onClick={this.handleRolesDropdown}
									id={
										AUTOMATION_TEST_ID.ACCOUNT.USER_DROPDOWN_CURRENT_ROLE_BUTTON
									}
								>
									<Icon
										type="faUserCircle"
										className={`mr-2 ${textColor}`}
										iconWrapperClassName="d-inline"
									/>{" "}
									{this.renderRoles(currentRole)}
									<span className="role-toggle-icon">
										<Icon
											type={toggleDown ? "faChevronDown" : "faChevronRight"}
											className={`ml-1 ${textColor}`}
										/>
									</span>
								</button>
							</div>
							<Collapse isOpen={toggleDown} className="collapse-css-transition">
								<div className="edu">
									{roles
										.filter((role) => role !== "subscriber")
										.map((role) => (
											<div className="row" key={role}>
												<button
													className="btn text-capitalize"
													value={role}
													onClick={this.handleRoleClick}
													name={
														AUTOMATION_TEST_ID.ACCOUNT
															.USER_DROPDOWN_USER_ROLE_BUTTON
													}
												>
													{this.renderRoles(role)}
													{currentRole === role && (
														<Icon
															type="faCheck"
															className={`ml-2 ${textColor}`}
															iconWrapperClassName="d-inline"
														/>
													)}
												</button>
											</div>
										))}
								</div>
							</Collapse>
						</div>
					</div>
				) : (
					<button className="dropdown-item text-capitalize no-event">
						<Icon
							type="faUserCircle"
							className={`mr-2 ${textColor}`}
							iconWrapperClassName="d-inline"
						/>{" "}
						{this.renderRoles(roles[0])}
					</button>
				)}
				<div className="dropdown-divider" />
			</Fragment>
		);
	}
}
