import i18n from "i18next";
import React from "react";

import {SelectedStudent, StudentExpectation} from "./Students";

export const StudentMilestoneStudents = ({
	students,
	selections,
	milestones,
	domain,
	onSelectStudent,
	onRemoveStudent,
	onOpenRecommendedActivities,
	isHolisticMath,
}) => {
	const studentsBelowExpectation = students.filter(
		(student) => student.level < student.expectedLevel
	);
	const studentsAtExpectation = students.filter(
		(student) => student.level === student.expectedLevel
	);
	const studentsAboveExpectation = students.filter(
		(student) => student.level > student.expectedLevel
	);

	const preData = [
		{
			label: i18n.t("chart.below_expectation"),
			data: studentsBelowExpectation,
			class: "below",
		},
		{
			label: i18n.t("chart.at_expectation"),
			data: studentsAtExpectation,
			class: "at",
		},
		{
			label: i18n.t("chart.above_expectation"),
			data: studentsAboveExpectation,
			class: "above",
		},
	];

	const data = preData.filter((datum) => datum.data);
	return (
		<div className="student-milestone-students">
			<div className="student-milestone-students-selected">
				{selections.selectedStudents.map((selectedStudent) => (
					<SelectedStudent
						key={selectedStudent.id}
						selections={selections}
						milestones={milestones}
						domain={domain}
						student={selectedStudent}
						onRemoveStudent={onRemoveStudent}
						onOpenRecommendedActivities={onOpenRecommendedActivities}
					/>
				))}
			</div>
			<div
				className={`student-milestone-students-area expectations-${data?.length}`}
			>
				{data.map((datum) => (
					<StudentExpectation
						key={datum.label}
						expectation={datum}
						selectedStudentIds={selections.selectedStudents.map(
							(selectedStudent) => selectedStudent.id
						)}
						onSelectStudent={onSelectStudent}
						onRemoveStudent={onRemoveStudent}
						onOpenRecommendedActivities={onOpenRecommendedActivities}
						isHolisticMath={isHolisticMath}
					/>
				))}
			</div>
		</div>
	);
};
