import {QuestionModel, SurveyPage} from "../Components/Portal/PublicComponents";
import {SURVEY_PAGE_TYPE} from "./Constants";

/*
========================================================
======== Exported Constants
========================================================
*/

export const TRANSLATION_CODES = {
	ANCESTRAL_LANGUAGE: "{{ANCESTRAL_LANGUAGE}}",
	COMMUNITY_LANGUAGE: "{{COMMUNITY_LANGUAGE}}",
	LANGUAGE: "{{LANGUAGE}}",
	LANGUAGE_CODE: "{{LANGUAGE_CODE}}",
	PRIMARY_LANGUAGE: "{{PRIMARY_LANGUAGE}}",
	STUDENT: "{{STUDENT}}",
};

/*
========================================================
======== Exported Functions
========================================================
*/

export const newRegex = (code) => {
	return new RegExp(code, "g");
};

export const evaluateSubmissionsAndUpdateAllQuestionsForChildAssessment = (
	schema,
	responses
) => {
	const {questions} = schema;
	questions.forEach((question) => {
		const questionInResponse = responses.find(
			(response) => response.question_id === question.id
		);
		if (questionInResponse) {
			const {values} = questionInResponse;
			question.response = [...values];
		}
	});
	return {...schema, questions};
};

export const evaluatePagesAndUpdateAllQuestionsForChildAssessment = (
	schema,
	payload
) => {
	const {question, responseId} = payload;
	const {questions} = schema;
	const questionToBeUpdatedIndex = questions.findIndex(
		(questionInSchema) => questionInSchema.id === question.id
	);
	questions.splice(questionToBeUpdatedIndex, 1, {
		...questions[questionToBeUpdatedIndex],
		response: [...responseId],
	});
	return {...schema, questions};
};

export const evaluatePagesAndCreateAllQuestionsForChildAssessment = (
	questionsAndPages
) => {
	const {questions} = questionsAndPages;
	const createdQuestions = questions.map((question) => ({
		...question,
		response: [],
	}));
	return {...questionsAndPages, questions: createdQuestions};
};

export const evaluatePagesAndCreateAllQuestions = ({languages}, survey) => {
	const {questions, pages} = survey;
	let allPossibleQuestions: QuestionModel[] = [];
	let allPossiblePages: SurveyPage[] = [];
	let completedGroupIds: number[] = [];
	if (!pages || !pages[0] || !questions) return {};
	pages[0].forEach((page) => {
		if (!completedGroupIds.includes(page.clone_for_each_language_group_id)) {
			if (page.questions?.length) {
				let pageIsHidden = false;
				if (page.clone_for_each_language_group_id) {
					completedGroupIds.push(page.clone_for_each_language_group_id);
					const pagesWithSameGroupId = pages[0].filter(
						(filterPage) =>
							filterPage.clone_for_each_language_group_id ===
							page.clone_for_each_language_group_id
					);
					languages.forEach((language) => {
						pagesWithSameGroupId.forEach((pageWithSameGroupId) => {
							const pageInfo = retrieveFullQuestionsFromPageAndCheckIfHidden(
								pageWithSameGroupId,
								questions,
								languages
							);
							if (pageInfo.isHidden) pageIsHidden = pageInfo.isHidden;
							const languageVariantQuestions = pageInfo.questions.map(
								(questionFromPage) => {
									return {
										...questionFromPage,
										language,
									};
								}
							);
							allPossibleQuestions = [
								...allPossibleQuestions,
								...languageVariantQuestions,
							];

							allPossiblePages = [
								...allPossiblePages,
								{
									...pageWithSameGroupId,
									hidden: pageIsHidden,
									language,
								},
							];
						});
					});
				} else {
					const pageInfo = retrieveFullQuestionsFromPageAndCheckIfHidden(
						page,
						questions,
						languages
					);
					if (pageInfo.isHidden) pageIsHidden = pageInfo.isHidden;
					allPossibleQuestions = [
						...allPossibleQuestions,
						...pageInfo.questions,
					];

					allPossiblePages.push({
						...page,
						hidden: pageIsHidden,
					});
				}
			} else {
				allPossiblePages.push({
					...page,
					hidden: false,
				});
			}
		}
	});

	return {
		questions: allPossibleQuestions,
		pages: allPossiblePages,
	};
};

export const evaluatePagesAndUpdateAllQuestions = (schema, payload) => {
	const {
		questionId,
		responseId,
		languageVariant,
		customResponseId,
		max_response_count,
		options,
		removeAllResponses,
	} = payload;
	const {questions, pages} = schema;
	const updatedQuestions = findResponseIndexAndUpdateQuestions(
		questions,
		questionId,
		responseId,
		languageVariant,
		customResponseId,
		max_response_count,
		options,
		removeAllResponses
	);
	const updatedPages = pages.map((page) => {
		if (page && page.questions && page.questions.length) {
			const pageQuestions = page.questions.map((questionId) => {
				return updatedQuestions.find((updatedQuestion) =>
					page.language && updatedQuestion.language
						? questionId === updatedQuestion.id &&
						  page.language.code === updatedQuestion.language.code
						: questionId === updatedQuestion.id
				);
			});

			let isPageHidden = true;
			pageQuestions.forEach((pageQuestion) => {
				let requires;
				if (pageQuestion.clone_from) {
					requires = updatedQuestions.find(
						(question) => question.id === pageQuestion.clone_from
					)?.requires;
				} else {
					requires = pageQuestion.requires;
				}
				if (requires) {
					const condition = requires[0];
					const requirements = requires.slice(1);
					if (condition === "any") {
						requirements.forEach((requirement) => {
							const requirementId = requirement[0];
							const requirementOption = requirement[1];
							const requiredQuestion = updatedQuestions.find(
								(updatedQuestion) =>
									page.language && updatedQuestion.language
										? requirementId === updatedQuestion.id &&
										  page.language.code === updatedQuestion.language.code
										: requirementId === updatedQuestion.id
							);

							if (
								verifyLanguageCodePageRequirements(
									requiredQuestion,
									requirementOption,
									page
								)
							) {
								isPageHidden = false;
							}
						});
					} else {
						let allTestsPass = false;

						requirements.forEach((requirement) => {
							const requirementId = requirement[0];
							const requirementOption = requirement[1];
							const requiredQuestion = updatedQuestions.find(
								(updatedQuestion) =>
									page.language && updatedQuestion.language
										? requirementId === updatedQuestion.id &&
										  page.language.code === updatedQuestion.language.code
										: requirementId === updatedQuestion.id
							);
							if (
								verifyLanguageCodePageRequirements(
									requiredQuestion,
									requirementOption,
									page
								)
							) {
								allTestsPass = true;
							}
						});
						isPageHidden = !allTestsPass;
					}
				} else {
					isPageHidden = false;
				}
			});

			return {
				...page,
				hidden: isPageHidden,
			};
		}
		return page;
	});

	return {
		questions: updatedQuestions,
		pages: updatedPages,
	};
};

interface FullQuestionsFromPage extends QuestionModel {
	variant_of?: number;
	blockForMapping?: boolean;
	question?: string;
	subQuestions: FullQuestionsFromPage[];
}

interface TopLevelQuestions {
	question: string;
	max_response_count?: number;
	options?: {};
	subQuestions: FullQuestionsFromPage[];
}

export const organizeQuestionsForRender = (questions, page) => {
	const topLevelQuestions: TopLevelQuestions[] = [];
	const questionIdsMapped: number[] = [];
	if (page?.questions?.length) {
		let fullQuestionsFromPage: FullQuestionsFromPage[] = [];
		page.questions.forEach((questionId) => {
			const question: FullQuestionsFromPage = page.language
				? questions.find(
						(question) =>
							question.id === questionId &&
							question.language.code === page.language.code
				  )
				: questions.find((question) => question.id === questionId);
			if (question.clone_for_each_language) {
				fullQuestionsFromPage = [
					...fullQuestionsFromPage,
					...questions
						.filter((question) => question.id === questionId)
						.map((question, index) => ({
							...question,
							variant_of: index ? question.id : null,
						})),
				];
			} else {
				fullQuestionsFromPage = [...fullQuestionsFromPage, question];
			}
		});

		const checkIfNonCloneExists = fullQuestionsFromPage.filter(
			(question) => !question.clone_from
		);

		let newMappedQuestionId;
		if (!checkIfNonCloneExists.length) {
			fullQuestionsFromPage = fullQuestionsFromPage.map(
				(fullQuestion, index) => {
					if (!index) {
						const parentQuestion = questions.find(
							(question) => question.id === fullQuestion.clone_from
						);
						newMappedQuestionId = fullQuestion.id;
						return {
							...parentQuestion,
							...fullQuestion,
							response: fullQuestion.response,
							customResponse: fullQuestion.customResponse,
							options: parentQuestion.options,
							blockForMapping: true,
							clone_from: null,
						};
					}

					return fullQuestion;
				}
			);
		}

		fullQuestionsFromPage.forEach((question) => {
			if (
				!questionIdsMapped.includes(question.id) &&
				!question.clone_from &&
				question.major_text &&
				question.options
			) {
				questionIdsMapped.push(question.id);
				const subQuestionGroup = [
					question,
					...fullQuestionsFromPage
						.filter((fullQuestion) => {
							if (
								fullQuestion.clone_from === question.id ||
								fullQuestion.variant_of === question.id ||
								(!fullQuestion.blockForMapping &&
									newMappedQuestionId === question.id)
							) {
								questionIdsMapped.push(fullQuestion.id);
								return true;
							}
							return false;
						})
						.map((fullQuestion) => ({
							...fullQuestion,
							max_response_count: question.max_response_count,
						})),
				];

				topLevelQuestions.push({
					question: question.major_text,
					max_response_count: question.max_response_count,
					options: question.options,
					subQuestions: subQuestionGroup,
				});
			}
		});
	}
	return topLevelQuestions;
};

/*
========================================================
======== Internal Helper Functions
========================================================
*/

const verifyLanguageCodePageRequirements = (question, match, page) => {
	switch (match) {
		case TRANSLATION_CODES.LANGUAGE_CODE:
			return (
				question.response && question.response.includes(page.language.code)
			);
		case `{{CUSTOM_RESPONSE_VALUE_${question.id}}}`:
			return question.customResponse && question.customResponse.length;
		default:
			return question.response && question.response.includes(match);
	}
};

const retrieveFullQuestionsFromPageAndCheckIfHidden = (
	page,
	questions,
	languages
) => {
	let isPageHidden = false;
	const fullQuestionsFromPage: QuestionModel[] = [];
	page.questions.forEach((questionId) => {
		const retreivedQuestion = questions.find(
			(question) => question.id === questionId
		);
		if (retreivedQuestion.requires) isPageHidden = true;
		if (retreivedQuestion.clone_from) {
			const originalQuestion = questions.find(
				(question) => question.id === retreivedQuestion.clone_from
			);
			if (originalQuestion.requires) isPageHidden = true;
		}

		if (
			page.type === SURVEY_PAGE_TYPE.GRID &&
			retreivedQuestion.clone_for_each_language
		) {
			languages.forEach((language) => {
				fullQuestionsFromPage.push({
					...retreivedQuestion,
					cloned_from: retreivedQuestion.id,
					language,
					minor_text: retreivedQuestion.minor_text
						? retreivedQuestion.minor_text.replace(
								newRegex(TRANSLATION_CODES.LANGUAGE),
								language.english_name
						  )
						: null,
					options: retreivedQuestion.options
						? reviseSurveyOptions(retreivedQuestion.options, {languages})
						: null,
				});
			});
		} else {
			fullQuestionsFromPage.push({
				...retreivedQuestion,
				options: retreivedQuestion.options
					? reviseSurveyOptions(retreivedQuestion.options, {languages})
					: null,
			});
		}
	});

	return {
		questions: fullQuestionsFromPage,
		isHidden: isPageHidden,
	};
};

const reviseSurveyOptions = (options, {languages}) => {
	const revisedOptions: {}[] = [];
	if (!options || !options.length) return revisedOptions;
	const primaryLanguage = languages.find((language) => language.is_primary);
	let communityLanguage = languages.find((language) => language.is_ancestral);
	if (!communityLanguage) communityLanguage = primaryLanguage;
	options.forEach((option) => {
		if (option.clone_for_each_language) {
			languages.forEach((language) => {
				let optionText = option.text.replace(
					newRegex(TRANSLATION_CODES.LANGUAGE),
					language.english_name
				);
				optionText = optionText.replace(
					newRegex(TRANSLATION_CODES.COMMUNITY_LANGUAGE),
					communityLanguage.english_name
				);

				revisedOptions.push({
					...option,
					value: option.value.replace(
						newRegex(TRANSLATION_CODES.LANGUAGE_CODE),
						language.code
					),
					text: optionText,
				});
			});
		} else {
			revisedOptions.push({
				...option,
				text: option.text.replace(
					newRegex(TRANSLATION_CODES.COMMUNITY_LANGUAGE),
					communityLanguage.english_name
				),
			});
		}
	});
	return revisedOptions;
};

const findResponseIndexAndUpdateQuestions = (
	allQuestions,
	questionId,
	responseId,
	languageVariant,
	customResponseId,
	max_response_count,
	options,
	removeAllResponses
) => {
	let questions = [...allQuestions]; // avoid mutation from original parameter
	const questionIndex = questions.findIndex((question) =>
		languageVariant
			? question.id === questionId && question.language.code === languageVariant
			: question.id === questionId
	);
	const foundQuestion = questions[questionIndex];
	let updatedQuestion = {...foundQuestion};
	if (removeAllResponses) {
		updatedQuestion = {
			...updatedQuestion,
			response: removeAllResponses.response ? [] : updatedQuestion.response,
		};
		if (updatedQuestion.customResponse) {
			if (removeAllResponses.customResponse) {
				updatedQuestion = {...updatedQuestion, customResponse: []};
			} else if (customResponseId) {
				updatedQuestion = {
					...updatedQuestion,
					customResponse: [customResponseId],
				};
			}
		}
	} else {
		updatedQuestion =
			customResponseId && responseId
				? updatedCustomAndResponseInQuestion(
						foundQuestion,
						responseId,
						customResponseId,
						max_response_count,
						options
				  )
				: customResponseId
				? updateCustomResponseInQuestion(
						foundQuestion,
						customResponseId,
						max_response_count
				  )
				: updateResponseInQuestion(
						foundQuestion,
						responseId,
						max_response_count,
						options
				  );
	}
	questions[questionIndex] = updatedQuestion;
	return questions;
};

const updateResponseInQuestion = (
	question,
	responseId,
	max_response_count,
	options
) => {
	const responseAlreadySelected =
		question.response && question.response.includes(responseId);
	const responseCount = max_response_count
		? max_response_count
		: question.max_response_count;
	if (!responseCount || responseCount === 1) {
		if (options && options.length) {
			const mutuallyExclusiveOptions = options.find(
				(option) => option.mutually_exclusive_group_id
			);
			if (mutuallyExclusiveOptions) {
				const otherResponsefromSameGroup = mutuallyExclusiveOptionsRetreival(
					question,
					options,
					responseId
				);
				if (otherResponsefromSameGroup) {
					return responseAlreadySelected
						? {
								...question,
								response: question.response.filter(
									(response) => response !== otherResponsefromSameGroup
								),
						  }
						: {
								...question,
								response: [
									...question.response.filter(
										(response) => response !== otherResponsefromSameGroup
									),
									responseId,
								],
						  };
				}
				return {
					...question,
					response: question.response
						? [...question.response, responseId]
						: [responseId],
				};
			}
		}
		return responseAlreadySelected
			? {
					...question,
					response: [],
			  }
			: {
					...question,
					response: [responseId],
					customResponse: [],
			  };
	}

	return responseAlreadySelected
		? {
				...question,
				response: question.response.filter(
					(response) => response !== responseId
				),
		  }
		: {
				...question,
				response: question.response
					? [...question.response, responseId]
					: [responseId],
		  };
};

const updateCustomResponseInQuestion = (
	question,
	customResponseId,
	max_response_count
) => {
	const responseAlreadySelected =
		question.customResponse &&
		question.customResponse.includes(customResponseId);
	const responseCount = max_response_count
		? max_response_count
		: question.max_response_count;
	if (!responseCount || responseCount === 1) {
		return responseAlreadySelected
			? {
					...question,
					customResponse: [],
			  }
			: {
					...question,
					customResponse: [customResponseId],
					response: [],
			  };
	}

	return responseAlreadySelected
		? {
				...question,
				customResponse: question.customResponse.filter(
					(response) => response !== customResponseId
				),
		  }
		: {
				...question,
				customResponse: question.customResponse
					? [...question.customResponse, customResponseId]
					: [customResponseId],
		  };
};

const updatedCustomAndResponseInQuestion = (
	question,
	responseId,
	customResponseId,
	max_response_count,
	options
) => {
	const responseAlreadySelected =
		question.response && question.response.includes(responseId);
	const responseCount = max_response_count
		? max_response_count
		: question.max_response_count;
	if (!responseCount || responseCount === 1) {
		if (options && options.length) {
			const mutuallyExclusiveOptions = options.find(
				(option) => option.mutually_exclusive_group_id
			);
			if (mutuallyExclusiveOptions) {
				const otherResponsefromSameGroup = mutuallyExclusiveOptionsRetreival(
					question,
					options,
					responseId
				);
				if (otherResponsefromSameGroup) {
					return responseAlreadySelected
						? {
								...question,
								customResponse: [customResponseId],
								response: question.response.filter(
									(response) => response !== otherResponsefromSameGroup
								),
						  }
						: {
								...question,
								customResponse: [customResponseId],
								response: [
									...question.response.filter(
										(response) => response !== otherResponsefromSameGroup
									),
									responseId,
								],
						  };
				}
				return {
					...question,
					customResponse: [customResponseId],
					response: question.response
						? [...question.response, responseId]
						: [responseId],
				};
			}
		}
		return {
			...question,
			response: responseAlreadySelected ? [] : [responseId],
			customResponse: [customResponseId],
		};
	}

	return {
		...question,
		response: responseAlreadySelected
			? question.response.filter((response) => response !== responseId)
			: question.response
			? [...question.response, responseId]
			: [responseId],
		customResponse: [customResponseId],
	};
};

const mutuallyExclusiveOptionsRetreival = (question, options, responseId) => {
	const mutuallyExclusiveOptions = options.find(
		(option) => option.mutually_exclusive_group_id
	);
	if (mutuallyExclusiveOptions) {
		const currentResponseOption = options.find(
			(option) => option.value === responseId
		);
		if (currentResponseOption) {
			const currentResponseGroup =
				currentResponseOption.mutually_exclusive_group_id;
			return question.response && question.response.length
				? question.response.find((response) => {
						const responseOption = options.find(
							(option) => option.value === response
						);
						if (responseOption)
							return (
								responseOption.mutually_exclusive_group_id ===
								currentResponseGroup
							);
						return false;
				  })
				: false;
		}
	}

	return null;
};
