import "../../../../Stylesheets/css/scss/Components/Portal/Public/Notifications/NotificationTab.css";

import i18n from "i18next";
import React, {Component} from "react";

import {NotificationActions} from "../../../../Actions";
import NotificationIcon from "../../../../Assets/Caregiver/PrimaryNav/notifications_icon_white.svg";
import {
	AUTOMATION_TEST_ID,
	STYLED_ELEMENT,
} from "../../../../Scripts/Constants";
import {getUnreadNotificationCount} from "../../../../Scripts/Notifications";
import {
	NotificationDataModel,
	NotificationModel,
} from "../../../../Scripts/PublicInterfaces";
import {portalClassSelector} from "../../../../Scripts/Utilities";
import {NotificationList} from "../Notifications";

interface NotificationTabProps {
	currentRole: string;
	filters?: any;
	notifications: {
		notifications: NotificationModel<NotificationDataModel>[];
		showTab: boolean;
	};
	portal: string;
	student?: any;
}

interface NotificationTabState {
	hideNotificationsCounts: boolean;
}

export class NotificationTab extends Component<
	NotificationTabProps,
	NotificationTabState
> {
	private sideNav: HTMLDivElement;
	private bellIcon: HTMLButtonElement;

	constructor(props) {
		super(props);
		this.state = {
			hideNotificationsCounts: false,
		};
		this.sideNav = document.createElement("div");
		this.bellIcon = document.createElement("button");
	}

	componentDidMount = () => {
		document.addEventListener("mousedown", this.handleOutsideTabClick, false);
	};

	componentWillUnMount = () => {
		document.removeEventListener(
			"mousedown",
			this.handleOutsideTabClick,
			false
		);
	};

	toggleIcon = () => {
		this.setNotificationReadState();
		NotificationActions.hideShowNotificationTab(
			!this.props.notifications.showTab
		);

		this.setState({
			hideNotificationsCounts: true,
		});
	};

	setNotificationReadState = () => {
		this.props.notifications.notifications.forEach((notification) => {
			if (!notification.read) {
				NotificationActions.postNotification(notification.id);
			}
		});
	};

	handleOutsideTabClick = (e) => {
		const {showTab} = this.props.notifications;
		if (
			this.sideNav &&
			!this.sideNav.contains(e.target) &&
			!this.bellIcon.contains(e.target) &&
			showTab
		) {
			NotificationActions.hideShowNotificationTab(false);
		}
	};

	render() {
		const {portal, student, currentRole, notifications, filters} = this.props;
		const unreadMessageCount = getUnreadNotificationCount(
			notifications.notifications
		);
		const badgeSpanClassName =
			unreadMessageCount < 10 ? `unread-count-less-ten` : `unread-count`;
		const {hideNotificationsCounts} = this.state;
		const buttonClassname = portalClassSelector(portal, STYLED_ELEMENT.BUTTON);
		return (
			<div className="notification-tab">
				<button
					className={`btn ${buttonClassname} notification-btn`}
					type="button"
					onClick={this.toggleIcon}
					ref={(node) => (this.bellIcon = node as HTMLButtonElement)}
					id={AUTOMATION_TEST_ID.PUBLIC.BUTTONS.NOTIFICATION_BUTTON}
				>
					<img src={NotificationIcon} alt="notification icon" />
				</button>
				{unreadMessageCount > 0 && !hideNotificationsCounts && (
					<div className="badge text-white badge-pill">
						<span className={badgeSpanClassName}>
							{unreadMessageCount >= 100 ? "..." : unreadMessageCount}
						</span>
					</div>
				)}

				<div
					className={`notification-tab-menu ${
						notifications.showTab
							? `menu-side-show border-top-rounded`
							: `menu-side-hidden`
					}`}
					ref={(node) => (this.sideNav = node as HTMLDivElement)}
					id={AUTOMATION_TEST_ID.PUBLIC.COMPONENTS.NOTIFICATION_TAB_MENU}
				>
					<div className="notification-tab-menu-header">
						<p className="mb-0 text-center">{i18n.t("admin.notifications")}</p>
					</div>
					<div className="notification-tab-menu-body">
						<NotificationList
							notifications={notifications.notifications}
							student={student}
							currentRole={currentRole}
							filters={filters}
						/>
					</div>
					<div className="notification-tab-menu-footer" />
				</div>
			</div>
		);
	}
}
