import htmlToText from "html-to-text";
import i18n from "i18next";
import React, {Component, Fragment, lazy, Suspense} from "react";
import isEqual from "react-fast-compare";

import {ReactComponent as LeafOL} from "../../../../Assets/Caregiver/Trees/leaf.svg";
import {ReactComponent as LeafMath} from "../../../../Assets/Caregiver/Trees/math-leaf.svg";
import {getCurrentDomainItems} from "../../../../Scripts/Activities";
import {
	AUTOMATION_TEST_ID,
	BADGE_ID,
	DOMAIN_LEARNING_OUTCOME,
	FILTER_TAGS,
} from "../../../../Scripts/Constants";
import {getLocaleEpochDateString} from "../../../../Scripts/Date";
import {DOMAIN_MATH, DOMAIN_ORAL_LANGUAGE} from "../../../../Scripts/Domain";
import {ReportActivityTagStatsCounts} from "../../../../Scripts/Interfaces";
import {
	ActivityModel,
	EarnedBadgeModel,
	OutcomeTaxonomyItem,
} from "../../../../Scripts/PublicInterfaces";
import {debounce} from "../../../../Scripts/Utilities";
import {Sticker} from "../index";
import {Loader} from "../Loader";
import {TreeTop} from "./d3";

interface SprigTreeProps {
	activity: {
		tagging: {learning_outcomes: OutcomeTaxonomyItem[]};
		studentIdForCompletedActivities?: [];
		completedActivities: {outcome_id: number}[];
		rewards?: {earnedBadges: EarnedBadgeModel[]};
		activities?: ActivityModel[];
	};
	level: number;
	domain: number;
	treeOnly?: boolean;
	chartId?: number | string;
	reports?: {
		counts: ReportActivityTagStatsCounts[];
	}[];
	hideStickers?: boolean;
	selectedTreeOutcome?: number;
	onSelectOutcome?: (outcome: number) => void;
	isHolisticMath?: boolean;
}

interface SprigTreeState {
	allowLeavesAnimation?: boolean;
	leaves?: {x: number; y: number}[];
	chartData?: {
		id: number;
		key: string;
		value: number;
	}[];
	tree: string | null | undefined;
}

const Tree1 = lazy(() =>
	import("../Visualizations/Trees/OralLanguage/Tree1").then(({Tree1}) => ({
		default: Tree1,
	}))
);

const Tree2 = lazy(() =>
	import("../Visualizations/Trees/OralLanguage/Tree2").then(({Tree2}) => ({
		default: Tree2,
	}))
);

const Tree3 = lazy(() =>
	import("../Visualizations/Trees/OralLanguage/Tree3").then(({Tree3}) => ({
		default: Tree3,
	}))
);

const Tree4 = lazy(() =>
	import("../Visualizations/Trees/OralLanguage/Tree4").then(({Tree4}) => ({
		default: Tree4,
	}))
);

const Tree5 = lazy(() =>
	import("../Visualizations/Trees/OralLanguage/Tree5").then(({Tree5}) => ({
		default: Tree5,
	}))
);

const Tree6 = lazy(() =>
	import("../Visualizations/Trees/OralLanguage/Tree6").then(({Tree6}) => ({
		default: Tree6,
	}))
);

const Tree7 = lazy(() =>
	import("../Visualizations/Trees/OralLanguage/Tree7").then(({Tree7}) => ({
		default: Tree7,
	}))
);

const Tree8 = lazy(() =>
	import("../Visualizations/Trees/OralLanguage/Tree8").then(({Tree8}) => ({
		default: Tree8,
	}))
);

const Tree9 = lazy(() =>
	import("../Visualizations/Trees/OralLanguage/Tree9").then(({Tree9}) => ({
		default: Tree9,
	}))
);

const Tree10 = lazy(() =>
	import("../Visualizations/Trees/OralLanguage/Tree10").then(({Tree10}) => ({
		default: Tree10,
	}))
);

const Tree1Math = lazy(() =>
	import("../Visualizations/Trees/Math/Tree1").then(({Tree1Math}) => ({
		default: Tree1Math,
	}))
);

const Tree2Math = lazy(() =>
	import("../Visualizations/Trees/Math/Tree2").then(({Tree2Math}) => ({
		default: Tree2Math,
	}))
);

const Tree3Math = lazy(() =>
	import("../Visualizations/Trees/Math/Tree3").then(({Tree3Math}) => ({
		default: Tree3Math,
	}))
);

const Tree4Math = lazy(() =>
	import("../Visualizations/Trees/Math/Tree4").then(({Tree4Math}) => ({
		default: Tree4Math,
	}))
);

const Tree5Math = lazy(() =>
	import("../Visualizations/Trees/Math/Tree5").then(({Tree5Math}) => ({
		default: Tree5Math,
	}))
);

const Tree6Math = lazy(() =>
	import("../Visualizations/Trees/Math/Tree6").then(({Tree6Math}) => ({
		default: Tree6Math,
	}))
);

const Tree7Math = lazy(() =>
	import("../Visualizations/Trees/Math/Tree7").then(({Tree7Math}) => ({
		default: Tree7Math,
	}))
);

const Tree8Math = lazy(() =>
	import("../Visualizations/Trees/Math/Tree8").then(({Tree8Math}) => ({
		default: Tree8Math,
	}))
);

const Tree9Math = lazy(() =>
	import("../Visualizations/Trees/Math/Tree9").then(({Tree9Math}) => ({
		default: Tree9Math,
	}))
);

const Tree10Math = lazy(() =>
	import("../Visualizations/Trees/Math/Tree10").then(({Tree10Math}) => ({
		default: Tree10Math,
	}))
);

export class SprigTree extends Component<SprigTreeProps, SprigTreeState> {
	private node;
	private resizeHandler;
	private treeTopMinimum: number;
	constructor(props) {
		super(props);
		this.state = {
			allowLeavesAnimation: false,
			tree: null,
		};
		this.node = null;
		this.resizeHandler = debounce(this.onResize, 100);
		this.treeTopMinimum = 5;
	}

	componentDidMount = () => {
		if (this.selectTree()?._result?.name) {
			this.drawTreeTop(false);
			this.setState({tree: this.selectTree()?._result?.name});
		}
		window.addEventListener("resize", this.resizeHandler);
	};

	componentWillUnmount = () => {
		window.removeEventListener("resize", this.resizeHandler);
	};

	componentDidUpdate = (prevProps) => {
		const {level, domain, activity, selectedTreeOutcome} = this.props;
		if (
			(prevProps.domain && prevProps.domain !== domain) ||
			prevProps.level !== level ||
			(!prevProps.activity.tagging && activity.tagging) ||
			(prevProps.activity.studentIdForCompletedActivities ===
				activity.studentIdForCompletedActivities &&
				prevProps.activity.completedActivities &&
				!isEqual(
					prevProps.activity.completedActivities,
					activity.completedActivities
				))
		) {
			this.drawTreeTop(true);
		} else if (prevProps.selectedTreeOutcome !== selectedTreeOutcome) {
			const currentSelectedSlice =
				document.querySelector<HTMLElement>(".outcome-selected");
			if (currentSelectedSlice) {
				currentSelectedSlice.classList.remove("outcome-selected");
			}
			if (selectedTreeOutcome) {
				const a = document.querySelector<HTMLElement>(
					`.slice${selectedTreeOutcome}`
				);

				if (a) {
					a.classList.add("outcome-selected");
				}
			}
		}
		if (this.selectTree()?._result?.name && !this.state.tree) {
			this.drawTreeTop(false);
			this.setState({tree: this.selectTree()?._result?.name});
		}
	};

	onResize = () => {
		const {leaves, chartData} = this.state;
		if (leaves && leaves.length) {
			this.onChartRender(this.node, chartData);
		}
	};

	drawTreeTop = (updateHasOccurred) => {
		const {level} = this.props;
		const selectedTree = this.selectTree();
		if (selectedTree) {
			const chart = this.getChartOptions();
			if (chart) {
				if (level >= this.treeTopMinimum)
					this.setState({allowLeavesAnimation: updateHasOccurred}, () =>
						TreeTop.draw(this.node, chart)
					);
				else if (level) {
					if (this.state.leaves)
						this.setState({allowLeavesAnimation: false}, () => {
							TreeTop.runClean(null, this.node);
						});
				}
			}
		}
	};

	decodeAmpersand = (string) => {
		return string.replace(/&amp;/g, "&");
	};

	getElementPosition = (svg, target) => {
		let element = target;
		while (!element.getScreenCTM && element.parentNode)
			element = target.parentNode;
		const bbox = element.getBBox();
		const point = svg.createSVGPoint();
		point.x = bbox.x;
		point.y = bbox.y;
		const matrix = element.getCTM();
		const position = point.matrixTransform(matrix);
		position.transform = `rotate(${Math.floor(Math.random() * 360)}deg)`;
		return position;
	};

	onChartRender = (svg, data) => {
		const nodes = Array.from(document.querySelectorAll(".arc-center"));
		if (!this.props.treeOnly && nodes && nodes.length) {
			const allNodePositions = nodes.map((node) =>
				this.getElementPosition(svg, node)
			);
			this.setState({leaves: allNodePositions, chartData: data});
		}
	};

	getChartOptions = () => {
		const {activity, domain, onSelectOutcome, level, reports, chartId} =
			this.props;
		if (activity?.tagging) {
			if (activity.completedActivities) {
				const {completedActivities, tagging} = activity;
				const outcomes = tagging[FILTER_TAGS.LEARNING_OUTCOMES];
				if (outcomes?.length) {
					const domainSpecificOutcomes = getCurrentDomainItems(
						outcomes,
						domain
					);
					const data = domainSpecificOutcomes.map((outcome) => {
						return {
							id: outcome.id,
							key: this.decodeAmpersand(outcome.name),
							value: completedActivities.reduce((total, activity) => {
								if (activity.outcome_id === outcome.id) return total + 1;
								return total;
							}, 0),
						};
					});
					return {
						data,
						onSelectOutcome,
						callback: this.onChartRender,
						domain,
						level,
						id: `treeOutline-${chartId ? chartId : domain}`,
					};
				}
			} else if (reports?.length) {
				const {tagging} = activity;
				const outcomes = tagging[FILTER_TAGS.LEARNING_OUTCOMES];
				if (outcomes?.length) {
					const domainSpecificOutcomes = getCurrentDomainItems(
						outcomes,
						domain
					);
					const data = domainSpecificOutcomes.map((outcome) => {
						return {
							id: outcome.id,
							key: this.decodeAmpersand(outcome.name),
							value: reports.reduce((total, report) => {
								if (report.counts) {
									const outcomeReport = report.counts.find(
										(count) => count.tag_id === outcome.id
									);
									if (outcomeReport)
										return total + outcomeReport.total_this_term;
								}
								return total;
							}, 0),
						};
					});
					const dataValidation = data.find((datum) => datum.value);
					if (!dataValidation) {
						return {
							data: [
								{
									value: 1,
									id: "nodata",
									key: i18n.t("phrase.complete_activity_to_see_data"),
								},
							],
							callback: this.onChartRender,
							domain,
							level,
						};
					}
					return {
						data,
						callback: this.onChartRender,
						domain,
						level,
					};
				}
			}
		}
		return null;
	};

	renderSticker = (badge) => {
		switch (badge.id) {
			case BADGE_ID.BIRDS_OF_A_FEATHER:
				return <Sticker.Goose key={badge.id} tier={badge.tier} />;
			case BADGE_ID.HOME_TWEET_HOME:
				return (
					<Sticker.Nest
						key={badge.id}
						tier={badge.tier}
						level={this.props.level}
					/>
				);
			case BADGE_ID.HOP_TO_IT:
				return <Sticker.Rabbit key={badge.id} tier={badge.tier} />;
			case BADGE_ID.RISE_AND_SHINE:
				return <Sticker.Sun key={badge.id} tier={badge.tier} />;
			case BADGE_ID.BUSY_AS_A_BEE:
				return (
					<Sticker.Bee
						key={badge.id}
						tier={badge.tier}
						level={this.props.level}
					/>
				);
			case BADGE_ID.EVEN_STEVEN:
				return <Sticker.Butterfly key={badge.id} tier={badge.tier} />;
			case BADGE_ID.SQUIRRELED_AWAY:
				return <Sticker.Squirrel key={badge.id} tier={badge.tier} />;
			case BADGE_ID.UNBELEAFABLE:
				return <Sticker.Leafpile key={badge.id} tier={badge.tier} />;
			case BADGE_ID.CHITTER_CHATTER:
				return <Sticker.Chickadee key={badge.id} tier={badge.tier} />;
			default:
				return null;
		}
	};

	renderStickers = () => {
		const {activity, hideStickers} = this.props;
		if (activity?.rewards?.earnedBadges && !hideStickers) {
			const achievedStickers = {};
			activity.rewards.earnedBadges.forEach((badge) => {
				const achievedSticker = achievedStickers[badge.id];
				if (achievedSticker) {
					if (achievedSticker.tier < badge.tier)
						achievedStickers[badge.id] = badge;
				} else {
					achievedStickers[badge.id] = badge;
				}
			});
			const stickersToRender = Object.keys(achievedStickers).map((key) => {
				return achievedStickers[key];
			});
			return (
				<Fragment>
					{stickersToRender.map((badge) => this.renderSticker(badge))}
				</Fragment>
			);
		}
		return null;
	};

	selectTree = () => {
		const {domain, level} = this.props;
		if (level > 10) {
			if (domain === DOMAIN_ORAL_LANGUAGE) return Tree10;
			else if (domain === DOMAIN_MATH) return Tree10Math;
		}
		switch (domain) {
			case DOMAIN_ORAL_LANGUAGE:
				switch (level) {
					case 1:
						return Tree1;
					case 2:
						return Tree2;
					case 3:
						return Tree3;
					case 4:
						return Tree4;
					case 5:
						return Tree5;
					case 6:
						return Tree6;
					case 7:
						return Tree7;
					case 8:
						return Tree8;
					case 9:
						return Tree9;
					case 10:
						return Tree10;
					default:
						return null;
				}
			case DOMAIN_MATH:
				switch (level) {
					case 1:
						return Tree1Math;
					case 2:
						return Tree2Math;
					case 3:
						return Tree3Math;
					case 4:
						return Tree4Math;
					case 5:
						return Tree5Math;
					case 6:
						return Tree6Math;
					case 7:
						return Tree7Math;
					case 8:
						return Tree8Math;
					case 9:
						return Tree9Math;
					case 10:
						return Tree10Math;
					default:
						return null;
				}
			default:
				return null;
		}
	};

	showAllLeaves = () => {
		const allLeaves = document.querySelectorAll(".sprig-tree-leaf");
		if (allLeaves && allLeaves.length)
			Array.from(allLeaves).forEach(
				(leaf) => ((leaf as HTMLElement).style.opacity = "")
			);
	};

	hideAllLeaves = (event) => {
		const allLeaves = document.querySelectorAll(".sprig-tree-leaf");
		if (allLeaves && allLeaves.length) {
			Array.from(allLeaves).forEach((leaf) => {
				if (event?.currentTarget?.id === leaf.id && leaf?.classList?.remove)
					leaf.classList.remove("leaf-animation");
				if (leaf?.id !== event?.currentTarget?.id)
					(leaf as HTMLElement).style.opacity = "0";
			});
		}
	};

	getOutcome = (outcome) => {
		const currentOutcome =
			this.props.activity?.tagging?.learning_outcomes?.find(
				(outcomeItem) => outcomeItem.id === outcome
			);
		return currentOutcome ? currentOutcome : {slug: ""};
	};

	getActivityDetailsFromId = (completedActivity) => {
		const {activity} = this.props;
		if (activity?.activities?.length && completedActivity) {
			const foundActivity = activity.activities.find(
				(fullActivity) => fullActivity.id === completedActivity.activity_id
			);
			if (foundActivity) {
				return {
					...completedActivity,
					title: htmlToText.fromString(foundActivity?.title?.rendered),
				};
			}
		}

		return completedActivity;
	};

	renderLeaves = () => {
		const {leaves, chartData} = this.state;
		if (leaves?.length) {
			const {activity, domain} = this.props;
			if (activity?.completedActivities?.length && chartData?.length) {
				const {completedActivities} = activity;
				const reversedCompletedActivities = [...completedActivities].reverse();
				const lastCompletedActivityByOutcome = chartData
					.map((datum, index) => {
						return {
							activity: this.getActivityDetailsFromId(
								reversedCompletedActivities.find(
									(completedActivity) =>
										completedActivity.outcome_id === datum.id
								)
							),
							point: leaves[index],
						};
					})
					.filter((datum) => datum.activity);
				let leafSize: {width; height};
				const treeOutline = document.querySelector("#white_OL");
				if (treeOutline) {
					const treeRadius = treeOutline.getBoundingClientRect().width / 3;
					leafSize = {
						width: `${treeRadius}px`,
						height: `${treeRadius * 0.75}px`,
					};
				}
				const mostRecentCompletedActivityTime =
					lastCompletedActivityByOutcome?.length
						? Math.max(
								...lastCompletedActivityByOutcome.map(
									(lastCompletedActivity) =>
										lastCompletedActivity.activity.completed_on[
											lastCompletedActivity.activity.completed_on.length - 1
										]
								)
						  )
						: null;

				return lastCompletedActivityByOutcome.map((datum, index) => (
					<div
						key={`completed_activity_${datum.activity?.outcome_id}_${
							datum.activity?.completed_on[
								datum.activity.completed_on.length - 1
							]
						}`}
						id={`sprig-tree-leaf${index}`}
						className={`sprig-tree-leaf${
							datum?.activity?.completed_on[
								datum?.activity?.completed_on.length - 1
							] === mostRecentCompletedActivityTime &&
							this.state.allowLeavesAnimation
								? " leaf-animation"
								: ""
						}`}
						style={{
							top: datum.point.y,
							left: datum.point.x,
							width: leafSize.width,
							height: leafSize.height,
						}}
						onMouseOver={this.hideAllLeaves}
						onMouseOut={this.showAllLeaves}
					>
						<div className="sprig-tree-leaf-tooltip">
							<div className="sprig-tree-leaf-animation">
								{domain === DOMAIN_MATH ? <LeafMath /> : <LeafOL />}
							</div>
							<div className="sprig-leaf-tooltip">
								{i18n.t(
									datum.activity.title
										? "rewards.last_completed_activity_with_title"
										: "rewards.last_completed_activity",
									{
										date: getLocaleEpochDateString(
											datum.activity.completed_on[
												datum.activity.completed_on.length - 1
											]
										),
										activity: i18n.t(
											`domain.${DOMAIN_LEARNING_OUTCOME[domain]}.${
												this.getOutcome(datum.activity.outcome_id)?.slug
											}`
										),
										activityTitle: datum.activity.title,
									}
								)}
								<div className="tooltip-arrow" />
							</div>
						</div>
					</div>
				));
			}
		}
		return null;
	};

	renderContent = (TreeComponent) => (
		<Suspense fallback={<Loader.Spinner />}>
			<TreeComponent isHolisticMath={this.props?.isHolisticMath}>
				{this.renderStickers()}
			</TreeComponent>
		</Suspense>
	);

	render() {
		const selectedTree = this.selectTree();
		return selectedTree ? (
			<Suspense fallback={<Loader.Spinner />}>
				<Fragment>
					<svg
						className="sprig-tree"
						viewBox="0 0 1020 1360"
						ref={(node) => (this.node = node)}
						id={AUTOMATION_TEST_ID.PUBLIC.COMPONENTS.SPRIG_TREE}
					>
						{this.renderContent(selectedTree)}
					</svg>
					{this.renderLeaves()}
				</Fragment>
			</Suspense>
		) : null;
	}
}
