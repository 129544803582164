import i18n from "i18next";
import React from "react";

import {AssetManager} from "../../../../../../../Scripts/AssetManager";
import {Question} from "../../../../../../../Scripts/Interfaces/Assessments";
import {Icon} from "../../../../../../Public";

interface WhatAndWhereTabProps {
	page: number;
	questions: MathWhatWhereWhoQuestion[];
}

export interface MathWhatWhereWhoQuestion {
	question: Question;
	id: number;
	question_id: number;
	values: string[];
}

export const WhatAndWhereTab = ({page, questions}: WhatAndWhereTabProps) => {
	const title =
		page === 1
			? i18n.t("assessments.math.questions.what_activities")
			: i18n.t("assessments.math.questions.where_do");
	const activities =
		page === 1
			? questions.filter(
					(question) =>
						question.question.id >= 12010 && question.question.id <= 12017
			  )
			: questions.filter(
					(question) =>
						question.question.id >= 12020 && question.question.id <= 12022
			  );
	return (
		<div className="what-where-tab">
			<h5>{title}</h5>
			<table className="table table-responsive-sm border-light-green">
				<thead>
					<tr>
						<th className="border-top-0" scope="col">
							{i18n.t("assessments.math.questions.how_often")}
						</th>
						<th className="border-top-0 text-center pb-2" scope="col">
							{i18n.t("assessments.math.questions.value.a_little")}
						</th>
						<th
							className="storytelling-th border-top-0 text-center pb-2 nun-semibold"
							scope="col"
						>
							{i18n.t("assessments.math.questions.value.sometimes")}
						</th>
						<th
							className="storytelling-th border-top-0 text-center pb-2 nun-semibold"
							scope="col"
						>
							{i18n.t("assessments.math.questions.value.a_lot")}
						</th>
					</tr>
				</thead>
				<tbody>
					{activities &&
						activities.map((activity) => {
							return <ActivityListItem key={activity.id} activity={activity} />;
						})}
				</tbody>
			</table>
		</div>
	);
};

interface ActivityListItemProps {
	activity: QuestionActivity;
}

interface QuestionActivity {
	question_id: number;
	question: Question;
	values: string[];
}

const ActivityListItem = ({activity}: ActivityListItemProps) => {
	const columns = [
		{value: "1", id: 1},
		{value: "2", id: 2},
		{value: "3", id: 3},
	];
	const assets = AssetManager.ASSETS;
	const currentImageSrc =
		assets.ASSESSMENT.MATH.WHATANDWHERE[activity.question_id];
	return (
		<tr>
			<th scope="row" className="flex align-center">
				<object
					type="image/svg+xml"
					data={currentImageSrc}
					style={{height: "60px", maxWidth: "120px"}}
				>
					{activity.question.minor_text}
				</object>
				{activity.question.minor_text}
			</th>
			{columns.map((column) => (
				<td className="nun-semibold" key={column.id}>
					{activity.values[0] === column.value ? (
						<Icon type="faCheckCircle" size="2x" className="text-primary" />
					) : null}
				</td>
			))}
		</tr>
	);
};
