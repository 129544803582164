import "../../../../Stylesheets/css/scss/Components/Portal/Public/Dropdown/CheckboxDropdown.css";

import i18n from "i18next";
import React, {Component} from "react";

import {CheckboxDropdownItem} from "./CheckboxDropdownItem";

interface CheckboxDropdownProps {
	id: number;
	items: [];
	onChange: (id: string, filter: number[]) => void;
	reset: boolean;
}

interface CheckboxDropdownState {
	items: CheckboxItem[];
	allSelectedItemsIds: number[];
	menuOpen: boolean;
}

export interface CheckboxItem {
	id: number;
	label: string;
}

export class CheckboxDropdown extends Component<
	CheckboxDropdownProps,
	CheckboxDropdownState
> {
	ALL_ID: number;
	ALL_ITEM: CheckboxItem;

	constructor(props: CheckboxDropdownProps) {
		super(props);
		this.ALL_ID = 0;
		this.ALL_ITEM = {
			id: this.ALL_ID,
			label: i18n.t("common.all"),
		};

		this.state = {
			items: [this.ALL_ITEM, ...props.items],
			allSelectedItemsIds: [],
			menuOpen: false,
		};
	}

	componentDidUpdate = (prevProps: CheckboxDropdownProps) => {
		if (!prevProps.reset && this.props.reset) {
			this.setState({allSelectedItemsIds: []});
		}
	};

	isAllItemsSelected = () =>
		this.state.allSelectedItemsIds.length === 0 ||
		this.state.allSelectedItemsIds.length === this.props.items.length;

	onChange = (item: CheckboxItem) => {
		const {allSelectedItemsIds} = this.state;
		this.setState(
			{
				menuOpen: false,
				allSelectedItemsIds: !allSelectedItemsIds.includes(item.id)
					? [...allSelectedItemsIds, item.id]
					: allSelectedItemsIds.filter((id: string | number) => id !== item.id),
			},
			() => {
				this.props.onChange(
					this.props.id.toString(),
					this.state.allSelectedItemsIds
				);
			}
		);
	};

	getDropdownLabel = () => {
		const {items, allSelectedItemsIds} = this.state;
		if (this.isAllItemsSelected()) {
			return this.ALL_ITEM.label;
		} else {
			const selectedItems = items.filter((item) =>
				allSelectedItemsIds.includes(item.id)
			);
			return selectedItems.map((item) => item.label).join(", ");
		}
	};

	toggleMenu = () => {
		this.setState({menuOpen: !this.state.menuOpen});
	};

	render() {
		const {items, allSelectedItemsIds, menuOpen} = this.state;
		return items?.length ? (
			<div className="component-checkbox_dropdown dropdown">
				<button
					className="btn btn-dropdown dropdown-toggle btn-dropdown-w-fixed flex"
					type="button"
					id={`dropdown_${this.props.id}`}
					onClick={this.toggleMenu}
				>
					<div className="ws-nowrap w-100">
						<span title={this.getDropdownLabel()} style={{padding: "0 5px"}}>
							{this.getDropdownLabel()}
						</span>
					</div>
				</button>
				{menuOpen ? (
					<div className="dropdown-menu line-height-def hide-overflow w-100 show">
						{items.map((item, index) => (
							<CheckboxDropdownItem
								key={`dropdown_item${index}`}
								isMasterItem={index === 0}
								isMasterChecked={this.isAllItemsSelected()}
								checked={allSelectedItemsIds.includes(item.id)}
								item={item}
								onChange={this.onChange}
							/>
						))}
					</div>
				) : null}
			</div>
		) : null;
	}
}
