import * as ActionTypes from "../Redux/ActionTypes";

const initialState = {
	queue: [],
};

export const LoaderReducer = (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.LOADER_QUEUE_ADD:
			return {
				...state,
				queue: [...state.queue, action.payload],
			};
		default:
			const {queue} = state;
			if (queue.length && queue.includes(action.type)) {
				return {
					...state,
					queue: queue.filter((actionId) => actionId !== action.type),
				};
			}

			return state;
	}
};
