import React, {Fragment, useState} from "react";

import {Icon} from "../../../../../../Public";
import {TabPropForImage} from ".";

export const MathActivityImage = ({tab}: {tab: TabPropForImage}) => {
	const [imagePageOne, setImagePageOne] = useState(true);

	const toggleImage = () => {
		setImagePageOne(!imagePageOne);
	};

	return (
		<Fragment>
			<img
				className="math-activity-image"
				src={tab.img[!imagePageOne && tab.img.length > 1 ? 1 : 0]}
				alt={tab.imgCaption[!imagePageOne && tab.imgCaption.length > 1 ? 1 : 0]}
			/>
			<div className="math-activity-image-caption p-3">
				<span className="caption">
					{tab.imgCaption[!imagePageOne && tab.imgCaption.length > 1 ? 1 : 0]}
				</span>
				{tab.audio !== null && (
					<span className="math-audio-container">
						<audio controls src={tab.audio} className="math-audio">
							<source src={tab.audio} type="audio/mp4" />
						</audio>
					</span>
				)}
				{(tab.img.length > 1 || tab.imgCaption.length > 1) && (
					<button
						className="no-default-style caption-icon"
						onClick={toggleImage}
					>
						<Icon
							type={imagePageOne ? "faAngleRight" : "faAngleLeft"}
							className="text-primary"
						/>
					</button>
				)}
			</div>
		</Fragment>
	);
};
