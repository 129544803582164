import "../../../../../Stylesheets/css/scss/Components/Portal/Public/Surveys/Pages/SurveyEnd.css";

import i18n from "i18next";
import React, {Fragment} from "react";

import {AUTOMATION_TEST_ID} from "../../../../../Scripts/Constants";
import {ErrorReporter, Loader} from "../../index";

interface SurveyEndProps {
	content: string;
	onSubmit?: () => void;
	actionTypesForLoader: string[];
}

export const SurveyEnd = ({
	content,
	onSubmit,
	actionTypesForLoader,
}: SurveyEndProps) => (
	<div className="survey-end">
		<div className="survey-end-content">
			<div className="survey-end-text">
				{content.split("/n/n").map((splitContent) => (
					<div className="survey-split-content" key={splitContent}>
						{splitContent}
					</div>
				))}
			</div>
			<ErrorReporter actionTypes={actionTypesForLoader}>
				<SurveyError />
				<Fragment />
			</ErrorReporter>
			<Loader.Wrap
				actionTypes={actionTypesForLoader ? actionTypesForLoader : []}
			>
				<Loader.HideContent>
					<button
						className="btn btn-primary"
						disabled={onSubmit ? false : true}
						onClick={onSubmit}
						id={AUTOMATION_TEST_ID.CAREGIVER.SURVEY.SUBMIT_BUTTON}
					>
						{i18n.t("survey.submit_survey")}
					</button>
				</Loader.HideContent>
				<Loader.Spinner />
			</Loader.Wrap>
		</div>
	</div>
);

interface SurveyErrorProps {
	error?: string;
}

const SurveyError = ({error}: SurveyErrorProps) => (
	<div className="survey-submit-error">{error}</div>
);
