import React from "react";
import ReactModal from "react-modal";
import {connect} from "react-redux";

import {ModalActions} from "../../../../Actions";
import {PORTALS, ROLES} from "../../../../Scripts/Constants";
import {ReducerSchool, Tagging} from "../../../../Scripts/PublicInterfaces";
import {modalTypes} from "./Modals";

interface ModalRootProps extends ModalRootPropsFromReducer {
	queue: ModalData[];
}

class ModalRoot extends React.Component<ModalRootProps> {
	getModalSize = (size) => {
		switch (size) {
			case "sm":
				return "modal-size-sm";
			case "sm-md":
				return "modal-size-sm-md";
			case "md":
				return "modal-size-md";
			case "lg":
				return "modal-size-lg";
			default:
				return "modal-size-lg";
		}
	};

	closeModal = () => {
		const {queue} = this.props;
		if (queue[0]) {
			const {id} = queue[0];
			ModalActions.hideModal(id);
		}
	};

	setPortalClass = (role) => {
		switch (role) {
			case PORTALS.EDUCATOR:
			case ROLES.TEACHER:
				return "educator-portal-modal";
			case ROLES.ADMIN:
			case PORTALS.ADMIN:
				return "admin-portal-modal";
			case ROLES.CAREGIVER:
			case PORTALS.CAREGIVER:
				return "caregiver-portal-modal";
			case PORTALS.EDITOR:
				return "editor-portal-modal";
			default:
				throw new Error(`Unsupported role type: ${role}`);
		}
	};

	render() {
		const {queue} = this.props;
		if (queue.length === 0) return null;
		const {modalType, modalProps} = queue[0];
		if (!modalType || !modalProps) return null;
		const SpecifiedModal: React.ElementType = modalTypes[modalType];
		const blockOverlayClick = modalProps?.blockCloseOnOverlayClick
			? false
			: true;
		return (
			<div className="modal-root">
				<ReactModal
					isOpen={modalProps.open}
					onRequestClose={this.closeModal}
					ariaHideApp={false}
					className={`content-wrapper global-modal ${this.getModalSize(
						modalProps.size
					)} ${this.setPortalClass(modalProps.role)}${
						modalProps.fullSizedOnSmallScreen ? " full-size" : ""
					}`}
					overlayClassName={`global-modal-overlay overlay ${
						modalProps.keepInWindow ? "global-modal-in-window" : ""
					}`}
					shouldCloseOnOverlayClick={blockOverlayClick}
				>
					<SpecifiedModal
						closeModal={this.closeModal}
						taxonomies={this.props.taxonomies}
						currentRole={this.props.currentRole}
						school={this.props.school}
						user={this.props.user}
						loader={this.props.loader}
						errorReporter={this.props.errorReporter}
						{...this.props.queue[0].modalProps}
					/>
				</ReactModal>
			</div>
		);
	}
}

interface ModalData {
	modalType?: string;
	modalProps?: {
		blockCloseOnOverlayClick: boolean;
		open: boolean;
		role: string;
		size: string;
		keepInWindow: boolean;
		fullSizedOnSmallScreen?: boolean;
	};
	id: number;
}

interface ModalRootReducer {
	modal: {
		queue: ModalData[];
	};
	user: {};
	activity: {
		grade: [];
		places: [];
		tagging: Tagging;
		competencies: [];
		domain: number;
		duration: [];
		curriculum: [];
	};
	appContent: {currentRole: string};
	school: ReducerSchool;
	loader: {};
	errorReporter: {};
}

interface ModalRootPropsFromReducer {
	user: {};
	taxonomies: {};
	currentRole: string;
	school: ReducerSchool;
	loader: {};
	errorReporter: {};
}

const mapStateToProps = (state: ModalRootReducer) => {
	return {
		...state.modal,
		user: state.user,
		taxonomies: {
			grade: state.activity.grade,
			places: state.activity.places,
			generalOutcomes: state.activity.tagging
				? state.activity.tagging.general_outcomes
				: [],
			specificOutcomes: state.activity.tagging
				? state.activity.tagging.specific_outcomes
				: [],
			competencies: state.activity.competencies,
			domain: state.activity.domain,
			duration: state.activity.duration,
			curriculum: state.activity.curriculum,
			tagging: state.activity.tagging,
			learningOutcomes: state.activity.tagging
				? state.activity.tagging.learning_outcomes
				: [],
		},
		currentRole: state.appContent.currentRole,
		school: {...state.school},
		loader: {...state.loader},
		errorReporter: {...state.errorReporter},
	};
};

export default connect(mapStateToProps)(ModalRoot);
