import * as d3 from "d3";

export const getChartConfiguration = (configuration) => {
	const {margin} = configuration;
	return {
		...configuration,
		margin: margin ? margin : {top: 20, left: 20, bottom: 20, right: 20},
	};
};

export const sortThroughArrayOfElementsAndGetGreatestDimensions = (
	elements
) => {
	if (elements.length) {
		const elementDimensions = elements
			.filter((element) => element !== undefined)
			.map((element) => element.getBBox());
		return {
			width: d3.max(elementDimensions, (element) => element.width),
			height: d3.max(elementDimensions, (element) => element.height),
		};
	}
};

export const createAxisFromOptions = (options, data) => {
	switch (options.scale) {
		case "linear":
			return createLinearScaleWithDomain(data);
		case "ordinal":
			return createOrdinalScaleWithDomain(data);
		default:
			return null;
	}
};

const createLinearScaleWithDomain = (data) => {
	return d3.scaleLinear().domain([d3.min(data), d3.max(data)]);
};

const createOrdinalScaleWithDomain = (data) => {
	return d3.scalePoint().domain(data);
};
