import i18n from "i18next";
import React, {Component} from "react";

import {
	CHART_TYPE,
	MATH_ASSESSMENT_TASK,
	REPORT_CHART_TYPE,
} from "../../../../Scripts/Constants";
import {DOMAIN_MATH} from "../../../../Scripts/Domain";
import {ReportRubric} from "../../../../Scripts/Interfaces";
import {ChartHeader, ReportAlertNoData, ReportChart} from "..";

interface RubricLevelProps {
	domain?: number;
	report: ReportRubric;
}

export class RubricLevel extends Component<RubricLevelProps> {
	parseData = () => {
		const {report, domain} = this.props;

		const {questions, assessed_level_count, breakdown} = report;
		const totalStudents = assessed_level_count.reduce((a, b) => a + b, 0);
		let level1: number[] = [];
		let level2: number[] = [];
		let level3: number[] = [];
		let level4: number[] = [];
		if (domain === DOMAIN_MATH) {
			const mathTasks = [
				MATH_ASSESSMENT_TASK.COMMUNICATION,
				MATH_ASSESSMENT_TASK.MAKING_CONNECTIONS,
				MATH_ASSESSMENT_TASK.MENTAL_MATH_AND_ESTIMAT,
				MATH_ASSESSMENT_TASK.PROBLEM_SOLVING,
				MATH_ASSESSMENT_TASK.REASONING,
				MATH_ASSESSMENT_TASK.VISUALIZATION,
			];
			mathTasks.forEach((task) => {
				level1.push(breakdown[task]?.[0]);
			});
			level1.push(assessed_level_count[0]);
			level1 = level1.map((item) =>
				parseFloat(((item / totalStudents) * 100).toFixed(2))
			);

			mathTasks.forEach((task) => {
				level2.push(breakdown[task]?.[1]);
			});
			level2.push(assessed_level_count[1]);
			level2 = level2.map((item) =>
				parseFloat(((item / totalStudents) * 100).toFixed(2))
			);

			mathTasks.forEach((task) => {
				level3.push(breakdown[task]?.[2]);
			});
			level3.push(assessed_level_count[2]);
			level3 = level3.map((item) =>
				parseFloat(((item / totalStudents) * 100).toFixed(2))
			);

			mathTasks.forEach((task) => {
				level4.push(breakdown[task]?.[3]);
			});
			level4.push(assessed_level_count[3]);
			level4 = level4.map((item) =>
				parseFloat(((item / totalStudents) * 100).toFixed(2))
			);
		} else {
			Object.keys(questions).forEach((questionID) => {
				level1.push(questions[questionID][0]);
			});
			level1.push(assessed_level_count[0]);
			level1 = level1.map((item) =>
				parseFloat(((item / totalStudents) * 100).toFixed(2))
			);

			Object.keys(questions).forEach((questionID) => {
				level2.push(questions[questionID][1]);
			});
			level2.push(assessed_level_count[1]);
			level2 = level2.map((item) =>
				parseFloat(((item / totalStudents) * 100).toFixed(2))
			);

			Object.keys(questions).forEach((questionID) => {
				level3.push(questions[questionID][2]);
			});
			level3.push(assessed_level_count[2]);
			level3 = level3.map((item) =>
				parseFloat(((item / totalStudents) * 100).toFixed(2))
			);

			Object.keys(questions).forEach((questionID) => {
				level4.push(questions[questionID][3]);
			});
			level4.push(assessed_level_count[3]);
			level4 = level4.map((item) =>
				parseFloat(((item / totalStudents) * 100).toFixed(2))
			);
		}
		const oralLanguageLabels = [
			i18n.t("admin.clarity"),
			i18n.t("common.structure"),
			i18n.t("common.story"),
			i18n.t("common.overall"),
		];
		const mathLabelsPrefix = `assessments.math.activities.process.`;
		const mathLabels = [
			i18n.t(`${mathLabelsPrefix}communication-math.title`),
			i18n.t(`${mathLabelsPrefix}connections.title`),
			i18n.t(`${mathLabelsPrefix}mental-math-and-estimation.title`),
			i18n.t(`${mathLabelsPrefix}problem-solving.title`),
			i18n.t(`${mathLabelsPrefix}reasoning.title`),
			i18n.t(`${mathLabelsPrefix}visualization.title`),
			i18n.t("common.overall"),
		];
		const labels = domain === DOMAIN_MATH ? mathLabels : oralLanguageLabels;
		const data = {
			labels,
			datasets: [
				{
					label: i18n.t("common.level_x", {level: 1}),
					backgroundColor: "#B3E2E1",
					borderColor: "white",
					borderWidth: 1,
					hoverBackgroundColor: "#F88D25",
					hoverBorderColor: "white",
					data: level1,
				},
				{
					label: i18n.t("common.level_x", {level: 2}),
					backgroundColor: "#8FD3D2",
					borderColor: "white",
					borderWidth: 1,
					hoverBackgroundColor: "#F88D25",
					hoverBorderColor: "white",
					data: level2,
				},
				{
					label: i18n.t("common.level_x", {level: 3}),
					backgroundColor: "#6BC4C3",
					borderColor: "white",
					borderWidth: 1,
					hoverBackgroundColor: "#F88D25",
					hoverBorderColor: "white",
					data: level3,
				},
				{
					label: i18n.t("common.level_x", {level: 4}),
					backgroundColor: "#4BB5B4",
					borderColor: "white",
					borderWidth: 1,
					hoverBackgroundColor: "#F88D25",
					hoverBorderColor: "white",
					data: level4,
				},
			],
		};
		return data;
	};

	render() {
		const {report} = this.props;
		const chartTitle =
			this.props.domain === DOMAIN_MATH
				? i18n.t("chart.assessment_rubric_levels")
				: i18n.t("assessments.oral_language.storytelling.rubric.title");
		return (
			<div className="mt-2">
				<ChartHeader title={chartTitle} />
				<div className="text-right mb-2 mt-5">
					{report && Object.keys(report).length !== 0 && (
						<ReportChart
							chartType={CHART_TYPE.BAR}
							data={this.parseData()}
							type={REPORT_CHART_TYPE.RUBRIC_LEVEL}
						/>
					)}
					{(!report || Object.keys(report).length === 0) && (
						<ReportAlertNoData />
					)}
				</div>
			</div>
		);
	}
}
