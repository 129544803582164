import {EducatorMainPageDescriptionSteps} from "./Educator";
import {
	ReadingMainPageDescriptionSteps,
	ReadingMainPageDescriptionStepsMK,
} from "./Reading";

export * from "./Educator";
export * from "./PortalTourTooltip";
export * from "./Widgets";

export const TOUR_ID = {
	EDUCATOR: {
		MAIN_PAGE_DESCRIPTION: "PORTAL_TOUR_MAIN_PAGE_DESCRIPTION",
	},
	READING: {
		MAIN_PAGE_DESCRIPTION: "READING_MAIN_PAGE_DESCRIPTION",
		MAIN_PAGE_DESCRIPTION_MK: "READING_MAIN_PAGE_DESCRIPTION_MK",
	},
};

export const getTourStepComponentBasedOnId = (id: string, step: number) => {
	switch (id) {
		case TOUR_ID.EDUCATOR.MAIN_PAGE_DESCRIPTION:
			return EducatorMainPageDescriptionSteps[step - 1];
		case TOUR_ID.READING.MAIN_PAGE_DESCRIPTION:
			return ReadingMainPageDescriptionSteps[step - 1];
		case TOUR_ID.READING.MAIN_PAGE_DESCRIPTION_MK:
			return ReadingMainPageDescriptionStepsMK[step - 1];
		default:
			return null;
	}
};
