import "./icon.scss";

import {IconName, IconProp} from "@fortawesome/fontawesome-svg-core";
import {faCircleXmark} from "@fortawesome/free-regular-svg-icons";
import {faCircle as faRegularCircle} from "@fortawesome/free-regular-svg-icons";
import {faHeart as faRegularHeart} from "@fortawesome/free-regular-svg-icons";
import {faCalendarDays} from "@fortawesome/free-regular-svg-icons";
import {
	faAngleLeft,
	faAngleRight,
	faArrowLeft,
	faAsterisk,
	faBackward,
	faBan,
	faBars,
	faBold,
	faBook,
	faBoxArchive,
	faBullhorn,
	faCalendar,
	faCamera,
	faCaretDown,
	faCaretUp,
	faCertificate,
	faChalkboardTeacher,
	faChalkboardUser,
	faChartArea,
	faChartBar,
	faCheck,
	faCheckCircle,
	faCheckSquare,
	faChevronCircleLeft,
	faChevronCircleRight,
	faChevronDown,
	faChevronLeft,
	faChevronRight,
	faChevronUp,
	faCircle,
	faCircleArrowLeft,
	faCircleInfo,
	faCircleNotch,
	faCirclePlay,
	faClipboardList,
	faCloudUploadAlt,
	faCode,
	faCoins,
	faCommentAlt,
	faCopy,
	faDownload,
	faEdit,
	faEllipsisH,
	faExclamationCircle,
	faEye,
	faEyeSlash,
	faFilePdf,
	faFilter,
	faFlag,
	faGlobe,
	faGraduationCap,
	faHashtag,
	faHeart,
	faHome,
	faInfoCircle,
	faItalic,
	faKey,
	faLeaf,
	faLink,
	faListAlt,
	faListOl,
	faListUl,
	faLock,
	faMessage,
	faMinus,
	faPen,
	faPencilAlt,
	faPenToSquare,
	faPlus,
	faPoll,
	faPrint,
	faQuoteLeft,
	faSadTear,
	faSchool,
	faSearch,
	faSignOutAlt,
	faSpinner,
	faSquare,
	faStar,
	faTabletAlt,
	faTh,
	faThList,
	faThumbsDown,
	faThumbsUp,
	faTimes,
	faTimesCircle,
	faTrashAlt,
	faUnderline,
	faUpload,
	faUser,
	faUserCircle,
	faUserCog,
	faUserEdit,
	faUserGraduate,
	faUserPlus,
	faUsers,
	faVideo,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

import {ReactComponent as SprigMathCharacter} from "../../../Assets/Caregiver/Domains/aliet.svg";
import {ReactComponent as SprigOralLanguageCharacter} from "../../../Assets/Caregiver/Domains/antle.svg";
import {ReactComponent as SprigLeaf} from "../../../Assets/Caregiver/PrimaryNav/activities_icon.svg";
import {ReactComponent as SprigTree} from "../../../Assets/Caregiver/PrimaryNav/home_icon.svg";
import {ReactComponent as SprigLogo} from "../../../Assets/Caregiver/PrimaryNav/logo.svg";
import {ReactComponent as SprigNotification} from "../../../Assets/Caregiver/PrimaryNav/notifications_icon_white.svg";
import {ReactComponent as SprigDashboard} from "../../../Assets/Educator/dashboard.svg";
import {ReactComponent as SprigStudent} from "../../../Assets/Educator/students.svg";
import {ReactComponent as HMAssessable} from "../../../Assets/HolisticMathPrototype/assessable.svg";
import {ReactComponent as Bloom} from "../../../Assets/HolisticMathPrototype/Bloom.svg";
import {ReactComponent as HMCirclePassword} from "../../../Assets/HolisticMathPrototype/circlePassword.svg";
import {ReactComponent as HMDiamondPassword} from "../../../Assets/HolisticMathPrototype/diamondPassword.svg";
import {ReactComponent as Flower} from "../../../Assets/HolisticMathPrototype/Flower.svg";
import {ReactComponent as HMAll} from "../../../Assets/HolisticMathPrototype/hm_assessment_activity_legend_all_of.svg";
import {ReactComponent as HMMost} from "../../../Assets/HolisticMathPrototype/hm_assessment_activity_legend_most.svg";
import {ReactComponent as HMNotYet} from "../../../Assets/HolisticMathPrototype/hm_assessment_activity_legend_not_yet.svg";
import {ReactComponent as HMRarely} from "../../../Assets/HolisticMathPrototype/hm_assessment_activity_legend_rarely.svg";
import {ReactComponent as HMSome} from "../../../Assets/HolisticMathPrototype/hm_assessment_activity_legend_some.svg";
import {ReactComponent as Seed} from "../../../Assets/HolisticMathPrototype/Seed.svg";
import {ReactComponent as Sprout} from "../../../Assets/HolisticMathPrototype/Sprout.svg";
import {ReactComponent as HMSquarePassword} from "../../../Assets/HolisticMathPrototype/squarePassword.svg";
import {ReactComponent as Star} from "../../../Assets/HolisticMathPrototype/star.svg";
import {ReactComponent as StarEarned} from "../../../Assets/HolisticMathPrototype/starEarned.svg";
import {ReactComponent as HMTrianglePassword} from "../../../Assets/HolisticMathPrototype/trianglePassword.svg";
import {ReactComponent as SprigReadingCircle} from "../../../Assets/Reading/circleChartIcon.svg";
import {ReactComponent as JLComprehension} from "../../../Assets/Reading/JLComprehension.svg";
import {ReactComponent as JLFluency} from "../../../Assets/Reading/JLFluency.svg";
import {ReactComponent as JLOralLanguage} from "../../../Assets/Reading/JLOralLanguage.svg";
import {ReactComponent as JLPhonics} from "../../../Assets/Reading/JLPhonics.svg";
import {ReactComponent as JLPhonologicalAwareness} from "../../../Assets/Reading/JLPhonologicalAwareness.svg";
import {ReactComponent as JLVocabulary} from "../../../Assets/Reading/JLVocabulary.svg";
import {ReactComponent as NeedsExplicit} from "../../../Assets/Reading/NeedsExplicit.svg";
import {ReactComponent as NeedsMore} from "../../../Assets/Reading/NeedsMorePractice.svg";
import {ReactComponent as NotYetAssessed} from "../../../Assets/Reading/NotYetAssessed.svg";
import {ReactComponent as SkillMastered} from "../../../Assets/Reading/SkillMastered.svg";
import {STYLE_CONSTANT} from "../../../Scripts/StyleConstants";

export type FontAwesomeIconSize =
	| "xs"
	| "lg"
	| "sm"
	| "1x"
	| "2x"
	| "3x"
	| "4x"
	| "5x"
	| "6x"
	| "7x"
	| "8x"
	| "9x"
	| "10x";

type FontAwesomeIconType =
	| "faBackward"
	| "faBars"
	| "faBullhorn"
	| "faCaretDown"
	| "faCaretUp"
	| "faCertificate"
	| "faChartBar"
	| "faCheck"
	| "faChevronDown"
	| "faChevronUp"
	| "faClipboardList"
	| "faDownload"
	| "faFilter"
	| "faInfoCircle"
	| "faLeaf"
	| "faPoll"
	| "faSignOutAlt"
	| "faSpinner"
	| "faTimes"
	| "faUserCircle"
	| "faUserGraduate"
	| "faSearch"
	| "faTimesCircle"
	| "faChevronCircleRight"
	| "faListAlt"
	| "faHashTag"
	| "faHeart"
	| "faCopy"
	| "faCommentAlt"
	| "faEyeSlash"
	| "faThumbsUp"
	| "faThumbsDown"
	| "faEye"
	| "faTh"
	| "faThList"
	| "faEllipsisH"
	| "faChartArea"
	| "faChevronLeft"
	| "faUsers"
	| "faHome"
	| "faEdit"
	| "faCircle"
	| "faCheckCircle"
	| "faChevronRight"
	| "faPenToSquare"
	| "faChalkboardUser"
	| "faChalkboardTeacher"
	| "faSchool"
	| "faCircleInfo"
	| "faPrint"
	| "faArrowLeft"
	| "faCircleXmark"
	| "faCalendarDays"
	| "faUser"
	| "faChalkBoardUser"
	| "faVideo"
	| "faCirclePlay"
	| "faFilePdf"
	| "faPencilAlt"
	| "faPlus"
	| "faUserPlus"
	| "faUpload"
	| "faSadTear"
	| "faTrashAlt"
	| "faPen"
	| "faBan"
	| "faFlag"
	| "faCircleNotch"
	| "faSquare"
	| "faCalendar"
	| "faMessage"
	| "faCircleArrowLeft"
	| "faCamera"
	| "faChevronCircleLeft"
	| "faBoxArchive"
	| "faExclamationCircle"
	| "faKey"
	| "faAsterisk"
	| "faMinus"
	| "faLink"
	| "faQuoteLeft"
	| "faListUl"
	| "faListOl"
	| "faCode"
	| "faCloudUploadAlt"
	| "faBold"
	| "faItalic"
	| "faUnderline"
	| "faBook"
	| "faTabletAlt"
	| "faGlobe"
	| "faUserCog"
	| "faUserEdit"
	| "faRegularCircle"
	| "faAngleRight"
	| "faAngleLeft"
	| "faGraduationCap"
	| "faCoins"
	| "faCheckSquare"
	| "faStar"
	| "faLock"
	| "faRegularHeart";

type SprigIconType =
	| "sprigLeaf"
	| "sprigLogo"
	| "sprigMathCharacter"
	| "sprigOralLanguageCharacter"
	| "sprigTree"
	| "sprigNotification"
	| "sprigDashboard"
	| "sprigStudent"
	| "sprigReadingCircle"
	| "jlPhonics"
	| "jlVocabulary"
	| "jlPhonologicalAwareness"
	| "jlFluency"
	| "jlComprehension"
	| "jlOralLanguage"
	| "notYetAssessed"
	| "needsExplicit"
	| "needsMore"
	| "skillMastered"
	| "seed"
	| "sprout"
	| "bloom"
	| "flower"
	| "hmStar"
	| "hmStarEarned"
	| "hmAssessNot"
	| "hmAssessNotYet"
	| "hmAssessSupport"
	| "hmAssessMost"
	| "hmAssessIndependently"
	| "hmAssessable"
	| "hmSquarePassword"
	| "hmTrianglePassword"
	| "hmDiamondPassword"
	| "hmCirclePassword";

export type IconType = SprigIconType | FontAwesomeIconType;

export interface IconProps {
	/**
	 * Is this the principal call to action on the page?
	 */
	type: IconType;
	/**
	 * What size icon are you looking for? Default is 3x.
	 */
	size?: FontAwesomeIconSize;
	pulse?: boolean;
	spin?: boolean;
	regularIcon?: {isRegularIcon: boolean; type: IconName};
	style?: {};
	className?: string;
	onClick?: () => void;
	name?: string;
	iconWrapperClassName?: string;
	id?: string;
}

const getIconByTypeFA = (type: FontAwesomeIconType) => {
	switch (type) {
		case "faBackward":
			return faBackward;
		case "faBars":
			return faBars;
		case "faBullhorn":
			return faBullhorn;
		case "faCaretDown":
			return faCaretDown;
		case "faCaretUp":
			return faCaretUp;
		case "faCertificate":
			return faCertificate;
		case "faChartBar":
			return faChartBar;
		case "faCheck":
			return faCheck;
		case "faChevronDown":
			return faChevronDown;
		case "faChevronUp":
			return faChevronUp;
		case "faClipboardList":
			return faClipboardList;
		case "faDownload":
			return faDownload;
		case "faFilter":
			return faFilter;
		case "faInfoCircle":
			return faInfoCircle;
		case "faLeaf":
			return faLeaf;
		case "faPoll":
			return faPoll;
		case "faSearch":
			return faSearch;
		case "faSignOutAlt":
			return faSignOutAlt;
		case "faSpinner":
			return faSpinner;
		case "faTimes":
			return faTimes;
		case "faTimesCircle":
			return faTimesCircle;
		case "faUserCircle":
			return faUserCircle;
		case "faUserGraduate":
			return faUserGraduate;
		case "faChevronCircleRight":
			return faChevronCircleRight;
		case "faListAlt":
			return faListAlt;
		case "faHashTag":
			return faHashtag;
		case "faHeart":
			return faHeart;
		case "faCopy":
			return faCopy;
		case "faCommentAlt":
			return faCommentAlt;
		case "faEyeSlash":
			return faEyeSlash;
		case "faThumbsUp":
			return faThumbsUp;
		case "faThumbsDown":
			return faThumbsDown;
		case "faEye":
			return faEye;
		case "faTh":
			return faTh;
		case "faThList":
			return faThList;
		case "faEllipsisH":
			return faEllipsisH;
		case "faChartArea":
			return faChartArea;
		case "faChevronLeft":
			return faChevronLeft;
		case "faUsers":
			return faUsers;
		case "faHome":
			return faHome;
		case "faEdit":
			return faEdit;
		case "faCircle":
			return faCircle;
		case "faCheckCircle":
			return faCheckCircle;
		case "faChevronRight":
			return faChevronRight;
		case "faPenToSquare":
			return faPenToSquare;
		case "faChalkboardUser":
			return faChalkboardUser;
		case "faChalkboardTeacher":
			return faChalkboardTeacher;
		case "faSchool":
			return faSchool;
		case "faCircleInfo":
			return faCircleInfo;
		case "faPrint":
			return faPrint;
		case "faArrowLeft":
			return faArrowLeft;
		case "faCircleXmark":
			return faCircleXmark;
		case "faUser":
			return faUser;
		case "faChalkBoardUser":
			return faChalkboardUser;
		case "faVideo":
			return faVideo;
		case "faCirclePlay":
			return faCirclePlay;
		case "faFilePdf":
			return faFilePdf;
		case "faPencilAlt":
			return faPencilAlt;
		case "faPlus":
			return faPlus;
		case "faUserPlus":
			return faUserPlus;
		case "faUpload":
			return faUpload;
		case "faSadTear":
			return faSadTear;
		case "faTrashAlt":
			return faTrashAlt;
		case "faPen":
			return faPen;
		case "faBan":
			return faBan;
		case "faFlag":
			return faFlag;
		case "faCircleNotch":
			return faCircleNotch;
		case "faSquare":
			return faSquare;
		case "faCalendar":
			return faCalendar;
		case "faMessage":
			return faMessage;
		case "faCircleArrowLeft":
			return faCircleArrowLeft;
		case "faCamera":
			return faCamera;
		case "faChevronCircleLeft":
			return faChevronCircleLeft;
		case "faBoxArchive":
			return faBoxArchive;
		case "faExclamationCircle":
			return faExclamationCircle;
		case "faKey":
			return faKey;
		case "faAsterisk":
			return faAsterisk;
		case "faMinus":
			return faMinus;
		case "faLink":
			return faLink;
		case "faQuoteLeft":
			return faQuoteLeft;
		case "faListUl":
			return faListUl;
		case "faListOl":
			return faListOl;
		case "faCode":
			return faCode;
		case "faCloudUploadAlt":
			return faCloudUploadAlt;
		case "faBold":
			return faBold;
		case "faItalic":
			return faItalic;
		case "faUnderline":
			return faUnderline;
		case "faBook":
			return faBook;
		case "faTabletAlt":
			return faTabletAlt;
		case "faGlobe":
			return faGlobe;
		case "faUserCog":
			return faUserCog;
		case "faUserEdit":
			return faUserEdit;
		case "faRegularCircle":
			return faRegularCircle;
		case "faRegularHeart":
			return faRegularHeart;
		case "faAngleRight":
			return faAngleRight;
		case "faAngleLeft":
			return faAngleLeft;
		case "faGraduationCap":
			return faGraduationCap;
		case "faCoins":
			return faCoins;
		case "faCalendarDays":
			return faCalendarDays;
		case "faCheckSquare":
			return faCheckSquare;
		case "faStar":
			return faStar;
		case "faLock":
			return faLock;
		default:
			return faTimes;
	}
};

const getIconByTypeSprig = (type: SprigIconType) => {
	switch (type) {
		case "sprigLeaf":
			return <SprigLeaf />;
		case "sprigLogo":
			return <SprigLogo />;
		case "sprigMathCharacter":
			return <SprigMathCharacter />;
		case "sprigOralLanguageCharacter":
			return <SprigOralLanguageCharacter />;
		case "sprigTree":
			return <SprigTree />;
		case "sprigNotification":
			return <SprigNotification />;
		case "sprigDashboard":
			return <SprigDashboard />;
		case "sprigStudent":
			return <SprigStudent />;
		case "sprigReadingCircle":
			return <SprigReadingCircle />;
		case "jlPhonics":
			return <JLPhonics />;
		case "jlVocabulary":
			return <JLVocabulary />;
		case "jlPhonologicalAwareness":
			return <JLPhonologicalAwareness />;
		case "jlFluency":
			return <JLFluency />;
		case "jlComprehension":
			return <JLComprehension />;
		case "jlOralLanguage":
			return <JLOralLanguage />;
		case "notYetAssessed":
			return <NotYetAssessed />;
		case "needsExplicit":
			return <NeedsExplicit />;
		case "needsMore":
			return <NeedsMore />;
		case "skillMastered":
			return <SkillMastered />;
		case "seed":
			return <Seed />;
		case "sprout":
			return <Sprout />;
		case "bloom":
			return <Bloom />;
		case "flower":
			return <Flower />;
		case "hmStar":
			return <Star />;
		case "hmStarEarned":
			return <StarEarned />;
		case "hmAssessNot":
			return <HMNotYet />;
		case "hmAssessSupport":
			return <HMSome />;
		case "hmAssessMost":
			return <HMMost />;
		case "hmAssessIndependently":
			return <HMAll />;
		case "hmAssessNotYet":
			return <HMRarely />;
		case "hmAssessable":
			return <HMAssessable />;
		case "hmSquarePassword":
			return <HMSquarePassword />;
		case "hmTrianglePassword":
			return <HMTrianglePassword />;
		case "hmDiamondPassword":
			return <HMDiamondPassword />;
		case "hmCirclePassword":
			return <HMCirclePassword />;
		default:
			return null;
	}
};

const isSprigIcon = (type: IconType): type is SprigIconType => {
	return [
		"sprigLeaf",
		"sprigLogo",
		"sprigMathCharacter",
		"sprigOralLanguageCharacter",
		"sprigTree",
		"sprigNotification",
		"sprigDashboard",
		"sprigStudent",
		"sprigReadingCircle",
		"jlPhonics",
		"jlVocabulary",
		"jlPhonologicalAwareness",
		"jlFluency",
		"jlComprehension",
		"jlOralLanguage",
		"notYetAssessed",
		"needsExplicit",
		"needsMore",
		"skillMastered",
		"seed",
		"sprout",
		"bloom",
		"flower",
		"hmStar",
		"hmStarEarned",
		"hmAssessNot",
		"hmAssessNotYet",
		"hmAssessSupport",
		"hmAssessMost",
		"hmAssessIndependently",
		"hmAssessable",
		"hmSquarePassword",
		"hmTrianglePassword",
		"hmDiamondPassword",
		"hmCirclePassword",
	].some((element) => element === type);
};

/**
 * Primary UI component for user interaction
 */

export const Icon: React.FC<IconProps> = ({
	type,
	size = "lg",
	pulse = false,
	regularIcon = {isRegularIcon: false, type: "times"},
	iconWrapperClassName = "",
	...props
}) => {
	return (
		<div
			className={[STYLE_CONSTANT.COMPONENT.ICON, iconWrapperClassName].join(
				" "
			)}
		>
			{isSprigIcon(type) ? (
				<div
					className={[
						`sprig-${STYLE_CONSTANT.COMPONENT.ICON}`,
						`sprig-${STYLE_CONSTANT.COMPONENT.ICON}--${size}`,
						props.className,
					].join(" ")}
					style={props.style}
				>
					{getIconByTypeSprig(type)}
				</div>
			) : (
				<FontAwesomeIcon
					icon={
						(regularIcon.isRegularIcon
							? ["far", regularIcon.type]
							: getIconByTypeFA(type)) as IconProp
					}
					size={size}
					pulse={pulse}
					{...props}
				/>
			)}
		</div>
	);
};
