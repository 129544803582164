import * as ActionTypes from "../Redux/ActionTypes";
import {CreateReducer} from "../Redux/Utilities";

const initialState = {
	currentRole: null,
};
const defaultTourTemplate = {
	step: 1,
	isActive: true,
};
export const AppContentReducer = CreateReducer(initialState, {
	[ActionTypes.APPCONTENT_SET_CURRENT_ROLE]: (state, {payload}) => {
		if (!payload) return state;
		return {
			...state,
			currentRole: payload,
		};
	},
	[ActionTypes.APPCONTENT_DEFAULT_CURRENT_ROLE]: (state) => {
		return {
			...state,
			currentRole: null,
		};
	},
	[ActionTypes.APPCONTENT_INITIATE_PORTAL_TOUR]: (state, {payload}) => {
		const {personId, tourId} = payload;
		const tours = state?.tours ? state.tours : [];
		const previousTour = tours.find(
			(tour) => tour.id === personId && tour.tourId === tourId
		);
		if (previousTour) {
			return {
				...state,
				tours: tours.map((tour) => {
					if (tour.id === personId && tour.tourId === tourId) {
						return {
							...tour,
							step: 1,
							isActive: true,
						};
					}
					return tour;
				}),
			};
		}
		return {
			...state,
			tours: [...tours, {...defaultTourTemplate, id: personId, tourId}],
		};
	},
	[ActionTypes.APPCONTENT_PROCEED_TO_NEXT_PORTAL_TOUR_STEP]: (
		state,
		{payload}
	) => {
		return {
			...state,
			tours: state?.tours?.map((tour) => {
				if (tour.id === payload.id && tour.tourId === payload.tourId) {
					return {
						...tour,
						step: tour.step + 1,
					};
				}
				return tour;
			}),
		};
	},
	[ActionTypes.APPCONTENT_END_CURRENT_TOUR]: (state, {payload}) => {
		return {
			...state,
			tours: state?.tours?.map((tour) => {
				if (tour.id === payload.id && tour.tourId === payload.tourId) {
					return {
						...tour,
						step: 1,
						isActive: false,
					};
				}
				return tour;
			}),
		};
	},
});
