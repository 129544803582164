import {TFunctionResult} from "i18next";
import React from "react";

interface TableThProps {
	children?:
		| JSX.Element[]
		| Element[]
		| Element
		| JSX.Element
		| string
		| number
		| TFunctionResult;
	scope?: string;
	className?: string;
	onClick?: () => void | null;
	title?: string;
	colSpan?: number;
	name?: string;
}

export const TableTh = ({children, ...rest}: TableThProps) => (
	<th {...rest}>{children}</th>
);
