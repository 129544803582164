import React, {Component} from "react";

interface FormDropdownProps {
	items: DropdownItem[];
	selectedItemId: number | string;
	label: string;
	id?: number;
	disabled?: boolean;
	onSelect: (targetId: number) => void;
}

interface DropdownItem {
	id: number | string;
	label: string;
}

export class FormDropdown extends Component<FormDropdownProps> {
	state = {
		isMenuOpen: false,
	};

	onToggleMenu = () => {
		this.setState({isMenuOpen: !this.state.isMenuOpen});
	};

	onSelectItem = (event) => {
		const {onSelect} = this.props;
		if (onSelect) {
			onSelect(event.target.id);
			this.setState({isMenuOpen: false});
		}
	};

	render() {
		const {items, selectedItemId, label, id, disabled} = this.props;
		const {isMenuOpen} = this.state;

		const selectedItem = items.find(
			(item: DropdownItem) => selectedItemId === item.id
		);
		const renderedItems = items.filter((item) => selectedItemId !== item.id);
		return (
			selectedItem && (
				<div className={`form-dropdown${isMenuOpen ? " open" : ""}`}>
					{label && <small>{label}</small>}
					<button
						className={`form-dropdown-input${
							renderedItems.length ? " arrow" : " no-arrow"
						}`}
						id={id?.toString()}
						onClick={this.onToggleMenu}
						disabled={disabled}
						type="button"
					>
						<div
							className="form-dropdown-input-text"
							title={selectedItem.label}
						>
							{selectedItem && selectedItem.label}
						</div>
					</button>
					{isMenuOpen && renderedItems.length ? (
						<ul className="form-dropdown-menu" role="menu">
							{renderedItems.map((item) => (
								<li
									key={item.id}
									id={item.id.toString()}
									className="form-dropdown-menu-item"
									onClick={this.onSelectItem}
								>
									{item.label}
								</li>
							))}
						</ul>
					) : null}
				</div>
			)
		);
	}
}
