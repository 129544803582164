import React from "react";

import {Icon} from "../Icons";

export const FormCheckbox = ({checked, label, onClick, id}) => (
	<div className="form-checkbox">
		<div
			className={`checkbox-container${checked ? " checked" : ""}`}
			onClick={onClick}
			id={id}
		>
			<Icon type="faCheck" iconWrapperClassName="d-inline" />
		</div>
		{label ? <strong>{label}</strong> : null}
	</div>
);
