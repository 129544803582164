import "../../../../Stylesheets/css/scss/Components/Portal/Public/Domain/DomainSwitch.css";

import i18n from "i18next";
import React from "react";

import {ReportActions} from "../../../../Actions";
import {
	DOMAIN_MATH,
	DOMAIN_ORAL_LANGUAGE,
	getClassroomLogo,
} from "../../../../Scripts/Domain";
import {Dropdown} from "../Dropdown";

interface DomainSwitchProps {
	domain: number;
	domains: {id: number}[];
	classname?: string;
	componentClassName: string;
	clearActivitiesOnSelectDomain?: () => void;
	id?: string;
	menuId?: string;
}

export const DomainSwitch = ({
	domain,
	domains,
	classname = "btn btn-dropdown dropdown-toggle btn-dropdown-w-fixed",
	componentClassName,
	clearActivitiesOnSelectDomain,
	id,
	menuId,
}: DomainSwitchProps) => {
	const authorizedDomainIds = domains.map((domainObject) => domainObject.id);
	if (authorizedDomainIds.length === 1 && authorizedDomainIds[0] !== domain) {
		ReportActions.setDomain(authorizedDomainIds[0]);
	}
	const getLink = (domain) => {
		const domainItem = domains.find(
			(domainObject) => domainObject.id === domain
		);
		return domainItem ? domainItem : {link: ""};
	};

	const domainItems = [
		...(authorizedDomainIds.includes(DOMAIN_ORAL_LANGUAGE)
			? [
					{
						id: DOMAIN_ORAL_LANGUAGE,
						img: getClassroomLogo(DOMAIN_ORAL_LANGUAGE, true, false),
						alt: "Oral language dark background",
						label: i18n.t("domain.oral_language"),
						link: getLink(DOMAIN_ORAL_LANGUAGE),
					},
			  ]
			: []),
		...(authorizedDomainIds.includes(DOMAIN_MATH)
			? [
					{
						id: DOMAIN_MATH,
						img: getClassroomLogo(DOMAIN_MATH, true, false),
						alt: "Math dark background",
						label: i18n.t("domain.math"),
						link: getLink(DOMAIN_MATH),
					},
			  ]
			: []),
	];

	const onSelectDomain = (item) => {
		ReportActions.setDomain(item.id);
		if (clearActivitiesOnSelectDomain) clearActivitiesOnSelectDomain();
	};
	return (
		<Dropdown
			componentClassName={componentClassName}
			className={classname}
			selectedItem={domainItems.find((item) => item.id === domain)}
			items={domainItems}
			onSelect={onSelectDomain}
			id={id}
			menuId={menuId}
		/>
	);
};
