import {
	ACTIVITY_FILTERS,
	ACTIVITY_USER,
	DOMAIN_SUBSCRIPTION_KEY,
	FILTER_TAGS,
	PORTALS,
} from "./Constants";
import {getCurrentSubscription} from "./Date";

const DEFAULT_PAGE = 1;
export const resetSaveSearchCompleted = (portal) => {
	const saveAndSearch = {
		[ACTIVITY_FILTERS.SAVED_FOR]: "",
		[ACTIVITY_FILTERS.SEARCH]: "",
	};
	switch (portal) {
		case PORTALS.CAREGIVER:
			return {
				...saveAndSearch,
				[ACTIVITY_FILTERS.COMPLETED_BY]: "",
			};
		case PORTALS.EDUCATOR:
			return {
				...saveAndSearch,
				[ACTIVITY_FILTERS.COMPLETED_BY_CLASS_AND_CURRENT_USER]: "",
			};
		default:
			throw new Error(`Unsupported portal type: ${portal}`);
	}
};

export const resetCurrentOutcomes = {
	[ACTIVITY_FILTERS.LEARNING_OUTCOMES]: null,
	[ACTIVITY_FILTERS.GENERAL_OUTCOMES]: null,
	[ACTIVITY_FILTERS.SPECIFIC_OUTCOMES]: null,
};

export const resetActivityFilters = {
	[ACTIVITY_FILTERS.PLACES]: [],
	[ACTIVITY_FILTERS.DURATIONS]: [],
	[ACTIVITY_FILTERS.DIFFICULTIES]: [],
	[ACTIVITY_FILTERS.HIDDEN]: false,
};

export const defaultFilterTagsInFilterBar = {
	...resetCurrentOutcomes,
	[FILTER_TAGS.COMPETENCIES]: [],
	[FILTER_TAGS.DIFFICULTIES]: [],
	[FILTER_TAGS.LANGUAGES]: [],
};

export const getFilterByFilters = (filters, student, filterBy) => {
	const recommendedFilters = {
		...filters,
		...resetSaveSearchCompleted(PORTALS.CAREGIVER),
		...resetCurrentOutcomes,
		[ACTIVITY_FILTERS.PAGE]: DEFAULT_PAGE,
	};

	switch (filterBy) {
		case ACTIVITY_USER.STATUS.RECOMMENDED:
			return {
				...recommendedFilters,
				[ACTIVITY_FILTERS.PRIORITIZE_FEATURED]:
					student.authorization.subscription ===
					DOMAIN_SUBSCRIPTION_KEY.FREE_TRIAL_USER
						? false
						: true,
				[ACTIVITY_FILTERS.COMPLETED_BY]: null,
				[ACTIVITY_FILTERS.NOT_COMPLETED_BY]: student.id,
			};
		case ACTIVITY_USER.STATUS.SAVED:
			return {
				...recommendedFilters,
				[ACTIVITY_FILTERS.SAVED_FOR]: student.id,
				[ACTIVITY_FILTERS.NOT_COMPLETED_BY]: null,
				[ACTIVITY_FILTERS.COMPLETED_BY]: null,
				[ACTIVITY_FILTERS.PRIORITIZE_FEATURED]: false,
			};

		case ACTIVITY_USER.STATUS.COMPLETED:
			return {
				...recommendedFilters,
				[ACTIVITY_FILTERS.NOT_COMPLETED_BY]: null,
				[ACTIVITY_FILTERS.COMPLETED_BY]: student.id,
				[ACTIVITY_FILTERS.PRIORITIZE_FEATURED]: false,
			};
		default:
			throw new Error(`Unsupported filterBy type: ${filterBy}`);
	}
};

export const getCurrentCurriculumOutcomes = (
	outcomes,
	subscriptions,
	currentTerm,
	isSprigHomeUser,
	nsCurriculumId,
	isFreeTrialReadingTeacher
) => {
	const currentSubscription = isFreeTrialReadingTeacher
		? subscriptions[0]
		: getCurrentSubscription(subscriptions, currentTerm);
	const currentCurriculumId = isSprigHomeUser
		? nsCurriculumId
		: currentSubscription
		? currentSubscription.curriculum.id
		: nsCurriculumId;
	return outcomes.filter(
		(outcome) => outcome.curriculum === currentCurriculumId
	);
};

export const getCurrentCurriculumCompetencies = (
	competencies,
	subscriptions,
	currentTerm,
	isSprigHomeUser,
	nsCurriculumId
) => {
	const currentSubscription = getCurrentSubscription(
		subscriptions,
		currentTerm
	);
	if (currentSubscription) {
		const currentCurriculumId = isSprigHomeUser
			? nsCurriculumId
			: currentSubscription.curriculum.id;
		return competencies.filter((competency) => {
			return competency.curricula.includes(currentCurriculumId);
		});
	}
};
