import React from "react";

interface TableRowProps {
	children: JSX.Element[] | JSX.Element | Element[] | Element;
	className?: string;
	name?: string;
	onClick?: () => void;
}

export const TableRow = ({children, ...rest}: TableRowProps) => (
	<tr {...rest}>{children}</tr>
);
