import i18n from "i18next";
import React, {Component} from "react";

import {ReportActions} from "../../../../Actions";
import {
	CHART_TYPE,
	REPORT_CHART_TYPE,
	REPORT_ID,
	REPORT_TYPE,
} from "../../../../Scripts/Constants";
import {LanguageTaxonomyItem} from "../../../../Scripts/PublicInterfaces";
import {ChartHeader, ReportAlertNoData, ReportChart} from "..";

interface SpokenAtHomeProps {
	id?: number;
	report?: {languages: []; home_count: number}[];
	languages: LanguageTaxonomyItem[];
}

interface SpokenAtHomeState {
	data: {languages: []; home_count: number}[];
	gotData: boolean;
}

export class SpokenAtHome extends Component<
	SpokenAtHomeProps,
	SpokenAtHomeState
> {
	constructor(props) {
		super(props);
		this.state = {
			gotData: false,
			data: [],
		};
	}

	componentDidMount = () => {
		if (!this.props.report) {
			this.getData();
		}
	};

	getData = () => {
		ReportActions.getReport(
			{
				report: REPORT_ID.HOME_LANGUAGES,
				id: this.props.id,
				type: REPORT_TYPE.CLASS,
				direct: true,
			},
			{
				handling: {
					handleResponse: (data) => {
						if (!data) {
							this.setState({
								gotData: true,
							});
						} else {
							this.setState({
								gotData: true,
								data,
							});
						}
					},
				},
			}
		);
	};

	getChartData = () => {
		const {data} = this.state;
		const {report} = this.props;
		const reportData = report ? report[0] : data[0];
		const {languages} = this.props;
		const percentageArray = languages.map((language) => {
			return (
				(reportData.languages[language.code] / reportData.home_count) *
				100
			).toFixed(2);
		});
		const languageLabels = languages.map((language) => language.english_name);
		const chartData = {
			labels: languageLabels,
			datasets: [
				{
					label: i18n.t("chart.percent_spoken_language"),
					backgroundColor: [
						"#4BB5B4",
						"#6BC4C3",
						"#8FD3D2",
						"#B3E2E1",
						"#D9F0F0",
					],
					borderColor: "white",
					borderWidth: 1,
					hoverBackgroundColor: "#F88D25",
					hoverBorderColor: "#F88D25",
					data: percentageArray,
				},
			],
		};
		const counts = languages?.map((language) => {
			return reportData.languages[language.code];
		});
		return {chartData, counts};
	};

	hasReport = () => {
		const {data, gotData} = this.state;
		const {report} = this.props;
		return (gotData && data.length > 0) || (report && report.length > 0);
	};

	render() {
		const hasReportData = this.hasReport();
		return (
			<div className="mt-2">
				<ChartHeader title={i18n.t("admin.languages_spoken_home")} />
				<div className="text-right mb-2 mt-5">
					{hasReportData && (
						<ReportChart
							chartType={CHART_TYPE.BAR}
							data={this.getChartData().chartData}
							type={REPORT_CHART_TYPE.LANGUAGE_SPOKEN_AT_HOME}
							tooltipValue={this.getChartData().counts}
						/>
					)}
					{!hasReportData && <ReportAlertNoData />}
				</div>
			</div>
		);
	}
}
