import "./ActivitiesFilter.scss";

import i18n from "i18next";
import React, {useEffect, useRef, useState} from "react";

import {setFiltersGetActivities} from "../../../../Scripts/Activities";
import {
	ACTIVITY_FILTERS,
	ACTIVITY_PLACE_SLUG,
	AUTOMATION_TEST_ID,
	FILTER_TAGS,
	PORTALS,
} from "../../../../Scripts/Constants";
import {Icon} from "../../../Public";

function usePrevious(value) {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
}

export const ActivitiesFilter = ({
	filterCategories,
	filters,
	closeMenuEvent,
	currentStudent = {id: 0},
	portal,
	onReadingLearningActivities,
	onHMLearningActivities = false,
}) => {
	const filterCurrentState = {
		places: filters.places,
		durations: filters.durations,
		difficulties: filters.difficulties,
	};
	const [difficultiesState, setDifficultiesState] = useState([
		...(filterCurrentState.difficulties ? filterCurrentState.difficulties : []),
	]);
	const [durationsState, setDurationState] = useState([
		...(filterCurrentState.durations ? filterCurrentState.durations : []),
	]);
	const [placesState, setPlacesState] = useState([
		...(filterCurrentState.places ? filterCurrentState.places : []),
	]);
	const [selectedHiddenActivities, setSelectedHiddenActivities] = useState(
		filters.hidden
	);
	// const [
	// 	selectedObservationalAssessments,
	// 	setSelectedObservationalAssessments,
	// ] = useState(filters.observationalAssessments);
	// const [selectedClassroomKits, setSelectedClassroomKits] = useState(
	// 	filters.classroomKits
	// );

	const prevPlacesState = usePrevious(filterCurrentState.places);
	const prevDifficultiesState = usePrevious(filterCurrentState.difficulties);
	const prevDurationsState = usePrevious(filterCurrentState.durations);
	const prevHiddenState = usePrevious(filters.hidden);

	useEffect(() => {
		if (filterCurrentState.places !== prevPlacesState) {
			setPlacesState(filterCurrentState.places);
		}
		if (filterCurrentState.difficulties !== prevDifficultiesState) {
			setDifficultiesState(filterCurrentState.difficulties);
		}
		if (filterCurrentState.durations !== prevDurationsState) {
			setDurationState(filterCurrentState.durations);
		}
		if (filters.hidden !== prevHiddenState) {
			setSelectedHiddenActivities(filters.hidden);
		}
	}, [
		filterCurrentState.difficulties,
		filterCurrentState.durations,
		filterCurrentState.places,
		filters.hidden,
		placesState,
		prevDifficultiesState,
		prevDurationsState,
		prevHiddenState,
		prevPlacesState,
	]);

	const {difficulties, duration, places} = filterCategories;
	const handleClickPlace = (placeId) => {
		if (placesState.includes(placeId)) {
			const index = placesState.indexOf(placeId);
			if (index > -1) {
				placesState.splice(index, 1);
			}
			setPlacesState([...placesState]);
		} else {
			setPlacesState([...placesState, placeId]);
		}
	};

	const handleClickDuration = (durationId) => {
		if (durationsState.includes(durationId)) {
			const index = durationsState.indexOf(durationId);
			if (index > -1) {
				durationsState.splice(index, 1);
			}
			setDurationState([...durationsState]);
		} else {
			setDurationState([...durationsState, durationId]);
		}
	};

	const handleClickDifficulty = (difficultyId) => {
		if (difficultiesState.includes(difficultyId)) {
			const index = difficultiesState.indexOf(difficultyId);
			if (index > -1) {
				difficultiesState.splice(index, 1);
			}
			setDifficultiesState([...difficultiesState]);
		} else {
			setDifficultiesState([...difficultiesState, difficultyId]);
		}
	};

	const handleClickClear = () => {
		setDifficultiesState([]);
		setDurationState([]);
		setPlacesState([]);
		setSelectedHiddenActivities(false);
		//setSelectedClassroomKits(false);
		//setSelectedObservationalAssessments(false);
		handleClickApply(true);
	};

	const handleClickApply = (isClear) => {
		//TODO: filter observational assessment/classroom kits
		const currentFilters = {
			...filters,
			[ACTIVITY_FILTERS.HIDDEN]: isClear
				? false
				: selectedHiddenActivities
				? true
				: false,
			[ACTIVITY_FILTERS.PAGE]: 1,
			[ACTIVITY_FILTERS.DURATIONS]: [...(isClear ? [] : durationsState)],
			[ACTIVITY_FILTERS.PLACES]: [...(isClear ? [] : placesState)],
			[ACTIVITY_FILTERS.SEARCH]: "",
			[FILTER_TAGS.DIFFICULTIES]: [...(isClear ? [] : difficultiesState)],
			filterTags: {
				...filters.filterTags,
			},
			...(isClear
				? {[ACTIVITY_FILTERS.NOT_COMPLETED_BY]: currentStudent.id}
				: {[ACTIVITY_FILTERS.NOT_COMPLETED_BY]: null}),
		};
		setFiltersGetActivities({...currentFilters}, true);
		closeMenuEvent();
	};

	const handleClickHiddenActivities = () => {
		setSelectedHiddenActivities(!selectedHiddenActivities);
	};

	const getPlaces = () => {
		switch (portal) {
			case PORTALS.CAREGIVER:
				return places.filter(
					(place) => place.slug !== ACTIVITY_PLACE_SLUG.SCHOOL
				);
			case PORTALS.EDUCATOR:
				return places;
			default:
				throw new Error(`Unsupported portal: ${portal}`);
		}
	};

	const categories = [
		// ...(onHMLearningActivities
		// 	? [
		// 			{
		// 				nonTaxonomyCategory: true,
		// 				option: (
		// 					<div key="activities-with" className="activities-with">
		// 						<div className="title">{i18n.t("admin.activities_with")}</div>
		// 						<div
		// 							className="activity-taxonomy-item"
		// 							onClick={() => setSelectedObservationalAssessments(true)}
		// 						>
		// 							<div className="icon-text">
		// 								<Icon type="hmAssessSupport" className="option-icon" />
		// 								<div>
		// 									{i18n.t(
		// 										"holistic_math_prototype.observational_assessments"
		// 									)}
		// 								</div>
		// 							</div>
		// 							{selectedObservationalAssessments ? (
		// 								<Icon type="faCheck" size="sm" />
		// 							) : null}
		// 						</div>
		// 						<div
		// 							className="activity-taxonomy-item"
		// 							onClick={() => setSelectedClassroomKits(true)}
		// 						>
		// 							<div className="icon-text">
		// 								<Icon type="faBoxArchive" className="option-icon" />
		// 								<div>{i18n.t("holistic_math_prototype.classroom_kit")}</div>
		// 							</div>
		// 							{selectedClassroomKits ? (
		// 								<Icon type="faCheck" size="sm" />
		// 							) : null}
		// 						</div>
		// 					</div>
		// 				),
		// 			},
		// 	  ]
		// 	: []),
		...(!onReadingLearningActivities
			? [
					{
						items: getPlaces(),
						categorySlug: places[0].taxonomy,
						handleClick: handleClickPlace,
						selected: [...(placesState ? placesState : [])],
					},
			  ]
			: []),
		{
			items: [...duration],
			categorySlug: duration[0].taxonomy,
			handleClick: handleClickDuration,
			selected: [...(durationsState ? durationsState : [])],
		},
		...(!onReadingLearningActivities
			? [
					{
						items: [...difficulties],
						categorySlug: difficulties[0].taxonomy,
						handleClick: handleClickDifficulty,
						selected: [...(difficultiesState ? difficultiesState : [])],
					},
			  ]
			: []),
		{
			nonTaxonomyCategory: true,
			option: (
				<div key="hidden-activity" className="hidden-activity">
					<div className="title">{i18n.t("admin.display")}</div>
					<div
						className="activity-taxonomy-item"
						onClick={handleClickHiddenActivities}
					>
						<div>{i18n.t("admin.hidden_activity", {count: 0})}</div>
						{selectedHiddenActivities ? (
							<Icon type="faCheck" size="sm" />
						) : null}
					</div>
				</div>
			),
		},
	];

	return (
		<div
			className="activities-filter"
			id={AUTOMATION_TEST_ID.PUBLIC.DROPDOWN.ACTIVITY_FILTER.MENU}
		>
			<div className="d-flex justify-content-between activities-filter-title">
				<div
					className="clear"
					onClick={handleClickClear}
					id={AUTOMATION_TEST_ID.PUBLIC.DROPDOWN.ACTIVITY_FILTER.CLEAR_BUTTON}
				>
					{i18n.t("admin.clear")}
				</div>
				<div>{i18n.t("admin.filters")}</div>
				<div
					className="apply"
					id={AUTOMATION_TEST_ID.PUBLIC.DROPDOWN.ACTIVITY_FILTER.APPLY_BUTTON}
					onClick={() => handleClickApply(false)}
				>
					{i18n.t("admin.apply")}
				</div>
			</div>
			<div className="activities-filter-category">
				{categories.map((category) => {
					return category.nonTaxonomyCategory ? (
						category.option
					) : (
						<div className={category.categorySlug} key={category.categorySlug}>
							<div className="title">
								{i18n.t(`activity_taxonomies.${category.categorySlug}`)}
							</div>
							{category.items.map((item) => {
								return (
									<ActivityTaxonomyItem
										key={item.slug}
										item={item}
										selected={category.selected?.includes(item?.id)}
										handleClick={category.handleClick}
									/>
								);
							})}
						</div>
					);
				})}
			</div>
		</div>
	);
};

const ActivityTaxonomyItem = ({item, selected, handleClick}) => {
	return (
		<div
			className="activity-taxonomy-item"
			onClick={() => handleClick(item.id)}
		>
			<div name={AUTOMATION_TEST_ID.PUBLIC.DROPDOWN.ACTIVITY_FILTER.MENU_ITEM}>
				{i18n.t(`activity_taxonomy_${item.taxonomy}.${item.slug}`)}
			</div>
			{selected ? <Icon type="faCheck" size="sm" /> : null}
		</div>
	);
};
