// Import Scripts
import "./Scripts/Debugger.js";
// Import Stylesheets
/*
	Mapped SCSS -> CSS is available in ./Stylesheets/css/scss with same file name
		ex: ./Stylesheets/scss/main.scss is available at ./Stylesheets/css/scss/main.css
*/
import "./Stylesheets/css/animate.css";
import "./Stylesheets/vendor/bootstrap.min.css";
import "./Stylesheets/css/scss/main.css";
import "./Stylesheets/css/scss/import.css";
import "./Locale/i18n";
import "./Scripts/SetupWindow.js";

import {library} from "@fortawesome/fontawesome-svg-core";
import {
	faCalendarAlt as faCalendarAltRegular,
	faCircle as faCircleRegular,
} from "@fortawesome/free-regular-svg-icons";
// Import Modules
import React, {StrictMode} from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import {PersistGate} from "redux-persist/integration/react";

import {Store} from "./Actions";
// Import Components
import App from "./Components/App";

library.add(faCalendarAltRegular, faCircleRegular);

ReactDOM.render(
	<Provider store={Store.store}>
		<PersistGate loading={null} persistor={Store.persistor}>
			<BrowserRouter basename="/app">
				<StrictMode>
					<App />
				</StrictMode>
			</BrowserRouter>
		</PersistGate>
	</Provider>,
	document.getElementById("root")
);
