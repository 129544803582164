import i18n from "../Locale/i18n";
import {LEVEL_ONE_OUTCOME_SLUG} from "./Constants";
import {DOMAIN_READING} from "./Domain";
import {getLevelOneOutcomes} from "./Utilities";

const levelOneOutcomesTaxonomy = getLevelOneOutcomes();

export const getIconType = (slug) => {
	switch (slug) {
		case LEVEL_ONE_OUTCOME_SLUG.SR_PHONICS:
			return "jlPhonics";
		case LEVEL_ONE_OUTCOME_SLUG.SR_PHONOLOGICAL_AWARENESS:
			return "jlPhonologicalAwareness";
		case LEVEL_ONE_OUTCOME_SLUG.SR_COMPREHENSION:
			return "jlComprehension";
		case LEVEL_ONE_OUTCOME_SLUG.SR_VOCABULARY:
			return "jlVocabulary";
		case LEVEL_ONE_OUTCOME_SLUG.SR_ORAL_LANGUAGE:
			return "jlOralLanguage";
		case LEVEL_ONE_OUTCOME_SLUG.SR_FLUENCY:
			return "jlFluency";
		default:
			throw new Error(`Unsupported topic slug ${slug}`);
	}
};

export const getSubItemGrade = (item) => {
	//TODO: update the mapping of level 2 outcomes to grade
	switch (item.slug) {
		case "awfgrades2-3":
			return "2 - 3";
		case "lowercaseletters":
		case "uppercaseletters":
		case "vowels":
			return "Pre K - 1";
		case "bookconcepts":
		case "directionality":
		case "letterconcepts":
		case "punctuationmarks":
		case "wordconcepts":
		case "pagrade1to2":
			return "1 - 2";
		case "1aall-now":
		case "1aon-yes":
		case "1bafter-just":
		case "1bknow-when":
			return "1";
		case "2aalways-made":
		case "2amany-your":
		case "2babout-light":
		case "2blong-together":
			return "2";
		case "ka-little":
		case "klook-you":
		case "paprektok":
			return "Pre K - K";
		case "orallanguageforpersonalandsocialgrowth":
		case "orallanguageincentres":
		case "orallanguageinwholearoupactivities":
		case "rck-2":
			return "K - 2";
		case "come-alive-reading":
		case "echoreading":
		case "paired-reading":
		case "reading-fluency":
			return "Pre K - 2";
		default:
			return "Pre K - 2";
		//throw new Error(`Unsupported topic slug ${item.slug}`);
	}
};

export const isTopicFreeTrialAccessible = (slug) => {
	const freeTrialAccessibleTopics = [LEVEL_ONE_OUTCOME_SLUG.SR_PHONICS];
	return freeTrialAccessibleTopics.includes(slug);
};

export const getFreeTrialAvailableOutcomes = () => {
	return levelOneOutcomesTaxonomy
		.filter((topic) => topic.slug === LEVEL_ONE_OUTCOME_SLUG.SR_PHONICS)
		.map((topic) => topic.id);
};

export const readingGradeItems = (grades) => {
	return grades.sort((a, b) => (a.average_age > b.average_age ? 1 : -1));
};

export const compare = (a, b) => {
	const importOrder = [
		LEVEL_ONE_OUTCOME_SLUG.SR_PHONICS,
		LEVEL_ONE_OUTCOME_SLUG.SR_PHONOLOGICAL_AWARENESS,
		LEVEL_ONE_OUTCOME_SLUG.SR_VOCABULARY,
		LEVEL_ONE_OUTCOME_SLUG.SR_ORAL_LANGUAGE,
		LEVEL_ONE_OUTCOME_SLUG.SR_COMPREHENSION,
		LEVEL_ONE_OUTCOME_SLUG.SR_FLUENCY,
	];

	const sortByObject = importOrder.reduce((obj, item, index) => {
		return {
			...obj,
			[item]: index,
		};
	}, {});

	return sortByObject[a.slug] - sortByObject[b.slug];
};

export const sortTopics = (learningOutcomes) => {
	return learningOutcomes.sort(compare);
};

export const sortSubTopics = (generalOutcomes) => {
	return generalOutcomes.sort((a, b) => {
		return a.id - b.id;
	});
};

export const getClassroomLink = (classroom) => {
	return classroom.domain_id === DOMAIN_READING
		? `/portal/classroom/${classroom.id}/circle-charts`
		: `/portal/classroom/${classroom.id}/dashboard`;
};

export const skillInCurrentGradeRange = (
	skill,
	grades,
	circleChartFilterSkillsByGrade
) => {
	const currentMinGradeIndex = grades
		.map((grade) => grade.id)
		.indexOf(skill.min_grade);
	const currentMaxGradeIndex = grades
		.map((grade) => grade.id)
		.indexOf(skill.max_grade);

	if (currentMinGradeIndex !== -1 && currentMaxGradeIndex !== -1) {
		const currentGradeRange = grades
			.slice(currentMinGradeIndex, currentMaxGradeIndex + 1)
			.map((grade) => grade.id);
		return currentGradeRange.some((gradeId) =>
			circleChartFilterSkillsByGrade.includes(gradeId)
		);
	}

	return false;
};

export const demoClassroom = {
	id: 1,
	name: "Demo",
};

export const getDemoClassroomObject = (term, domain_id, default_grade_id) => {
	return {
		id: 1,
		name: "Demo",
		room: "Demo",
		term,
		domain_id,
		school_id: 1,
		external_id: null,
		default_grade_id,
		student_count: 11,
		teacher_count: 1,
		caregiver_count: 0,
		total_completed_activity_count: null,
		unique_completed_activity_count: null,
		student_with_caregiver_count: 0,
		is_demo: true,
	};
};

export const SUBSCRIPTION_PLAN = {
	SPROUT: "sprout",
	BLOOM: "bloom",
};

export const CONTENT_TIER_NUMBER = {
	ONE: 1,
	TWO: 2,
	THREE: 3,
	FOUR: 4,
};

export const TEACHER_LIMIT = {
	TRIAL: 1,
	SPROUT: 3,
	BLOOM: 100,
};

export const SPRIG_READING_PRODUCT_URL =
	process.env.REACT_APP_SPRIG_ENV !== "production"
		? "products/sprig-reading-3-0/"
		: "products/sprig-reading/";

export const linkToProduct = (type) => {
	return process.env.REACT_APP_SPRIG_ENV !== "production"
		? `products/sprig-reading-3-0/?attribute_plan=${type}`
		: `products/sprig-reading/?attribute_plan=${type}`;
};

export const getCustomizedGradeItems = (grades) => {
	const prefix = "reading.customized_grades";
	const customizedGradeItems = [
		{
			label: i18n.t(`${prefix}.k2`),
			id: grades.find((grade) => grade.slug === "k2").id,
		},
		{
			label: i18n.t(`${prefix}.k3`),
			id: grades.find((grade) => grade.slug === "k3").id,
		},
		{
			label: i18n.t(`${prefix}.k4`),
			id: grades.find((grade) => grade.slug === "k4").id,
		},
		{
			label: i18n.t(`${prefix}.k5`),
			id: grades.find((grade) => grade.slug === "k5").id,
		},
		{
			label: i18n.t(`${prefix}.g1`),
			id: grades.find((grade) => grade.slug === "g1").id,
		},
		{
			label: i18n.t(`${prefix}.g2`),
			id: grades.find((grade) => grade.slug === "g2").id,
		},
		{
			label: i18n.t(`${prefix}.g3`),
			id: grades.find((grade) => grade.slug === "g3").id,
		},
	];
	return customizedGradeItems;
};

export const getCounts = (
	topic,
	skills,
	classroomCharts,
	skillMastered,
	skillsNeedMorePractice,
	skillsNeedExplicitInstruction
) => {
	const skillsUnderCurrentTopic = skills.filter(
		(skill) => skill.parent_parent === topic.id
	);
	skillsUnderCurrentTopic.forEach((skill) => {
		if (classroomCharts?.[topic.id]) {
			const studentIds = Object.keys(classroomCharts[topic.id]);
			studentIds.forEach((studentId) => {
				const currentStudentOnSkill =
					classroomCharts[topic.id][studentId].charts[skill.id]?.value;
				if (currentStudentOnSkill === 3) {
					skillMastered += 1;
				} else if (currentStudentOnSkill === 2) {
					skillsNeedMorePractice += 1;
				} else if (currentStudentOnSkill === 1) {
					skillsNeedExplicitInstruction += 1;
				}
			});
		}
	});

	return {
		skillMastered,
		skillsNeedMorePractice,
		skillsNeedExplicitInstruction,
	};
};

export const getSkillsPercentage = (skillsCount) => {
	const getPercentage = (count, total) => {
		if (count) {
			const proportion = (count / total) * 100;
			if (proportion < 1) {
				return 1;
			}
			return Math.floor((count / total) * 100);
		}
		return 0;
	};

	const {
		totalSkills,
		skillMastered,
		skillsNeedMorePractice,
		skillsNeedExplicitInstruction,
	} = skillsCount;

	const percentageMaster = getPercentage(skillMastered, totalSkills);
	const percentageNeedMorePractice = getPercentage(
		skillsNeedMorePractice,
		totalSkills
	);
	const percentageExplicitInstruction = getPercentage(
		skillsNeedExplicitInstruction,
		totalSkills
	);

	return {
		totalSkills,
		skillMastered: `${percentageMaster}%`,
		skillsNeedMorePractice: `${percentageNeedMorePractice}%`,
		skillsNeedExplicitInstruction: `${percentageExplicitInstruction}%`,
		skillsNotAssessedYet: `${
			100 -
			percentageMaster -
			percentageNeedMorePractice -
			percentageExplicitInstruction
		}%`,
	};
};
