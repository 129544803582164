import "../../../../Stylesheets/css/scss/Components/Portal/Public/Dropdown/DropdownWrapper.css";

import React, {Component} from "react";

import {DropdownItem, DropdownSingleItem, OutsideClickHandler} from "./index";

interface DropdownWrapperProps {
	items: DropdownSingleItem[];
	onSelect: (item: DropdownSingleItem) => void;
}

export class DropdownWrapper extends Component<DropdownWrapperProps> {
	state = {
		open: false,
	};

	onSelectItem = (datum: DropdownSingleItem) => {
		const {onSelect} = this.props;
		this.setState({open: false}, () => {
			if (onSelect) onSelect(datum);
		});
	};

	toggleMenu = () => {
		this.setState({open: !this.state.open});
	};

	onOutsideClick = () => {
		this.setState({open: false});
	};

	render() {
		const {items, children} = this.props;
		return (
			<div className="dropdown-wrapper">
				<OutsideClickHandler onOutsideClick={this.onOutsideClick}>
					<div className="dropdown-selector" onClick={this.toggleMenu}>
						{children}
					</div>
					{this.state.open && (
						<div className="dropdown-wrapper-content">
							<div className="dropdown-wrapper-menu">
								{items.map((item) => (
									<DropdownItem
										key={`dropdown_item_${item.id}`}
										item={item}
										onSelectItem={this.onSelectItem}
									/>
								))}
							</div>
						</div>
					)}
				</OutsideClickHandler>
			</div>
		);
	}
}
