import i18n from "i18next";
import React from "react";

import {
	MATH_ASSESSMENT_TASK,
	PROVINCE_SLUG,
} from "../../../../../../../Scripts/Constants";
import {ResultsPolarChart} from ".";

interface Levels {
	rubric: {[rubricName: string]: RubricLevel};
}

interface RubricLevel {
	[nameOfConcept: string]: number;
}

interface NavigationTabModal {
	page: number;
	navTitle: string;
}

export const MathResultsActivitiesHome = ({
	levels,
	studentName,
	handleNavClick,
	navTabs,
	client,
}: {
	levels: Levels;
	studentName: string;
	handleNavClick: (event: React.MouseEvent<HTMLDivElement>) => void;
	navTabs: NavigationTabModal[];
	client: string;
}) => {
	const isONClient = client === PROVINCE_SLUG.ON;
	const visualizationLevel = getTaskLevel(
		levels.rubric[MATH_ASSESSMENT_TASK.VISUALIZATION]
	);
	const reasoningLevel = getTaskLevel(
		levels.rubric[MATH_ASSESSMENT_TASK.REASONING]
	);
	const communicationLevel = getTaskLevel(
		levels.rubric[MATH_ASSESSMENT_TASK.COMMUNICATION]
	);
	const makingConnectionsLevel = getTaskLevel(
		levels.rubric[MATH_ASSESSMENT_TASK.MAKING_CONNECTIONS]
	);
	const mentalMathLevel = getTaskLevel(
		levels.rubric[MATH_ASSESSMENT_TASK.MENTAL_MATH_AND_ESTIMAT]
	);
	const problemSolvingLevel = getTaskLevel(
		levels.rubric[MATH_ASSESSMENT_TASK.PROBLEM_SOLVING]
	);
	const mathLabelsPrefix = `assessments.math.activities.process`;
	const data = {
		datasets: [
			{
				data: [
					visualizationLevel,
					reasoningLevel,
					communicationLevel,
					makingConnectionsLevel,
					mentalMathLevel,
					problemSolvingLevel,
				],
				backgroundColor: [
					"#00962B",
					"#005F9B",
					"#009D9E",
					"#D53A3C",
					"#F38111",
					"#8B3967",
				],
			},
		],
		labels: [
			i18n.t(
				`${mathLabelsPrefix}.${
					isONClient ? `temp-on-reflecting` : `visualization`
				}.title`
			),
			i18n.t(
				`${mathLabelsPrefix}.${
					isONClient ? `temp-on-reasoning-proving` : `reasoning`
				}.title`
			),
			i18n.t(
				`${mathLabelsPrefix}.${
					isONClient ? `temp-on-communicating` : `communication-math`
				}.title`
			),
			i18n.t(
				`${mathLabelsPrefix}.${
					isONClient ? `temp-on-connecting` : `connections`
				}.title`
			),
			i18n.t(
				`${mathLabelsPrefix}.${
					isONClient ? `temp-on-representing` : `mental-math-and-estimation`
				}.title`
			),
			i18n.t(
				`${mathLabelsPrefix}.${
					isONClient ? `temp-on-problem-solving` : `problem-solving`
				}.title`
			),
		],
	};
	const mathProcessesLabel = i18n.t("admin.math_processes");
	return (
		<div className="math-results row p-3">
			<div className="math-results-level col-md-6">
				<h2>{mathProcessesLabel}</h2>
				<small>
					{i18n.t(
						"assessments.math.activities.result.student_completed_interactive_activities",
						{student: studentName.split(" ")[0]}
					)}
				</small>
				<div className="activities-nav-tabs row">
					{navTabs.map((tab: NavigationTabModal) => (
						<NavigationTab
							key={tab.navTitle}
							tab={tab}
							handleNavClick={handleNavClick}
						/>
					))}
				</div>
			</div>
			<div className="math-results-chart col-md-6">
				<ResultsPolarChart data={data} />
			</div>
		</div>
	);
};

const NavigationTab = ({
	tab,
	handleNavClick,
}: {
	tab: NavigationTabModal;
	handleNavClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}) => (
	<div className="col-4">
		<div
			id={tab.page.toString()}
			className="activities-nav-tab text-center text-white rounded"
			onClick={handleNavClick}
		>
			<small id={tab.page.toString()} onClick={handleNavClick}>
				{tab.navTitle}
			</small>
		</div>
	</div>
);

const getTaskLevel = (levels: RubricLevel) => {
	const averageLevel = Math.floor(
		Object.values(levels).reduce((a, b) => a + b, 0) /
			Object.values(levels).length
	);
	return averageLevel;
};
