import React from "react";
import {Trans} from "react-i18next";

import {AUTOMATION_TEST_ID} from "../../../../../Scripts/Constants";

export const ActivityRecommendation = ({data}) => (
	<div className="col-10">
		<small
			className="nun-semibold"
			name={AUTOMATION_TEST_ID.PUBLIC.COMPONENTS.NOTIFICATION_CONTENT}
		>
			<Trans i18nKey="phrase.activity_recommendation">
				{[
					<span key="educatorName" className="nun-bold">
						{{educatorName: data.source_name}}
					</span>,
					<span key="activityName" className="nun-bold">
						{{activityName: data.activity_title}}
					</span>,
					<span key="studentName" className="nun-bold">
						{{studentName: data.student_name}}
					</span>,
				]}
			</Trans>
		</small>
	</div>
);
