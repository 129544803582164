import "./ActivitiesNavFilterListIcon.scss";

import React, {Fragment} from "react";

import {AUTOMATION_TEST_ID, PORTALS} from "../../../../Scripts/Constants";
import {DropdownWrapper, Icon} from "../../../Public";
import {ActivitiesFilter} from "./ActivitiesFilter";

export const ActivitiesNavFilterListIcon = ({
	onClickListIcon,
	isList,
	isOnRecommended,
	portal,
	student = {id: 0},
	activity,
	onReadingLearningActivities = false,
}) => {
	const getFilterIcon = () => {
		return (
			<button
				className={`no-default-style navigation-option filter-icon${
					isOnRecommended ? "" : " filter-disabled"
				}`}
				id={AUTOMATION_TEST_ID.PUBLIC.DROPDOWN.ACTIVITY_FILTER.BUTTON}
			>
				<Icon type="faFilter" />
			</button>
		);
	};

	const {tagging, places, duration} = activity;

	const filterCategories = {
		places,
		duration,
		difficulties: tagging.difficulties,
	};

	const dropdown = {
		menuItems: (closeMenuEvent) => [
			<ActivitiesFilter
				filterCategories={filterCategories}
				filters={activity.filters}
				closeMenuEvent={closeMenuEvent}
				portal={portal}
				{...(portal === PORTALS.CAREGIVER ? {currentStudent: student} : {})}
				onReadingLearningActivities={onReadingLearningActivities}
			/>,
		],
	};

	return (
		<Fragment>
			<DropdownWrapper {...dropdown} position="sw" disabled={!isOnRecommended}>
				{getFilterIcon()}
			</DropdownWrapper>
			{/* <div
				className="navigation-option list-toggle-icon"
				onClick={onClickListIcon}
				id={
					AUTOMATION_TEST_ID.READING.CLASSROOM.LEARNING_ACTIVITY
						.LIST_VIEW_TOGGLE_BUTTON
				}
			>
				<Icon type={`${isList ? "faTh" : "faThList"}` as IconType} />
			</div> */}
		</Fragment>
	);
};
