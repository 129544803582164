import * as ActionTypes from "../Redux/ActionTypes";
import {getData} from "../Scripts/Axios/axiosRequests";
import {
	ACTIVITY_MONITOR_ACTIONS,
	REPORT_ID,
	REPORT_TYPE,
} from "../Scripts/Constants";
import {get_url} from "../Scripts/Fetch";
import {ActivityMonitorActions, LoaderActions} from "./index";
import {getSubmissionQueryString} from "./SchoolActions";

const returnURLForReport = ({
	report,
	id,
	type,
	terms,
	domains,
	direct,
	tags,
	milestone_count,
	period,
	start,
	end,
}) => {
	const url = `${get_url()}wp-json/sprig/v2/reports?report=${report}&id=${id}&type=${type}${
		terms ? `&terms=${terms}` : ``
	}${domains ? `&domains=${domains}` : ``}${direct ? `&direct=true` : ``}${
		tags ? `&tags=${tags}` : ``
	}${milestone_count ? `&milestone_count=${milestone_count}` : ``}${
		start ? `&start=${start}` : ``
	}${end ? `&end=${end}` : ``}${period ? `&period=${period}` : ``}`;
	return url;
};

export const getReport = (options, handleFunction = {}) => {
	return (dispatch) => {
		getData(returnURLForReport(options))
			.then((response) => {
				if (handleFunction.handling) {
					handleFunction.handling.handleResponse(response);
				} else
					dispatch({
						type: ActionTypes.REPORT_GET,
						payload: {
							response,
							report: options.report,
							type: options.type,
							direct: options.direct,
						},
					});
			})
			.catch((error) => {
				console.error(`Error: ${error.message}`);
			});
	};
};

export const getScreenTimeReport = (options) => {
	return (dispatch) => {
		const queryString = getSubmissionQueryString(options);
		const url = `${get_url()}wp-json/sprig/v2/classStudentsSessions${queryString}`;
		LoaderActions.addToQueue(ActionTypes.REPORT_GET_STUDENT_SCREEN_TIME_REPORT);
		getData(url)
			.then((response) => {
				dispatch({
					type: ActionTypes.REPORT_GET_STUDENT_SCREEN_TIME_REPORT,
					payload: {
						response,
						report: REPORT_ID.SCREENTIME,
						type: REPORT_TYPE.CLASS,
					},
				});
			})
			.catch((error) => {
				console.error(`Error: ${error.message}`);
			});
	};
};

export const getReportSingle = (options) => {
	return (dispatch) => {
		LoaderActions.addToQueue(ActionTypes.REPORT_GET_SINGLE);
		getData(returnURLForReport(options), true)
			.then((response) => {
				if (response.status === 200) {
					dispatch({
						type: ActionTypes.REPORT_GET_SINGLE,
						payload: {
							response: [...response.data],
							report: options.report,
							type: options.type,
							direct: options.direct,
							tag: options.tag,
						},
					});
				} else {
					dispatch({
						type: ActionTypes.REPORT_GET_SINGLE,
						payload: {
							response: [],
							report: options.report,
							type: options.type,
							direct: options.direct,
							tag: options.tag,
						},
					});

					ActivityMonitorActions.addToQueue({
						type: ACTIVITY_MONITOR_ACTIONS.ERROR,
						title: `Failed to load report: ${options.type} ${options.report}`,
						message: response.data.message,
					});
				}
			})
			.catch((error) => {
				console.error(`Error: ${error.message}`);
			});
	};
};

export const getAllReports = (reports, portal) => {
	const actionType = ActionTypes.REPORT_GET_ALL;
	return (dispatch) => {
		LoaderActions.addToQueue(actionType);
		Promise.all(reports.map((report) => getData(returnURLForReport(report))))
			.then((results) => {
				const resolvedReports = results.map((result, resultsIndex) => {
					const report = reports[resultsIndex];
					return {
						response: result,
						report: report.report,
						type: report.type,
						direct: report.direct,
						domains: report.domains,
					};
				});

				dispatch({
					type: actionType,
					payload: {reports: resolvedReports, portal},
				});
			})
			.catch((error) => {
				console.error(`Error: ${error.message}`);
			});
	};
};

export const setDomain = (domain) => {
	return {
		type: ActionTypes.REPORT_SET_DOMAIN,
		payload: domain,
	};
};
