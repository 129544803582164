import React from "react";
import {Trans} from "react-i18next";
import {Link} from "react-router-dom";

import {ModalActions, NotificationActions} from "../../../../Actions";
import {
	AUTOMATION_TEST_ID,
	MODAL_TYPES,
	PORTALS,
} from "../../../../Scripts/Constants";
import {
	getNotificationIcon,
	NOTIFICATION_TYPE,
} from "../../../../Scripts/Notifications";
import {Icon} from "../../../Public";
import {BadgeIcon} from "../Rewards/Badges/BadgeIcon";
import {
	ActivityRecommendation,
	IssueOpened,
	IssueResolved,
	NewBadgeEarned,
	TimeReceived,
} from "./Widgets";

export const NotificationItem = ({
	notification,
	student,
	filters,
	closeMenuEvent,
}) => {
	const notificationIcon = getNotificationIcon(
		notification.type,
		notification.data.request_type
	);
	const activityRecommendation =
		notification.type === NOTIFICATION_TYPE.activityRecommendation ||
		notification.type === NOTIFICATION_TYPE.activityUnrecommendation;
	const handleClick = () => {
		if (activityRecommendation) {
			NotificationActions.hideShowNotificationTab(false);
			if (closeMenuEvent) closeMenuEvent();
			if (student.student_id === notification.data.student) {
				ModalActions.showModal(
					{
						open: true,
						student: notification.data.student,
						size: "md",
						role: PORTALS.CAREGIVER,
						keepInWindow: true,
						data: {
							filters,
							activityId: notification.data.activity,
						},
					},
					MODAL_TYPES.ACTIVITY_MODAL_NEW
				);
			}
		}
	};

	const findNotificationIcon = () => {
		switch (notification.type) {
			case NOTIFICATION_TYPE.studentBadgeEarned:
				return (
					<BadgeIcon
						iconName={notification.data.badge}
						tier={notification.data.tier}
						earned={true}
					/>
				);
			case NOTIFICATION_TYPE.activityRecommendation:
				return <Icon type="sprigLeaf" />;
			default:
				return (
					<Icon
						type={notificationIcon}
						size="lg"
						className="edu-text-primary"
					/>
				);
		}
	};
	return (
		<ConditionalWrapper
			condition={
				activityRecommendation &&
				student &&
				student.student_id !== notification.data.student
			}
			wrapper={(children) => (
				<Link
					style={{color: "#000"}}
					className="no-default-link-style"
					to={`/portal/student/${notification.data.student}/domain/${notification.data.domain}/home/activity/${notification.data.activity}`}
				>
					{children}
				</Link>
			)}
		>
			<div
				className="notification-item"
				name="notification-card"
				onClick={handleClick}
			>
				<div className="notification-card-content">
					<div className="notification-card-content-icon">
						{findNotificationIcon()}
					</div>
					{(notification.type === NOTIFICATION_TYPE.activityRecommendation ||
						notification.type ===
							NOTIFICATION_TYPE.activityUnrecommendation) && (
						<ActivityRecommendation data={notification.data} />
					)}
					{notification.type === NOTIFICATION_TYPE.studentBadgeEarned && (
						<NewBadgeEarned notification={notification} />
					)}
					{notification.type === NOTIFICATION_TYPE.adminRequestClosed && (
						<IssueResolved notification={notification} />
					)}
					{notification.type === NOTIFICATION_TYPE.adminRequestOpened && (
						<IssueOpened notification={notification} />
					)}
					{notification.type === "activity_completion" && (
						<div className="col-10">
							<small
								className="nun-semibold"
								name={AUTOMATION_TEST_ID.PUBLIC.COMPONENTS.NOTIFICATION_CONTENT}
							>
								<Trans i18nKey="phrase.recommend_activity">
									{[
										<span key="studentName" className="nun-bold">
											{{student: notification.data.student_name}}
										</span>,
										<span key="activityName" className="nun-bold">
											{{activity: notification.data.activity_title}}
										</span>,
										<span key="teacherName" className="nun-bold">
											{{teacher: notification.data.reporter_name}}
										</span>,
									]}
								</Trans>
							</small>
						</div>
					)}
				</div>
				<TimeReceived notification={notification} />
			</div>
		</ConditionalWrapper>
	);
};

const ConditionalWrapper = ({condition, wrapper, children}) =>
	condition ? wrapper(children) : children;
