import "./ReadingToast.scss";

import i18n from "i18next";
import React, {useEffect} from "react";

import {ActivityMonitorActions} from "../../../../Actions";
import {READING_TOAST_TYPE} from "../../../../Scripts/Constants";
import {Icon} from "../../../Public";

interface ReadingToastProps {
	type: string;
	data: {
		classroomName?: string;
		userName?: string;
		schoolName?: string;
		skillName?: string;
		topicPrimaryColour: string;
		topicName?: string;
	};
	id: string;
}

export const ReadingToast = ({type, data, id}: ReadingToastProps) => {
	useEffect(() => {
		const interval = setInterval(() => {
			ActivityMonitorActions.removeFromQueue(id);
			clearInterval(interval);
		}, 4000);

		return () => clearInterval(interval);
	}, [id]);

	const onRemoveToast = () => {
		ActivityMonitorActions.removeFromQueue(id);
	};

	const getIconType = () => {
		if (type !== READING_TOAST_TYPE.ADD_CLASSROOM) {
			switch (type) {
				case READING_TOAST_TYPE.INVITE_TEACHER:
				case READING_TOAST_TYPE.REMOVE_TEACHER:
					return <Icon type="faChalkBoardUser" />;
				case READING_TOAST_TYPE.ADD_STUDENT:
				case READING_TOAST_TYPE.REMOVE_STUDENT:
					return <Icon type="sprigStudent" size="2x" />;
				case READING_TOAST_TYPE.ASSESSING_SKILL.MASTERED:
					return <Icon type="skillMastered" />;
				case READING_TOAST_TYPE.ASSESSING_SKILL.NEEDS_PRACTICE:
					return <Icon type="needsMore" />;
				case READING_TOAST_TYPE.ASSESSING_SKILL.NEEDS_INSTRUCTION:
					return <Icon type="needsExplicit" />;
				case READING_TOAST_TYPE.ASSESSING_SKILL.NOT_YET_TO_BE_ASSESSED:
					return <Icon type="notYetAssessed" />;
				default: {
					console.log(`Unsupported action type: ${type}`);
					return null;
				}
			}
		} else {
			return null;
		}
	};

	const getMessage = () => {
		const {userName, skillName, topicName, classroomName} = data;
		const skillToastData = {
			userName,
			skillName,
			topicName,
		};
		const userActionToastData = {
			userName,
			classroomName,
		};
		switch (type) {
			case READING_TOAST_TYPE.INVITE_TEACHER: {
				return i18n.t(
					"reading.phrase.toast.invited_action",
					userActionToastData
				);
			}
			case READING_TOAST_TYPE.REMOVE_STUDENT:
			case READING_TOAST_TYPE.REMOVE_TEACHER: {
				return i18n.t(
					"reading.phrase.toast.removed_action",
					userActionToastData
				);
			}
			case READING_TOAST_TYPE.ADD_STUDENT:
			case READING_TOAST_TYPE.ADD_CLASSROOM: {
				const {userName, classroomName, schoolName} = data;
				return i18n.t("reading.phrase.toast.added_action", {
					userClassroomName:
						type === READING_TOAST_TYPE.ADD_STUDENT ? userName : classroomName,
					classroomSchoolName:
						type === READING_TOAST_TYPE.ADD_STUDENT
							? classroomName
							: schoolName,
				});
			}
			case READING_TOAST_TYPE.ASSESSING_SKILL.MASTERED: {
				return i18n.t(
					"reading.phrase.toast.assessing.mastered",
					skillToastData
				);
			}
			case READING_TOAST_TYPE.ASSESSING_SKILL.NEEDS_PRACTICE: {
				return i18n.t(
					"reading.phrase.toast.assessing.needs_practice",
					skillToastData
				);
			}
			case READING_TOAST_TYPE.ASSESSING_SKILL.NEEDS_INSTRUCTION: {
				return i18n.t(
					"reading.phrase.toast.assessing.needs_instruction",
					skillToastData
				);
			}
			case READING_TOAST_TYPE.ASSESSING_SKILL.NOT_YET_TO_BE_ASSESSED: {
				return i18n.t(
					"reading.phrase.toast.assessing.not_assessed",
					skillToastData
				);
			}
			default: {
				console.log(`Unsupported action type: ${type}`);
				return "";
			}
		}
	};
	let currentTopicColorProperty = {};
	currentTopicColorProperty["--backgroundColor"] = data.topicPrimaryColour;

	return (
		<div
			className={`reading-toast toast-fade ${type}`}
			style={currentTopicColorProperty}
		>
			{getIconType()}
			<div className="toast-message">{getMessage()}</div>
			<div className="toast-close-button" onClick={onRemoveToast}>
				<Icon type="faTimesCircle" size="lg" />
			</div>
		</div>
	);
};
