import "./StudentMilestoneFilters.scss";

import htmlToText from "html-to-text";
import i18n from "i18next";
import React, {Fragment, useState} from "react";
import {Link} from "react-router-dom";

import * as ActionTypes from "../../../../../Redux/ActionTypes";
import {getCurrentDomainItems} from "../../../../../Scripts/Activities";
import {
	ACTIVITY_TAG,
	ORAL_LANGUAGE_RUBRIC,
	OUTCOME_LEVEL,
} from "../../../../../Scripts/Constants";
import {getMonthName} from "../../../../../Scripts/Date";
import {DOMAIN_ORAL_LANGUAGE} from "../../../../../Scripts/Domain";
import {
	MilestoneSubitem,
	MilestoneTerm,
} from "../../../../../Scripts/Interfaces";
import {CompetencyTaxonomyItem} from "../../../../../Scripts/PublicInterfaces";
import {Icon} from "../../../../Public";
import {Loader} from "../../../PublicComponents";
import {Dropdown, DropdownItemModel} from "../../index";
import {ActivityInfoTag} from "../../Modal/Modals/Activity/ActivityModalWidget";

interface StudentMilestoneFiltersProps {
	onSelectStudent: (student: Student | undefined) => void;
	onSelectMilestone: (data: any) => void;
	onSelectRubric: (data: any) => void;
	onRemoveStudent: () => void;
	onSelectReset: () => void;
	onOpenRecommendActivitiesOnFilters: () => void;

	classroomDomain: number;
	students: Student[];
	milestones: MilestoneTerm[];
	selections: {
		milestone: number | string;
		selectedStudents: Student[];
		rubric: string | null;
	};
	studentMilestones: MilestoneSubitem[];
	competencies: CompetencyTaxonomyItem[];
	isHolisticMath?: boolean;
	activity?: {};
	classroom: {};
}

interface Student {
	name: string;
	id: number;
}

export const StudentMilestoneFilters = ({
	classroomDomain,
	onSelectStudent,
	onSelectMilestone,
	onSelectRubric,
	onRemoveStudent,
	onSelectReset,
	selections,
	students,
	milestones,
	onOpenRecommendActivitiesOnFilters,
	studentMilestones,
	competencies,
	isHolisticMath,
	activity,
	classroom,
}: StudentMilestoneFiltersProps) => {
	const [showRecommendedActivities, setShowRecommendedActivities] =
		useState(false);
	const {milestone, selectedStudents, rubric} = selections;
	const milestoneItems = milestones?.map((milestoneItem) => ({
		label: getMonthName(milestoneItem.start),
		id: milestoneItem.milestone_id,
	}));
	const currentMilestoneItem = milestoneItems?.find(
		(milestoneItem) => milestoneItem.id === milestone
	);
	const studentsHaveMilestoneData = students.filter((student) => {
		const milestoneForCurrentStudent = studentMilestones.find(
			(studentMilestone) => studentMilestone.id === student.id
		);
		if (milestoneForCurrentStudent) {
			const studentCurrentMilestone =
				milestoneForCurrentStudent.milestones.find(
					(currentMilestone) => currentMilestone.milestone_id === milestone
				);
			if (studentCurrentMilestone) return student;
			return null;
		}
		return null;
	});

	const studentItems = studentsHaveMilestoneData.map((student) => ({
		label: student.name,
		id: student.id,
	}));
	const allStudentsItem = {id: 0, label: i18n.t("admin.all_students")};
	studentItems.unshift(allStudentsItem);
	const selectedStudentItem = selectedStudents?.length
		? {label: selectedStudents[0].name, id: selectedStudents[0].id}
		: allStudentsItem;
	const onClickStudent = (item: DropdownItemModel) => {
		if (!item.id) onRemoveStudent();
		else onSelectStudent(students.find((student) => student.id === item.id));
	};

	const rubricItems =
		classroomDomain === DOMAIN_ORAL_LANGUAGE
			? Object.keys(ORAL_LANGUAGE_RUBRIC).map((key) => ({
					id: ORAL_LANGUAGE_RUBRIC[key],
					label: i18n.t(`rubric.oral_language.${ORAL_LANGUAGE_RUBRIC[key]}`),
			  }))
			: getCurrentDomainItems(competencies, classroomDomain).map(
					(competency) => {
						return {
							id: competency.id,
							label: competency.name.replace(/&amp;/g, "&"),
						};
					}
			  );

	rubricItems.unshift({id: 0, label: i18n.t("activity_tags.all")});
	const selectedRubricItem = rubric
		? rubricItems.find((rubricItem) => rubricItem.id === rubric)
		: {label: i18n.t("activity_tags.all"), id: null};
	const onClickRecommendActivitiesOnFilters = () => {
		if (isHolisticMath) {
			setShowRecommendedActivities(!showRecommendedActivities);
		} else onOpenRecommendActivitiesOnFilters();
	};

	return (
		<div className="student-milestone-filters">
			<div className="student-milestone-filters-group">
				<div className="filters-group-label">
					{i18n.t("admin.classroom_student")}
				</div>
				<Dropdown
					items={studentItems}
					selectedItem={selectedStudentItem}
					onSelect={onClickStudent}
				/>
			</div>
			<div className="student-milestone-filters-group">
				<div className="filters-group-label">{i18n.t("chart.milestone")}</div>
				<Dropdown
					items={milestoneItems}
					selectedItem={currentMilestoneItem}
					onSelect={onSelectMilestone}
				/>
			</div>
			{isHolisticMath ? null : (
				<div className="student-milestone-filters-group">
					<div className="filters-group-label">{i18n.t("common.rubric")}</div>
					<Dropdown
						items={rubricItems}
						selectedItem={selectedRubricItem}
						onSelect={onSelectRubric}
					/>
				</div>
			)}
			<div className="student-milestone-filters-actions">
				<button
					className="btn edu-btn-primary"
					onClick={onClickRecommendActivitiesOnFilters}
				>
					<Icon type="faLeaf" size="lg" />
				</button>
				<button className="btn btn-secondary" onClick={onSelectReset}>
					{i18n.t("actions.reset")}
				</button>
				{showRecommendedActivities ? (
					<RecommendedActivities activity={activity} classroom={classroom} />
				) : null}
			</div>
		</div>
	);
};

export const RecommendedActivities = ({activity, classroom}) => {
	return (
		<div className="recommended-activities">
			<div className="recommended-activities-title">
				{i18n.t("admin.recommended_activities")}
			</div>
			<div className="current-recommended-activities">
				<Loader.Wrap actionTypes={[ActionTypes.ACTIVITY_GET_ALL]}>
					<Loader.HideContent>
						{activity.activities.map((activityItem) => (
							<RecommendedActivity
								key={activityItem.id}
								activity={activityItem}
								places={activity.places}
								duration={activity.duration}
								learningOutcomes={activity.tagging.learning_outcomes}
								classroom={classroom}
							/>
						))}
					</Loader.HideContent>
					<Loader.Spinner />
				</Loader.Wrap>
			</div>
		</div>
	);
};

const RecommendedActivity = ({
	activity,
	places,
	duration,
	learningOutcomes,
	classroom,
}) => {
	const infoTags = [
		{
			title: i18n.t("admin.where"),
			content: activity.places.map((place) => {
				return i18n.t(
					`activity_taxonomy_place.${
						places.find((placeTaxonomy) => placeTaxonomy.id === place).slug
					}`
				);
			}),
		},
		{
			title: i18n.t("activity_taxonomies.duration"),
			content: activity.durations.map((durationItem) => {
				return i18n.t(
					`activity_taxonomy_duration.${
						duration.find(
							(durationTaxonomy) => durationTaxonomy.id === durationItem
						).slug
					}`
				);
			}),
		},
		{
			title: i18n.t("holistic_math_prototype.outcome"),
			content: activity[ACTIVITY_TAG[OUTCOME_LEVEL.ONE]].map((outcome) => {
				return htmlToText.fromString(
					learningOutcomes.find(
						(outcomeTaxonomy) => outcomeTaxonomy.id === outcome
					).name
				);
			}),
		},
	];

	return (
		<div className="recommended-activity">
			<Link
				to={{
					pathname: `/portal/classroom/${classroom.id}/learningactivities/recommended`,
					state: {activity},
				}}
				className="no-link-decoration"
			>
				<div className="activity-title">
					{htmlToText.fromString(activity.title.rendered)}
				</div>
				<div className="activity-infos">
					<Fragment>
						{infoTags.map((item) => (
							<ActivityInfoTag item={item} key={item.title} />
						))}
					</Fragment>
				</div>
			</Link>
		</div>
	);
};
