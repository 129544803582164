import i18n from "i18next";
import React from "react";

import {AssetManager} from "../../../../../../../Scripts/AssetManager";
import {Icon} from "../../../../../../Public";
import {MathWhatWhereWhoQuestion} from "./";

interface WhoDoYouDoMathWithProps {
	questions: MathWhatWhereWhoQuestion[];
}

export const WhoDoYouDoMathWith = ({questions}: WhoDoYouDoMathWithProps) => {
	const whoDoYouDoMathWith = questions.find(
		(question) => question.question.id === 12030
	);
	const assets = AssetManager.ASSETS;
	const firstRow = [
		{id: 1, src: assets.ASSESSMENT.ROLES.MOM, caption: "Mom", value: "mom"},
		{id: 2, src: assets.ASSESSMENT.ROLES.DAD, caption: "Dad", value: "dad"},
		{
			id: 3,
			src: assets.ASSESSMENT.ROLES.GRANDPARENTS,
			caption: "Grandparents",
			value: "grand",
		},
		{
			id: 4,
			src: assets.ASSESSMENT.ROLES.SIBLINGS,
			caption: "Siblings",
			value: "siblings",
		},
	];
	const secondRow = [
		{
			id: 1,
			src: assets.ASSESSMENT.ROLES.AUNT_UNCLE,
			caption: "Aunt/Uncle",
			value: "aunt",
		},
		{
			id: 2,
			src: assets.ASSESSMENT.ROLES.FRIENDS,
			caption: "Friends",
			value: "friends",
		},
		{
			id: 3,
			src: assets.ASSESSMENT.ROLES.COUSINS,
			caption: "Cousins",
			value: "cousins",
		},
		{
			id: 4,
			src: assets.ASSESSMENT.ROLES.TEACHER,
			caption: "Teacher",
			value: "teacher",
		},
	];
	return (
		<div className="who-tab">
			<h5>{i18n.t("assessments.math.questions.who_do_with")}</h5>
			<div className="container">
				{whoDoYouDoMathWith &&
					[firstRow, secondRow].map((row, index) => (
						<div className="row justify-content-between" key={index}>
							{row.map((role) => (
								<WhoPicture
									role={role}
									key={role.id}
									whoDoYouDoMathWithValues={whoDoYouDoMathWith.values}
								/>
							))}
						</div>
					))}
			</div>
		</div>
	);
};

interface WhoPictureProps {
	role: {
		value: string;
		src: string;
		caption: string;
	};
	whoDoYouDoMathWithValues: string[];
}

const WhoPicture = ({role, whoDoYouDoMathWithValues}: WhoPictureProps) => (
	<div className="who-picture">
		<h4 className="do-math-check-mark">
			<Icon
				type="faCheckCircle"
				size="lg"
				className={`${
					whoDoYouDoMathWithValues.includes(role.value)
						? "text-primary"
						: "text-white"
				}`}
			/>
		</h4>
		<object className="who-picture-img" type="image/svg+xml" data={role.src}>
			antle's {role.caption}
		</object>
		<h5 className="who-picture-caption">{role.caption}</h5>
	</div>
);
