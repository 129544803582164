import {getData, getDataNonJSON} from "./Axios/axiosRequests";
import {
	ASSESSMENT_ID,
	FILE_EXTENSIONS,
	MATH_FILE_NAME,
	MODAL_TYPES,
} from "./Constants";
import {DOMAIN_MATH, DOMAIN_ORAL_LANGUAGE} from "./Domain";
import {get_url} from "./Fetch";
import {newRegex, TRANSLATION_CODES} from "./SurveyUtilities";

export function getMostRecentSubmission(submissions, assessment_id) {
	const new_array = submissions.filter((submission) => {
		return submission.assessment_id === assessment_id;
	});
	const mostRecent = Math.max.apply(
		Math,
		new_array.map((submission) => {
			return submission.uploaded;
		})
	);
	const submission = new_array.find((obj) => {
		return obj.uploaded === mostRecent;
	});
	return submission;
}

export async function getStorytellingAudio(submissionId) {
	const url = `${get_url()}wp-json/sprig/v2/submissions/${submissionId}/audio`;
	const data = await getDataNonJSON(url);
	const blob = new Blob([data], {type: "audio/mp4"});
	return new Promise((resolve) => {
		var reader = new FileReader();
		reader.readAsDataURL(blob);
		reader.onload = function () {
			resolve(reader.result);
		};
	});
}

export async function getQuestions(submissionId) {
	const url = `${get_url()}wp-json/sprig/v2/responses?submission=${submissionId}&context=view`;
	const questions = await getData(url);
	return questions ? questions : null;
}

export async function getSubmissions(
	studentId,
	termId,
	assessmentId,
	domainId
) {
	const url = `${get_url()}wp-json/sprig/v2/submissions?assessed=${studentId}&term=${termId}&only_most_recent=true&context=view`;
	const submissions = await getData(url);
	if (Array.isArray(submissions)) {
		const mostRecentStudentSubmission = getMostRecentSubmission(
			submissions,
			assessmentId
		);
		if (mostRecentStudentSubmission)
			return domainId === DOMAIN_ORAL_LANGUAGE
				? mostRecentStudentSubmission.id
				: mostRecentStudentSubmission;
	}
	return null;
}

export const getMathResultFiles = async (submission) => {
	const berry_student_sort_audio = await getFile(
		submission.id,
		MATH_FILE_NAME.BERRY_STUDENT_SORT_AUDIO,
		FILE_EXTENSIONS.MP4
	);
	const berry_app_sort_audio = await getFile(
		submission.id,
		MATH_FILE_NAME.BERRY_APP_SORT_AUDIO,
		FILE_EXTENSIONS.MP4
	);
	const berry_connections_audio = await getFile(
		submission.id,
		MATH_FILE_NAME.BERRY_CONNECTIONS_AUDIO,
		FILE_EXTENSIONS.MP4
	);
	const crossing_1_image = await getFile(
		submission.id,
		MATH_FILE_NAME.CROSSING_1_IMAGE,
		FILE_EXTENSIONS.PNG
	);
	const crossing_2_image = await getFile(
		submission.id,
		MATH_FILE_NAME.CROSSING_2_IMAGE,
		FILE_EXTENSIONS.PNG
	);
	const shape_1_image = await getFile(
		submission.id,
		MATH_FILE_NAME.SHAPE_1_IMAGE,
		FILE_EXTENSIONS.PNG
	);
	const berry_student_sort_image = await getFile(
		submission.id,
		MATH_FILE_NAME.BERRY_STUDENT_SORT_IMAGE,
		FILE_EXTENSIONS.PNG
	);
	const berry_app_sort_image = await getFile(
		submission.id,
		MATH_FILE_NAME.BERRY_APP_SORT_IMAGE,
		FILE_EXTENSIONS.PNG
	);
	const ingredients_1_image = await getFile(
		submission.id,
		MATH_FILE_NAME.INGREDIENTS_1_IMAGE,
		FILE_EXTENSIONS.PNG
	);
	const ingredients_2_image = await getFile(
		submission.id,
		MATH_FILE_NAME.INGREDIENTS_2_IMAGE,
		FILE_EXTENSIONS.PNG
	);
	const sharing_three_plates = await getFile(
		submission.id,
		MATH_FILE_NAME.SHARING_THREE_PLATES,
		FILE_EXTENSIONS.PNG
	);

	const sharing_four_plates = await getFile(
		submission.id,
		MATH_FILE_NAME.SHARING_FOUR_PLATES,
		FILE_EXTENSIONS.PNG
	);
	return {
		berry_student_sort_audio,
		berry_app_sort_audio,
		berry_connections_audio,
		crossing_1_image,
		crossing_2_image,
		shape_1_image,
		berry_student_sort_image,
		berry_app_sort_image,
		ingredients_1_image,
		ingredients_2_image,
		sharing_three_plates,
		sharing_four_plates,
	};
};

export const getFile = async (submissionId, key, extension) => {
	const url = `${get_url()}wp-json/sprig/v2/submissions/${submissionId}/files/${key}`;
	const file = await getDataNonJSON(url);
	let blob: Blob;

	switch (extension) {
		case FILE_EXTENSIONS.MP4:
			blob = new Blob([file], {type: "audio/mp4"});
			break;
		case FILE_EXTENSIONS.PNG:
			blob = new Blob([file], {type: "image/png"});
			break;
		default:
			throw new Error(`Unsupported file extension: ${extension}`);
	}

	return new Promise((resolve) => {
		var reader = new FileReader();
		reader.readAsDataURL(blob);
		reader.onload = function () {
			resolve(reader.result);
		};
	});
};

export const getModalType = (domain) => {
	switch (domain) {
		case DOMAIN_MATH:
			return MODAL_TYPES.MATH_ASSESSMENT_RESULT;
		case DOMAIN_ORAL_LANGUAGE:
			return MODAL_TYPES.ORAL_LANGUAGE_ASSESSMENT_RESULTS;
		default:
			throw new Error(`Unsupported domain id: ${domain}`);
	}
};

export const parseStringAndReplaceVariables = (string, languages) => {
	let newString = JSON.parse(string);
	if (languages?.length) {
		let communityLanguage = languages.find((language) => language.is_ancestral);
		if (!communityLanguage) communityLanguage = "UNKNOWN COMMUNITY LANGUAGE";
		return newString.replace(
			newRegex(TRANSLATION_CODES.COMMUNITY_LANGUAGE),
			communityLanguage.english_name
		);
	}
	return newString;
};

export const findResultsInReducer = (
	assessmentResults,
	student,
	currentClassroom
) => {
	if (assessmentResults.length) {
		return assessmentResults.find((result) => {
			const classroomId =
				result.domainId === DOMAIN_ORAL_LANGUAGE
					? result.assessment.currentClassroom.id
					: result.assessment.classroom.id;
			return (
				result.id === student.id &&
				result.domainId === currentClassroom.domain_id &&
				classroomId === currentClassroom.id
			);
		});
	}
	return null;
};

export const getMathSubmission = async (student, term) => {
	const submission = await getSubmissions(
		student.id,
		term.id,
		ASSESSMENT_ID.STUDENT_MATH,
		DOMAIN_MATH
	);
	const url = `${get_url()}wp-json/sprig/v2/students/${
		student.id
	}/stats?domain=${DOMAIN_MATH}`;
	const levels = await getData(url);
	if (submission) {
		const questions = await getQuestions(submission.id);
		const files = await getMathResultFiles(submission);
		if (questions && files) {
			return {
				questions,
				files,
				levels,
			};
		}
	}
};

export const getOralLanguageSubmission = async (student, term) => {
	const submissionId = await getSubmissions(
		student.id,
		term.id,
		ASSESSMENT_ID.STUDENT_ORAL_LANGUAGE,
		DOMAIN_ORAL_LANGUAGE
	);
	const audio = getStorytellingAudio(submissionId);
	const questions = getQuestions(submissionId);
	return Promise.all([audio, questions]).then(([audio, questions]) => {
		return {
			questions,
			audio,
		};
	});
};

export const getAssessmentResultsData = (student, currentClassroom) => {
	const {term, domain_id} = currentClassroom;
	if (domain_id === DOMAIN_ORAL_LANGUAGE) {
		return getOralLanguageSubmission(student, term);
	}
	if (domain_id === DOMAIN_MATH) {
		return getMathSubmission(student, term);
	}
};
