import "../../../Stylesheets/css/scss/Components/Portal/Public/NoFound.css";

import i18n from "i18next";
import React from "react";

import {AUTOMATION_TEST_ID} from "../../../Scripts/Constants";
import {Icon} from "../../Public";

export const NoFound = () => (
	<div
		className="no-results-found-component"
		id={AUTOMATION_TEST_ID.PUBLIC.COMPONENTS.NO_FOUND}
	>
		<div className="no-results-found-icon">
			<Icon type="faSearch" size="5x" />
		</div>
		<div className="no-results-found-title">
			<span>{i18n.t("common.no_results_found")}</span>
		</div>
		<div className="no-results-found-description">
			{i18n.t("phrase.we_cant_found_results")}
		</div>
	</div>
);
