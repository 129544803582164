import "./page.scss";

import React, {Fragment, useState} from "react";

import {STYLE_CONSTANT} from "../../../Scripts/StyleConstants";
import {
	PrimaryNavigationBar,
	PrimaryNavigationBarProps,
	SecondaryNavigationBar,
	SecondaryNavigationBarProps,
	SidebarNavigation,
	SidebarNavigationProps,
} from "../Navigation";

export interface PageLayoutProps {
	primaryNavigationProps: PrimaryNavigationBarProps;
	secondaryNavigationProps?: SecondaryNavigationBarProps;
	sidebarNavigationProps?: SidebarNavigationProps;
	customizedMainComponent?: JSX.Element | null;
}

/**
 * Primary UI component for user interaction
 */
export const PageLayout: React.FC<PageLayoutProps> = ({
	primaryNavigationProps,
	secondaryNavigationProps = null,
	sidebarNavigationProps = null,
	customizedMainComponent = null,
	children,
	...props
}) => {
	const [isMenuOpen, updateMenu] = useState(false);
	const asideClass = `${STYLE_CONSTANT.COMPONENT.LAYOUT.PAGE}--aside`;
	const asideWithSecondaryClass = secondaryNavigationProps
		? `${STYLE_CONSTANT.COMPONENT.LAYOUT.PAGE}--aside-secondary`
		: "";
	const menuClass = !isMenuOpen
		? `${STYLE_CONSTANT.COMPONENT.LAYOUT.PAGE}--menu-closed`
		: "";
	const hasSideBar = sidebarNavigationProps ? "has-side-bar" : "";
	return (
		<div
			className={[STYLE_CONSTANT.COMPONENT.LAYOUT.PAGE].join(" ")}
			{...props}
		>
			<PrimaryNavigationBar
				menu={{
					isOpen: isMenuOpen,
					action: () => updateMenu(!isMenuOpen),
				}}
				{...primaryNavigationProps}
			/>
			<div
				className={[
					`${STYLE_CONSTANT.COMPONENT.LAYOUT.PAGE}--main`,
					menuClass,
				].join(" ")}
			>
				{customizedMainComponent ? (
					customizedMainComponent
				) : (
					<Fragment>
						{sidebarNavigationProps ? (
							<SidebarNavigation {...sidebarNavigationProps} />
						) : null}
						<div
							className={[asideClass, asideWithSecondaryClass, hasSideBar].join(
								" "
							)}
						>
							{secondaryNavigationProps ? (
								<SecondaryNavigationBar {...secondaryNavigationProps} />
							) : null}
							<div
								className={`${STYLE_CONSTANT.COMPONENT.LAYOUT.PAGE}--content`}
							>
								{children}
							</div>
						</div>
					</Fragment>
				)}
			</div>
		</div>
	);
};
