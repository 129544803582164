import "./secondaryNavigationBar.scss";

import React from "react";

import {STYLE_CONSTANT} from "../../../Scripts/StyleConstants";
import {Icon, IconProps} from "../index";

export interface SecondaryNavigationBarProps {
	title?: string;
	titleIcon?: IconProps;
	children?: React.ReactNode;
	customizedClassName?: string;
}

/**
 * Primary UI component for user interaction
 */
export const SecondaryNavigationBar: React.FC<SecondaryNavigationBarProps> = ({
	title,
	titleIcon,
	children,
	customizedClassName,
	...props
}) => {
	return (
		<nav
			className={[
				STYLE_CONSTANT.COMPONENT.NAVIGATION.SECONDARY,
				customizedClassName,
			].join(" ")}
			{...props}
		>
			<section
				className={`${STYLE_CONSTANT.COMPONENT.NAVIGATION.SECONDARY}--content`}
			>
				<div
					className={`${STYLE_CONSTANT.COMPONENT.NAVIGATION.SECONDARY}--title-and-icon`}
				>
					{titleIcon && <Icon {...titleIcon} />}
					{title && (
						<span
							className={`${STYLE_CONSTANT.COMPONENT.NAVIGATION.SECONDARY}--content-title`}
						>
							{title}
						</span>
					)}
				</div>
				{children}
			</section>
		</nav>
	);
};
