import React, {Fragment} from "react";

import {AUTOMATION_TEST_ID} from "../../../../Scripts/Constants";
import {FontAwesomeIconSize, Icon} from "../../../Public";

interface LoaderSpinnerProps {
	show?: boolean;
	size?: FontAwesomeIconSize;
	style?: {};
	spinnerClassName?: string;
	loadingText?: JSX.Element;
}

export const LoaderSpinner = ({
	show = false,
	size = "3x",
	style = {},
	spinnerClassName = "mt-3",
	loadingText = <Fragment />,
}: LoaderSpinnerProps) => {
	return show ? (
		<div
			className="loader-spinner text-center w-100"
			id={AUTOMATION_TEST_ID.PUBLIC.COMPONENTS.LOADER_SPINNER}
		>
			<Icon
				type="faSpinner"
				pulse
				className={spinnerClassName}
				size={size}
				style={style}
				iconWrapperClassName="d-inline"
			/>
			{loadingText ? loadingText : null}
		</div>
	) : null;
};
