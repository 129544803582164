import "../../../../../Stylesheets/css/scss/Components/Portal/Public/Assessment/OralLanguage/LearningOpportunities.css";

import htmlToText from "html-to-text";
import i18n from "i18next";
import React, {Component} from "react";
import {Link} from "react-router-dom";

import {getCurrentDomainItems} from "../../../../../Scripts/Activities";
import {AssetManager} from "../../../../../Scripts/AssetManager";
import {ROLES} from "../../../../../Scripts/Constants";
import {DOMAIN_ORAL_LANGUAGE} from "../../../../../Scripts/Domain";
import {
	EducatorTaxonomiesModel,
	OutcomeTaxonomyItem,
} from "../../../../../Scripts/PublicInterfaces";
import {QuestionSection} from "../Public";

interface LearningOpportunitiesInAssessmentProps {
	currentClassroomId: number;
	role: string;
	studentId: number;
	taxonomies: EducatorTaxonomiesModel;
	studentName: string;
	onCloseModal: () => void;
}

interface LearningOpportunitiesInAssessmentState {
	outcomes: OutcomeTaxonomyItem[];
}

export class LearningOpportunitiesInAssessment extends Component<
	LearningOpportunitiesInAssessmentProps,
	LearningOpportunitiesInAssessmentState
> {
	constructor(props: LearningOpportunitiesInAssessmentProps) {
		super(props);

		this.state = {
			outcomes: this.selectOutcomesToRender(
				props.taxonomies,
				DOMAIN_ORAL_LANGUAGE
			),
		};
	}

	onCloseModal = () => {
		this.props.onCloseModal();
	};

	header = {
		title: i18n.t("assessments.oral_language.learning_opportunities.title"),
		description: i18n.t(
			"assessments.oral_language.learning_opportunities.description",
			{student: this.props.studentName}
		),
	};

	randomlySelectNumberItemsFromArray = (numberOfItems, array) => {
		const shuffledArray = array.sort(() => 0.5 - Math.random());
		return shuffledArray.slice(0, numberOfItems);
	};

	selectOutcomesToRender = (taxonomies, domain: number) => {
		if (
			taxonomies.learningOutcomes.length &&
			taxonomies.generalOutcomes.length
		) {
			const maximumOutcomes = 6;
			const selectedLearningOutcomes = this.randomlySelectNumberItemsFromArray(
				3,
				getCurrentDomainItems(taxonomies.learningOutcomes, domain)
			);
			return [
				...selectedLearningOutcomes,
				...this.randomlySelectNumberItemsFromArray(
					maximumOutcomes - selectedLearningOutcomes.length,
					getCurrentDomainItems(taxonomies.generalOutcomes, domain)
				),
			];
		} else {
			return [];
		}
	};

	getLinkUrl = () => {
		const {currentClassroomId, role, studentId} = this.props;
		return (role === ROLES.TEACHER || role === ROLES.PRINCIPAL) &&
			currentClassroomId
			? `/portal/classroom/${currentClassroomId}/learningactivities/recommended`
			: `/portal/student/${studentId}/domain/${DOMAIN_ORAL_LANGUAGE}/activities`;
	};

	renderAsset = ({id}) => {
		const {taxonomies} = this.props;
		const learningOutcome = taxonomies.learningOutcomes.find(
			(outcome) => outcome.id === id
		);
		const generalOutcome = taxonomies.generalOutcomes.find(
			(outcome) => outcome.id === id
		);
		const specificOutcome = taxonomies.specificOutcomes.find(
			(outcome) => outcome.id === id
		);
		let slug = "";
		if (learningOutcome) {
			slug = learningOutcome.slug;
		} else if (generalOutcome) {
			const parentOutcome = taxonomies.learningOutcomes.find(
				(outcome) => outcome.id === generalOutcome.parent
			);
			if (parentOutcome) {
				slug = parentOutcome.slug;
			}
		} else if (specificOutcome) {
			const parentOutcome = taxonomies.learningOutcomes.find(
				(outcome) => outcome.id === specificOutcome.parent_parent
			);
			if (parentOutcome) {
				slug = parentOutcome.slug;
			}
		}
		return AssetManager.getOutcomeAssetBySlug(slug);
	};

	renderOutcome = ({id, name}) => {
		const Asset = this.renderAsset({id});
		return (
			<Link
				key={`outcome-link-${id}`}
				to={{
					pathname: this.getLinkUrl(),
					state: {outcomeId: id},
				}}
				className="no-link-decoration"
			>
				<div
					className="learning-opportunity-outcome p-3"
					onClick={this.onCloseModal}
				>
					<div className="outcome-img">
						<Asset />
					</div>
					<small className="text-center">{htmlToText.fromString(name)}</small>
				</div>
			</Link>
		);
	};

	render() {
		const {outcomes} = this.state;
		return (
			<div className="assessment-learning-opportunities">
				<QuestionSection {...this.header}>
					<div className="learning-opportunity-assets">
						{outcomes.map((outcome) => this.renderOutcome(outcome))}
					</div>
				</QuestionSection>
			</div>
		);
	}
}
