import i18n from "i18next";
import React, {Component, Fragment} from "react";

import {ReportActions} from "../../../../Actions";
import {
	getChartTitle,
	getCurrentDomainItems,
	getCurrentReports,
	getDropdownLabel,
	getItemsWithSameParent,
	getReportChart,
} from "../../../../Scripts/Activities";
import {
	ACTIVITY_TAG,
	ACTIVITY_TAG_STATS_REPORT,
	AUTOMATION_TEST_ID,
	OUTCOME_LEVEL,
	REPORT_ID,
	REPORT_TYPE,
	TIME_PERIOD,
} from "../../../../Scripts/Constants";
import {OutcomeTaxonomyItem} from "../../../../Scripts/PublicInterfaces";
import {ChartHeader, Dropdown, ReportAlertNoData} from "../index";

interface ActivitiesCompletionProps {
	domain?: number;
	reportName: string;
	id?: number;
	reports?: [];
	labels?: {id: number; name: string}[];
	parentOutcomes?: OutcomeTaxonomyItem[];
	taxonomies?: {};
	curriculumSlug?: string;
}

interface ActivitiesCompletionState {
	context: string;
	currentParentOutcome: number;
	label: number;
}

export class ActivitiesCompletion extends Component<
	ActivitiesCompletionProps,
	ActivitiesCompletionState
> {
	private reportsHaveParentOutcomes;
	private reportWithLabelsDropdown;

	constructor(props) {
		super(props);

		const {labels} = this.props;
		if (labels)
			this.state = {
				context: TIME_PERIOD.TERM,
				label: labels?.length > 0 ? labels[0].id : 0,
				currentParentOutcome: 0,
			};
		else {
			this.state = {
				context: TIME_PERIOD.TERM,
				label: 0,
				currentParentOutcome: 0,
			};
		}

		this.reportsHaveParentOutcomes = [
			ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.SCHOOLS,
			ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.DIVISION,
			ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.SCHOOL,
			ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.STUDENT,
			ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.CLASS,
			ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.CLASSROOMS,
			ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.DIVISION,
			ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.SCHOOLS,
			ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.STUDENT,
			ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.SCHOOL,
			ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.CLASSROOMS,
			ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.CLASS,
		];

		this.reportWithLabelsDropdown = [
			ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.SCHOOLS,
			ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.SCHOOLS,
			ACTIVITY_TAG_STATS_REPORT.COMPETENCY.SCHOOLS,
			ACTIVITY_TAG_STATS_REPORT.LEARNING_OUTCOMES.SCHOOLS,
			ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.CLASSROOMS,
			ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.CLASSROOMS,
			ACTIVITY_TAG_STATS_REPORT.COMPETENCY.CLASSROOMS,
			ACTIVITY_TAG_STATS_REPORT.LEARNING_OUTCOMES.CLASSROOMS,
			ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.STUDENT,
			ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.STUDENT,
			ACTIVITY_TAG_STATS_REPORT.COMPETENCY.STUDENT,
			ACTIVITY_TAG_STATS_REPORT.LEARNING_OUTCOMES.STUDENT,
		];
	}

	componentDidMount = () => {
		const {reportName, parentOutcomes, domain} = this.props;
		const currentDomainOutcomes =
			this.reportsHaveParentOutcomes.indexOf(reportName) > -1
				? getCurrentDomainItems(parentOutcomes, domain)
				: null;
		this.setState({
			currentParentOutcome:
				currentDomainOutcomes?.length > 0 ? currentDomainOutcomes[0].id : null,
		});
	};

	getData = () => {
		const {domain, id, taxonomies, reportName, reports, curriculumSlug} =
			this.props;
		const {context, currentParentOutcome, label} = this.state;
		const currentReport = this.getCurrentReport(reportName, reports, id, label);
		if (currentReport) {
			const specificReport = getCurrentReports(
				currentReport.counts,
				context,
				reportName,
				domain,
				currentParentOutcome,
				taxonomies,
				curriculumSlug
			);
			return specificReport;
		}
		return getCurrentReports(
			[],
			context,
			reportName,
			domain,
			currentParentOutcome,
			taxonomies,
			curriculumSlug
		);
	};

	getCurrentReport = (reportName, reports, id, label) => {
		switch (reportName) {
			case ACTIVITY_TAG_STATS_REPORT.LEARNING_OUTCOMES.DIVISION:
			case ACTIVITY_TAG_STATS_REPORT.COMPETENCY.DIVISION:
			case ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.DIVISION:
			case ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.DIVISION:
			case ACTIVITY_TAG_STATS_REPORT.LEARNING_OUTCOMES.CLASS:
			case ACTIVITY_TAG_STATS_REPORT.COMPETENCY.CLASS:
			case ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.CLASS:
			case ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.CLASS:
			case ACTIVITY_TAG_STATS_REPORT.LEARNING_OUTCOMES.SCHOOL:
			case ACTIVITY_TAG_STATS_REPORT.COMPETENCY.SCHOOL:
			case ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.SCHOOL:
			case ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.SCHOOL: {
				return reports && reports.find((report) => report.item_id === id);
			}
			case ACTIVITY_TAG_STATS_REPORT.LEARNING_OUTCOMES.SCHOOLS:
			case ACTIVITY_TAG_STATS_REPORT.COMPETENCY.SCHOOLS:
			case ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.SCHOOLS:
			case ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.SCHOOLS:
			case ACTIVITY_TAG_STATS_REPORT.LEARNING_OUTCOMES.STUDENT:
			case ACTIVITY_TAG_STATS_REPORT.COMPETENCY.STUDENT:
			case ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.STUDENT:
			case ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.STUDENT:
			case ACTIVITY_TAG_STATS_REPORT.LEARNING_OUTCOMES.CLASSROOMS:
			case ACTIVITY_TAG_STATS_REPORT.COMPETENCY.CLASSROOMS:
			case ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.CLASSROOMS:
			case ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.CLASSROOMS: {
				return reports && reports.find((report) => report.item_id === label);
			}
			default:
				throw new Error(`Unsupported report: ${reportName}`);
		}
	};

	matchItemToLabel = (id) => {
		const {labels} = this.props;
		if (!!labels) {
			const currentLabel = labels.find((label) => label.id === id);
			return currentLabel ? currentLabel.name : "";
		}
		return "";
	};

	handleLabelChange = (item) => {
		this.setState({label: item.id});
	};

	handleContextChange = (item) => {
		this.setState({context: item.id});
	};

	handleParentOutcomeChange = (item) => {
		const {reportName, taxonomies, id} = this.props;
		this.setState({
			currentParentOutcome: item.id,
		});
		const outcomeIds = getItemsWithSameParent(taxonomies, item.id).map(
			(outcome) => outcome.id
		);
		const specific = {
			direct:
				reportName === ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.DIVISION ||
				reportName === ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.SCHOOL ||
				reportName === ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.CLASS,
			tag: ACTIVITY_TAG[OUTCOME_LEVEL.THREE],
		};
		const general = {
			direct:
				reportName === ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.DIVISION ||
				reportName === ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.SCHOOL ||
				reportName === ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.CLASS,
			tag: ACTIVITY_TAG[OUTCOME_LEVEL.TWO],
		};
		const specificOutcomesReport =
			reportName === ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.DIVISION ||
			reportName === ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.SCHOOLS ||
			reportName === ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.CLASS ||
			reportName === ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.SCHOOL ||
			reportName === ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.CLASSROOMS ||
			reportName === ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.STUDENT;
		ReportActions.getReportSingle({
			id,
			report: REPORT_ID.ACTIVITY_TAG_STATS,
			type: this.getReportType(),
			tags: outcomeIds,
			direct: specificOutcomesReport ? specific.direct : general.direct,
			tag: specificOutcomesReport ? specific.tag : general.tag,
		});
	};

	getReportType = () => {
		const {reportName} = this.props;
		switch (reportName) {
			case ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.SCHOOL:
			case ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.CLASSROOMS:
			case ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.SCHOOL:
			case ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.CLASSROOMS:
				return REPORT_TYPE.SCHOOL;
			case ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.DIVISION:
			case ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.SCHOOLS:
			case ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.DIVISION:
			case ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.SCHOOLS:
				return REPORT_TYPE.DIVISION;
			case ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.CLASS:
			case ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.STUDENT:
			case ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.CLASS:
			case ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.STUDENT:
				return REPORT_TYPE.CLASS;
			default:
				throw new Error(`Unsupported reportName type: ${reportName}`);
		}
	};

	getParentOutcomesDropdownItems = () => {
		const {reportName, parentOutcomes, domain} = this.props;
		if (this.reportsHaveParentOutcomes.indexOf(reportName) > -1) {
			return getCurrentDomainItems(parentOutcomes, domain).map((outcome) => {
				return {
					id: outcome.id,
					label: outcome.name.replace(/&amp;/g, "&"),
				};
			});
		}
		return [];
	};

	getDropdownTitle = () => {
		const {reportName} = this.props;
		switch (reportName) {
			case ACTIVITY_TAG_STATS_REPORT.LEARNING_OUTCOMES.CLASSROOMS:
			case ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.CLASSROOMS:
			case ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.CLASSROOMS:
			case ACTIVITY_TAG_STATS_REPORT.COMPETENCY.CLASSROOMS:
				return i18n.t("admin.choose_class");
			case ACTIVITY_TAG_STATS_REPORT.LEARNING_OUTCOMES.STUDENT:
			case ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.STUDENT:
			case ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.STUDENT:
			case ACTIVITY_TAG_STATS_REPORT.COMPETENCY.STUDENT:
				return i18n.t("admin.choose_student");
			case ACTIVITY_TAG_STATS_REPORT.LEARNING_OUTCOMES.SCHOOLS:
			case ACTIVITY_TAG_STATS_REPORT.GENERAL_OUTCOMES.SCHOOLS:
			case ACTIVITY_TAG_STATS_REPORT.SPECIFIC_OUTCOMES.SCHOOLS:
			case ACTIVITY_TAG_STATS_REPORT.COMPETENCY.SCHOOLS:
				return i18n.t("admin.choose_school");
			default:
				throw new Error(`Unsupported report type: ${reportName}`);
		}
	};

	render() {
		const {reportName, reports, labels} = this.props;
		const {context, currentParentOutcome, label} = this.state;
		const PERIOD_DROPDOWN_ITEMS = [
			{id: TIME_PERIOD.WEEK, label: i18n.t("time.this_week")},
			{id: TIME_PERIOD.MONTH, label: i18n.t("time.this_month")},
			{id: TIME_PERIOD.TERM, label: i18n.t("time.this_term")},
		];
		const selectedItem = PERIOD_DROPDOWN_ITEMS.find(
			(item) => item.id === context
		);
		const parentOutcomesDropdownItems = this.getParentOutcomesDropdownItems();
		const outcomeSelectedItem = parentOutcomesDropdownItems
			? parentOutcomesDropdownItems.find(
					(item) => item.id === currentParentOutcome
			  )
			: {};
		const chartTitle = getChartTitle(reportName);
		return (
			<div className="mt-2 activities-completion-chart">
				<ChartHeader title={i18n.t(`admin.${chartTitle}`)} />
				{reports && reports.length > 0 ? (
					<Fragment>
						<div className="text-right mb-3 mt-5">
							{getReportChart(this.getData(), reportName)}
						</div>
						<div className="flex wrap justify-content-between pt-3 border-top action-dropdowns">
							<Dropdown
								items={PERIOD_DROPDOWN_ITEMS}
								onSelect={this.handleContextChange}
								selectedItem={selectedItem}
								componentClassName="flex"
								id={AUTOMATION_TEST_ID.PUBLIC.REPORTS.PERIOD_DROPDOWN_BUTTON}
								menuId={AUTOMATION_TEST_ID.PUBLIC.REPORTS.PERIOD_DROPDOWN_MENU}
							/>
							{parentOutcomesDropdownItems.length > 0 && (
								<Dropdown
									componentClassName={
										this.reportWithLabelsDropdown.indexOf(reportName) > -1 &&
										labels &&
										labels.length > 0
											? "flex justify-center"
											: "flex justify-end"
									}
									items={parentOutcomesDropdownItems}
									onSelect={this.handleParentOutcomeChange}
									selectedItem={outcomeSelectedItem}
									dropdownLabel={i18n.t(
										`activity_taxonomies.${getDropdownLabel(reportName)}`
									)}
									labelIcon="faLeaf"
									id={
										AUTOMATION_TEST_ID.PUBLIC.REPORTS
											.PARENT_OUTCOME_DROPDOWN_BUTTON
									}
									menuId={
										AUTOMATION_TEST_ID.PUBLIC.REPORTS
											.PARENT_OUTCOME_DROPDOWN_MENU
									}
									dropdownMenuClassName={"dropdown-menu-right"}
								/>
							)}
							{this.reportWithLabelsDropdown.indexOf(reportName) > -1 &&
								labels &&
								labels.length && (
									<Dropdown
										className="btn btn-dropdown dropdown-toggle text-right"
										componentClassName="flex justify-end"
										items={labels.map((label) => ({
											label: label.name,
											id: label.id,
										}))}
										onSelect={this.handleLabelChange}
										selectedItem={{
											label: this.matchItemToLabel(label),
										}}
										dropdownLabel={this.getDropdownTitle()}
										labelIcon="faUserGraduate"
										id={
											AUTOMATION_TEST_ID.PUBLIC.REPORTS.LABELS_DROPDOWN_BUTTON
										}
										menuId={
											AUTOMATION_TEST_ID.PUBLIC.REPORTS.LABELS_DROPDOWN_MENU
										}
									/>
								)}
						</div>
					</Fragment>
				) : (
					<div className="text-right mb-2 mt-5">
						<ReportAlertNoData />
					</div>
				)}
			</div>
		);
	}
}
