import i18n from "i18next";
import React from "react";

import {getMonthName} from "../../../../../../Scripts/Date";
import {DOMAIN_ORAL_LANGUAGE} from "../../../../../../Scripts/Domain";
import {MilestoneTerm} from "../../../../../../Scripts/Interfaces";
import {getRoundedNumber} from "../../../../../../Scripts/Localization";
import {HasId, HasName} from "../../../../../../Scripts/PublicInterfaces";
import {Icon} from "../../../../../Public";

interface Student extends HasId, HasName {
	level: number;
	activityCompletion: number;
}

export const SelectedStudent = ({
	student,
	selections,
	milestones,
	domain,
	onRemoveStudent,
	onOpenRecommendedActivities,
}: {
	student: Student;
	selections: {milestone: number; rubric: string};
	milestones: MilestoneTerm[];
	domain: number;
	onRemoveStudent: (student: Student) => void;
	onOpenRecommendedActivities: (student: Student) => void;
}) => {
	const {milestone, rubric} = selections;
	const currentMilestone = milestones.find(
		(milestoneItem) => milestoneItem.milestone_id === milestone
	);
	const currentMilestoneLabel = currentMilestone
		? getMonthName(currentMilestone.start)
		: "";
	const currentRubric = !!rubric
		? domain === DOMAIN_ORAL_LANGUAGE
			? i18n.t(`rubric.oral_language.${rubric}`)
			: i18n.t(`rubric.math.${rubric}`)
		: i18n.t("common.all");
	const studentData = [
		{
			label: i18n.t("common.name"),
			data: student.name,
		},
		{
			label: i18n.t("common.rubric"),
			data: currentRubric,
		},
		{
			label: i18n.t("chart.milestone"),
			data: currentMilestoneLabel,
		},
		{
			label: i18n.t("common.level"),
			data: getRoundedNumber(student.level, 2),
		},
		{
			label: i18n.t("admin.activities_completed"),
			data: student.activityCompletion,
		},
	];

	const openRecommendedActivities = () => {
		onOpenRecommendedActivities(student);
	};

	return (
		<div
			key={`selectedStudent${student.id}`}
			className="student-milestone-selected-student"
		>
			{studentData.map((datum) => (
				<div
					className="selected-student-content"
					key={`student-content-${datum.label}-${student.id}`}
				>
					<div className="selected-student-label">{datum.label}</div>
					<div className="selected-student-data">{datum.data}</div>
				</div>
			))}
			<div className="selected-student-actions">
				<div
					className="selected-student-link"
					onClick={openRecommendedActivities}
				>
					<Icon type="faLeaf" size="1x" />
				</div>
				<div
					className="selected-student-remove"
					onClick={() => onRemoveStudent(student)}
				>
					<Icon type="faTimesCircle" className="text-muted" size="1x" />
				</div>
			</div>
		</div>
	);
};
