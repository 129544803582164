import React, {useEffect, useState} from "react";

import {ConditionalWrapper} from "../../../../Portal/PublicComponents";
import PortalTourStep from "../../PortalTourStep";

export const MobilePageDescription = ({children, portalTourProps, tourId}) => {
	const [windowSize, setWindowSize] = useState(window.innerWidth);

	useEffect(() => {
		const handleResize = () => {
			setWindowSize(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);

		return () => window.removeEventListener("resize", handleResize);
	}, []);
	return (
		<ConditionalWrapper
			condition={windowSize < 992}
			wrapper={(children) => {
				return (
					<PortalTourStep
						{...portalTourProps}
						isWalkThrough={true}
						wrapperClassName="w-100"
						tourId={tourId}
						isMobileScreen={true}
					>
						{children}
					</PortalTourStep>
				);
			}}
		>
			{children}
		</ConditionalWrapper>
	);
};
