import i18n from "i18next";
import React from "react";

import {AssetManager} from "../../../../../../Scripts/AssetManager";
import {
	MATH_ASSESSMENT_TASK,
	MATH_PROCESS_SLUG,
	PROVINCE_SLUG,
} from "../../../../../../Scripts/Constants";
import {MathResultsActivitiesHome, MathResultsActivitiesTab} from "./Widgets";

export const MathResultsActivities = ({
	files,
	student,
	questions,
	handleNavClick,
	page,
	levels,
	role,
	taxonomies,
	classroom,
	client,
	closeModal,
}) => {
	const getQuestion = (questionId) => {
		return questions.find((question) => question.question_id === questionId)
			.values[0];
	};

	const scoopEstimate = getQuestion(12110);
	const scoopEstimateSecond = getQuestion(12111);
	const pieceEstimate = getQuestion(12120);
	const pieceEstimateSecond = getQuestion(12121);
	const berryEstimate = getQuestion(12140);
	const berryEstimateSecond = getQuestion(12141);
	const mathLabelsPrefix = `assessments.math.activities`;
	const process = `${mathLabelsPrefix}.process`;
	const description = `${mathLabelsPrefix}.description`;
	const result = `${mathLabelsPrefix}.result`;
	const getRubrics = (task) => {
		const rubricLevels = [1, 2, 3, 4];
		return rubricLevels.map((level) => {
			return {
				level,
				rubric: i18n.t(`${process}.${task}.rubric.level_${level}`),
			};
		});
	};

	const rubrics = {
		visualization: getRubrics(MATH_ASSESSMENT_TASK.VISUALIZATION),
		reasoning: getRubrics(MATH_ASSESSMENT_TASK.REASONING),
		communication: getRubrics(MATH_ASSESSMENT_TASK.COMMUNICATION),
		makingConnections: getRubrics(MATH_ASSESSMENT_TASK.MAKING_CONNECTIONS),
		mentalMath: getRubrics(MATH_ASSESSMENT_TASK.MENTAL_MATH_AND_ESTIMAT),
		problemSolving: getRubrics(MATH_ASSESSMENT_TASK.PROBLEM_SOLVING),
	};
	const task = `${mathLabelsPrefix}.task`;
	const riverCrossingTab = {
		task: `${i18n.t(`${task}.river_crossing`)}`,
		description: i18n.t(`${description}.river_crossing`, {
			student: student.name,
		}),
		img: [files.crossing_1_image, files.crossing_2_image],
		imgCaption: [
			i18n.t(`${result}.river_crossing`, {
				student: student.name,
			}),
			i18n.t(`${result}.river_crossing_2`, {
				student: student.name,
			}),
		],
		audio: null,
		rubrics: rubrics.visualization,
		rubricLevel: levels.rubric[MATH_ASSESSMENT_TASK.VISUALIZATION]["1a"],
	};
	const shapePuzzleTab = {
		task: `${i18n.t(`${task}.shape_puzzle`)} `,
		description: i18n.t(`${description}.shape`, {
			student: student.name,
		}),
		img: [files.shape_1_image],
		imgCaption: [i18n.t(`${result}.shape`)],
		audio: null,
		rubrics: rubrics.visualization,
		rubricLevel: levels.rubric[MATH_ASSESSMENT_TASK.VISUALIZATION]["1b"],
	};
	const sortBerriesTab = {
		task: `${i18n.t(`${task}.sort_berries`)}`,
		description: i18n.t(`${description}.sort_student`, {
			student: student.name,
		}),
		img: [files.berry_student_sort_image],
		imgCaption: [
			i18n.t(`${result}.sort_student`, {
				student: student.name,
			}),
		],
		audio: files.berry_student_sort_audio,
		rubrics: rubrics.reasoning,
		rubricLevel: levels.rubric[MATH_ASSESSMENT_TASK.REASONING]["2a"],
	};
	const sortBerriesCommunicationTab = {
		task: "",
		description: i18n.t(`${description}.sort_kalolin`, {
			student: student.name,
		}),
		img: [files.berry_app_sort_image],
		imgCaption: [i18n.t(`${result}.sort_kalolin`)],
		audio: files.berry_app_sort_audio,
		rubrics: rubrics.communication,
		rubricLevel: levels.rubric[MATH_ASSESSMENT_TASK.COMMUNICATION]["2a"],
	};
	const makingConnectionsTab = {
		task: `${i18n.t(`${task}.sort_berries`)}`,
		description: i18n.t(`${description}.making_connection`, {
			student: student.name,
		}),
		img: [AssetManager.ASSETS.ASSESSMENT.MATH.STATIC.MAKING_CONNECTIONS],
		imgCaption: [i18n.t(`${result}.making_connection`)],
		audio: [files.berry_connections_audio],
		rubrics: rubrics.makingConnections,
		rubricLevel: levels.rubric[MATH_ASSESSMENT_TASK.MAKING_CONNECTIONS]["2b"],
	};
	const estimateIngredientsTab = {
		task: `${i18n.t(`${task}.estimate_ingredients`)}`,
		description: i18n.t(`${description}.scoop_estimate`, {
			student: student.name,
		}),
		img: [
			files.ingredients_1_image,
			...(scoopEstimateSecond ? [files.ingredients_2_image] : []),
		],
		imgCaption: [
			i18n.t(`${result}.scoop_estimate`, {
				student: student.name,
				estimate: scoopEstimate,
			}),
			...(scoopEstimateSecond
				? [
						i18n.t(`${result}.scoop_estimate_2`, {
							student: student.name,
							estimate: scoopEstimateSecond,
						}),
				  ]
				: []),
		],

		audio: null,
		rubrics: rubrics.mentalMath,
		rubricLevel:
			levels.rubric[MATH_ASSESSMENT_TASK.MENTAL_MATH_AND_ESTIMAT]["3a"],
	};
	const estimatePieces = {
		task: `${i18n.t(`${task}.estimate_piece`)}`,
		description: i18n.t(`${description}.piece_estimate`, {
			student: student.name,
		}),
		img: [AssetManager.ASSETS.ASSESSMENT.MATH.STATIC.PIECE_ESTIMATION],
		imgCaption: [
			i18n.t(`${result}.piece_estimate`, {
				student: student.name,
				estimate: pieceEstimate,
			}),
			...(pieceEstimateSecond
				? [
						i18n.t(`${result}.piece_estimate_2`, {
							student: student.name,
							estimate: pieceEstimateSecond,
						}),
				  ]
				: []),
		],
		audio: null,
		rubrics: rubrics.mentalMath,
		rubricLevel:
			levels.rubric[MATH_ASSESSMENT_TASK.MENTAL_MATH_AND_ESTIMAT]["3b"],
	};
	const estimateBerries = {
		task: `${i18n.t(`${task}.estimate_berries`)}`,
		description: i18n.t(`${description}.berries_estimate`, {
			student: student.name,
		}),
		img: [AssetManager.ASSETS.ASSESSMENT.MATH.STATIC.BERRY_ESTIMATION],
		imgCaption: [
			i18n.t(`${result}.berries_estimate`, {
				student: student.name,
				estimate: berryEstimate,
			}),
			...(berryEstimateSecond
				? [
						i18n.t(`${result}.berries_estimate_2`, {
							student: student.name,
							estimate: berryEstimateSecond,
						}),
				  ]
				: []),
		],
		audio: null,
		rubrics: rubrics.mentalMath,
		rubricLevel:
			levels.rubric[MATH_ASSESSMENT_TASK.MENTAL_MATH_AND_ESTIMAT]["4a"],
	};
	const sharingThreeplates = {
		task: `${i18n.t(`${task}.share_berries_3`)}`,
		description: i18n.t(`${description}.share_3`, {
			student: student.name,
		}),
		img: [files.sharing_three_plates],
		imgCaption: [
			i18n.t(`${result}.share_3`, {
				student: student.name,
			}),
		],
		audio: null,
		rubrics: rubrics.problemSolving,
		rubricLevel: levels.rubric[MATH_ASSESSMENT_TASK.PROBLEM_SOLVING]["4b"],
	};
	const sharingFourplates = {
		task: `${i18n.t(`${task}.share_berries_4`)}`,
		description: i18n.t(`${description}.share_4`, {
			student: student.name,
		}),
		img: [files.sharing_four_plates],
		imgCaption: [
			i18n.t(`${result}.share_4`, {
				student: student.name,
			}),
		],
		audio: null,
		rubrics: rubrics.problemSolving,
		rubricLevel: levels.rubric[MATH_ASSESSMENT_TASK.PROBLEM_SOLVING]["4c"],
	};
	const isONClient = client === PROVINCE_SLUG.ON;
	const navTabs = [
		{
			page: 2,
			navTitle: i18n.t(
				`${process}.${
					isONClient ? `temp-on-reflecting` : `visualization`
				}.title`
			),
		},
		{
			page: 3,
			navTitle: i18n.t(
				`${process}.${
					isONClient ? `temp-on-reasoning-proving` : `reasoning`
				}.title`
			),
		},
		{
			page: 3,
			navTitle: i18n.t(
				`${process}.${
					isONClient ? `temp-on-communicating` : `communication-math`
				}.title`
			),
		},
		{
			page: 4,
			navTitle: i18n.t(
				`${process}.${isONClient ? `temp-on-connecting` : `connections`}.title`
			),
		},
		{
			page: 5,
			navTitle: i18n.t(
				`${process}.${
					isONClient ? `temp-on-representing` : `mental-math-and-estimation`
				}.title`
			),
		},
		{
			page: 6,
			navTitle: i18n.t(
				`${process}.${
					isONClient ? `temp-on-problem-solving` : `problem-solving`
				}.title`
			),
		},
	];

	const visualizationSection = {
		tabs: [riverCrossingTab, shapePuzzleTab],
		title: i18n.t(
			`${process}.${isONClient ? `temp-on-reflecting` : `visualization`}.title`
		),
		background: "#00962B",
		slug: isONClient
			? MATH_PROCESS_SLUG.REFLECTING
			: MATH_PROCESS_SLUG.VISUALIZATION,
	};
	const reasoningSection = {
		tabs: [sortBerriesTab],
		title: i18n.t(
			`${process}.${
				isONClient ? `temp-on-reasoning-proving` : `reasoning`
			}.title`
		),
		background: "#005F9B",
		slug: isONClient
			? MATH_PROCESS_SLUG.REASONING_PROVING
			: MATH_PROCESS_SLUG.REASONING,
	};
	const communicationSection = {
		tabs: [sortBerriesCommunicationTab],
		title: i18n.t(
			`${process}.${
				isONClient ? `temp-on-communicating` : `communication-math`
			}.title`
		),
		background: "#009D9E",
		slug: isONClient
			? MATH_PROCESS_SLUG.COMMUNICATING
			: MATH_PROCESS_SLUG.COMMUNICATION,
	};
	const makingConnectionsSection = {
		tabs: [makingConnectionsTab],
		title: i18n.t(
			`${process}.${isONClient ? `temp-on-connecting` : `connections`}.title`
		),
		background: "#D53A3C",
		slug: isONClient
			? MATH_PROCESS_SLUG.CONNECTING
			: MATH_PROCESS_SLUG.CONNECTIONS,
	};
	const mentalMathSection = {
		tabs: [estimateIngredientsTab, estimatePieces, estimateBerries],
		title: i18n.t(
			`${process}.${
				isONClient ? `temp-on-representing` : `mental-math-and-estimation`
			}.title`
		),
		background: "#F38111",
		slug: isONClient
			? MATH_PROCESS_SLUG.REPRESENTING
			: MATH_PROCESS_SLUG.MENTAL_MATH_AND_ESTIMAT,
	};
	const problemSolvingSection = {
		tabs: [sharingThreeplates, sharingFourplates],
		title: i18n.t(
			`${process}.${
				isONClient ? `temp-on-problem-solving` : `problem-solving`
			}.title`
		),
		background: "#8B3967",
		slug: isONClient
			? MATH_PROCESS_SLUG.PROBLEM_SOLVING_ON
			: MATH_PROCESS_SLUG.PROBLEM_SOLVING,
	};
	const resultTabs = [
		{
			page: 2,
			tabs: [visualizationSection],
		},
		{
			page: 3,
			tabs: [reasoningSection, communicationSection],
		},
		{
			page: 4,
			tabs: [makingConnectionsSection],
		},
		{
			page: 5,
			tabs: [mentalMathSection],
		},
		{
			page: 6,
			tabs: [problemSolvingSection],
		},
	];
	if (page === 1) {
		return (
			<MathResultsActivitiesHome
				handleNavClick={handleNavClick}
				navTabs={navTabs}
				levels={levels}
				studentName={student.name}
				client={client}
			/>
		);
	} else {
		return (
			<MathResultsActivitiesTab
				page={page}
				resultTabs={resultTabs}
				role={role}
				competencies={taxonomies.tagging.competencies}
				classroomId={classroom?.id}
				studentId={student.id}
				closeModal={closeModal}
			/>
		);
	}
};
