import "./MobileSearchBar.scss";

import React from "react";

import {PrimaryNavSearchActivity} from "./PrimaryNavSearchActivity";

export const MobileSearchBar = ({showBar, filters, portal}) => (
	<div
		className={`mobile-search-bar${showBar ? " mobile-search-bar--show" : ""}`}
	>
		<PrimaryNavSearchActivity filters={filters} portal={portal} />
	</div>
);
