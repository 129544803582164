import "./PortalTourTooltip.scss";

import React from "react";

export interface PortalTourTooltipProps {
	children: React.ReactElement;
	style: {};
}

export const PortalTourTooltip = ({
	children,
	style,
	...props
}: PortalTourTooltipProps) => (
	<div className="portal-tour-tooltip" style={style}>
		{React.cloneElement(children, {...props})}
	</div>
);
