import "./Notification.scss";

import i18n from "i18next";
import React, {Component, Fragment} from "react";

import {ModalActions, NotificationActions} from "../../../../Actions";
import {AUTOMATION_TEST_ID, MODAL_TYPES} from "../../../../Scripts/Constants";
import {
	NotificationDataModel,
	NotificationModel,
} from "../../../../Scripts/PublicInterfaces";
import {Icon} from "../../..";
import {LazyLoader} from "../index";
import {NotificationItem} from "./index";

interface NotificationsProps {
	currentRole: string;
	filters?: any;
	notification: {
		notifications: NotificationModel<NotificationDataModel>[];
	};
	student?: {};
	closeMenuEvent?: () => void;
}

interface NotificationsState {
	notificationLimit: number;
}

export class NotificationsView extends Component<
	NotificationsProps,
	NotificationsState
> {
	notificationIncrement: number = 20;

	constructor(props) {
		super(props);
		this.state = {
			notificationLimit: this.notificationIncrement,
		};
	}

	componentDidMount = () => {
		this.props.notification.notifications.forEach((notification) => {
			if (!notification.read) {
				NotificationActions.postNotification(notification.id);
			}
		});
	};

	componentDidUpdate = (prevProps) => {
		const {notifications} = this.props.notification;
		const prevNotifications = prevProps.notification?.notifications;
		if (
			prevNotifications &&
			notifications &&
			prevNotifications.length !== notifications.length
		) {
			const lengthDifference = notifications.length - prevNotifications.length;
			const onlyNewNotifications = [...notifications].splice(
				0,
				lengthDifference
			);
			const allNewNotifications = onlyNewNotifications.filter(
				(notification) => !notification.read
			);
			const allNewBadgesNotifications = allNewNotifications.filter(
				(notification) => notification.type === "badge_earned"
			);
			if (allNewBadgesNotifications.length)
				this.handleNewBadgeEarned(allNewBadgesNotifications);
		}
	};

	handleNewBadgeEarned = (badges) => {
		const {currentRole} = this.props;
		ModalActions.showModal(
			{
				open: true,
				badges,
				role: currentRole,
				size: "md",
				blockCloseOnOverlayClick: true,
			},
			MODAL_TYPES.BADGES_EARNED
		);
	};

	onLoadMoreNotifications = () => {
		const {notification} = this.props;
		const filteredNotifications = notification?.notifications.filter(
			(notification) => notification.data !== null
		);
		if (this.state.notificationLimit < filteredNotifications.length) {
			this.setState({
				notificationLimit:
					this.state.notificationLimit + this.notificationIncrement,
			});
		}
	};

	render() {
		const {filters, notification, student, closeMenuEvent} = this.props;
		const filteredNotifications = notification?.notifications.filter(
			(notification) => notification.data !== null
		);
		const notificationsForRender = filteredNotifications.slice(
			0,
			this.state.notificationLimit
		);
		return (
			<div
				className="notification-list"
				id={AUTOMATION_TEST_ID.PUBLIC.COMPONENTS.NOTIFICATION_TAB_MENU}
			>
				<div className="notification-list-header">
					{i18n.t("admin.notifications")}
				</div>

				<div className="notification-list-body">
					<LazyLoader onLoadMore={this.onLoadMoreNotifications}>
						<Fragment>
							{notificationsForRender.length ? (
								notificationsForRender.map((notification) => (
									<NotificationItem
										student={student}
										notification={notification}
										key={notification.id}
										filters={filters}
										closeMenuEvent={closeMenuEvent}
									/>
								))
							) : (
								<EmptyState />
							)}
						</Fragment>
					</LazyLoader>
				</div>
			</div>
		);
	}
}

const EmptyState = () => {
	return (
		<div className="empty-state">
			<Icon type="sprigNotification" size="4x" />
			<div className="empty-state-text">{i18n.t("admin.no_notifications")}</div>
		</div>
	);
};
