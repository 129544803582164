import React, {Fragment} from "react";
import {Route} from "react-router-dom";

import {Title} from "./index";

export interface AppRouteProps {
	title: string;
	meta?: string;
	exact?: boolean;
	path: string;
	render: (props) => React.ReactNode;
}

export const AppRoute = ({title, meta, ...props}: AppRouteProps) => (
	<Fragment>
		{title || meta ? <Title title={title} meta={meta} /> : null}
		<Route {...props} />
	</Fragment>
);
