import React from "react";
import {Link} from "react-router-dom";

import {ModalActions, NotificationActions} from "../../../../../Actions";
import {MODAL_TYPES, PORTALS} from "../../../../../Scripts/Constants";
import {
	getNotificationIcon,
	NOTIFICATION_TYPE,
} from "../../../../../Scripts/Notifications";
import {Icon} from "../../../../Public";
import {
	ActivityRecommendation,
	IssueOpened,
	IssueResolved,
	NewBadgeEarned,
	TimeReceived,
} from "./";

export const NotificationListItem = ({notification, student, filters}) => {
	const notificationIcon = getNotificationIcon(
		notification.type,
		notification.data.request_type
	);
	const activityRecommendation =
		notification.type === NOTIFICATION_TYPE.activityRecommendation ||
		notification.type === NOTIFICATION_TYPE.activityUnrecommendation;
	const handleClick = () => {
		if (activityRecommendation) {
			NotificationActions.hideShowNotificationTab(false);
			if (student.student_id === notification.data.student) {
				ModalActions.showModal(
					{
						open: true,
						student: notification.data.student,
						size: "md",
						role: PORTALS.CAREGIVER,
						keepInWindow: true,
						data: {
							filters,
							activityId: notification.data.activity,
						},
					},
					MODAL_TYPES.ACTIVITY_MODAL_NEW
				);
			}
		}
	};
	return (
		<ConditionalWrapper
			condition={
				activityRecommendation &&
				student &&
				student.student_id !== notification.data.student
			}
			wrapper={(children) => (
				<Link
					style={{color: "#000"}}
					className="no-default-link-style"
					to={`/portal/student/${notification.data.student}/domain/${notification.data.domain}/home/activity/${notification.data.activity}`}
				>
					{children}
				</Link>
			)}
		>
			<div
				className="p-3 border-bottom text-left notif-card"
				name="notification-card"
				onClick={handleClick}
			>
				<div className="row align-items-center">
					<div className="col-2 align-self-center text-center">
						<Icon
							type={notificationIcon}
							size="lg"
							className="edu-text-primary"
						/>{" "}
					</div>
					{(notification.type === NOTIFICATION_TYPE.activityRecommendation ||
						notification.type ===
							NOTIFICATION_TYPE.activityUnrecommendation) && (
						<ActivityRecommendation data={notification.data} />
					)}
					{notification.type === NOTIFICATION_TYPE.studentBadgeEarned && (
						<NewBadgeEarned notification={notification} />
					)}
					{notification.type === NOTIFICATION_TYPE.adminRequestClosed && (
						<IssueResolved notification={notification} />
					)}
					{notification.type === NOTIFICATION_TYPE.adminRequestOpened && (
						<IssueOpened notification={notification} />
					)}
				</div>
				<TimeReceived notification={notification} />
			</div>
		</ConditionalWrapper>
	);
};

const ConditionalWrapper = ({condition, wrapper, children}) =>
	condition ? wrapper(children) : children;
