import * as ActionTypes from "../Redux/ActionTypes";
import {CreateReducer} from "../Redux/Utilities";

const initialState = {queue: []};

export const ModalReducer = CreateReducer(initialState, {
	[ActionTypes.SHOW_MODAL]: (state, {payload}) => {
		const temp = state.queue.filter(
			(modal) => modal.modalType !== payload.modalType
		);
		return {
			...state,
			queue: [
				...temp,
				{
					modalProps: payload.modalProps,
					modalType: payload.modalType,
					type: ActionTypes.SHOW_MODAL,
					id: Date.now(),
				},
			],
		};
	},
	[ActionTypes.HIDE_MODAL]: (state, {payload}) => {
		return {
			...state,
			queue: state.queue.filter((queueItem) => queueItem.id !== payload),
		};
	},
	[ActionTypes.MODAL_UPDATE_PROPS]: (state, {payload}) => {
		if (!payload) return state;
		if (!state.modalProps) return {...state, modalProps: payload};
		return {
			...state,
			modalProps: {
				...state.modalProps,
				...payload,
			},
		};
	},
});

export default ModalReducer;
