import "../../../../../Stylesheets/css/scss/Components/Portal/Public/Surveys/Pages/SurveyStart.css";

import i18n from "i18next";
import React from "react";

import {AUTOMATION_TEST_ID} from "../../../../../Scripts/Constants";

interface SurveyStartProps {
	asset: {
		src: string;
		alt: string;
	};
	content: string;
	onStart: () => void;
}

export const SurveyStart = ({asset, content, onStart}: SurveyStartProps) => (
	<div className="survey-start">
		<div className="survey-start-asset">
			<object data={asset.src}>{asset.alt}</object>
		</div>
		<div className="survey-start-content">
			<div className="survey-start-text">
				{content.split("/n/n").map((splitContent) => (
					<div className="survey-split-content" key={splitContent}>
						{splitContent}
					</div>
				))}
			</div>
			<button
				className="btn btn-primary"
				onClick={onStart}
				id={AUTOMATION_TEST_ID.CAREGIVER.SURVEY.START_BUTTON}
			>
				{i18n.t("survey.start_survey")}
			</button>
		</div>
	</div>
);
