import React from "react";
import ReactTooltip from "react-tooltip";

import {Icon} from "../Icons";

interface FormGroupProps {
	validation?: {
		isValid: boolean;
	};
	label: string;
	id: string;
	info?: string;
	withIcon?: boolean;
	toggleIcon?: boolean;
	clickIcon?: () => void;
	formType?: "input" | "textarea";
	type: string;
	className: string;
	placeholder: string;
	name: string;
	value: string;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onKeyDown?: (
		event: React.KeyboardEvent<HTMLInputElement> & {
			target: HTMLInputElement;
		}
	) => void;
	onBlur?: () => void;
	inputRef?: React.RefObject<HTMLInputElement>;
	autoFocus?: boolean;
	disabled?: boolean;
	rows?: number;
}

export const FormGroup = React.forwardRef(
	({formType = "input", ...props}: FormGroupProps, ref) => (
		<div
			className={`form-group${
				props.validation
					? props.validation.isValid
						? " valid"
						: " invalid"
					: ""
			}`}
		>
			{props.label && props.id ? (
				<small className="flex">
					{props.label}
					{props.info && <InformationIcon info={props.info} />}
				</small>
			) : null}
			<InputType {...props} formType={formType} ref={ref} />
			{props.withIcon && (
				<Icon
					id={props.id}
					className="eye-icon"
					type={props.toggleIcon ? "faEye" : "faEyeSlash"}
					onClick={props.clickIcon}
				/>
			)}
			<ReactTooltip
				id="form-group-information-tooltip"
				type="dark"
				effect="solid"
				place="top"
			/>
		</div>
	)
);

const InformationIcon = ({info}) => (
	<div
		className="form-label-information ml-1"
		data-tip={info}
		data-for="form-group-information-tooltip"
	>
		<Icon type="faInfoCircle" />
	</div>
);

const InputType = ({formType, ref, ...props}) => {
	const {withIcon, toggleIcon, clickIcon, inputRef, ...rest} = props;
	switch (formType) {
		case "input":
			return <input {...rest} ref={ref} />;
		case "textarea":
			return <textarea {...rest} />;
		default:
			return <input {...rest} ref={ref} />;
	}
};
