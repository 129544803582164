import React, {Component} from "react";
import {Redirect} from "react-router-dom";

import {STYLED_ELEMENT} from "../../../../Scripts/Constants";
import {portalClassSelector} from "../../../../Scripts/Utilities";
import {Icon} from "../../../Public";
import {UserDropdown} from "./index";

interface SimpleNavWithGoBackProps {
	goBackPath?: string;
	children: JSX.Element;
	portal: string;
}

interface SimpleNavWithGoBackState {
	goToProfile: boolean;
	goToHome: boolean;
}

export class SimpleNavWithGoBack extends Component<
	SimpleNavWithGoBackProps,
	SimpleNavWithGoBackState
> {
	constructor(props) {
		super(props);
		this.state = {
			goToProfile: false,
			goToHome: false,
		};
	}

	handleProfile = () => {
		this.setState({goToProfile: true}, () => {
			this.setState({goToProfile: false});
		});
	};

	handleBack = () => {
		this.setState({goToHome: true}, () => {
			this.setState({goToHome: false});
		});
	};

	render() {
		const {goBackPath, children, portal} = this.props;
		if (this.state.goToProfile) {
			return <Redirect push to="/portal/account" />;
		} else if (this.state.goToHome) {
			return <Redirect push to={goBackPath ? goBackPath : "/portal/"} />;
		}
		return (
			<div
				className={`component-simple_nav_goback primary-navbar fixed-top ${portalClassSelector(
					portal,
					STYLED_ELEMENT.BACKGROUND
				)}`}
			>
				<nav className="navbar content-wrapper">
					<div className="flex">
						<button
							className={`btn ${portalClassSelector(
								portal,
								STYLED_ELEMENT.BUTTON
							)} d-inline mr-2`}
							onClick={this.handleBack}
						>
							<Icon type="faArrowLeft" className="ml-2 mr-2" />
						</button>
						{children}
					</div>
					<div className="ml-auto d-flex flex-row">
						<UserDropdown portal={portal} />
					</div>
				</nav>
			</div>
		);
	}
}
