import "../../../../../Stylesheets/css/scss/Components/Portal/Public/Visualizations/WeeklyGoal.css";

import React from "react";

import {AssetManager} from "../../../../../Scripts/AssetManager";
import {ProgressRing} from "../../Charts";

export const WeeklyGoal = ({goalsInCurrentDomain}) => {
	const actualProgress =
		(goalsInCurrentDomain.weekly_total / goalsInCurrentDomain.weekly_goal) *
		100;
	const progress = actualProgress > 100 ? 100 : actualProgress;
	return (
		<div className="weekly-goal">
			<ProgressRing
				radius={33}
				stroke={2.5}
				progress={progress}
				strokeBackgroundColor="#E5E5E5"
				strokeColor="#40BC49"
			/>
			<div className="weekly-goal-icon-data">
				<img src={AssetManager.ASSETS.ICON.LEAF_ICON} alt="leaf-icon" />
				<div
					className={`tree-activity-metric-data${
						goalsInCurrentDomain.weekly_total > 9 ? "-small-font-size" : ""
					}`}
				>{`${goalsInCurrentDomain.weekly_total} /
						${goalsInCurrentDomain.weekly_goal}`}</div>
			</div>
		</div>
	);
};
