import i18n from "i18next";
import React, {Component, lazy, Suspense} from "react";
import {withRouter} from "react-router";
import {Redirect, Route, RouteComponentProps, Switch} from "react-router-dom";

import {UserActions} from "../Actions";
import {ROUTES, URL_ACTIONS} from "../Scripts/Constants";
import {validateToken} from "../Scripts/TokenAndUser";
import {NoMatch} from "./Portal/PublicComponents";
import {
	AppRoute,
	AppRouteWithProps,
	LaunchScreen,
	PrivateRoute,
} from "./Private";

const ReadingCreateProfile = lazy(() =>
	import("./Portal/SprigReading/Authentication/ReadingCreateProfile").then(
		({ReadingCreateProfile}) => ({default: ReadingCreateProfile})
	)
);

const ReadingRegistrationPaidPlan = lazy(() =>
	import(
		"./Portal/SprigReading/Authentication/ReadingRegistrationPaidPlan"
	).then(({ReadingRegistrationPaidPlan}) => ({
		default: ReadingRegistrationPaidPlan,
	}))
);

const ReadingRegistration = lazy(() =>
	import("./Portal/SprigReading/Authentication").then(
		({ReadingRegistration}) => ({default: ReadingRegistration})
	)
);

const LoginContainer = lazy(
	() => import("../Containers/Authentication/LoginContainer")
);
const RegistrationContainer = lazy(
	() => import("../Containers/Authentication/RegistrationContainer")
);
const PortalRendering = lazy(() => import("./Portal/PortalRendering"));
const PasswordReset = lazy(
	() => import("./Private/Authentication/PasswordReset/PasswordReset")
);
const ResetRequest = lazy(
	() => import("./Private/Authentication/PasswordReset/ResetRequest")
);

interface AppProps extends RouteComponentProps {}

interface AppState {
	url_action_sprig_home?: boolean;
	url_action_sprig_language?: boolean;
	url_action_sprig_home_coupon?: boolean;
	variation_id?: string;
	new_user?: boolean;
	rp_key: string;
	login_name: string;
	action: string;
	user_token: string;
	country: string;
}

class App extends Component<AppProps, AppState> {
	constructor(props) {
		super(props);

		this.state = {
			rp_key: "",
			url_action_sprig_home: false,
			url_action_sprig_language: false,
			url_action_sprig_home_coupon: false,
			new_user: false,
			login_name: "",
			action: "",
			user_token: "",
			country: "",
		};
	}

	componentDidMount = () => {
		const url_keys: {
			key?: string;
			login?: string;
			action?: string;
			variation_id?: string;
			"user-token"?: string;
			country?: string;
		} = this.getUrlVars();
		if (
			url_keys["user-token"] &&
			url_keys.hasOwnProperty("country") &&
			url_keys.country
		) {
			this.setState({
				user_token: url_keys["user-token"],
				country: url_keys.country,
			});
		} else if (
			url_keys.hasOwnProperty("action") &&
			url_keys.action &&
			url_keys.hasOwnProperty("key") &&
			url_keys?.key &&
			url_keys.hasOwnProperty("login") &&
			url_keys.login
		) {
			this.setState(
				{
					rp_key: url_keys.key,
					login_name: url_keys.login,
					action: url_keys.action,
				},
				() => this.props.history.push("/reset-password")
			);
		} else if (
			url_keys.hasOwnProperty("action") &&
			(url_keys.action === URL_ACTIONS.SPRIG_HOME ||
				url_keys.action === URL_ACTIONS.SPRIG_LANGUAGE ||
				url_keys.action === URL_ACTIONS.SPRIG_HOME_COUPON)
		) {
			if (url_keys.hasOwnProperty("variation_id") && !!url_keys.variation_id) {
				this.setState(
					{
						url_action_sprig_home: url_keys.action === URL_ACTIONS.SPRIG_HOME,
						url_action_sprig_language:
							url_keys.action === URL_ACTIONS.SPRIG_LANGUAGE,
						url_action_sprig_home_coupon:
							url_keys.action === URL_ACTIONS.SPRIG_HOME_COUPON,
						variation_id: url_keys.variation_id,
					},
					() => this.props.history.push("/register")
				);
			} else {
				this.setState(
					{
						url_action_sprig_home: url_keys.action === URL_ACTIONS.SPRIG_HOME,
						url_action_sprig_language:
							url_keys.action === URL_ACTIONS.SPRIG_LANGUAGE,
						url_action_sprig_home_coupon:
							url_keys.action === URL_ACTIONS.SPRIG_HOME_COUPON,
					},
					() => this.props.history.push("/register")
				);
			}
		} else if (this.props.location.pathname === ROUTES.COUPON) {
			this.setState(
				{
					url_action_sprig_home: false,
					url_action_sprig_language: false,
					url_action_sprig_home_coupon: true,
					variation_id: undefined,
				},
				() => this.props.history.push("/register")
			);
		} else {
			if (url_keys.hasOwnProperty("action") && url_keys.action === "new_user") {
				this.setState({new_user: true}, () => {
					this.verifyToken();
				});
			} else {
				this.verifyToken();
			}
		}
	};

	verifyToken = () => {
		if (localStorage.getItem("sprig-login-token")) {
			validateToken()
				.then((hasToken) => {
					if (hasToken) {
						UserActions.userHasVerifiedToken(true);
					}
				})
				.catch((err) => {
					console.error(err);
					UserActions.userHasVerifiedToken(false);
				});
		} else {
			UserActions.userHasVerifiedToken(false);
			localStorage.removeItem("sprig-login-token");
		}
	};

	getUrlVars = (): {
		key?: string;
		login?: string;
		action?: string;
		variation_id?: string;
		"user-token"?: string;
		country?: string;
	} => {
		let vars = {};
		window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
			Object.assign(vars, {[key]: value});
			return "";
		});
		return vars;
	};

	getUrlActions = () => {
		const {
			url_action_sprig_home,
			url_action_sprig_language,
			url_action_sprig_home_coupon,
			variation_id,
			user_token,
			country,
		} = this.state;
		if (
			url_action_sprig_home ||
			url_action_sprig_language ||
			url_action_sprig_home_coupon ||
			variation_id ||
			user_token ||
			country
		)
			return {
				url_action_sprig_home,
				url_action_sprig_language,
				url_action_sprig_home_coupon,
				variation_id,
				user_token,
				country,
			};
		return {};
	};

	render() {
		return (
			<div className="App">
				<Suspense fallback={<LaunchScreen />}>
					<Switch>
						<Route
							exact
							path="/"
							render={() => (
								<Redirect
									to={{
										pathname: ROUTES.PORTAL,
									}}
								/>
							)}
						/>
						<PrivateRoute
							path={ROUTES.PORTAL}
							urlAction={this.getUrlActions()}
							newUser={this.state.new_user}
							component={PortalRendering}
						/>
						<AppRoute
							title={i18n.t("page_titles.sign_in")}
							path={ROUTES.SIGN_IN}
							render={(routeProps) => <LoginContainer {...routeProps} />}
						/>
						<AppRoute
							title={i18n.t("page_titles.reset_password_request")}
							path={ROUTES.RESET_REQUEST}
							render={(routeProps) => <ResetRequest {...routeProps} />}
						/>
						<AppRouteWithProps
							title={i18n.t("page_titles.register")}
							path={ROUTES.REGISTER}
							urlAction={this.getUrlActions()}
							component={RegistrationContainer}
						/>
						<AppRoute
							title={i18n.t("page_titles.register")}
							path={ROUTES.READING_REGISTER}
							render={(routeProps) => (
								<ReadingRegistrationPaidPlan location={routeProps.location} />
							)}
						/>
						<AppRoute
							title={i18n.t("page_titles.portal.reading.free_trial")}
							path={ROUTES.READING_FREE_TRIAL}
							render={(routeProps) => (
								<ReadingRegistration match={routeProps.match} />
							)}
						/>
						<AppRoute
							title={i18n.t("page_titles.portal.reading.create_profile")}
							path={ROUTES.CREATE_PROFILE}
							render={() => (
								<ReadingCreateProfile urlAction={this.getUrlActions()} />
							)}
						/>
						<AppRoute
							title={i18n.t("page_titles.reset_password")}
							path={ROUTES.RESET_PASSWORD}
							render={() => (
								<PasswordReset
									rp_key={this.state.rp_key}
									username={this.state.login_name}
									action={this.state.action}
								/>
							)}
						/>
						<Route component={NoMatch} />
					</Switch>
				</Suspense>
			</div>
		);
	}
}

const AppWithRouter = withRouter(App);
export default AppWithRouter;
