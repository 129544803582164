import "../../../../../Stylesheets/css/scss/Components/Portal/Public/Rewards/BadgeCollection.css";

import React, {Component} from "react";
import {connect} from "react-redux";

import {
	BadgeModel,
	EarnedBadgeModel,
} from "../../../../../Scripts/PublicInterfaces";
import {Badge} from "./Badge";

interface BadgeCollectionProps extends BadgeCollectionReducer {}

export class BadgeCollection extends Component<BadgeCollectionProps> {
	findAndReplaceVariablesInDescription = (badge, tier) => {
		if (badge && badge.tier_changes) {
			const {tier_changes, description} = badge;
			const allTierChangeKeys = Object.keys(tier_changes);
			if (allTierChangeKeys.length) {
				let formattedDescription = description;
				allTierChangeKeys.forEach((key) => {
					formattedDescription = formattedDescription.replace(
						`__${key}__`,
						tier_changes[key][tier]
					);
				});
				return formattedDescription;
			}
		}
		return badge.description;
	};

	render() {
		const {activity} = this.props;
		if (activity?.rewards?.badges && activity.rewards?.earnedBadges) {
			const {badges, earnedBadges} = activity.rewards;
			const allBadges: any = [];
			badges.forEach((badge) => {
				[...Array(badge.tier_count).keys()].forEach((index) => {
					const badgeTier = index + 1;
					const earnedBadge = earnedBadges.find(
						(earnedBadge) =>
							earnedBadge.id === badge.id && earnedBadge.tier >= badgeTier
					);
					const shownBadge = earnedBadge
						? {
								...earnedBadge,
								tier: badgeTier,
								earned: true,
								description: this.findAndReplaceVariablesInDescription(
									earnedBadge,
									index
								),
						  }
						: {
								...badge,
								tier: badgeTier,
								description: this.findAndReplaceVariablesInDescription(
									badge,
									index
								),
						  };
					allBadges.push(shownBadge);
				});
			});
			const categoriesByBadgeName: any[] = [
				...new Set(allBadges.map((badge) => badge.name)),
			];
			return (
				<div className="badge-collection">
					{categoriesByBadgeName.map((badgeName, index) => {
						const badgesPerCategory = allBadges.filter(
							(badge) => badge.name === badgeName
						);
						return (
							<section
								className="badge-category"
								key={`badge-category-${badgeName}`}
							>
								<div className="badge-category-title">
									<h5>{badgeName}</h5>
								</div>
								<div className="badge-category-content">
									{badgesPerCategory.map((badge, index) => (
										<Badge key={`badge_${badge.id}_${index}`} badge={badge} />
									))}
								</div>
								{index !== categoriesByBadgeName.length - 1 && (
									<div className="cl-border" />
								)}
							</section>
						);
					})}
				</div>
			);
		}
		return null;
	}
}

interface BadgeCollectionReducer {
	activity: {
		rewards: {
			badges: BadgeModel[];
			earnedBadges: EarnedBadgeModel[];
		};
	};
}

export default connect((state: BadgeCollectionReducer) => {
	return {
		activity: state.activity,
	};
})(BadgeCollection);
