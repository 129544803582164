import {Chart as ChartJS, registerables} from "chart.js";
import i18n from "i18next";
import React from "react";
import {Bar, Pie} from "react-chartjs-2";

import {getTooltips} from "../../../../Scripts/Activities";
import {CHART_TYPE, REPORT_CHART_TYPE} from "../../../../Scripts/Constants";

ChartJS.register(...registerables);
interface ReportChartProps {
	data: {
		labels: string[];
		datasets: {
			label: string;
			backgroundColor: string | string[];
			borderColor: string;
			borderWidth: number;
			hoverBackgroundColor: string;
			hoverBorderColor: string;
			data: number[] | string[];
		}[];
	};
	type: string;
	chartType: string;
	tooltipValue?: number[];
}

export const ReportChart = ({
	data,
	tooltipValue,
	type,
	chartType,
}: ReportChartProps) => {
	const getChartOptions = () => {
		switch (type) {
			case REPORT_CHART_TYPE.ACTIVITY_COMPLETION: {
				const yLabelString = i18n.t("admin.activities_completed");
				const xLabelString = i18n.t("admin.domain_outcome");
				return activityCompletionChartOption(yLabelString, xLabelString);
			}
			case REPORT_CHART_TYPE.ASSESSMENT_COMPLETION: {
				const yLabelString = i18n.t("chart.percent_assessments_completed");
				const xLabelString = i18n.t("chart.assessment_type");
				const tooltipString = "chart.x_percent_surveys_completed";
				return percentageChartOption(
					tooltipValue,
					yLabelString,
					xLabelString,
					tooltipString
				);
			}
			case REPORT_CHART_TYPE.RUBRIC_LEVEL: {
				const yLabelString = i18n.t("chart.percent_students");
				const xLabelString = i18n.t("common.rubric_categories");

				return rubricLevelChartOption(yLabelString, xLabelString);
			}
			case REPORT_CHART_TYPE.ANCESTRAL_LANGUAGE_SPEAKER: {
				const yLabelString = i18n.t("chart.percent_surveys_completed");
				const xLabelString = i18n.t("admin.user_roles");
				const tooltipString = "chart.percent_ancestral_language_speakers";
				return percentageChartOption(
					tooltipValue,
					yLabelString,
					xLabelString,
					tooltipString
				);
			}
			case REPORT_CHART_TYPE.LANGUAGE_SPOKEN_AT_HOME: {
				const yLabelString = i18n.t("chart.percent_surveys_completed");
				const xLabelString = i18n.t("admin.languages_spoken");
				const tooltipString = "chart.percent_spoken_language";
				return percentageChartOption(
					tooltipValue,
					yLabelString,
					xLabelString,
					tooltipString
				);
			}
			case REPORT_CHART_TYPE.ACTIVITY_TAG_COMPLETION: {
				return activityTagCompletion(data);
			}
			default:
				throw new Error(`Unsupported chart type: ${type}`);
		}
	};

	const chartOptions = getChartOptions();
	switch (chartType) {
		case CHART_TYPE.BAR:
			return <Bar data={data} width={100} height={50} options={chartOptions} />;
		case CHART_TYPE.PIE:
			return <Pie data={data} width={100} height={50} options={chartOptions} />;
		default:
			throw new Error(`Unsupported chart type: ${chartType}`);
	}
};

const tickLegendLabelColor = "#000";
const fontFamily = "nunitobold";

const legendLabelFont = {
	family: fontFamily,
	color: tickLegendLabelColor,
};

const titleFont = {
	family: fontFamily,
	color: "#6c757d",
};

const tickFont = {
	size: 12,
	family: fontFamily,
	wight: "500",
};

const activityTagCompletion = (data) => ({
	plugins: {
		legend: {
			position: "left" as const,
			labels: {
				filter: (item) =>
					item.text !== i18n.t("phrase.complete_activity_to_see_data") &&
					item.text !== undefined,
				font: {
					...legendLabelFont,
					size:
						data?.labels?.length < 20
							? data?.labels?.length < 10
								? 14
								: 12
							: 10,
				},
				boxWidth: 10,
			},
		},
		tooltip: {
			bodyFont: {size: 14},
			filter: (item) => item.label !== undefined,
			callbacks: {
				label: (context) => {
					const noData = i18n.t("phrase.complete_activity_to_see_data");
					return context.label === noData
						? noData
						: `${context.label}: ${context.parsed}`;
				},
			},
		},
	},
});

const activityCompletionChartOption = (yLabelString, xLabelString) => ({
	plugins: {
		tooltip: {
			mode: "index" as const,
			callbacks: {
				title: (context) => getTooltips(context) || "",
			},
		},
		legend: legend(false),
	},
	scales: {
		y: {
			ticks: {
				...tickFont,
				precision: 0,
			},
			min: 0,
			title: {
				...title(yLabelString),
			},
		},
		x: {...xAxes(xLabelString)},
	},
});

const percentageChartOption = (
	tooltipValue,
	yLabelString,
	xLabelString,
	tooltipString
) => {
	const max = 100;
	const stepSize = 10;
	return {
		plugins: {
			tooltip: {
				mode: "index" as const,
				callbacks: {
					label: (context) =>
						i18n.t(tooltipString, {
							count: tooltipValue[context.datasetIndex],
							total: context.parsed.y,
						}),
				},
			},
			legend: legend(false),
		},
		scales: {
			y: {...yAxes(max, stepSize, yLabelString)},
			x: {...xAxes(xLabelString)},
		},
	};
};

const rubricLevelChartOption = (yLabelString, xLabelString) => ({
	plugins: {
		legend: legend(true),
	},
	scales: {
		y: {
			stacked: true,
			...yAxes(undefined, undefined, yLabelString),
		},
		x: {
			stacked: true,
			...xAxes(xLabelString),
		},
	},
});

const legend = (display) => ({
	display,
	labels: {
		font: {...legendLabelFont},
	},
});

const yAxes = (max = 100, stepSize = 20, yLabelString) => ({
	ticks: {
		stepSize,
		color: tickLegendLabelColor,
		font: {
			...tickFont,
		},
	},
	min: 0,
	max: max === 0 ? 20 : max > 100 ? 100 : max,
	title: {
		...title(yLabelString),
	},
});

const xAxes = (xLabelString) => ({
	grid: {display: false},
	ticks: {
		color: tickLegendLabelColor,
		font: {...tickFont},
	},
	title: {
		...title(xLabelString),
	},
});

const title = (text) => ({
	text,
	display: true,
	font: {
		...titleFont,
	},
});
