import React from "react";

import {AUTOMATION_TEST_ID} from "../../../../../Scripts/Constants";
import {Icon} from "../../../../Public";

interface ModalHeaderProps {
	onClose?: () => void;
	children?: JSX.Element[] | JSX.Element;
	modalHeaderRef?: HTMLDivElement;
	noCloseButton?: boolean;
}

export const ModalHeader = ({
	onClose,
	children,
	modalHeaderRef,
	noCloseButton = false,
}: ModalHeaderProps) => (
	<div
		className="rounded-top modal-header bg-white border-bottom"
		ref={(node) => (modalHeaderRef = node as HTMLDivElement)}
	>
		<div className="modal-header-content">{children}</div>
		{!noCloseButton ? (
			<button
				type="button"
				className="modal-close-btn"
				onClick={onClose}
				aria-label="Close"
				id={AUTOMATION_TEST_ID.PUBLIC.BUTTONS.MODAL_CLOSE_BUTTON}
			>
				<Icon type="faTimesCircle" />
			</button>
		) : null}
	</div>
);
