import {
	LanguageActivities,
	LearningOpportunitiesInAssessment,
	Storytelling,
	WhereDoYouTalk,
	WhoDoYouTalk,
} from "./OralLanguage";

export const OralLanguageAssessment = {
	Storytelling,
	WhereDoYouTalk,
	WhoDoYouTalk,
	LanguageActivities,
	LearningOpportunitiesInAssessment,
};
export * from "./Math";
export * from "./Public";
