import "./PrimaryNavSearchActivity.scss";

import i18n from "i18next";
import React, {useState} from "react";

import {setFiltersGetActivities} from "../../../../Scripts/Activities";
import {
	ACTIVITY_FILTERS,
	AUTOMATION_TEST_ID,
	KEY_EVENT,
} from "../../../../Scripts/Constants";
import {
	defaultFilterTagsInFilterBar,
	resetCurrentOutcomes,
	resetSaveSearchCompleted,
} from "../../../../Scripts/LearningOpportunities";
import {Icon} from "../../../Public";

export const PrimaryNavSearchActivity = ({filters, portal}) => {
	const [searchValue, setSearchValue] = useState(
		filters[ACTIVITY_FILTERS.SEARCH]
	);

	const handleChange = (event) => {
		setSearchValue(event.target.value);
	};

	const handleKeyDown = (event) => {
		if (event.key === KEY_EVENT.ENTER) {
			handleClickSearch();
		}
	};

	const handleClickTimes = () => {
		setSearchValue("");
		if (filters[ACTIVITY_FILTERS.SEARCH]) {
			const currentFilters = {
				...filters,
				...resetCurrentOutcomes,
				...resetSaveSearchCompleted(portal),
				filterTags: {...defaultFilterTagsInFilterBar},
				[ACTIVITY_FILTERS.PAGE]: null,
			};
			setFiltersGetActivities({...currentFilters}, true);
		}
	};

	const handleClickSearch = () => {
		if (searchValue && searchValue !== filters.search) {
			const currentFilters = {
				...filters,
				...resetCurrentOutcomes,
				...resetSaveSearchCompleted(portal),
				filterTags: {...defaultFilterTagsInFilterBar},
				[ACTIVITY_FILTERS.NOT_COMPLETED_BY]: null,
				[ACTIVITY_FILTERS.SEARCH]: searchValue,
				[ACTIVITY_FILTERS.PAGE]: null,
				[ACTIVITY_FILTERS.DIFFICULTIES]: [],
				[ACTIVITY_FILTERS.PLACES]: [],
				[ACTIVITY_FILTERS.DURATIONS]: [],
				[ACTIVITY_FILTERS.HIDDEN]: false,
			};
			setFiltersGetActivities({...currentFilters}, true);
		}
	};
	return (
		<div
			className="primary-nav-search-activity"
			id={AUTOMATION_TEST_ID.PUBLIC.COMPONENTS.ACTIVITY_SEARCH_BAR}
		>
			<div
				className="search-icon"
				onClick={handleClickSearch}
				id={AUTOMATION_TEST_ID.PUBLIC.COMPONENTS.ACTIVITY_SEARCH_BUTTON}
			>
				<Icon type="faSearch" size="1x" />
			</div>
			<input
				className="search-input"
				placeholder={i18n.t("admin.search_learning_activities")}
				value={searchValue}
				onChange={handleChange}
				onKeyDown={handleKeyDown}
				id={AUTOMATION_TEST_ID.PUBLIC.COMPONENTS.ACTIVITY_SEARCH_ACTIVITY_INPUT}
			/>
			{searchValue !== "" && (
				<div className="times-icon" onClick={handleClickTimes}>
					<Icon type="faTimesCircle" size="1x" />
				</div>
			)}
		</div>
	);
};
