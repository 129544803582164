import * as ActionTypes from "../Redux/ActionTypes";
import {CreateReducer} from "../Redux/Utilities";

const initialState = {
	queue: [],
};

export const ErrorReporterReducer = CreateReducer(initialState, {
	[ActionTypes.ERROR_REPORTER_ADD_TO_QUEUE]: (state, {payload}) => {
		if (!payload) return state;
		return {
			...state,
			queue: [...state.queue, payload],
		};
	},
	[ActionTypes.ERROR_REPORTER_REMOVE_ERROR_FROM_QUEUE]: (state, {payload}) => {
		if (!payload) return state;
		return {
			...state,
			queue: state.queue.filter(
				(queueItem) => queueItem.actionType !== payload
			),
		};
	},
});
