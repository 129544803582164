import htmlToText from "html-to-text";
import i18n from "i18next";
import React, {Fragment, useState} from "react";

import {
	ActivityActions,
	ActivityMonitorActions,
} from "../../../../../../../Actions";
import {sanitizeContent} from "../../../../../../../Scripts/Activities";
import {
	ACTIVITY_MONITOR_ACTIONS,
	PORTALS,
	SANITIZE_TYPE,
} from "../../../../../../../Scripts/Constants";
import {Icon, IconType} from "../../../../../../Public";
import {ActivityCompletion} from "../../../../Activity";

export const ActivityHeaderComponents = ({
	activity,
	saved,
	completions,
	studentId,
	customizedFunctions,
	role,
	userId = 0,
	isReadingCard = false,
	iconType = "",
	isHolisticMathCard = false,
}) => {
	const [sendRequest, setSendRequest] = useState(false);
	const handleSaveIconClick = (e) => {
		e.stopPropagation();
		setSendRequest(true);
		const query = saved ? `/${activity.id}` : ``;
		const person = role === PORTALS.EDUCATOR ? userId : studentId;
		ActivityActions.saveAndDeleteSaveActivity(
			person,
			query,
			{activity_id: activity.id},
			{
				handling: {
					success: () => {
						ActivityMonitorActions.addToQueue({
							type: ACTIVITY_MONITOR_ACTIONS.SUCCESS,
							title: i18n.t(
								saved ? "admin.activity_unsaved" : "admin.activity_saved"
							),
							message: i18n.t(
								saved
									? "admin.activity_unsaved_message"
									: "admin.activity_saved_message",
								{
									activity: htmlToText.fromString(activity.title.rendered),
								}
							),
						});

						ActivityActions.getSavedActivities(person, {
							handling: {success: () => setSendRequest(false)},
						});
						if (customizedFunctions) {
							customizedFunctions();
						}
					},
					error: (error) => {
						ActivityMonitorActions.addToQueue({
							type: ACTIVITY_MONITOR_ACTIONS.ERROR,
							title: i18n.t(
								saved
									? "error.failed_unsave_activity"
									: "error.failed_save_activity"
							),
							message: i18n.t(
								saved
									? "admin.failed_unsave_activity_message"
									: "error.failed_save_activity_message",
								{
									activity: htmlToText.fromString(activity.title.rendered),
									error: error.body.message,
								}
							),
						});
						setSendRequest(false);
					},
				},
			}
		);
	};

	return (
		<Fragment>
			{isReadingCard && iconType ? (
				<div className="topic-logo">
					<Icon type={iconType as IconType} />
				</div>
			) : null}
			<div
				className="activity-title"
				dangerouslySetInnerHTML={sanitizeContent(
					activity.title.rendered,
					SANITIZE_TYPE.TITLE
				)}
			/>
			<div
				className={`save-icon${saved ? " saved" : " un-saved"}`}
				onClick={handleSaveIconClick}
			>
				{sendRequest && (
					<Icon type="faSpinner" size="1x" pulse className="spinner-icon" />
				)}
				{!sendRequest && (
					<Icon type="faHeart" size="1x" className="heart-icon" />
				)}
			</div>
			{!isReadingCard && !isHolisticMathCard ? (
				<div className="activity-completion">
					<ActivityCompletion completion={completions} id={activity.id} />
				</div>
			) : null}
		</Fragment>
	);
};
