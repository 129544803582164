import i18n from "i18next";
import React from "react";

import {getDayDifference} from "../../../../../Scripts/Notifications";
import {
	NotificationDataModel,
	NotificationModel,
} from "../../../../../Scripts/PublicInterfaces";

export const TimeReceived = ({
	notification,
}: {
	notification: NotificationModel<NotificationDataModel>;
}) => {
	const dayDifference = getDayDifference(notification.issued_on);
	const dayOrDays =
		dayDifference > 1 ? i18n.t("time.days") : i18n.t("time.day");
	return (
		<div className="timestamp text-muted text-right mt-2">
			<small className="nun-semibold">
				{dayDifference > 0 &&
					i18n.t("phrase.time_received", {dayDifference, dayOrDays})}
				{dayDifference === 0 && i18n.t("phrase.received_today")}
			</small>
		</div>
	);
};
